import React, { useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { getToken, } from '../../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
import BTN from '../../../StyleComponents/Button'
import { confirm as Confirm } from '../../Method/Confirm';
import { API } from '../../../data/options'


interface props {
    onClose: () => void
    data: {

    }
}

let wrapper: Element

export const Border = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    & > div{
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        & > div{
            margin-right: 30px;
        }
        input{
            width: 333px;
            border: 1px solid #979797;
            border-radius: 4px;
            padding: 3px;
        }
    }
`

export default async function () {
    return new Promise(async (reslove, reject) => {
        function Category(props: props) {
            const data = useRef<FormData>()
            const [shouldClose, setShouldClose] = useState(false)
            const [loading, setloading] = useState(false)
            return <Modal title='批量导入商品' onClose={props.onClose} loading={loading} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <ol id='batch-order'>
                        <li>
                            <span >下载商品表格模版</span>
                            <BTN color='red' style={{
                                marginLeft: '10px',
                            }} onClick={() => {
                                window.location.href = 'https://jieligo-1259536745.cos.ap-guangzhou.myqcloud.com/uploads/wxacode/导入商品模板.xlsx'
                            }}>下载模版</BTN>
                        </li>
                        <li>
                            <span>按照表格模版格式</span>
                            <span style={{ color: '#639ADF' }}>填写好商品信息</span>
                        </li>
                        <img alt='发货填写示例图' src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/goods/h9raTPpAAT.png' style={{ width: '600px' }}></img>
                        <li>
                            <span>填好商品信息后保存表格，然后点击下方按钮</span>
                            <span style={{ color: '#639ADF' }}>上传该表格</span>
                        </li>
                        <label style={{ cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <input type='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                                onChange={e => {
                                    if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                    const file = e.target.files[0]
                                    const formData = new FormData()
                                    formData.append('file', file)
                                    formData.append('types', '1')
                                    formData.append('token', getToken())
                                    data.current = formData
                                }} />
                            <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                        </label>
                        <div style={{ color: '#3d83d8', cursor: 'pointer' }} onClick={e => {
                            const win = window.open('/help/importexcel', '_blank');
                            win && win.focus();
                        }}></div>
                    </ol>
                </div>
            </Modal>

            function confirm() {
                setloading(true)
                fetch(API + '/pcorder/creategoodsbyimportexcel', {
                    method: 'post', body: data.current, mode: 'cors',
                    credentials: 'include',
                }).then((res) => {
                    setloading(false)
                    res.json().then((res) => {
                        if (res.code !== 0) {
                            Confirm({
                                text: res.message
                            })
                            return
                        }
                        message.success('上架成功')
                        setShouldClose(true)
                        reslove(true)
                    })
                }).catch(() => {
                    message.error('网络错误')
                })
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}