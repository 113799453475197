import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Switch, Route, Redirect } from "react-router-dom";
import Content from "./ContentLayoutPanel";
// import topPhone from ''
import { toggleLoginPanel } from "../../reducers/index";
// import home_phone from '../../images/home_phone.png'
import News from "./News.tsx";
// import SignIn from './SignIn.tsx';
import styled from "styled-components";
import Loading from "../Method/Loading";
import { Carousel } from "antd";
import PrivacyPolicy from "./Privacy Policy";
import Footer from "./component/Footer.tsx";
const TOP = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 84px;
  background: rgba(249, 97, 55, 0);
  z-index: 60;
  transition: background 0.1s;
`;

const TopContent = styled.div`
  max-width: 1200px;
  margin: auto;
  height: 100%;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
  .icon-pc_gengduo {
    display: none;
  }
  @media (max-width: 800px) {
    .icon-pc_gengduo {
      display: inline-block;
      font-size: 30px;
      color: #fff;
      margin-right: 15px;
    }
  }
  & > img {
    @media (max-width: 800px) {
      height: 45px;
      & ~ * {
        *:not(.active) {
          display: none;
        }
      }
    }
  }
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  & > * {
    height: 100%;
  }
  a.active {
    font-weight: 600;
  }
`;

const AboutTimeLine = styled.div`
  text-align: center;
  padding-bottom: 60px;
  img {
    display: none;
    @media (min-width: 1200px) {
      &:nth-child(1) {
        display: inline-block;
      }
    }
    @media (min-width: 800px) and (max-width: 1200px) {
      &:nth-child(2) {
        display: inline-block;
      }
    }
    @media (max-width: 800px) {
      @media (min-width: 460px) {
        &:nth-child(3) {
          width: 450px;
        }
      }
      &:nth-child(3) {
        display: inline-block;
      }
    }
  }
`;

const Menu = styled.div`
  flex: 1;
  padding: 84px 20px;
  & > div:not(._submenu) {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }
  a {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
    display: block;
  }
  * {
    color: #333;
    font-size: 16px;
    font-weight: 700;
  }
  ._submenu {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  padding: 10px;
  font-size: 16px;
  height: 100%;
  line-height: 1;
  cursor: pointer;
  padding: 0 28px;
  .active & {
    font-size: 20px;
  }
  .menu {
    display: none;
  }
  &:hover {
    .menu {
      display: block;
      position: absolute;
      left: 0;
      top: 100%;
      opacity: 1;
      a {
        width: 120px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: #333;
        &.active,
        &:hover {
          color: #f96137;
        }
      }
    }
  }
`;

const TopButton = styled.button`
  font-size: 16px;
  border: 1px white solid;
  padding: 9px 29px;
  border-radius: 20px;
  color: white;
  line-height: 1;
  background: transparent;
  margin-left: 1rem;
  &:hover {
    opacity: 0.5;
  }
`;
const LoginButton = styled(TopButton)`
  color: #fff;
  border: #fff 1px solid;
`;

const NewsList = styled.div`
  max-width: 1140px;
  margin: auto;
  padding-top: 60px;
  background: #fff;
`;

const HomeSwitch = styled.div`
  background-color: #fff;
  button {
    background: #fff;
    border: 0;
    border-bottom: #fff solid 4px;
    color: #999999;
    margin: 0 48px;
    padding: 15px 0;
    font-size: 22px;
    font-weight: 600;
  }
  .active {
    button {
      border-bottom: #fa4f3a solid 4px;
      color: #fa4f3a;
    }
  }
  @media (max-width: 500px) {
    width: 100%;
    & > * {
      width: 50%;
    }
  }
`;

const CarouselItem = styled.div`
  background: url(${(props) => props.bg});
  @media (max-width: 600px) {
    background: white;
    padding-top: 0;
    height: unset;
    & > div {
      height: unset !important;
      ._subimg {
        width: 100%;
        display: block !important;
      }
    }
  }
  background-position: center center;
  padding-top: 84px;
  height: 650px;
  & > div {
    height: 500px;
    max-width: 1200px;
    margin: 0 auto;
    cursor: pointer;
    ._subimg {
      display: none;
    }
  }
`;

const Feature = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-position: center;
  background-size: cover;
  .icons {
    max-width: 1085px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    color: #333;
    font-size: 16px;
    font-weight: 700;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 60px;
      @media (max-width: 800px) {
        margin-bottom: 40px;
      }
      & > div {
        width: 170px;
        height: 170px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 800px) {
          margin-bottom: 12px;
          width: 55px;
          height: 55px;
          i {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
    }
    @media (max-width: 800px) {
      span {
        font-size: 12px;
      }
    }
    i {
      color: #fa4f3a;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 18px;
    }
  }
`;

const indexList1 = [
  {
    title: "搭建销售网络·发挥渠道效能",
    content: "赋能门店全渠道系统对接管理\n门店统一管理、独立门店数据分析",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/IkVYyPO2ZI.png",
  },
  {
    title: "数据清晰·分析明确",
    content: "多种对账方案、账单清晰易懂\n可总对总、总对分",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/2q6N7u96Z6.png",
  },
  {
    title: "消息通知·高效处理订单",
    content:
      "佣金发放、退款、提现等操作均有消息通知\n高效处理订单发货/核销、退款",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/AN0WuinLZI.png",
  },
];
const indexList2 = [
  {
    title: "新零售SaaS系统",
    content: "赋能门店全渠道系统对接管理\n门店统一管理、独立门店数据分析",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/R5uJ8N57I1.png",
  },
  {
    title: "财务对账",
    content: "多种对账方案、账单清晰易懂\n可总对总、总对分",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/5PP9QPgGZ9.png",
  },
  {
    title: "门店+团长分销",
    content:
      "一键分销，操作简便\n商品统一管理，库存、订单即时同步\n多种模式，提供定制化服务，满足多样化需求",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/BbiEgH2llv.png",
  },
];

const indexList3 = [
  // 功能介绍--团长服务
  {
    title: "商品管理·批量导入商品",
    content:
      "商品库轻松管理商品、随时修改、上架、下架\n还可以通过商品表格批量上传商品、提高效率",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/u65JDe3k3z.png",
  },
  {
    title: "好友帮卖",
    content:
      "两种价格模式：对外统一定价、可自由定价\n对外统一定价：可设置所有的帮卖好友价格统一\n可自由定价：只设置供货价、帮卖好友可自由定价",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/zk4L67tm7m.png",
  },
  {
    title: "订单管理·批量发货",
    content:
      "可以对订单进行筛选、搜索、导出、打印等\n订单分类清晰、订单状态同步更新\n可批量处理订单、快速发货/核销",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/0hJ0128h08.png",
  },
  {
    title: "短视频带货",
    content:
      "支持60S长视频，带货更直观\n首页全屏视频播放：沉浸式体验\n活动图文详情穿插视频：可嵌入多个视频、视频和文字、图片可以穿插展示",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/wV87ipPA3j.png",
  },
];

const indexList4 = [
  // 功能介绍--品牌服务
  {
    title: "社区团购商城·连锁门店",
    content:
      "帮品牌构建社区团购业务\n品牌连锁店可设置多个门店、同步总店商品价格和库存\n可切换单列/双列商品展示样式",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/WqKDC9KIgn.png",
  },
  {
    title: "门店+团长分销",
    content:
      "一键分销，操作简便\n商品统一管理，库存、订单即时同步\n多种模式，提供定制化服务，满足多样化需求",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/4vLv9MoVHu.png",
  },
  {
    title: "支持多种配送方式",
    content:
      "快递发货：打通各大快递公司、物流信息同步查询\n门店自提：可设置多个不同自提门店地址\n骑手配送：连接达达骑手配送、同城配送更快捷",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/pZ8wXCwk1C.png",
  },
  {
    title: "多维度数据分析",
    content:
      "交易分析：按时间统计每一次活动销售额、佣金、总收入等\n客户销售统计：按时间统计每一个客户的参团次数和累积消费金额\n成本利润统计：按时间统计所有的活动总收入和总成本支出，得出预估利润",
    img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/Vnt92vMBMV.png",
  },
];

class Home extends Component {
  news = [
    {
      title: "滴滴疯狂投入社区团购，单量全国第二，美团、拼多多望而生畏",
      content:
        "9月28日成都滴滴橙心迎来首次爆仓，当天橙心开启狂欢日活动，在晚上九点前所商品售罄，之后成都橙心通知团长关闭小程序下单和退款，同时封锁数据。而在山东也开启“限时秒杀”刷屏活动，恒都黑胡椒牛排4.99一袋，内蒙土豆1.89一袋，北京二锅头0.99一瓶，滴滴所到之处，风声鹤唳。除了川渝大本营外，目前滴滴橙心优选已在山东济南、云南昆明、贵州贵阳、广西南宁、陕西西安开城，至此，橙心优选开城范围已经扩大到七省市，从整体数据来看，成都、重庆大本营日单量最大，西安、济南、南宁增长最快。在撒钱猛冲下，滴滴橙心得以在120天内完成全国日订单总量280万单的突破，按照目前单量，橙心在团购赛道仅次于兴盛优选，已跻身团购赛道第二位置，滴滴大力出奇迹。",
      img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/6xBKsm6UQs.png",
      src: "https://mp.weixin.qq.com/s?__biz=MzkzMjEwODc2Mg==&mid=2247483935&idx=1&sn=d62308356d838fcaef8bf8723a00527a&chksm=c26182aaf5160bbcab843a96fe23c760ee1458c15156c96099e738bbdc92c9358c758e32858f&token=396899486&lang=zh_CN#rd",
    },
    {
      title: "盒马加码社区团购",
      content:
        "9月29日，盒马CEO侯毅朋友圈透露，其最近两周一直在武汉、长沙、成都、合肥系统性调研社区团购，侯毅认为社区团购实质就是盒马云超，在原来的实体门店上用电商方式扩大商品品类，用门店配送体系实现物流配送，只要扩大共享门店范围，到店物流进一步下沉，盒马云超就可以快速拓展到农村。为此，盒马成立独立的盒马优选事业部，由盒马物流负责人陈明（花名：红方）负责，向侯毅汇报。陈明此前在京东是侯毅的管培生，现在是盒马最年轻的 P9。该消息在阿里 9 月中旬的一次集团总裁会上，由阿里巴巴集团董事局主席兼 CEO 张勇宣布，盒马优选由盒马总裁侯毅直接负责，向阿里 B2B 事业群业务总裁戴珊汇报，加码社区团购，侯毅决心大。",
      img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/dqQ5s4Xdz6.png",
      src: "https://mp.weixin.qq.com/s?__biz=MzkzMjEwODc2Mg==&mid=2247483931&idx=1&sn=faa83ef1a1547f922090eacfc599a3b3&chksm=c26182aef5160bb8f7d50fcd184bb9b976425da21d3a7a614f5aee6b9d40e169fb112c2c7728&token=396899486&lang=zh_CN#rd",
    },
    {
      title: "美团广佛破30万单",
      content:
        "美团优选广佛仓大概一万一平，目前实用面积为5000 平，目前这个大仓承载单日销量已破30万单，单是广州就覆盖了超1000名团长，而数据飞涨背后，美团优选后端整个配送效率的提升也不容忽视。美团优选广州白云区的一个网格仓每日单量10000＋，高峰时期有25000单，美团优选截团时间11点，网格仓第一波到货时间为晚上九点，上半夜就出完货，美团优选要求一点半所有网格仓都要完成，司机早上七点前全部出完仓，蔬菜品类到仓较早。相比之下，武汉多多买菜网格仓到货时间为早上4－5点，司机早上10全部出仓，12点左右可全部到团长手上，网格仓每日单量7000－8000单。而成都橙心优选到网格仓时间不稳定，中心仓刚搬到龙泉，之前曾是苏宁大仓，中心仓分拣效率不高，早上4－5点到网格仓，网格仓每日单量7000单上下。",
      img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/0rrRL4G6R4.png",
      src: "https://mp.weixin.qq.com/s?__biz=MzkzMjEwODc2Mg==&mid=2247483927&idx=1&sn=6f18386e5777a89e5b047644492d1492&chksm=c26182a2f5160bb4e513102a2ab2137299fe8b7f2d0cf5579a9b33c3795e7609f0fa235aff76&token=396899486&lang=zh_CN#rd",
    },
    {
      title: "钱大妈无锡开店，小兔买菜迎劲敌",
      content:
        "9月19日，钱大妈无锡第一家门店太湖国际正式开业，此举意味着钱大妈在全国重点城市拓展布局上再迈进一步，标志着钱大妈经营理念和模式在苏锡常区域认可度不断提升。数据显示，9月19日开业当天，门店实体销售金额12.5万，19点前客单成交量高达3300笔，实力打响无锡第一枪新一线城市，是《第一财经周刊》根据商业资源集聚度、城市枢纽性、城市人活跃度、生活方式多样性和未来可塑性五大指标综合评比后得出名单。无锡作为苏南发达地区地级市，于2018年就已跻身新一线城市，拥有雄厚经济基础，对周边多个城市具有辐射能力。值得一提的是，此前钱大妈七月入驻合肥，也于今年正式跻身新一线城市。 ",
      img: "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/1O51lI5AV7.png",
      src: "https://mp.weixin.qq.com/s?__biz=MzkzMjEwODc2Mg==&mid=2247483923&idx=1&sn=a72d5cc52ed16764345fb9da4b46467d&chksm=c26182a6f5160bb098963c0d74dcbf55227373a16b45eb5599d548bb9596ab91b21675376477&token=396899486&lang=zh_CN#rd",
    },
  ];

  constructor() {
    super();
    this.state = {
      signin: false,
      subMenu1: false,
    };
  }
  componentDidUpdate(prev) {
    Loading.hide();
    if (!this.props.waitingLogin) {
      if (this.props.is_Login) {
        this.props.history.push("/index");
      }
    }
    if (
      this.props.location.hash &&
      !prev.location.hash &&
      this.props.location.hash === "#introduction"
    ) {
    }

    if (!["/home", "/home/品牌服务"].includes(this.props.location.pathname)) {
      this.header.current.style.background = `rgba(249, 97, 55, ${1})`;
    }

    this.scrollHandle();
  }
  componentDidMount() {
    Loading.hide();
    document.title =
      window.location.href.indexOf("jieligo") === -1
        ? "GroupAI公司官网"
        : "接力GO";
    if (!this.props.waitingLogin) {
      if (this.props.is_Login) {
        this.props.history.push("/index");
      }
    }
    document.addEventListener("scroll", this.bindScrollHandle);
    if (!["/home", "/home/品牌服务"].includes(this.props.location.pathname)) {
      this.header.current.style.background = `rgba(249, 97, 55, ${1})`;
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.bindScrollHandle);
  }
  showLogin() {
    this.props.showLogin(true);
  }

  bindScrollHandle = this.scrollHandle.bind(this);
  scrollHandle() {
    const top = document.documentElement.scrollTop || document.body.scrollTop;
    const op = top < 300 ? top / 300 : 1;
    const text = (opacity = 1) => {
      let op = opacity;
      if (!["/home", "/home/品牌服务"].includes(this.props.location.pathname)) {
        op = 1;
      }
      return `rgba(249, 97, 55, ${op})`;
    };
    if (op === 1 && this.header.current.style.background === "rgb(249, 97, 55)")
      return;
    this.header.current.style.background = text(op);
  }

  header = React.createRef();

  render() {
    return (
      <div id="index" style={{}}>
        <TOP ref={this.header} className="">
          <TopContent className="fbc">
            <i
              className="iconfont icon-pc_gengduo"
              onClick={() => {
                this.props.history.push("/home/menu");
              }}
            />
            <img
              alt=""
              style={{ marginRight: 42 }}
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/cKCblQJqK2.png"
            />
            <Nav>
              <NavLink
                className={(() => {
                  if (
                    this.props.location.pathname === "/home" ||
                    this.props.location.pathname === "/home/品牌服务"
                  )
                    return "active";
                })()}
                exact
                to="/home"
              >
                <NavItem
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <div>首页</div>
                </NavItem>
              </NavLink>
              <div>
                <NavItem>
                  <div>功能介绍</div>
                  <div
                    className="menu"
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    <NavLink to="/home/feature/团长服务" exact>
                      团长服务
                    </NavLink>
                    <NavLink to="/home/feature/品牌服务" exact>
                      品牌服务
                    </NavLink>
                  </div>
                </NavItem>
              </div>
              <NavLink to="/home/infomation">
                <NavItem
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <div>行业资讯</div>
                </NavItem>
              </NavLink>
              <NavLink to="/home/定制服务">
                <NavItem
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <div>定制服务</div>
                </NavItem>
              </NavLink>
              <NavLink to="/home/关于我们">
                <NavItem
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <div>关于我们</div>
                </NavItem>
              </NavLink>
            </Nav>
            <div style={{ flex: 1 }}></div>
            <div className="fbc">
              {/* <TopButton className='cur-pointer' onClick={() => { this.setState({ signin: true }) }}>注册</TopButton> */}
              <LoginButton
                onClick={this.showLogin.bind(this)}
                className="cur-pointer"
              >
                登录
              </LoginButton>
            </div>
          </TopContent>
        </TOP>
        <Switch>
          <Route path="/home/feature">
            <div className="flex-column" style={{ flex: 1, paddingTop: 84 }}>
              <div
                style={{
                  background: `url(https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/42E17zDqK2.png)`,
                  backgroundSize: "cover",
                  height: 234,
                  flexDirection: "column",
                }}
                className="flex-center"
              >
                <div
                  style={{
                    fontSize: 60,
                    fontWeight: 700,
                    fontFamily: "Helvetica",
                  }}
                >
                  微信小程序+PC端网页
                </div>
                <h3 style={{ fontWeight: 700, fontFamily: "Helvetica" }}>
                  新零售SaaS系统社区团购工具
                </h3>
              </div>
              <HomeSwitch className="flex-center">
                <NavLink to={"/home/feature/团长服务"} exact>
                  <button>团长服务</button>
                </NavLink>
                <NavLink to={"/home/feature/品牌服务"} exact>
                  <button>品牌服务</button>
                </NavLink>
              </HomeSwitch>
              <Switch>
                <Route path="/home/feature/团长服务">
                  <div
                    style={{
                      padding: "20px 0",
                      maxWidth: 1140,
                      margin: "auto",
                    }}
                  >
                    {indexList3.map((v, i) => {
                      return (
                        <Content
                          content={v.content}
                          textbg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/030bbML4Rf.png"
                          title={v.title}
                          img={v.img}
                          type={(i % 2) + 1}
                          key={i}
                        ></Content>
                      );
                    })}
                  </div>
                </Route>
                <Route path="/home/feature/品牌服务">
                  <div
                    style={{
                      padding: "20px 0",
                      maxWidth: 1140,
                      margin: "auto",
                    }}
                  >
                    {indexList4.map((v, i) => {
                      return (
                        <Content
                          content={v.content}
                          textbg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/030bbML4Rf.png"
                          title={v.title}
                          img={v.img}
                          type={(i % 2) + 1}
                          key={i}
                        ></Content>
                      );
                    })}
                  </div>
                </Route>
              </Switch>
            </div>
          </Route>

          <Route path="/home/infomation">
            <img
              className="img-center"
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/5gqgsi33vz.jpg"
              alt=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 690,
                width: "100%",
                zIndex: -1,
              }}
            />
            <div style={{ background: "#fff", flex: 1, marginTop: 84 }}>
              <NewsList>
                {this.news.map((item, index) => {
                  return (
                    <News
                      key={index}
                      {...item}
                      signin={() => {
                        this.setState({ signin: true });
                      }}
                    />
                  );
                })}
              </NewsList>
            </div>
          </Route>

          <Route path="/home/关于我们">
            <div className="flex-column" style={{ flex: 1, paddingTop: 84 }}>
              <div
                style={{
                  background: `url(https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/kkQ7w00EBx.png)`,
                  backgroundSize: "cover",
                  height: 244,
                  flexDirection: "column",
                  marginBottom: 100,
                }}
                className="flex-center"
              >
                <div
                  style={{
                    fontSize: 60,
                    fontWeight: 700,
                    fontFamily: "Helvetica",
                  }}
                >
                  美好生活，一起接力GO!
                </div>
                <h3 style={{ fontWeight: 700, fontFamily: "Helvetica" }}>
                  新零售SaaS系统社区团购工具
                </h3>
              </div>
              <div style={{ maxWidth: 1140, margin: "auto" }}>
                <div className="flex-center" style={{ marginBottom: 60 }}>
                  <img
                    alt=""
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/i18TTNO464.png"
                  />
                </div>
                <p style={{ color: "#666", fontSize: 16, marginBottom: 100 }}>
                  接力GO是一家专注于社区零售解决方案的技术服务商。在社区团购、社群电商、新零售行业中，已有5年以上的行业经验。目前接力GO定位于为通过SaaS系统、小程序交易平台等互联网解决方案，为大型商超、品牌连锁等线下实体，构建基于真实城市社区的“消费新渠道”，协助其构筑新零售、同城配送、及社区团购业务。
                </p>
                <div className="flex-center" style={{ marginBottom: 60 }}>
                  <img
                    alt=""
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/GBhBHVG49Z.png"
                  />
                </div>
                <AboutTimeLine>
                  <img
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/bF9eGvK0GF.png"
                    alt=""
                  />
                  {/* > 1200 */}
                  <img
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/1nNtYGAr47.png"
                    alt=""
                  />
                  {/* < 1200 */}
                  <img
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/jsnjDjkaPn.png"
                    alt=""
                  />
                  {/* < 800 */}
                </AboutTimeLine>
              </div>
            </div>
          </Route>
          <Route path="/home/定制服务">
            <div
              className="flex-column"
              style={{
                flex: 1,
                paddingTop: 84,
                justifyContent: "center",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/l25FC6yDI0.png)`,
              }}
            >
              <div
                className="flex-center"
                style={{ fontSize: 50, marginBottom: 50 }}
              >
                一键获取专属定制方案 扫码了解更多
              </div>
              <div
                className="flex-center cur-pointer"
                onClick={() => {
                  this.setState({ codeView: true });
                }}
                style={{
                  width: 260,
                  height: 80,
                  background:
                    "linear-gradient(116deg,#f77035 4%, #fc3f3c 126%)",
                  borderRadius: 40,
                  boxShadow: "0px 2px 8px 0px rgba(231,62,39,0.37)",
                  fontSize: 30,
                  color: "#fff",
                }}
              >
                点击获取
              </div>
            </div>
          </Route>
          <Route path="/home/menu">
            <Menu className="flex-column">
              <NavLink exact to="/home">
                首页
              </NavLink>
              <div
                className="flex-between"
                onClick={() => {
                  this.setState({ subMenu1: !this.state.subMenu1 });
                }}
              >
                功能介绍
                <i className="iconfont icon-down" />
              </div>
              <div className="_submenu">
                {this.state.subMenu1 && (
                  <NavLink to="/home/feature/团长服务" exact>
                    团长服务
                  </NavLink>
                )}
                {this.state.subMenu1 && (
                  <NavLink to="/home/feature/品牌服务" exact>
                    品牌服务
                  </NavLink>
                )}
              </div>
              <NavLink to="/home/infomation">行业资讯</NavLink>
              <NavLink to="/home/定制服务">定制服务</NavLink>
              <NavLink to="/home/关于我们">关于我们</NavLink>
            </Menu>
          </Route>
          <Route path="/home/privacyAgreement" component={PrivacyPolicy} />
          <Route path="/home">
            {/* 预留轮播 */}
            <Carousel autoplay>
              <CarouselItem
                bg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/a5cCZlAOUl.png"
                minbg=""
              >
                {/* 登录 */}
                <div onClick={this.showLogin.bind(this)}>
                  <img
                    className="_subimg"
                    alt=""
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/CfaJ6ORasa.png"
                  />
                </div>
              </CarouselItem>
              <CarouselItem
                bg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/596iizIibQ.png"
                minbg=""
              >
                <div
                  onClick={() => {
                    this.props.history.push("/home/feature/团长服务");
                  }}
                >
                  <img
                    className="_subimg"
                    alt=""
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/TDig7Ga79d.png"
                  />
                </div>
              </CarouselItem>
              <CarouselItem
                bg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/7U754U4JU3.png"
                minbg=""
              >
                <div
                  onClick={() => {
                    this.props.history.push("/home/定制服务");
                  }}
                >
                  <img
                    className="_subimg"
                    alt=""
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/v0dOShvdvG.png"
                  />
                </div>
              </CarouselItem>
            </Carousel>
            {/* 预留轮播 */}
            <HomeSwitch className="flex-center">
              <NavLink to={"/home"} exact>
                <button>团长服务</button>
              </NavLink>
              <NavLink to={"/home/品牌服务"}>
                <button>品牌服务</button>
              </NavLink>
            </HomeSwitch>
            <Switch>
              <Route path="/home" exact>
                <div className="flex-column" style={{ flex: 1 }}>
                  <Feature
                    className="flex-column"
                    bg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/Ta92G6kjSK.png"
                  >
                    <strong className="flex-center" style={{ padding: 30 }}>
                      适用对象：个人团长
                    </strong>
                    <div className="flex-center" style={{ marginBottom: 30 }}>
                      <img
                        alt=""
                        src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/Gf7g7DdVq0.png"
                      />
                    </div>
                    <div className="icons">
                      <div>
                        <div>
                          <i className="iconfont icon-haoyoubangmai" />
                          <span>好友帮卖</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-xiaoxitongzhi" />
                          <span>消息通知</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-shangchengzhuye" />
                          <span>商城主页</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-24xiaoshikefu" />
                          <span>24小时客服</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-yijiandaorushangpin" />
                          <span>一键导入商品</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-dingdanguanli3" />
                          <span>订单管理</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-jiaoyifenxi1" />
                          <span>交易分析</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-shujudaochu" />
                          <span>数据导出</span>
                        </div>
                      </div>
                    </div>
                  </Feature>
                  <div
                    style={{ padding: "20px 0", maxWidth: 930, margin: "auto" }}
                  >
                    {indexList1.map((v, i) => {
                      return (
                        <Content
                          content={v.content}
                          title={v.title}
                          img={v.img}
                          type={(i % 2) + 1}
                          key={i}
                        ></Content>
                      );
                    })}
                  </div>
                </div>
              </Route>
              <Route path="/home/品牌服务">
                <div className="flex-column" style={{ flex: 1 }}>
                  <Feature
                    className="flex-column"
                    bg="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/QciM1PmqQx.png"
                  >
                    <strong className="flex-center" style={{ padding: 30 }}>
                      适用对象：品牌连锁店
                    </strong>
                    <div className="flex-center" style={{ marginBottom: 30 }}>
                      <img
                        alt=""
                        src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/Gf7g7DdVq0.png"
                      />
                    </div>
                    <div className="icons">
                      <div>
                        <div>
                          <i className="iconfont icon-shangchengzhuye" />
                          <span>独有商城</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-fendianguanli1" />
                          <span>门店管理</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-duojifenxiao" />
                          <span>门店+团长分销</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-jiaoyifenxi1" />
                          <span>交易数据分析</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-tuanchangduijie" />
                          <span>团长对接</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-fangancehua" />
                          <span>方案策划</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-peixunbangzhu" />
                          <span>培训帮助</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <i className="iconfont icon-duigongzhuanzhang" />
                          <span>对公转账</span>
                        </div>
                      </div>
                    </div>
                  </Feature>
                  <img
                    style={{ width: "unset", margin: 50 }}
                    alt=""
                    src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/OTdWJP2wTc.png"
                  />
                  <div
                    style={{ padding: "20px 0", maxWidth: 930, margin: "auto" }}
                  >
                    {indexList2.map((v, i) => {
                      return (
                        <Content
                          content={v.content}
                          title={v.title}
                          img={v.img}
                          type={(i % 2) + 1}
                          key={i}
                        ></Content>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      background: `url(https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/42E17zDqK2.png)`,
                      backgroundSize: "cover",
                      height: 312,
                      flexDirection: "column",
                    }}
                    className="flex-center"
                  >
                    <div
                      style={{
                        color: "#333",
                        fontSize: 50,
                        fontWeight: "bold",
                        marginBottom: 30,
                      }}
                    >
                      一键获取专属定制方案 扫码了解更多
                    </div>
                    <button
                      onClick={() => {
                        this.setState({ codeView: true });
                      }}
                      className="flex-center"
                      style={{
                        color: "#fff",
                        border: 0,
                        height: 80,
                        width: 260,
                        background:
                          "linear-gradient(116deg,#f77035 4%, #fc3f3c 126%)",
                        borderRadius: 40,
                        fontSize: 30,
                        boxShadow: `0px 2px 8px 0px rgba(231,62,39,0.37)`,
                      }}
                    >
                      点击获取
                    </button>
                  </div>
                </div>
              </Route>
            </Switch>
            <div className="flex-center">
              <img
                style={{ margin: 50 }}
                alt=""
                src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/uiJprrryUZ.png"
              />
            </div>
            <Brand />
          </Route>
          <Redirect to="/home" />
        </Switch>
        {this.props.location.pathname !== "/home/menu" ? <Footer /> : null}

        {this.state.codeView ? (
          <div
            onClick={() => {
              this.setState({ codeView: false });
            }}
            className="flex-center"
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: 0,
              left: 0,
              background: "rgba(7, 7, 7, .7)",
              flexDirection: "column",
            }}
          >
            <img
              alt=""
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/gxUdHee8Ge.png"
              style={{ width: 200, height: 200, marginBottom: 16 }}
            />
            <p style={{ color: "#fff" }}>打开微信扫一扫 联系客服进行咨询</p>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    waitingLogin: state.waitingLogin,
    is_Login: state.is_Login,
    sidebarOpen: state.sidebarOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showLogin: (b) => {
      dispatch(toggleLoginPanel(b));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));

const Brand = () => {
  const Grid = styled.div`
    display: grid;
    margin: auto;
    max-width: 1140px;
    width: 100%;
    @media (min-width: 700px) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    @media (max-width: 700px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      transition: transform 0.4s;
      &:hover {
        box-shadow: 0px 0px 13px 0px rgba(213, 213, 213, 0.5);
        transform: translateY(-5px) translateZ(-500px) perspective(500px);
        img {
          transition: filter 0.1s;
          filter: grayscale(0);
        }
      }
    }
    img {
      max-width: 80%;
      max-height: 90%;
      filter: grayscale(1);
    }
  `;
  return (
    <>
      <Grid>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/kH6Ik7S7kv.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/dvl941h51m.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/nxFpY0NOZb.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/NNnqTqN2j2.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/q0Q1DpgT4q.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/hwxzhawu5A.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/05gH4P322H.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/821AXAsSS8.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/ahuk33jcGl.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/6PwXmHcmEa.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/KcxZe3w9Wf.png?imageView2/2/h/80/w/160"
          />
        </div>
        <div>
          <img
            alt=""
            src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/ILiw9lICGt.png?imageView2/2/h/80/w/160"
          />
        </div>
      </Grid>

      <div style={{ textAlign: "center", paddingBottom: 100, marginTop: 40 }}>
        <span style={{ color: "#333", fontWeight: 600 }}>
          更多品牌正在加入中
        </span>
      </div>
    </>
  );
};
