export const OFFSET_LIMIT = 30;

export const appname = "jieligo";
// export const appname = 'bushubao'

console.log(process.env.NODE_ENV);

export const QQ_MAP_KEY = "UL7BZ-EJCKP-CIQD3-LUJUC-OLQ2F-26BFS";

export const authkey = "1IUNQug7BiSdtoF27Vx9ljNSXKid3T8W";
export const platform = "h5";

export const UnImageSrc =
  "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/uD7s5UdrWw.png";

export const API = (() => {
  let state = "";
  if (process && process.env && process.env.NODE_ENV)
    state = process.env.NODE_ENV;
  const { host, port } = window.location;
  // if (host === 'www.jieligo.net') return 'https://test.groupai.net'
  if (host === "www.jieligo.net") return "https://api.jieligo.net";
  if (host === "jieligo-15d47a-1259536745.tcloudbaseapp.com")
    return "https://test.groupai.net";
  // if (host === 'jieligo-15d47a-1259536745.tcloudbaseapp.com') return 'https://test.jieligo.net'
  if (port === "3001") {
    // return 'https://api.jieligo.net'
    // return 'https://dev.jieligo.net'
    // return 'http://www.tuan.com/index.php'
    // return 'https://test.jieligo.net'
    // return 'https://test.groupai.net'
  }
  switch (state) {
    case "development":
      // return 'https://api.jieligo.net'
      // return 'https://dev.jieligo.net'
      // return "https://test.jieligo.net"; // 测试数据库
      return "https://test.groupai.net";
    // return 'https://api.jieligo.net'
    // return 'http://www.tuan.com/index.php'
    // eslint-disable-next-line no-fallthrough
    default:
      return "https://api.jieligo.net";
  }
})();

export const DEFAULT_LOGO =
  "https://jieligo-1259536745.picgz.myqcloud.com/uploads/icbc/IIUOQ8hHqY.png?imageView2/5/w/200";
