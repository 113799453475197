import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router';
import { get } from '../../netApi/fetch';
import { Table } from '../../StyleComponents/PrintTable';
import Loading from '../Method/Loading';
import { message } from 'antd';
import styled from 'styled-components';

const Button = styled.button`
    border: 1px solid var(--theme-main);
    background: var(--theme-main);
    color: #fff;
    transition: all .2s;
    border-radius: 4px;
    padding: 4px 11px;
    margin-left: 16px;
    margin-bottom: 12px;
    position: absolute;
    right: 5%;
    top: 20rpx;
    :hover{
        background: #fff;
        color: var(--theme-main);
    }
    @media print{
        display: none;
    }
`
interface Match extends match {
    params: {
        tuanid: string,
    }
}

interface Props extends RouteComponentProps {
    user: LoginUserData,
    match: Match
}
interface data {
    num: number,
    income: string,
    rebates: string,
    sid: number | string,
    name: string,
}

const mapStateToProps = (state: any,) => {
    return {
        user: state.login_user_data
    }
}

const Print = function (props: Props) {

    const [list, setList] = useState<Array<data>>([])
    const [title, setTitle] = useState('')
    const [total_income, setIncome] = useState<number>()
    const [total_rebates, setRebates] = useState<number>()
    const getted = useRef(false)

    useEffect(() => {
        if (props.user.token && getted.current === false) {
            init()
        }
    })

    function init() {
        Loading.show()
        getted.current = true
        get('/tuan/view', { id: encodeURIComponent(props.match.params.tuanid), types: 1 }).then((res: any) => {
            setTitle(res.title)
        })
        get('/pcorder/exportdatabyfriend', { tuanid: encodeURIComponent(props.match.params.tuanid), types: 1, op: 'print' }).then((res: any) => {
            Loading.hide()
            setIncome(res.total_income)
            setRebates(res.total_rebates)
            setList((res.list as Array<any>).map((item: any): data => {
                return {
                    num: Number(item.num),
                    income: item.income,
                    rebates: item.rebates,
                    sid: Number(item.sid),
                    name: item.name,
                }
            }))
        }).catch((e) => {
            Loading.hide()
            if (e.message) message.error(e.message, 0)
        })
    }

    return <div>
        {list.length > 0
            ? <Button className='cur-pointer' onClick={window.print}>打印</Button>
            : null}
        <Table>
            <thead>
                <tr><td colSpan={4}>{title}</td></tr>
                <tr>
                    <td style={{
                        borderWidth: 1, borderStyle: 'solid', borderColor: '#000', width: '4em'
                    }}></td>
                    <td style={{
                        borderWidth: 1, borderStyle: 'solid', borderColor: '#000'
                    }}>名称</td>
                    <td style={{
                        borderWidth: 1, borderStyle: 'solid', borderColor: '#000'
                    }}>本期收入</td>
                    <td style={{
                        borderWidth: 1, borderStyle: 'solid', borderColor: '#000'
                    }}>发放佣金</td>
                </tr>
            </thead>
            <tbody>
                {list.map((item: data, idx: number) => {
                    return <tr key={item.sid}>
                        <td style={{ width: '4em' }}>{idx + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.income}</td>
                        <td>{item.rebates}</td>
                    </tr>
                })}

            </tbody>
        </Table>
        <Table>
            <thead>
                <tr>
                    <td style={{ border: '1px solid #000' }}></td>
                    <td style={{ border: '1px solid #000' }}>总收入</td>
                    <td style={{ border: '1px solid #000' }}>总佣金</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>汇总信息</td>
                    <td>{total_income}</td>
                    <td>{total_rebates}</td>
                </tr>
            </tbody>
        </Table>
    </div>
}

export default connect(mapStateToProps)(Print)