
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
// import axios from 'axios';

import { message } from 'antd';

import Modal from '../../total/Modal';

// import { OFFSET_LIMIT, appname } from '../../../data/options';
import api from '../../../netApi';
import { OFFSET_LIMIT } from '../../../data/options';

const type = [
    { name: '公开', showList: false, see_type: 1 },
    { name: '私密', showList: false, see_type: 2 },
    { name: '部分可见', showList: true, see_type: 3 },
    { name: '不给谁看', showList: true, see_type: 4 },
]

class Tag extends Component {

    offset = 0

    constructor(props) {
        super()
        
        this.state = {
            list: [],
            fanlist: [],
            searchFan: '',
            see_type: props.initData.see_type,
            selectedTag: {
                3: new Set(),
                4: new Set(),
            }
        }

        if (props.initData.see_type === 3 || props.initData.see_type === 4) {
            this.state.selectedTag[props.initData.see_type] = new Set(props.initData.tag.map(i => i.id))
        }
        
    }

    componentDidMount() {
        this.getTaglist()
    }



    render() {
        return (
            <Modal title='谁可以看' cancel={true} style={{ content: { maxHeight: 600, width: 400, height: 300, overflow: 'auto' } }} close={() => {
                this.props.onClose()
            }} confirm={() => {
                const data = {}
                data.see_type = this.state.see_type
                data.tag = []
                if (data.see_type === 3 || data.see_type === 4) {
                    data.tag = this.state.list.filter(i => this.state.selectedTag[data.see_type].has(i.id))
                    if (data.tag.length === 0) {
                        message.error('请至少选择一个标签')
                        return
                    }
                }

                this.props.onConfirm(data)
            }} >
                <div>
                    <div className='cur-pointer edittuan__tag__add' onClick={() => {
                        this.setState({
                            editTag: true,
                            editTagData: {
                                name: '',
                                member: [],
                            }
                        })
                    }}>添加标签</div>
                    {type.map(item => {
                        return (
                            <div key={item.see_type}>
                                <div className='fcc edittuan__tag__type cur-pointer' onClick={() => {
                                    this.setState({ see_type: item.see_type })
                                }}>
                                    <div><i style={{ color: this.state.see_type === item.see_type ? '#fd5156' : '#eee' }} className={`iconfont ${this.state.see_type === item.see_type ? 'icon-xingzhuangkaobei' : 'icon-yuan'}`} /></div>
                                    <div style={{ userSelect: 'none' }}>{item.name}</div>
                                </div>
                                {(item.showList && this.state.see_type === item.see_type) ? (
                                    <div>
                                        {this.state.list.map(tag => {
                                            return (
                                                <div key={tag.id} className='fcc edittuan__tag__item cur-pointer' onClick={() => {
                                                    const selectedlist = this.state.selectedTag[item.see_type]
                                                    if (selectedlist.has(tag.id)) {
                                                        selectedlist.delete(tag.id)
                                                    } else {
                                                        selectedlist.add(tag.id)
                                                    }
                                                    this.setState({ selectedTag: this.state.selectedTag })
                                                }}>
                                                    <div><i style={{ color: this.state.selectedTag[Number(item.see_type)].has(tag.id) ? '#fd5156' : '#eee' }} className={`iconfont ${this.state.selectedTag[Number(item.see_type)].has(tag.id) ? 'icon-duoxuan1' : 'icon-check-box'}`} /></div>
                                                    <div style={{ flex: 1 }}>{tag.name}</div>
                                                    <div>
                                                        <span style={{minWidth: '3em', display: 'inline-block'}} onClick={(e) => {
                                                            e.stopPropagation()
                                                            let del = window.confirm('是否删除该标签')
                                                            if(del){
                                                                api('tag','update')({}, 'post', {
                                                                    sid: this.props.user.self_sid,
                                                                    id: tag.id,
                                                                    status: 0,
                                                                    name: tag.name,
                                                                    text: tag.text,
                                                                    types: 1,
                                                                }).then(res=>{
                                                                    if(Number(res.data.code) === 0){
                                                                        this.getTaglist()
                                                                    }
                                                                })
                                                            }
                                                        }}>删除</span>
                                                        <span style={{minWidth: '3em', display: 'inline-block'}} onClick={e => {
                                                            e.stopPropagation()
                                                            api('tag', 'getview')({
                                                                types: 1,
                                                                sid: this.props.user.self_sid,
                                                                id: window.btoa(tag.id)
                                                            }).then(res => {
                                                                this.setState({
                                                                    editTagLoading: false,
                                                                    editTagData: {
                                                                        name: res.data.data.name,
                                                                        member: res.data.data.member,
                                                                        id: res.data.data.id
                                                                    }
                                                                })
                                                            })
                                                            console.log(tag)
                                                            this.setState({
                                                                editTag: true,
                                                                editTagData: {
                                                                    name: '',
                                                                    member: []
                                                                },
                                                                editTagLoading: true
                                                            })
                                                        }}>编辑</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        )
                    })}
                </div>
                {this.state.editTag ? <Modal style={{
                    content: { width: 600, height: 450 }
                }} title={this.state.editTagData.name || '添加标签'} close={() => { this.setState({ editTag: false, editTagData: null }) }} confirm={() => {
                    const data = {}
                    data.name = this.state.editTagData.name
                    data.text = this.state.editTagData.member.map(i => i.uid)
                    data.sid = this.props.user.self_sid
                                        
                    if (data.text.length === 0) {
                        message.error('请选择成员')
                        return
                    }
                    if (!data.name || data.name === '') {
                        message.error('请填写标签名称')
                        return
                    }

                    if(!this.state.editTagData.id){
                        api('tag', 'create')({}, 'post', {
                            ...data, types: 1,
                        }).then(res => {
                            if (res.status === 200) {
                                this.setState({ editTag: false })
                                this.getTaglist()
                            }
                        })
                    }else if(this.state.editTagData.id){
                        data.id = this.state.editTagData.id
                        data.status = 1
                        api('tag', 'update')({}, 'post', {
                            ...data, types: 1,
                        }).then(res => {
                            if (res.status === 200) {
                                this.setState({ editTag: false })
                                this.getTaglist()
                            }
                        })
                    }
                }} loading={this.state.editTagLoading} cancel={true} >
                    <div className='edittuan__tag__edit fbc'>
                        <div>
                            <div>粉丝列表:</div>
                            <label><input value={this.state.searchFan} placeholder='搜索粉丝' onChange={e => {
                                this.setState({ searchFan: e.target.value, fanlist: [] }, () => {
                                    this.offset = 0
                                    this.count = undefined
                                    this.getFanlist()
                                })
                            }} /></label>
                            <div className='edittuan__tag__edit__fanlist' onScrollCapture={(e) => {
                                const offset = e.target.scrollHeight - e.target.scrollTop
                                if (offset < 600) {
                                    this.getFanlist()
                                }
                            }}>
                                {this.state.fanlist.map(fan => {
                                    if (this.state.editTagData.member.map(i => i.uid).includes(fan.uid)) {
                                        return null
                                    }
                                    return <div key={fan.uid} className='edittuan__tag__edit__fanlist__item cur-pointer select-hover fbc' onClick={() => {
                                        this.setState({
                                            editTagData: {
                                                ...this.state.editTagData,
                                                member: [...this.state.editTagData.member, fan]
                                            }
                                        })
                                    }}>
                                        <img alt='头像' src={fan.logo} />
                                        <div>
                                            {fan.name}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div>
                            <label><input value={this.state.editTagData.name} maxLength={10} placeholder='请输入标签名字' onChange={e => {
                                this.setState({
                                    editTagData: {
                                        ...this.state.editTagData,
                                        name: e.target.value,
                                    }
                                })
                            }} /></label>
                            <div>标签成员:</div>
                            <div className='edittuan__tag__edit__fanlist'>
                                {this.state.editTagData.member.map(fan => {
                                    return <div key={fan.uid} className='edittuan__tag__edit__fanlist__item cur-pointer select-hover fbc' onClick={() => {
                                        this.setState({
                                            editTagData: {
                                                ...this.state.editTagData,
                                                member: this.state.editTagData.member.filter(i => i.uid !== fan.uid)
                                            }
                                        })
                                    }}>
                                        <img alt='头像' src={fan.logo} />
                                        <div>
                                            {fan.name}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </Modal> : null}
            </Modal>
        )
    }

    getFanlist() {
        if (this.offset >= this.count && this.count !== undefined) return
        if (this.fanlistLoading) return
        this.fanlistLoading = true
        api('tag', 'getfollowlistbytag')({
            tagid: '',
            search: this.state.searchFan || '',
            offset: this.offset,
            limit: OFFSET_LIMIT,
            types: 1,
        }).then(res => {
            const data = res.data.data
            this.offset += data.list.length
            this.count = Number(data.count)
            this.setState({
                fanlist: this.state.fanlist.concat(data.list)
            })
            this.fanlistLoading = false
        })
    }

    getTaglist() {
        api('tag', 'getlist')({
            sid: this.props.self_sid,
            offset: 0,
            limit: 500000,
            types: 1,
        }).then(res => {
            const data = res.data.data
            this.setState({
                list: data.data
            }, this.getFanlist)

        })
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data
    }
}

export default connect(mapStateToProps)(withRouter(Tag))