import { useState, useEffect } from "react"
import React from 'react';
import Modal from "../../total/Modal";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { get, getToken, post } from "../../../netApi/fetch";
import QRCode from 'qrcode.react';
let confirmWrap: Element

interface member {
    description: string
    id: string
    level_name: string
    logo: string
    slogo: string
    member_amount_list: amountItem[]
    member_rule_list: {
        desc: string
        logo: string
        member_level_id: string
        title: string
        value: string
    }[]
}

interface amountItem {
    convert_to_month: string
    member_amount_id: string
    now_charge_amount: string
    number_of_months: string
    original_charge_amount: string
}


const Shadow = styled.div`
    position: fixed;
    left: 0; top: 0;
    width: 100vw; height: 100vh;
    background: #66666666;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Panel = styled.div`
    width: 780px;
    height: 600px;
    border-radius: 6px;
    background-color: #fff;
`

const BG = styled.div`
    background: linear-gradient(315deg,#dc9b48 3%, #fbf5d5);
`

const Title = styled(BG)`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #411E08;
    padding: 0 20px;
    border-radius: 6px 6px 0 0;
    img{
        width: 36px; height: 36px;
        border-radius: 50%;
        margin-right: 10px;
    }
`

const Table = styled.table`
    margin: 0 12px;
    border-collapse: separate;
    border-spacing: 0;
    tr:last-child:not(:only-child) td:first-child {
        border-bottom-left-radius: 4px;
    }

    tr:last-child:not(:only-child) td:last-child {
        border-bottom-right-radius: 4px;
    }

    tr:first-child:only-child td:first-child {
        border-top-left-radius: 4px;
    }

    tr:first-child:only-child td:last-child {
        border-top-right-radius: 4px;
    }

    tr:only-child:first-child{
        td{border-top: 1px solid #ccc;}
    }

    td{
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
    td:last-child{
        border-right: 1px solid #ccc;
    }
    td.vip_title{
        padding-top: 12px;
        .vip_name{
            font-size: 18px;
            font-family: Helvetica, Helvetica-Bold;
            font-weight: 700;
            text-align: center;
            color: #333333;
            margin-bottom: 10px;
        }
        .vip_button{
            width: 110px;
            height: 24px;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #333333;
            margin-bottom: 10px;
            border-radius: 12px;
            cursor: pointer;
        }
        .vip_content{
            color: #999;
            font-size: 12px;
            text-align: center;
            height: 34px;
            margin-bottom: 12px;
        }
    }
    tbody{
        td{
            text-align: center;
            font-size: 12px;
            color: #333;
            padding: 6px 0;
            white-space: pre-line;
        }
    }
`

export function M2G(source: number): string {
    if (source > 1024) return (source / 1024).toFixed(2) + 'G'
    return source.toFixed(2) + 'M'
}

export default function (props: {
    // user: user
}) {
    return new Promise((res, rej) => {
        function Power(p) {
            const [data, setData] = useState<member[]>()
            const [user, setUser] = useState<shopinfo>()
            useEffect(() => {
                if (data) return
                get('/charge/getmemberlevelview', { show_style: 2 }).then(res => {
                    setData(res.member_list)
                    setUser(res.shopinfo)
                })
            }, [data])

            const [list, setList] = useState<string[]>()

            useEffect(() => {
                if (!data) return
                const item = data[0];
                const S = item.member_rule_list.map(i => {
                    return i.title
                })
                setList(S)
            }, [data])

            const [code, setcode] = useState()
            useEffect(() => {
                if(code)return
                async function getMessageCode() {
                    try {
                        const { service_sid } = await get('/chat/getservicesid', {})
                        const { image } = await post('/user/getwxacode?app=jieligo', {
                            width: 200,
                            page: 'message/pages/message/home',
                            scene: `id=${service_sid}`,
                        })
                        setcode(image)
                    } catch (error) {

                    }
                }
                getMessageCode()
            }, [code])
            const showCode = useState(false)
            const qrcodeSrc = useState('')
            if (!user) return <></>
            return <Shadow onClick={e => {
                e.stopPropagation()
                reject()
            }}>
                <Panel onClick={e => { e.stopPropagation() }}>
                    <Title>
                        <div className='flex-box'>
                            <img src={user.logo} className='img-center' alt='' />
                            <div style={{ marginRight: 10, fontSize: 18, fontWeight: 600 }}>{user.name}</div>
                            <div style={{ marginRight: 20, }}>{user.member_level_info.level_name}</div>
                            <div className="flex-between cur-pointer" onClick={() => {
                                showCode[1](true)
                            }}>
                                <div className='flex-center' style={{
                                    width: 30,
                                    height: 30,
                                    background: '#fbf5e9',
                                    borderRadius: '50%',
                                    marginRight: 5,
                                }}>
                                    <i style={{ color: '#411E08', lineHeight: 1, }} className="iconfont icon-lianxikefu1"></i>
                                </div>
                                <div style={{}}>联系客服</div>
                            </div>
                        </div>
                        <div className='cur-pointer' onClick={reject}>
                            <i className="iconfont icon-cha"></i>
                        </div>
                    </Title>
                    <div className="flex-center" style={{ height: 45, fontSize: 22, fontWeight: 600, }} >VIP权限对比</div>
                    <Table>
                        <thead>
                            <tr>
                                {data?.map((i, idx) => {
                                    return <td key={idx} className='vip_title' style={{ width: 148 + (idx === 0 ? 12 : 0), }}>
                                        <div className="flex-box" style={{ flexDirection: "column" }} >
                                            <div className='vip_name'>{i.level_name}</div>
                                            {Number(i.id) !== -1 && <>
                                                {i.member_amount_list.length !== 0
                                                    ? <BG className='vip_button flex-center' onClick={() => {
                                                        qrcodeSrc[1](`https://plus.jieligo.net/plus?token=${getToken()}&viptype=${i.id}`)
                                                    }} >立即{(user.member_level_info && (Number(user.member_level_info.level_type) === 5)) ? '续费' : '升级'}</BG>
                                                    : <div style={{ height: 24, marginBottom: 10 }}></div>}
                                                <div className='vip_content flex-center'  >{i.slogo}</div>
                                            </>}
                                        </div>
                                    </td>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map((i, idx) => {
                                return <tr key={idx}  >
                                    {data.map((j, jidx) => {
                                        let value = j.member_rule_list[idx]?.value
                                        if (value?.length > 13) value = value.replace('（', '\n（')
                                        if (value === 'false' || value === 'true') {
                                            return <td key={jidx}><i style={{ color: '#DFA949', fontSize: 12, }} className={`iconfont ${value === 'true' ? 'icon-dagou' : 'icon-dacha'} `} /></td>
                                        }
                                        return <td key={jidx}>{value}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <div className="flex-center" style={{ fontSize: 12, color: '#333', marginTop: 8 }}>温馨提示：付款后请刷新页面</div>
                </Panel>
                {
                    !!qrcodeSrc[0] && <Modal showConfirm={false} onClose={qrcodeSrc[1].bind(0, '')} >
                        <div style={{ width: 400, height: 310 }}>
                            <div style={{ fontSize: 18, color: '#333', textAlign: "center" }}>用手机扫描下方二维码</div>
                            <div style={{ fontSize: 18, color: '#333', textAlign: "center", marginBottom: 20 }}>进入升级付费页面</div>
                            <QRCode style={{ margin: '0 auto', display: "block" }}
                                level='H' renderAs='svg' size={170} imageSettings={{
                                    src: 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/Z9KKNZk366.png',
                                    height: 40,
                                    width: 40,
                                }}
                                value={qrcodeSrc[0]} />
                        </div>
                    </Modal>
                }
                {showCode[0] && <Modal title='联系客服' showConfirm={false} onClose={showCode[1].bind(false, false)} >
                    <div className="flex-center" style={{ marginBottom: 10, fontWeight: 600 }}>手机微信扫描下方小程序码</div>
                    <div className="flex-center" style={{ marginBottom: 20, fontWeight: 600 }}>立即联系客服</div>
                    <div className="flex-center" style={{ width: 300, marginBottom: 40 }}>
                        <img src={code} alt="" style={{ width: 238, height: 238 }} className="img-center" />
                    </div>
                </Modal>}
            </Shadow >

            function reject() {
                res(false)
                ReactDOM.render(<></>, confirmWrap)
            }
        }




        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Power {...props} />, confirmWrap)
    })
}