import React, { Component } from 'react'
import styled from 'styled-components';
import { message } from 'antd';
import { get, post } from '../../netApi/fetch';
import { confirm } from '../Method/Confirm';
import { followListItem, level } from './List';

interface Props {
    data: followListItem,
    leverList?: level[],
    reload?: () => void,
    sid: number | string,
    isMyself: boolean
}

interface State {
    showMenu: boolean,
    showConfirmBlackList: boolean,
    showConfirmCanSale: boolean,
    showConfirmAdmin: boolean,
    showChangeShopgradeuser: boolean,
    classList?: {
        id: string | number,
        discount: string,
        name: string,
    }[],
    classThis?: {
        id: string | number,
    }
    data?: followListItem,
}

const Wrap = styled.div`
    font-size: 12px;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    &:hover{
        background: #f8f8f8;
    }
    .followItemContent{
        .flex-box{
            margin-bottom: 10px;
            &>div{
                margin-right: 16px
            }
        }
    }
    .tag{
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        padding: 4px 11px;
    }
`
const Name = styled.div`
    font-size:16px;
    font-weight:600;
    color:rgba(51,51,51);
`

const Logo = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 16px;
`

const Ctrl = styled.div`
    position: absolute;
    right: 0;top: 0;
    height: 70px;
    width: 70px;
    i{
        line-height: 1;
        font-size: 20px;
    }
`
const Menu = styled.div`
    transition: all .3s;
    box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);
    position: absolute;
    right: 20px;
    transform-origin: top center;
    top: 50px;
    width: 180px;
    background: white;
    overflow: hidden;
    z-index: 10;
    div{
        padding: 14px 40px;
        font-size: 14px;
        color: #333;
        text-align: center;
        user-select: none;
        &:hover{
            background: #f8f5f5;
        }
    }
`

class Item extends Component<Props> {
    readonly state: State = {
        showMenu: false,
        showConfirmBlackList: false,
        showConfirmCanSale: false,
        showConfirmAdmin: false,
        showChangeShopgradeuser: false,
    }

    // componentDidMount(){
    //     window.addEventListener('click', this.menuHandle)
    // }
    // componentWillUnmount(){
    //     window.removeEventListener('click', this.menuHandle)
    // }
    // menuHandle = this.closeMenu.bind(this)

    constructor(p: Props){
        super(p)
        this.state.data = p.data
    }

    render() {
        const { data } = this.state
        let userlevel
        if (this.props.leverList) {
            userlevel = this.props.leverList.find((i: level) => data.shopgradeuserclassid === i.id)
        }
        return <>
            <Wrap className='flex-between flex-top' onClick={this.closeMenu.bind(this)} onMouseLeave={this.closeMenu.bind(this)} >
                <Logo src={data.shopinfo.logo + '?imageView2/5/w/170/h/170'} />
                <div className='flex1 followItemContent'>
                    <div className='flex-box'>
                        <Name>{data.shopinfo.name}</Name>
                        {data.ismyadmin ? <div className='tag' style={{
                            color: 'var(--theme-main)',
                            borderColor: 'var(--theme-main)'
                        }}>
                            管理员
                        </div> : null}
                        {data.ismyseller ? <div className='tag' style={{
                            color: '#FFA92C',
                            borderColor: '#FFA92C'
                        }}>
                            帮卖好友
                          </div> : null}
                          {data.isblacklist ? <div className='tag' style={{
                            color: '#999',
                            borderColor: '#999'
                        }}>
                            黑名单
                          </div> : null}
                        {userlevel ? <div className='tag' style={{
                            color: '#C57D00',
                            borderColor: '#C57D00'
                        }}>
                            {userlevel.name}
                        </div> : null}
                    </div>
                    <div className='flex-box'>
                        <div>
                            浏览次数{data.views_total}
                        </div>
                        <div>
                            参团次数{data.join_total}
                        </div>
                        <div>
                            消费金额￥{(Number(data.payprice_total) / 100).toFixed(2)}
                        </div>
                    </div>
                    <div>{data.info}</div>
                </div>
                <Ctrl className='flex-center cur-pointer' onClick={(e) => {
                    e.stopPropagation()
                    this.setState({ showMenu: !this.state.showMenu })
                }}>
                    <i className='iconfont icon-gengduo' />
                    <Menu style={{
                        maxHeight: this.state.showMenu ? 220 : 0,
                    }}>
                        {/* <div onClick={this.clickMenu.bind(this, 1)}>升级会员</div> */}
                        <div onClick={this.clickMenu.bind(this, 2)}>{data.ismyseller ? '取消该好友帮卖' : '允许好友帮卖'}</div>
                        <div onClick={this.clickMenu.bind(this, 3)}>{!data.isblacklist ? '加入黑名单' : '取消黑名单'}</div>
                        {this.props.isMyself ? <div onClick={this.clickMenu.bind(this, 4)}>{data.ismyadmin ? '取消管理员' : '设为管理员'}</div> : null}
                    </Menu>
                </Ctrl>
            </Wrap>
        </>
    }
    clickMenu(i: number) {
        const { data } = this.state
        switch (i) {
            case 1:
                get('/shopgradeuser/view', {
                    sid: this.props.sid,
                    uid: 1
                }).then((res: any) => {
                    const classList = res.user_class_new.filter((i: any) => Number(i.status) === 1).map((item: any) => {
                        return {
                            id: item.id,
                            discount: (item.discount * 10) + '折',
                            name: item.name,
                        }
                    })
                    this.setState({
                        classList,
                        classThis: {
                            id: res.info.classid,
                        },
                        showChangeShopgradeuser: true,
                    })
                })
                break;
            case 2:
                confirm({
                    text: `确认${data.ismyseller ? '取消' : '允许'}${data.shopinfo.name}帮卖`
                }).then(res => {
                    if (!res) return
                    this.toggleCansale()
                })
                break;
            case 3:
                confirm({
                    text: !data.isblacklist ? `加入黑名单后，${data.shopinfo.name}将无法看到您发布的所有信息` : `取消黑名单后，${data.shopinfo.name}将可以看到您发布的所有信息`
                }).then(res => {
                    if (!res) return
                    this.toggleBlackList()
                })
                break;
            case 4:
                confirm({
                    text: data.ismyadmin ? `取消${data.shopinfo.name}的管理员权限` : `设置${data.shopinfo.name}为管理员`
                }).then(res => {
                    if (!res) return
                    this.toggleAdmin()
                })
                break;
            default:
                break;
        }
    }
    closeMenu() {
        this.setState({ showMenu: false })
    }

    toggleAdmin() {
        if (!this.state.data) return
        const { uid } = this.state.data.shopinfo
        if (!this.state.data.ismyadmin) {
            post('/shopadministrator/createbyfensi', {
                types: 1,
                uid
            }).then(() => {
                message.success('设置成功')
                this.props.reload && this.props.reload()
            })
        } else {
            if (!this.props.sid) return
            post('/shopadministrator/delete', {
                types: 1,
                uid,
                sid: this.props.sid,
            }).then(() => {
                message.success('取消成功')
                this.props.reload && this.props.reload()
            })
        }
    }

    toggleCansale() {
        if (!this.state.data) return
        const { uid } = this.state.data.shopinfo
        if (!this.state.data.ismyseller) {
            post('/seller/createbyfensi', {
                uid
            }).then(() => {
                message.success('设置成功')
                const {data} = this.state
                data.ismyseller = true
                this.setState({
                    data: this.state.data
                })
                // this.props.reload && this.props.reload()
            })
        } else {
            post('/seller/delete', {
                uid,
            }).then(() => {
                message.success('取消成功')
                const {data} = this.state
                data.ismyseller = false
                this.setState({
                    data: this.state.data
                })
                // this.props.reload && this.props.reload()
            })
        }
    }

    toggleBlackList() {
        if (!this.state.data) return
        const { uid } = this.state.data.shopinfo
        if (!this.state.data.isblacklist) {
            post('/blacklist/create', {
                types: 1,
                uid
            }).then(() => {
                message.success('设置成功')
                this.props.reload && this.props.reload()
            })
        } else {
            post('/blacklist/delete', {
                types: 1,
                uid,
            }).then(() => {
                message.success('取消成功')
                this.props.reload && this.props.reload()
            })
        }
    }
}

export default Item