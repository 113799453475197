import React, { Component } from 'react'

class Switch extends Component {

    static defaultProps = {
        width: 44,
        height: 22,
        color: 'var(--theme-main)',
        margin: 2,
        checked: false,
        disabled: false,
    }

    constructor() {
        super()
        this.state = {}
    }

    componentDidUpdate(prev) {
        if (prev.checked !== this.props.checked) {
            this.setState({
                boxShadow: `0 0 0 1px ${this.props.checked ? this.props.color : 'rgba(0,0,0,0.25)'}`
            })
            setTimeout(() => {
                this.setState({
                    boxShadow: '0 0 0 1px rgba(0,0,0,0)'
                })
            }, 180)
        }
    }

    render() {
        return (
            <div className='cur-pointer'
                style={{
                    position: 'relative',
                    transition: 'all .36s',
                    background: this.props.checked ? this.props.color : 'rgba(0,0,0,0.25)',
                    borderRadius: this.props.height / 2,
                    height: this.props.height,
                    width: this.props.width,
                    margin: this.props.margin,
                    cursor: this.props.disabled ? 'not-allowed' : 'pointer',
                    boxShadow: this.state.boxShadow,
                    ...this.props.style,
                }}
                onClick={() => {
                    if (this.props.disabled) return
                    this.props.onClick && this.props.onClick()
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        height: this.props.height,
                        width: this.props.height,
                        background: '#fff',
                        borderRadius: this.props.height / 2,
                        top: 0,
                        left: this.props.checked ? (this.props.width - this.props.height) : 0,
                        transition: 'all .36s',
                    }}></div>
            </div>
        )
    }
}

export default Switch