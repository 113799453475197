import React, { useEffect, useState, } from 'react';
import { UnImageSrc } from '../../data/options';
import { good as goodInterface } from './datalist';
import styled from 'styled-components';
import { confirm } from '../Method/Confirm';
import joinEvent from './method/joinEvent';
import UpToMall from './method/UpToMall';
import Prompt from '../Method/Prompt';
import startSale from './method/startSale';
import { post } from '../../netApi/fetch';
import { Checkbox, message, } from 'antd';
import { NavLink } from 'react-router-dom';
import Toast from '../Method/Toast';
import simpleCreateGood from './method/simpleCreateGood';

const Item = styled.tr`
    position: relative;
    td{
        padding: 20px 0 0;
        vertical-align: top;
    }
    td.name{
        img{
            width: 60px;height: 60px;
            margin-right: 12px;
        }
        span{
            width: 168px;
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            word-break: break-all;
            max-width: 400px;
            /* overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; */
        }
    }
    td:last-child{
        height: 100%;
        div div{
            cursor: pointer;
            color: var(--theme-main);
            font-weight: 600;
            padding: 0 8px;
            &:not(:last-child){
                border-right: 1px solid #ccc;
            }
        }
    }
    &:hover{
        background-color: #f7f7f7;
        & + tr{
            background-color: #f7f7f7;
            opacity: 1;
        }
    }
    & + tr{
        opacity: 0;
        &:hover{
            opacity: 1;
        }
    }
`
const Tip = styled.div`
    display: none;
    div:hover>&{
        display: block;
        height: 60px;
        width: 207px;
        position: absolute;
        top: -66px;
        right: 0;
        border-radius: 4px;
        padding: 7px 11px !important;
        color: #fff !important;
        font-weight: normal !important;
        background: #333;
        box-sizing: border-box;
        &::before{
            content: '';
            position: absolute;
            right: 20px;
            bottom: -6px;
            height: 0;
            width: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #333;
        }
    }
`

const CtrlSpan = styled.div`
    margin-left: 8px;
    background-color: #E1E1E1;
    border-radius: 2px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
    padding: 0 4px;
    * {
        background-color: #E1E1E1;
        color: #666;
    }
    &:hover{
        *{
            background-color: #666;
            color: #e1e1e1;
        }
        background-color: #666;
        color: #e1e1e1;
    }
`

function GoodItem(props: { data: goodInterface, type: number, eventList: any[], reload: () => void, isBranch?: boolean, checked?: boolean, onCheck?: (checked: boolean) => void }) {
    const [good, setGood] = useState<goodInterface>(props.data)
    const [moreAttr, showMoreAttr] = useState(false)
    const max = Math.max(...good.attribute.map(i => Number(i.price) || 0))
    const min = Math.min(...good.attribute.map(i => Number(i.price) || 0))
    if (isNaN(max) || isNaN(min)) {
        props.reload()
    }
    useEffect(() => {
        setGood(props.data)
    }, [props.data])
    const [hide, sethide] = useState(false)
    if (hide) return null
    return <>
        <Item>
            {props.type === 4 && <td style={{ width: 36, paddingLeft: 10, }} onClick={() => {
                props.onCheck && props.onCheck(!props.checked)
            }}>
                <Checkbox checked={props.checked} />
            </td>}
            {/* 名称 */}
            <td rowSpan={2} className='name' style={{ borderBottom: '.5px #eee solid' }} >
                <div style={{ display: 'flex', alignItems: 'flex-start', paddingLeft: 5, paddingBottom: 12 }}>
                    <img className='img-center' src={(good.logo || UnImageSrc) + '/sm'} alt="" />
                    <span>
                        <div>
                            {good.name}
                        </div>
                        {(props.type === 3 || props.type === 2) && <div className='flex-center' style={{
                            height: 18, borderRadius: 2, color: '#fff', fontSize: 12, marginTop: 20,
                            background: Number(good.can_buy) === 1 ? 'var(--theme-main)' : '#999',
                            width: Number(good.can_buy) === 1 ? 44 : 68,
                        }}>
                            {Number(good.can_buy) === 1 ? '出售中' : '已暂停出售'}
                        </div>}
                    </span>
                </div>
            </td>
            {/* 规格 */}
            <td style={{}}>
                {good.attribute.map((attr, idx) => {
                    if (idx > 1 && !moreAttr) return null
                    if (good.attribute.length === 1) return <div key={attr.id || idx}>{attr.unit}</div>
                    return <div key={attr.id || idx}>{idx + 1}、{attr.unit}</div>
                })}
                {good.attribute.length > 2 && <div className='cur-pointer' style={{ color: 'var(--theme-main)', }} onClick={() => { showMoreAttr(!moreAttr) }}>
                    {moreAttr ? '收起' : `还有个${good.attribute.length - 2}规格`}
                </div>}

            </td>
            {/* 价格 */}
            <td>
                {props.type !== 1 && <div>
                    {max === min ? `￥${(max / 100).toFixed(2)}` : `￥${(min / 100).toFixed(2)} ~ ￥${(max / 100).toFixed(2)}`}
                </div>}
            </td>
            {/* 排序 */}
            <td>
                {(props.type === 2 || props.type === 3) && <div>
                    <span style={{ color: '#8b8b8b', marginRight: 10 }}>{good.rank}</span>
                    <i style={{ color: '#999' }} onClick={() => {
                        Prompt({
                            type: 'number',
                            value: good.rank,
                            title: '商城中商品将按序号排序',
                        }).then(res => {
                            return post('/mall/setmallgoods', { op: 4, mall_goods_id: good.mall_goods_id, rank: Number(res), goodsid: good.goodsid }).then(res => {
                                message.success('修改成功')
                                props.reload()
                            })
                        }).catch(e => { if (e.message) message.error(e.message) })
                    }} className='iconfont icon-bianji cur-pointer' />
                </div>}
            </td>

        </Item>
        <tr style={{ borderBottom: '.5px #eee solid' }}>
            <td colSpan={4} style={{ paddingBottom: 12 }}>
                {props.type === 1 && <div className='flex-box' style={{ justifyContent: 'flex-end' }}>
                    {/* <div>查看</div> */}
                    <CtrlSpan>
                        <NavLink to={`/index/mall/goods/create?id=${good.id}`}>编辑</NavLink>
                    </CtrlSpan>
                    <CtrlSpan onClick={() => {
                        confirm({ text: `是否删除该商品` }).then(res => {
                            if (res) {
                                post('/warehouse/goodsset', { id: good.id, status: 0, }).then(() => {
                                    message.success('删除成功')
                                    sethide(true)
                                })
                            }
                        })
                    }}>删除</CtrlSpan>
                    <CtrlSpan onClick={() => {
                        if (good.is_on_sale) {
                            message.warn('商品已上架,请勿重新上架')
                            return
                        }
                        UpToMall({
                            ware_house_goods_id: good.id
                        }).then(res => {
                            if (res) {
                                setGood({ ...good, is_on_sale: true })
                            }
                        })
                    }}>{good.is_on_sale ? '上架中' : '上架到商城'}</CtrlSpan>
                </div>}

                {props.type === 2 && <div className='flex-box' style={{ justifyContent: 'flex-end' }}>
                    {/* <div>查看</div> */}
                    <CtrlSpan onClick={() => {
                        if (Number(good.is_multistagele_spec) === 1) {
                            UpToMall(good.mall_goods_id, true, good.mall_goods_id, 'ware', good.goodsid).then(res => {
                                if (res) {
                                    props.reload()
                                }
                            }).catch(() => {
                            })
                        } else {
                            simpleCreateGood(good.mall_goods_id).then((res) => {
                                if (res) {
                                    props.reload()
                                }
                            }).catch()
                        }
                    }}> 编辑 </CtrlSpan>
                    <CtrlSpan onClick={() => {
                        confirm({
                            text: '商品下架后将不对外展示，你仍可在后台商品信息库中找到商品。是否下架该商品？',
                        }).then(res => {
                            if (res) {
                                post('/mall/setmallgoods', { op: 2, mall_goods_id: good.mall_goods_id, goodsid: good.goodsid }).then(() => {
                                    sethide(true);
                                    Toast.show('下架成功')
                                }).catch(e => {
                                    if (e.message) message.error(e.message)
                                })
                            }
                        })
                    }} >下架</CtrlSpan>

                    {!good.is_participate_activity && <CtrlSpan onClick={() => {
                        joinEvent(good, props.eventList).then(res => {
                            if (res) {
                                good.is_participate_activity = 1
                                setGood({ ...good })
                                props.reload()
                            }
                        })
                    }}>参与活动</CtrlSpan>}

                    {!!good.is_participate_activity && <CtrlSpan onClick={() => {
                        confirm({
                            text: `确定把商品“${good.name}”从活动中移除？`
                        }).then(res => {
                            if (res) {
                                post('/mall/removeactivitygoods', { remove_goods_id_list: good.goodsid, activity_id: good.activity_id }).then(() => {
                                    good.is_participate_activity = 0
                                    setGood({ ...good })
                                })
                            }
                        }).catch(e => { if (e.message) message.error(e.message) })
                    }}>从活动中移除</CtrlSpan>}


                    <CtrlSpan style={{ position: "relative" }} onClick={() => {
                        if (Number(good.can_buy) === 0) {
                            startSale(good, () => {
                                props.reload()
                            })
                        } else {
                            const data = {
                                op: 5,
                                mall_goods_id: good.mall_goods_id,
                                goodsid: good.goodsid,
                            }
                            post('/mall/setmallgoods', data).then(res => {
                                Toast.show('已暂停出售')
                                good.can_buy = '0'
                                setGood({ ...good })
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }
                    }}>
                        {Number(good.can_buy) === 0 ? '开启出售' : '暂停出售'}
                        {Number(good.can_buy) !== 0 && <Tip>暂停出售后该商品库存为0，且不在商城中对外展示</Tip>}
                    </CtrlSpan>
                </div>}

                {props.type === 3 && <div className='flex-box' style={{ justifyContent: 'flex-end' }}>
                    {/* <div>查看</div> */}
                    {/* <div>
                    <NavLink to={`/goods/data/create?id=${good.id}`}>编辑</NavLink>
                </div> */}
                    {/* <div onClick={() => {
                    post('/mall/setmallgoods', { op: 2, mall_goods_id: good.mall_goods_id, goodsid: good.goodsid }).then(() => { sethide(true) }).catch(if (e.message) message.error(e.message))
                }} >下架</div> */}
                    {!props.isBranch && !!good.is_participate_activity && <CtrlSpan onClick={() => {
                        confirm({
                            text: `确定把商品“${good.name}”从活动中移除？`
                        }).then(res => {
                            if (res) {
                                post('/mall/removeactivitygoods', { remove_goods_id_list: good.goodsid, activity_id: good.activity_id }).then(() => {
                                    good.is_participate_activity = 0
                                    sethide(true)
                                    setGood({ ...good })
                                })
                            }
                        }).catch(e => { if (e.message) message.error(e.message) })
                    }}>从活动中移除</CtrlSpan>}
                    <CtrlSpan style={{ position: "relative" }} onClick={() => {
                        if (Number(good.can_buy) === 0) {
                            startSale(good, () => {
                                props.reload()
                            })
                        } else {
                            const data = {
                                op: 5,
                                mall_goods_id: good.mall_goods_id,
                                goodsid: good.goodsid,
                            }
                            post('/mall/setmallgoods', data).then(res => {
                                Toast.show('已暂停出售')
                                good.can_buy = '0'
                                setGood({ ...good })
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }
                    }}>
                        {Number(good.can_buy) === 0 ? '开启出售' : '暂停出售'}
                        {Number(good.can_buy) !== 0 && <Tip>暂停出售后该商品库存为0，且不在商城中对外展示</Tip>}
                    </CtrlSpan>
                </div>}

                {props.type === 4 && <div className='flex-box' style={{ justifyContent: 'flex-end' }}>
                    {/* <div>查看</div> */}
                    <CtrlSpan>
                        {/* eslint-disable-next-line react/jsx-no-target-blank*/}
                        <a href={`/good/edit/${btoa(good.goodsid)}`} target='_blank' >编辑</a>
                    </CtrlSpan>
                    <CtrlSpan onClick={() => {
                        confirm({
                            text: `确定要删除商品'${good.name}'吗`
                        }).then(res => {
                            if (res) {
                                return post('/goods/updatestatus', {
                                    action: 'delete',
                                    goodsid: good.goodsid
                                })
                            }
                        }).then(() => {
                            sethide(true)
                            message.success(`成功删除'${good.name}'`)
                        }).catch((e) => {
                            if (e.message) message.error(e.message)
                        })
                    }}>删除</CtrlSpan>
                </div>}
            </td>
        </tr>
    </>
}

export default GoodItem