import React, { Component } from 'react'
import { RouteComponentProps, match, NavLink } from 'react-router-dom'
import Main from '../../high-component/Content'
import BanUnLogin from '../../high-component/BanUnLogin'
import { connect } from 'react-redux'
import { showLoading } from '../../reducers/index'
import styled from 'styled-components';

// components
import Item, { FakeItem } from './item'
import Select from '../total/Select_';
import Export from './exportPanel_'
import Modal from '../total/Modal'
import Search from '../total/Input/Search'
import PrintOrderPanel from './OrderCtrl/PrintOrderPanel';
import { message, Pagination } from 'antd'
import Quick from './OrderCtrl/Quick';
import ExpressMark from './OrderCtrl/ExpressMark';
import MarkHistory from './OrderCtrl/MarkHistory';
//func
import { get, } from '../../netApi/fetch';
import parse from '../../methods/paramParse';

import { OFFSET_LIMIT, API } from '../../data/options';
import BTN from '../../StyleComponents/Button'
const Warp = styled.div`
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
    max-width: 986px;
    height: 100vh;
    padding-bottom: 100px;
    &::-webkit-scrollbar{
        width: 0;
    }
`
const Relative = styled.div`
    position: relative;
`
const EventSelectLabel = styled.label`
    input, i{
        display: none;
    }
`
const SelectedTitle = styled.div`
    color: #454545;
    font-size: 20px;
    width: 9em;
    cursor: pointer;
    font-weight: 600;
    user-select: none;
`
const Page = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    box-shadow:0px -1px 2px 0px rgba(215,215,215,0.5);
    &>div{
        max-width: 956px;
        margin: auto;
    }
`
interface State {
    searchText?: string,
    batch: boolean,
    query: query
    orderlist: order[],
    count: number,
    order_total?: {
        allorder_count: number,
        my_saleorder_total: number,
    },
    tuanlist: {
        id: string,
        title: string,
        pid: string,
        distributionid: string,
        takeway: string,
    }[],
    expressList: {}[],
    showExportPanel: boolean,
    printOrders?: order[],
    loading: boolean,
    isdistribution?: boolean,
    sellerData?: any,

    batch_loading?: boolean,

    ShowPrintOrder?: boolean,

    quickSend?: boolean,
    quickCheckin?: boolean,
    quick_loading?: boolean,
    quickIndex?: number,
    showSelectSendPanel?: boolean,

    selectedSendMode: number,
    showExpressMark: boolean,
    markGoodlist?: {
        name: string;
        id: string | number;
        num: number | string;
        mark_num: number | string;
    }[],
    markid?: number | string;
    showMarkHistory: boolean;
    approvalList: {
        review_op: string;
        name: string;
    }[],
    approvalIndex: number,
}
interface Props extends RouteComponentProps {
    match: Match,
    login_user_data: any,
    showLoading: () => void,
    hideLoading: () => void,
}

interface Match extends match {
    params: {
        query: string
    }
}

interface query {
    search?: string,
    page?: number,
    option?: string,
    tuanid?: string,
    subtuanid?: string,
    orderType?: number,
    distributionType?: number,
    review_op?: string,
}

class OrderList extends Component<Props, State> {

    readonly state: State = {
        searchText: '',
        batch: false,
        query: {
            search: '',
            page: 1,
            option: 'all',
            tuanid: '',
            orderType: 1,
            distributionType: 2,
        },
        orderlist: [],
        tuanlist: [],
        expressList: [],
        count: 0,
        showExportPanel: false,
        loading: false,

        selectedSendMode: 1,

        showExpressMark: false,
        showMarkHistory: false,
        approvalList: [{ review_op: 'for_me', name: '待我审批' }, { review_op: 'for_upper', name: '待上级审批' }, { review_op: 'for_lower', name: '待下级审批' }],
        approvalIndex: 0,
    }

    bc?: BroadcastChannel

    componentDidMount() {
        document.title = '订单列表'
        get('/express/getExpressCompany', {}).then((res: any) => {
            this.setState({ expressList: res })
        })
        this.getTuanList()
        this.getQuery().then(() => this.getData()).catch(e => { if (e.message) message.error(e.message) })

        if (BroadcastChannel) {
            if (!window.BroadcastChannel) return
            this.bc = new BroadcastChannel('orderList')
            this.bc.onmessage = e => {
                console.log(e.data)
                switch (e.data) {
                    case 'reload':
                        this.getData({ loading: false })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    componentWillUnmount() {
        document.title = '接力go'
        this.bc?.close()
    }

    componentDidUpdate(props: Props) {
        if (props.match.params.query === this.props.match.params.query) return
        console.log('get')
        this.setState({ orderlist: [], sellerData: null })
        this.getQuery().then(() => this.getData()).catch(e => {
            if (e.message) message.error(e.message)
            this.props.hideLoading()
        })
    }

    render() {
        const tuanlist = [{ title: '按活动筛选', id: '' }, ...this.state.tuanlist]
        const orderType = [{ title: '我的订单', id: 1 }, { title: '好友订单', id: 2 }]
        const distributionOrderType = [{ title: '按帮卖好友汇总', id: 1 }, { title: '全部好友订单汇总', id: 2 }]
        const SelectedTuanIndex = tuanlist.findIndex((i: { id: string, title: string }) => encodeURIComponent(btoa(i.id)) === this.state.query.tuanid)
        const SelectedTuan = this.state.tuanlist.find((i: { id: string, title: string }) => encodeURIComponent(btoa(i.id)) === this.state.query.tuanid)
        // 是否展示一键xx
        const show = (() => {
            if (!SelectedTuan) return true
            const sc = (Number(SelectedTuan.pid) > 0) // 是否二级
            if (sc) return false
            // 一级
            if (Number(SelectedTuan.distributionid) > 0) {
                // 一级帮卖
                if (Number(SelectedTuan.takeway) === 1) return true
                if (Number(SelectedTuan.takeway) === 2) return false
            }
            return true // 一级不开帮卖
        })()

        // 可一键快递列表
        const quickSendList = this.state.tuanlist.filter(i => (Number(i.takeway) === 1 && Number(i.pid) < 1))

        // 可一键核销列表
        const quickCheckinList = this.state.tuanlist.filter(i => (Number(i.takeway) === 2 && Number(i.distributionid) <= 0))
        return <Relative><Warp>
            <div className='fbc' style={{ flexWrap: 'wrap', padding: '30px 0', borderBottom: '1px solid #f1f1f1' }}>
                {/* 活动筛选 */}
                <EventSelectLabel>
                    <SelectedTitle className='fbc'>
                        <div>{SelectedTuanIndex !== -1 ? tuanlist[SelectedTuanIndex].title : ''}</div>
                        <div style={{ flex: 1 }}><i className='icon-down iconfont' style={{
                            display: 'inline',
                            fontSize: 12,
                        }} /></div>
                    </SelectedTitle>
                    <div>
                        <Select inputStyle={{
                            border: 0, width: '8em',
                        }} maxHeight={578} selectWidth='18em' list={tuanlist} showKey='title' uni='tuan'
                            value={SelectedTuanIndex}
                            onChange={(e, i?: number): void => {
                                if (encodeURIComponent(btoa(e.id)) === this.state.query.tuanid) return
                                this.changeQuery({ tuanid: encodeURIComponent(btoa(e.id)), page: 1, option: 'all', orderType: 1, distributionType: 2, })
                            }} />
                    </div>
                </EventSelectLabel>
                {/* </div> */}

                <div style={{ flex: 1000 }}></div>

                {/* 搜索 */}
                <div style={{ minWidth: 300, textAlign: 'right' }}>
                    <div style={{ width: 300, display: 'inline-block' }}>
                        <Search search={this.state.searchText || ''} onChange={e => {
                            this.setState({ searchText: e })
                        }} onSearch={() => { console.log(164); this.changeQuery({ search: this.state.searchText }) }} placeholder='搜索用户名、手机号、订单号' outerStyle={{ padding: '4px 11px' }} />
                    </div>
                </div>

                {/* 发货 && 导出 */}
                <div className='fbc' style={{ justifyContent: 'flex-end', minWidth: 200, flex: 1 }}>
                    <BTN color='red' solid className=' orderList__export' onClick={() => { this.setState({ showExportPanel: true }) }}>导出数据</BTN>
                </div>
            </div>
            <div className='fcc' style={{ padding: 8, borderBottom: '1px solid #f1f1f1', position: 'relative', justifyContent: 'flex-end', minHeight: 50 }}>
                {/* 切换我的订单好友订单 */}
                {this.state.query.tuanid && this.state.isdistribution ?
                    <div className='fcc' style={{ position: 'absolute', left: 0, }}>
                        <div style={{ marginRight: '1.2em' }}>
                            <Select inputStyle={{
                                borderColor: 'var(--theme-main)', width: '8em', color: 'var(--theme-main)',
                            }} maxHeight={578} selectWidth='12em' list={orderType} showKey='title' uni='ordertype'
                                value={orderType.findIndex((i: { id: number, title: string }) => i.id === this.state.query.orderType)}
                                onChange={(e): void => {
                                    this.changeQuery({ orderType: e.id, subtuanid: this.state.query.tuanid, distributionType: 2 })
                                }} />
                        </div>
                        {this.state.query.orderType === 2 ? <div>
                            <Select inputStyle={{
                                borderColor: 'var(--theme-main)', width: '10em', color: 'var(--theme-main)',
                            }} maxHeight={578} selectWidth='12em' list={distributionOrderType} showKey='title' uni='distritype'
                                value={distributionOrderType.findIndex((i: { id: number, title: string }) => i.id === this.state.query.distributionType)}
                                onChange={(e, i?: number): void => {
                                    if (e.id === this.state.query.distributionType) return
                                    this.changeQuery({ distributionType: e.id, subtuanid: this.state.query.subtuanid || this.state.query.tuanid })
                                }} />
                        </div> : null}

                    </div>
                    : null}

                {/* 状态筛选 */}
                {(this.state.query.distributionType !== 1 || this.state.query.orderType === 1) ? (
                    <div className='fbc' style={{ width: '21em', position: "relative", padding: '8px 0', }}>
                        {[
                            { key: 'all', name: '全部' },
                            { key: 'notuse', name: '未核销/发货' },
                            { key: 'used', name: '已完成' },
                            { key: 'applyrefund', name: '退款中' },
                            { key: 'refundsuccess', name: '已退款' },
                        ].map(i => {
                            const active = { color: 'var(--theme-main)' }
                            return (
                                <div key={i.key} className='fcc cur-pointer' onClick={() => {
                                    this.changeQuery({ option: i.key, page: 1 });
                                }}>
                                    <span style={this.state.query.option === i.key ? active : {}}>{i.name}</span>
                                </div>
                            )
                        })}
                        <div style={{ position: 'absolute', width: '100%', bottom: 0, height: 4 }}>
                            <div style={{
                                position: 'absolute', height: '100%', bottom: 0, top: 0, background: 'var(--theme-main)', borderRadius: 2,
                                width: (() => {
                                    if (!this.state.query.option) {
                                        return 0
                                    }
                                    // (this.state.query.option && this.state.query.option.length === 3) ? '2em' : '3em'
                                    switch (this.state.query.option.length) {
                                        case 3:
                                            return '2em'
                                        case 6:
                                            return 'calc(4px + 5.5em)'
                                        default:
                                            return '3em'
                                    }
                                })(),
                                transition: 'all .3s',
                                left: (() => {
                                    switch (this.state.query.option) {
                                        case 'all':
                                            return 0
                                        case 'notuse':
                                            return 'calc(3em )'
                                        case 'used':
                                            return 'calc(10em - 4px)'
                                        case 'applyrefund':
                                            return 'calc(100% - 7em - 4px)'
                                        case 'refundsuccess':
                                            return 'calc(100% - 3em)'
                                        default:
                                            return 0
                                    }
                                })()
                            }}></div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div style={{ paddingBottom: 70 }}>
                {<>
                    <div className='fbc' style={{}}>
                        {(this.state.query.orderType === 1 && this.state.order_total) ? <div style={{ marginTop: 12, }}>
                            <h4 style={{ fontSize: '1.2rem', fontWeight: 600, color: '#333' }}>
                                订单总览
                                {this.state.query.tuanid ? (<NavLink to={`/orders/Analysis/${this.state.query.tuanid}`}><span style={{ fontSize: 12, color: '#b8b8b8' }} className='cur-pointer' onClick={() => {
                                    // this.props.history.push()
                                }}>点击查看订单分析</span></NavLink>) : null}
                            </h4>
                            <div className='fcc' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {
                                    this.state.query.option === 'applyrefund' ?
                                        <div style={{ marginRight: 12 }}><Select list={this.state.approvalList} inputStyle={{ width: '9em', fontSize: 12 }}
                                            value={(() => {
                                                const idx = this.state.approvalList.findIndex(i => i.review_op === this.state.query.review_op)
                                                return idx >= 0 ? idx : 0
                                            })()}
                                            uni='approval' showKey='name' onChange={(e, idx) => {
                                                this.changeQuery({ review_op: e.review_op, page: 1, })
                                            }} /></div> : null
                                }
                                <div style={{ color: '#999', marginRight: 12 }}>总订单：<span style={{ color: '#333' }}>{this.state.order_total.allorder_count}</span></div>
                                <div style={{ color: '#999', marginRight: 12 }}>销售额：<span style={{ color: '#333' }}>{(this.state.order_total.my_saleorder_total / 100).toFixed(2)}</span>元</div>
                            </div>
                        </div> : <div></div>}
                        {this.state.query.distributionType !== 1 ? <div style={{ padding: '16px 0' }}>
                            {show ? <>
                                {SelectedTuan === undefined || Number(SelectedTuan.takeway) === 1 ? <>
                                    <BTN style={{ marginLeft: 12 }} color='red' onClick={() => { this.setState({ showSelectSendPanel: true }) }}>批量发货</BTN>
                                </> : null}
                                {SelectedTuan === undefined || Number(SelectedTuan.takeway) === 2 ? <>
                                    <BTN style={{ marginLeft: 12 }} color='red' onClick={() => { this.setState({ quickCheckin: true }) }}>一键核销</BTN>
                                </> : null}
                            </> : null}
                            <BTN style={{ marginLeft: 12 }} color='red' onClick={() => { this.setState({ ShowPrintOrder: true }) }}>打印订单</BTN>
                        </div> : null}
                    </div>
                    {this.state.query.distributionType !== 1 && !this.state.loading ? <div>
                        {this.state.orderlist.map((i: order) => {
                            if (Number(i.shipping_mode) === 0) {
                                if (Number(i.addressid) === 0) {
                                    i.shipping_mode = 1
                                } else {
                                    i.shipping_mode = 2
                                }
                            }
                            return <Item data={i} print={this.print.bind(this, i)} key={i.orderid} send={this.getData.bind(this, { loading: false })} expressList={this.state.expressList} />
                        })}
                    </div> : null}
                </>}

                {/* {this.state.query.orderType === 2  && this.state.query.distributionType === 2 && !this.state.loading ? <>
                    {this.state.orderlist.map((i: order) => {
                        return <Item data={i} print={this.print.bind(this, i)} key={i.orderid} send={this.getData.bind(this, { loading: false })} expressList={this.state.expressList} />
                    })}
                </> : null} */}

                {this.state.query.orderType === 2 && this.state.query.distributionType === 1 && this.state.sellerData ? <>
                    {(() => {
                        let data = this.state.sellerData.thisSellerData
                        if (!data || data.length <= 0) return
                        if (!this.state.query.subtuanid) return
                        const id = atob(decodeURIComponent(this.state.query.subtuanid))
                        data = data[id]
                        if (!data) return
                        return <div className='TuanOrder__sellerList'>
                            <div className='TuanOrder__sellerList__title'>
                                {data.shopinfo.name}的订单汇总
                            </div>
                            <div className='TuanOrder__sellerList__item'>
                                <div className='TuanOrder__sellerList__item__head'>
                                    <img className='img-center TuanOrder__sellerList__item__head__ava' alt='avatar' src={data.shopinfo.logo + '?imageView2/2/w/150'} />
                                    <div className='TuanOrder__sellerList__item__head__username'>{data.shopinfo.name}</div>
                                </div>
                                <div className='TuanOrder__sellerList__item__list'>
                                    {Object.keys(data.item).map(item => {
                                        const good = data.item[item]
                                        return <div key={item} className='fcc TuanOrder__sellerList__item__list__item' >
                                            <img alt='good' src={good.logo} className="TuanOrder__sellerList__item__list__item__logo" />
                                            <div className='TuanOrder__sellerList__item__list__item__name'>{good.name}({good.unit})</div>
                                            {/* <div className='TuanOrder__sellerList__item__list__item__price'>￥{(good.price / 100).toFixed(2)}</div> */}
                                            <div className='TuanOrder__sellerList__item__list__item__num'>x{good.num}</div>
                                        </div>
                                    })}
                                </div>
                                <div className='fbc TuanOrder__sellerList__item__foot'>
                                    <NavLink to={`/orders/t=${this.state.query.tuanid}&st=${this.state.query.subtuanid}&p=1&ot=2&dt=2`} className='TuanOrder__sellerList__item__foot__toDetail cur-pointer'>
                                        点击查看该好友的订单&gt;
                                    </NavLink>
                                    <div className='TuanOrder__sellerList__item__foot__totalData'>共计<span>{data.goods_count}</span>件，支付金额：<span className='TuanOrder__sellerList__item__foot__totalPrice'>￥{(data.goods_total / 100).toFixed(2)}</span></div>
                                </div>
                            </div>
                        </div>
                    })()}
                    <div className='TuanOrder__sellerList'>
                        <div className='TuanOrder__sellerList__title fbc'>
                            <div>
                                {this.state.sellerData.thisSellerData.length === 0 ? '帮卖好友信息汇总' : 'TA的帮卖好友信息汇总'}
                                {(() => {
                                    const data = this.state.sellerData.thisSellerData
                                    if (data) return
                                    return <NavLink to={`/orders/t=${this.state.query.tuanid}&p=1&ot=2&dt=1`}>
                                        <span style={{ color: '#999', fontSize: 14, marginLeft: 24, }}>查看全部好友订单</span>
                                    </NavLink>
                                })()}
                            </div>
                            {this.state.sellerData.thisSellerData.length === 0 ? <button className='button_solid_red' onClick={() => {
                                window.open(`/picklist/${this.state.query.tuanid}?type=2`)
                            }}>打印拣货单</button> : null}
                        </div>
                        <div style={{ position: 'relative', minHeight: 300 }}>
                            {this.state.sellerData.sellerList && this.state.sellerData.sellerList.length > 0 ? (
                                this.state.sellerData.sellerList.map((i: any) => {
                                    return (
                                        <div className='TuanOrder__sellerList__item' key={i.tuanid}>
                                            <div className='flex-between'>
                                                <div className='TuanOrder__sellerList__item__head'>
                                                    <img className='img-center TuanOrder__sellerList__item__head__ava' alt='avatar' src={i.shopinfo.logo + '?imageView2/2/w/150'} />
                                                    <div className='TuanOrder__sellerList__item__head__username'>{i.shopinfo.name}</div>
                                                </div>
                                                {this.state.query.tuanid === this.state.query.subtuanid ? <div className='flex-between'>
                                                    <div className='button_hollow_red' style={{ padding: '4px 11px', marginRight: 12 }} onClick={() => {
                                                        
                                                        const list = []
                                                        for (const key in i.item) {
                                                            if (i.item.hasOwnProperty(key)) {
                                                                const good = i.item[key];
                                                                list.push({ id: key, num: good.num, name: good.name, mark_num: good.mark_num })
                                                            }
                                                        }
                                                        this.setState({
                                                            showExpressMark: true,
                                                            markGoodlist: list,
                                                            markid: i.sid,
                                                        })
                                                    }}>标记发货</div>
                                                    <div className='cur-pointer' style={{ color: '#999', padding: '4px 11px', marginRight: 12 }} onClick={() => {
                                                        this.setState({
                                                            showMarkHistory: true,
                                                            markid: i.sid,
                                                        })
                                                    }}>发货记录</div>
                                                </div> : null}
                                            </div>
                                            <div className='TuanOrder__sellerList__item__list'>
                                                {Object.keys(i.item).map(item => {
                                                    const good = i.item[item]
                                                    return <div key={item} className='fcc TuanOrder__sellerList__item__list__item' >
                                                        <img alt='good' src={good.logo} className="TuanOrder__sellerList__item__list__item__logo" />
                                                        <div className='TuanOrder__sellerList__item__list__item__name'>{good.name}({good.unit})</div>
                                                        <div className='TuanOrder__sellerList__item__list__item__num'>x{good.num}</div>
                                                    </div>
                                                })}
                                            </div>
                                            <div className='fbc TuanOrder__sellerList__item__foot'>
                                                <NavLink to={`/orders/st=${encodeURIComponent(window.btoa(i.tuanid))}&p=1&ot=2&dt=1&t=${this.state.query.tuanid}`} className='TuanOrder__sellerList__item__foot__toDetail cur-pointer'>
                                                    点击查看该好友的汇总信息{">"}
                                                </NavLink>
                                                <div className='TuanOrder__sellerList__item__foot__totalData'>共计<span>{i.goods_count}</span>件，支付金额：<span className='TuanOrder__sellerList__item__foot__totalPrice'>￥{(i.goods_total / 100).toFixed(2)}</span></div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : null}
                            {!this.state.loading && this.state.sellerData.sellerList && this.state.sellerData.sellerList.length === 0 ? (<div className='fcc'
                                style={{ height: 200 }}>
                                {this.state.sellerData.thisSellerData.length === 0 ? '你还没有帮卖好友' : 'TA还没有帮卖好友'}
                            </div>) : null}
                        </div>
                    </div>
                </> : null}

                {this.state.loading ? [1, 2, 3, 4, 5].map(i => {
                    if (this.state.query.distributionType === 1) return null
                    return <FakeItem key={i} />
                }) : null}
            </div>

            <Page>
                {this.state.query.distributionType !== 1 ? <div className='fbc'>
                    <div></div>
                    <Pagination total={this.state.count} pageSizeOptions={[]} current={this.state.query.page} pageSize={OFFSET_LIMIT} onChange={e => { this.changeQuery({ page: e }); console.log(362) }} />
                </div> : null}
            </Page>

            {this.state.showExportPanel ? <Export
                printList={this.printList.bind(this)}
                close={() => {
                    this.setState({
                        showExportPanel: false
                    })
                }}
                tuanlist={this.state.tuanlist}
                preselect={this.state.query.tuanid}
            ></Export> : null}

            {this.state.ShowPrintOrder ? <PrintOrderPanel
                printList={this.printList.bind(this)}
                close={() => {
                    this.setState({
                        ShowPrintOrder: false
                    })
                }}
                tuanlist={this.state.tuanlist}
                height={320}
                width={400}
                preselect={this.state.query.tuanid} /> : null}

            {/* 批量发货 */}
            {this.state.batch ? (<Modal loading={this.state.batch_loading} cancel={true} title={'如何批量发货'} confirm={() => {
                if (!this.uploadxls) {
                    message.warning('请选择文件')
                    return
                }
                this.setState({
                    batch_loading: true,
                })
                fetch(API + '/pcorder/sendgoodsbyimportexcel', {
                    method: 'post', body: this.uploadxls, mode: 'cors',
                    credentials: 'include',
                }).then((res: any) => {
                    res.json().then((res: any) => {
                        if (res.code !== 0) {
                            message.error(res.message)
                            this.setState({ batch_loading: false })
                            return
                        }
                        alert('发货成功')
                        this.getData()
                        this.setState({ batch: false, batch_loading: false })
                    })
                }).catch(() => {
                    message.error('网络错误')
                    this.setState({ batch_loading: false })
                })
            }} close={() => {
                this.uploadxls = null
                this.setState({ batch: false })
            }}>
                <ol id='batch-order'>
                    <li><span style={{ color: '#3d83d8', cursor: 'pointer' }} onClick={() => {
                        this.setState({
                            showExportPanel: true, batch: false,
                        })
                    }}>导出数据</span>，下载Excel表格</li>
                    <li>按照表格格式填写好快递名称和快递单号，保存表格文件，例如：圆通 YT4132628326828</li>
                    <img alt='发货填写示例图' src='https://tuangou-1259536745.picgz.myqcloud.com/uploads/20191012/tE6T0cuxkZ.jpg'></img>
                    <li>
                        点击下方按钮上传表格，系统自动会填上对应订单的快递单号，并发货。<br />
                        <span>注意：请<span style={{ color: 'var(--theme-main)' }}>勿</span>删除Excel表格中，<span style={{ color: 'var(--theme-main)' }}>第一行</span>以及<span style={{ color: 'var(--theme-main)' }}>订单号、快递公司、快递单号</span>三列</span>
                    </li>
                    <label style={{ cursor: 'pointer' }} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <input type='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                            onChange={e => {
                                if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                const file = e.target.files[0]
                                const formData = new FormData()
                                formData.append('file', file)
                                formData.append('types', '1')
                                formData.append('token', this.props.login_user_data.token)
                                this.uploadxls = formData
                            }} />
                        <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                    </label>
                    <div style={{ color: '#3d83d8', cursor: 'pointer' }} onClick={e => {
                        const win = window.open('/help/importexcel', '_blank');
                        win && win.focus();
                    }}>还不懂？查看详细教程</div>
                </ol>
            </Modal>) : null}

            {/* 一键发货 */}
            {this.state.quickSend ? <Quick takeway={1} initTuanId={Number(atob(decodeURIComponent(this.state.query.tuanid || '')))}
                quickSendList={quickSendList} quickCheckinList={quickCheckinList} onConfirm={() => {
                    this.getData()
                    this.setState({ quickSend: false, })
                }} onClose={() => { this.setState({ quickSend: false, }) }} /> : null}

            {/* 一键核销 */}
            {this.state.quickCheckin ? <Quick takeway={2} initTuanId={Number(atob(decodeURIComponent(this.state.query.tuanid || '')))}
                quickSendList={quickSendList} quickCheckinList={quickCheckinList} onConfirm={() => {
                    this.getData()
                    this.setState({ quickCheckin: false, })
                }} onClose={() => { this.setState({ quickCheckin: false, }) }} /> : null}

            {this.state.showSelectSendPanel ? <Modal title='请选择发货方式' onConfirm={() => {
                this.setState({ showSelectSendPanel: false })
                if (this.state.selectedSendMode === 1) {
                    this.setState({ batch: true })
                }
                if (this.state.selectedSendMode === 2) {
                    this.setState({ quickSend: true })
                }
            }} onClose={this.setState.bind(this, { ...this.state, showSelectSendPanel: false })}>
                <div className='fbc'>
                    <div className='cur-pointer' onClick={() => { this.setState({ selectedSendMode: 1 }) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: this.state.selectedSendMode === 1 ? '#fff' : '#eee', color: this.state.selectedSendMode === 1 ? 'var(--theme-main)' : '#666', border: this.state.selectedSendMode === 1 ? '1px solid var(--theme-main)' : '1px solid #eee' }}>
                        <div style={{ fontSize: 20, fontWeight: 600, }}>快递配送</div>
                        <div style={{ fontSize: 12, marginTop: 15 }}>需要导入快递单号</div>
                        <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: this.state.selectedSendMode === 1 ? 'block' : 'none' }} />
                    </div>
                    <div className='cur-pointer' onClick={() => { this.setState({ selectedSendMode: 2 }) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: this.state.selectedSendMode === 2 ? '#fff' : '#eee', color: this.state.selectedSendMode === 2 ? 'var(--theme-main)' : '#666', border: this.state.selectedSendMode === 2 ? '1px solid var(--theme-main)' : '1px solid #eee' }}>
                        <div style={{ fontSize: 20, fontWeight: 600, }}>一键发货（无需导入单号）</div>
                        <div style={{ fontSize: 12, marginTop: 15 }}>适用于由商家自己配送货物的活动</div>
                        <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: this.state.selectedSendMode === 2 ? 'block' : 'none' }} />
                    </div>
                </div>
            </Modal> : null}

            {/* 标记发货 */}
            {this.state.showExpressMark && this.state.markGoodlist && this.state.markid
                ? <ExpressMark goodlist={this.state.markGoodlist} mark_type={2} tuanid={this.state.query.tuanid || ''} id={this.state.markid} onClose={() => {
                    this.setState({ showExpressMark: false, markGoodlist: undefined })
                }} />
                : null
            }

            {/* 标记发货信息 */}
            {this.state.showMarkHistory && this.state.markid
                ? <MarkHistory type={2} id={this.state.markid} tuanid={this.state.query.tuanid || ''} onClose={() => {
                    this.setState({ showMarkHistory: false, markGoodlist: undefined })
                }} />
                : null}



        </Warp></Relative>
    }

    uploadxls: any = null

    getQuery(): Promise<void> {
        return new Promise((reslove, reject) => {
            const data = this.props.match.params.query ? parse(this.props.match.params.query) : {}
            const query: query = {
                search: '',
                page: 1,
                option: 'all',
                tuanid: '',
                orderType: 1,
                distributionType: 2,
                subtuanid: '',
                review_op: '',
            }
            if (data.s) query.search = data.s
            if (data.p) query.page = Number(data.p)
            if (data.op) query.option = data.op
            if (data.t) query.tuanid = data.t
            if (data.ot) query.orderType = Number(data.ot)
            if (data.dt) query.distributionType = Number(data.dt)
            if (data.st) query.subtuanid = data.st
            if (data.review_op) query.review_op = data.review_op
            if (query.orderType === 2 && query.tuanid === '') {
                this.changeQuery({ orderType: 1 })
                reject('请选择团购')
            }
            this.setState({ query, searchText: query.search }, () => {
                reslove()
            })
        })

    }

    changeQuery(data: query): void {
        let path: string = '/orders/'
        const query: query = { ...this.state.query, ...data }
        if (query.tuanid === '') {
            delete query.subtuanid
            delete query.orderType
            delete query.distributionType
        }
        if (query.option !== 'applyrefund') {
            delete query.review_op
        } else {
            if (!query.review_op) query.review_op = 'for_me'
        }
        if (query.search) path += `s=${query.search}`
        if (query.page) path += `&p=${query.page}`
        if (query.option) path += `&op=${query.option}`
        if (query.tuanid || query.tuanid === '') path += `&t=${query.tuanid}`
        if (query.orderType) path += `&ot=${query.orderType}`
        if (query.distributionType) path += `&dt=${query.distributionType}`
        if (query.subtuanid) path += `&st=${query.subtuanid}`
        if (query.review_op) path += `&review_op=${query.review_op}`
        this.props.history.push(path)
    }

    async getData(config: { loading?: boolean } = { loading: true }) {
        const data: any = { ...this.state.query }
        data.offset = (data.page - 1) * OFFSET_LIMIT
        data.op = data.option
        delete data.orderType
        delete data.page
        delete data.option
        if (this.state.query.option === 'applyrefund' && !data.review_op) data.review_op = 'for_me'
        this.setState({ loading: true })
        if (config.loading) {
            this.props.showLoading()
            this.setState({ orderlist: [] })
        }
        if (data.tuanid !== '') {
            try {
                const { isdistribution } = await get('/tuan/view', { id: data.tuanid, from: 'manage', types: 1, })
                this.setState({ isdistribution })
            } catch {
                this.props.hideLoading()
            }
        } else {
            this.setState({ isdistribution: false })
        }
        if (this.state.query.orderType === 1 || this.state.query.option === 'applyrefund') {
            let getorderData = {
                ...data,
                types: 1,
                addressid: 'all',
                limit: OFFSET_LIMIT,
            }
            if (this.state.query.option !== 'applyrefund') delete getorderData.review_op

            try {
                const { list, tuanlist, order_total, count, } = await get('/shop/getorderlist', getorderData)
                this.setState({
                    orderlist: this.dealOrderlist(list),
                    tuanlist,
                    order_total, count: Number(count),
                })
                this.props.hideLoading()
            } catch (e) {
                this.props.hideLoading()
                if (e.message) message.error(e.message)
            }
        }
        if (this.state.query.orderType === 2 && this.state.query.option !== 'applyrefund') {
            if (this.state.query.distributionType === 1) {
                try {
                    const data = await get('/tuan/getdistributionorderlist', {
                        tuanid: this.state.query.subtuanid || '',
                        types: 1,
                        offset: 0,
                        limit: 100000,
                        search: this.state.query.search || '',
                    })
                    this.props.hideLoading()
                    this.setState({
                        sellerData: data,
                    })
                } catch (e) {
                    if (e.message) message.error(e.message)
                    this.props.hideLoading()
                }
            } else if (this.state.query.distributionType === 2) {
                try {
                    const { list, count } = await get('/tuan/getdistributionallsellerorderlist', {
                        ...data,
                        tuanid: data.subtuanid || data.tuanid,
                        types: 1,
                        limit: OFFSET_LIMIT,
                    })
                    this.setState({
                        orderlist: this.dealOrderlist(list),
                        count: Number(count),
                    })
                    this.props.hideLoading()
                } catch (e) {
                    this.props.hideLoading()
                    if (e.message) message.error(e.message)
                }
            }
        }
        this.setState({ loading: false })
    }

    async getTuanList() {
        let data = {
            types: 1,
            op: 'all',
            offset: 0,
            addressid: 'all',
            limit: 0,
            type: 'tuan',
            review_op: this.state.query.review_op
        }

        if (this.state.query.option !== 'applyrefund') delete data.review_op

        const { tuanlist } = await get('/shop/getorderlist', data)
        this.setState({ tuanlist })
    }

    dealOrderlist(list: order[]): order[] {
        return list.map(((item: order) => {
            let status = ''
            const orderStatus = item.status
            switch (Number(orderStatus)) {
                case 0:
                    status = '未支付'
                    break;
                case 1:
                    status = '支付成功'
                    if (item.refundContent.length > 0) {
                        status = item.refundContent
                    }
                    break
                case 2:
                    status = '核销成功'
                    if (Number(item.addressid) === 0) {
                        status = '发货成功'
                    }
                    break;
                case 4:
                    status = '退款完成'
                    break
                case 5:
                    status = '无需支付'
                    break
                case 6:
                    status = '业务开单'
                    break
                default:
                    break;
            }
            item.statusText = status
            return item
        }))
    }
    printList(list: order[]) {
        if (!Array.isArray(list)) {
            return
        }
        this.setState({
            printOrders: list
        }, () => {
            // window.print()
            this.props.history.push({
                pathname: '/orders/print',
                state: {
                    list
                }
            })
        })
    }
    print(item: order) {
        this.setState({
            printOrders: [item]
        }, () => {
            this.props.history.push({
                pathname: '/orders/print',
                state: {
                    list: [item]
                }
            })
        })
    }
}

const mapStateToProps = (state: any,) => {
    return {
        is_Login: state.is_Login,
        waitingLogin: state.waitingLogin,
        login_user_data: state.login_user_data,
    }
}
const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
    return {
        showLoading: () => {
            dispatch(showLoading(true, '加载中...'))
        },
        hideLoading: () => {
            dispatch(showLoading(false))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BanUnLogin(Main(OrderList, false)))