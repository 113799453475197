import React, { useState } from "react"
import Modal from "../total/Modal"
import ReactDOM from "react-dom"
import { message } from "antd"
import styled from "styled-components"
import Select from "../total/Select_"

interface props {
    type: 'number' | 'string' | 'select'
    title?: string | JSX.Element
    content?: string | JSX.Element
    value?: string | number
    confirmText?: string
    cancelText?: string
    warningText?: string
    placeholder?: string
    selectConfig?: {
        list: {[key: string]: any}[]
        showKey: string
    }
}

let confirmWrap: Element

export default function (props: props) {

    const Wrap = styled.div`
        width: 30em;
    `

    const Input = styled.input`
        padding: 8px 11px;
        border: 1px solid #ededed;
        width: 100%;
        display: inline-block;
    `
    const Content = styled.div`
        margin-bottom: 1em;
    `

    return new Promise((res, rej) => {
        if(props.type === 'select' && !props.selectConfig){
            rej('请输入选择列表')
            return
        }
        function Prompt(props: props) {
            const [value, setValue] = useState<string>(((props.value || '') + '') || '')
            const [shouldClose, close] = useState(false)
            const [selectValue, setSelectCValue] = useState<number>()
            return <Modal banMaskClose banAnime title={props.title} shouldClose={shouldClose} cancel={true} confirmText={props.confirmText || '确认'} cancelText={props.cancelText || '取消'}
                onConfirm={confirm} onClose={reject} >
                <Wrap>
                    <Content>{props.content}</Content>
                    {props.type === 'select' && props.selectConfig
                        ? <Select uni='Prompt' maxHeight={200} placeholder={props.placeholder} list={props.selectConfig.list} showKey={props.selectConfig.showKey} value={selectValue} onChange={selectChange} />
                        : <Input value={value} type={props.type} placeholder={props.placeholder} onKeyDown={e => {
                            if(e.key === 'Enter')confirm()
                        }} autoFocus onChange={e => { setValue(e.target.value) }} />
                    }
                </Wrap>
            </Modal>
            function confirm() {
                if(props.type !== 'select'){
                    if (!value && value !== '') {
                        message.warn(props.warningText || '请输入')
                        return
                    }
                    res(value)
                    close(true)
                }else{
                    if(selectValue === undefined){
                        message.warn(props.warningText || '请选择')
                        return
                    }
                    res(props.selectConfig.list[selectValue])
                    close(true)
                }
            }
            function reject() {
                rej()
                ReactDOM.render(<></>, confirmWrap)
                if(confirmWrap){
                    document.body.removeChild(confirmWrap)
                    confirmWrap = undefined
                }
            }

            function selectChange(item, idx){
                setSelectCValue(idx)
            }
        }



        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Prompt {...props} />, confirmWrap)
    })
}
