import React, { useState } from 'react';

import Modal from '../../total/Modal';
import { message } from 'antd';
import { post } from '../../../netApi/fetch';

interface Props {
    goodlist: {
        name: string;
        id: string | number;
        num: number | string;
        mark_num?: number | string;
    }[];
    onClose: (refresh?: boolean) => void;
    mark_type: 1 | 2;
    id: string | number;
    tuanid: string;
}

export default function (props: Props) {

    const [list, setList] = useState(props.goodlist.map(good => { return { name: good.name, num: Number(good.num) - Number(good.mark_num || 0), id: good.id } }))

    return <Modal title='标记发货' onClose={() => {
        props.onClose()
    }} onConfirm={() => {
        const data = {
            tuanid: atob(decodeURIComponent(props.tuanid)),
            mark_type: props.mark_type,
            to_sid: props.mark_type === 2 ? props.id : undefined,
            to_addressid: props.mark_type === 1 ? props.id : undefined,
            mark_list: list.map(good => {
                return {
                    goodsattrid: good.id,
                    num: good.num ,
                }
            })
        }
        post('/order/marksendgoodsbypickupmode', data).then(()=>{
            message.success('标记成功')
            props.onClose(true)
        }).catch((e) => {
            if (e.message) message.error(e.message) 
        })
    }} >
        <div>标记发货数量：</div>
        <div style={{ maxHeight: 600, overflow: 'auto' }}>
            {list.map((good, idx) => {
                return <div key={good.id} className='flex-between' style={{ marginBottom: 12 }} >
                    <div>{good.name}</div>
                    <div>
                        <input type='number' style={{ border: '1px solid #999', borderRadius: 4, padding: '4px 11px' }} value={good.num > 0 ? good.num : 0} onChange={e => {
                            const num = Number(e.target.value)
                            if (num < 0) return
                            const max = Number(props.goodlist[idx].num) - Number(props.goodlist[idx].mark_num || 0)
                            if (max < num) {
                                message.warn('超出可标记数量')
                                return
                            }
                            good.num = num
                            setList([...list])
                        }} />
                    </div>
                </div>
            })}
        </div>
    </Modal>
}