import React, { Component } from 'react';
import { message } from 'antd';

class InputSign extends Component {

    constructor() {
        super()
        this.state = {
            list: [],
            showAddMenu: false
        }
    }

    componentDidMount() {
        this.setState({
            list: JSON.parse(JSON.stringify(this.props.signlist))
        })
    }

    render() {
        return (<div className='edittuan__sign'>
            {this.state.list.map((i, index) => {
                let type
                switch (Number(i.type)) {
                    case 1:
                        type = '单行'
                        break;
                    case 2:
                        type = '多选'
                        break;
                    case 3:
                        type = '语音'
                        break;
                    case 4:
                        type = '图片'
                        break
                    case 5:
                        type = '位置'
                        break;
                    case 6:
                        type = '单选'
                        break
                    default:
                        type = ''
                }

                if (!i.editting) return (<div key={index} className='edittuan__sign__item'>
                    <table style={{ width: '100%', background: '#f8f8f8', marginTop: 0 }}>
                        <tbody>
                            <tr style={{ borderBottom: '1px #d8d8d8 solid' }}>
                                <td className='edittuan__sign__item__type'>{type}</td>
                                <td className='edittuan__sign__item__info-title' style={{ width: '20em' }}>{i.name}</td>
                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                    {Number(i.is_must) === 1 ? (<div className='edittuan__sign__item__icon'>必填</div>) : null}
                                    {Number(i.is_pub) === 1 ? (<div className='edittuan__sign__item__icon'>公开</div>) : null}
                                    {Number(i.is_pub) === 2 ? (<div className='edittuan__sign__item__icon'>部分公开</div>) : null}
                                </td>
                                <td style={{ width: '7em' }}>
                                    <div className='fbc' style={{ justifyContent: 'flex-start' }}>
                                        <span className='cur-pointer' style={{ marginRight: '1em' }} onClick={() => {
                                            this.setState({ list: this.state.list.filter((i, _index) => index !== _index) }, () => {
                                                this.props.onChange && this.props.onChange(this.state)
                                            })
                                        }}>删除</span>
                                        <span className=' edittuan__sign__item__info-confirm cur-pointer' onClick={() => {
                                            i.editting = true
                                            this.setState({ list: this.state.list })
                                        }}>编辑</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>)
                return <div key={index} className='edittuan__sign__item'>
                    <table style={{ width: '100%', background: '#f8f8f8', marginTop: 13, marginBottom: 13 }}>
                        <tbody>
                            <tr style={{ borderBottom: '1px #d8d8d8 solid' }}>
                                <td className='edittuan__sign__item__type'>{type}</td>
                                <td className='edittuan__sign__item__info-title'>填写信息名</td>
                                <td>
                                    <input placeholder='请填写单行文本主题名称' style={{ width: '100%' }} value={i.name} onChange={e => {
                                        i.name = e.target.value
                                        this.setState({ list: this.state.list })
                                    }} />
                                </td>
                                <td></td>
                            </tr>
                            <tr style={{ borderBottom: '1px #d8d8d8 solid' }}>
                                <td></td>
                                <td className='edittuan__sign__item__info-title'>补充说明</td>
                                <td>
                                    <input placeholder='请输入补充说明（非必填）' style={{ width: '100%' }} value={i.description} onChange={e => {
                                        i.description = e.target.value
                                        this.setState({ list: this.state.list })
                                    }} />
                                </td>
                                <td></td>
                            </tr>
                            {(Number(i.type) === 2 || Number(i.type) === 6) ? (i.data_value.map((dv, dv_index) => {
                                return (
                                    <tr key={dv_index} style={{ borderBottom: '1px #d8d8d8 solid' }}>
                                        <td></td>
                                        <td className='edittuan__sign__item__info-title'>选项{dv_index + 1}</td>
                                        <td>
                                            <input placeholder='请填写选项内容' style={{ width: '100%' }} value={dv.content} onChange={e => {
                                                dv.content = e.target.value
                                                this.setState({ list: this.state.list })
                                            }} />
                                        </td>
                                        <td className='edittuan__sign__item__info-confirm cur-pointer'>
                                            <span onClick={() => {
                                                i.data_value = i.data_value.filter((i, index) => index !== dv_index)
                                                this.setState({ list: this.state.list })
                                            }}>删除</span>
                                        </td>
                                    </tr>
                                )
                            })) : null}
                            {(Number(i.type) === 2 || Number(i.type) === 6) ? (
                                <tr>
                                    <td></td>
                                    <td colSpan={2}>
                                        <div className='fcc edittuan__sign__item__info-confirm cur-pointer' onClick={() => {
                                            i.data_value = [...i.data_value, { content: '' }]
                                            this.setState({ list: this.state.list })
                                        }}>+添加选项</div>
                                    </td>
                                </tr>
                            ) : null}
                            <tr >
                                <td></td>
                                <td colSpan={2}>
                                    <div className='fcc' style={{ justifyContent: 'flex-start' }}>
                                        <div className='cur-pointer' style={{ marginRight: 20 }} onClick={() => {
                                            i.is_must = Number(i.is_must)
                                            i.is_must = !i.is_must
                                            this.setState({ list: this.state.list })
                                        }}>
                                            {Number(i.is_must) === 1 ? (<i style={{ color: '#fd5356' }} className='iconfont icon-check_box'></i>) : (<i style={{ color: '#999' }} className='iconfont icon-check-box'></i>)}
                                            <span>必填</span>
                                        </div>
                                        <div className='cur-pointer' style={{ marginRight: 20 }} onClick={() => {
                                            if (Number(i.is_pub) !== 1) {
                                                i.is_pub = 1
                                            } else {
                                                i.is_pub = 0
                                            }
                                            this.setState({ list: this.state.list })
                                        }}>
                                            {Number(i.is_pub) === 1 ? (<i style={{ color: '#fd5356' }} className='iconfont icon-check_box'></i>) : (<i style={{ color: '#999' }} className='iconfont icon-check-box'></i>)}
                                            <span>公开</span>
                                        </div>
                                        {Number(i.type) === 1 ? (<div className='cur-pointer' style={{ marginRight: 20 }} onClick={() => {
                                            if (Number(i.is_pub) !== 2) {
                                                i.is_pub = 2
                                            } else {
                                                i.is_pub = 0
                                            }
                                            this.setState({ list: this.state.list })
                                        }}>
                                            {Number(i.is_pub) === 2 ? (<i style={{ color: '#fd5356' }} className='iconfont icon-check_box'></i>) : (<i style={{ color: '#999' }} className='iconfont icon-check-box'></i>)}
                                            <span>部分公开</span>
                                        </div>) : ''}
                                    </div>
                                </td>
                                <td style={{ width: '7em' }}>
                                    <div className='fbc' style={{ justifyContent: 'flex-start' }}>
                                        <span style={{ marginRight: '1em' }} className='cur-pointer' onClick={() => {
                                            let list = this.state.list
                                            list[index] = JSON.parse(JSON.stringify(this.props.signlist))[index]
                                            if (list[index]) {
                                                list[index].editting = false
                                            }
                                            list = list.filter(i => i !== undefined)
                                            this.setState({ list })
                                        }}>取消</span>
                                        <span className=' edittuan__sign__item__info-confirm cur-pointer' onClick={() => {
                                            if (i.name === '') {
                                                message.error('请填写信息名')
                                                return
                                            }
                                            if (Number(i.type) === 2 || Number(i.type) === 6) {
                                                if (i.data_value.length <= 0) {
                                                    message.error('请输入选项')
                                                    return
                                                }
                                            }
                                            if (!i.id) {
                                                if (Number(i.type) === 2 || Number(i.type) === 6) {
                                                    i.data_value = i.data_value.map(dv => {
                                                        return {
                                                            ...dv,
                                                            value: dv.content,
                                                        }
                                                    })
                                                }
                                            }
                                            i.editting = false
                                            this.setState({ list: this.state.list })
                                            this.props.onChange && this.props.onChange(this.state)
                                        }}>确认</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            })}
            <div className='fcc' onMouseLeave={() => {
                this.setState({ showAddMenu: false })
            }}>
                <span className='edittuan__sign__add cur-pointer' onClick={() => { this.setState({ showAddMenu: !this.state.showAddMenu }) }}>+添加
                    {this.state.showAddMenu ? (<div className='shadow-menu edittuan__sign__add__menu cur-pointer'>
                        <div className='shadow-menu__item fcc' onClick={this.add.bind(this, 1)}>添加单行</div>
                        <div className='shadow-menu__item fcc' onClick={this.add.bind(this, 5)}>添加位置</div>
                        <div className='shadow-menu__item fcc' onClick={this.add.bind(this, 3)}>添加语音</div>
                        <div className='shadow-menu__item fcc' onClick={this.add.bind(this, 4)}>添加图片</div>
                        <div className='shadow-menu__item fcc' onClick={this.add.bind(this, 6)}>添加单选</div>
                        <div className='shadow-menu__item fcc' onClick={this.add.bind(this, 2)}>添加多选</div>
                    </div>) : null}
                </span>
            </div>
        </div>)
    }

    add(type) {
        let data
        switch (Number(type)) {
            case 1:
                // 文字
                data = {
                    data_value: [],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
                break;
            case 2:
                // type = '多选'
                data = {
                    data_value: [{ content: '' }, { content: '' }],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
                break;
            case 3:
                // 语音
                data = {
                    data_value: [],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
                break;
            case 4:
                // type = '图片'
                data = {
                    data_value: [],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
                break
            case 5:
                // type = '位置'
                data = {
                    data_value: [],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
                break;
            case 6:
                // type = '单选'
                data = {
                    data_value: [{ content: '' }, { content: '' }],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
                break
            default:
                data = {
                    data_value: [],
                    description: '',
                    name: '',
                    is_must: false,
                    is_pub: 0,
                    type,
                    editting: true,
                }
        }
        this.setState({
            list: this.state.list.concat(data)
        })
    }
}

export default InputSign