import React, { useState, useEffect, } from 'react';
import ReactDom from 'react-dom';
import Modal from '../../total/Modal';
import { message } from 'antd';
import { get, post } from '../../../netApi/fetch';

let confirmWrap: Element

interface Props {
    good: good,
    distribution: boolean
}

interface attr extends good.attr {
    original_price: string;
    editOriginPrice: string | number | readonly string[];
    editPrice: number | string
    editQuantity: number | string
    editInputNumber?: string
    editNowPrice?: string
}


export default (props: Props) => {
    return new Promise<void>((res, rej) => {
        function HemaGoodsNumSetting(props: Props) {
            const [attrs, setAttrs] = useState<attr[]>([])
            useEffect(() => {
                if (attrs && attrs.length > 0) return
                get('/goods/view', { goodsid: props.good.goodsid }).then(res => {
                    setAttrs(res.attribute.map(i => {
                        const settingAttr = props.good.attribute.find(j => Number(i.id) === Number(j.id)) as attr
                        if (!props.distribution) {
                            return {
                                ...settingAttr,
                                editPrice: (Number(i.price) / 100).toFixed(2),
                                editOriginPrice: (Number(i.original_price) / 100).toFixed(2),
                                editQuantity: i.quantity,
                                editInputNumber: '',
                                editNowPrice: (Number(i.price) / 100).toFixed(2),
                            }
                        } else {
                            return {
                                ...settingAttr,
                                editPrice: settingAttr.editPrice,
                                editOriginPrice: settingAttr.editOriginPrice,
                                editQuantity: settingAttr.editQuantity,
                                editInputNumber: settingAttr.editInputNumber,
                                editNowPrice: settingAttr.editNowPrice,
                            }
                        }
                    }))
                })
            }, [attrs, props])
            return <Modal title='选择活动惊喜' onClose={onClose} banAnime cancel={false} onConfirm={onConfirm.bind(undefined, attrs)} style={{
                outer: {
                    width: 654,
                }
            }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', overflow: 'overlay', maxHeight: '50vh' }}>
                    <img alt='' className='img-center' src={props.good.logo} style={{ width: 95, height: 95 }} />
                    <div style={{ flex: 1, }}>
                        <div>{props.good.name}</div>
                        {attrs.map((i, idx) => {
                            return <div key={i.id}>
                                <div style={{ marginBottom: 10, marginTop: 10, }}>{idx + 1}、{i.unit}</div>
                                <div style={{ marginBottom: 10, }} className='flex-box'>
                                    <div style={{ marginRight: 12 }}>
                                        商城库存：{i.mall_quantity}
                                    </div>
                                    <div>
                                        商城价格：{i.mall_price}
                                    </div>
                                </div>
                                <div style={{ width: '100%', flexWrap: 'wrap', marginBottom: 10, fontSize: 12 }}>
                                    <span>活动库存</span>
                                    <input onChange={e => {
                                        const value = e.target.value
                                        i.editQuantity = value
                                        setAttrs([...attrs])
                                    }} onBlur={() => {
                                        post('/goods/updatetuangoodsinfo', { type: 1, goods_attr_id: i.id, value: i.editQuantity }).then(() => {
                                            i.quantity = i.editQuantity
                                            setAttrs([...attrs])
                                        }).catch(e => {
                                            message.error(e)
                                        })
                                    }} type='number' value={i.editQuantity} style={{ width: 90, border: 0, borderBottom: '1px solid #999', textAlign: 'center' }} />
                                    <span>件</span>
                                </div>
                                {!props.distribution && <>
                                    <div style={{ width: '100%', flexWrap: 'wrap', marginBottom: 10, fontSize: 12 }}>
                                        <span>活动价格</span>
                                        <input onChange={e => {
                                            const value = e.target.value
                                            i.editPrice = value
                                            setAttrs([...attrs])
                                        }} onBlur={() => {
                                            function change() {
                                                post('/goods/updatetuangoodsinfo', { type: 2, goods_attr_id: i.id, value: (Number(i.editPrice) * 100).toFixed(0) }).then(() => {
                                                    i.price = (Number(i.editPrice) * 100).toFixed(0)
                                                    setAttrs([...attrs])
                                                }).catch(e => {
                                                    message.error(e)
                                                    change()
                                                })
                                            }
                                            change.call(this)
                                        }} type='number' value={i.editPrice} style={{ width: 90, border: 0, borderBottom: '1px solid #999', textAlign: 'center', color: 'var(--theme-main)', fontSize: 16 }} />
                                        <span>元</span>
                                    </div>
                                </>}
                                {!!props.distribution && <>
                                    <div style={{ width: '100%', flexWrap: 'wrap', marginBottom: 10, fontSize: 12 }}>
                                        <span>供应价</span>
                                        <input onChange={e => {
                                            const value = e.target.value
                                            i.editInputNumber = value
                                            setAttrs([...attrs])
                                        }} onBlur={() => {

                                        }} type='number' value={i.editInputNumber} style={{ width: 90, border: 0, borderBottom: '1px solid #999', textAlign: 'center', color: 'var(--theme-main)', fontSize: 16 }} />
                                        <span>元</span>
                                    </div>
                                    <div style={{ width: '100%', flexWrap: 'wrap', marginBottom: 10, fontSize: 12 }}>
                                        <span>对外售价</span>
                                        <input onChange={e => {
                                            const value = e.target.value
                                            i.editNowPrice = value
                                            setAttrs([...attrs])
                                        }} onBlur={() => {

                                        }} type='number' value={i.editNowPrice} style={{ width: 90, border: 0, borderBottom: '1px solid #999', textAlign: 'center', color: 'var(--theme-main)', fontSize: 16 }} />
                                        <span>元</span>
                                    </div>
                                </>}
                                <div style={{ width: '100%', flexWrap: 'wrap', fontSize: 12 }}>
                                    <span>划线价</span>
                                    <input onChange={e => {
                                        const value = e.target.value
                                        i.editOriginPrice = value
                                        setAttrs([...attrs])
                                    }} onBlur={() => {
                                        function change() {
                                            post('/goods/updatetuangoodsinfo', { type: 3, goods_attr_id: i.id, value: (Number(i.editOriginPrice) * 100).toFixed(0) }).then(() => {
                                                i.original_price = (Number(i.editOriginPrice) * 100).toFixed(0)
                                                setAttrs([...attrs])
                                            }).catch(e => {
                                                message.error(e)
                                                change()
                                            })
                                        }
                                        change.call(this)
                                    }} type='number' value={i.editOriginPrice} style={{ width: 90, border: 0, borderBottom: '1px solid #999', textAlign: 'center', color: 'var(--theme-main)', fontSize: 16 }} />
                                    <span>元</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Modal>
        }


        const onClose = () => {
            if (confirmWrap) {
                ReactDom.render(<></>, confirmWrap)
                document.body.removeChild(confirmWrap)
                confirmWrap = undefined
            }
            res()
        }
        const onConfirm = (attrs) => {
            if (confirmWrap) {
                ReactDom.render(<></>, confirmWrap)
                document.body.removeChild(confirmWrap)
                confirmWrap = undefined
            }
            res(attrs)
        }

        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDom.render(<HemaGoodsNumSetting {...props} />, confirmWrap)
    })

}