import React, { useEffect, useState, } from 'react';
import ReactDom from 'react-dom';

import { DatePicker, } from 'antd';
import zhCN from 'antd/lib/date-picker/locale/zh_CN';


import Modal from '../../total/Modal';
import BTN from '../../../StyleComponents/Button';

import moment from 'moment';
import { API } from '../../../data/options';
import { getToken } from '../../../netApi/fetch';

type ExportType = 1 | 0 // 1为门店维度 0为帮卖好友维度

type QuickTime = 'today' | 'yesterday' | 'day before yesterday'

export default function () {
    let confirmWrap: Element

    const Export = () => {
        const [exportType, setType] = useState<ExportType>(1)

        const [quickTime, setQuickTime] = useState<QuickTime>()

        const [start, setStart] = useState<moment.Moment>()
        const [end, setEnd] = useState<moment.Moment>()

        useEffect(() => {
            switch (quickTime) {
                case 'today':
                    setStart(moment().startOf('day'))
                    setEnd(moment().endOf('day'))
                    break
                case 'yesterday':
                    setStart(moment().subtract(1, 'days').startOf('day'))
                    setEnd(moment().subtract(1, 'days').endOf('day'))
                    break
                case 'day before yesterday':
                    setStart(moment().subtract(2, 'days').startOf('day'))
                    setEnd(moment().subtract(2, 'days').endOf('day'))
                    break
                default:
                    break
            }
        }, [quickTime])

        useEffect(() => {
            if (start && end) {
                if (start.isAfter(end)) {
                    setStart(moment(end))
                    setEnd(moment(start))
                }
            }
        }, [start, end])

        return <Modal title='导出售卖数据' onClose={onClose} onConfirm={confirm} style={{
            outer: {
                width: 640,
            },
            content: {
                height: 325,
            },
        }} >
            <div style={{
                border: '1px solid #d9d9d9',
                borderRadius: 4,
                padding: 16,
            }}>
                <div className='flex-box' style={{ marginBottom: 10 }}>
                    <div style={{ marginRight: 16, fontWeight: 600, }}>选择导出维度</div>
                    <div className='flex-box'>
                        <BTN color={exportType === 1 ? 'red' : 'gray'} solid={exportType === 1} onClick={() => {
                            setType(1)
                        }} style={{ marginRight: 16, }}>门店维度</BTN>
                        <BTN color={exportType === 0 ? 'red' : 'gray'} solid={exportType === 0} onClick={() => {
                            setType(0)
                        }} >帮卖好友维度</BTN>
                    </div>
                </div>
                <div style={{ color: '#999', fontSize: 12, marginLeft: 100, marginBottom: 16 }}>门店维度：统计各个门店每个商品售卖的数据</div>
                <div style={{ marginRight: 16, fontWeight: 600, marginBottom: 25 }}>选择售卖数据时间</div>
                <div className='flex-box' style={{ marginBottom: 20, paddingLeft: 30, }}>
                    <div style={{ marginRight: 16, }}>快速选择</div>
                    <div className='flex-box'>
                        <BTN color={quickTime === 'today' ? 'red' : 'gray'} onClick={() => {
                            setQuickTime('today')
                        }} style={{ marginRight: 16, }}>今天</BTN>
                        <BTN color={quickTime === 'yesterday' ? 'red' : 'gray'} onClick={() => {
                            setQuickTime('yesterday')
                        }} style={{ marginRight: 16, }}>昨天</BTN>
                        <BTN color={quickTime === 'day before yesterday' ? 'red' : 'gray'} onClick={() => {
                            setQuickTime('day before yesterday')
                        }} style={{ marginRight: 16, }} >前天</BTN>
                    </div>
                </div>
                <div className='flex-box' style={{ paddingLeft: 30, }}>
                    <div style={{ marginRight: 16, }}>开始时间</div>
                    <DatePicker style={{ marginRight: 40, width: 170, }} locale={zhCN} showTime value={start} onChange={e => {
                        setStart(e)
                    }} />
                    <div style={{ marginRight: 16, }}>结束时间</div>
                    <DatePicker style={{ width: 170, }} locale={zhCN} showTime value={end} onChange={e => {
                        setEnd(e)
                    }} />
                </div>
            </div>
        </Modal>

        function confirm() {
            let src = API + `/mall/exportgoodssalesstatistics?branch_dimension=${exportType}&types=1&token=${getToken()}&goods_ids=`
            if (start) src += `&start_time=${start.unix()}`
            if (end) src += `&end_time=${end.unix()}`
            window.open(src, '_blank')
        }
    }

    const onClose = () => {
        if (confirmWrap) {
            ReactDom.render(<></>, confirmWrap)
            document.body.removeChild(confirmWrap)
            confirmWrap = undefined
        }
    }

    if (!confirmWrap) {
        confirmWrap = document.createElement('div')
        document.body.appendChild(confirmWrap)
    }
    ReactDom.render(<Export />, confirmWrap)
}