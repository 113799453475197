import React, { Component } from 'react'

interface Props {
    onChange: (val: string) => void,
    disable?: boolean,
    value: string,
    fontSize?: number,
    placeholder?: string,
    style?: {
        border?: string,
        color?: string,
        transition?: string,
        transform?: string,
    }
}

class Textarea extends Component<Props, any>{

    render() {
        return <div style={{ position: 'relative', }}>
            <div style={{
                fontSize: this.props.fontSize || 14,
                padding: '.8em', width: '100%',
                minHeight: '4em', opacity: 0,
                whiteSpace: 'pre-wrap', wordBreak: 'break-all',
                paddingBottom: '2.3em'
            }}>
                {this.props.value || this.props.placeholder}
            </div>
            <textarea placeholder={this.props.placeholder}
                style={{
                    border: '1px solid rgb(235,235,235)', 
                    resize: 'none', 
                    color: '#666', fontSize: this.props.fontSize || 14,
                    padding: '.7em', 
                    width: '100%', height: '100%', 
                    position: 'absolute', left: 0, top: 0,
                    outline: 0,
                    ...this.props.style,
                }}
                disabled={this.props.disable}
                value={this.props.value} onChange={(e) => {
                    this.props.onChange(e.target.value)
                }} />
        </div>
    }
}

export default Textarea