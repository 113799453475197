import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../total/Modal';
import styled from 'styled-components';
// import BTN from '../../../StyleComponents/Button';
// import { message } from 'antd';
import { get, post } from '../../../netApi/fetch';
import { message } from 'antd';
let confirmWrap: Element
interface props {
    mall: mall.mallinfo,
    masterid?: number | string
}

interface postdata {
    mall_id: string,
}
const Item = styled.div<{ active: boolean }>`
    width: 138px;
    height: 70px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: ${props => props.active ? '#fff' : ''};
    border-color:${props => props.active ? 'var(--theme-main)' : ''};
    margin-bottom: 16px;
    padding: 16px;
    &:hover{
        background: #f8f5f5;
    }
    &:not(:nth-child(4n)){
        margin-right: 16px;
    }
    ${props => props.active ? 'background: var(--theme-main)!important;' : ''}
`
export default function (props: props) {
    return new Promise<boolean>((res, rej) => {
        function Create(p) {
            const [branchList, setBranchList] = useState<any[]>()
            const loading = useRef(false)
            const [active,
                // setActive,
            ] = useState<number>()
            if (!branchList && !loading.current) {
                get('/mall/getbranchmalllist', {
                    mall_id: props.masterid || props.mall.id,
                    offset: 0, limit: 999999,
                }).then(res => {
                    setBranchList(res.list)
                    loading.current = true
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            return <Modal title='切换主页' cancel={true} onClose={cancel} style={{ content: { maxHeight: 600, overflowX: 'auto' } }} onConfirm={() => {
                if (active === undefined) {
                    message.warn('请选择店铺')
                    return
                }
                const data: postdata = {
                    mall_id: branchList[active].id,
                }
                post('/mall/switchbindmall', data).then(() => {
                    res(true)
                    cancel()
                }).catch(e => { if (e.message) message.error(e.message) })
            }}  >
                <div className='hiddenScrollBar' style={{ width: 610, height: 300, overflowY: 'auto', }}>
                    <div className='flex-box' style={{ flexWrap: 'wrap', alignItems: 'flex-start' }} >
                        {(branchList || []).map((item, i) => {
                            return (
                                <Item key={i} active={active === i} className='flex-box cur-pointer'
                                    onClick={() => {
                                        const data: postdata = {
                                            mall_id: branchList[i].id,
                                        }
                                        post('/mall/switchbindmall', data).then(() => {
                                            res(true)
                                            cancel()
                                        }).catch(e => { if (e.message) message.error(e.message) })
                                    }}>
                                    <div style={{ marginRight: 8 }}><img className='img-center' style={{ width: 30, height: 30, borderRadius: '50%', }} alt='头像' src={item.logo} /></div>
                                    <div style={{ wordBreak: "break-all" }}>{item.branch_name}</div>
                                    {i === 0 ? (
                                        <div style={{
                                            minWidth: 40, height: 20, fontSize: 12, lineHeight: '20px', textAlign: 'center', borderRadius: 10, marginLeft: 8, background: '#fd5356', color: '#fff'
                                        }}>
                                            总部
                                        </div>
                                    ) : ''}
                                </Item>
                            )
                        })}
                    </div>
                </div>
            </Modal>
        }

        function cancel() {
            if (confirmWrap) {
                ReactDOM.render(null, confirmWrap)
                document.body.removeChild(confirmWrap)
                confirmWrap = undefined
            }
            res(false)
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Create {...props} />, confirmWrap)
    })
}