import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router';
import styled from 'styled-components';
import { get } from '../../netApi/fetch';
import { selectAddress } from '../../reducers';
import Loading from '../Method/Loading';
import BTN from '../../StyleComponents/Button'
import Wx_sign from '../../high-component/Wx_sign'

interface useraddress {
    addressdetail: string
    area: string
    city: string
    contact_num: string
    contacts: string
    createtime: string
    customer_id: string
    delivery: string
    id: string
    name: string
    province: string
    telphone: string
    uid: string
    address: {
        address: string
        area: string
        city: string
        contact_num: string
        contacts: string
        customer_id: string
        id: string
        logistics_mode: string
        province: string
        remarks: string
    }
}

interface addressitem {
    id: string
    name: string
    address: string
    contact: string
    mobile: string
}

interface props extends RouteComponentProps {
    match: m,
    setAddress: (item: {
        value: any, type: 'address' | 'point' | 'wechat'
    }) => void
}

interface m extends match {
    params: {
        tuanid?: string
    }
}

const W = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-right: 14px;
`

const L = styled.div`
    font-size: 14px;
    color: #999;
`

const Item = styled.div`
    padding: 16px 22px;
    border-bottom: #ededed 1px solid;
`

const S = function (props: props) {
    const [userAddressList, setUserAddressList] = useState<useraddress[]>()
    const addressOffset = useRef(0)
    const addressCount = useRef(Infinity)
    const addressGetting = useRef(false)
    useEffect(() => {
        if (!userAddressList) {
            if (addressGetting.current) return
            addressGetting.current = true
            get('/customer/arrearsList', {
                type: 0,
                customer_type: 1,
                offset: addressOffset.current,
                limit: 10,
            }).then(res => {
                addressGetting.current = false
                addressCount.current = Number(res.count)
                addressOffset.current += res.list.length
                setUserAddressList([...res.list])
            })
        }
    }, [userAddressList])

    const [addressList, setAddressList] = useState<addressitem[]>()
    useEffect(() => {
        if (props.match.params.tuanid && !addressList) {
            get('/address/getexpresspickuppointlistbytuanid', {
                offset: 0, limit: 99999,
                tuanid: encodeURIComponent(btoa(props.match.params.tuanid)),
            }).then(res => {
                setAddressList(res.list)
            })
        }
    }, [props.match.params.tuanid, addressList])

    useEffect(() => {
        function getdata(e: Event) {
            const h = document.documentElement.scrollHeight
            const t = (e.target as Document).documentElement.scrollTop || (e.target as Document).body.scrollTop
            const ch = document.documentElement.clientHeight
            const bottom = h - t - ch
            if (bottom < 200) {
                if (addressGetting.current) return
                addressGetting.current = true
                get('/customer/arrearsList', {
                    type: 0,
                    customer_type: 1,
                    offset: addressOffset.current,
                    limit: 10,
                }).then(res => {
                    addressGetting.current = false
                    addressCount.current = Number(res.count)
                    addressOffset.current += res.list.length
                    setUserAddressList([...userAddressList, ...res.list])
                })
            }
        }
        document.addEventListener('scroll', getdata)
        return () => {
            document.removeEventListener('scroll', getdata)
        }
    })

    return <div style={{ paddingTop: 50 }}>
        {!!addressList && <div>
            <Item>快递到自提门店</Item>
            {addressList.map(address => {
                return <Item key={address.id} onClick={() => {
                    const username = prompt('请填写联系人')
                    const phone = prompt('请填写联系电话')

                    Loading.show()
                    get('/address/getone', { id: encodeURIComponent(btoa(address.id)) }).then(res => {
                        Loading.hide()
                        props.setAddress({
                            value: {
                                address: res[0], username, phone
                            }, type: 'point'
                        }); props.history.goBack()
                    }).catch(e => {
                        if (e.message) message.error(e.message)
                        Loading.hide()
                    })
                }} >
                    <W>{address.name}</W>
                    <L>地址：{address.address}</L>
                </Item>
            })}
            <div style={{ background: '#f7f8f9', height: 20 }}></div>
        </div>}
        <Item>快递到我的地址</Item>
        {(userAddressList || []).map(i => {
            return <Item key={i.id} onClick={() => { props.setAddress({ value: i, type: 'address' }); props.history.goBack() }} >
                <div className='flex-box'>
                    <W>{i.contacts}</W>
                    <L>{i.telphone}</L>
                </div>
                <L>地址：{i.province}{i.city}{i.area}{i.addressdetail} </L>
            </Item>
        })}
        <div className='flex-center' style={{
            position: 'fixed', bottom: 60, left: 0, width: '100vw',
        }}>
            <BTN big color='#1AAD19' solid onClick={() => {
                // @ts-ignore
                wx.ready(function () {
                    // @ts-ignore
                    wx.openAddress({
                        success: function (res) {
                            props.setAddress({
                                type: 'wechat',
                                value: {
                                    addressinfo: {
                                        address: res.detailInfo,
                                        area: res.countryName,
                                        city: res.cityName,
                                        province: res.provinceName,
                                        town: '',
                                    },
                                    username: res.userName,
                                    phone: res.telNumber,
                                }
                            })
                            props.history.goBack()
                        }
                    })
                })
            }} >选择微信地址</BTN>
        </div>
    </div>

}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setAddress: (data) => {
            dispatch(selectAddress(data))
        }
    }
}

export default Wx_sign(connect(mapStateToProps, mapDispatchToProps)(S))