import React, { Component } from 'react';
import parseParams from '../../methods/paramParse';


class Empty extends Component {
    componentDidMount() {
        const search = parseParams(this.props.location.search)
        if(search.to){
            this.props.history.push(decodeURIComponent(search.to))
            return
        }
        this.props.history.goBack()
    }
    render() {
        return (
            <div />
        )
    }
}
export default Empty