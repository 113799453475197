import React, { useState, useEffect, } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import Modal from '../../total/Modal';
import zhCN from 'antd/es/locale/zh_CN';
import Select from '../../total/Select_';
import { DatePicker, message, Radio } from 'antd';
import moment from 'moment';
import { API, } from '../../../data/options';
import { get, getToken } from '../../../netApi/fetch';

let confirmWrap: Element

interface Props {
    type: '1' | '2'  // 1导出 2打印
    isBranch: boolean
    branchlist: any[]
    branch_id?: string | number
    relation_id?: string | number
}

const Border = styled.div`
    padding: 16px;
    min-width: 600px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 400px;
    max-height: calc(80vh - 50px);
    overflow-y: auto;
    & > div{
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        strong{
            display: inline-block;
            min-width: 6em;
            margin-right: 16px;
        }
    }
`

const Delivery = styled.div<{ active: boolean }>`
    width: 68px;
    height: 30px;
    border: 1px solid ${props => props.active ? 'var(--theme-main)' : '#ccc'};
    background: ${props => props.active ? 'var(--theme-main)' : '#fff'};
    color: ${props => props.active ? '#fff' : '#999'};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
`

const Time = styled(Delivery)`
    color: ${props => props.active ? 'var(--theme-main)' : '#999'};
    border: 1px solid ${props => props.active ? 'var(--theme-main)' : '#ccc'};
    background: #fff;
`

const Line = styled.div`
    height: 1px;
    background: #d9d9d9;
    margin-bottom: 20px;
`


const TableItem = styled('div') <{ selected: boolean, }>`
    cursor: pointer;
    /* margin-right: 30px; */
    display: flex;
    flex-basis: 100%;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 12px;
    i{
        color: ${props => props.selected ? 'var(--theme-main)' : '#ccc'};
        margin-right: 6px;
    }
`

type delivery = 1 | 2 | 3
type time = 'today' | 'yesterday' | 'before_yesterday'



export const ExportPickData = (props: Props) => {
    return new Promise<void>((res, rej) => {
        function ExportPickData(props: Props) {

            // 配送方式
            const [delivery, setDelivery] = useState<delivery>()

            // 时间
            const [time, setActiveTime] = useState<time>()
            const [startTime, setStartTime] = useState<moment.Moment>()
            const [endTime, setEndTime] = useState<moment.Moment>()
            useEffect(() => {
                if (time) {
                    const start = moment()
                    switch (time) {
                        case 'today':
                            start.hour(0)
                            start.minute(0)
                            start.second(0)
                            setStartTime(start)
                            setEndTime(moment(start).add(1, 'days'))
                            break;
                        case 'before_yesterday':
                            start.hour(0)
                            start.minute(0)
                            start.second(0)
                            start.subtract(2, 'days')
                            setStartTime(start)
                            setEndTime(moment(start).add(1, 'days'))
                            break
                        case 'yesterday':
                            start.hour(0)
                            start.minute(0)
                            start.second(0)
                            start.subtract(1, 'days')
                            setStartTime(start)
                            setEndTime(moment(start).add(1, 'days'))
                            break
                        default:
                            break;
                    }
                }
            }, [time])

            const [branchlist,
                // setBranchList,
            ] = useState((props.branchlist || []).map(i => {
                return {
                    ...i, showName: i.branch_name || '总部'
                }
            }))
            const [bIdx, setBranchIndex] = useState<number>()

            const status = [{ name: '全部', value: 0 }, { name: '已完成', value: 1 }, { name: '未完成', value: 2 }]
            const [order_status, setOS] = useState(0)

            // 排序
            const [sort, setSort] = useState('asc')

            // 导出退款
            const [refund, setRefund] = useState(false)

            // 导出表格类型
            const [exportTable, setExportTable] = useState<Set<number>>(new Set())

            // 打印类型
            const [printTable, setPrintTable] = useState<number>()
            useEffect(() => {
                setPrintTable(undefined)
                if (exportTable.has(3) && delivery !== 2) {
                    exportTable.delete(3)
                    setExportTable(new Set(exportTable))
                }
            }, [delivery, exportTable])

            const [sellerlist, setSellerlist] = useState<any[]>()
            const [sidx, setSellerIndex] = useState<number>()
            useEffect(() => {
                setSellerIndex(undefined)
                let branch_id = bIdx ? branchlist[bIdx - 1].id : 0
                if (props.isBranch && props.branch_id) {
                    branch_id = props.branch_id
                }
                get('/mall/sellerlist', {
                    branch_id,
                    offset: 0,
                    limit: 999999,
                }).then(({ list }) => setSellerlist(list)).catch(e => { if (e.message) message.error(e.message) })
            }, [branchlist, bIdx, props])

            return <Modal title={props.type === '1' ? '导出数据' : '打印单据'} banAnime cancel={false} onConfirm={onConfirm}
                onClose={onClose} >
                <Border>
                    <div style={{ display: 'block' }}>
                        <div style={{ marginBottom: 20 }}><strong>选择订单创建时间</strong><span style={{ fontSize: 12, color: '#aaa' }}>不选择时间，则导出全部时间段订单</span></div>
                        <div className='flex-box' style={{ marginBottom: 20 }}>
                            <div style={{ marginRight: 14 }} >快速选择</div>
                            <Time onClick={() => { setActiveTime('today') }} active={time === 'today'}>今天</Time>
                            <Time onClick={() => { setActiveTime('yesterday') }} active={time === 'yesterday'}>昨天</Time>
                            <Time onClick={() => { setActiveTime('before_yesterday') }} active={time === 'before_yesterday'}>前天</Time>
                        </div>
                        <div className='flex-box' style={{ marginBottom: 20 }}>
                            <div style={{ padding: 14 }}>开始时间</div>
                            <DatePicker style={{ borderRadius: 4 }} value={startTime} locale={zhCN.DatePicker} showTime onChange={e => {
                                if (!e) setStartTime(undefined)
                                if (e) setStartTime(e)
                            }} />
                            <div style={{ padding: 14 }}>结束时间</div>
                            <DatePicker style={{ borderRadius: 4 }} disabledDate={(date) => {
                                if (startTime) {
                                    return date <= moment(startTime).endOf('day')
                                }
                                return false
                            }} value={endTime ? endTime : null} locale={zhCN.DatePicker} showTime onChange={e => {
                                if (!e) setEndTime(undefined)
                                if (e) setEndTime(e)
                            }} />
                        </div>
                    </div>
                    <div>
                        <strong>选择配送方式</strong>
                        <Delivery active={delivery === 2} onClick={() => { setDelivery(2) }} >自提</Delivery>
                        <Delivery active={delivery === 1} onClick={() => { setDelivery(1) }} >快递配送</Delivery>
                        <Delivery active={delivery === 3} onClick={() => { setDelivery(3) }} >骑手配送</Delivery>
                    </div>
                    {!props.isBranch && !props.relation_id && <div>
                        <strong>选择门店</strong>
                        <Select list={[{ showName: '所有门店', }, ...branchlist]} maxHeight={delivery > 0 ? 300 : 230} placeholder='所有门店总和' showKey='showName' value={bIdx} uni='branch' onChange={(e, i) => {
                            setBranchIndex(i)
                        }} />
                    </div>}

                    <div>
                        <strong>选择订单状态</strong>
                        <Select uni='status' list={status} showKey='name' value={status.findIndex(i => i.value === order_status)} onChange={e => {
                            setOS(e.value)
                        }} />
                    </div>

                    <div style={{ display: "block" }}>
                        {delivery > 0 && <>
                            <Line />
                            <div style={{ flexWrap: "wrap" }}>
                                {/* <div className='flex-box' style={{width: '50%'}}>
                                <strong>选择商品</strong>
                                <Select onChange={e => {}} uni='good' showKey='' list={[]} />
                            </div> */}
                                <div className='flex-box' style={{ width: '50%', marginBottom: 20 }} >
                                    <strong>选择帮卖好友</strong>
                                    <Select onChange={(e, i) => { setSellerIndex(i) }} maxHeight={delivery > 0 ? 300 : 230} placeholder='选择帮卖好友' uni='seller' value={sidx} showKey='name' list={sellerlist} />
                                </div>
                                {/* <div className='flex-box' style={{width: '50%'}}>
                                <strong>选择自提点</strong>
                                <Select onChange={e => {}} uni='pick' showKey='' list={[]} />
                            </div> */}
                            </div>
                            <Line />
                            <div className='flex-box'>
                                <div style={{ marginBottom: 20 }} onClick={() => { setSort('asc') }}>
                                    <Radio checked={sort === 'asc'}>最早订单排最前</Radio>
                                </div>
                                <div style={{ marginBottom: 20 }} onClick={() => { setSort('desc') }}>
                                    <Radio checked={sort === 'desc'}>最晚订单排最前</Radio>
                                </div>
                            </div>
                            {props.type === '1' && <div className='flex-box'>
                                <div style={{ marginBottom: 20 }} onClick={() => { setRefund(false) }}>
                                    <Radio checked={!refund}>不导出全部退款订单</Radio>
                                </div>
                                <div style={{ marginBottom: 20 }} onClick={() => { setRefund(true) }}>
                                    <Radio checked={refund}>导出所有订单（含全部退款订单）</Radio>
                                </div>
                            </div>}
                            <Line />
                            {props.type === '1' && <div style={{ padding: '0' }}>
                                <div style={{ color: '#999', fontSize: 12, margin: '20px 0' }}>选择要导出的表格类型：</div>
                                <div>
                                    <TableItem selected={exportTable.has(2)} onClick={() => {
                                        exportTable[exportTable.has(2) ? 'delete' : 'add'](2)
                                        setExportTable(new Set(exportTable))
                                    }}>
                                        <i className={`iconfont ${exportTable.has(2) ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                        <div>消费者订单（不合并展示同个消费者购买的不同商品）</div>
                                    </TableItem>
                                    <TableItem selected={exportTable.has(1)} onClick={() => {
                                        exportTable[exportTable.has(1) ? 'delete' : 'add'](1)
                                        setExportTable(new Set(exportTable))
                                    }}>
                                        <i className={`iconfont ${exportTable.has(1) ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                        <div>消费者订单（合并展示同个消费者购买的不同商品）</div>
                                    </TableItem>
                                    {delivery === 2 && <>
                                        <TableItem selected={exportTable.has(3)} onClick={() => {
                                            exportTable[exportTable.has(3) ? 'delete' : 'add'](3)
                                            setExportTable(new Set(exportTable))
                                        }}>
                                            <i className={`iconfont ${exportTable.has(3) ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                            <div>自提点拣货单</div>
                                        </TableItem>
                                    </>}
                                    <TableItem selected={exportTable.has(4)} onClick={() => {
                                        exportTable[exportTable.has(4) ? 'delete' : 'add'](4)
                                        setExportTable(new Set(exportTable))
                                    }}>
                                        <i className={`iconfont ${exportTable.has(4) ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                        <div>商品总单</div>
                                    </TableItem>
                                    {!props.isBranch && branchlist.length > 1 && <TableItem selected={exportTable.has(5)} onClick={() => {
                                        exportTable[exportTable.has(5) ? 'delete' : 'add'](5)
                                        setExportTable(new Set(exportTable))
                                    }}>
                                        <i className={`iconfont ${exportTable.has(5) ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                        <div>门店销售统计</div>
                                    </TableItem>}
                                </div>
                            </div>}
                            {props.type === '2' && <div style={{ padding: '0' }}>
                                <div style={{ color: '#999', fontSize: 12, margin: '20px 0' }}>选择要导出的表格类型：</div>
                                <div>
                                    <TableItem selected={printTable === 1} onClick={() => { setPrintTable(1) }}>
                                        <i className={`iconfont ${printTable === 1 ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                        <div>打印消费者面单</div>
                                    </TableItem>
                                    <TableItem selected={printTable === 2} onClick={() => { setPrintTable(2) }}>
                                        <i className={`iconfont ${printTable === 2 ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                        <div>打印商品总单</div>
                                    </TableItem>
                                    {delivery === 2 && <>
                                        <TableItem selected={printTable === 3} onClick={() => { setPrintTable(3) }}>
                                            <i className={`iconfont ${printTable === 3 ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                            <div>打印仓库拣货单</div>
                                        </TableItem>
                                        <TableItem selected={printTable === 4} onClick={() => { setPrintTable(4) }}>
                                            <i className={`iconfont ${printTable === 4 ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                            <div>打印门店对货单&消费者订单</div>
                                        </TableItem>
                                    </>}
                                </div>
                            </div>}
                        </>}
                    </div>
                </Border>
            </Modal>
            async function onConfirm() {
                const data = {
                    shipping_mode: delivery,
                    order_status,
                    branch_mall_id: branchlist[bIdx - 1]?.id || 0,
                    sell_sid: (sellerlist || [])[sidx]?.id || 0,
                    start: startTime?.unix() || '',
                    end: endTime?.unix() || '',
                    sort: (() => {
                        if (sort === 'asc') return 1
                        if (sort === 'desc') return 0
                        return undefined
                    })(),

                    data_type: (() => {
                        if (printTable === 1) return 'face_sheet'
                        if (printTable === 2) return 'goods_order'
                        if (printTable === 3) return 'consumer_order'
                        if (printTable === 4) return 'consumer_order'
                    })(),
                    relation_id: props.relation_id,
                }
                if (!data.shipping_mode) {
                    message.warn('请选择配送方式')
                    return
                }
                if (props.type === '1') {
                    if (!Array.from(exportTable).length) {
                        message.warn('请选择表格类型')
                        return
                    }
                    const src = (() => {
                        let base = API + '/pcorder/exportmallorderlist?'
                        base += `sheet_list=${Array.from(exportTable).join(',')}&` // 表格类型
                        base += `shipping_mode=${data.shipping_mode}&` // 配送方式
                        base += `status=${data.order_status || 0}&` // 订单状态
                        if (data.start) base += `start=${data.start}&` // 开始时间
                        if (data.end) base += `end=${data.end}&` // 结束时间
                        if (data.relation_id) base += `relation_id=${data.relation_id}&` // relation_id
                        base += `sort=${sort}&` // 排序
                        base += `refund_all=${Number(refund)}&` // 退款订单
                        base += `branch_ids=${data.branch_mall_id}&` // 分店
                        base += `seller_sid=${data.sell_sid}&`
                        base += `types=1&`
                        base += `token=${getToken()}&`
                        return base
                    })()
                    // let res: Response
                    // try {
                    //     res = await fetch(src)
                    // } catch (error) {
                    //     message.error('访问出错，请稍后重试')
                    // }
                    // if (!res) return
                    // if (res.status !== 200) {
                    //     message.error('访问出错，请稍后重试')
                    //     return
                    // }
                    // const json = res.clone()
                    // let isJson: boolean
                    // try {
                    //     const data = await json.json()
                    //     console.log(data);
                    //     Prompt({
                    //         type: 'string',
                    //         title: '发送到邮箱'
                    //     }).then(res => {

                    //     })
                    //     isJson = true
                    // } catch (error) {
                    //     isJson = false
                    // }
                    // if (isJson) return
                    // const blob = res.clone()
                    // blob.blob().then(data => {
                    //     var a = document.createElement('a');
                    //     document.body.appendChild(a) //兼容火狐，将a标签添加到body当中
                    //     var url = window.URL.createObjectURL(data);   // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
                    //     a.href = url;
                    //     let name = res.headers.get('content-disposition').split('=')[1]
                    //     a.download = name
                    //     a.target = '_blank'  // a标签增加target属性
                    //     a.click();
                    //     a.remove()  //移除a标签
                    //     window.URL.revokeObjectURL(url);
                    // })
                    // return
                    window.open(src, '_blank')
                }
                if (props.type === '2') {
                    if (!printTable) {
                        message.warn('请选择打印表单类型')
                        return
                    }
                    const keys: string[] = Object.keys(data)
                    let url = `/mallprint/${printTable}`
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        const value = data[key]
                        if (i === 0) {
                            url += `?${key}=${value}`
                        } else { url += `&${key}=${value}` }
                    }
                    window.open(url, '_blank')
                }
            }
        }


        const onClose = () => {
            if (confirmWrap) {
                ReactDom.render(<></>, confirmWrap)
                document.body.removeChild(confirmWrap)
                confirmWrap = undefined
            }
            res()
        }

        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDom.render(<ExportPickData {...props} />, confirmWrap)
    })

}