import React, { Component } from 'react'

// import Main from '../../high-component/Content';

class Batch extends Component {
    constructor() {
        super()
        this.state = {
            preview: false
        }
    }
    render() {
        return (
            <div style={{
                minHeight: '100vh',
                background: '#eceef3',
            }}>
                <div style={{ maxWidth: 1000, margin: '0 auto' }}>
                    <h1 style={{ paddingTop: 30 }}>如何批量发货？</h1>
                    <ol style={{ marginBottom: 0, paddingBottom: 30 }}>
                        <li style={{ margin: '30px 0' }}>导出数据，下载Excel表格</li>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ flexBasis: '40%' }}>
                                <img style={{ width: '100%', background: 'white' }} alt='点击放大查看' onClick={(e) => { this.setState({ preview: e.target.src }) }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/20191012/QgbQX2dB1e.jpg' />
                            </div>
                            <span style={{ flex: 1 }} className='fcc'>或</span>
                            <div style={{ flexBasis: '40%' }}>
                                <img style={{ width: '100%', background: 'white' }} alt='点击放大查看' onClick={(e) => { this.setState({ preview: e.target.src }) }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/20191012/9r0eZeT20f.jpg' />
                            </div>
                        </div>
                        <li style={{ margin: '30px 0' }}>
                            按照表格格式填写好快递名称和快递单号，保存表格文件，例如：圆通 YT4132628326828
                        </li>
                        <img alt='点击放大查看' onClick={(e) => { this.setState({ preview: e.target.src }) }} style={{ width: '100%' }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/20191012/1maFhlA9hT.jpg' />
                        <li style={{ margin: '30px 0' }}>
                            <div>
                                点击上传表格按钮，选择刚刚保存的Excel表格，按确定，系统自动会填上对应订单的快递单号，并发货。
                            </div>
                            {/* <br/> */}
                            <span>注意：请勿删除Excel表格中，<span style={{color: 'var(--theme-main)'}}>订单号、快递公司、快递单号</span>三列</span>
                        </li>
                        <img alt='点击放大查看' onClick={(e) => { this.setState({ preview: e.target.src }) }} style={{ maxWidth: '40%' }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/20191012/Wt36NvQqGJ.jpg' />
                    </ol>
                </div>
                {this.state.preview ? (<div className='mask' onClick={() => { this.setState({ preview: false }) }}>
                    <img alt='' style={{
                        maxWidth: '90vw', maxHeight: '90vh'
                    }} src={this.state.preview} />
                </div>) : ''}
            </div>
        )
    }
}

export default (Batch)