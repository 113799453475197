import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { get, getToken, } from '../../../netApi/fetch'
import styled from 'styled-components'
import { DatePicker, message, } from 'antd'
import Select from '../../total/Select_'
import BTN from '../../../StyleComponents/Button'
import moment from 'moment'
import zhCN from 'antd/es/locale/zh_CN';
import Textarea from '../../total/Input/Textarea'
import { API } from '../../../data/options'
import { confirm as Confirm } from '../../Method/Confirm';
import Loading from '../../Method/Loading'

interface props {
    onClose: () => void
    data: {
        title: string,
        desc: string,
        start_time: string,
        end_time: string,
        promotionActivityMall: {
            mall_id: string
        }[]
    }
}

const Border = styled.div`
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    padding: 0 10px;
    &:not(:last-child){
        margin-bottom: 16px;
    }
    & > div{
        padding: 12px 0;
        /* display: flex;
        align-items: flex-start;
        justify-content: space-between; */
        &:not(:last-child){
            /* border-bottom: #f1f1f1 solid 1px; */
        }
    }
`

const Input = styled.input`
    font-size: 14px;
    border: 1px solid #ebebeb;
    padding: 6px 8px;
    width: 100px;
`

let wrapper: Element


export default async function (mall_id: number, id?: number,) {
    return new Promise<void>(async (reslove, reject) => {
        function Mall(props: props) {
            const [name, setName] = useState(props.data.title || '')

            const [timelong, setlong] = useState('') // 持续时间
            const [startTime, setStartTime] = useState<number>(props.data.start_time ? moment(props.data.start_time).valueOf() : undefined)
            const [endTime, setEndTime] = useState<number>(props.data.end_time ? moment(props.data.end_time).valueOf() : undefined)

            useEffect(() => {
                if (timelong && startTime) {
                    setEndTime(moment(startTime).add(timelong, 'days').valueOf())
                }
            }, [timelong, startTime])

            const [branchlist, setBranchlist] = useState<any[]>()
            useEffect(() => {
                if (!branchlist) {
                    const hasSelected = (props.data.promotionActivityMall || []).map(i => Number(i.mall_id))
                    get('/mall/getmallbranchgrouplist', {}).then(res => {
                        setBranchlist([{ showName: '总部', id: mall_id, select: hasSelected.includes(mall_id) || false }, ...res.reduce((prev, item) => {
                            if (item.id === 'all') return prev
                            if (item.branch_list && item.branch_list.length === 0) return prev
                            return [...prev, { ...item, showName: item.name, select: false }, ...item.branch_list.map(i => {
                                return {
                                    ...i,
                                    showName: i.branch_name,
                                    select_sub_item: 1,
                                    select: hasSelected.includes(Number(i.id)) || false
                                }
                            })]
                        }, [])])
                    }).catch(e => { })
                }
            }, [branchlist, props.data])

            const [desc, setDetail] = useState(props.data.desc || '')

            const [showString, setShowString] = useState('')

            useEffect(() => {
                const s = ((): string => {
                    if (!branchlist) return ''
                    const l = branchlist.filter(i => ((i.branch_group_id && i.select) || (i.select && i.id === mall_id))).length
                    if (l > 0) return `已选择${l}个门店`
                    return ''
                })()
                setShowString(s)
            }, [branchlist])

            const file = useRef<File>()
            const [shouldClose, setShouldClose] = useState(false)
            return <Modal title={`${id ? '编辑' : '创建'}活动`} onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm} style={{
                content: {
                    height: 450, width: 670, overflowY: 'auto',
                }
            }} >
                {/* <div style={{ height: 450, width: 640, overflowY: 'auto', }} className='' > */}
                <Border>
                    <div className='flex-box'>
                        <div style={{ marginRight: 16 }}>活动名称</div>
                        <Input placeholder='最多可输入5个字' maxLength={10} style={{ width: '12em' }} value={name} onChange={e => { setName(e.target.value) }} />
                    </div>
                </Border>
                <Border>
                    <div>活动时间</div>
                    <div className='flex-box'>
                        <div style={{ marginRight: 16 }}>持续时长</div>
                        <Input type='number' placeholder='选填，填写后自动计算结束时间' maxLength={5} style={{ width: '20em' }} value={timelong} onChange={e => {
                            setlong(e.target.value)
                            if (!startTime) {
                                setStartTime(moment().valueOf())
                            }
                        }} />
                        <div>天</div>
                    </div>
                    <div className='flex-box'>
                        <div style={{ marginRight: 16 }}>开始时间</div>
                        <DatePicker style={{ marginRight: 40 }} showTime locale={zhCN.DatePicker} value={startTime ? moment(startTime) : undefined} onChange={e => {
                            if (!e) {
                                setStartTime(undefined)
                                return
                            }
                            setStartTime(e.valueOf())
                        }} />
                        <div style={{ marginRight: 16 }}>结束时间</div>
                        <DatePicker showTime locale={zhCN.DatePicker} value={endTime ? moment(endTime) : undefined} onChange={e => {
                            if (!e) {
                                setEndTime(undefined)
                                return
                            }
                            setEndTime(e.valueOf())
                            setlong('')
                        }} />
                    </div>
                </Border>
                {!!branchlist && branchlist.length > 0 && <Border>
                    <div className='flex-box'>
                        <div style={{ marginRight: 16 }}>选择上架门店</div>
                        {(() => {
                            const list = branchlist || []
                            const value = list.map((i, idx) => i.select ? idx : -1).filter(i => i !== -1)
                            return <Select uni='branch' placeholder='请选择门店' showString={showString} multi list={list} maxHeight={200} value={value} showKey='showName' onChange={selectBranch} />
                        })()}
                    </div>
                </Border>}

                <Border>
                    <div>活动说明（选填）</div>
                    <div>
                        <Textarea value={desc} onChange={setDetail} placeholder='请输入活动说明内容' />
                    </div>
                </Border>
                <Border>
                    <div>批量上传活动商品</div>
                    <div className='flex-box'>
                        <div style={{ marginRight: 10 }}>下载表格模板并填写</div>
                        <div onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <a download href="https://jieligo-1259536745.cos.ap-guangzhou.myqcloud.com/uploads/wxacode/%E5%AF%BC%E5%85%A5%E5%95%86%E5%93%81%E5%88%B0%E5%95%86%E5%9F%8E%E6%B4%BB%E5%8A%A8.xlsx">
                                <BTN color='red'>
                                    下载模版
                                    </BTN>
                            </a>
                        </div>
                    </div>
                    <div className='flex-box'>
                        <div style={{ marginRight: 10 }}>上传表格</div>
                        <label style={{ cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <input type='file' id='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                                onChange={e => {
                                    if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                    file.current = e.target.files[0]
                                }} />
                            <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                        </label>
                    </div>
                    <div style={{ color: '#999' }}>温馨提示：同一个商品无法添加到两个商城活动中（包括已结束的活动）</div>
                </Border>
                <div style={{ height: 100 }}></div>
                {/* </div> */}
            </Modal>

            function selectBranch(e) {
                e.select = !e.select
                const list = branchlist.map(i => {
                    if (e.branch_list && i.branch_group_id) {
                        if (Number(i.branch_group_id) === Number(e.id)) {
                            return {
                                ...i, select: e.select
                            }
                        }
                    }
                    return i
                })
                setBranchlist(list)
            }

            function confirm() {
                if (!name) {
                    message.warn('请填写活动名称')
                    return
                }
                if (!startTime) {
                    message.warn('请填写活动开始时间')
                    return
                }
                const data = new FormData()
                data.append('title', name)
                data.append('start_time', moment(startTime).format('YYYY-MM-DD HH:mm:ss'))
                data.append('end_time', moment(endTime).format('YYYY-MM-DD HH:mm:ss'))
                const list = (branchlist || []).filter(i => ((i.branch_group_id && i.select) || (i.select && i.id === mall_id))).map(i => i.id)
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    data.append('branch_mall_id[]', String(item))
                }
                if (list.length === 0) data.append('branch_mall_id[]', '0')
                data.append('desc', desc)
                data.append('id', id ? String(id) : '')
                data.append('token', getToken())
                data.append('types', '1')
                data.append('file', file.current)
                const href = id ? '/mall/updatemallactivity' : '/mall/createmallactivity'
                const p = () => {
                    Loading.show()
                    fetch(API + href, {
                        method: 'post', body: data, mode: 'cors',
                        credentials: 'include',

                    }).then((res) => {
                        Loading.hide()
                        res.json().then((res) => {
                            if (res.code !== 0) {
                                let log: string = res.message
                                if (log.length > 400) log = log.slice(0, 400) + '...'
                                Confirm({
                                    text: log,
                                    title: '温馨提示'
                                })
                                return
                            }
                            message.success(`${id ? '编辑' : '创建'}成功`)
                            setShouldClose(true)
                            reslove()
                        })
                    }).catch(() => {
                        message.error('网络错误')
                    })
                }
                if (file.current) {
                    file.current.slice(0, 1).arrayBuffer().then(() => {
                        p()
                    }).catch(() => {
                        file.current = undefined
                        var F = document.getElementById('file') as HTMLInputElement;
                        F.value = ''
                        message.error('文件发生变动，请重新选择文件')
                    })
                } else {
                    p()
                }
            }
        }
        let data

        if (id) {
            try {
                data = await get('/mall/getonemallactivity', { activity_id: id })
            } catch (e) {
                if (e.message) message.error(e.message)
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Mall data={data || {}} onClose={() => {
            if (wrapper) {
                ReactDOM.render(null, wrapper)
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
            reject()
        }} />, wrapper)
    })
}