import React, { Component } from 'react'

import Modal from '../../total/Modal';

import styled from 'styled-components';

import { post } from '../../../netApi/fetch';
import { InputNumber, message } from 'antd';

import Select from '../../total/Select_';

interface State {
    mode: number,
    items: selectedItem[],
    shouldClose: boolean,
    refresh: boolean,
}
interface Props {
    order: order,
    onClose: (e: { refresh: boolean }) => never,
}
interface selectedItem extends order.good {
    selectedNum: number
}

const ModeSelect = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`
const Inner = styled.div`
    width: 60vw;
    max-width: 100%;
`
const Goodlist = styled.div`
    overflow-y: auto;
    max-height: 65vh;
    padding: 5px;
    &::-webkit-scrollbar-thumb {
        background: rgba(102,102,102,.6);
        border-radius: 3px;
    }
`
const Logo = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 20px;
`
const GoodName = styled.div`
    flex: 1;
    margin-right: 1rem;
    font-size: 16px;
    word-break: break-all;
    /* white-space: ; */
    span:nth-child(1){
        color: #333;
    }
    span:nth-child(2){
        color: #666;
    }
`
class Checkin extends Component<Props> {
    readonly state: State = {
        mode: 1,
        items: [],
        shouldClose: false,
        refresh: false
    }

    readonly modelist = [{ type: 1, name: '全部核销' }, { type: 2, name: '部分核销' }]

    componentDidMount() {
        this.setState({
            items: this.props.order.items.map(i => {
                return {
                    ...i,
                    refund_num: Number(i.refund_num) || 0,
                    checkin_num: Number(i.checkin_num) || 0,
                    selectedNum: 0,
                }
            })
        })
    }

    render() {
        return <Modal title='核销' onConfirm={async () => {
            const data: any = {
                orderid: this.props.order.orderid,
                code: this.props.order.exchangecode,
                checkin_mode: this.state.mode,
                types: 1,
            }
            if (this.state.mode === 1) {
                data.checkin_items = []
            } else if (this.state.mode === 2) {
                data.checkin_items = this.state.items.map(i => { return { id: i.id, num: i.selectedNum } }).filter(i => i.num)
                if (!data.checkin_items.length) {
                    message.warning('请选择核销商品')
                    return
                }
            }
            
            const checkin = await post('/order/exchange', data)
            console.log(checkin)
            this.setState({ shouldClose: true, refresh: true })
        }} shouldClose={this.state.shouldClose} onClose={() => { this.props.onClose({ refresh: this.state.refresh }) }} tips={(()=>{
            if(this.state.mode === 1)return ''
            return `已选择${this.state.items.reduce((acc,item)=>{return acc + item.selectedNum}, 0)}件商品`
        })()} >
            <Inner>
                <ModeSelect onClick={() => { }}>
                    <div>选择核销模式</div>
                    <div>
                        <Select list={this.modelist} uni='mode' showKey='name' value={this.modelist.findIndex(i => i.type === this.state.mode)} onChange={e => {
                            this.setState({ mode: e.type })
                        }} />
                    </div>
                </ModeSelect>
                <Goodlist>
                    {this.state.mode === 1 ? <div>
                        {this.props.order.items.map(i => {
                            return <div key={i.goodsid} className='fbc' style={{ alignItems: 'flex-start', marginBottom: 30, }}>
                                <Logo>
                                    <img src={i.logo} alt='' className='img-center' />
                                </Logo>
                                <GoodName><span>{i.name}</span><span>({i.unit})</span></GoodName>
                                <div className='fbc'>
                                    <div><span style={{ marginRight: '1rem', color: 'var(--theme-main)' }}>￥{((i.price / 100)).toFixed(2)}</span><span>数量： {i.num}</span></div>
                                </div>
                            </div>
                        })}
                    </div> : null}
                    {this.state.mode === 2 ? <div>
                        {this.state.items.map(i => {
                            return <div key={i.goodsid} style={{ marginBottom: 30, }}>
                                <div className='fbc' style={{ alignItems: 'flex-start' }}>
                                    <Logo>
                                        <img src={i.logo} alt='' className='img-center' />
                                    </Logo>
                                    <GoodName><span>{i.name}</span><span>({i.unit})</span></GoodName>
                                </div>
                                <div className='fbc'>
                                    <div>
                                        <span style={{ marginRight: '1rem' }}>购买： {i.num}</span>
                                        <span style={{ marginRight: '1rem' }}>已退： {i.refund_num}</span>
                                        <span style={{ marginRight: '1rem' }}>已核销： {i.checkin_num}</span>
                                    </div>
                                    <InputNumber min={0} max={i.num - i.refund_num - (i.checkin_num || 0)} value={i.selectedNum}
                                        formatter={(num) => {
                                            return String(Number(num) || 0)
                                        }}
                                        onChange={(e) => {
                                            this.setState({
                                                items: this.state.items.map(j => { if (i.id === j.id) return { ...j, selectedNum: e }; return j })
                                            })
                                        }} />
                                </div>
                            </div>
                        })}
                    </div> : null}
                </Goodlist>
            </Inner>
        </Modal>
    }
}

export default Checkin