import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import Main from '../../high-component/Content';
import PrintOrder from './PrintOrder';
import { get, } from '../../netApi/fetch';

import styled from 'styled-components';
import Loading from '../Method/Loading';
import { message } from 'antd';
import Select from '../total/Select_.tsx';

const Warp = styled.div`
    padding: 60px;
    position: relative;
    @media print{
        padding: 0;
    }
`

class PrintOrderPage extends Component {
    state = {
        option: 0,
    }

    componentDidMount() {
        if (this.props.match.params.tuanid && this.props.is_Login && !this.state.list) {
            this.get()
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.tuanid && this.props.is_Login && !this.state.list) {
            this.get()
        }
    }

    render() {
        let list = this.props.location.state ? this.props.location.state.list : this.state.list
        if (!list) return null
        return (
            <Warp id='printlist' style={{}}>
                {list ? (<div>
                    {list.map((item, i) => {
                        return (<PrintOrder key={i} data={item} />)
                    })}
                </div>) : ''}
                <div style={{
                    position: 'fixed', left: 400, top: 300,
                }}>
                    {!(this.props.location.state && this.props.location.state.list) && <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fcc print-hide'>
                        <div style={{ minWidth: '7em' }}>选择订单状态</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <Select uni='dingdan' list={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' },]} showKey='title'
                                onChange={e => {
                                    this.setState({
                                        option: e.value,
                                        list: [],
                                    }, () => { this.get() })
                                }} value={this.state.option === 0 ? 0 : 1}
                            />
                        </div>
                    </div>}
                    {this.state.sellerlist
                        ? <div className='fbc flexwrap print-hide'>
                            <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>
                                <div style={{ minWidth: '7em' }}>选择帮卖好友</div>
                                <div style={{ flex: 1, minWidth: '7em' }}>
                                    <Select canSearch uni='sellerlist' placeholder={`请选择帮卖好友`} maxHeight={300} list={this.state.sellerlist} showKey='name'
                                        onChange={e => {
                                            this.setState({
                                                selectedSellerId: e.id,
                                                list: []
                                            }, () => { this.get() })
                                        }} value={this.state.sellerlist.findIndex(i => {
                                            if (i.id === this.state.selectedSellerId) return true
                                            return false
                                        })} />
                                </div>
                            </div>
                        </div>
                        : null}
                    <div style={{ padding: 20, paddingTop: 0, flex: 1, textAlign: 'right' }} className=''>
                        <button className='print-hide button_solid_red'
                            onClick={() => {
                                window.print()
                            }}
                        >立即打印</button>
                    </div>
                </div>
            </Warp>
        )
    }

    get() {
        // const search = parse(this.props.location.search)
        Loading.show('加载中')
        get('/pcorder/orderList', {
            sort: 'asc',
            option: this.state.option || 0,
            tuanid: atob(decodeURIComponent(this.props.match.params.tuanid)),
            output_sheet_list: '2,5,4',
            types: 1,
            output_type: 'array',
            seller_sid: this.state.selectedSellerId || ''
        }).then(res => {
            this.setState({
                list: res.origin_list.map(i => {
                    if (Array.isArray(i.items)) return i
                    const items = []
                    for (const key in i.items) {
                        if (i.items.hasOwnProperty(key)) {
                            const good = i.items[key];
                            items.push(good)
                        }
                    }
                    return { ...i, items }
                })
            }, () => {
                if (!this.state.sellerlist) {
                    this.getSeller()
                }
            })

            Loading.hide()
        }).catch(e => {
            Loading.hide()
            if (e.message) message.error(e.message)
        })
    }

    getSeller(id) {
        get('/tuan/getsellerinfolistbytuanid', {
            types: 1,
            tuanid: decodeURIComponent(this.props.match.params.tuanid),
        }).then((res) => {
            this.setState({ sellerlist: [{ id: '', name: '全部' }, ...res.list] })
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        is_Login: state.is_Login,
    }
}

export default connect(mapStateToProps)(PrintOrderPage)