import React, { useState, useEffect, } from 'react';
import { match, NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import TuanDetail from './component/TuanDetail';
import TuanOrder from './component/TuanOrder';
import Analysis from './component/TuanAnalysis';
import { Nav, Num } from './style';

interface Props extends RouteComponentProps {
    match: Match,
}

interface Match extends match {
    params: {
        tuanid: string
    }
}


function C(props: Props) {
    const tuanid = props.match.params.tuanid
    const [tuanData, setTuanData] = useState<any>()
    const [orders, setOrders] = useState(0)
    const [active, setActive] = useState(0)
    useEffect(() => {
        if (!tuanData) return
        const orders = Number(tuanData.isPay) + Number(tuanData.sellerorders_ispay)
        setOrders(orders)
    }, [tuanData])

    useEffect(() => {
        const page = props.location.pathname
        if (/detail/.test(page)) {
            setActive(1)
        } else if (/applyrefund/.test(props.location.search)) {
            setActive(3)
        } else if (/orders/.test(page)) {
            setActive(2)
        } else if (/analysis/.test(page)) {
            setActive(4)
        }
    }, [props])
    return <div>
        <Nav active={active}>
            <NavLink to={`/index/t/${tuanid}/detail`}>
                <i className='iconfont icon-shouzhimingxi1' />
                <div>活动详情</div>
            </NavLink>
            <NavLink to={`/index/t/${tuanid}/orders`}>
                <i className='iconfont icon-dingdanguanli1' />
                <div>订单管理</div>
                {orders > 0 ? <Num>{orders}</Num> : null}
            </NavLink>
            <NavLink to={`/index/t/${tuanid}/orders?option=applyrefund`}>
                <i className='iconfont icon-tuikuan3' />
                <div>退款</div>
            </NavLink>
            <NavLink to={`/index/t/${tuanid}/analysis`}>
                <i className='iconfont icon-jiaoyifenxi1' />
                <div>交易分析</div>
            </NavLink>
        </Nav>
        <Switch>
            <Route path={`/index/t/:tuanid/detail`} >
                <TuanDetail onGetData={e => {
                    setTuanData(e)
                }} />
            </Route>
            <Route path={`/index/t/:tuanid/orders`} >
                <TuanOrder />
            </Route>
            <Route path={`/index/t/:tuanid/analysis`} component={Analysis} />
        </Switch>
    </div>
}

export default C