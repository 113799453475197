import React, { Component } from 'react'
import { connect } from 'react-redux';
import { get, getToken, getUrl } from '../../../netApi/fetch';
import { message } from 'antd';
import { NavLink } from 'react-router-dom';
import Modal from '../../total/Modal';
import styled from 'styled-components';
import Quick from '../../order/OrderCtrl/Quick';
import Select from '../../total/Select_';
import Export from '../../order/exportPanel_.tsx';
import Loading from '../../Method/Loading';
import ExpressMark from '../../order/OrderCtrl/ExpressMark';
import MarkHistory from '../../order/OrderCtrl/MarkHistory';
import { API } from '../../../data/options';

const Button = styled.button`
    border: 1px solid var(--theme-main) ;
    background: #fff;
    color: var(--theme-main) ;
    transition: all .2s;
    border-radius: 4px;
    padding: 4px 11px;
    margin-left: 16px;
    margin-bottom: 12px;
    position: relative;
    :hover{
        background: var(--theme-main) ;
        color: #fff;
    }
`
const Tip = styled.div`
    position: absolute;
    background: #333;
    transform-origin: bottom center;
    transform: scale(0);
    transition: transform .2s;
    color: #fff;
    border-radius: 4px;
    padding: 14px 7px;
    font-size: 14px;
    right: -1em;
    bottom: 3em;
    span:nth-child(2){
        color: #EC9E2D;
    }
    button:hover &{
        transform: scale(1);
    }
    ::after{
        content: '';
        position: absolute;
        bottom: -6px;
        left: ${props => props.left - 6}px;
        height: 0;
        width: 0;
        border-left: transparent solid 6px;
        border-right: transparent solid 6px;
        border-top: #333 solid 6px;
    }
`
class Analysis extends Component {

    constructor() {
        super()
        this.state = {
            order: {},
            goodslist: [],
            sellerGoodList: [],
            addressGoodList: [],
            order_statistics: {},
            goodtype: 1,
            showSelectPrint: false,
            printOP: 0,
            selectedSellerId: '',
            markFilter: 0,

            showExpressMark: false,
            markGoodlist: []
        }
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(props) {
        if (this.props.match.params.tuanid !== props.match.params.tuanid) {
            this.setState({
                order: {},
                goodslist: [],
                sellerGoodList: [],
                addressGoodList: [],
                order_statistics: {},
                goodtype: 1,
                showSelectPrint: false,
                printOP: 0,
                selectedSellerId: '',
                markFilter: 0,
            })
            this.getData()
        }
    }

    render() {


        // 可一键快递列表
        const quickSendList = (this.state.tuanlist || []).filter(i => (Number(i.takeway) === 1 && Number(i.pid) < 1))

        // 可一键核销列表
        const quickCheckinList = (this.state.tuanlist || []).filter(i => (Number(i.takeway) === 2 && Number(i.distributionid) <= 0))
        return (
            <div className='' style={{ padding: '24px 15px', background: '#fff' }}>
                {/* <h1 className='order__analysis__title'>{this.state.title || '团购订单汇总'}</h1> */}
                <div className='flex-between'>
                    <div className='flex-box' style={{ justifyContent: 'flex-end' }} >
                        {[1, 3, 21, 768205].includes(Number(this.props.user.self_sid)) ? <>
                            <Button style={{ marginLeft: 0, }} onClick={() => {
                                const a = document.createElement('a')
                                a.target = '_blank'
                                a.href = getUrl('/pcorder/exportdatabyfriend', {
                                    types: 1,
                                    tuanid: btoa(this.props.match.params.tuanid),
                                    token: getToken(),
                                })
                                a.click()
                            }} className='cur-pointer'>导出供货收入和佣金统计</Button>
                            {[3, 4, 7, 8].includes(this.state.order.statistics_type)
                                ? <Button onClick={() => {
                                    const a = document.createElement('a')
                                    a.target = '_blank'
                                    console.log(window);
                                    a.href = `/print/dbf/${btoa(this.props.match.params.tuanid)}`
                                    a.click()
                                }} className='cur-pointer'>打印对账单</Button>
                                : null}
                        </> : null}
                    </div>

                    <div style={{ textAlign: "right" }}>
                        <Button className='cur-pointer' onClick={() => { this.setState({ showExportPanel: true }) }}>导出数据</Button>
                        {[2, 4, 6, 8].includes(this.state.order.statistics_type) ? <Button onClick={() => {
                            window.open(`${API}/pcorder/exportdatabypickuppointformseller?types=1&token=${getToken()}&tuanid=${btoa(this.props.match.params.tuanid)}`, '_blank')
                        }} className='cur-pointer'>导出仓库拣货单</Button> : null}
                        {[1, 2, 3].includes(this.state.order.statistics_type) ? <Button className='cur-pointer' onClick={() => {
                            if ([2, 4, 6, 8].includes(this.state.order.statistics_type)) {
                                this.setState({ quickCheckin: true })
                            } else {
                                this.setState({ showSelectSendPanel: true })
                            }
                        }}>{[2, 4, 6, 8].includes(this.state.order.statistics_type) ? '一键核销' : '一键发货'}</Button> : null}
                        <NavLink target='_blank' to={`/orders/print/${btoa(this.props.match.params.tuanid)}`} >
                            <Button className='cur-pointer'>打印消费者面单<Tip left={100} style={{ width: 200 }}>
                                <span>每个消费者的订单商品明细，适合</span><span>顾客核对明细</span>时使用
                            </Tip></Button>
                        </NavLink>
                        <NavLink target='_blank' to={`/print/totalgood/${btoa(this.props.match.params.tuanid)}`} >
                            <Button className='cur-pointer'>打印商品总单<Tip left={100} style={{ width: 200 }}>
                                <span>包含每个商品售卖数量，适合</span><span>采购和统计</span>时使用
                            </Tip></Button>
                        </NavLink>
                        {[2, 4, 6, 8].includes(this.state.order.statistics_type) ? <Button onClick={() => {
                            this.setState({ printType: 2, printSelector: true })
                        }} className='cur-pointer'>打印仓库拣货单<Tip left={100} style={{ width: 200 }}>
                                <span>包含每个自提门店的商品明细，适合</span><span>自提点拣货</span>时使用
                            </Tip></Button> : null}
                        {[2, 4, 6, 8].includes(this.state.order.statistics_type) ? <Button onClick={() => {
                            this.setState({ printType: 1, printSelector: true })
                        }} className='cur-pointer'>打印门店对货单&消费者订单<Tip left={125} style={{ width: 250 }}>
                                <span>包含每个门店的商品明细和消费者明细，供</span><span>自提门店对货和消费者核销</span>时使用
                            </Tip></Button> : null}
                    </div>
                </div>

                <h4>我的订单总览</h4>
                <div className='order__analysis__total' style={{ alignItems: 'start' }} >
                    {this.state.myOrderTotal && this.state.myOrderTotal['count'] ? <div className='order__analysis__total__item' style={{ flexWrap: 'wrap' }}>
                        <div className='order__analysis__total__item__name'>我的订单</div>
                        <div className='order__analysis__total__item__price' style={{ marginRight: 16 }}>
                            {this.state.myOrderTotal['count']}
                        </div>
                        <NavLink to={`/index/t/${this.props.match.params.tuanid}/orders?type=my`}>
                            <button className='button_solid_red' style={{ marginRight: 16 }}>我的订单详情</button>
                        </NavLink>
                    </div> : null}
                    {[
                        { key: 'completed_count', name: [2, 4, 6, 8].includes(this.state.order.statistics_type) ? '已核销' : '已发货', price: false },
                        { key: 'pay_count', name: [2, 4, 6, 8].includes(this.state.order.statistics_type) ? '未核销' : '未发货', price: false },
                        { key: 'apply_count', name: '退款中', price: false },
                        { key: 'refunded_count', name: '已退款', price: false },
                    ].map((i, idx) => {
                        if (!this.state.myOrderTotal) return null
                        if (this.state.myOrderTotal[i.key] !== undefined) {
                            let price = <span>{Number(this.state.myOrderTotal[i.key])}</span>
                            if (i.price) price = <span>{(Number(this.state.myOrderTotal[i.key]) / 100).toFixed(2)}</span>
                            return <div key={idx} className='order__analysis__total__item'>
                                <div className='order__analysis__total__item__name'>{i.name}</div>
                                <div className='order__analysis__total__item__price'>
                                    {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                    {price}
                                </div>
                            </div>
                        } else return null
                    })}
                </div>

                {[3, 4, 7, 8].includes(this.state.order.statistics_type) ? <>
                    <h4>好友订单总览</h4>
                    <div className='order__analysis__total' style={{ alignItems: 'start' }}>
                        {this.state.sellerOrderTotal && this.state.sellerOrderTotal['count'] ? <div className='order__analysis__total__item' style={{ flexWrap: 'wrap' }}>
                            <div className='order__analysis__total__item__name'>好友订单数</div>
                            <div className='order__analysis__total__item__price' style={{ marginRight: 16 }}>
                                {this.state.sellerOrderTotal['count']}
                            </div>
                            <NavLink to={`/index/t/${this.props.match.params.tuanid}/orders?type=seller`}>
                                <button className='button_solid_red' style={{ marginRight: 16 }}>好友订单详情</button>
                            </NavLink>
                        </div> : null}
                        {[
                            { key: 'completed_count', name: [2, 4, 6, 8].includes(this.state.order.statistics_type) ? '已核销' : '已发货', price: false },
                            { key: 'pay_count', name: [2, 4, 6, 8].includes(this.state.order.statistics_type) ? '未核销' : '未发货', price: false },
                            { key: 'apply_count', name: '退款中', price: false },
                            { key: 'refunded_count', name: '已退款', price: false },
                        ].map((i, idx) => {
                            if (!this.state.sellerOrderTotal) return null
                            if (this.state.sellerOrderTotal[i.key] !== undefined) {
                                let price = <span>{Number(this.state.sellerOrderTotal[i.key])}</span>
                                if (i.price) price = <span>{(Number(this.state.sellerOrderTotal[i.key]) / 100).toFixed(2)}</span>
                                return <div key={idx} className='order__analysis__total__item'>
                                    <div className='order__analysis__total__item__name'>{i.name}</div>
                                    <div className='order__analysis__total__item__price'>
                                        {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                        {price}
                                    </div>
                                </div>
                            } else return null
                        })}
                    </div></> : null}

                {this.state.showPriceData
                    ? <>
                        <div className='flex-between'>
                            <h4>交易总览</h4>
                        </div>
                        <div className='order__analysis__total'>
                            {[1, 2, 3, 4].includes(this.state.order.statistics_type) ? <div className='flex-center' style={{ width: '100%', marginBottom: 20 }} >
                                <div className='order__analysis__total__item__name'>本次总收入</div>
                                <div className='order__analysis__total__item__price' style={{ color: `var(--theme-main)` }}>
                                    <span style={{ fontSize: 12 }}>￥</span>
                                    {(this.state.order_statistics['total_income'] / 100).toFixed(2)}
                                </div>
                            </div> : null}
                            {[
                                { key: 'total_sales', name: '本次总销售', price: true },
                                { key: 'total_refund', name: '本次总退款', price: true },
                                { key: 'tuan_total_pay_price', name: '订单总额', price: true },
                                { key: 'my_sales', name: '我的销售', price: true },
                                { key: 'my_refund', name: '我的退款', price: true },
                                { key: 'my_income', name: '我的收入', price: true },
                                { key: 'supply_sales', name: '供货销售', price: true },
                                { key: 'supply_refund', name: '供货退款', price: true },
                                { key: 'supply_income', name: '供货收入', price: true },
                                { key: 'friend_sales', name: '好友销售', price: true },
                                { key: 'friend_refunds', name: '好友退款', price: true },
                            ].map((i, idx) => {
                                if (this.state.order_statistics[i.key] !== undefined) {
                                    let price = <span>{Number(this.state.order_statistics[i.key])}</span>
                                    if (i.price) price = <span>{(Number(this.state.order_statistics[i.key]) / 100).toFixed(2)}</span>
                                    return <div key={idx} className='order__analysis__total__item'>
                                        <div className='order__analysis__total__item__name'>{i.name}</div>
                                        <div className='order__analysis__total__item__price'>
                                            {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                            {price}
                                        </div>
                                    </div>
                                } else return null
                            })}
                        </div>

                        {[5, 6, 7, 8].includes(this.state.order.statistics_type) ? (<>
                            <h4>我的佣金总览</h4>
                            <div className='order__analysis__total'>
                                {[5, 6, 7, 8].includes(this.state.order.statistics_type) ? <div className='flex-center' style={{ width: '100%', marginBottom: 20 }} >
                                    <div className='order__analysis__total__item__name'>本次可提现收入</div>
                                    <div className='order__analysis__total__item__price' style={{ color: `var(--theme-main)` }}>
                                        <span style={{ fontSize: 12 }}>￥</span>
                                        {(this.state.order_statistics['total_income'] / 100).toFixed(2)}
                                    </div>
                                </div> : null}
                                {[
                                    { key: 'self_sales_rebates', name: '直接销售佣金', price: true },
                                    { key: 'friend_sales_rebates', name: '帮卖销售佣金', price: true },
                                    { key: 'total_rebates', name: '总佣金收入', price: true },
                                    { key: 'my_rebates', name: '我的佣金', price: true },
                                    { key: 'arrived_rebates', name: '已到账佣金', price: true },
                                    { key: 'unarrived_rebates', name: '待收佣金', price: true },
                                    { key: 'refund_rebates', name: '已撤回佣金', price: true },
                                    { key: 'need_refund_rebates', name: '需向上级退回佣金', price: true },
                                ].map((i, idx) => {
                                    if (this.state.order_statistics[i.key] !== undefined) {
                                        let price = <span>{Number(this.state.order_statistics[i.key])}</span>
                                        if (i.price) price = <span>{(Number(this.state.order_statistics[i.key]) / 100).toFixed(2)}</span>
                                        return <div key={idx} className='order__analysis__total__item'>
                                            <div className='order__analysis__total__item__name'>{i.name}</div>
                                            <div className='order__analysis__total__item__price'>
                                                {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                                {price}
                                            </div>
                                        </div>
                                    } else return null
                                })}
                            </div>
                        </>) : null}

                        {[3, 4, 7, 8].includes(this.state.order.statistics_type) ? (<>
                            <h4>好友佣金总览</h4>
                            <div className='order__analysis__total'>
                                {[
                                    { key: 'friend_total_rebates', name: '好友总佣金', price: true },
                                    { key: 'paid_rebates', name: '已发放佣金', price: true },
                                    { key: 'unpaid_rebates', name: '待发放佣金', price: true },
                                    { key: 'refund_rebates', name: '已撤回佣金', price: true },
                                    { key: 'need_take_back_rebates', name: '需向好友索回佣金', price: true },
                                ].map((i, idx) => {
                                    if (this.state.order_statistics[i.key] !== undefined) {
                                        let price = <span>{Number(this.state.order_statistics[i.key])}</span>
                                        if (i.price) price = <span>{(Number(this.state.order_statistics[i.key]) / 100).toFixed(2)}</span>
                                        return <div key={idx} className='order__analysis__total__item'>
                                            <div className='order__analysis__total__item__name'>{i.name}</div>
                                            <div className='order__analysis__total__item__price'>
                                                {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                                {price}
                                            </div>
                                        </div>
                                    } else return null
                                })}
                            </div>
                        </>) : null}
                    </>
                    : null}

                {this.state.showPriceData
                    ? <div className='flex-center cur-pointer' style={{ color: `var(--theme-main)` }} onClick={() => { this.setState({ showPriceData: !this.state.showPriceData }) }} >
                        收起 <i className='icon-up iconfont' />
                    </div>
                    : <div className='flex-center cur-pointer' style={{ color: `var(--theme-main)` }} onClick={() => { this.setState({ showPriceData: !this.state.showPriceData }) }} >
                        查看更多交易数据 <i className='icon-down iconfont' />
                    </div>}
                <h4>商品售卖统计</h4>
                {/* 商品列表 */}
                <div className='flex-between'>
                    <div className='order__analysis__goodtypeChange'>
                        <div onClick={() => {
                            this.setState({ goodtype: 1 })
                        }} className={`order__analysis__goodtype ${this.state.goodtype === 1 ? 'order__analysis__goodtype_active' : ''}`}>商品维度</div>
                        {[2, 4, 6, 8].includes(this.state.order.statistics_type) ? (<div onClick={() => {
                            this.setState({ goodtype: 2 })
                        }} className={`order__analysis__goodtype ${this.state.goodtype === 2 ? 'order__analysis__goodtype_active' : ''}`}>自提点维度</div>) : null}
                        {[3, 4, 7, 8].includes(this.state.order.statistics_type) ? (<div onClick={() => {
                            this.setState({ goodtype: 3 })
                        }} className={`order__analysis__goodtype ${this.state.goodtype === 3 ? 'order__analysis__goodtype_active' : ''}`}>帮卖好友维度</div>) : null}
                    </div>
                    <div>
                        {[2, 3].includes(this.state.goodtype)
                            ? <>
                                <div className='flex-center'>
                                    <span>筛选</span>
                                    <Select uni='mark' list={[{ title: '全部' }, { title: '已标记发货' }, { title: '未标记发货' }]} showKey='title' value={this.state.markFilter} onChange={(e, i) => {
                                        this.setState({ markFilter: i })
                                    }} />
                                </div>
                            </>
                            : null}
                    </div>
                </div>

                {this.state.goodtype === 1 ? (
                    <table className='order__analysis__list'>
                        <thead>
                            <tr>
                                <th>商品名称（规格）</th>
                                <th>{[2, 4, 6, 8].includes(this.state.order.statistics_type) ? '未核销' : '未发货'}</th>
                                <th>退款中</th>
                                <th>已售</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.goodslist.map(i => {
                                if (i.id === 'total') {
                                    return <tr key={i.id}>
                                        <td>总计</td>
                                        <td>{i.notfinish}</td>
                                        <td>{i.refunding_num}</td>
                                        <td>{i.num}</td>
                                    </tr>
                                }
                                return <tr key={i.id}>
                                    <td>{i.name}</td>
                                    <td>{i.notfinish}</td>
                                    <td>{i.refunding_num}</td>
                                    <td>{i.num}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                ) : null}

                {this.state.goodtype === 2 ?
                    this.state.addressGoodList.map((address, index) => {
                        if (this.state.markFilter === 1) {
                            if (Number(address.addressData.mark_total) === 0) return null
                        }
                        if (this.state.markFilter === 2) {
                            if (Number(address.addressData.mark_total) > 0) return null
                        }
                        return (<table key={index} className='order__analysis__list'>
                            <thead>
                                <tr>
                                    <th>
                                        <div className='flex-between'>
                                            <div>{address.addressData.name}</div>
                                            <div className='flex-between'>
                                                <div className='button_hollow_red' style={{ padding: '4px 11px', marginRight: 12 }} onClick={() => {
                                                    this.setState({ showExpressMark: true, markGoodlist: address.goodlist, mark_type: 1, markid: address.addressData.id })
                                                }}>标记发货</div>
                                                <div className='cur-pointer' style={{ color: '#999', padding: '4px 11px', marginRight: 12 }} onClick={() => {
                                                    this.setState({
                                                        mark_type: 1, markid: address.addressData.id, showMarkHistory: true
                                                    })
                                                }}>发货记录</div>
                                            </div>
                                        </div>
                                        <div className='flex-between'>
                                            <div>
                                                <div style={{ color: '#999', fontWeight: 400, }}>{address.addressData.address + address.addressData.addressdetail}</div>
                                                <div style={{ color: '#999', fontWeight: 400, }} className='flex-box' >
                                                    <div style={{ marginRight: 16 }}>联系方式：{address.addressData.mobile}</div>
                                                    <div>联系人：{address.addressData.contact}</div>
                                                </div>
                                            </div>
                                            <div style={{ padding: '4px 11px', marginRight: 12 }}>共{address.order_count}个订单</div>
                                        </div>
                                    </th>
                                    <th>{[2, 4, 6, 8].includes(this.state.order.statistics_type) ? '未核销' : '未发货'}</th>
                                    <th>退款中</th>
                                    <th>已售</th>
                                </tr>
                            </thead>
                            <tbody>
                                {address.goodlist.map(i => {
                                    return <tr key={i.id}>
                                        <td>{i.name}</td>
                                        <td>{i.notfinish}</td>
                                        <td>{i.refunding_num}</td>
                                        <td>{i.num}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td>总计</td>
                                    <td>{address.total.notfinish}</td>
                                    <td>{address.total.refunding_num}</td>
                                    <td>{address.total.num}</td>
                                </tr>
                            </tbody>
                        </table>)
                    }) : null}

                {[3, 4, 7, 8].includes(this.state.order.statistics_type) && this.state.goodtype === 3 ? (<div onClick={() => {
                    const url = getUrl('/pcorder/exportdata', {
                        type: 1, types: 1,
                        token: getToken(), tuanid: this.props.match.params.tuanid
                    })
                    const a = document.createElement('a')
                    a.href = url
                    a.target = '_blank'
                    a.click()
                }} className='button_hollow_red' style={{ padding: '4px 11px', marginBottom: 12, display: 'inline-block' }}>导出统计表格</div>) : null}
                {this.state.goodtype === 3 ?
                    this.state.sellerGoodList.map((seller, index) => {
                        if (this.state.markFilter === 1) {
                            if (Number(seller.seller_info.mark_total) === 0) return null
                        }
                        if (this.state.markFilter === 2) {
                            if (Number(seller.seller_info.mark_total) > 0) return null
                        }
                        return (<table key={index} className='order__analysis__list'>
                            <thead>
                                <tr>
                                    <th>
                                        <div className='flex-between'>
                                            <div>
                                                <div>{seller.seller_info.name}</div>
                                                <div>共{seller.order_count}个订单</div>
                                            </div>
                                            <div className='flex-between'>
                                                <div className='button_hollow_red' style={{ padding: '4px 11px', marginRight: 12 }} onClick={() => {
                                                    this.setState({ showExpressMark: true, markGoodlist: seller.goodlist, mark_type: 2, markid: seller.seller_info.id })
                                                }}>标记发货</div>
                                                <div className='cur-pointer' style={{ color: '#999', padding: '4px 11px', marginRight: 12 }} onClick={() => {
                                                    this.setState({
                                                        mark_type: 2, markid: seller.seller_info.id, showMarkHistory: true
                                                    })
                                                }}>发货记录</div>
                                            </div>
                                        </div>
                                    </th>
                                    <th>{[2, 4, 6, 8].includes(this.state.order.statistics_type) ? '未核销' : '未发货'}</th>
                                    <th>退款中</th>
                                    <th>已售</th>
                                </tr>
                            </thead>
                            <tbody>
                                {seller.goodlist.map(i => {
                                    return <tr key={i.id}>
                                        <td>{i.name}</td>
                                        <td>{i.notfinish}</td>
                                        <td>{i.refunding_num}</td>
                                        <td>{i.num}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td>总计</td>
                                    <td>{seller.total.notfinish}</td>
                                    <td>{seller.total.refunding_num}</td>
                                    <td>{seller.total.num}</td>
                                </tr>
                            </tbody>
                        </table>)
                    }) : null}


                {this.state.showExportPanel ? <Export
                    // printList={this.printList.bind(this)}
                    close={() => {
                        this.setState({
                            showExportPanel: false
                        })
                    }}
                    tuanlist={this.state.tuanlist || []}
                    preselect={btoa(this.props.match.params.tuanid)}
                ></Export> : null}

                {/* 一键发货 */}
                {this.state.quickSend ? <Quick takeway={1} initTuanId={Number((decodeURIComponent(this.props.match.params.tuanid || '')))}
                    quickSendList={quickSendList} quickCheckinList={quickCheckinList} onConfirm={() => {
                        this.getData()
                        this.setState({ quickSend: false, })
                    }} onClose={() => { this.setState({ quickSend: false, }) }} /> : null}

                {/* 一键核销 */}
                {this.state.quickCheckin ? <Quick takeway={2} initTuanId={Number((decodeURIComponent(this.props.match.params.tuanid || '')))}
                    quickSendList={quickSendList} quickCheckinList={quickCheckinList} onConfirm={() => {
                        this.getData()
                        this.setState({ quickCheckin: false, })
                    }} onClose={() => { this.setState({ quickCheckin: false, }) }} /> : null}

                {/* 打印筛选 */}
                {this.state.printSelector ? <Modal title={`打印${this.state.printType === 2 ? '拣货单' : '对货单&消费者订单'}`} onConfirm={() => {
                    window.open(`/picklist/${btoa(this.props.match.params.tuanid)}?op=${this.state.printOP}&type=${this.state.printType || ''}&ss=${this.state.selectedSellerId}`, '_blank')
                }} onClose={() => { this.setState({ printSelector: false }) }}>
                    <div>
                        <div style={{ padding: 20, flex: 1 }} className='fcc'>
                            <div style={{ minWidth: '7em' }}>选择订单状态</div>
                            <div style={{ flex: 1, minWidth: '7em' }}>
                                <Select uni='dingdan' list={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' },]} showKey='title'
                                    onChange={e => {
                                        this.setState({
                                            printOP: e.value
                                        })
                                    }} value={this.state.printOP === 0 ? 0 : 1} />
                            </div>
                        </div>
                        <div style={{ padding: 20, flex: 1 }} className='fcc'>
                            <div style={{ minWidth: '7em' }}>选择帮卖好友</div>
                            <div style={{ flex: 1, minWidth: '7em' }}>
                                <Select canSearch uni='sellerlist' placeholder={`请选择帮卖好友`} maxHeight={300} list={this.state.sellerlist || []} showKey='name'
                                    onChange={e => {
                                        this.setState({
                                            selectedSellerId: e.id
                                        })
                                    }} value={(this.state.sellerlist || []).findIndex(i => {
                                        if (i.id === this.state.selectedSellerId) return true
                                        return false
                                    })} />
                            </div>
                        </div>
                    </div>
                </Modal> : null}

                {this.state.showSelectSendPanel ? <Modal title='请选择发货方式' onConfirm={() => {
                    this.setState({ showSelectSendPanel: false })
                    if (this.state.selectedSendMode === 1) {
                        this.setState({ batch: true })
                    }
                    if (this.state.selectedSendMode === 2) {
                        this.setState({ quickSend: true })
                    }
                }} onClose={this.setState.bind(this, { ...this.state, showSelectSendPanel: false })}>
                    <div className='fbc'>
                        <div className='cur-pointer' onClick={() => { this.setState({ selectedSendMode: 1 }) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: this.state.selectedSendMode === 1 ? '#fff' : '#eee', color: this.state.selectedSendMode === 1 ? `var(--theme-main)` : '#666', border: this.state.selectedSendMode === 1 ? `1px solid var(--theme-main) ` : '1px solid #eee' }}>
                            <div style={{ fontSize: 20, fontWeight: 600, }}>快递配送</div>
                            <div style={{ fontSize: 12, marginTop: 15 }}>需要导入快递单号</div>
                            <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: this.state.selectedSendMode === 1 ? 'block' : 'none' }} />
                        </div>
                        <div className='cur-pointer' onClick={() => { this.setState({ selectedSendMode: 2 }) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: this.state.selectedSendMode === 2 ? '#fff' : '#eee', color: this.state.selectedSendMode === 2 ? `var(--theme-main)` : '#666', border: this.state.selectedSendMode === 2 ? `1px solid var(--theme-main) ` : '1px solid #eee' }}>
                            <div style={{ fontSize: 20, fontWeight: 600, }}>一键发货（无需导入单号）</div>
                            <div style={{ fontSize: 12, marginTop: 15 }}>适用于由商家自己配送货物的活动</div>
                            <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: this.state.selectedSendMode === 2 ? 'block' : 'none' }} />
                        </div>
                    </div>
                </Modal> : null}

                {/* 批量发货 */}
                {this.state.batch ? (<Modal loading={this.state.batch_loading} cancel={true} title={'如何批量发货'} confirm={() => {
                    if (!this.uploadxls) {
                        message.warning('请选择文件')
                        return
                    }
                    this.setState({
                        batch_loading: true,
                    })
                    fetch(API + '/pcorder/sendgoodsbyimportexcel', {
                        method: 'post', body: this.uploadxls, mode: 'cors',
                        credentials: 'include',
                    }).then((res) => {
                        res.json().then((res) => {
                            if (res.code !== 0) {
                                message.error(res.message)
                                this.setState({ batch_loading: false })
                                return
                            }
                            alert('发货成功')
                            this.getData()
                            this.setState({ batch: false, batch_loading: false })
                        })
                    }).catch(() => {
                        message.error('网络错误')
                        this.setState({ batch_loading: false })
                    })
                }} close={() => {
                    this.uploadxls = null
                    this.setState({ batch: false })
                }}>
                    <ol id='batch-order'>
                        <li><span style={{ color: '#3d83d8', cursor: 'pointer' }} onClick={() => {
                            this.setState({
                                showExportPanel: true, batch: false,
                            })
                        }}>导出数据</span>，下载Excel表格</li>
                        <li>按照表格格式填写好快递名称和快递单号，保存表格文件，例如：圆通 YT4132628326828</li>
                        <img alt='发货填写示例图' src='https://tuangou-1259536745.picgz.myqcloud.com/uploads/20191012/tE6T0cuxkZ.jpg'></img>
                        <li>
                            点击下方按钮上传表格，系统自动会填上对应订单的快递单号，并发货。<br />
                            <span>注意：请<span style={{ color: `var(--theme-main)` }}>勿</span>删除Excel表格中，<span style={{ color: `var(--theme-main)` }}>第一行</span>以及<span style={{ color: `var(--theme-main)` }}>订单号、快递公司、快递单号</span>三列</span>
                        </li>
                        <label style={{ cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <input type='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                                onChange={e => {
                                    if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                    const file = e.target.files[0]
                                    const formData = new FormData()
                                    formData.append('file', file)
                                    formData.append('types', '1')
                                    formData.append('token', getToken())
                                    this.uploadxls = formData
                                }} />
                            <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                        </label>
                        <div style={{ color: '#3d83d8', cursor: 'pointer' }} onClick={e => {
                            const win = window.open('/help/importexcel', '_blank');
                            win && win.focus();
                        }}>还不懂？查看详细教程</div>
                    </ol>
                </Modal>) : null}

                {/* 标记发货 */}
                {this.state.showExpressMark && this.state.markGoodlist && this.state.mark_type && this.state.markid
                    ? <ExpressMark goodlist={this.state.markGoodlist} tuanid={this.props.match.params.tuanid} mark_type={this.state.mark_type} id={this.state.markid} onClose={(refresh) => {
                        if (refresh) {
                            switch (this.state.mark_type) {
                                case 1: // 自提点
                                    const a = this.state.addressGoodList.find(address => address.addressData.id === this.state.markid)
                                    if (a) a.addressData.mark_total = 1
                                    break;
                                case 2:// 帮卖好友
                                    const s = this.state.sellerGoodList.find(seller => seller.seller_info.id === this.state.markid)
                                    if (s) s.seller_info.mark_total = 1
                                    break
                                default:
                                    break;
                            }
                        }
                        this.setState({ showExpressMark: false, markGoodlist: undefined, mark_type: undefined })
                    }} />
                    : null
                }

                {/* 标记发货信息 */}
                {this.state.showMarkHistory
                    ? <MarkHistory type={this.state.mark_type} id={this.state.markid} tuanid={this.props.match.params.tuanid} onClose={() => {
                        this.setState({ showMarkHistory: false, markGoodlist: undefined, mark_type: undefined })
                    }} />
                    : null}
            </div>
        )
    }

    getData() {
        this.getList()
        get('/order/transactionanalysis', {
            types: 1,
            start_time: 0,
            end_time: 0,
            tuanid: (decodeURIComponent(this.props.match.params.tuanid)),
        }).then(res => {
            this.setState({
                order_statistics: res.order_statistics
            })
        }).catch(e => { if (e.message) message.error(e.message) })
        get('/shop/getorderlist', {
            op: 'all',
            search: '',
            tuanid: btoa(decodeURIComponent(this.props.match.params.tuanid)),
            offset: 0, limit: 0,
            type: 'tuan',
            addressid: 'all',
            types: 1,
        }).then((res) => {
            this.setState({
                tuanlist: res.tuanlist,
                myOrderTotal: res.order_count
            })
        }).catch(e => { if (e.message) message.error(e.message) })

        get('/tuan/getdistributionorderlist', {
            types: 1,
            offset: 0, limit: 0,
            tuanid: btoa(decodeURIComponent(this.props.match.params.tuanid)),
            search: '',
        }).then(res => {
            this.setState({
                sellerOrderTotal: res.order_count
            })
        }).catch(e => { if (e.message) message.error(e.message) })

        get('/tuan/getsellerinfolistbytuanid', {
            types: 1,
            tuanid: btoa(this.props.match.params.tuanid),
        }).then((res) => {
            this.setState({ sellerlist: [{ id: '', name: '全部' }, ...res.list] })
        }).catch(e => { if (e.message) message.error(e.message) })
    }

    getList() {
        Loading.show()
        get('/order/getorderstatistics', {
            types: 1,
            tuanid: btoa(this.props.match.params.tuanid)
        }).then((res) => {
            if (!res) return
            const goodslist = Object.keys(res.goods_statistics.goods_totalList).map(i => {
                return {
                    ...res.goods_statistics.goods_totalList[i],
                    id: i
                }
            })

            let sellerGoodList = (res.goods_statistics.seller_totalList || []).map(i => {
                return {
                    seller_info: i.seller_info,
                    total: i.total,
                    order_count: i.order_count,
                    goodlist: i.goods_statistics.map(j => {
                        return {
                            ...j,
                            id: j.goodsattrid
                        }
                    })
                }
            })

            let addressGoodList = (res.goods_statistics.pick_up_totalList || []).map(i => {
                return {
                    addressData: i.address_info,
                    total: i.total,
                    order_count: i.order_count,
                    goodlist: i.goods_statistics.map(j => {
                        return {
                            ...j,
                            id: j.goodsattrid
                        }
                    })
                }
            })

            this.setState({
                goodslist, sellerGoodList, addressGoodList,
                title: res.tuan_info.title,
                order: res.order_statistics
            })
            Loading.hide()
        }).catch(e => {
            message.error(e)
        })
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.login_user_data,
        sidebarOpen: state.sidebarOpen,
    }
}

export default connect(mapStateToProps)((Analysis))