import React, { useState, useEffect, useRef, } from 'react';
import styled from 'styled-components';
import Modal from '../total/Modal';
import { get, } from '../../netApi/fetch';
import Search from '../total/Input/Search';
import { RouteComponentProps, withRouter } from "react-router-dom";


interface tuan {
    image?: {
        src: string,
    },
    id: string,
    title: string,
}

interface props extends RouteComponentProps {
    onClose: () => void
}

const Item = styled.div`
    padding: 20px 10px;
    &:hover{
        background: #f5f5f5;
    }
    &>div:first-child{
        margin-right: 16px;
    }
    &>div:nth-child(2){
        font-size: 14;
        color: #000;
    }
`

function TuanList(props: props) {

    const [search, setSearch] = useState('')
    const offset = useRef(0)
    const [searchKey, setSearchKey] = useState('')

    const [list, setList] = useState<Array<tuan>>([])

    useEffect(() => {
        if (list.length === 0) {
            getList()
        }
    }, [list])

    const title = <div className="flex-box">
        <div style={{ marginRight: 30 }}>选择活动</div>
        <div style={{ paddingRight: 30 }}>
            <Search placeholder='搜索你要查看的活动' search={search} onChange={(e: string) => { setSearch(e) }} onSearch={() => {
                setSearchKey(search)
            }} />
        </div>
    </div>

    return <Modal title={title} onClose={props.onClose} >
        <div style={{ height: '50vh', width: 600, overflow: 'auto' }}>
            <Item className='flex-between cur-pointer' onClick={() => {
                props.history.push(`/orders`)
                props.onClose()
            }}>
                <div className=''>
                    <div className='flex-center' style={{ width: 40, height: 40, background: '#FFA92C' }} >
                        <i className='iconfont icon-wodedingdanicon' style={{ color: '#fff' }} />
                    </div>
                </div>
                <div className='flex1'>
                    <div>全部活动订单</div>
                    <div style={{ color: '#999' }}>可搜索手机号快速查找用户订单并核销</div>
                </div>
            </Item>
            {list.map((tuan: tuan) => {
                if(searchKey){
                    if(!new RegExp(searchKey).test(tuan.title))return null
                }
                return <Item className='flex-between cur-pointer' key={tuan.id} onClick={() => {
                    props.history.push(`/index/t/${tuan.id}/analysis`)
                    props.onClose()
                }}>
                    <div className=''>
                        <img alt='' className='img-center' style={{ width: 40, height: 40, display: 'block' }} src={tuan.image ? tuan.image.src + '?imageView2/5/w/300' : ''} />
                    </div>
                    <div className='flex1'>{tuan.title}</div>
                </Item>
            })}
        </div>
    </Modal>

    function getList() {
        get('/tuan/gettuanlistbyshop', {
            types: 1,
        }).then((res: any) => {
            offset.current += res.tuan_list
            setList([
                ...res.tuan_list.map((i: any) => {
                    return {
                        image: i.image,
                        title: i.title,
                        id: i.id,
                    }
                })
            ])
        })
    }
}

export default withRouter(TuanList)