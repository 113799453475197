import React, { useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { good } from '../datalist'
import { message } from 'antd'
import { post } from '../../../netApi/fetch'
import Toast from '../../Method/Toast'

interface i extends good {

}

const Border = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    & >div:not(:last-child){
        margin-bottom: 16px;
    }
    input{
        border: 1px solid #ebebeb;
        margin-left: 20px;
        width: 150px;
        padding: 6px 11px;
    }
`

interface props {
    onClose: () => void
    data: {

    }
}

interface sku {
    no: string,
    bar: string,
    weight: string,
    spec: {
        spec: {
            id: number,
            name: string,
            rank: number,
        },
        spec_item: {
            id: number,
            name: string,
            rank: string,
        }
    }[]
    ids: string,
}

let wrapper: Element


export default async function (good: good, callback?: () => void) {
    return new Promise<void>(async (reslove, reject) => {
        function Sku(props: props) {

            const [list, setList] = useState(good.attribute.map(i => {
                return {
                    goodsattrid: i.goodsattrid,
                    unit: i.unit,
                    stock_num: '',
                }
            }))

            const [shouldClose, setShouldClose] = useState(false)

            return <Modal title='开启出售' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <Border>
                        <div style={{ marginBottom: 10, fontWeight: 'bold' }}>设置库存</div>
                        <div style={{ marginBottom: 10, color: '#999' }}>设置库存后该商品才可展示及出售</div>
                        {list.map((i, idx) => {
                            return <div key={i.goodsattrid} >
                                <div style={{ marginBottom: 20, }}>规格{idx + 1}：{i.unit}</div>
                                <div style={{ marginBottom: 20, }} className='flex-box'>
                                    <div>库存</div>
                                    <input type='number' onChange={e => {
                                        i.stock_num = e.target.value
                                        setList([...list])
                                    }} value={i.stock_num} />
                                    <div>件</div>
                                </div>
                            </div>
                        })}
                    </Border>
                </div>
            </Modal>

            function confirm() {
                if (list.find(i => i.stock_num === '')) {
                    message.warn('请填写库存')
                    return
                }
                const data = {
                    op: 6,
                    mall_goods_id: good.mall_goods_id,
                    goodsid: good.goodsid,
                    sku_list: list.map(i => {
                        return {
                            goodsattrid: i.goodsattrid,
                            stock_num: i.stock_num,
                        }
                    })
                }
                post('/mall/setmallgoods', data).then(res => {
                    setShouldClose(true)
                    callback && callback()
                    Toast.show('已开启出售')
                    reslove()
                }).catch(e => { if (e.message) message.error(e.message) })
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Sku data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
        }} />, wrapper)
    })
}