// import 'core-js';

import React, { Component } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import "./App.css";
// import './style/theme.less';

import Myrouter from "./router/Myrouter";
import reducer from "./reducers/index";

// import './style/bootstrap.min.css'
import "./style/animate.css";
import "./style/total.scss";
import "./style/Sidebar.scss";
import "./style/Home.scss";
import "./style/login.scss";
// import './style/ContentLayoutPanel.scss'
import "./style/Payment.scss";
import "./style/order.scss";
import "./style/member_after_pay.scss";
import "./style/tuan.scss";
import "./style/Good.scss";
import "./style/ali.scss";
import { post } from "./netApi/fetch";

const store = createStore(reducer);

class App extends Component {
  constructor(props: any) {
    super(props);
    document.title =
      window.location.href.indexOf("jieligo") === -1
        ? "GroupAI公司官网"
        : "接力GO";
  }
  componentDidMount() {
    window.onerror = (e) => {
      let state = "";
      if (process && process.env && process.env.NODE_ENV)
        state = process.env.NODE_ENV;
      if (state !== "development") {
        post("/file/senderror", {
          errlog: {
            error: e,
            navigator,
          },
        });
      }
    };
  }

  render() {
    return (
      <Provider store={store}>
        <Myrouter />
      </Provider>
    );
  }
}

export default App;
