import React, { Component } from 'react';
import { get } from '../../netApi/fetch';
import { RouteComponentProps, match } from 'react-router-dom'
import styled from 'styled-components';
import Loading from '../Method/Loading';

interface props extends RouteComponentProps {
    match: m,
}

interface m extends match {
    params: {
        tuanid: string
    }
}

interface state {
    list: item[],
    tuanname?: string
}

interface item {
    id: string,
    data: {
        name: string,
        num: number,
        unit: string,
        style_no: string,
        refunding_num: string | number,
    }
}

const Table = styled.table`
    thead{
        display: table-header-group;
        font-weight: bold;
        tr:first-child{
            td{
                font-size: 24px;
            }
        }
        tr:last-child{
            td{
                text-align: center;
            }
        }
    }
    tbody{
        td{
            border: 1px solid #000;
            text-align: center;
            line-height: 2;
        }
    }
    *{
        color: #000;
        font-family: SimHei;
    }
    border-collapse: collapse;
    width: 90%;
    margin: 20px auto 60px;
    &:nth-child(2n){
        page-break-after: always;
    }
    @media print{
        margin: 30px auto 60px;
    }
`
const Print = styled.div`
    position: sticky;
    right: 0;
    top: 170px;
    text-align: right;
    @media print {
        display: none;
    }
`
const Wrap = styled.div`
    @media screen {
        position: relative;
        margin: 0 auto;
        max-width: 1200px;
    }
    @media print{
        max-width: unset;
        margin: 0;
        width: 100%;
    }
`
class PickList extends Component<props>{

    readonly state: state = {
        list: []
    }

    componentDidMount() {
        Loading.show()
        get('/order/getorderstatistics', {
            types: 1,
            tuanid: this.props.match.params.tuanid,
        }).then((res: any) => {
            this.setState({ tuanname: res.tuan_info.title })
        })
        get('/pcorder/orderList', {
            sort: 'asc',
            option: 0,
            tuanid: atob(decodeURIComponent(this.props.match.params.tuanid)),
            output_sheet_list: '2,5,4',
            types: 1,
            output_type: 'array',
        }).then((res: any) => {
            const data = res.output_by_statistics.list
            const list = Object.keys(data).map((i: string) => {
                return {
                    id: Number(i),
                    data: data[i]
                }
            })
            this.setState({ list })
            Loading.hide()
        })
    }

    render() {
        return <Wrap>
            <Print><button className='button_solid_red' onClick={() => { window.print() }}>打印</button></Print>
            <div>
                {/* 门店拣货单 */}
                <Table>
                    <thead>
                        <tr>
                            <td colSpan={6}>
                                {this.state.tuanname ? <span style={{ marginRight: 16 }}>{this.state.tuanname}</span> : null}
                                商品总单
                                </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000', width: '7em' }}>商品编码</td>
                            <td style={{ border: '1px solid #000', }}>商品名称</td>
                            <td style={{ border: '1px solid #000', }}>商品规格</td>
                            <td style={{ border: '1px solid #000', }}>退款中</td>
                            <td style={{ border: '1px solid #000', width: '4em' }}>数量</td>
                            {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list.map((good: item, idx: number) => {
                            if (idx === this.state.list.length - 1) {
                                return <tr key={idx}>
                                    <td colSpan={3}>{good.data.name}</td>
                                    <td style={{ border: '1px solid #000', }}>{good.data.refunding_num}</td>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>{good.data.num}</td>
                                </tr>
                            }
                            return <tr key={idx}>
                                <td style={{ border: '1px solid #000', width: '7em' }}>{good.data.style_no}</td>
                                <td style={{ border: '1px solid #000', }}>{good.data.name}</td>
                                <td style={{ border: '1px solid #000', }}>{good.data.unit}</td>
                                <td style={{ border: '1px solid #000', }}>{good.data.refunding_num}</td>
                                <td style={{ border: '1px solid #000', width: '4em' }}>{good.data.num}</td>
                                {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                                {/* <td style={{ border: '1px solid #000', width: '4em' }}>{(a.price / 100).toFixed(2)}</td> */}
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        </Wrap>
    }
}

export default PickList