import React, { useState, useEffect,   } from 'react';
import { match, NavLink, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ShopManage from './method/ShopManage';
import MallSetting from './method/MallSetting';
import MallAnalysis from './component/MallAnalysis';
import MallOrder from './component/MallOrder';
import MallGoods from './component/MallGoods';
import { Nav, Num } from './style';
import styled from 'styled-components';
import { get, post } from '../../netApi/fetch';
import { HR2SR } from '../../StyleComponents/Button';
import Toast from '../Method/Toast';
import { admin } from '../../data/competence';
import text from '../../data/text';
import hemaShopManage from './method/hemaShopManage';


interface Props extends RouteComponentProps {
    match: Match,
    user: user,
    mall: mall.mallinfo,
    isHema: boolean,
}

interface Match extends match {
    params: {
    }
}

const Phone = styled.div`
    div:hover > &{
        display: flex;
    }
    width: 450px;
    height: 450px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px #cccccc;
    position: absolute;
    bottom: -450px;
    right: 12px;
    padding: 50px 20px 85px;
    display: none;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    img{
        width: 146px;
        height: 146px;
        margin-bottom: 20px;
        margin-top: 30px;
    }
`


function C(props: Props) {
    const [active, setActive] = useState(0)

    const [mallData, setMallData] = useState<any>()
    useEffect(() => {
        post('/charge/checkmemberauth', { sid: false, auth_type: 1 }).then(res => {
            text.ERROR.MEMBER.ADMIN = res.message
            admin.set(res.auth)
        })
    }, [])

    useEffect(() => {
        if (!mallData && props.mall.distribution_relation_id) {
            get('/mall/mallview', {
                relation_id: props.mall.distribution_relation_id,
            }).then(res => {
                setMallData(res)
            })
        }
    }, [mallData, props.mall.distribution_relation_id])

    useEffect(() => {
        const page = props.location.pathname
        if (/goods/.test(page)) {
            setActive(1)
        } else if (/applyrefund/.test(props.location.search)) {
            setActive(3)
        } else if (/orders/.test(page)) {
            setActive(2)
        } else if (/analysis/.test(page)) {
            setActive(4)
        }
    }, [props])

    const [code, setCode] = useState<string>()
    useEffect(() => {
        if (!props.mall) return
        if (!props.mall.id || !props.mall.distribution_relation_id) return
        post('/user/getwxacode?app=jieligo', {
            width: 200,
            page: 'package/packageUserhome/userhome',
            scene: `mall_id=${props.mall.id}&relation_id=${props.mall.distribution_relation_id}`,
        }).then(res => {
            setCode(res.image)
        })
    }, [props.mall])
    return <div>
        <Nav active={active}>
            <NavLink to={`/index/mall/goods/2`}>
                <i className='iconfont icon-shouzhimingxi1' />
                <div>管理商品</div>
            </NavLink>
            <NavLink to={`/index/mall/orders`}>
                <i className='iconfont icon-dingdanguanli1' />
                <div>订单管理</div>
                {Number(mallData?.mall_order_statistics?.mall_order_not_delivery_num) > 0 ? <Num>{mallData.mall_order_statistics.mall_order_not_delivery_num}</Num> : null}
            </NavLink>
            <NavLink to={`/index/mall/orders?option=applyrefund`}>
                <i className='iconfont icon-tuikuan3' />
                <div>退款</div>
                {Number(mallData?.mall_order_statistics?.mall_order_refunding_num) > 0 ? <Num>{mallData.mall_order_statistics.mall_order_refunding_num}</Num> : null}
            </NavLink>
            <NavLink to={`/index/mall/${props.mall.id},${props.mall.distribution_relation_id}/analysis`}>
                <i className='iconfont icon-jiaoyifenxi1' />
                <div>交易分析</div>
            </NavLink>
            {(Number(props.mall.pid) === 0 && admin.get()) ? <div onClick={() => { 
                if(!props.isHema)  ShopManage({ user: props.user, mallinfo: props.mall })
                if(props.isHema) {
                    hemaShopManage({ user: props.user, mallinfo: props.mall })
                    return
                }
             }}>
                <i className='iconfont icon-shangcheng-weixuanzhong' />
                <div>管理门店</div>
            </div> : null}
            <div onClick={() => {
                MallSetting({ user: props.user }).then(res => {
                    // setMallinfo(false)
                }).catch(e => { })
            }} >
                <i className='iconfont icon-shoujichakan' />
                <div>商城设置</div>
            </div>
            <div>
                <i className='iconfont icon-shoujichakan' />
                <div>手机端查看</div>
                <Phone>
                    <div style={{ color: '#666' }} >扫一扫用手机查看商城</div>
                    <img alt='' src={code} />
                    <HR2SR onClick={download_code} >保存小程序码到本地</HR2SR>
                    <div style={{ borderBottom: '1px solid #d8d8d8', marginBottom: 20, }}></div>
                    <div style={{ marginBottom: 16, fontSize: 12, color: '#333', width: '100%' }} >商城小程序路径</div>
                    <div style={{
                        background: '#f2f2f2',
                        borderRadius: 7,
                        width: '100%',
                        padding: '6px 11px'
                    }} className='flex-between' >
                        <input style={{ border: 0, color: '#333', flex: 1 }} disabled value={`/package/packageUserhome/userhome?relation_id=${props.mall.distribution_relation_id}`} />
                        <HR2SR onClick={copy_link}>复制</HR2SR>
                    </div>
                </Phone>
            </div>
        </Nav>
        <Switch>
            <Route path={`/index/mall/orders`} >
                <MallOrder />
            </Route>
            <Route path={`/index/mall/:id/analysis`} component={MallAnalysis} />
            <Route path={`/index/mall/goods/:type`} component={MallGoods} />
        </Switch>
    </div>

    function download_code() {
        if (!code) return
        const image = new Image()
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            const context = canvas.getContext('2d')
            context.drawImage(image, 0, 0, image.width, image.height)
            const url = canvas.toDataURL('image/png')

            // 生成一个a元素
            const a = document.createElement('a')
            // 创建一个单击事件
            const event = new MouseEvent('click')
            a.download = '商城小程序码'
            a.href = url
            a.dispatchEvent(event)
        }

        image.src = code
    }
    function copy_link() {
        if (!props.mall.distribution_relation_id) return
        const input = document.createElement('input');
        input.setAttribute('readonly', 'readonly');
        input.setAttribute('value', `/package/packageUserhome/userhome?relation_id=${props.mall.distribution_relation_id}`);
        document.body.appendChild(input);
        input.select()
        // input.setSelectionRange(0, 9999);
        if (document.execCommand('copy')) {
            document.execCommand('copy')
            Toast.show('复制成功')
        }
        document.body.removeChild(input);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        isHema: state.isHema,
    }
}

export default connect(mapStateToProps)(withRouter(C))