import React, { Component } from 'react'

import Modal from '../../total/Modal';
import Select from '../../total/Select_';
import { ReduxImg } from '../../total/DraggableImgs';

import styled from 'styled-components';

import { get, post } from '../../../netApi/fetch';

import { message, Timeline } from 'antd';

interface Props {
    order: order,
    onClose: (e?: any) => void,
    refundList: any[]
    refundState: {
        process: string,
        top: number
    },
}
interface State {
    loading: boolean,
    refundData: {
        refund_mode: string,
        refund_sku_list?: {
            name: string,
            num: number,
            unit: string,
        }[],
        refund_time: string[],
        refund_fee: string,
    },
    inited: boolean,
    showConfirm: boolean,
    showReject: boolean,
    reasonList: { title: string, id: number }[],
    agreeList: { title: string, id: number }[],
    rejectReason?: number,
    rejectReasonText?: string,
    refundList: any[],
    refundState: {
        process: string | null,
        top: number | null,
    },
    agreeRefund: boolean
}

interface uploadData {
    refund_desc?: string,
    refund_reason?: number,
    images_item?: {
        rank: number | string,
        id: number | string,
    }[]
}

const Intro = styled.p`

`
const Message = styled.div`
    flex-wrap: wrap;
    div:nth-child(1){
        margin-right: 1rem;
    }
`

const Goodslist = styled.div`
    max-height: 50vh;
    overflow-y: auto;
    & > div > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: .4rem;
        & div:nth-child(2){
            min-width: 5em;
        }
    }
`

const RefundItem = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div:nth-child(1){
        width: 6em;
    }
    & > div:nth-child(2){
        flex: 1;
        & > input {
            display: inline-block;
            width: 100%;
            border-radius: 4px;
            padding: 4px 11px;
            border: #d9d9d9 solid 1px;
            transition: all .3s;
        }
    }
`

class Refund extends Component<Props, State> {
    readonly state: State = {
        loading: false,
        refundData: {
            refund_mode: '',
            refund_sku_list: [{ name: '', num: 0, unit: '' }],
            refund_time: [],
            refund_fee: '',
        },
        inited: false,
        showConfirm: false,
        showReject: false,
        reasonList: [{ title: '订单已发货', id: 5 }, { title: '货物不支持退款', id: 6 }, { title: '已超出退货/退款时间', id: 7 }, { title: '其他原因', id: 8 }],
        agreeList: [{ title: '客户不想要了', id: 9 }, { title: '客户选错商品', id: 10 }, { title: '客户写错地址', id: 11 }, { title: '其他原因', id: 8 }],
        rejectReasonText: '',
        refundList: [],
        refundState: {
            process: null,
            top: null
        },
        agreeRefund: false  //true---同意按钮  false---退款按钮
    }

    async componentDidMount() {
        this.setState({
            refundData: await this.getData(),
            inited: true,
            refundList: this.props.refundList,
            refundState: this.props.refundState
        })
    }

    UploadRefundData: uploadData = {}

    render() {
        return <Modal confirmText={this.state.refundState && this.state.refundState.process === 'new' && Number(this.state.refundState.top) === 0 ? '同意并向上级申请退款' : '确认退款'} title='退款' loading={this.state.loading || !this.state.inited} cancel cancelText='拒绝退款'
            onReject={this.toggleReject.bind(this)}
            close={() => { this.props.onClose() }} confirm={this.confirm.bind(this)} >
            {this.state.inited !== false ? <>
                <Message className='fbc'>
                    <div>退款类型： <strong>{this.state.refundData.refund_mode}</strong></div>
                    <div>{this.state.refundData.refund_time[0]}</div>
                </Message>
                <div>退款金额：  <strong>{this.state.refundData.refund_fee}</strong></div>
                {this.state.refundData.refund_sku_list ? <><h3 style={{ marginTop: '1.5rem' }}>商品信息</h3>
                    <Goodslist>
                        <div>
                            {this.state.refundData.refund_sku_list.map((item, i) => {
                                return <div key={i}>
                                    <div>{item.name}({item.unit})</div>
                                    <div>数量： {item.num}</div>
                                </div>
                            })}
                        </div>
                    </Goodslist></> : null}
            </> : null}
            {this.state.showConfirm ? <Modal title='确认退款' confirm={this.refund.bind(this)} cancel close={() => { this.setState({ loading: false, showConfirm: false, }) }}>
                <Intro onClick={() => { this.show() }} >
                    <span>{this.state.agreeRefund ? '确定同意并向上级申请退款吗？申请后需逐级向上申请退款审批' : '确认退款吗？退款后金额将直接返回用户手里，且不可取消退款'}</span><br />
                    <span style={{ color: '#999999', fontSize: '10px' }}>温馨提示：每个订单最多可退款3次，取消退款申请也算一次</span>
                </Intro>
            </Modal> : null}

            {this.state.showReject ? <Modal cancel title={this.state.agreeRefund ? '同意退款' : '拒绝理由'} onConfirm={this.reject.bind(this)} onClose={this.toggleReject.bind(this)}>
                <RefundItem>
                    <div>{this.state.agreeRefund ? '同意' : '退款'}理由：</div>
                    <div>
                        <Select
                            list={this.state.agreeRefund ? this.state.agreeList : this.state.reasonList}
                            showKey={'title'} placeholder='选择拒绝理由'
                            uni='reason' onChange={this.selectRaeson.bind(this)} value={this.state.rejectReason} />
                    </div>
                </RefundItem>
                <RefundItem>
                    <div>其他说明：</div>
                    <div>
                        <input value={this.state.rejectReasonText} placeholder='选填' onChange={this.setReasonText.bind(this)} />
                    </div>
                </RefundItem>
                <div>
                    <div style={{ marginBottom: 12 }}>图片：</div>
                    <div style={{ width: 250 }}>
                        <ReduxImg maxLength={6} imgtype='refund' images={[]} width={70} height={70} onChange={(e: image[]) => {
                            const imglist = e.map((i: image) => {
                                const rank: number = Number(i.rank) || 0
                                return {
                                    id: i.id,
                                    rank: rank,
                                }
                            })
                            this.UploadRefundData.images_item = imglist
                        }} />
                    </div>
                    <div style={{ overflowY: 'auto', maxHeight: '40vh', width: '45vw' }}>
                        {this.state.refundList && this.state.refundList.length !== 0 ? this.state.refundList.map((refundListItem, refundListIndex) => {
                            return <div key={refundListIndex}>
                                {refundListItem.length !== 0 ? <div style={{ borderBottom: '1px solid #ccc', marginBottom: '10px' }} >
                                    <div> {refundListIndex + 1} &nbsp;&nbsp;退款审批进度：</div>
                                    <Timeline style={{ marginLeft: '20px', marginTop: '15px' }}>
                                        {refundListItem.map((item: any, index: number) => {
                                            const rs = Number(item.review_status)
                                            return <Timeline.Item key={index} color={rs === 1 || rs === 0 || index === refundListItem.length - 1 ? '#FF9A27' : '#CCCCCC'} style={{ paddingBottom: index === this.state.refundList.length - 1 ? '10px' : '' }}>
                                                <div style={{ marginLeft: '10px' }}>
                                                    {
                                                        item.name ? <div style={{ fontSize: '12px', marginBottom: '10px' }}>{item.name}</div> : null
                                                    }
                                                    {
                                                        !item.time_desc ? <div style={{ fontSize: '12px', marginBottom: '10px' }}>{item.reviewtime}</div> : null
                                                    }
                                                    {
                                                        item.time_desc ? <div style={{ fontSize: '12px', marginBottom: '10px' }}>{item.time_desc}</div> : null
                                                    }
                                                    {
                                                        (rs === 1 || rs === 0) && item.review_reason.refund_reason ? <div style={{ fontSize: '12px', marginBottom: '10px' }}>{rs === 1 ? '同意' : '拒绝'}理由:&nbsp;&nbsp;{item.review_reason.refund_reason}</div> : null
                                                    }
                                                    {
                                                        (rs === 1 || rs === 0) && item.review_reason.refund_desc ? <div style={{ fontSize: '12px', marginBottom: '10px' }}>其他说明:&nbsp;&nbsp;{item.review_reason.refund_desc}</div> : null
                                                    }
                                                    {
                                                        item.review_reason && item.review_reason.length !== 0 && item.review_reason.images.length !== 0 ? <div>
                                                            <div style={{ fontSize: '12px', marginBottom: '10px' }}>图片：</div>
                                                            {
                                                                item.review_reason.images.map((imgItem: any, imgIndex: number) => {
                                                                    return <img alt='' src={imgItem.src} key={imgIndex} style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                                                                })
                                                            }
                                                        </div> : null}
                                                </div>
                                            </Timeline.Item>
                                        })}
                                    </Timeline>
                                </div> : null}
                            </div>
                        }) : null}
                    </div>
                </div>
            </Modal> : null}
        </Modal>
    }

    confirm(): void {
        this.setState({ loading: true, showConfirm: true, })
    }

    show() {
        console.log(this.props, this.state)
    }

    async getData() {
        const refundData = await get('/order/detail', { types: '1', orderid: window.btoa(String(this.props.order.orderid)) })
        const ret = refundData.refund_info[Math.max(...Object.keys(refundData.refund_info).map(i => Number(i)))]
        return ret
    }

    async refund() {
        if (this.state.refundState && this.state.refundState.process === 'new' && Number(this.state.refundState.top) === 0) {
            this.setState({
                showReject: !this.state.showReject,
                agreeRefund: true,
            })
        } else {
            if (this.state.refundState && Number(this.state.refundState.top) === 0) {
                message.error('请找团长同意退款')
            } else {
                this.setState({ showConfirm: false })
                try {
                    await post('/order/confirmrefund', {
                        types: 1,
                        orderid: this.props.order.orderid,
                        items: [],
                        refund_fee: 0,
                        refund_mode: 1,
                    })
                    this.setState({ loading: false })
                    message.success('退款成功')
                    this.props.onClose()
                } catch (e) {
                    this.setState({ loading: false })
                    if (e.message) message.error(e.message)
                }
            }

        }
    }

    toggleReject(): void {
        this.setState({ showReject: !this.state.showReject, agreeRefund: false })
    }

    async reject() {
        if (!this.UploadRefundData.refund_reason) {
            message.warn('请选择退款理由')
            return
        }
        if (this.UploadRefundData.refund_reason === 8) {
            if (!this.UploadRefundData.refund_desc) {
                message.warn('请填写退款理由')
                return
            }
        }
        this.setState({ showConfirm: false })

        // 判断process--是新流程还是旧流程
        if (this.state.refundState && this.state.refundState.process === 'new') {
            // 判断agreeRefund 是同意还是拒绝
            if (this.state.agreeRefund) {
                this.reviewrefundapply('agree')
            } else {
                this.reviewrefundapply('reject')
            }
        } else {
            try {
                await post('/order/cancelrefundapply', {
                    types: 1,
                    orderid: this.props.order.orderid,
                    ...this.UploadRefundData,
                })
                message.success('已拒绝退款')
                this.props.onClose({ shouldReflesh: true })
            } catch (e) {
                this.setState({ loading: false })
                if (e.message) message.error(e.message)
            }
        }
    }

    reviewrefundapply(type: string) {
        post('/order/reviewrefundapply', {
            op: type,
            orderid: this.props.order.orderid,
            ...this.UploadRefundData,
        }).then(() => {
            if (type === 'agree') {
                message.success('同意退款')
            } else {
                message.success('已拒绝退款')
            }
            this.setState({ agreeRefund: false })
            this.props.onClose({ shouldReflesh: true })
        }).catch((e) => {
            if (e.message) message.error(e.message)
            this.setState({ loading: false, showConfirm: false, })
        })
    }

    selectRaeson(e: { [key: string]: any; }) {
        let list = this.state.agreeRefund ? this.state.agreeList : this.state.reasonList
        this.setState({ rejectReason: list.findIndex(i => i.id === e.id) })
        this.UploadRefundData.refund_reason = e.id
    }
    setReasonText(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ rejectReasonText: e.currentTarget.value })
        this.UploadRefundData.refund_desc = e.currentTarget.value
    }
}

export default Refund