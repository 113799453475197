import React, { Component } from 'react'
// import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router-dom'

// import Select from '../total/Select_';

// import api from '../../netApi';
import {API} from '../../data/options';

import WX from '../../high-component/Wx_sign';
// import parse from '../../methods/paramParse';
import { getToken } from '../../netApi/fetch';
// import styled from 'styled-components';
import Modal from '../total/Modal';
// import moment from 'moment'

import { DatePicker, message } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';

interface Props extends RouteComponentProps {
    match: Match,
    islogin: boolean,
    user: LoginUserData,
}

interface Match extends match {
    params: {
        data: any
    }
}

interface state {
}

// const Mask = styled.img`
//         width: 200px;
//         mask-image: url('https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/jFKAU25d2V.svg');
//         mask-mode: match-source;
//         mask-size: contain;
// `

class Export extends Component<Props> {

    readonly state: state = {

    }
    componentDidMount() {

    }

    componentDidUpdate() {

    }

    start?: string = undefined
    end?: string = undefined

    render() {
        return (<>
            <Modal banMaskClose banAnime onClose={this.props.history.goBack} title='导出对账单' onConfirm={()=>{
                if(!this.start || !this.end){
                    message.warn('请选择时间')
                    return    
                }
                window.open(`${API}/pcorder/exportstatementofaccountbytime?types=1&start_time=${this.start}&end_time=${this.end}&token=${getToken()}`)
            }} >
                <div>
                    <div>选择导出时间</div>
                    <div>
                        <DatePicker.RangePicker showTime locale={locale} onChange={(e) => {
                            if(!e)return
                            if(e[0])this.start = e[0].format('YYYY-MM-DD HH:mm:ss')
                            if(e[1])this.end = e[1].format('YYYY-MM-DD HH:mm:ss')
                        }} />
                    </div>
                </div>
            </Modal>
        </>)
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.login_user_data,
        islogin: state.is_Login
    }
}

export default WX(connect(mapStateToProps)(Export))
