import React, { Component } from 'react'
import styled from 'styled-components';
import { message } from 'antd';
import { post } from '../../netApi/fetch';
import { confirm } from '../Method/Confirm';
import { RankItem } from './MySeller';
import Loading from '../Method/Loading';
import { GroupItem } from './Level';
import Modal from '../total/Modal';
import Select from '../total/Select_';
import BTN from '../../StyleComponents/Button';

interface Props {
    data: RankItem,
    reload: () => void,
    teamList: GroupItem[]
}

interface State {
    showMenu: boolean,
    showConfirmBlackList: boolean,
    showConfirmCanSale: boolean,
    showConfirmAdmin: boolean,
    showChangeShopgradeuser: boolean,
    showSelectGroup: boolean,
    classList?: {
        id: string | number,
        discount: string,
        name: string,
    }[],
    classThis?: {
        id: string | number,
    },
    selectedGroup?: GroupItem
}

const Wrap = styled.div`
    font-size: 12px;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    &:hover{
        background: #f8f8f8;
    }
    .followItemContent{
        .flex-box{
            margin-bottom: 10px;
            &>div{
                margin-right: 16px
            }
        }
    }
    .tag{
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        padding: 4px 11px;
    }
`
const Logo = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 16px;
`

const Ctrl = styled.div`
    position: absolute;
    right: 0;top: 0;
    height: 70px;
    width: 70px;
    i{
        line-height: 1;
        font-size: 20px;
    }
`
const Menu = styled.div`
    transition: all .3s;
    box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);
    position: absolute;
    right: 20px;
    transform-origin: top center;
    top: 50px;
    width: 220px;
    background: white;
    overflow: hidden;
    z-index: 10;
    div{
        padding: 14px 40px;
        font-size: 14px;
        color: #333;
        text-align: center;
        user-select: none;
        &:hover{
            background: #f8f5f5;
        }
    }
`

const DataView = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    margin-right: 16px;
    &>div:nth-child(1){
        margin-bottom: 12px;
    }
`
const DataViewRed = styled(DataView)`
    color: var(--theme-main);
`

class Item extends Component<Props> {
    readonly state: State = {
        showMenu: false,
        showConfirmBlackList: false,
        showConfirmCanSale: false,
        showConfirmAdmin: false,
        showChangeShopgradeuser: false,
        showSelectGroup: false,
    }

    componentDidMount(){
        this.setState({
            selectedGroup: this.props.teamList.find(i => i.id === this.props.data.team_id)
        })
    }
    // componentWillUnmount(){
    //     window.removeEventListener('click', this.menuHandle)
    // }
    // menuHandle = this.closeMenu.bind(this)

    render() {
        const { data } = this.props
        return <>
            <Wrap className='flex-between flex-top' onClick={this.closeMenu.bind(this)} onMouseLeave={this.closeMenu.bind(this)}>
                <Logo src={data.shopinfo.logo + '?imageView2/5/w/170/h/170'} />
                <div className='flex1'>
                    <div className='flex-box ' style={{ marginBottom: 16 }} >
                        <div style={{ color: '#333', fontSize: 16, marginRight: 16 }}>{data.shopinfo.name}</div>
                        <div className='tag' style={{ color: '#FFA92C', borderColor: '#FFA92C', marginRight: 16 }}>{data.team_name || '帮卖好友'}</div>
                        <div>{data.createtime}</div>
                    </div>
                    <div className='flex-between'>
                        <div className='flex-box'>
                            <DataViewRed>
                                <div>累计销售</div>
                                <div>￥{data.sale_total}</div>
                            </DataViewRed>
                            <DataView>
                                <div>累计开团</div>
                                <div>{data.tuan_total}</div>
                            </DataView>
                            <DataView>
                                <div>累计帮卖佣金</div>
                                <div>￥{data.pay_rebates}</div>
                            </DataView>
                            {/* <DataView>
                                <div>累计推荐佣金</div>
                                <div>￥{data.sale_total}</div>
                            </DataView> */}
                        </div>
                        <div>
                            <BTN color='gray' onClick={this.delete.bind(this)}>取消帮卖</BTN>
                        </div>
                    </div>
                </div>
                <Ctrl className='flex-center cur-pointer' onClick={(e) => {
                    e.stopPropagation()
                    this.setState({ showMenu: !this.state.showMenu })
                }}>
                    <i className='iconfont icon-gengduo' />
                    <Menu style={{
                        maxHeight: this.state.showMenu ? 220 : 0,
                    }}>
                        <div onClick={this.clickMenu.bind(this, 1)}>修改帮卖好友等级</div>
                        <div onClick={this.clickMenu.bind(this, 2)}>查看TA的联系方式</div>
                        {/* <div onClick={this.clickMenu.bind(this, 3)}>置顶帮卖好友</div> */}
                    </Menu>
                </Ctrl>
            </Wrap>
            {this.state.showSelectGroup ? <Modal title='修改帮卖好友等级' onClose={() => { this.setState({ showSelectGroup: false }) }}
                onConfirm={() => {
                    if (!this.state.selectedGroup) {
                        message.warning('请选择好友的等级')
                        return
                    }
                    Loading.show()
                    post('/seller/operateseller', {
                        types: 1, op: 'change_team', uid: this.props.data.uid, team_id: this.state.selectedGroup.id
                    }).then(() => {
                        Loading.hide()
                        this.props.reload()
                        message.success('设置成功')
                        this.setState({ showSelectGroup: false })
                    }).catch((e) => {
                        Loading.hide()
                        this.props.reload()
                        if (e.message) message.error(e.message)
                    })
                }} >
                <div>
                    <div className='flex-box' style={{ marginBottom: 12 }}>
                        <img alt='' src={data.shopinfo.logo || ''} style={{ width: 36, height: 36, borderRadius: '50%', marginRight: 16 }} className='img-center' />
                        <div>{data.shopinfo.name}</div>
                    </div>
                    <div className='flex-box'>
                        <div>修改等级：</div>
                        <div>
                            <Select placeholder='请选择分组' uni='level' list={[...this.props.teamList,{id: 0, name: '取消好友等级', } ]} showKey='name' value={[...this.props.teamList, {id: 0, name: '取消好友等级', }].findIndex(i => i.id === this.state.selectedGroup?.id)} onChange={(e: any) => {
                                this.setState({ selectedGroup: e })
                            }} />
                        </div>
                    </div>
                </div>
            </Modal> : null}
        </>
    }

    clickMenu(type: number) {
        switch (type) {
            case 1:
                this.setState({ showSelectGroup: true })
                break;
            case 2:
                Loading.show()
                post('/seller/operateseller', {
                    types: 1, op: 'contact', uid: this.props.data.uid,
                }).then((res: any) => {
                    Loading.hide()
                    if (!res.shop_info) return
                    const data = res.shop_info
                    confirm({
                        text: <div>
                            <div className='flex-box' style={{ marginBottom: 12 }}>
                                <img alt='' src={data.logo || ''} style={{ width: 36, height: 36, borderRadius: '50%', marginRight: 16 }} className='img-center' />
                                <div>{data.name}</div>
                            </div>
                            <div style={{ marginBottom: 12 }}>联系方式：{data.phone}</div>
                            <div style={{ marginBottom: 0 }}>地址：<span>{data.address + data.addressdetail}</span></div>
                        </div>,
                        title: '联系方式'
                    }).then()
                }).catch((e) => {
                    Loading.hide()
                    if (e.message) message.error(e.message)
                })
                break
            default:
                break;
        }
    }

    delete() {
        confirm({ text: '确定取消该好友帮卖吗', title: '操作提示' }).then((res: any) => {
            if (res) {
                post('/seller/delete', {
                    types: 1,
                    uid: this.props.data.uid,
                }).then(() => {
                    message.success('取消成功')
                    this.props.reload && this.props.reload()
                })
            }
        })
    }

    closeMenu() {
        this.setState({ showMenu: false })
    }
}

export default Item