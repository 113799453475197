
import React, { Component } from 'react';
import { connect } from 'react-redux'

import Modal from '../../total/Modal';

import { get, post } from '../../../netApi/fetch';
import BTN from '../../../StyleComponents/Button';
import { message } from 'antd';
import Search from '../../total/Input/Search';
import Select from '../../total/Select_';
import editAddressCategory from '../../address/editAddressCategory';

interface SelectAddress extends address {
    editting: boolean,
    editcontact: string,
    editmobile: string,
    editname: string,
    editCate: number
    editaddressdetail: string,
    selected?: boolean
}

interface Props {
    user: user,
    selectedAddress: string[],
    onClose: () => void,
    onConfirm: (arr: string[]) => void,
    notSelect?: boolean,
    isHema?: boolean,
    is_hssq?: boolean
    branch?: {
        branch_name: string
        distribution_relation_id: string
        id: string
        logo: string
        name: string
    }
}
interface State {
    nowSelectAddress: string[],
    addressList: SelectAddress[],
    addAddress: SelectAddress
    shouldClose: boolean,
    loading: boolean,
    searchKey: String
    catList: {
        id: number | string
        name: string
    }[]
    selectedCatId: number
    isSelectAll: boolean
}
class Address extends Component<Props, State>{
    readonly state = {
        nowSelectAddress: [''],
        addressList: [],
        addAddress: {
            editting: false,
            editcontact: '',
            editmobile: '',
            editname: '',
            editCate: -1,
            editaddressdetail: '',
            id: '',
            name: '',
            address: '',
            province: '',
            city: '',
            area: '',
            addressdetail: '',
            contact: '',
            mobile: '',
            sid: '',
            uid: '',
            status: '',
            addressname: '',
        },
        shouldClose: false,
        loading: true,
        searchKey: '',
        catList: [],
        selectedCatId: 0,
        isSelectAll: false,
    }

    componentDidMount() {
        this.getAddress()
        this.getCat()
        this.setState({ nowSelectAddress: [...this.props.selectedAddress] })
    }

    componentDidUpdate(_, state) {
        if (state.selectedCatId !== this.state.selectedCatId) {
            this.search()
        }
    }

    render() {
        return <Modal loading={this.state.loading} style={{ content: { maxHeight: '60vh', overflowY: 'auto', minWidth: '50vw', boxSizing: 'content-box', paddingLeft: 0, paddingBottom: 0, } }} shouldClose={this.state.shouldClose}
            title={this.props.notSelect ? '自提点设置' : <div className='flex-center'>
                <div style={{ marginRight: 12 }} >选择自提点</div>
            </div>} close={() => { this.props.onClose() }} cancel={true} confirm={() => {
                this.setState({ shouldClose: true })
                this.props.onConfirm(this.state.nowSelectAddress)
            }}
            confirmText={this.props.notSelect ? '确定' : `已选择${this.state.nowSelectAddress.length}个自提点`}
            tips={<div className='cur-pointer' style={{ display: 'flex', alignItems: 'center', }}>
                {this.state.isSelectAll ? <div onClick={this.cancelAll.bind(this)}>
                    <i className="iconfont icon-gou" style={{ marginRight: 8, color: 'var(--theme-main)' }} />
                    取消全选
                </div> : <div onClick={this.selectAll.bind(this)}>
                    <i className="iconfont icon-yuan" style={{ marginRight: 8, }} />
                    全选
                </div>}

            </div>}
        >
            <div className='flex-between'  >
                <div className='hiddenScrollBar' style={{ height: 550, width: 100, overflow: 'auto', maxHeight: '60vh', boxShadow: '0px 2px 10px 0px rgba(215,215,215,0.50)', marginRight: 12 }}>
                    {this.state.catList.map(cat => {
                        const active = Number(cat.id) === this.state.selectedCatId
                        return <div key={cat.id} className='flex-center cur-pointer' style={{
                            borderLeft: `6px solid ${active ? 'var(--theme-main)' : 'transparent'}`,
                            background: active ? '#FFEEEF' : '#fff',
                            fontSize: active ? 14 : 12,
                            color: active ? 'var(--theme-main)' : '#ccc',
                            height: 32,
                        }} onClick={() => { this.setState({ selectedCatId: Number(cat.id) }) }} >
                            {cat.name}
                        </div>
                    })}
                    <div className='flex-center cur-pointer' style={{
                        height: 32, color: 'var(--theme-main)',
                    }} onClick={() => {
                        editAddressCategory(this.props.user.self_sid, (list) => {
                            if (!list) return
                            this.setState({ catList: [{ id: 0, name: '全部' }, ...list] })
                            if (!list.find(i => Number(i.id) === Number(this.state.selectedCatId))) {
                                this.setState({ selectedCatId: 0 })
                            }
                        })
                    }} >
                        +管理分类
                    </div>
                </div>
                <div className='hiddenScrollBar' style={{ height: 550, flex: 1, overflow: 'auto', maxHeight: '60vh' }} >
                    {this.state.addAddress.editting ? <div className='edittuan__address__editpanel' style={{ marginBottom: 15 }}>
                        <div className='fbc edittuan__address__editpanel__input'>
                            <div className='edittuan__address__editpanel__input-name'>名称</div>
                            <div className='edittuan__address__editpanel__input-value'>
                                <input placeholder='请输入门店/自提点名称'
                                    value={this.state.addAddress.editname !== undefined ? this.state.addAddress.editname : this.state.addAddress.name} onChange={e => {
                                        const addAddress = this.state.addAddress
                                        addAddress.editname = e.target.value
                                        this.setState({ addAddress })
                                    }} />
                            </div>
                        </div>
                        <div className='fbc edittuan__address__editpanel__input'>
                            <div className='edittuan__address__editpanel__input-name'>地址</div>
                            <div className='edittuan__address__editpanel__input-value'>
                                <input placeholder='请输入门店/自提点地址'
                                    value={this.state.addAddress.editaddressdetail !== undefined ? this.state.addAddress.editaddressdetail : this.state.addAddress.address} onChange={e => {
                                        const addAddress = this.state.addAddress
                                        addAddress.editaddressdetail = e.target.value
                                        this.setState({ addAddress })
                                    }} />
                            </div>
                        </div>
                        <div className='fbc edittuan__address__editpanel__input'>
                            <div className='edittuan__address__editpanel__input-name'>联系人</div>
                            <div className='edittuan__address__editpanel__input-value'>
                                <input placeholder='自提点的联系人，选填'
                                    value={this.state.addAddress.editcontact !== undefined ? this.state.addAddress.editcontact : this.state.addAddress.contact} onChange={e => {
                                        const addAddress = this.state.addAddress
                                        addAddress.editcontact = e.target.value
                                        this.setState({ addAddress })
                                    }} />
                            </div>
                        </div>
                        <div className='fbc edittuan__address__editpanel__input'>
                            <div className='edittuan__address__editpanel__input-name'>联系电话</div>
                            <div className='edittuan__address__editpanel__input-value'>
                                <input placeholder='自提点的联系方式，选填' maxLength={15}
                                    value={this.state.addAddress.editmobile !== undefined ? this.state.addAddress.editmobile : this.state.addAddress.mobile} onChange={e => {
                                        const addAddress = this.state.addAddress
                                        addAddress.editmobile = e.target.value
                                        this.setState({ addAddress })
                                    }} />
                            </div>
                        </div>
                        <div className='edittuan__address__editpanel__ctrl'>
                            <BTN color='gray' onClick={() => {
                                this.setState({ addAddress: { ...this.state.addAddress, editting: false } })
                            }}>取消</BTN>
                            <BTN color='red' onClick={() => {
                                const data = {
                                    address: this.state.addAddress.editaddressdetail,
                                    addresslat: 0,
                                    addresslng: 0,
                                    addressname: '',
                                    contact: this.state.addAddress.editcontact,
                                    mobile: this.state.addAddress.editmobile,
                                    name: this.state.addAddress.editname,
                                    status: 1,
                                    sid: this.props.user.self_sid,
                                    types: 1,
                                }
                                post('/address/create', data).then(() => {
                                    this.getAddress()
                                    this.setState({ addAddress: { ...this.state.addAddress, editting: false } })
                                })
                            }} style={{ marginLeft: 12, }}>确定</BTN>
                        </div>
                    </div> : <div className='flex-between' style={{ marginBottom: '1em' }} >
                        <BTN color='red' onClick={() => {
                            this.setState({ addAddress: { ...this.state.addAddress, editting: true } })
                        }}>添加自提点</BTN>
                        <Search outerStyle={{ paddingTop: 4, paddingBottom: 4, }} search={this.state.searchKey} onSearch={() => { this.search() }} onChange={(e) => { this.setState({ searchKey: e }) }} placeholder='搜索自提点名称' />
                    </div>}
                    {this.state.addressList && this.state.addressList.map((ad: SelectAddress, index) => {
                        return (
                            <div className='edittuan__address' key={ad.id}>
                                <div className='edittuan__address-item fbc' onClick={() => {
                                    if (this.props.notSelect) return
                                    ad.selected = !ad.selected
                                    if (this.state.nowSelectAddress.includes(ad.id)) {
                                        const nsa = this.state
                                        nsa.nowSelectAddress = this.state.nowSelectAddress.filter((i: string) => i !== ad.id)
                                    } else {
                                        this.state.nowSelectAddress.push(ad.id)
                                    }
                                    this.setState({ addressList: this.state.addressList })
                                }}>
                                    {!this.props.notSelect && <div style={{ width: 20, }}>
                                        <i style={{ color: this.state.nowSelectAddress.includes(ad.id) ? 'var(--theme-main)' : '#999' }} className={`${this.state.nowSelectAddress.includes(ad.id) ? 'icon-gou' : 'icon-yuan'} iconfont`}></i>
                                    </div>}
                                    <div style={{ flex: 1 }} >
                                        <div className='fbc'>
                                            <div className='edittuan__address-title'>{ad.name}</div>
                                            <div className='edittuan__address-ctrl'>
                                                <BTN onClick={(e) => {
                                                    e.stopPropagation()
                                                    post('/address/update', {
                                                        status: 0,
                                                        types: 1,
                                                        id: ad.id
                                                    }).then(() => {
                                                        this.search()
                                                    })
                                                }}>删除</BTN>
                                                <BTN onClick={(e) => {
                                                    e.stopPropagation()
                                                    ad.editting = true
                                                    ad.editCate = Number(ad.cat_id)
                                                    this.setState({ addressList: this.state.addressList })
                                                }}>编辑</BTN>
                                            </div>
                                        </div>
                                        <div className='edittuan__address-detail'>{ad.address + ad.addressdetail}</div>
                                    </div>
                                </div>
                                {ad.editting ? <div className='edittuan__address__editpanel'>
                                    <div className='fbc edittuan__address__editpanel__input'>
                                        <div className='edittuan__address__editpanel__input-name'>名称</div>
                                        <div className='edittuan__address__editpanel__input-value'>
                                            <input placeholder='请输入门店/自提点名称' value={ad.editname !== undefined ? ad.editname : ad.name} onChange={e => {
                                                ad.editname = e.target.value
                                                this.setState({ addressList: this.state.addressList })
                                            }} />
                                        </div>
                                        <Select list={this.state.catList.filter(i => !!Number(i.id))} uni='cat' value={this.state.catList.filter(i => !!Number(i.id)).findIndex(i => Number(i.id) === Number(ad.editCate))} showKey='name' onChange={e => {
                                            ad.editCate = Number(e.id)
                                            this.setState({ addressList: this.state.addressList })
                                        }} />
                                    </div>
                                    <div className='fbc edittuan__address__editpanel__input'>
                                        <div className='edittuan__address__editpanel__input-name'>地址</div>
                                        <div className='edittuan__address__editpanel__input-value'>
                                            <input placeholder='请输入门店/自提点地址' value={ad.editaddressdetail !== undefined ? ad.editaddressdetail : (ad.address + ad.addressdetail)} onChange={e => {
                                                ad.editaddressdetail = e.target.value
                                                this.setState({ addressList: this.state.addressList })
                                            }} />
                                        </div>
                                    </div>
                                    <div className='fbc edittuan__address__editpanel__input'>
                                        <div className='edittuan__address__editpanel__input-name'>联系人</div>
                                        <div className='edittuan__address__editpanel__input-value'>
                                            <input placeholder='自提点的联系人，选填' value={ad.editcontact !== undefined ? ad.editcontact : ad.contact} onChange={e => {
                                                ad.editcontact = e.target.value
                                                this.setState({ addressList: this.state.addressList })
                                            }} />
                                        </div>
                                    </div>
                                    <div className='fbc edittuan__address__editpanel__input'>
                                        <div className='edittuan__address__editpanel__input-name'>联系电话</div>
                                        <div className='edittuan__address__editpanel__input-value'>
                                            <input placeholder='自提点的联系方式，选填' maxLength={15} value={ad.editmobile !== undefined ? ad.editmobile : ad.mobile} onChange={e => {
                                                ad.editmobile = e.target.value
                                                this.setState({ addressList: this.state.addressList })
                                            }} />
                                        </div>
                                    </div>
                                    <div className='edittuan__address__editpanel__ctrl'>
                                        <BTN color='gray' onClick={() => {
                                            ad.editname = ''
                                            ad.editaddressdetail = ''
                                            ad.editcontact = ''
                                            ad.editmobile = ''
                                            ad.editting = false
                                            this.setState({ addressList: this.state.addressList })
                                        }}>取消</BTN>
                                        <BTN color='red' onClick={() => {
                                            const data = {
                                                address: ad.editaddressdetail,
                                                addresslat: 0,
                                                addresslng: 0,
                                                addressname: '',
                                                contact: ad.editcontact,
                                                id: ad.id,
                                                mobile: ad.editmobile,
                                                name: ad.editname,
                                                status: 1,
                                                sid: this.props.user.self_sid,
                                                uid: this.props.user.self_uid,
                                                types: 1,
                                                cat_id: ad.editCate,
                                            }
                                            post('/address/update', data).then(() => {
                                                this.search()
                                            }).catch(e => message.error(e.message))
                                        }} style={{ marginLeft: 12 }}>确定</BTN>
                                    </div>
                                </div> : ''}
                            </div>
                        )
                    })}
                </div>
            </div>
        </Modal>
    }

    async getAddress() {
        const data: any = {
            offset: 0, limit: 100000, sid: window.btoa(this.props.user.self_sid),
            is_hssq: this.props.is_hssq || '',
        }
        if (this.props.isHema) {
            if (!this.props.branch || !this.props.branch.id) {
                message.error('请选择NB门店')
                return
            }
            data.mall_id = this.props.branch.id
        }
        // 获取我的自提点
        const { list } = await get('/address/getlist', data)
        this.setState({ addressList: list, loading: false })
        if (!this.props.selectedAddress || this.props.selectedAddress.length === 0) {
            this.setState({
                nowSelectAddress: list.map(i => i.id)
            })
        }
    }

    async getCat() {
        const res = await get('/address/getpickuppointcategorylist', {
            offset: 0, limit: 999, sid: btoa(this.props.user.self_sid),
            is_hssq: this.props.is_hssq || '',
        })
        this.setState({
            catList: [
                {
                    id: 0, name: '全部'
                },
                ...res.list,
            ]
        })
    }

    async search() {
        const data: any = {
            offset: 0, limit: 100000, sid: window.btoa(this.props.user.self_sid), search: this.state.searchKey, cat_id: this.state.selectedCatId || 0,
            is_hssq: this.props.is_hssq || '',
        }
        if (this.props.isHema) {
            if (!this.props.branch || !this.props.branch.id) {
                message.error('请选择NB门店')
                return
            }
            data.mall_id = this.props.branch.id
        }
        // 获取我的自提点
        const { list } = await get('/address/getlist', data)
        this.setState({ addressList: list, loading: false })
    }

    selectAll() {
        for (let i = 0; i < this.state.addressList.length; i++) {
            const address = this.state.addressList[i];
            this.state.nowSelectAddress.push(address.id)
            this.setState({
                nowSelectAddress: Array.from(new Set(this.state.nowSelectAddress)),
                isSelectAll: true,
            })
        }
    }

    cancelAll() {
        this.setState({
            nowSelectAddress: this.state.nowSelectAddress.filter(i => !this.state.addressList.find(j => Number(j.id) === Number(i))),
            isSelectAll: false,
        })
    }
}
const mapStateToProps = (state: any) => {
    return {
        user: state.login_user_data
    }
}
export default connect(mapStateToProps)(Address)