import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { get, post } from '../../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
// import Select from '../../total/Select_'
// import Switch from '../../total/Switch'
// import BTN from '../../../StyleComponents/Button'
import { confirm as _confirm } from '../../Method/Confirm';
import moment from 'moment'
import createEvent from './createEvent'


interface props {
    onClose: () => void
    data: {

    }
    listChange?: () => void
}

let wrapper: Element

const Ctrl = styled.div`
    span{
        padding: 0 8px;
        color: var(--theme-main);
        cursor: pointer;
    }
    span:not(:last-child){
        border-right: 1px #ccc solid;
    }
`
const Table = styled.table`
    border-radius: 8px;
    width: 100%;
    thead{
        background: #f5f5f5;
    }
    td{
        padding: 10px;
    }
    tr{
        border: 1px solid #f1f1f1;
    }
`
const Status = styled.span`
    display: inline-block;
    padding: 1px 4px;
    font-size: 12px;
    color: #fff;
    border-radius: 2px;
`

interface event {
    title: string,
    start: number,
    end: number,
    status: string | number
    id: string | number,
    status_msg: string,
}

export default async function (sid: number | string, mall_id: number) {
    return new Promise<void>(async (reslove, reject) => {
        function Category(props: props) {

            const [shouldClose, setShouldClose] = useState(false)

            const [list, setList] = useState<event[]>()

            const offset = useRef(0)

            useEffect(() => {
                if (!list) {
                    get('/mall/getshopmallactivitylist', {
                        offset: offset.current,
                        limit: 200000,
                    }).then(res => {
                        offset.current += res.length
                        setList(res.map(i => {
                            return {
                                ...i,
                                start: moment(i.start_time),
                                end: moment(i.end_time),
                            }
                        }))
                    })
                }
            }, [list])

            return <Modal title='管理活动' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <Table>
                        <thead>
                            <tr>
                                <td style={{ width: 170 }}>活动</td>
                                <td style={{ width: 130 }}>开始时间</td>
                                <td style={{ width: 130 }}>结束时间</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(list || []).map(i => {
                                return <React.Fragment key={i.id}>
                                    <tr key={i.id}>
                                        <td>
                                            <span style={{ marginRight: 10 }}>{i.title}</span>
                                            {(() => {
                                                if (Number(i.status) === 2) return <Status style={{ background: '#999' }}>已暂停</Status>
                                                const now = moment()
                                                if (now.isBefore(i.start)) return <Status style={{ background: '#FF9A27' }}>未开始</Status>
                                                if (now.isAfter(i.end)) return <Status style={{ background: '#999' }}>已结束</Status>
                                                return <Status style={{ background: 'var(--theme-main)' }}>进行中</Status>
                                            })()}
                                        </td>
                                        <td>{moment(i.start).format('M月D日 HH:mm')}</td>
                                        <td>{moment(i.end).format('M月D日 HH:mm')}</td>
                                        <td>
                                            <Ctrl>
                                                <span onClick={() => {
                                                    _confirm({
                                                        title: '操作确认',
                                                        text: '确定删除活动' + i.title + '吗'
                                                    }).then(res => {
                                                        if (res) {
                                                            return post('/mall/deletemallactivity', { activity_id: i.id }).then(res => {
                                                                message.success('删除成功')
                                                                offset.current = 0
                                                                setList(undefined)
                                                            })
                                                        }
                                                    }).catch(e => { if (e.message) message.error(e.message) })
                                                }}>删除</span>
                                                <span onClick={() => {
                                                    let href: string
                                                    if (Number(i.status) === 2) {
                                                        href = '/mall/startmallactivity'
                                                    }
                                                    if (Number(i.status) === 1) {
                                                        href = '/mall/pausemallactivity'
                                                    }
                                                    post(href, {
                                                        activity_id: i.id,
                                                    }).then(res => {
                                                        if (Number(i.status) === 2) {
                                                            i.status = 1
                                                        } else if (Number(i.status) === 1) i.status = 2
                                                        setList([...list])
                                                    }).catch(e => { if (e.message) message.error(e.message) })
                                                }}>{Number(i.status) === 1 ? '暂停' : ''}{Number(i.status) === 2 ? '开启' : ''}</span>
                                                {/* <span>查看</span> */}
                                                <span onClick={() => {
                                                    createEvent(mall_id, Number(i.id)).then(() => {
                                                        offset.current = 0
                                                        setList(undefined)
                                                    }).catch(e => { if (e.message) message.error(e.message) })
                                                }}>编辑</span>
                                            </Ctrl>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            })}
                        </tbody>
                    </Table>
                </div>
            </Modal>

            function confirm() {
                setShouldClose(true)
                reslove()
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{ sid }} onClose={() => {
            ReactDOM.render(null, wrapper)
            reject()
        }} />, wrapper)
    })
}