import React, { Component } from 'react'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types'
import Wx_sign from '../../high-component/Wx_sign'

import { select_good } from '../../reducers/index';

class SingleGood extends Component {

    // static propTypes = {
    //     // good
    // }

    constructor(p) {
        super()
        window.scroll(0, 0)
        this.state = {
            ...p.location.state,
            activeAttrID: p.location.state.data.attribute[0].id
        }

    }

    // componentDidMount(){
    //     
    // }

    render() {
        return (
            <div>
                <div style={{ width: '100vw', height: '100vw' }}>
                    <img alt={this.state.data.name} src={this.state.data.logo} style={{
                        width: '100%', height: '100%'
                    }} className='img-center' />
                </div>
                <div style={{ padding: 12 }}>
                    <h6>{this.state.data.name}</h6>
                    {this.state.data.attribute.map(i => {
                        if (i.id !== this.state.activeAttrID) return ''
                        return (
                            <div key={i.id}>
                                <div>
                                    <span style={{ fontSize: 12, color: '#878787', marginRight: 12 }}>库存{Number(i.isunlimited) === 1 ? '无限' : i.quantity}</span>
                                    <span style={{ fontSize: 12, color: '#878787' }}>已团{i.salesnum}</span>
                                </div>
                                <div className='fbc'>
                                    <span style={{ color: '#FF3E3E' }}>￥{((i.distribution_price ? i.distribution_price.nowprice : i.price) / 100).toFixed(2)}</span>
                                    <div className='fcc'>
                                        <i style={{ color: 'var(--theme-main)', fontSize: 20 }} className='iconfont icon-jianhao-tianchong' onClick={() => {
                                            for (let index = 0; index < this.state.num.length; index++) {
                                                const num = this.state.num[index];
                                                if (Number(num.id) === Number(i.id)) {
                                                    if (num.num <= 0) return
                                                    num.num--
                                                    this.setState({ num: this.state.num })
                                                    return
                                                }
                                            }
                                        }}></i>
                                        <span style={{ fontSize: 20, display: 'block', width: '2em', textAlign: 'center' }}>
                                            {(() => {
                                                for (let index = 0; index < this.state.num.length; index++) {
                                                    const num = this.state.num[index];
                                                    if (Number(num.id) === Number(i.id)) {
                                                        return num.num
                                                    }
                                                }
                                            })()}
                                        </span>
                                        <i style={{ color: 'var(--theme-main)', fontSize: 20 }} className='iconfont icon-jiahao-tianchong' onClick={() => {
                                            for (let index = 0; index < this.state.num.length; index++) {
                                                const num = this.state.num[index];
                                                if (Number(num.id) === Number(i.id)) {
                                                    num.num++
                                                    this.setState({ num: this.state.num })
                                                    return
                                                }
                                            }
                                        }}></i>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div style={{ color: '#343434', fontSize: 15, marginBottom: 8, fontFamily: 'PingFangSC-Regular,PingFang SC' }}>规格</div>
                    <div className='fbc' style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {this.state.data.attribute.map(i => {
                            const style = {
                                fontSize: 12,
                                background: '#F7F8F9',
                                color: '#212121',
                                position: 'relative',
                                borderRadius: 5,
                                padding: '4px 11px',
                                marginRight: 12, marginBottom: 12,
                                border: '1px solid transparent'
                            }
                            if (i.id === this.state.activeAttrID) {
                                style.border = '1px solid var(--theme-main)'
                                style.color = 'var(--theme-main)'
                                style.background = 'rgba(255,246,246,1)'
                            }

                            return (
                                <div key={i.id} className='fcc' style={style} onClick={() => {
                                    this.setState({
                                        activeAttrID: i.id
                                    })
                                }}>
                                    {i.unit}
                                    {(() => {
                                        for (let index = 0; index < this.state.num.length; index++) {
                                            const num = this.state.num[index];
                                            if (Number(num.id) === Number(i.id)) {
                                                if (num.num === 0) return ''
                                                return (
                                                    <div className='fcc' style={{
                                                        position: 'absolute', right: '-.5em', top: '-.5em', borderRadius: 9, background: '#FF9928',
                                                        color: 'white', fontSize: 8, lineHeight: 1, minWidth: 18, height: 18, padding: 4
                                                    }}>
                                                        {num.num}
                                                    </div>
                                                )
                                            }
                                        }
                                    })()}
                                </div>
                            )
                        })}
                        <div style={{ flex: 1 }}></div>
                    </div>
                    {(() => {
                        if (this.state.data.content !== '') {
                            return (
                                <div style={{ background: '#F7F8F9', borderRadius: 5, padding: 15, marginBottom: 15 }}>
                                    <div style={{ color: 'var(--theme-main)', textAlign: 'center', fontSize: 12, marginBottom: 12 }}>商品亮点推荐</div>
                                    <div style={{ whiteSpace: 'pre-line', width: '100%', wordBreak: 'break-word', fontSize: 10 }}>
                                        {this.state.data.content}
                                    </div>
                                </div>
                            )
                        }
                    })()}
                    {this.state.data.images.map(i => {
                        return (
                            <div key={i.id} >
                                <img style={{ width: '100%', marginBottom: 20 }} alt={this.state.data.name} src={i.src} />
                            </div>
                        )
                    })}
                </div>
                <div style={{ position: 'fixed', bottom: 0, left: 0, height: '25vw', width: '100vw', background: 'rgba(245,245,245,1)', padding: '0 12px 0px' }} className='fbc'>
                    <div className='fcc' style={{
                        height: 50, lineHeight: 1, padding: '1em', fontSize: 18, borderRadius: 25, color: '#333',
                        background: '#fff', marginRight: 20, minWidth: '5em',
                    }} onClick={() => {
                        this.props.history.goBack()
                    }}>取消</div>
                    <div className='fcc' style={{
                        height: 50, lineHeight: 1, padding: '1em', fontSize: 18, borderRadius: 25, color: '#fff',
                        background: 'linear-gradient(90deg,rgba(248,93,50,1) 0%,rgba(249,50,71,1) 100%)', flex: 1,
                    }} onClick={() => {
                        this.props.setGood({
                            goodsid: this.state.data.goodsid,
                            num: this.state.num
                        })
                        this.props.history.goBack()
                    }}>加入购物车
                            <span style={{ fontSize: 12, lineHeight: 1, borderRadius: '0.6em', background: '#fff', height: '1.2em', minWidth: '1.2em', padding: 4, color: '#FF9A27', marginLeft: 8 }} className='fcc'>{this.state.num.reduce(((arr, cur) => {
                        return cur.num + arr
                    }), 0)}</span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setGood: (data) => {
            dispatch(select_good(data))
        }
    }
}

export default Wx_sign((connect(mapStateToProps, mapDispatchToProps)(SingleGood)))