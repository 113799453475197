import React, { useEffect, useState, } from 'react';
import styled from 'styled-components';

interface props {
    value: number
    divide?: number
}

const Bar = styled.div`
    position: relative;
    height: 4px;
    background-color: #ededed;
    div{
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
    }
`

export default function (props: props) {
    const [divide, setDivide] = useState<number>()
    useEffect(() => {
        setDivide(props.divide || 80)
    }, [props.divide])
    return <Bar>
        <div style={{
            width: props.value + '%',
            background: props.value > divide ? 'var(--theme-main)' : 'linear-gradient(140deg,#96afff 14%, #48a6ed 87%)',
            maxWidth: '100%',
        }}></div>
    </Bar>
}