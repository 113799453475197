import { message } from "antd";
import React, { Component } from "react";
import { get } from "../../../netApi/fetch";
import Modal from "../../total/Modal";
import { SolidRed } from "../../../StyleComponents/Button";
import Search from "../../total/Input/Search";
import { confirm } from "../../Method/Confirm";
import { connect } from "react-redux";

class SelectGood extends Component {
  constructor(p) {
    super();
    this.state = {
      goodslist: [],
      categorylist: [],
      branchList: [],
      selectedCate: 0,
      canNotCancel: new Set(),
      searchInput: "",
    };
  }

  search = "";

  savedGoodList = [];

  componentDidMount() {
    if (this.props.cannotcancel) {
      for (let i = 0; i < this.props.cannotcancel.length; i++) {
        const item = this.props.cannotcancel[i];
        this.state.canNotCancel.add(Number(item.goodsid));
      }
      this.setState({ canNotCancel: this.state.canNotCancel });
    }
    this.getPropsToState();
    this.getGoodslist();
    this.getCatList();
  }

  componentDidUpdate(p, s) {
    this.getPropsToState();
    if (Number(s.selectedCate) !== this.state.selectedCate) {
      this.offset = 0;
      this.setState({
        goodslist: [],
      });
      this.getGoodslist();
    }
  }
  render() {
    return (
      <Modal
        title={
          <div className="flex-center">
            <span style={{ marginRight: 12 }}>添加商品</span>
            <SolidRed
              onClick={() => {
                this.offset = 0;
                this.setState(
                  {
                    goodslist: [],
                  },
                  () => {}
                );
                this.getGoodslist();
              }}
            >
              刷新最新商品
            </SolidRed>
            <Search
              outerStyle={{ padding: "4px 1em", marginLeft: 8 }}
              placeholder="搜索商品名称"
              search={this.state.searchInput}
              onChange={(e) => {
                this.setState({ searchInput: e });
              }}
              onSearch={() => {
                this.search = this.state.searchInput;
                this.offset = 0;
                this.setState({
                  goodslist: [],
                });
                this.getGoodslist();
              }}
            />
          </div>
        }
        style={{ content: { paddingLeft: 0, maxHeight: "70vh" } }}
        confirm={() => {
          const newgood = [];
          if (!this.state.selectedGoodsid) {
            this.props.close();
            return;
          }
          for (let i = 0; i < this.state.selectedGoodsid.length; i++) {
            const selectgood = this.state.selectedGoodsid[i];
            let prev = false;
            for (let j = 0; j < this.props.selectedGoodsid.length; j++) {
              const prevSelected = this.props.selectedGoodsid[j];
              if (Number(prevSelected) === Number(selectgood)) {
                prev = true;
                break;
              }
            }
            if (!prev) {
              for (let k = 0; k < this.savedGoodList.length; k++) {
                const good = this.savedGoodList[k];
                if (Number(good.goodsid) === Number(selectgood)) {
                  newgood.push(good);
                  break;
                }
              }
            }
          }
          this.props.confirm(newgood, this.state.selectedGoodsid);
          this.props.close();
        }}
        close={() => {
          this.props.close();
        }}
      >
        <div className="top"></div>
        <div className="fcc" style={{ alignItems: "flex-start" }}>
          <div
            className="left hiddenScrollBar"
            style={{
              overflowY: "auto",
              height: "100%",
              maxHeight: "65vh",
              width: "7em",
            }}
          >
            <div
              onClick={() => {
                this.setState({ selectedCate: 0 });
              }}
              style={{
                padding: ".6em .3em",
                fontSize: 12,
                textAlign: "center",
                color: this.state.selectedCate === 0 ? "#FD5356" : "#A3A3A3",
                borderLeft:
                  this.state.selectedCate === 0
                    ? "6px solid var(--theme-main)"
                    : "6px solid transparent",
                background:
                  this.state.selectedCate === 0 ? "#FFEEEF" : "transparent",
                cursor: "pointer",
              }}
            >
              全部好物
            </div>
            {this.state.categorylist.map((i) => {
              return (
                <div
                  onClick={() => {
                    this.setState({ selectedCate: Number(i.id) });
                  }}
                  key={i.id}
                  style={{
                    padding: ".6em .3em",
                    fontSize: 12,
                    textAlign: "center",
                    color:
                      this.state.selectedCate === Number(i.id)
                        ? "#FD5356"
                        : "#A3A3A3",
                    borderLeft:
                      this.state.selectedCate === Number(i.id)
                        ? "6px solid var(--theme-main)"
                        : "6px solid transparent",
                    background:
                      this.state.selectedCate === Number(i.id)
                        ? "#FFEEEF"
                        : "transparent",
                    cursor: "pointer",
                  }}
                >
                  {i.name}
                </div>
              );
            })}
          </div>
          <div
            className="right hiddenScrollBar"
            onScroll={(e) => {
              const { scrollHeight, scrollTop, clientHeight } = e.target;
              const bottom = scrollHeight - scrollTop - clientHeight;
              if (bottom < scrollHeight / 2) {
                this.getGoodslist();
              }
            }}
            style={{
              overflowY: "auto",
              height: "100%",
              maxHeight: "65vh",
              width: 640,
              paddingLeft: 16,
            }}
          >
            {this.state.goodslist.map((i) => {
              // if (Number(i.catid) !== this.state.selectedCate && this.state.selectedCate !== 0) return ''
              return (
                <div
                  key={i.goodsid}
                  style={{
                    padding: 16,
                    display: "flex",
                    position: "relative",
                    border: "1px solid #cecece",
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    if (!i.logo) {
                      confirm({
                        title: "温馨提示",
                        text: "该商品未添加图片，请先添加商品图片",
                        confirmText: "打开编辑页面",
                      }).then((res) => {
                        if (res)
                          window.open("/good/edit/" + window.btoa(i.goodsid));
                      });
                      return;
                    }
                    if (this.state.selectedGoodsid.includes(i.goodsid)) {
                      this.setState({
                        selectedGoodsid: this.state.selectedGoodsid.filter(
                          (id) => id !== i.goodsid
                        ),
                      });
                    } else {
                      this.setState({
                        selectedGoodsid: [
                          ...this.state.selectedGoodsid,
                          i.goodsid,
                        ],
                      });
                    }
                  }}
                >
                  <img
                    alt=""
                    src={i.logo}
                    style={{ width: 90, height: 90, marginRight: 20 }}
                    className="img-center"
                  ></img>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        paddingRight: 30,
                        color: "#212121",
                        fontSize: 16,
                        fontWeight: 700,
                        wordBreak: "break-all",
                      }}
                    >
                      {i.name}
                      <SolidRed
                        style={{
                          fontSize: 12,
                          padding: "2px 3px",
                          marginLeft: 12,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `/good/edit/${btoa(i.goodsid)}`,
                            "_blank"
                          );
                        }}
                      >
                        编辑商品
                      </SolidRed>
                    </div>
                    {i.attribute.map((attr) => {
                      return (
                        <div
                          key={attr.id}
                          style={{
                            paddingRight: 30,
                            color: "#8b8b8b",
                            fontSize: 12,
                          }}
                        >
                          <div>
                            {`规格：${attr.unit} 库存：${
                              Number(attr.quantity) > 0
                                ? attr.quantity
                                : Number(attr.isunlimited) === 1
                                ? "无限"
                                : attr.quantity
                            } 已售：${attr.salesnum}`}
                          </div>
                          {this.props.isHema && (
                            <div>{`商城库存：${Number(
                              attr.mall_quantity
                            )} 商城价格：${(
                              Number(attr.mall_price) / 100
                            ).toFixed(2)}`}</div>
                          )}
                          {this.props.isHema && Number(attr.start_with) > 1 && (
                            <div
                              style={{ color: "#212121" }}
                            >{`起购量：${Number(attr.start_with)}`}</div>
                          )}
                        </div>
                      );
                    })}
                    <div style={{ color: "var(--theme-main)", marginTop: 12 }}>
                      {(() => {
                        const max = Math.max(
                          ...i.attribute.map((i) => i.price)
                        );
                        const min = Math.min(
                          ...i.attribute.map((i) => i.price)
                        );
                        if (max === min) {
                          return <span>￥{(min / 100).toFixed(2)}</span>;
                        } else {
                          return (
                            <span>
                              ￥{(min / 100).toFixed(2)}~￥
                              {(max / 100).toFixed(2)}
                            </span>
                          );
                        }
                      })()}
                    </div>
                  </div>
                  <div style={{ position: "absolute", top: 20, right: 20 }}>
                    {(() => {
                      if (!this.state.selectedGoodsid)
                        return (
                          <i
                            className="iconfont icon-yuan"
                            style={{ color: "#999", fontSize: 20 }}
                          ></i>
                        );
                      if (this.state.selectedGoodsid.includes(i.goodsid)) {
                        return (
                          <i
                            className="iconfont icon-gou"
                            style={{ color: "var(--theme-main)", fontSize: 20 }}
                          ></i>
                        );
                      } else {
                        return (
                          <i
                            className="iconfont icon-yuan"
                            style={{ color: "#999", fontSize: 20 }}
                          ></i>
                        );
                      }
                    })()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }
  getPropsToState() {
    if (
      !this.state.selectedGoodsid &&
      this.props.selectedGoodsid &&
      this.state.goodslist.length > 0
    ) {
      this.setState({
        selectedGoodsid: this.props.selectedGoodsid,
      });
    }
  }
  offset = 0;
  getting = false;
  getGoodslist() {
    if (this.props.isHema) {
      if (!this.props.selectedHemaNB) return;
    }
    if (this.getting) return;
    this.getting = true;
    const data = {
      offset: this.offset,
      limit: 10,
      op: "display",
      catid: this.state.selectedCate,
      search: this.search || "",
    };
    if (this.props.isHema)
      data.distribution_relation_id =
        this.props.selectedHemaNB.distribution_relation_id;
    get("/goods/getlistbysid", data)
      .then((res) => {
        this.getting = false;
        this.offset += res.list.length;
        this.setState({
          goodslist: [...(this.state.goodslist || []), ...res.list],
        });
        this.savedGoodList = [...this.savedGoodList, ...res.list];
        if (this.search !== this.state.searchInput) {
          this.setState({
            searchInput: this.search,
          });
        }
      })
      .catch((e) => message.error(e));
  }

  getCatList() {
    get("/goodscategory/getall").then((res) => {
      this.setState({
        categorylist: res,
      });
    });
  }
}

export default connect((state) => {
  return {
    user: state.login_user_data,
  };
})(SelectGood);
