import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { TOPBAR_HEIGHT } from '../../../data/style';
import { saveMallInfo } from '../../../reducers';
import BTN from '../../../StyleComponents/Button';
import Footer from '../../total/footer';
import MallSetting from '../method/MallSetting';

interface Props extends RouteComponentProps {
    user: user
    saveMallInfo: (data: false) => void
}


function C(props: Props) {
    console.log(props);
    if (Number(props.user.bind_mall_id)) {
        // props.history.replace('/index/mall')
    }
    return <div className='flex-between' style={{ flexDirection: 'column', minHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)`, background: '#fff' }} >
        <div className='flex-box' style={{ padding: '120px 170px', width: '100%' }}>
            <img alt='' style={{ height: 430, marginRight: 120 }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/fmPvHi2vh2.png' />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img alt='' style={{ width: 43, marginBottom: 16 }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/iWAa13aAaZ.png' />
                <h2 style={{ marginBottom: 27, }}>接力GO商城</h2>
                <div style={{ textAlign: 'center', marginBottom: 30 }} >
                    <div style={{ marginBottom: 10 }}>· 商城模式，自由切换</div>
                    <div style={{ marginBottom: 10 }}>· 更多商品，展示丰富</div>
                    <div>· 同城、快递、自提，灵活设置</div>
                </div>
                <BTN color='#FB503A' solid big onClick={() => {
                    MallSetting({ create: true, user: props.user }).then(res => {
                        props.saveMallInfo(undefined)
                        props.history.push('/index')
                    }).catch()
                }}  >
                    <i className='icon-wodehuodongjibangmai iconfont' style={{ marginRight: 11 }} />
                    <span>发布商城</span>
                </BTN>
            </div>
        </div>
        <div>
            <Footer />
        </div>
    </div>
}

const mapStateToProps = (state,) => {
    return {
        user: state.login_user_data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(C)
