import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { get, post } from '../../../netApi/fetch'
import styled from 'styled-components'
import { Checkbox, message } from 'antd'
import Select from '../../total/Select_'
import Switch from '../../total/Switch'
import DraggableImgs from '../../total/DraggableImgs_'
import { HR2SR } from '../../../StyleComponents/Button'
import Textarea from '../../total/Input/Textarea'
import Toast from '../../Method/Toast'

interface props {
    onClose: () => void
    goodsid: number | string
}

const Border = styled.div`
    border: 1px solid #d9d9d9;
    padding: 16px;
    border-radius: 4;
    &:not(:last-child){
        margin-bottom: 16px;
    }
    & > div{
        display: flex;
        align-items: center;
        &:not(:last-child){
            margin-bottom: 16px;
        }
    }
    input{
        border: 1px solid #ebebeb;
        padding: 8px 11px;
        flex: 1;
        width: 150px;
    }
    .data-name{
        font-size: 14px;
        font-family: Helvetica;
        text-align: left;
        color: #353535;
        width: 75px;
    }
`

let wrapper: Element

interface attr {
    bar_code: string
    goodsattrid: string
    price: string
    unit: string
    weight: string
    original_price: string
    stock_num: string
    showMore?: boolean
}


export default function (goodsid?: number | string,) {
    return new Promise(async (reslove, reject) => {


        function Mall(props: props) {
            const images = useRef<any[]>([])
            const [name, setName] = useState<string>('')
            const [attr, setAttr] = useState<attr[]>([])
            useEffect(() => {
                // attr为空时，添加默认attr
                if (!attr || attr.length === 0) {
                    setAttr([{
                        bar_code: '',
                        goodsattrid: '',
                        price: '',
                        unit: '',
                        weight: '0',
                        original_price: '',
                        stock_num: ''
                    }])
                }
            }, [attr])

            // 分类
            const [catlist, setCatlist] = useState<any[]>()
            const [catIndex, setCat] = useState<number>()
            const [catid, setCatid] = useState<any>()
            const [subCatid, setSubCatid] = useState<any>()
            useEffect(() => {
                if (!catlist) {
                    get('/goodscategory/getall', {}).then(res => {
                        setCatlist([{ name: '全部分类' }, ...res])
                    })
                }
            }, [catlist])


            // 帮卖
            const [isDistribution, toggleDistribution] = useState(false)
            const [distributionPercent, setPercent] = useState<string>('')
            const [commsion, setCommsion] = useState<string>('')
            useEffect(() => {
                if (!isDistribution) return
                const percent = Number(distributionPercent)
                if (isNaN(percent)) return
                if (percent > 100) {
                    setPercent('99')
                }
                const price = attr.map(i => Number(i.price))
                if (price.find(i => isNaN(i))) return
                const max = Math.max(...price)
                const min = Math.min(...price)
                if (max === min) {
                    setCommsion(`￥${((max * percent) / 100).toFixed(2)}`)
                } else {
                    setCommsion(`￥${((min * percent) / 100).toFixed(2)}~￥${((max * percent) / 100).toFixed(2)}`)
                }
            }, [distributionPercent, isDistribution, attr])

            // 配送方式
            const [delivery, setDelivery] = useState<Set<1 | 2 | 3>>(new Set())
            const [canRider, setCanRider] = useState(false)

            // 商品编码
            const [goods_no, setgoods_no] = useState('')

            // 商品描述
            const [content, setContent] = useState('')

            // 分店
            const [branchList, setBranchList] = useState<any[]>([])

            const datasource = useRef<any>()

            // 获取数据
            useEffect(() => {
                if (!props.goodsid) return
                get('/mall/mallgoodsview', { from: 'update', mall_goodsid: goodsid }).then(res => {
                    datasource.current = res
                    images.current = res.image_list.map((i, idx) => {
                        return {
                            src: i.src,
                            rank: idx,
                            id: i.id,
                        }
                    })
                    setContent(res.content)
                    setName(res.name)
                    setAttr(res.sku_list.map(i => {
                        return {
                            goodsattrid: i.goodsattrid,
                            original_price: (Number(i.original_price) / 100).toFixed(2),
                            price: (Number(i.price) / 100).toFixed(2),
                            stock_num: i.stock_num,
                            unit: i.unit,
                            weight: i.weight,
                            bar_code: i.bar_code,
                        }
                    }))
                    toggleDistribution(!!Number(res.is_distribution))
                    setPercent(res.total_commission_percent)
                    setDelivery(new Set(res.isselect_shipping_mode.map(i => Number(i))))
                    setCanRider(!!Boolean(res.has_rider_shipping_mode))
                    setBranchList((res.branch_group_list || []).reduce((p, group) => {
                        let list = []
                        list.push({
                            value: false,
                            name: group.group_name,
                            group,
                        })
                        for (let i = 0; i < group.branch_list.length; i++) {
                            const branch = group.branch_list[i];
                            const item = {
                                value: false,
                                name: branch.branch_name,
                                branch,
                                select_sub_item: 1
                            }
                            if ((res.on_sale_branch_mall_ids || []).find(i => Number(i) === Number(branch.mall_id))) {
                                item.value = true
                            }
                            list.push(item)
                        }
                        return [...p, ...list]
                    }, []))
                    setgoods_no(res.goods_no)
                    setCatid(res.category_ids[0])
                })
            }, [props.goodsid])

            useEffect(() => {
                if (catid && catlist) {
                    const index = catlist.findIndex(i => Number(i.id) === Number(catid))
                    if (index >= 0) {
                        setCat(index)
                    } else {
                        const _index = catlist.findIndex(i => i.children?.find(j => Number(j.id) === Number(catid)))
                        if (_index >= 0) {
                            setCat(_index)
                            setSubCatid(catid)
                        }
                    }
                }
            }, [catid, catlist])

            const [isHema, setIsHema] = useState(false)
            useEffect(() => {
                get('/shop/getsid', {}).then(res => {
                    console.log(res);
                    setIsHema(res.is_hema)
                })
            }, [])

            return <Modal title={!props.goodsid ? '上架商品' : '编辑商品'} onClose={props.onClose} onConfirm={() => {
                if (!props.goodsid) {
                    create()
                } else {
                    update()
                }
            }} style={{
                content: {
                    width: 640, height: 400, overflowY: 'overlay',
                }
            }}>
                <div style={{ padding: '16px 16px 0', background: '#f5f5f5', marginBottom: 16 }}>
                    <DraggableImgs disabled={isHema} imgtype={props.goodsid ? 'goods' : 'warehouse_goods'} images={images.current} width={72} height={72} onChange={e => {
                        images.current = e
                    }} loadingStyle={{ left: -16, top: -16, width: 'calc(100% + 32px)', height: 'calc(100% + 16px)', }} />
                </div>
                <Border>
                    <div>
                        <div className='data-name'>商品名称</div>
                        <input disabled={isHema} value={name} onChange={e => setName(e.target.value)} placeholder='请输入商品名称' />
                    </div>
                </Border>
                {isHema && <div style={{ marginBottom: 16, color: '#ff5060', fontSize: 12, }}>
                    *接力GO后台不允许修改商品信息，仅限盒马后台修改
                </div>}
                {attr.map((i, index) => {
                    return <Border key={index} >
                        <div>
                            <div className='data-name'>规格{index + 1}</div>
                            <div style={{ marginRight: 70 }}>
                                <input disabled={isHema} type="text" placeholder='如件/瓶/箱/个/kg等' value={i.unit} onChange={e => {
                                    i.unit = e.target.value
                                    setAttr([...attr])
                                }} />
                            </div>
                            <div className='data-name'>售价</div>
                            <div>
                                <input disabled={isHema} type='number' placeholder='' value={i.price} onChange={e => {
                                    i.price = e.target.value
                                    setAttr([...attr])
                                }} />
                            </div>
                            <div style={{ marginLeft: 16 }}>元</div>
                        </div>
                        <div>
                            <div className='data-name'>库存</div>
                            <div>
                                <input disabled={isHema} type='number' placeholder='不填则不限制数量' value={i.stock_num} onChange={e => {
                                    i.stock_num = e.target.value
                                    setAttr([...attr])
                                }} />
                            </div>
                            <div style={{ marginLeft: 16 }}>件</div>
                        </div>
                        {i.showMore && <>
                            <div>
                                <div className='data-name'>商品条码</div>
                                <div style={{ marginRight: 70 }}>
                                    <input disabled={isHema} type="text" placeholder='请输入商品条码' value={i.bar_code} onChange={e => {
                                        i.bar_code = e.target.value
                                        setAttr([...attr])
                                    }} />
                                </div>
                                <div className='data-name'>重量</div>
                                <div>
                                    <input disabled={isHema} type='number' placeholder='请输入商品重量' value={i.weight} onChange={e => {
                                        i.weight = e.target.value
                                        setAttr([...attr])
                                    }} />
                                </div>
                                <div style={{ marginLeft: 16 }}>g</div>
                            </div>
                            <div>
                                <div className='data-name'>原价</div>
                                <div>
                                    <input disabled={isHema} type='number' placeholder='请输入原价' value={i.original_price} onChange={e => {
                                        i.original_price = e.target.value
                                        setAttr([...attr])
                                    }} />
                                </div>
                                <div style={{ marginLeft: 16 }}>元</div>
                            </div>
                        </>}
                        <div style={{ position: 'relative', justifyContent: 'space-between' }}>
                            {!i.showMore && <span onClick={() => {
                                i.showMore = true
                                setAttr([...attr])
                            }} className='cur-pointer' style={{ color: '#8b8b8b' }}>更多设置</span>}
                            {i.showMore && <span onClick={() => {
                                i.showMore = false
                                setAttr([...attr])
                            }} className='cur-pointer' style={{ color: '#8b8b8b' }}>收起</span>}
                            {index === (attr.length - 1) && <HR2SR onClick={add_attr} style={{
                                position: 'absolute', left: 'calc(50% - 46px)'
                            }} >+ 添加规格</HR2SR>}
                            {attr.length > 1 && <span className='cur-pointer' style={{ color: 'var(--theme-main)' }} onClick={() => {
                                if (isHema) {
                                    message.warning('')
                                    return
                                }
                                setAttr(attr.filter((_, idx) => idx !== index))
                            }}>删除</span>}
                        </div>
                    </Border>
                })}

                <Border>
                    <div>
                        <div style={{ marginRight: 6 }} className='data-name'>商品分类</div>
                        <div style={{ marginRight: 6 }}>
                            <Select list={catlist || []} placeholder='请选择分类' showKey={'name'} uni='cat' maxHeight={300} value={catIndex} onChange={(_, i) => {
                                setCat(i)
                            }} />
                        </div>
                        <div style={{ marginRight: 6 }}>
                            {(catlist && catlist[catIndex] && catlist[catIndex].children)
                                ? <Select list={catlist[catIndex].children} placeholder='请选择二级分类' showKey={'name'} uni='cat' maxHeight={300} value={catlist[catIndex].children.findIndex(i => Number(i.id) === Number(subCatid))} onChange={(_, i) => {
                                    setSubCatid(_.id)
                                }} />
                                : null}
                        </div>

                    </div>
                </Border>

                {props.goodsid && <Border>
                    <div>
                        <div className='data-name'>同步到门店</div>
                        <Select placeholder='请选择门店' maxHeight={200} showString={(() => {
                            const count = branchList.filter(i => i.branch && i.value).length
                            return count ? `已选择${count}个门店` : undefined
                        })()} list={branchList} showKey='name' uni='branch' value={(() => {
                            const list = []
                            for (let i = 0; i < branchList.length; i++) {
                                const b = branchList[i];
                                if (b.value) list.push(i)
                            }
                            return list
                        })()} multi onChange={branchHandle} />
                    </div>
                </Border>}

                <Border>
                    <div>
                        <div className='data-name' style={{ width: `7em` }} >让好友帮我卖</div>
                        <Switch onClick={() => { toggleDistribution(!isDistribution) }} checked={isDistribution} />
                    </div>
                    {isDistribution && <>
                        <div style={{ display: 'block', fontSize: 12, color: '#8b8b8b' }}>好友转发的活动商品将按照<span style={{ color: 'var(--theme-main)' }} >统一的价格</span>对外销售，你可以在下方设置佣金，奖励帮你帮卖的好友。</div>
                        <div>
                            <div className='data-name'>佣金比例</div>
                            <div>
                                <input value={distributionPercent} placeholder='' type='number' max='100' onChange={e => {
                                    setPercent(e.target.value)
                                }} />
                                <span style={{ position: 'relative', left: -40 }}>%</span>
                            </div>
                            <div style={{ marginLeft: 16 }}>佣金金额：{commsion}</div>
                        </div>
                    </>}
                </Border>

                <Border>
                    <div>
                        <div className='data-name' style={{ width: `7em` }} >选择配送方式</div>
                    </div>
                    <div>
                        <div className='cur-pointer' style={{ marginRight: 40 }} onClick={() => { checkDelivery(1) }}>
                            <Checkbox style={{ marginRight: 16 }} checked={(delivery).has(1)} disabled={isHema} />
                            <span>快递配送上门</span>
                        </div>
                        <div className='cur-pointer' style={{ marginRight: 40 }} onClick={() => { checkDelivery(2) }}>
                            <Checkbox style={{ marginRight: 16 }} checked={(delivery).has(2)} disabled={isHema} />
                            <span>门店自提</span>
                        </div>
                        {canRider && <div className='cur-pointer' style={{ marginRight: 40 }} onClick={() => { checkDelivery(3) }}>
                            <Checkbox style={{ marginRight: 16 }} checked={(delivery).has(3)} disabled={isHema} />
                            <span>骑手配送</span>
                        </div>}
                    </div>
                </Border>

                <Border>
                    <div>
                        <div className='data-name'>商品编码</div>
                        <input value={goods_no} disabled={isHema} placeholder='请输入商品编码' onChange={e => {
                            setgoods_no(e.target.value)
                        }} />
                        <div style={{ color: '#8b8b8b' }} >（每个商品对应唯一的商品编码）</div>
                    </div>
                </Border>

                <Border>
                    <div>商品描述</div>
                    <Textarea disable={isHema} placeholder='选填，对商品进行补充描述' onChange={setContent} value={content} />
                </Border>

            </Modal>

            function checkDelivery(type: 1 | 2 | 3) {
                if (delivery.has(type)) delivery.delete(type)
                else delivery.add(type)
                setDelivery(new Set(delivery))
            }

            function add_attr() {
                if (isHema) {
                    message.warning('')
                    return
                }
                setAttr([...attr, {
                    bar_code: '',
                    goodsattrid: '',
                    price: '',
                    unit: '',
                    weight: '0',
                    original_price: '',
                    stock_num: '',
                }])
            }

            function branchHandle(e) {
                let list = []
                if (e.group) {
                    if (e.group.branch_group_id === 'all') {
                        list = branchList.map(i => { return { ...i, value: !e.value } })
                        setBranchList(list)
                        return
                    }
                    list = branchList.map(i => {
                        const data = { ...i }
                        if (data.group && String(data.group.branch_group_id) === String(e.group.branch_group_id)) data.value = !e.value
                        if (data.branch && String(data.branch.branch_group_id) === String(e.group.branch_group_id)) data.value = !e.value
                        return data
                    })
                }
                if (e.branch) {
                    list = branchList.map(i => {
                        const data = { ...i }
                        if (data.branch && data.branch.mall_id === e.branch.mall_id) data.value = !e.value
                        return data
                    })
                }

                function checkAll() {
                    list.find(i => i.group && i.group.branch_group_id === 'all').value = !list.find(i => {
                        if (i.group) return i.group.branch_group_id !== 'all' && !i.value
                        if (i.branch) return !i.value
                        return false
                    })
                }
                function checkGroup() {
                    let gidx
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i];
                        if (item.group) {
                            if (item.group.branch_group_id === 'all') continue
                            gidx = i // 保存当前group下标
                            item.value = true
                        }
                        if (item.branch) {
                            if (!item.value) list[gidx].value = false
                        }
                    }
                }

                checkGroup()
                checkAll()

                setBranchList(list)
            }


            function create() {
                const data: any = {}
                data.content = content
                data.name = name
                if (!name || name === '') {
                    message.warn('请输入商品名')
                    return
                }
                data.is_multi_spec = false
                data.category_ids = (() => {
                    if (subCatid) return [subCatid]
                    return catIndex ? [catlist[catIndex].id] : []
                })()
                let attr_warn = false
                data.goods_attrs = attr.map(i => {
                    if (!i.unit || i.unit === '' || i.price === '' || Number(i.price) <= 0) {
                        attr_warn = true
                    }
                    return {
                        unit: i.unit,
                        weight: i.weight,
                        bar_code: i.bar_code,
                        original_price: (Number(i.original_price) * 100).toFixed(0),
                        price: (Number(i.price) * 100).toFixed(0),
                        stock_num: i.stock_num,
                    }
                })
                if (attr_warn) {
                    message.warn('请完成规格信息填写')
                    return
                }
                data.image_ids = (images.current || []).map((i,) => {
                    return { id: i.id, rank: i.rank }
                })
                data.is_distribution = isDistribution
                if (isDistribution) {
                    data.total_commission_percent = distributionPercent
                    if (distributionPercent === '') {
                        message.warn('请填写佣金比例')
                        return
                    }
                }
                data.goods_shipping_list = []
                if (delivery.has(1)) {
                    data.goods_shipping_list.push({ shipping_mode: 1 })
                }
                if (delivery.has(2)) {
                    data.goods_shipping_list.push({ shipping_mode: 2 })
                }
                if (delivery.has(3)) {
                    data.goods_shipping_list.push({ shipping_mode: 3 })
                }
                if (data.goods_shipping_list.length < 1) {
                    message.warn('请选择配送方式')
                    return
                }
                data.goods_no = goods_no
                post('/mall/createwarehouseandgoods', data).then(() => {
                    reslove(true)
                    props.onClose()
                    if (wrapper) {
                        document.body.removeChild(wrapper)
                        wrapper = undefined
                        Toast.show('创建成功')
                    }
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            function update() {
                const data: any = {
                    op: 3,
                    mall_goods_id: props.goodsid,
                    goodsid: datasource.current.sku_list[0].goodsid
                }
                data.content = content
                data.name = name
                if (!name || name === '') {
                    message.warn('请输入商品名')
                    return
                }
                data.is_multi_spec = false

                // data.category_ids = (() => {
                //     return catIndex ? [catlist[catIndex].id] : []
                // })()
                data.catid = (() => {
                    if (subCatid) return [subCatid]
                    return catIndex ? [catlist[catIndex].id] : []
                })()

                let attr_warn = false
                data.goodsattrs = attr.map(i => {
                    if (!i.unit || i.unit === '' || i.price === '' || Number(i.price) <= 0) {
                        attr_warn = true
                    }
                    return {
                        id: i.goodsattrid || '',
                        unit: i.unit,
                        weight: i.weight,
                        bar_code: i.bar_code,
                        original_price: (Number(i.original_price) * 100).toFixed(0),
                        price: (Number(i.price) * 100).toFixed(0),
                        quantity: i.stock_num,
                    }
                })
                data.sku_list = attr.map(i => {
                    if (!i.unit || i.unit === '' || i.price === '' || Number(i.price) <= 0) {
                        attr_warn = true
                    }
                    return {
                        goodsattrid: i.goodsattrid || '',
                        unit: i.unit,
                        weight: i.weight,
                        bar_code: i.bar_code,
                        original_price: (Number(i.original_price) * 100).toFixed(0),
                        price: (Number(i.price) * 100).toFixed(0),
                        stock_num: i.stock_num,
                    }
                })
                if (attr_warn) {
                    message.warn('请完成规格信息填写')
                    return
                }
                data.imageids = (images.current || []).map((i,) => {
                    return { id: i.id, rank: i.rank }
                })
                data.is_distribution = isDistribution
                if (isDistribution) {
                    data.total_commission_percent = distributionPercent
                    if (distributionPercent === '') {
                        message.warn('请填写佣金比例')
                        return
                    }
                }
                data.distribution_group_commission_list = datasource.current.global_mall_distribution_commission_list
                data.distribution_group_commission_type = 2

                data.goods_shipping_list = []
                if (delivery.has(1)) {
                    data.goods_shipping_list.push({ shipping_mode: 1 })
                }
                if (delivery.has(2)) {
                    data.goods_shipping_list.push({ shipping_mode: 2 })
                }
                if (delivery.has(3)) {
                    data.goods_shipping_list.push({ shipping_mode: 3 })
                }
                if (data.goods_shipping_list.length < 1) {
                    message.warn('请选择配送方式')
                    return
                }
                data.goods_no = goods_no

                // 分店
                data.to_branch_mall_ids = branchList.filter(i => i.select_sub_item && i.value).map(i => {
                    return i.branch.mall_id
                })
                post('/mall/setmallgoods', data).then(() => {
                    reslove(true)
                    props.onClose()
                    if (wrapper) {
                        document.body.removeChild(wrapper)
                        wrapper = undefined
                        Toast.show('创建成功')
                    }
                }).catch(e => { if (e.message) message.error(e.message) })
            }
        }


        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Mall goodsid={goodsid} onClose={() => {
            ReactDOM.render(null, wrapper)
        }} />, wrapper)
    })
}