import React, { Component } from 'react';
import Select from '../../total/Select_.tsx';

import api from '../../../netApi/index';

export default class Control extends Component {

    options = {}

    constructor() {
        super()
        this.state = {
            keyWords: '',
            offerIds: '',
            priceStart: '',
            priceEnd: '',
            aliCategoryList: [],
            filter: [
                // {
                //     name: '免费赊账',
                //     value: '50000114',
                //     key: 'holidayTagId',
                //     checked: false,
                // },
                {
                    name: '7+天包换',
                    value: 'qtbh,swtbh',
                    key: 'buyerProtection',
                    checked: false,
                },
                // {
                //     name: '严选厂货'
                // },
                // {
                //     name: '厂货通'
                // },
                {
                    name: '进口货源',
                    value: '72514',
                    key: 'offerTags',
                    checked: false,
                },
                {
                    name: '渠道专享价商品',
                    value: '1387842',
                    key: 'offerTags',
                    checked: false,
                },
                {
                    name: '按销量排行',
                    value: 'saleQuantity',
                    key: 'sortType',
                    checked: false,
                },
                {
                    name: '是否升序',
                    value: false,
                    key: 'descendOrder',
                    checked: false,
                },
            ]
        }
    }

    componentDidMount() {
        api('alibaba', 'getcategory')({
            // categoryID: 124734004,
        }).then(res => {
            this.setState({
                aliCategoryList: res.data.data.categoryInfo[0].childCategorys
            })
        })
    }

    render() {
        return <div className='aliControl'>

            {/* 搜索框 */}
            <div className='aliControl__search'>
                <span>在线采购</span>
                <div className='fcc'>
                    <input style={{ marginRight: 8 }} placeholder='请输入搜索关键词' onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.onSearch(this.state.keyWords, this.state.offerIds)
                        }
                    }} type='search' value={this.state.keyWords} onChange={(e) => {
                        this.setState({
                            keyWords: e.target.value
                        })
                    }} />
                    <input style={{ marginRight: 8 }} placeholder='请输入id,多id用英文逗号分隔' onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.onSearchId(this.state.keyWords, this.state.offerIds)
                        }
                    }} type='search' value={this.state.offerIds} onChange={(e) => {
                        this.setState({
                            offerIds: e.target.value
                        })
                    }} />
                    <button className='cur-pointer' onClick={() => {
                        this.options = {}
                        this.setState({
                            timeType: -1, clear: 0, priceEnd: '', priceStart: '', filter: this.state.filter.map(i => {
                                return { ...i, checked: false }
                            })
                        })
                        this.props.onSearch(this.state.keyWords, this.state.offerIds)
                    }}>搜 索</button>
                </div>
                <span className='cur-pointer' onClick={() => {
                    this.options = {}
                    this.setState({
                        keyWords: '', timeType: -1, clear: 0, priceEnd: '', priceStart: '', filter: this.state.filter.map(i => {
                            return { ...i, checked: false }
                        })
                    })
                    this.props.clear()
                }}>清空筛选</span>
            </div>

            {/* 分类 */}
            <div className='aliControl__category'>
                {this.state.aliCategoryList.map(i => {
                    return <div key={i.id} className={(() => {
                        let cln = 'aliControl__category__item cur-pointer '
                        if (this.options.postCategoryId === i.id) {
                            cln += ' aliControl__category__item_active'
                        }
                        if (i.child && i.child.map(j => j.id).includes(this.options.postCategoryId)) {
                            cln += ' aliControl__category__item_active'
                        }
                        return cln
                    })()} onClick={e => {
                        e.stopPropagation()
                        this.selectCategory(i.id)
                    }} onMouseEnter={async () => {
                        i.closeMenu = false
                        setTimeout(async () => {
                            if (i.closeMenu) return
                            i.showMenu = true
                            if (!i.child) {
                                i.child = await this.getCategory(i.id)
                            }
                            this.setState({ aliCategoryList: this.state.aliCategoryList })
                        }, 100);
                    }} onMouseLeave={() => { i.closeMenu = true; i.showMenu = false; this.setState({ aliCategoryList: this.state.aliCategoryList }) }}>
                        <span>{i.name}</span>
                        {i.showMenu && i.child && <div className='aliControl__category__item__menu'>
                            {i.child.map(j => {
                                return <div key={j.id} onClick={e => {
                                    e.stopPropagation()
                                    this.selectCategory(j.id)
                                }} className={`aliControl__category__item cur-pointer ${this.options.postCategoryId === j.id ? 'aliControl__category__item_active' : ''}`} >
                                    {j.name}
                                </div>
                            })}
                        </div>}
                    </div>
                })}
            </div>

            {/* 条件筛选 */}
            <div>
                {/* tag筛选 */}
                <div style={{ display: 'flex', margin: '1rem 0' }}>
                    {this.state.filter.map((i, index) => {
                        return <label key={i.value} className='fcc' style={{ marginRight: '1rem' }}>
                            <input type='checkbox' checked={i.checked} onChange={e => {
                                const list = [...this.state.filter]
                                list[index].checked = !i.checked
                                this.setState({
                                    filter: list,
                                }, () => {
                                    if(i.key === 'offerTags'){
                                        console.log(this.options);
                                        this.options[i.key] = this.state.filter.filter(j => j.checked && (j.key === i.key)).map(j => j.value).join(',')
                                    }else {
                                        if (list[index].checked) {
                                            this.options[i.key] = i.value
                                        } else {
                                            delete this.options[i.key]
                                        }
                                    }
                                    this.props.onOptionsChange(this.options)
                                })
                            }} />
                            <span>{i.name}</span>
                        </label>
                    })}
                </div>
                {/* tag筛选 */}

                {/* 其他筛选 */}
                <div style={{ display: 'flex' }}>
                    {/* 价格筛选 */}
                    <div className='aliControl__price'>
                        <label>
                            <input type='digit' placeholder='最低价' value={this.state.priceStart} onChange={e => {
                                this.setState({ priceStart: e.target.value })
                            }} />
                            <span>￥</span>
                        </label>
                        <span>——</span>
                        <label>
                            <input type='digit' placeholder='最高价' value={this.state.priceEnd} onChange={e => {
                                this.setState({ priceEnd: e.target.value })
                            }} />
                            <span>￥</span>
                        </label>
                        <button className='cur-pointer' onClick={() => {
                            this.props.onPriceChange(this.state.priceStart, this.state.priceEnd)
                        }} >确定</button>
                    </div>

                    {/* 发货时间 */}
                    <div className='aliControl__deliveryTimeType'>
                        <Select uni='deliveryTimeType' list={[
                            { key: undefined, title: '选择发货时间' },
                            { key: '1', title: '24小时发货' },
                            { key: '2', title: '48小时发货' },
                            { key: '3', title: '72小时发货' },
                        ]} showKey='title' value={this.state.timeType} placeholder='选择发货时间' onChange={(e, idx) => {
                            this.options.deliveryTimeType = e.key
                            this.setState({ timeType: idx })
                        }} />
                        <button className='cur-pointer' onClick={() => {
                            this.props.onOptionsChange(this.options)
                        }} >确定</button>
                    </div>

                    {/* 地区筛选 */}

                    {/* 经营模式筛选 */}

                    {/* 发货保障 */}
                </div>
            </div>
        </div>
    }

    async getCategory(id) {
        const { data } = await api('alibaba', 'getcategory')({
            categoryID: id,
        })
        return data.data.categoryInfo[0].childCategorys
    }

    selectCategory(id) {
        this.options.postCategoryId = id
        this.props.onOptionsChange(this.options)
    }
}