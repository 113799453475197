import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Select extends Component {
    static propTypes = {
        list: PropTypes.array.isRequired,
        placeholder: PropTypes.string,
        showkey: PropTypes.string.isRequired, // 下拉框内展示值的key
        showValue: PropTypes.string.isRequired,  // input框内展示值的key
        change: PropTypes.func,
        maxHeight: PropTypes.number,
        hovorClass: PropTypes.string,
        initValue: PropTypes.number, // 默认值
        value: PropTypes.number, // 默认值
        uni: PropTypes.string.isRequired, // 标识符
        disabled: PropTypes.bool, // 禁用
    }
    constructor() {
        super()
        this.state = {
            value: ''
        }
        document.addEventListener('click', this.cs)
    }
    key = Math.random() * 100
    cs = this.closeSelect.bind(this)
    select(i, e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }
        this.setState({
            value: this.props.list[i][this.props.showValue]
        })
        if (this.props.change) {
            this.props.change(this.props.list[i], e)
        }
    }
    showPanel(e) {
        if (this.props.disabled) return
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            showList: !this.state.showList
        })
    }
    closeSelect(e) {
        if (e.target.className === this.props.uni) return
        this.setState({
            showList: false
        })
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.cs)
    }
    componentDidMount() {
        if (!isNaN(this.props.initValue)) {
            this.select(this.props.initValue)
        } else if (!isNaN(this.props.value)) {
            this.select(this.props.value)
        }
    }
    componentDidUpdate(prev) {
        if (this.props.value !== prev.value) {
            this.select(this.props.value)
        }
    }
    render() {
        return (
            <div style={{
                position: 'relative'
            }}  >
                <input placeholder={this.props.placeholder} value={this.state.value} readOnly style={{
                    userSelect: 'none', WebkitUserSelect: 'none',
                    width: '100%', fontSize: 14, padding: '4px 11px',
                    background: '#fff', border: '1px solid #d9d9d9',
                    cursor: 'pointer', outline: 'none', borderRadius: 4
                }} onClick={this.showPanel.bind(this)} className={`${this.props.uni}`} />
                <div style={{
                    position: 'absolute', display: this.state.showList ? 'block' : 'none', zIndex: 1000,
                    maxHeight: this.props.maxHeight, width: '100%', background: '#fff', boxShadow: '0 2px 8px rgba(0,0,0,0.15)', overflow: 'auto',
                    borderRadius: 6, animation: 'menuOpen ease .3s forwards'
                }} className={`${this.props.uni}`}>
                    {this.props.list.map((item, i) => {
                        return (
                            <div key={i} onClick={this.select.bind(this, i)} style={{ padding: '4px 11px', fontSize: 14, userSelect: 'none' }} className={this.props.hovorClass}>
                                {item[this.props.showkey]}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
export default Select