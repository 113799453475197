
import React, { useState, useEffect, useRef, } from 'react';
import styled from 'styled-components';
import parseParams from '../../../methods/paramParse';
import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import Search from '../../total/Input/Search';
import { SIDEBAR_WIDTH, INDEX_CONTENT_LIST_WIDTH, PAGINATION_HEIGHT, TOPBAR_HEIGHT, } from '../../../data/style';
import { message, Pagination } from 'antd';
import Select from '../../total/Select_';
import { get } from '../../../netApi/fetch';
import OrderItem from '../../order/item';
import Prompt from '../../Method/Prompt';
import Export from '../../order/exportPanel_';
import Modal from '../../total/Modal';
import Quick from '../../order/OrderCtrl/Quick';
import Loading from '../../Method/Loading';
import batch from '../../order/methods/batch';
const OptionsLine = styled.div<{ option: string }>`
    position: absolute;
    bottom: 0;
    top: 0;
    background: var(--theme-main);
    border-radius: 2px;
    height: 2px;
    transition: all .3s;
    width: ${props => {
        if (!props.option) {
            return 0
        }
        // (option && option.length === 3) ? '2em' : '3em'
        switch ((props.option || '').length) {
            case 3:
                return '2em'
            case 6:
                return 'calc(4px + 5.5em)'
            default:
                return '3em'
        }
    }};
    left: ${props => {
        switch (props.option) {
            case 'all':
                return 0
            case 'notuse':
                return 'calc(3em )'
            case 'used':
                return 'calc(10em - 4px)'
            case 'applyrefund':
                return 'calc(100% - 7em - 4px)'
            case 'refundsuccess':
                return 'calc(100% - 3em)'
            default:
                return 0
        }
    }};
`

const Page = styled.div`
    position: fixed;
    width: calc(100vw - ${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px - 128px);
    height: ${PAGINATION_HEIGHT}px;
    right: 128px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px 0px rgba(215,215,215,0.50); 
    background: #fff;
`

const Side = styled.div`
    width: 128px;
    right: 0;
    top: ${TOPBAR_HEIGHT + 70}px;
    height: calc(100vh - 70px - ${TOPBAR_HEIGHT}px);
    background-color: #f7f7f7;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    button{
        width: 108px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        color: #666;
        font-size: 14px;
        border: 0;
        margin-bottom: 16px;
        transition: all .3s;
        &:hover{
            color: #fff;
            background-color: var(--theme-main) ;
        }
    }
`

interface props extends RouteComponentProps {
    match: Match
}
interface Match extends match {
    params: {
        tuanid: string
    }
}

type orderType = 'all' | 'my' | 'seller'

function List(props: props) {
    useEffect(() => {
        document.body.style.background = '#fff'
        return () => {
            document.body.style.background = ''
        }
    })
    const [option, setOption] = useState<string>()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [count, setCount] = useState(0)

    const [type, setType] = useState<orderType>('all')
    const typelist: { title: string, value: orderType }[] = [
        { title: '全部订单', value: 'all' },
        { title: '我的订单', value: 'my' },
        { title: '好友订单', value: 'seller' },
    ]

    const [orderList, setOrderList] = useState<order[]>()
    const [expressList, setExpressList] = useState<any[]>()

    const getKey = useRef<any>()

    const [showExportPanel, setshowExportPanel] = useState(false)
    const [quickCheckin, setquickCheckin] = useState(false)
    const [quickSend, setquickSend] = useState(false)
    const [showSelectSendPanel, setshowSelectSendPanel] = useState(false)
    // const [showSelectSendPanel, setshowSelectSendPanel] = useState(false)
    const [selectedSendMode, setselectedSendMode] = useState<1 | 2>(1)

    const [tuanlist, setTuanlist] = useState<any[]>()

    // 可一键快递列表
    const quickSendList = (tuanlist || []).filter(i => (Number(i.takeway) === 1 && Number(i.pid) < 1))

    // 可一键核销列表
    const quickCheckinList = (tuanlist || []).filter(i => (Number(i.takeway) === 2 && Number(i.distributionid) <= 0))

    const thistuan = (tuanlist || []).find(i => i.id === props.match.params.tuanid)

    useEffect(() => {
        const params = parseParams(props.location.search)
        setOption(params.option || 'all')
        setPage(Number(params.page || 1))
        setType(params.type as orderType || 'all')
        setSearch(params.search || '')
        getKey.current = {
            type: params.type as orderType || 'all',
            option: params.option || 'all',
            search: params.search || '',
            page: Number(params.page || 1),
        }
        setOrderList([])
        Loading.show('加载中')
        get('/shop/getorderlist', {
            order_op: params.type as orderType || 'all',
            op: params.option || 'all',
            search: params.search || '',
            offset: (Number(params.page || 1) - 1) * 10,
            limit: 10,
            tuanid: btoa(props.match.params.tuanid)
        }).then(res => {
            function paramsChange(e) {
                if (e.page === getKey.current.page && e.option === getKey.current.option && e.type === getKey.current.type && e.search === getKey.current.search) return false
                return true
            }
            if (paramsChange({
                type: params.type as orderType || 'all',
                option: params.option || 'all',
                search: params.search || '',
                page: Number(params.page || 1),
            }) && !params.refresh) {
                return
            }
            Loading.hide()
            setCount(res.count)
            setOrderList(res.list)
            setTuanlist(res.tuanlist)
        }).catch(e => {
            message.error(e.message)
            Loading.hide()
        })
    }, [props.location.search, props.match.params.tuanid])

    useEffect(() => {
        if (!expressList) {
            get('/express/getExpressCompany', {}).then(res => {
                setExpressList(res)
            })
        }
    }, [expressList])

    function route(data: any) {
        const s = {
            option,
            page,
            type,
            search,
        }
        if (data.option) s.option = data.option
        if (data.page) s.page = data.page
        if (data.type) s.type = data.type
        if (data.search) s.search = data.search
        let href = `${props.location.pathname}?option=${s.option}&page=${s.page}&type=${s.type}&search=${s.search}`
        if (data.v) {
            href += `&${data.v}`
        }
        props.history.push(href)
    }


    return <div style={{ background: '#fff', padding: 16, marginRight: 128 }}>
        <div className='flex-between'>
            <div className='flex-between' style={{ width: '21em', position: "relative", padding: '8px 0', marginBottom: 12 }}>
                {[
                    { key: 'all', name: '全部' },
                    { key: 'notuse', name: '未核销/发货' },
                    { key: 'used', name: '已完成' },
                    { key: 'applyrefund', name: '退款中' },
                    { key: 'refundsuccess', name: '已退款' },
                ].map(i => {
                    const active = { color: `var(--theme-main)` }
                    return (
                        <div key={i.key} className='fcc cur-pointer' onClick={() => {
                            route({ page: 1, option: i.key })
                        }}>
                            <span style={option === i.key ? active : {}}>{i.name}</span>
                        </div>
                    )
                })}
                <div style={{ position: 'absolute', width: '100%', bottom: 0, height: 4 }}>
                    <OptionsLine option={option} ></OptionsLine>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <Search placeholder='搜索用户名、手机号、订单号' search={search} onSearch={() => {
                    route({ page: 1, })
                }} onChange={setSearch} outerStyle={{ width: '18em', padding: '4px 12px' }} />
            </div>
        </div>
        <div>
            <div style={{ width: 90 }}>
                <Select inputStyle={{
                    background: 'var(--theme-main)', color: '#fff', border: 0,
                }} list={typelist} showKey='title' value={typelist.findIndex(i => i.value === type)} uni='ordertype' onChange={e => {
                    route({ page: 1, type: e.value, })
                }} />
            </div>
        </div>
        <div style={{ paddingBottom: 80, }}>
            {(orderList || []).map(i => {
                return <OrderItem key={i.orderid} data={i} expressList={expressList || []} print={() => {
                    props.history.push({
                        pathname: '/orders/print',
                        state: {
                            list: [i]
                        }
                    })
                }} send={() => { route({ v: (Math.random() * 10000).toFixed(0) }) }} />
            })}
        </div>
        <Page>
            <Pagination current={page} showSizeChanger={false} pageSize={10} onChange={e => {
                route({ page: e })
            }} total={count} />
        </Page>
        <Side>
            <button onClick={() => setshowExportPanel(true)} >
                导出数据
            </button>
            {!!thistuan && Number(thistuan.takeway) === 1 && Number(thistuan.pid) < 1 && <button onClick={() => { setshowSelectSendPanel(true) }}>
                批量发货
            </button>}
            {!!thistuan && Number(thistuan.takeway) === 2 && Number(thistuan.distributionid) <= 0 && <button onClick={() => { setquickCheckin(true) }}>
                一键核销
            </button>}
            <button onClick={() => {
                Prompt({
                    type: 'select',
                    title: '打印订单',
                    content: '选择订单状态',
                    selectConfig: {
                        list: [{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' },],
                        showKey: 'title'
                    }
                }).then(res => {
                    window.open(`/orders/print/${encodeURIComponent(btoa(props.match.params.tuanid))}?op=${(res as { value: number, title: string }).value}&type=tuan`)
                }).catch()
            }}>
                打印订单
            </button>
        </Side>
        {showExportPanel ? <Export
            close={() => { setshowExportPanel(false) }}
            tuanlist={tuanlist || []}
            preselect={btoa(props.match.params.tuanid)}
        ></Export> : null}

        {showSelectSendPanel ? <Modal title='请选择发货方式' onConfirm={() => {
            setshowSelectSendPanel(false)
            if (selectedSendMode === 1) {
                batch().then(res => {
                    if (res === 'export') setshowExportPanel(true)
                    if (res === true) props.history.push(props.location.pathname + (props.location.search || '?') + `&refresh=1`)
                })
            }
            if (selectedSendMode === 2) {
                setquickSend(true)
            }
        }} onClose={() => { setshowSelectSendPanel(false) }}>
            <div className='fbc'>
                <div className='cur-pointer' onClick={() => { setselectedSendMode(1) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: selectedSendMode === 1 ? '#fff' : '#eee', color: selectedSendMode === 1 ? `var(--theme-main)` : '#666', border: selectedSendMode === 1 ? `1px solid var(--theme-main) ` : '1px solid #eee' }}>
                    <div style={{ fontSize: 20, fontWeight: 600, }}>快递配送</div>
                    <div style={{ fontSize: 12, marginTop: 15 }}>需要导入快递单号</div>
                    <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: selectedSendMode === 1 ? 'block' : 'none' }} />
                </div>
                <div className='cur-pointer' onClick={() => { setselectedSendMode(2) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: selectedSendMode === 2 ? '#fff' : '#eee', color: selectedSendMode === 2 ? `var(--theme-main)` : '#666', border: selectedSendMode === 2 ? `1px solid var(--theme-main) ` : '1px solid #eee' }}>
                    <div style={{ fontSize: 20, fontWeight: 600, }}>一键发货（无需导入单号）</div>
                    <div style={{ fontSize: 12, marginTop: 15 }}>适用于由商家自己配送货物的活动</div>
                    <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: selectedSendMode === 2 ? 'block' : 'none' }} />
                </div>
            </div>
        </Modal> : null}

        {/* 一键核销 */}
        {quickCheckin ? <Quick takeway={2} initTuanId={Number(props.match.params.tuanid)}
            quickSendList={quickSendList} quickCheckinList={quickCheckinList} onConfirm={() => {
                props.history.push(props.location.pathname + (props.location.search || '?') + `&refresh=1`)
                setquickCheckin(false)
            }} onClose={() => { setquickCheckin(false) }} /> : null}

        {/* 一键发货 */}
        {quickSend ? <Quick takeway={1} initTuanId={Number(props.match.params.tuanid)}
            quickSendList={quickSendList} quickCheckinList={quickCheckinList} onConfirm={() => {
                props.history.push(props.location.pathname + (props.location.search || '?') + `&refresh=1`)
                setquickSend(false)
            }} onClose={() => { setquickSend(false) }} /> : null}
    </div>
}

export default withRouter(List)