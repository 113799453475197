import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import Modal from '../total/Modal'
import Select from '../total/Select_.tsx';
import api from '../../netApi'
import Refund from './OrderCtrl/refund.tsx';
import ActRefund from './OrderCtrl/ActRefund.tsx';
import Checkin from './OrderCtrl/Checkin.tsx';
import { setOrder } from '../../reducers';
import { get } from '../../netApi/fetch.ts';

import BTN from '../../StyleComponents/Button';

// import { initiativeRefund } from './methods/initiativeRefund.ts';
// import { message } from 'antd'
import styled from 'styled-components'
import { confirm } from '../Method/Confirm'
import { post } from '../../netApi/fetch'
import { message } from 'antd'
import Prompt from '../Method/Prompt'
import { DEFAULT_LOGO } from '../../data/options'
import { express } from '../../data/competence'
import text from '../../data/text'
import VIPPower from '../index/method/VIPPower'

const Logo = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: .6em;
`

class Item extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired
    }
    constructor(p) {
        super()
        this.state = {
            outstyle: {
                border: '1px solid #dedede',
                marginBottom: 10,
                marginTop: 10,
                overflow: 'hidden',
                borderRadius: 10,
            },
            head: {
                background: '#f6f6f6',
                fontSize: 14,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
            },
            mch_remark: p.data.mch_remark,
        }
        // 配送信息
        if (Number(p.data.shipping_mode) === 3) {
            this.state.rider = true
            this.state.dispatch = p.data.dispatch_info
            this.state.dispatch.showText = (() => {
                switch (Number(p.data.dispatch_info.status)) {
                    case 0:
                        return '预发布'
                    case 1:
                        return '待接单'
                    case 2:
                        return '待取货'
                    case 100:
                        return '已到店'
                    case 3:
                        return '配送中'
                    case 4:
                        return '已完成'
                    case 5:
                        return '已取消'
                    case 7:
                        return '已过期'
                    case 9:
                        return '投递异常返还中'
                    case 10:
                        return '投递异常返还完成'
                    default:
                        return ''
                }
            })()
        }
    }


    componentDidUpdate(p) {
        if (Number(this.props.data.shipping_mode) === 3) {
            if (p.data.dispatch_info.status !== this.props.data.dispatch_info.status) {
                const dispatch = this.props.data.dispatch_info
                dispatch.showText = (() => {
                    switch (Number(this.props.data.dispatch_info.status)) {
                        case 0:
                            return '预发布'
                        case 1:
                            return '待接单'
                        case 2:
                            return '待取货'
                        case 100:
                            return '已到店'
                        case 3:
                            return '配送中'
                        case 4:
                            return '已完成'
                        case 5:
                            return '已取消'
                        case 7:
                            return '已过期'
                        case 9:
                            return '投递异常返还中'
                        case 10:
                            return '投递异常返还完成'
                        default:
                            return ''
                    }
                })()
                this.setState({
                    dispatch
                })
            }
        }
    }


    render() {
        return (
            <div className='orderItem' style={this.state.outstyle} >
                <div style={this.state.head}>
                    <div>
                        <img src={(this.props.data.shopinfo.logo || DEFAULT_LOGO) + '?imageView2/5/w/200'} alt={this.props.data.shopinfo.name} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 16 }} className='img-center' />
                    </div>
                    <div style={{ wordBreak: 'break-all' }} onClick={() => { console.log(this.props.data) }}>购买人：{this.props.data.shopinfo.name}</div>
                    <div className='orderDate' style={{ flex: 1, color: '#999', fontSize: 14 }}>{this.props.data.paytime}</div>
                    <div>订单号：{this.props.data.payorderid}</div>
                </div>
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <tbody>
                        {this.props.data.items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className='orderItem__goodName'>
                                        <div className='fcc'>
                                            <img className=' img-center' alt='' src={(item.logo || DEFAULT_LOGO) + '?imageView2/5/w/200'} />
                                            <div style={{ wordBreak: 'break-all' }}><span>{item.name}</span><span>({item.unit})</span></div>
                                        </div>
                                    </td>
                                    <td className='orderItem__price'>
                                        <div className='fcc'>￥{(item.price / 100).toFixed(2)}</div>
                                    </td>
                                    <td className='orderItem__num'>
                                        <div className='fcc'>x{item.num}</div>
                                    </td>

                                    {index === 0 && <td className='orderItem__totalPrice' rowSpan={this.props.data.items.length}>
                                        <div>共{this.props.data.items.length}件</div>
                                        {Number(this.props.data.postfee) ? <div>运费￥{(this.props.data.postfee / 100).toFixed(2)}</div> : null}
                                        <div>应付金额：￥{(this.props.data.totalprice / 100).toFixed(2)}</div>
                                        {(() => {
                                            if ([0, 100].includes(Number(this.props.data.discount))) return null
                                            const discount = Number(this.props.data.discount) / 10 + "折"
                                            const dp = (Number(this.props.data.discount_price_after) / 100).toFixed(2)
                                            return <div >会员优惠（{discount}）：<span style={{ display: 'inline-block' }}>-￥{dp}</span></div>
                                        })()}
                                        {this.props.data.couponlog_price ? <div>活动惊喜：<span style={{ display: 'inline-block' }}>-￥{(Number(this.props.data.couponlog_price) / 100).toFixed(2)}</span></div> : null}
                                    </td>}

                                    {index === 0 && <td className='orderItem__totalPrice' rowSpan={this.props.data.items.length}>
                                        <div>实付金额</div>
                                        <div style={{ color: 'var(--theme-main)' }}>￥{(Number(this.props.data.payprice) / 100).toFixed(2)}</div>
                                    </td>}

                                    {index === 0 && !this.state.rider && <td rowSpan={this.props.data.items.length}>
                                        <div className='flex-center' style={{ color: 'var(--theme-main)' }}>
                                            {this.props.data.status_content}
                                        </div>
                                    </td>}

                                    {index === 0 && this.state.rider && <td rowSpan={this.props.data.items.length}>
                                        <div className='flex-center' style={{ color: 'var(--theme-main)' }}>
                                            {this.state.dispatch.showText || '未发货'}
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan='6' style={{ padding: '8px 20px' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <span style={{ padding: '4px 11px 4px 0', minWidth: '4em', color: '#999' }}>买家备注：</span>
                                    <span style={{ display: 'inline-block', height: '100%', padding: ' 4px 11px 4px 0px' }}>
                                        <span style={{ maxWidth: '70%', wordBreak: 'break-all' }}>{this.props.data.remark}</span>
                                        {/* <i className='icon-xiugai iconfont' onClick={()=>{this.setState({setRemark: true})}} style={{marginLeft: 20}}></i> */}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='6' style={{ paddingRight: 8, padding: '4px 20px' }}>
                                <div className='fbc'>
                                    {this.props.data.buy_no ? <div>
                                        <span style={{ color: '#999' }}>接龙号：</span>
                                        <span style={{ color: '#333' }}>NO.{/[a-zA-Z]+/.exec(this.props.data.buy_no)}-</span>
                                        <span style={{ color: '#333', fontSize: '2em' }}>{/[0-9]+/.exec(this.props.data.buy_no)}</span>
                                    </div> : null}
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 5, flex: 1 }}>
                                        {(!this.props.data.mall_id || Number(this.props.data.mall_id) <= 0) && !!this.props.data.is_can_send_goods_or_exchange && !this.props.data.is_hema && <>
                                            {(Number(this.props.data.shipping_mode) === 1 && this.props.data.type !== 'hd' && (Number(this.props.data.cansend) !== 0)) ? (
                                                <BTN className='' color='red' style={{ minWidth: '6em' }} onClick={() => { this.setState({ ship: true }) }}>
                                                    发 货
                                                </BTN>
                                            ) : null}

                                            {(((Number(this.props.data.shipping_mode) === 2 || this.props.data.type === 'hd')) && (Number(this.props.data.cansend) !== 0)) ? (
                                                <BTN className='' color='red' style={{ minWidth: '6em' }} onClick={() => { this.setState({ showExchange: true }) }}>
                                                    核 销
                                                </BTN>
                                            ) : null}
                                        </>}
                                        {Boolean(this.props.data.mall_id && Number(this.props.data.mall_id) > 0 && !this.props.data.is_hema) && <>
                                            {!!this.props.data.is_can_send_goods_or_exchange && <>
                                                {Number(this.props.data.shipping_mode) === 1
                                                    && <BTN className='' color='red' style={{ minWidth: '6em' }} onClick={() => { this.setState({ ship: true }) }}>
                                                        发 货
                                                </BTN>}
                                                {Number(this.props.data.shipping_mode) === 2
                                                    && <BTN className='' color='red' style={{ minWidth: '6em' }} onClick={() => { this.setState({ showExchange: true }) }}>
                                                        核 销
                                                </BTN>}
                                                {Number(this.props.data.shipping_mode) === 3
                                                    && <BTN className='' color='red' style={{ minWidth: '6em' }} onClick={() => {
                                                        confirm({ text: '本订单将由达达骑手上门取货并配送，确定现在要配送？' }).then(res => {
                                                            if (!res) return
                                                            post('/order/dispatchdelivery', { order_id: this.props.data.orderid }).then(res => {
                                                                message.success('发货成功')
                                                            })
                                                        })
                                                    }}>
                                                        立即配送
                                                </BTN>}
                                            </>}
                                        </>}
                                        {(() => {
                                            if (Number(this.props.data.shipping_mode) !== 3) return null
                                            if (!this.props.data) return null
                                            if (!this.props.data.dispatch_info) return null
                                            const { status } = this.props.data.dispatch_info
                                            if ([1, 2, 100].includes(Number(status))) {
                                                return <BTN solid color='gray' onClick={() => {
                                                    get('/dispatch/cancelreasons', {}).then(res => {
                                                        return Prompt({
                                                            type: 'select',
                                                            title: '请选择取消理由',
                                                            selectConfig: {
                                                                list: res,
                                                                showKey: 'reason'
                                                            }
                                                        })
                                                    }).then(res => {
                                                        post('/dispatch/cancelorder', {
                                                            order_id: this.props.data.orderid,
                                                            reason_id: res.id
                                                        }).then(res => {
                                                            message.success('操作成功')
                                                            this.props.send()
                                                        })
                                                    }).catch(e => { if (e.message) message.error(e.message) })
                                                }}>取消配送</BTN>
                                            }
                                            if ([5, 7, 10].includes(Number(status))) {
                                                return <BTN solid color='gray' onClick={() => {
                                                    confirm({ text: '本订单将由达达骑手上门取货并配送，确定现在要配送？', title: '操作确认' },).then(res => {
                                                        if (!res) return
                                                        post('/dispatch/readdorder', { order_id: this.props.data.orderid }).then(res => {
                                                            message.success('操作成功')
                                                            this.props.send()
                                                        }).catch(e => { if (e.message) message.error(e.message) })
                                                    })
                                                }}>重新配送订单</BTN>
                                            }
                                            if ([9].includes(Number(status))) {
                                                return <BTN solid color='gray' onClick={() => {
                                                    confirm({ text: '本订单将由达达骑手上门取货并配送，确定现在要配送？', title: '操作确认' },).then(res => {
                                                        if (!res) return
                                                        post('/dispatch/returnconfirm', { order_id: this.props.data.orderid }).then(res => {
                                                            message.success('操作成功')
                                                            this.props.send()
                                                        }).catch(e => { if (e.message) message.error(e.message) })
                                                    })
                                                }}>配送异常返回确认</BTN>
                                            }
                                        })()}
                                        {(this.props.data.refund_status === -1 && Number(this.props.data.cansend) === 1 && !this.props.data.is_hema) ? (
                                            <BTN className='' color='gray' onClick={() => { this.setState({ showRefund: true }) }}>
                                                退款处理
                                            </BTN>
                                        ) : null}

                                        <a target='_blank' rel="noopener noreferrer" href={`/${this.props.data.type === 'hd' ? 'eventOrder' : 'orders'}/detail/` + this.props.data.orderid} >
                                            <BTN color='red'
                                                className='' style={{
                                                    minWidth: '6em', marginLeft: 10
                                                }}>订单详情</BTN>
                                        </a>

                                        {/*  */}
                                        <BTN className='' color='gray'
                                            style={{ marginLeft: 10, minWidth: '6em' }}
                                            onClick={this.props.print}
                                        >打印订单</BTN>

                                        {(this.props.data.refund_status !== -1 && Number(this.props.data.status) === 1 && !this.props.data.is_hema) ? <BTN className='' color='gray'
                                            style={{ marginLeft: 10, minWidth: '6em' }}
                                            onClick={() => {
                                                get('/order/getcanrefundmode', {
                                                    types: 1,
                                                    orderid: btoa(this.props.data.orderid)
                                                }).then(({ mode_list, fail_content }) => {
                                                    if (fail_content !== '') {
                                                        alert(fail_content)
                                                        return
                                                    }
                                                    this.setState({ initiativeRefund: true, mode_list, })
                                                })
                                            }}
                                        >发起退款</BTN> : null}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='6' style={{ padding: '8px 20px', borderBottom: 0 }}>
                                {this.state.mch_remark ? (
                                    <div className='orderItem__mchRemark flex-between'>
                                        <span>
                                            卖家备注：<span>{this.state.mch_remark}</span>
                                        </span>
                                        <span className='cur-pointer' onClick={() => {
                                            Prompt({
                                                type: 'string',
                                                value: this.state.mch_remark,
                                                placeholder: '请填写备注',
                                                title: '请填写备注',
                                            }).then(res => {
                                                return post('/order/addmchremark?app=jieligo', {
                                                    orderid: this.props.data.orderid,
                                                    mch_remark: res,
                                                }).then(() => {
                                                    this.setState({ mch_remark: res })
                                                    message.success('修改成功')
                                                })
                                            }).catch(e => { if (e.message) message.error(e.message) })
                                        }}>修改<i className='iconfont icon-xiugai' style={{ fontSize: 14 }} /></span>
                                    </div>
                                ) : <div className='orderItem__mchRemark flex-between cur-pointer' style={{ display: 'inline-block' }} onClick={() => {
                                    Prompt({
                                        type: 'string',
                                        placeholder: '请填写备注',
                                        title: '请填写备注',
                                    }).then(res => {
                                        return post('/order/addmchremark?app=jieligo', {
                                            orderid: this.props.data.orderid,
                                            mch_remark: res,
                                        }).then(() => {
                                            this.setState({ mch_remark: res })
                                            message.success('修改成功')
                                        })
                                    }).catch(e => { if (e.message) message.error(e.message) })
                                }}>
                                    <span>添加备注</span>
                                </div>}
                            </td>
                        </tr>
                        {(this.props.data.lasttuan_shopinfo && this.props.data.lasttuan_shopinfo.logo) ? <tr>
                            <td colSpan='5' style={{ padding: '8px 20px', borderBottom: 0 }}>
                                <div style={{ fontWeight: 800, display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: "#999", fontWeight: 400 }}>订单来源：</span>
                                    <div className='fcc'>
                                        <Logo>
                                            <img className='img-center' src={this.props.data.lasttuan_shopinfo.logo} alt='团长头像' />
                                        </Logo>
                                        <div>{this.props.data.lasttuan_shopinfo.name}</div>
                                    </div>
                                </div>
                            </td>
                        </tr> : null}
                        {(this.props.data.sale_from_info && this.props.data.sale_from_info.shop_logo) ? <tr>
                            <td colSpan='5' style={{ padding: '8px 20px', borderBottom: 0 }}>
                                <div style={{ fontWeight: 800, display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: "#999", fontWeight: 400 }}>订单来源：</span>
                                    <div className='fcc'>
                                        <Logo>
                                            <img className='img-center' src={this.props.data.sale_from_info.shop_logo} alt='团长头像' />
                                        </Logo>
                                        <div>{this.props.data.sale_from_info.shop_name}</div>
                                        {this.props.data.sale_from_info.branch_name.length && <div style={{
                                            border: '1px #FF9423 solid', borderRadius: 2, color: '#FF9423', fontWeight: 'normal', padding: '0 4px', fontSize: 12, marginLeft: 12
                                        }}>{this.props.data.sale_from_info.branch_name}</div>}
                                    </div>
                                </div>
                            </td>
                        </tr> : null}
                    </tbody>
                </table>

                {this.state.ship ? (
                    <Modal title='请填写发货信息' loading={this.state.sending} close={() => { this.setState({ ship: false, express: {}, expressNum: '' }) }} cancel={true} confirm={this.sendExpress.bind(this)} >
                        <div id='shipPanel'>
                            <div style={{ maxWidth: 500 }}>
                                <div className='title'>购买人信息</div>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top' }}>
                                        <div style={{ fontSize: 14 }}>收货人</div>
                                        <div style={{ fontSize: 14 }}>{this.props.data.username}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top' }}>
                                        <div style={{ fontSize: 14, minWidth: '4em' }}>电 话</div>
                                        <div style={{ fontSize: 14 }}>{this.props.data.phone}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top' }}>
                                        <div style={{ fontSize: 14, minWidth: '4em' }}>地 址</div>
                                        <div style={{ fontSize: 14 }}>{this.props.data.address}</div>
                                    </div>
                                </div>
                            </div>
                            <form style={{ maxWidth: 500, marginTop: 12 }}>
                                <table style={{ tableLayout: 'fixed', width: '100%', border: 0 }}>
                                    <thead>
                                        <tr>
                                            <td style={{ width: '25%', border: 0 }}>
                                                <div style={{ paddingBottom: 12, fontWeight: 600, color: '#353535' }}>
                                                    立即发货
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ marginTop: 14 }}>
                                            <td style={{ height: 30, border: 0 }}><div style={{ fontSize: 14 }}>快递单号</div></td>
                                            <td style={{ fontSize: 14, padding: 6, border: 0 }}>
                                                <input onChange={(e) => {
                                                    const num = e.target.value
                                                    this.setState({
                                                        expressNum: e.target.value
                                                    })
                                                    get('/express/getexpresscompanybynum', {
                                                        types: 1,
                                                        express_number: num
                                                    }).then(res => {
                                                        this.setState({
                                                            express: res
                                                        })
                                                    }).catch()
                                                }} value={this.expressNum}
                                                    style={{
                                                        fontSize: 14, padding: '4px 11px', width: '100%', outline: 'none',
                                                        borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: '#d9d9d9'
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 30, border: 0 }}><div style={{ fontSize: 14, }}>快递公司</div></td>
                                            <td style={{ fontSize: 14, padding: 6, border: 0 }}>
                                                <Select uni='expressCompany'
                                                    list={this.props.expressList || []}
                                                    showKey='name'
                                                    maxHeight={200}
                                                    placeholder='请选择快递公司'
                                                    hovorClass='select-hover'
                                                    onChange={(e) => {
                                                        this.setState({
                                                            express: e
                                                        })
                                                    }}
                                                    value={this.props.expressList ? this.props.expressList.findIndex(i => Number(i.id) === Number(this.state.express ? this.state.express.id : '')) : ''}
                                                >
                                                </Select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </Modal>
                ) : ''}
                {this.state.showExchange ? <Checkin order={this.props.data} onClose={e => {
                    if (e.refresh) this.props.send()
                    this.setState({ showExchange: false })
                }}>
                </Checkin> : null}

                {this.state.showRefund ? (
                    <Refund order={this.props.data} onClose={() => { this.props.send(); this.setState({ showRefund: false }) }} />
                ) : ''}

                {this.state.initiativeRefund ? (<ActRefund onClose={() => {
                    this.props.send();

                    this.setState({ initiativeRefund: false })
                }} order={this.props.data} modelist={this.state.mode_list} />) : null}

            </div>
        )
    }

    exchange() {
        api('order', 'exchange')({}, 'post', {
            orderid: this.props.data.orderid,
            types: 1,
            code: 0
        }).then((res) => {
            if (Number(res.data.code) === 0) {
                alert('核销成功')
                this.props.send()
                this.setState({ showExchange: false })
            }
        })
    }
    sendExpress() {
        if (!this.state.express || !this.state.express.id) {
            alert('请选择快递信息')
            return;
        }
        if (!this.state.expressNum || this.state.expressNum.trim().length === 0) {
            if (this.state.express.code !== 'sjzzps') {
                alert('请填写快递单号')
                return;
            }
        }
        this.setState({ sending: true })
        api('order', 'sendgoods')({}, 'post', {
            orderid: this.props.data.orderid,
            express_id: this.state.express.id,
            expressname: this.state.express.name,
            expressnum: this.state.expressNum || '',
            types: 1,
        }).then((res) => {
            if (Number(res.data.code) === 0) {
                message.success('发货成功')
                if (!express.get()) {
                    confirm({
                        title: '温馨提示',
                        text: text.ERROR.MEMBER.EXPRESS,
                        confirmText: '去升级',
                        cancelText: '暂不升级',
                    }).then(res => {
                        if (res) VIPPower({})
                    })
                }
                this.props.send()
                this.setState({ ship: false })
            }
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setOrderList: (order) => {
            dispatch(setOrder(order))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Item))

export class FakeItem extends Component {
    constructor() {
        super()
        this.state = {
            outstyle: {
                border: '1px solid #dedede',
                marginBottom: 20,
                overflow: 'hidden'
            },
            head: {
                background: '#f6f6f6',
                fontSize: 16,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
            },
        }
    }
    render() {
        return <div className='orderlistitem' style={this.state.outstyle}>
            <div style={this.state.head}>
                <div>
                    <div alt='' style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 16 }} className='img-center skeleton' />
                </div>
                <div className='orderDate skeleton' style={{ flex: 1, color: '#999', fontSize: 14 }}></div>
                <div className='skeleton'></div>
            </div>
            <table style={{ width: '100%', tableLayout: 'fixed' }}>
                <tbody>
                    {[1, 1].map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className='orderItem__goodName'>
                                    <div className='skeleton'></div>
                                </td>
                                <td className='orderItem__price'>
                                    <div className='skeleton'></div>
                                </td>
                                <td className='orderItem__num'>
                                    <div className='skeleton'></div>
                                </td>
                                {index === 0 && <td className='orderItem__totalPrice' rowSpan={2}>
                                    <div className='skeleton'></div>
                                </td>}
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan='5' style={{ padding: '8px 20px' }}>
                            <div className='skeleton' style={{ display: 'flex', alignItems: 'flex-start' }}></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='5' style={{ paddingRight: 8, padding: '4px 20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 5 }}>
                                <div className='skeleton'></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='5' style={{ padding: '8px 20px', borderBottom: 0 }}>
                            <div className='skeleton'></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}