import React, { useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const Border = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    & >div:not(:last-child){
        margin-bottom: 16px;
    }
    input{
        border: 1px solid #ebebeb;
        margin-left: 20px;
        width: 150px;
        padding: 6px 11px;
    }
`

interface props {
    onClose: () => void
    data: {

    }
}

interface sku {
    no: string,
    bar: string,
    weight: string,
    spec: {
        spec: {
            id: number,
            name: string,
            rank: number,
        },
        spec_item: {
            id: number,
            name: string,
            rank: string,
        }
    }[]
    ids: string,
}

let wrapper: Element


export default async function (skulist: sku[]) {
    return new Promise(async (reslove, reject) => {
        function Sku(props: props) {

            const [list, setList] = useState(skulist)

            const [shouldClose, setShouldClose] = useState(false)

            return <Modal title='设置编码与重量' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    {list.map((i, idx) => {
                        return <Border key={idx}>
                            {i.spec.map(spec => {
                                return <div className='flex-box' key={spec.spec.id}>
                                    <div style={{ width: '7em', marginRight: 20, }}>{spec.spec.name}</div>
                                    <div>{spec.spec_item.name}</div>
                                </div>
                            })}
                            <div className='flex-box'>
                                {/* <div>规格编码</div>
                                <input style={{ marginRight: 30 }} placeholder='请输入规格编码' value={i.no} onChange={e => {
                                    i.no = e.target.value
                                    setList([...list])
                                }} /> */}
                                <div>重量</div>
                                <input style={{ marginRight: 14 }} placeholder='请输入商品重量' type='number' value={i.weight} onChange={e => {
                                    i.weight = e.target.value
                                    setList([...list])
                                }} />
                                <div>g</div>
                            </div>
                            <div className='flex-box'>
                                <div>商品条码</div>
                                <input style={{ marginRight: 30 }} placeholder='请输入商品条码' value={i.bar} onChange={e => {
                                    i.bar = e.target.value
                                    setList([...list])
                                }} />
                                <div>每个规格对应唯一的商品条码</div>
                            </div>
                        </Border>
                    })}
                </div>
            </Modal>

            function confirm() {
                setShouldClose(true)
                reslove(list)
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Sku data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
        }} />, wrapper)
    })
}