import { API as baseURL, authkey, platform } from '../data/options';
import 'whatwg-fetch';
// import md5 from 'crypto-js/md5';
import md5 from 'md5';
interface options {
  method?: string;
  headers?: HeadersInit;
  mode?: 'cors'; // 固定为cors
  credentials?: 'include'; // 固定携带cookies
  cache?: RequestCache;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  integrity?: string;
}

interface data {
  params?: { [key: string]: string | number };
  data?: { [key: string]: string | number };
}

let TOKEN = localStorage.getItem('token') || '';

function fapi(address: string, data: data = {}, options?: options): { [key: string]: any } {
  return new Promise((resolve, reject) => {
    const url: string = getUrl(address, data.params);
    let createtime = String(Math.floor(Date.now() / 1000));
    let token = md5(authkey + createtime);
    const defaultOptions: options = {
      headers: {
        platform,
        token,
        createtime,
      },
      mode: 'cors',
      credentials: 'include',
      method: 'get',
    };
    fetch(url, {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers,
        ...(options.headers || {}),
      },
      body: JSON.stringify(data.data),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((resdata: NetworkResult) => {
            if (resdata.code === 0) {
              resolve(resdata.data);
              return;
            } else {
              reject(resdata);
            }
          });
          return;
        }
        reject('服务器网络错误，请重试');
      })
      .catch((res) => {
        reject();
      });
  });
}

export function getUrl(address: string, params: { [key: string]: string | number } = {}): string {
  let url: string = baseURL + address;

  const keys: string[] = Object.keys(params);

  if (keys.length >= 1) {
    url += `?${keys[0]}=${params[keys[0]]}`;
    for (let i = 1; i < keys.length; i++) {
      const key = keys[i];
      const value = params[key];
      url += `&${key}=${value}`;
    }
  }

  return url;
}

export function post(address: string, data: { [key: string]: any }, params?: { [key: string]: string | number }, options?: options) {
  return fapi(
    address,
    { params, data: { types: 1, ...data, token: TOKEN } },
    {
      method: 'post',
      ...options,
    }
  );
}

export function get(address: string, params: { [key: string]: string | number | boolean | undefined }, options?: options) {
  return fapi(
    address,
    { params: { types: 1, ...params, token: TOKEN } },
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...options,
    }
  );
}

export function setToken(t: string): void {
  localStorage.setItem('token', t);
  TOKEN = t;
}

export function getToken(): string {
  return TOKEN;
}

export default fapi;
