import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from '../total/Select_';
import { message, Radio, DatePicker } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import Modal from '../total/Modal';
import moment from 'moment';
import styled from 'styled-components';

import { get } from '../../netApi/fetch';
import { withRouter, RouteComponentProps } from 'react-router';

const Line = styled.div`
    height: 1px;
    background: #ddd;
`


const TableItem = styled('div') <{ selected: boolean, type: number }>`
    cursor: pointer;
    /* margin-right: 30px; */
    display: flex;
    flex-basis: ${props => String(props.type).length > 1 ? '50%' : '100%'};
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 12px;
    i{
        color: ${props => props.selected ? 'var(--theme-main)' : '#ccc'};
        margin-right: 6px;
    }
`

interface Props extends RouteComponentProps {
    printList?: (list: any[]) => void,
    close: () => void,
    tuanlist: any[],
    user: any,
    type?: string,
    preselect?: string
}

interface state {
    downloadLink: string,
    option: number,
    type: number,
    tuanid: string,
    // exportType: number, // 0全部 1快递 2自提


    sort: string,

    refund: boolean,

    Loading: boolean,

    table: {
        key: number,
        value: boolean,
        name: string,
    }[],

    goodlist: {
        goodsid: number,
        name: string,
    }[],
    goodsids: number | string,

    sellerlist: {
        id: number,
        name: string,
        logo: string,
    }[],
    selectedSellerId: number | string,

    addresslist: {
        id: number,
        name: string,
    }[],
    selectedAddressIdxs: number[],

    startTime?: number,
    endTime?: number,
}

class Export extends Component<Props> {
    readonly state: state = {
        downloadLink: '',
        option: 0,
        type: 1,
        tuanid: '',


        sort: 'asc',

        refund: false,

        Loading: false,

        table: [
            { key: 2, value: false, name: '按行展示（不合并）' },
            { key: 1, value: false, name: '按行展示（合并）' },
            { key: 3, value: false, name: '按列展示' },
            { key: 5, value: false, name: '自提点拣货单' },
            { key: 4.1, value: false, name: '售卖数量统计【按销售数量排序】' },
            { key: 4.2, value: false, name: '售卖数量统计【按销售额排序】' },
            { key: 7.1, value: false, name: '帮卖好友销售统计【按订单数量排序】' },
            { key: 7.2, value: false, name: '帮卖好友销售统计【按销售额排序】' },
        ],

        goodlist: [],
        goodsids: '',

        addresslist: [],
        selectedAddressIdxs: [],

        sellerlist: [],
        selectedSellerId: '',

    }

    componentDidMount() {
        const selectedID = this.props.preselect
        if (selectedID) {
            this.setState({ tuanid: atob(decodeURIComponent(selectedID)) })
        }
    }
    select(type: any, e: any) {
        this.setState({ [type]: e.target.value })
    }

    export() {
        if (!this.state.tuanid) {
            message.warning('请选择活动')
            return
        }

        let output_by_goods_orderby: number
        let output_by_seller_orderby: number
        const list = this.state.table.filter(i => i.value).map(i => {
            const k = String(i.key)
            if(k.length > 1){
                const v = k.split('.')
                if(v[0] === '4'){
                    output_by_goods_orderby = Number(v[1])
                    return v[0]
                }
                if(v[0] === '7'){
                    output_by_seller_orderby = Number(v[1])
                    return v[0]
                }
            }
            return i.key
        }).join(',')
        if (list.length === 0) {
            message.warning('请选择导出的表格类型')
            return
        }

        const data: any = {
            option: this.state.option,
            type: this.props.type === 'hd' ? 2 : 1,
            tuanid: this.state.tuanid,
            sort: this.state.sort,
            output_refundorder: Number(this.state.refund)
        }
        if (this.state.startTime) data.start = this.state.startTime
        if (this.state.endTime) data.end = this.state.endTime
        if (this.state.goodsids) data.output_goodsids = this.state.goodsids
        // if (this.state.selectedAddressId) data.output_addressids = this.state.selectedAddressId
        if (this.state.selectedAddressIdxs) data.output_addressids = this.state.selectedAddressIdxs.map(i => this.state.addresslist[i].id)
        if (this.state.selectedSellerId) data.seller_sid = this.state.selectedSellerId
        if (list.length > 0) data.output_sheet_list = list

        // 帮卖好友销售统计排序方式
        if(output_by_seller_orderby)data.output_by_seller_orderby = (()=>{
            if(output_by_seller_orderby === 1)return 'order_num'
            if(output_by_seller_orderby === 2)return 'sale_price'
        })()

        // 商品售卖数量统计排序方式
        if(output_by_goods_orderby) data.output_by_goods_orderby = (()=>{
            if(output_by_goods_orderby === 1)return 'sale_num'
            if(output_by_goods_orderby === 2)return 'sale_price'
        })()
        console.log(output_by_goods_orderby);
        const query = getUrl('', data)
        window.open('/download/order/' + query, '_blank')
    }
    changeExportType(t: number) {
        this.setState({ actionType: t })
    }
    close(e: any) {
        e.preventDefault()
        e.stopPropagation()
        this.props.close()
    }

    tableCheck(idx: any) {
        const { table } = this.state
        const flag = table[idx].value
        const item = table[idx]
        if (!item.value) {
            if (item.key === 4.1 || item.key === 4.2) {
                table.forEach((i) => {
                    if (i.key === 4.1 || i.key === 4.2) {
                        i.value = false
                    }
                })
            }
            if (item.key === 7.1 || item.key === 7.2) {
                table.forEach((i) => {
                    if (i.key === 7.1 || i.key === 7.2) {
                        i.value = false
                    }
                })
            }
        }
        if (table.filter(i => i.value).length >= 3 && !flag) {
            message.warn('最多选择3种表格类型')
            return
        }
        // if (!(item.key === 4.1 || item.key === 4.2 || item.key === 7.1 || item.key === 7.2)) {
        // }

        table[idx].value = !table[idx].value
        this.setState({ table })
    }

    getGoodlist(id: number) {
        get('/goods/getgoodslistbytuanid', {
            tuanid: btoa(String(id)),
            types: 1,
        }).then((res: any) => {
            this.setState({ goodlist: [{ goodsid: '', name: '全部' }].concat(res.list) })
        })
    }

    getAddress(id: number) {
        get('/address/getpickuppointlistbytuanid', {
            tuanid: btoa(String(id)),
            types: 1,
        }).then((res: any) => {
            this.setState({ addresslist: [{ id: '', name: '全部' }].concat(res.list) })
        })
    }

    getSeller(id: number): void {
        get('/tuan/getsellerinfolistbytuanid', {
            types: 1,
            tuanid: btoa(String(id)),
        }).then((res: any) => {
            this.setState({ sellerlist: [{ id: '', name: '全部' }, ...res.list] })
        })
    }

    render() {
        return (
            <Modal confirmText={'导出订单'} showCancel title={'导出数据'} loading={this.state.Loading} close={this.props.close} style={{
                outer: {
                    width: 800,
                },
                content: {
                    overflowY: 'auto'
                }
            }} onConfirm={this.export.bind(this)} >
                <div className='fbc flexwrap'>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fcc'>
                        <div style={{ minWidth: '7em' }}>选择活动</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <Select uni='event' list={[
                                ...this.props.tuanlist,
                            ]} showKey='title'
                                placeholder='请选择活动'
                                onChange={e => {
                                    if (!e.id) {
                                        this.setState({ tuanid: undefined })
                                        return
                                    }
                                    this.setState({
                                        tuanid: e.id,
                                        goodlist: [],
                                        addresslist: [],
                                    })
                                    this.getGoodlist(e.id)
                                    this.getAddress(e.id)
                                    this.getSeller(e.id)
                                }} value={this.props.tuanlist.findIndex(i => i.id === this.state.tuanid)} maxHeight={200}
                                canSearch={true}
                            /></div>
                    </div>

                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fcc'>
                        <div style={{ minWidth: '7em' }}>选择订单状态</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <Select uni='dingdan' list={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' },]} showKey='title'
                                onChange={e => {
                                    this.setState({
                                        option: e.value
                                    })
                                }} value={this.state.option === 0 ? 0 : 1}
                            />
                        </div>
                    </div>
                </div>

                <div className='fbc flexwrap'>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc '>
                        <div style={{ minWidth: '7em' }}>开始时间</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <DatePicker style={{ width: '100%', borderRadius: 4 }} locale={zhCN.DatePicker} showTime onChange={e => {
                                const startTime = e?.unix()
                                if (this.state.endTime && startTime && this.state.endTime < startTime) this.setState({ endTime: null })
                                this.setState({ startTime: e?.unix() })
                            }} />
                        </div>
                    </div>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>
                        <div style={{ minWidth: '7em' }}>结束时间</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <DatePicker style={{ width: '100%', borderRadius: 4 }} disabledDate={(date) => {
                                return date <= moment.unix(this.state.startTime || 0).endOf('day')
                            }} value={this.state.endTime ? moment.unix(this.state.endTime) : null} locale={zhCN.DatePicker} showTime onChange={e => {
                                this.setState({ endTime: e?.unix() })
                            }} />
                        </div>
                    </div>
                </div>

                <div className='fbc flexwrap'>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>
                        <div style={{ minWidth: '7em' }}>选择商品</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <Select uni='goodslist' placeholder={`请选择${this.state.tuanid ? '商品' : '活动'}`} maxHeight={300} list={this.state.goodlist} showKey='name'
                                onChange={e => {
                                    this.setState({
                                        goodsids: e.goodsid
                                    })
                                }} value={this.state.goodlist.findIndex(i => {
                                    if (i.goodsid === this.state.goodsids) return true
                                    return false
                                })} />
                        </div>
                    </div>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>
                        <div style={{ minWidth: '7em' }}>选择自提点</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <Select multi uni='goodslist' placeholder={`请选择${this.state.tuanid ? '自提点' : '活动'}`} maxHeight={300} list={this.state.addresslist} showKey='name'
                                onChange={(e, i) => {
                                    
                                    if (i === undefined) return
                                    let ks = this.state.selectedAddressIdxs
                                    if (i === 0) {
                                        ks = [0]
                                    } else {
                                        if (ks.includes(i)) {
                                            ks = ks.filter((item) => item !== i)
                                        } else {
                                            ks.push(i)
                                        }
                                        if (ks.length > 1) ks = ks.filter(item => item !== 0)
                                    }
                                    this.setState({
                                        selectedAddressIdxs: ks
                                    })
                                }} value={this.state.selectedAddressIdxs} />
                        </div>
                    </div>
                </div>

                <div className='fbc flexwrap'>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>
                        <div style={{ minWidth: '7em' }}>选择帮卖好友</div>
                        <div style={{ flex: 1, minWidth: '7em' }}>
                            <Select canSearch uni='sellerlist' placeholder={`请选择帮卖好友`} maxHeight={300} list={this.state.sellerlist} showKey='name'
                                onChange={e => {
                                    this.setState({
                                        selectedSellerId: e.id
                                    })
                                }} value={this.state.sellerlist.findIndex(i => {
                                    if (i.id === this.state.selectedSellerId) return true
                                    return false
                                })} />
                        </div>
                    </div>
                    <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>

                    </div>
                </div>

                <div className='fbc flexwrap'>
                    <div className='flexwrap' style={{ display: 'flex', padding: '0 20px 20px' }}>
                        <div onClick={() => { this.setState({ sort: 'asc' }) }}>
                            <Radio checked={this.state.sort === 'asc'}>最早订单排最前</Radio>
                        </div>
                        <div onClick={() => { this.setState({ sort: 'desc' }) }}>
                            <Radio checked={this.state.sort === 'desc'}>最晚订单排最前</Radio>
                        </div>
                    </div>
                    <div className='flexwrap' style={{ display: 'flex', padding: '0 20px 20px' }}>
                        <div onClick={() => { this.setState({ refund: false }) }}>
                            <Radio checked={!this.state.refund}>不导出全部退款订单</Radio>
                        </div>
                        <div onClick={() => { this.setState({ refund: true }) }}>
                            <Radio checked={this.state.refund}>导出所有订单（含全部退款订单）</Radio>
                        </div>
                    </div>
                </div>
                <Line />
                <div style={{ padding: '0 20px' }}>
                    <div style={{ color: '#999', fontSize: 12, margin: '20px 0' }}>选择要导出的表格类型(每次最多导出3种类型)：</div>
                    <div className='flexwrap' style={{ display: 'flex', }}>
                        {this.state.table.map((i: { key: number, name: string, value: boolean }, idx) => {
                            return <TableItem selected={i.value} key={i.key} type={i.key} onClick={this.tableCheck.bind(this, idx)}>
                                <i className={`iconfont ${i.value ? 'icon-gouxuan' : 'icon-yuan'}`} />
                                <div>{i.name}</div>
                            </TableItem>
                        })}
                    </div>
                </div>
                {/* <div style={{ padding: 20, paddingBottom: 0, paddingTop: 0 }} className='fcc'>
                    <button className='button_solid_red' onClick={this.export.bind(this)}>导出订单</button>
                </div> */}
            </Modal>

        )
    }
}

const mapStateToProps = (state: any,) => {
    return {
        user: state.login_user_data,
        order: state.order,
    }
}
export default withRouter(connect(mapStateToProps)((Export)))

function getUrl(address: string, params: { [key: string]: string | number } = {}): string {

    let url: string = address

    const keys: string[] = Object.keys(params)

    if (keys.length >= 1) {
        url += `${keys[0]}=${params[keys[0]]}`
        for (let i = 1; i < keys.length; i++) {
            const key = keys[i];
            const value = params[key]
            url += `&${key}=${value}`
        }
    }

    return url
}