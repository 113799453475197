import React, { useEffect, useState } from 'react'
import Modal from '../total/Modal'
import ReactDOM from 'react-dom'
import { get, post } from '../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
import BTN from '../../StyleComponents/Button'
import { confirm as Confirm } from '../Method/Confirm';
import Prompt from '../Method/Prompt'

interface props {
    onClose: () => void
    data: {

    }
}

interface category {
    name: string
    id: string | number
}

let wrapper: Element

const Ctrl = styled.div`
    text-align: right;
    span{
        padding: 0 8px;
        color: var(--theme-main);
        cursor: pointer;
    }
    span:not(:last-child){
        border-right: 1px #ccc solid;
    }
`
const Table = styled.table`
    border-radius: 8px;
    thead{
        background: #f5f5f5;
    }
    td{
        padding: 10px;
    }
    tr{
        border: 1px solid #f1f1f1;
    }
`

export default async function (sid: number | string, onCatChange?: (list: category[]) => void) {
    return new Promise<void>(async (reslove, reject) => {
        function Category(props: props) {

            const [newName, setNewName] = useState('')

            const [shouldClose, setShouldClose] = useState(false)

            const [list, setList] = useState<category[]>()

            useEffect(() => {
                if (!list) {
                    onCatChange && onCatChange(list)
                    get('/address/getpickuppointcategorylist', { sid: encodeURIComponent(btoa('' + sid)) }).then(res => {
                        setList(res.list)
                        onCatChange && onCatChange(res.list)
                    })
                }
            }, [list])

            return <Modal title='编辑分组' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <div className='flex-box' style={{ border: '1px solid #d9d9d9', borderRadius: 4, padding: 10, marginBottom: 16 }}>
                        <div style={{ marginRight: 10, }}>添加分组</div>
                        <input style={{ border: 0, flex: 1 }} maxLength={7} value={newName} placeholder='请输入分组名称' onChange={e => setNewName(e.target.value)} />
                        <BTN color='gray' onClick={() => {
                            post('/address/setpickuppointcategory', {
                                name: newName,
                                op: 'create'
                            }).then(() => {
                                message.success('添加成功')
                                setList(undefined)
                                setNewName('')
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }}>添加</BTN>
                    </div>
                    <Table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td style={{ width: 370 }}>分组名称</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(list || []).map((i, idx) => {
                                return <tr key={i.id}>
                                    <td>{i.name}</td>
                                    <td>
                                        <Ctrl>
                                            <span onClick={del.bind(i)}>删除</span>
                                            <span onClick={edit.bind(i)}>编辑</span>
                                            <span onClick={up.bind(i)}>上移一位</span>
                                            {idx !== 0 && <span onClick={top.bind(i)}>置顶</span>}
                                        </Ctrl>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
            </Modal>

            function del() {
                Confirm({
                    text: `确定删除该分组？`
                }).then(res => {
                    if (res) {
                        return post('/address/setpickuppointcategory', {
                            id: this.id,
                            op: 'delete',
                            name: this.name,
                        }).then(() => {
                            setList(undefined)
                            message.success('删除成功')
                        })
                    }
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            function edit() {
                const { id, name, } = this
                Prompt({
                    type: "string",
                    value: name,
                    title: '编辑分组名称',
                }).then((res) => {
                    return post('/address/setpickuppointcategory', {
                        op: 'update', id, name: res,
                    }).then(() => {
                        setList(undefined)
                        message.success('修改成功')
                    })
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            function up() {
                const { id, name, } = this

                return post('/address/setpickuppointcategory', {
                    op: 'rank', id, name, rank_type: "up"
                }).then(() => {
                    setList(undefined)
                    message.success('修改成功')
                }).catch(e => { if (e.message) message.error(e.message) })
            }
            function top() {
                const { id, name, } = this

                return post('/address/setpickuppointcategory', {
                    op: 'rank', id, name, rank_type: "top"
                }).then(() => {
                    setList(undefined)
                    message.success('修改成功')
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            function confirm() {
                setShouldClose(true)
                reslove()
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{ sid }} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}