import { API as baseURL, authkey, platform } from '../data/options';
import md5 from 'md5';
// import md5 from 'crypto-js/md5';
import { getToken } from '../netApi/fetch';
import Loading from '../component/Method/Loading';

/**
 * 
 * @param file 
 * @param type 群通知content 商品goods 商品图片选goods 团购详情图选tuan 商店头像选shop 
 */
export default function (file: File, uploadtype: 'content' | 'goods' | 'tuan' | 'shop') {
    return new Promise((res: (data: {id: string, src: string, uid: string}) => void, rej) => {
        Loading.show()
        let createtime = String(Math.floor(Date.now() / 1000))
        let token = md5(authkey + createtime)
        const host = `${baseURL}/file/upload`
        const data = new FormData()
        data.append('uploadtype', uploadtype)
        data.append('file', file)
        data.append('types', '1')
        data.append('token', getToken())
        fetch(host, {
            method: 'post',
            headers: {
                platform,
                token,
                createtime,
            },
            body: data,
        }).then(d => {
            return d.json()
        }).then(data => {
            Loading.hide()
            res(data.data.file)
        }).catch(err => {
            rej(err)
        })

    })
}

export function selectImage(config: {
    multi?: boolean
} = {}){
    return new Promise((res, rej) => {
        const lb = document.createElement('input')
        lb.type = 'file'
        if(config.multi){
            lb.multiple = true
        }
        lb.accept = 'image/*'
        lb.onchange = () => {
            res(lb.files)
        }
        lb.click()
    })
}