import React, { Component } from 'react'

import api from '../../netApi/index';

import Images from '../total/DraggableImgs';
import Loading from '../../high-component/Loading';

import { confirm } from '../Method/Confirm.tsx';
import VIPPower from '../index/method/VIPPower.tsx';
import { post } from '../../netApi/fetch';
// import { Menu, Dropdown, Icon } from 'antd';
import text from '../../data/text.ts';
import { storage } from '../../data/competence';

class Content extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.editting === true) return null 
        if ((nextProps.list && nextProps.list.length > 0) && prevState && (!prevState.list || prevState.list.length === 0)) {
            return {
                list: nextProps.list.map((i, index) => {
                    if (i.id) return i
                    return { ...i, id: -index }
                })
            }
        }

        return null
    }

    constructor() {
        super()
        this.state = {
            list: []
        }
    }

    componentDidMount() {
        post('/charge/checkmemberauth', { sid: false, auth_type: 5 }).then(res => {
            storage.set(!!res.auth)
        })
    }

    render() {
        return (<div style={{ position: 'relative' }}>
            {this.state.list.map((i, index) => {
                switch (Number(i.type)) {
                    case 3:
                        return (<div key={i.id}>
                            <div style={{ padding: '10px 0', color: '#353535' }} className='fcc'>
                                <span style={{ minWidth: '4em' }}>多 图</span>
                                <div style={{ flex: 1, }}>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.up.bind(this, index)} className='iconfont icon-shangyi'></i>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.down.bind(this, index)} className='iconfont icon-xiayi'></i>
                                </div>
                                <div>
                                    <i style={{ fontSize: 18, lineHeight: 1, color: '#777' }} className='iconfont icon-guanbi-tianchong' onClick={this.del.bind(this, i.id)}></i>
                                </div>
                            </div>
                            <Images imgtype='tuan' onChange={e => {
                                i.value = e
                                this.setState({ list: this.state.list })
                            }} images={i.value} width={70} height={70} />
                        </div>)
                    case 2:
                        return (<div key={i.id}>
                            <div style={{ padding: '10px 0', color: '#353535' }} className='fcc'>
                                <span style={{ minWidth: '4em' }}>单 图</span>
                                <div style={{ flex: 1, }}>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.up.bind(this, index)} className='iconfont icon-shangyi'></i>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.down.bind(this, index)} className='iconfont icon-xiayi'></i>
                                </div>
                                <div>
                                    <i style={{ fontSize: 18, lineHeight: 1, color: '#777' }} className='iconfont icon-guanbi-tianchong' onClick={this.del.bind(this, i.id)}></i>
                                </div>
                            </div>
                            <div>
                                <img alt='' src={i.value.src} style={{ maxWidth: '50%', maxHeight: 400 }} />
                            </div>
                        </div>)
                    case 1:
                        return (<div key={i.id}>
                            <div style={{ padding: '10px 0', color: '#353535' }} className='fcc'>
                                <span style={{ minWidth: '4em' }}>文 字</span>
                                <div style={{ flex: 1, }}>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.up.bind(this, index)} className='iconfont icon-shangyi'></i>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.down.bind(this, index)} className='iconfont icon-xiayi'></i>
                                </div>
                                <div>
                                    <i style={{ fontSize: 18, lineHeight: 1, color: '#777' }} className='iconfont icon-guanbi-tianchong' onClick={this.del.bind(this, i.id)}></i>
                                </div>
                            </div>
                            <div style={{ fontSize: 14, padding: '.8em', width: '100%', position: 'relative', whiteSpace: 'pre-wrap', wordBreak: 'break-all', minHeight: '4em' }}>
                                {i.value}
                                <textarea style={{ border: '1px solid rgb(201,201,201)', fontSize: 14, padding: '.7em', width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}
                                    value={i.value} onChange={(e) => {
                                        i.value = e.target.value
                                        this.setState({
                                            list: this.state.list
                                        })
                                    }} />
                            </div>

                        </div>)
                    case 4:
                        if (Number(i.istop) === 1) return null
                        return (<div key={i.id}>
                            <div style={{ padding: '10px 0', color: '#353535' }} className='fcc'>
                                <span style={{ minWidth: '4em' }}>视 频</span>
                                <div style={{ flex: 1, }}>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.up.bind(this, index)} className='iconfont icon-shangyi'></i>
                                    <i style={{ marginLeft: '.4em', fontSize: 24, lineHeight: 1 }} onClick={this.down.bind(this, index)} className='iconfont icon-xiayi'></i>
                                </div>
                                <div>
                                    <i style={{ fontSize: 18, lineHeight: 1, color: '#777' }} className='iconfont icon-guanbi-tianchong' onClick={this.del.bind(this, i.id)}></i>
                                </div>
                            </div>
                            <div>
                                <video alt='' src={i.value.origin_src} controls style={{ maxWidth: '50%', maxHeight: 400 }} />
                            </div>
                        </div>)
                    default:
                        return '';
                }
            })}
            <div style={{ marginTop: 40, textAlign: 'right' }}>
                <span style={{ position: 'relative', color: '#8E99AE', fontSize: 16, fontWeight: 500, }} onClick={() => {
                    this.setState({ showAddMenu: true })
                }}>
                    <span className='fcc cur-pointer' style={{ display: 'inline-block' }}>
                        <i className='iconfont icon-jiahao-tianchong' style={{ color: 'inherit' }}></i>添加图文
                    </span>
                    {this.state.showAddMenu ? (
                        <div onMouseLeave={() => {
                            if (!this.shouldHide) return
                            this.setState({ showAddMenu: false })
                        }} className='shadow-menu' style={{ position: 'absolute', right: 0, top: 0, background: '#fff', width: '6em', border: '1px solid #eee', textAlign: 'left' }}>
                            <div className='cur-pointer shadow-menu__item' onClick={e => {
                                e.stopPropagation()
                                this.setState({
                                    list: this.state.list.concat({ type: 1, value: '', id: -(this.state.list.length + 1) }),
                                    showAddMenu: false,
                                })
                            }}>添加文字</div>
                            {/* 添加文字 */}
                            {/* 添加单图 */}
                            <div className='cur-pointer' onClick={e => {
                                this.shouldHide = false
                                e.stopPropagation()
                            }}><label className='cur-pointer  shadow-menu__item' style={{ display: 'block', width: '100%', height: '100%', marginBottom: 0 }} onClick={e => {
                                console.log(storage);
                                if (!storage.get()) {
                                    this.setState({
                                        showAddMenu: false,
                                    })
                                    e.stopPropagation()
                                    e.preventDefault()
                                    confirm({
                                        text: text.ERROR.MEMBER.IMAGE,
                                        title: '温馨提示',
                                        confirmText: '去升级',
                                        cancelText: '暂不升级',
                                    }).then(res => {
                                        if (res) VIPPower({})
                                    })
                                }
                            }}>
                                    添加单图
                                <input onChange={(e) => {
                                        const image = e.target.files[0];
                                        this.shouldHide = true
                                        this.props.showLoading()
                                        const data = new FormData()
                                        data.append('file', image)
                                        data.append('types', 1)
                                        data.append('uploadtype', 'tuan')
                                        api('file', 'upload')({}, 'post', data, {
                                            headers: { 'Content-Type': 'multipart/form-data' }
                                        }).then(res => {
                                            if (Number(res.data.code) === 0) {
                                                const data = res.data.data.file
                                                this.setState({
                                                    list: this.state.list.concat({ type: 2, value: data, id: -(this.state.list.length + 1) }),
                                                    showAddMenu: false,
                                                })
                                                this.props.hideLoading()
                                            }
                                        })
                                    }} type='file' style={{ width: 0, height: 0, opacity: 0, display: 'none' }} accept='image/*' />
                                </label>
                            </div>
                            {/* 添加单图 */}
                            {/* 添加多图 */}
                            <div className='cur-pointer shadow-menu__item' onClick={e => {
                                e.stopPropagation()
                                if (!storage.get()) {
                                    e.preventDefault()
                                    this.setState({
                                        showAddMenu: false,
                                    })
                                    confirm({
                                        text: text.ERROR.MEMBER.IMAGE,
                                        title: '温馨提示',
                                        confirmText: '去升级',
                                        cancelText: '暂不升级',
                                    }).then(res => {
                                        if (res) VIPPower({})
                                    })
                                    return
                                }
                                this.setState({
                                    list: this.state.list.concat({ type: 3, value: [], id: -(this.state.list.length + 1) }),
                                    showAddMenu: false,
                                })
                            }}>添加多图</div>
                            {/* 添加多图 */}
                            {/* 添加视频 */}
                            <div className='cur-pointer' onClick={e => {
                                this.shouldHide = false
                                e.stopPropagation()
                            }}><label className='cur-pointer  shadow-menu__item' style={{ display: 'block', width: '100%', height: '100%', marginBottom: 0 }} onClick={e => {
                                if (!storage.get()) {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.setState({
                                        showAddMenu: false,
                                    })
                                    confirm({
                                        text: text.ERROR.MEMBER.VIDEO,
                                        title: '温馨提示',
                                        confirmText: '去升级',
                                        cancelText: '暂不升级',
                                    }).then(res => {
                                        if (res) VIPPower({})
                                    })
                                }
                            }}>
                                    添加视频
                                <input onChange={(e) => {
                                        const image = e.target.files[0];
                                        this.shouldHide = true
                                        this.props.showLoading()
                                        const data = new FormData()
                                        data.append('file', image)
                                        data.append('types', 1)
                                        data.append('uploadtype', 'video')
                                        api('file', 'upload')({}, 'post', data, {
                                            headers: { 'Content-Type': 'multipart/form-data' }
                                        }).then(res => {
                                            if (Number(res.data.code) === 0) {
                                                const data = res.data.data.file
                                                this.setState({
                                                    list: this.state.list.concat({ type: 4, value: data, id: -(this.state.list.length + 1) }),
                                                    showAddMenu: false,
                                                })
                                                this.props.hideLoading()

                                            }
                                        })
                                    }} type='file' style={{ width: 0, height: 0, opacity: 0, display: 'none' }} accept='video/*' />
                                </label>
                            </div>
                            {/* 添加视频 */}
                        </div>
                    ) : ''}
                </span>
            </div>
        </div>)
    }

    del(id) {
        this.setState({
            list: this.state.list.filter(i => i.id !== id),
            editting: true,
        })
    }

    up(index) {
        if (index === 0) return
        const list = this.state.list
        const target = list[index]
        const flist = list.filter(i => i.id !== target.id)
        this.setState({
            list: [...flist.slice(0, index - 1), target, ...flist.slice(index - 1)]
        })
    }

    down(index) {
        if (index === this.state.list.length) return
        const list = this.state.list
        const target = list[index]
        const flist = list.filter(i => i.id !== target.id)
        this.setState({
            list: [...flist.slice(0, index + 1), target, ...flist.slice(index + 1)]
        })
    }
}
export default Loading(Content)

