import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { showLoading } from '../../reducers/index'
import Main from '../../high-component/Content'
import { Head, Filter, MainPage, Page } from '../../StyleComponents/SimpleFilterList';

import { get } from '../../netApi/fetch';
import parse from '../../methods/paramParse';

import Item from './listItem';
import Search from '../total/Input/Search';
import Select from '../total/Select_';
import { message, Pagination } from 'antd';

interface Props extends RouteComponentProps {
    showLoading: () => void,
    hideLoading: () => void,
    login_user_data: LoginUserData,
}

interface State {
    getData: {
        type: number,
        sort: string,
        search: string,
        page: number,
    },
    searchKey: string,
    data?: followList
}

interface followList {
    count: number,
    fensi_total: number,
    follow_total: number,
    supply_tuan_count: number,
    list: followListItem[]
}

export interface followListItem {
    shopinfo: shopinfo,
    views_total: number,  // 浏览过我的活动的总次数
    join_total: number, // 参与我的活动的总次数
    payprice_total: number, // 参与我的活动的总金额
    last_view_time: string, // 他最近浏览我这个店的日期
    sid: number,
    info: string, // 信息
    isblacklist: boolean, // 黑名单
    ismyadmin: boolean, // 管理员
    ismyseller: boolean, // 帮卖好友
    shopgradeuserdiscount: number, // 个人折扣
    shopgradeuserclassid: string | number, // 等级id或选级id
    activity_total: number,
    createtime: string,
    goods_total: number,
    id: number | string,
    isshopgradeuser: boolean,
    status: string | number,
    uid: number | string,
    updatetime: string,
}
export interface level {
    discount: number,
    discount_name: string,
    id: string | number,
    name: string,
    type: number, // 类型 0消费金额 1参团次数	
    number: number, // 0金额 1次数
    status: number, //	0下架 1正常
}

class List extends Component<Props>{

    readonly state: State = {
        getData: {
            type: 1,
            sort: 'desc',
            search: '',
            page: 1
        },
        searchKey: '',
    }

    type = [
        { type: 1, name: '浏览次数' },
        { type: 2, name: '参团次数' },
        { type: 3, name: '消费总额' },
        { type: 4, name: '最近浏览' },
    ]

    componentDidMount() {
        this.parseParam().then(() => {
            this.getList()
        })
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.location.search !== this.props.location.search || (!prevProps.login_user_data.self_sid && this.props.login_user_data.self_sid)) {
            this.parseParam().then(() => {
                this.getList()
            })
        }
    }

    render() {
        return <div style={{ position: "relative" }}><MainPage>
            <Head className='flex-between'>
                <div style={{ fontSize: 20, fontWeight: 600 }}>{this.state.data?.count}人关注我</div>
                <Search outerStyle={{ width: '20em' }} placeholder='搜索用户微信昵称或小程序昵称' search={this.state.searchKey} onChange={(e: string) => this.setState({ searchKey: e })} onSearch={() => {
                    this.props.history.push(`/follow/list?page=1&search=${this.state.searchKey}&sort=${this.state.getData.sort}&type=${this.state.getData.type}`)
                }} />
            </Head>
            <Filter className='flex-box'>
                <div className='flex-box'>
                    <div style={{ fontWeight: 600 }}>排序方式</div>
                    <Select list={this.type} uni='followType' inputStyle={{
                        color: 'var(--theme-main)', background: 'transparent', border: 0, width: '7em', fontWeight: 600
                    }} showKey='name' onChange={e => {
                        this.props.history.push(`/follow/list?page=1&search=${this.state.getData.search}&sort=${this.state.getData.sort}&type=${e.type}`)
                    }} value={this.type.findIndex(i => i.type === this.state.getData.type)} />
                </div>
                <div className='cur-pointer' style={{ color: 'var(--theme-main)', fontWeight: 600, marginLeft: 30 }} onClick={() => {
                    this.props.history.push(`/follow/list?page=1&search=${this.state.getData.search}&sort=${this.state.getData.sort === 'asc' ? 'desc' : 'asc'}&type=${this.state.getData.type}`)
                }}>
                    <span>
                        {this.state.getData.sort === 'asc' ? '从少到多' : ''}
                        {this.state.getData.sort === 'desc' ? '从多到少' : ''}
                    </span>
                    <i className='iconfont icon-paixu1' style={{ fontSize: 14, }} />
                </div>
            </Filter>
            <div>
                {this.state.data?.list.map((i: followListItem) => {
                    return <Item reload={this.getList.bind(this)} key={i.id} data={i} isMyself={Number(this.props.login_user_data.self_sid) === Number(this.props.login_user_data.sid)} sid={this.props.login_user_data.self_sid} />
                })}
            </div>
            <Page>
                <div className='flex-between'>
                    <div></div>
                    <Pagination current={this.state.getData.page} pageSizeOptions={[]} total={this.state.data?.count} pageSize={20} onChange={e => {
                        this.props.history.push(`/follow/list?page=${e}&search=${this.state.getData.search}&sort=${this.state.getData.sort}&type=${this.state.getData.type}`)
                    }} />
                </div>
            </Page>
        </MainPage></div>
    }

    getList() {
        const data = {
            ...this.state.getData,
            types: 1,
            op: 1,
            limit: 20,
            offset: 0,
        }
        data.offset = (data.page - 1) * data.limit
        delete data.page
        this.props.showLoading()
        get('/follow/getfollowlist', data).then((res: any) => {
            this.props.hideLoading()
            this.setState({
                data: {
                    fensi_total: Number(res.fensi_total),
                    follow_total: Number(res.follow_total),
                    count: Number(res.count),
                    supply_tuan_count: Number(res.supply_tuan_count),
                    list: res.list.map((i: any): followListItem => {
                        return {
                            activity_total: Number(i.activity_total),
                            createtime: i.createtime,
                            goods_total: Number(i.goods_total),
                            id: Number(i.id),
                            info: i.info,
                            isblacklist: Boolean(Number(i.isblacklist)),
                            ismyadmin: Boolean(Number(i.ismyadmin)),
                            ismyseller: Boolean(Number(i.ismyseller)),
                            isshopgradeuser: Boolean(Number(i.isshopgradeuser)),
                            join_total: Number(i.join_total),
                            last_view_time: i.last_view_time,
                            payprice_total: Number(i.payprice_total),
                            shopgradeuserclassid: Number(i.shopgradeuserclassid),
                            shopgradeuserdiscount: Number(i.shopgradeuserdiscount),
                            shopinfo: {
                                fensi_total: Number(i.shopinfo.fensi_total),
                                follow_total: Number(i.shopinfo.follow_total),
                                id: Number(i.shopinfo.id),
                                logo: String(i.shopinfo.logo),
                                name: String(i.shopinfo.name),
                                uid: Number(i.shopinfo.uid),
                                member_level_info: i.member_level_info,
                            },
                            sid: Number(i.sid),
                            status: i.status,
                            uid: i.uid,
                            updatetime: i.updatetime,
                            views_total: Number(i.views_total),
                        }
                    })
                }
            })
        }).catch((e: any) => {
            this.props.hideLoading()
            if (e.message) message.error(e.message)
        })
    }

    getLevel() {
        get('/shopgrade/getshopgradelist', {
            types: 1,
            offset: 0,
            limit: 99999
        }).then((res: any) => {

        })
    }

    parseParam() {
        return new Promise<void>((res, rej) => {
            const params = parse(this.props.location.search)
            const data = {
                type: 1,
                sort: 'desc',
                search: '',
                page: 1
            }
            if (params.type) data.type = Number(params.type)
            if (params.sort) data.sort = String(params.sort)
            if (params.search) data.search = String(params.search)
            if (params.page) data.page = Number(params.page)
            this.setState({
                getData: data,
                searchKey: data.search
            }, () => {
                res()
            })
        })
    }
}

const mapStateToProps = (state: any,) => {
    return {
        is_Login: state.is_Login,
        waitingLogin: state.waitingLogin,
        login_user_data: state.login_user_data,
    }
}
const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
    return {
        showLoading: () => {
            dispatch(showLoading(true, '加载中...'))
        },
        hideLoading: () => {
            dispatch(showLoading(false))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)((Main(List, false)))
