import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Audio_ extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
    }

    constructor(p) {
        super()
        this.state = {
            data: []
        }
        // console.log(this.props)
        const data = new Audio(p.src)
        data.oncanplaythrough = () => {
            this.setState({
                data: [data]
            }, () => {
            })
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div>
                {this.state.data.map(i => {
                    return (
                        <div key={i.src} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }} >
                            <audio key={i.src} src={i.src} onTimeUpdate={(e) => {
                                // console.log(e)
                            }} controls controlsList="nodownload nofullscreen noremoteplayback" />
                            {/* <div>
                            <i className='iconfont icon-bofangzanting'></i>
                            <i className='iconfont icon-bofangzanting1'></i>
                        </div> */}
                            {/* <div style={{position: 'absolute',bottom: 0 ,height: 3}}>
                            <div style={{position:'absolute',top:0,left:0,height: '100%',background:'#fd5356',width: 23}}>

                            </div>
                        </div> */}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Audio_