import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withRouter, } from 'react-router-dom';

class Good extends Component {
    static propTypes = {
        good: PropTypes.object.isRequired,
        num: PropTypes.array.isRequired,
        change: PropTypes.func
    }
    constructor(p) {
        super()
        this.state = {
            num: p.num.reduce(((acc, cur) => acc + cur.num), 0)
        }
        let saleout = true
        for (let i = 0; i < p.good.attribute.length; i++) {
            const attr = p.good.attribute[i];
            if (Number(attr.isunlimited) === 1 || Number(attr.quantity) > 0) {
                saleout = false
            }
        }
        this.state.saleout = saleout
    }
    componentDidMount() {
        this.setState({
            showPrice: this.getShowPrice()
        })
    }
    componentDidUpdate() {
        const prenum = this.state.num
        if (this.props.num.reduce(((acc, cur) => acc + cur.num), 0) !== Number(prenum)) {
            this.setState({
                num: this.props.num.reduce(((acc, cur) => acc + cur.num), 0)
            })
        }
    }
    render() {
        return (
            <div className='fcc' style={{
                marginBottom: 20
            }}>
                <div
                    style={{
                        width: '24vw',
                        height: '24vw',
                        maxWidth: 80,
                        maxHeight: 80,
                        position: 'relative',
                    }}>
                    <img
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                        alt={this.props.good.name}
                        src={this.props.good.logo + '/sm'}
                        className='img-center' />
                    {this.state.saleout ? (
                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, background: 'rgba(225, 225, 225, 0.6)' }} className='fcc'>
                            <div style={{ borderRadius: '50%', width: '5em', height: '5em', background: 'rgba(51, 51, 51, 0.6)', color: 'white', fontSize: 12, lineHeight: 1 }} className='fcc'>已售空</div>
                        </div>
                    ) : ''}
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        marginLeft: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        // height: '24vw',
                        // maxHeight: 80,
                    }}>
                    <div
                        style={{
                            color: '#343434',
                            fontSize: 16,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical'
                        }}>{this.props.good.name}</div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            color: '#878787',
                            fontSize: 12, marginRight: 12,
                        }}>已团{this.props.good.salesnum}</div>
                        {this.props.good.attribute.length === 1 && <>
                            <div style={{
                                color: '#878787',
                                fontSize: 12, marginRight: 12,
                            }}>库存{this.props.good.attribute[0].quantity}</div>
                            {/* {this.props.good.attribute[0].mall_quantity && <div style={{
                                color: '#878787',
                                fontSize: 12, marginRight: 12,
                            }}>{this.props.isHema ? '盒马': ''}商城库存{this.props.good.attribute[0].mall_quantity}</div>}
                            {this.props.good.attribute[0].mall_price && <div style={{
                                color: '#878787',
                                fontSize: 12, marginRight: 12,
                            }}>{this.props.isHema ? '盒马': ''}商城价格￥{(Number(this.props.good.attribute[0].mall_price) / 100).toFixed(2)}</div>} */}
                            {Number(this.props.good.start_count) > 1 && <div style={{
                                color: '#878787',
                                fontSize: 12, marginRight: 12,
                            }}>起购数量{this.props.good.start_count}</div>}
                            {this.props.good.limit_count && <div style={{
                                color: '#878787',
                                fontSize: 12, marginRight: 12,
                            }}>限购数量{this.props.good.limit_count}</div>}
                            {this.props.good.attribute[0].increment && <div style={{
                                color: '#878787',
                                fontSize: 12, marginRight: 12,
                            }}>步长{this.props.good.attribute[0].increment}</div>}
                        </>}
                    </div>
                    <div className='fbc'>
                        <div
                            style={{
                                color: '#FF9A27',
                                fontSize: 16
                            }}>￥{this.state.showPrice}</div>
                        <div className='fcc'>
                            <i style={{ color: 'var(--theme-main)', fontSize: 20 }} className='iconfont icon-jianhao-tianchong'></i>
                            <span style={{
                                display: 'inline-block',
                                fontSize: 20,
                                lineHeight: '20px',
                                minWidth: 20,
                                textAlign: 'center',
                                margin: '0 .5em'
                            }}>
                                {this.state.num}
                            </span>
                            <i style={{ color: 'var(--theme-main)', fontSize: 20 }} className='iconfont icon-jiahao-tianchong'></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    getShowPrice() {
        let max,
            min
        for (let i = 0; i < this.props.good.attribute.length; i++) {
            const attr = this.props.good.attribute[i];
            const price = attr.distribution_price ? attr.distribution_price.nowprice : attr.price
            if (!max || price >= max) {
                max = price
            }
            if (!min || price <= min) {
                min = price
            }
        }
        min = (min / 100).toFixed(2)
        max = (max / 100).toFixed(2)
        if (min === max) {
            return min
        } else {
            return `${min}~${max}`
        }
    }
}

export default withRouter(Good)