let input, success_func, fail_func
function onBridgeReady() {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke(
        'getBrandWCPayRequest', input,
        function (res) {
            // eslint-disable-next-line eqeqeq
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                success_func && success_func(res)
            } else {
                fail_func && fail_func(res)
            }
        });
}

export default function (data, success, fail) {
    input = data
    success_func = success
    fail_func = fail
    if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
    } else {
        onBridgeReady();
    }
}