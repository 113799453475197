import React, { Component } from 'react'

class Success extends Component {

    constructor(p) {
        super()
        this.state = p.location.state
        this.state.lou = this.state.data.orders + 1
        this.tuanid = window.btoa(this.state.data.id)
    }

    render() {
        return (
            <div>
                {/* 背景 */}
                <div className='fixedBG' style={{ background: '#F7F8F9' }}></div>

                {/* success */}
                <div className='fcc' style={{ flexDirection: 'column', minHeight: 200, paddingTop: 90 }}>
                    <i className='iconfont icon-gouxuan' style={{ color: '#FD5356', marginBottom: 20, fontSize: 70, lineHeight: 1 }}></i>
                    <div style={{ color: '#333', fontSize: 18 }}>参团成功</div>
                </div>

                {/* 几楼 哪位 */}
                <div className='fcc' style={{ padding: 20, background: '#fff', margin: '50px 20px', paddingLeft: 10 }}>
                    <div style={{ background: '#FFF6F6', color: 'var(--theme-main)', fontSize: 12, padding: '4px 11px', borderRadius: '1.5em', }}>{this.state.lou}楼</div>
                    <img alt={this.state.user.name} src={this.state.user.avatar} className='img-center' style={{ borderRadius: '50%', width: 40, height: 40, margin: '0 12px' }} />
                    <div className='single-text-hidden' style={{ flex: 1, fontSize: 12, maxWidth: '10em' }}>{this.state.user.name}</div>
                    <div style={{ background: '#FFF6F6', color: 'var(--theme-main)', fontSize: 12, padding: '4px 11px', borderRadius: '1.5em', }}>已参团+1</div>
                </div>

                {/* 返回 */}
                <div className='fcc' style={{ color: '#fff', background: '#FD5356', fontSize: 18, borderRadius: 10, padding: 12, margin: '0 20px' }} onClick={() => {
                    this.props.history.replace(`/tuan/${this.tuanid}`)
                }}>
                    回到活动页面
                </div>
            </div>
        )
    }
}

export default Success