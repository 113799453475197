import axios from 'axios';
// import qs from 'qs';
import { API as baseURL, authkey, platform } from '../data/options';
import md5 from 'md5';
// import md5 from 'crypto-js/md5';
import { getToken } from './fetch.ts';

axios.interceptors.response.use((res) => {
  if (Number(res.data.code) !== 0) {
    if (res.config.url.split('/')[res.config.url.split('/').length - 1] !== 'checkIsLogin' && Number(res.data.code) !== -1030) {
      // console.log()
      alert(res.data.message);
    }
    const pathname = window.location.pathname;
    if (['/home'].findIndex((i) => new RegExp(i).test(pathname)) === -1) {
      if (Number(res.data.code) === -1030) {
        window.location.href = '/home';
      }
    }
  }
  return res;
});

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

const api = function (sub, address) {
  /**
   *
   * @param {url参数} params
   * @param {request method} method
   * @param {post Data} data
   * @param {其他配置} config
   */
  function request(params = {}, method = 'get', data = {}, c = {}) {
    // if(!params.types && !data.types){
    //     if(method === 'get'){
    //         params.types = 1
    //     }else if(method === 'post'){
    //         data.types = 1
    //     }
    // }
    const config = {
      headers: {
        platform: 'h5',
      },
      ...c,
    };
    if ((!c.headers || !c.headers['Content-Type']) && method === 'get') {
      if (!config.headers) {
        config.headers = {};
      }
      let createtime = String(Math.floor(Date.now() / 1000));
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      config.headers['token'] = md5(authkey + createtime);
      config.headers['platform'] = platform;
      config.headers['createtime'] = createtime;
    }
    const url = `/${sub}/${address}`;
    const requestData = {
      url,
      method,
      baseURL,
      params,
      data,
      withCredentials: true,
      ...config,
    };
    const p = { ...params };
    const d = { ...data };
    if (method === 'get') {
      if (!p.token) {
        p.token = getToken();
        requestData.params = p;
      }
    }
    if (method === 'post') {
      if (!d.token && !(data.get && data.get('token'))) {
        if (data.append) {
          data.append('token', getToken());
          requestData.data = data;
        } else {
          d.token = getToken();
          requestData.data = d;
        }
      }
    }

    return axios.request(requestData);
  }
  return request;
};
export default api;
export { baseURL };
