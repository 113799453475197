import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleLoginPanel, setIsInvite } from '../../reducers/index'

import Loading from '../total/Loading';

import Main from '../../high-component/Content'
import BanUnLogin from '../../high-component/BanUnLogin'
import Wx_sign from '../../high-component/Wx_sign'

import api from '../../netApi'

import pay from '../../data/pay';

import gold_service from '../../images/gold_service.png';
import diomond_service from '../../images/diomond_service.png';
import high_member from '../../images/high_member.png';
import low_member from '../../images/low_member.png';

import p_1 from '../../images/p_1.png';
import p_2 from '../../images/p_2.png';
import p_3 from '../../images/p_3.png';
import p_4 from '../../images/p_4.png';
import p_5 from '../../images/p_5.png';
import p_6 from '../../images/p_6.png';

class Payment extends Component {
    constructor(p) {
        super()
        const type = p.match.params.type
        if (p.isinvite) {
            p.setIsInvite(false)
        }

        if (type !== 'member' && type !== 'service') {
            p.history.replace('/')
        }
        let typeText, typeNumber
        if (type === 'member') {
            typeText = '会员'
            typeNumber = 1
            api('proxy', 'accountConfigList')().then((data) => {
                this.setState({
                    data: data.data.data.filter( i => Number(i.type) !== 6),
                    checkItem: data.data.data[0]
                })
            })
            document.title = '升级接力go会员'
        }
        if (type === 'service') {
            typeText = '服务商'
            typeNumber = 2
            api('proxy', 'proxyConfigList')().then((data) => {
                this.setState({
                    data: data.data.data,
                    checkItem: data.data.data[0]
                })
            })
            document.title = '升级接力go代理商'
        }


        this.state = {
            type, typeText, typeNumber, data: [],
            loading: true, isready: false,
            activeIndex: 0,
        }
        const ua = navigator.userAgent.toLowerCase();
        if (!(/MicroMessenger/i).test(ua)) {
            alert('请使用微信打开本页面')
            p.history.goBack()
            // p.history.replace('/')
            return
        }
        if (p.canReady) {
            this.ready()
        }

        this.checkLogin(p)


    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
        document.title = '接力go'
    }
    componentDidMount() {

    }
    componentDidUpdate() {
        // console.log(this.props)
        if (this.props.canReady && !this.state.isready) {
            this.ready()
        }
    }
    checkLogin(props) {
        if (!props.waitingLogin) {
            if (!props.is_Login) {
                props.showLogin()
                return false
            }
        }
        return true
    }
    closeLoading() {
        this.setState({
            loading: false
        })
    }
    stop(e) {
        e.preventDefault()
    }
    topay(i, item) {
        const _this = this
        // console.log(item)
        if (!this.checkLogin(this.props)) return
        if (this.props.location.state && this.props.location.state.isinvite) return
        let type = Number(item.type)
        this.setState({
            loading: true
        })
        api('proxy', 'bind')({
            type: 1
        }, 'post', {
            proxy_type: type,
            intiver: this.props.match.params.sid
        }).then((res) => {
            // console.log(res.data.code,res.data.code!==-1044)
            if (Number(res.data.code) !== 0 && Number(res.data.code) !== -1044) return
            api('proxyorder', 'create')({}, 'post', {
                proxy_type: type,
                total_fee: item.annual_fee || item.price
            }).then((r) => {
                this.setState({
                    loading: false
                })
                if (Number(r.data.code) !== 0) return
                // console.log(r)
                pay(r.data.data.jsparams, (payrequest) => {
                    let logText = '支付成功'
                    switch (type) {
                        case 1:
                            logText += ',您已成功开通黄金代理'
                            break;
                        case 2:
                            logText += ',您已成功开通钻石代理'
                            break;
                        case 3:
                            logText += ',您已成功开通初级账号'
                            break
                        case 4:
                            logText += ',您已成功开通高级账号'
                            break
                        default:
                            break;
                    }
                    alert(logText)
                    if (_this.state.type === 'service') {
                        const data = {
                            pathname: '/service/success',
                            state: { type }
                        }
                        _this.props.history.push(data)
                    } else if (_this.state.type === 'member') {
                        const data = {
                            pathname: '/member/success',
                            state: { type }
                        }
                        _this.props.history.push(data)
                    }
                })
            })
        })
    }
    ready() {
        const _this = this
        const type = this.props.match.params.type
        // eslint-disable-next-line no-undef
        wx.ready(function () {
            const text = {
                title: '',
                desc: ''
            }
            if (type === 'service') {
                text.title = '给你分享接力go的黄金代理商升级链接'
                text.desc = '升级代理商，能力多多，收入多多'
            } else if (type === 'member') {
                text.title = '分享给你接力go的高级会员'
                text.desc = '开通会员，社群裂变更快，功能更丰富'
            }
            _this.closeLoading()
            _this.setState({
                isready: true
            })
            // eslint-disable-next-line no-undef
            wx.updateAppMessageShareData({
                // title: `邀请你成为${typeText}`, // 分享标题
                // desc: '', // 分享描述
                ...text,
                link: window.location.href.split('?')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://tuangou-1259536745.picgz.myqcloud.com/uploads/tuan/sOe8222ehX.png', // 分享图标
            })
        });
    }
    render() {
        return (
            <div 
                id='payment'
                style={{
                    backgroundImage: this.state.type === 'service' ? 'url(https://tuangou-1259536745.picgz.myqcloud.com/uploads/goods/mXdmD4z1Dj.png)' : 'url(https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/87AXVWxayV.jpg)'
                }}>
                <div className='fcc' style={{
                    zIndex: 10
                }}>
                    {this.state.data[0] && this.state.data.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                style={{
                                    maxWidth: 450,
                                    padding: 20,
                                    background: '#fff',
                                    flexDirection: 'column',
                                    zIndex: 10,
                                    margin: 20,
                                    boxShadow: '0 8px 19px 0 rgba(219,219,219,0.50)',
                                }}
                                className={`fcc type ${this.state.activeIndex === index ? 'checked' : ''}`}
                                onClick={() => {
                                    this.setState({
                                        activeIndex: index,
                                        checkItem: item
                                    })
                                }}
                            >
                                {this.state.type === 'service' ? (
                                    <img alt='' src={Number(item.type) === 1 ? gold_service : diomond_service}></img>
                                ) : (
                                        <img alt='' src={Number(item.type) === 3 ? low_member : high_member}></img>
                                    )}
                                <span style={{ margin: '1rem 0' }}>{item.name || '代理'}</span>
                                <div>
                                    <span>￥</span>
                                    <span>{((item.annual_fee || item.price) / 100)}</span>
                                    <span>/年</span>
                                </div>
                                {this.state.type === 'service' ?
                                    (
                                        <div>
                                            <div style={{ textAlign: 'center', fontSize: 14, color: '#848484', margin: '10px 0' }}>赠送高级版账号({item.senior_account}个/年)</div>
                                            <div style={{ textAlign: 'center', fontSize: 14, color: '#848484', margin: '10px 0' }}>赠送初级版账号({item.primary_account}个/年)</div>
                                        </div>
                                    )
                                    : ''}
                                {/* <button onClick={this.topay.bind(this,index,item)} style={{background: 'var(--theme-main)', color: '#fff',padding:'4px 11px',borderRadius: 4,fontSize:12,lineHeight: '21px'}}>立即开通>>></button> */}
                            </div>
                        )
                    })}
                </div>
                {this.state.checkItem ? (
                    <div style={{
                        position: 'fixed', bottom: '0', width: '100vw', padding: 20, zIndex: 20, paddingBottom: 10
                    }}>
                        <button onClick={this.topay.bind(this, this.state.activeIndex, this.state.checkItem)}
                            style={{
                                background: 'var(--theme-main)', color: '#fff', padding: '4px 11px', borderRadius: 12, fontSize: 16, lineHeight: '32px', width: '100%'

                            }}>支付￥{((this.state.checkItem.annual_fee || this.state.checkItem.price) / 100)},立即开通</button>
                    </div>
                ) : ''}

                <div className='title'>
                    {this.state.type === 'service' ? '服务商特权' : 'VIP特权'}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', }} className='power'>
                    <div>
                        <div className='fcc'>
                            <img src={p_1} alt=''></img>
                        </div>
                        <div>工具插件</div>
                    </div>
                    <div>
                        <div className='fcc'>
                            <img src={p_2} alt=''></img>
                        </div>
                        <div>交易费率</div>
                    </div>
                    <div>
                        <div className='fcc'>
                            <img src={p_3} alt=''></img>
                        </div>
                        <div>管理员数量</div>
                    </div>
                    <div>
                        <div className='fcc'>
                            <img src={p_4} alt=''></img>
                        </div>
                        <div>帮买好友数量</div>
                    </div>
                    <div>
                        <div className='fcc'>
                            <img src={p_5} alt=''></img>
                        </div>
                        <div>小程序高级功能</div>
                    </div>
                    <div>
                        <div className='fcc'>
                            <img src={p_6} alt=''></img>
                        </div>
                        <div>PC端高级功能</div>
                    </div>
                </div>
                <div className='title'>
                    套餐详细功能介绍
                </div>
                <div className='tableWrap'>
                    {this.state.type === 'member' ? (
                        <table>
                            <thead>
                                <tr>
                                    <td>特权阶级</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.name || '会员'}
                                            </td>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>工具插件</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.func_name && item.func_name.join('、')}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>交易费率</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.rate}%
                                        </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>管理员数量</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.manage_num}个
                                        </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>帮买好友数量</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.distri_num}个
                                        </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>小程序高级功能</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.pro_senior_func_name && item.pro_senior_func_name.join('、')}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>PC端高级功能</td>
                                    {this.state.data.map(item => {
                                        return (
                                            <td key={item.id}>
                                                {item.pc_senior_func_name && item.pc_senior_func_name.join('、')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <td>特权阶级</td>
                                        {this.state.data.map(item => {
                                            return (
                                                <td key={item.id}>
                                                    {item.name || '代理'}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>赠送高级版账号</td>
                                        {this.state.data.map(item => {
                                            return (
                                                <td key={item.id}>{item.senior_account}个/年</td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>赠送初级版账号</td>
                                        {this.state.data.map(item => {
                                            return (
                                                <td key={item.id}>{item.primary_account}个/年</td>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        )}
                </div>
                {(this.props.login_user_data && Number(this.props.login_user_data.sid) === Number(this.props.match.params.sid) && !this.state.loading) ? (<div
                    style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1000, background: 'rgba(0,0,0,.6)', }}
                    className='fcc'>
                    <i className='iconfont icon-quxianjiantou' style={{
                        position: 'fixed', right: 0, top: 0, fontSize: 90, color: '#fff', lineHeight: '90px'
                    }}></i>
                    <pre style={{ color: 'white' }}>
                        请点击右上角将此页面分享给你要邀请的人
                    </pre>
                </div>) : ''}
                {this.state.loading ? (
                    <Loading />
                ) : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        is_Login: state.is_Login,
        login_user_data: state.login_user_data,
        waitingLogin: state.waitingLogin,
        isinvite: state.isinvite,
        wx_sign: state.wx_sign
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showLogin: () => {
            dispatch(toggleLoginPanel(true))
        },
        setIsInvite: (v) => {
            dispatch(setIsInvite(v))
        }
    }
}
export default BanUnLogin(Wx_sign(Main(connect(mapStateToProps, mapDispatchToProps)(Payment))))