import React, { Component } from 'react'


export default (Warp) => {
    class LoadingWarp extends Component {
        constructor() {
            super()
            this.state = {
                Loading: false
            }
        }
        render() {
            const { forwardedRef, ...rest } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <Warp {...rest} ref={forwardedRef} showLoading={() => { this.setState({ Loading: true }) }} hideLoading={() => { this.setState({ Loading: false }) }} />
                    {this.state.Loading ? <Loading /> : ''}
                </div>
            )
        }
    }
    return React.forwardRef((props, ref) => {
        return <LoadingWarp {...props} forwardedRef={ref} />;
    })
}

class Loading extends Component {
    render() {
        return (<div className='fcc' style={{ position: 'absolute', width: '100%', top: 0, left: 0, height: '100%', background: 'rgba(255,255,255,.6)' }}>
            <i className='iconfont icon-xingzhuang' style={{ fontSize: 32, color: 'black' }}></i>
        </div>)
    }
}