import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, match } from 'react-router-dom'
import parseParams from '../../methods/paramParse';
import { get } from '../../netApi/fetch';
import Loading from '../Method/Loading';
import Item1 from './PrintItem/Item1';
import { Table as T } from '../../StyleComponents/PrintTable';
import moment from 'moment';
import styled from 'styled-components';
import BTN from '../../StyleComponents/Button';

const Light = styled.div`
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #000000;
    letter-spacing: 0px;
`

interface props extends RouteComponentProps {
    match: m,
}

interface m extends match {
    params: {
        type: string
    }
}

const Table = styled(T)`
    max-width: 1082px;
    page-break-after: always;
`

const B = styled(BTN)`
    position: fixed;
    top: 200px;

    @media (min-width: 1250px){
        left: calc(50vw + 560px);
    }
`

function Print(props: props) {

    const [search,] = useState(parseParams(props.location.search))
    const [type] = useState(Number(props.match.params.type))

    const [list1, setlist1] = useState<any[]>([])
    const [list2, setlist2] = useState<any[]>([])
    const [mallname2, setmallname2] = useState('')
    const [list3, setlist3] = useState<any[]>([])
    useEffect(() => {
        Loading.show()
        get('/mall/getmallpickuppointdeliveryorder', search).then(res => {
            Loading.hide()
            if (type === 1) {
                setlist1(res.list)
            }
            if (type === 2) {
                setlist2(res.list)
                setmallname2(res.mall_name)
            }
            if ([3, 4].includes(type)) {
                setlist3(res.list)
            }
        }).catch(e => {
            
            if (e.message) message.error(e.message)
            Loading.hide()
        })
    }, [search, type])

    // 消费者面单
    if (props.match.params.type === '1') {
        return <div>
            <B solid color='red' style={{ left: 360 }} onClick={window.print} >打印</B>

            {list1.map((i, idx) => {
                return <Item1 item={i} key={idx} />
            })}
        </div>
    }

    // 商品总单
    if (props.match.params.type === '2') {
        return <div>
            {/* 门店拣货单 */}
            <B solid color='red' onClick={window.print} >打印</B>
            <Table>
                <thead>
                    <tr>
                        <td colSpan={6}>【{mallname2}】 商品总单</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #000', width: '7em' }}>商品条码</td>
                        <td style={{ border: '1px solid #000', }}>商品名称</td>
                        <td style={{ border: '1px solid #000', }}>商品规格</td>
                        <td style={{ border: '1px solid #000', width: '4em' }}>数量</td>
                        <td style={{ border: '1px solid #000', }}>退款中</td>
                        {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                    </tr>
                </thead>
                <tbody>
                    {list2.map((i, idx) => {
                        return <tr key={idx}>
                            <td style={{ border: '1px solid #000', width: '7em' }}>{i.bar_code}</td>
                            <td style={{ border: '1px solid #000', }}>{i.goods_name}</td>
                            <td style={{ border: '1px solid #000', }}>{i.unit}</td>
                            <td style={{ border: '1px solid #000', width: '4em' }}>{i.num}</td>
                            <td style={{ border: '1px solid #000', }}>{i.refund_apply_num}</td>
                            {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                            {/* <td style={{ border: '1px solid #000', width: '4em' }}>{(a.price / 100).toFixed(2)}</td> */}
                        </tr>
                    })}
                    <tr>
                        <td colSpan={3}>总计</td>
                        <td>{list2.reduce((prev, item) => Number(item.num) + prev, 0)}</td>
                        <td>{list2.reduce((prev, item) => Number(item.refund_apply_num) + prev, 0)}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    }

    // 仓库拣货单
    if (props.match.params.type === '3') {
        return <div>
            <B solid color='red' onClick={window.print} >打印</B>
            {list3.map((i, idx) => {
                const data = i.delivery_order
                return <Table key={idx}>
                    <thead>
                        <tr style={{ border: 0 }}>
                            <td colSpan={6}>
                                <Light>打印时间：{moment().format('YYYY-MM-DD HH:mm:ss')}</Light>
                                <div className='flex-box'>
                                    <div>【{data.mall_name}】拣货单</div>
                                    <div>共{data.order_count}个订单</div>
                                </div>
                                {(search.start || search.end) && <Light>
                                    订单创建时间：{moment.unix(Number(search.start)).format('YYYY-MM-DD HH:mm:ss')}至{moment.unix(Number(search.end)).format('YYYY-MM-DD HH:mm:ss')}
                                </Light>}
                                <Light>
                                    电话：{data.address_mobile}&nbsp;&nbsp;&nbsp;地址：{data.address_detail}&nbsp;&nbsp;&nbsp;团长：{data.seller}
                                </Light>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>序号</td>
                            <td>商品条码</td>
                            <td>商品名称</td>
                            <td>数量</td>
                            <td>退款中</td>
                            <td>总价</td>
                        </tr>
                        {data.list.map((good, gidx) => {
                            return <tr key={good}>
                                <td>{gidx + 1}</td>
                                <td>{good.bar_code}</td>
                                <td>{good.goods_name}</td>
                                <td>{good.num}</td>
                                <td>{good.refund_apply_num}</td>
                                <td>{(Number(good.total_price) / 100).toFixed(2)}</td>
                            </tr>
                        })}
                        <tr>
                            <td colSpan={3}>总计</td>
                            <td>{data.count_num}</td>
                            <td>{data.list.reduce((prev, item) => Number(item.refund_apply_num) + prev, 0)}</td>
                            <td>{(Number(data.count_price) / 100).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            })}
        </div>
    }

    // 门店对货单&消费者订单
    if (props.match.params.type === '4') {
        return <div>
            <B solid color='red' onClick={window.print} >打印</B>
            {list3.map((i, idx) => {
                const pickdata = i.delivery_order
                const customdata = i.consumer_order
                return <React.Fragment key={idx}>
                    <Table key={idx}>
                        <thead>
                            <tr style={{ border: 0 }}>
                                <td colSpan={6}>
                                    <Light>打印时间：{moment().format('YYYY-MM-DD HH:mm:ss')}</Light>
                                    <div className='flex-box'>
                                        <div>【{pickdata.mall_name}】对货单</div>
                                        <div>共{pickdata.order_count}个订单</div>
                                    </div>
                                    {(search.start || search.end) && <Light>
                                        订单创建时间：{moment.unix(Number(search.start)).format('YYYY-MM-DD HH:mm:ss')}至{moment.unix(Number(search.end)).format('YYYY-MM-DD HH:mm:ss')}
                                    </Light>}
                                    <Light>
                                        电话：{pickdata.address_mobile}&nbsp;&nbsp;&nbsp;地址：{pickdata.address_detail}&nbsp;&nbsp;&nbsp;团长：{pickdata.seller}
                                    </Light>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>序号</td>
                                <td>商品条码</td>
                                <td>商品名称</td>
                                <td>数量</td>
                                <td>退款中</td>
                                <td>总价</td>
                            </tr>
                            {pickdata.list.map((good, gidx) => {
                                return <tr key={gidx}>
                                    <td>{gidx + 1}</td>
                                    <td>{good.bar_code}</td>
                                    <td>{good.goods_name}</td>
                                    <td>{good.num}</td>
                                    <td>{good.refund_apply_num}</td>
                                    <td>{(Number(good.total_price) / 100).toFixed(2)}</td>
                                </tr>
                            })}
                            <tr>
                                <td colSpan={3}>总计</td>
                                <td>{pickdata.count_num}</td>
                                <td>{pickdata.list.reduce((prev, item) => Number(item.refund_apply_num) + prev, 0)}</td>
                                <td>{(Number(pickdata.count_price) / 100).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </Table>

                    {/* 消费者 */}
                    <Table>
                        <thead>
                            <tr style={{ border: 0 }}>
                                <td colSpan={6}>
                                    <Light>打印时间：{moment().format('YYYY-MM-DD HH:mm:ss')}</Light>
                                    <div className='flex-box'>
                                        <div>【{customdata.mall_name}】消费者订单</div>
                                        <div>共{customdata.order_count}个订单</div>
                                    </div>
                                    {(search.start || search.end) && <Light>
                                        订单创建时间：{moment.unix(Number(search.start)).format('YYYY-MM-DD HH:mm:ss')}至{moment.unix(Number(search.end)).format('YYYY-MM-DD HH:mm:ss')}
                                    </Light>}
                                    <Light>
                                        电话：{customdata.address_mobile}&nbsp;&nbsp;&nbsp;地址：{customdata.address_detail}&nbsp;&nbsp;&nbsp;团长：{customdata.seller}
                                    </Light>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>接龙号</td>
                                <td>昵称</td>
                                <td>联系人</td>
                                <td>手机号</td>
                                <td>产品名称</td>
                                <td>数量</td>
                                <td>退款中</td>
                                <td>总价</td>
                            </tr>
                            {customdata.list.map((good, gidx) => {
                                return <tr key={gidx}>
                                    <td>{good.buy_no}</td>
                                    <td>{good.buyer_name}</td>
                                    <td>{good.contact}</td>
                                    <td>{good.mobile}</td>
                                    <td>{good.goods_name}</td>
                                    <td>{good.num}</td>
                                    <td>{good.refund_apply_num}</td>
                                    <td>{(Number(good.price) / 100).toFixed(2)}</td>
                                </tr>
                            })}
                            <tr>
                                <td colSpan={5}>总计</td>
                                <td>{customdata.count_num}</td>
                                <td>{customdata.list.reduce((prev, item) => Number(item.refund_apply_num) + prev, 0)}</td>
                                <td>{(Number(customdata.count_price) / 100).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </React.Fragment>
            })}
        </div>
    }

}

export default Print