import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
const Footer = styled.footer`
    color: white;
    font-size: 14px;
    background-color: #332A26;
    & > div:nth-child(1){
        max-width: 1140px;
        display: flex;
        justify-content: center;
        padding: 30px;
        a{
            color: white;
        }
        & > div{
            display: flex;
            flex-direction: column;
            &:nth-child(1){
                margin-right: 100px;
                & > * {
                    &:not(strong){
                        padding: 10px 0;
                    }
                    &:not(:last-child){
                        padding-bottom: 10px;
                        border-bottom: 1px solid #666;
                        @media (min-width: 800px){
                            border-bottom: 0;
                        }
                    }
                }
                @media (max-width: 800px){
                    margin-bottom: 40px;
                    strong{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        i{
                            display: inline-block;
                        }
                    }
                }
            }
            &:nth-child(2){
                flex: 1;
                & > * {
                    &:not(strong){
                        padding: 10px 0;
                    }
                    &:not(:last-child){
                        padding-bottom: 10px;
                    }
                }
                @media (max-width: 800px){
                    margin-bottom: 40px;
                    strong{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        i{
                            display: inline-block;
                        }
                    }
                }
            }
            &:nth-child(3){
                align-items: center;
                img{
                    margin-bottom: 10px;
                }
            }
        }
        strong{
            font-size: 16px;
            i{
                display: none;
            }
        }
        @media (max-width: 800px){
            .hide_{
                *:not(:first-child){
                    display: none;
                }
                * {
                    border-bottom: 0 !important;
                }
            }
            flex-direction: column;
            & > * {
                margin-right: 0 !important;
            }
        }
    }
`

export default function(){

    const [footer1, setFooter1] = useState(true)
    const [footer2, setFooter2] = useState(true)

    return <Footer>
    <div style={{ margin: '0 auto', }}>
        <div className={footer1 ? 'hide_' : ''}>
            <strong onClick={() => { setFooter1(!footer1) }}>产品服务<i className='iconfont icon-down' /></strong>
            <div><NavLink to='/home/feature/团长服务'>
                <span onClick={() => { window.scrollTo({ top: 0, }) }}>团长服务</span>
            </NavLink></div>
            <div><NavLink to='/home/feature/品牌服务'>
                <span onClick={() => { window.scrollTo({ top: 0, }) }}>品牌服务</span>
            </NavLink></div>
            <div><NavLink to='/downloads/app'>
                <span onClick={() => { window.scrollTo({ top: 0, }) }}>下载客户端</span>
            </NavLink></div>
        </div>
        <div className={footer2 ? 'hide_' : ''}>
            <strong onClick={() => { setFooter2(!footer2) }}>关于我们<i className='iconfont icon-down' /></strong>
            <div>广州公司地址：广东省广州市天河区椰林路9号克莱顿酒店2楼205</div>
            <div>武汉办事处地址：武汉市汉阳区龙阳大道58号人信汇6号楼2017室</div>
        </div>
        <div>
            <img alt='' src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/hGt0zRsp6R.jpg' style={{ width: 108, height: 108 }} />
            <div>扫描二维码关注我们</div>
        </div>
    </div>
    <div className='flex-center' style={{ padding: 14, background: '#241C18' }}>
        <a className='flex-center' target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602008538" style={{ textDecoration: 'none', fontSize: 12 }}>
            <img src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/4UhsHGjua4.png" alt='' />
            <p style={{
                margin: '0px 0px 0px 5px', color: '#fff'
            }}>粤公网安备 44010602008538号</p ></ a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <div style={{ fontSize: 12 }}>北京灵数世纪科技有限公司 <a style={{ color: 'white' }} href='http://beian.miit.gov.cn/'>{window.location.href.indexOf('jieligo') !== -1 ? '京ICP备19012758号-2':'京ICP备19012758号-1'}</a></div>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a  style={{ color: 'white' }} href='/home/privacyAgreement'>隐私政策</a>
    </div>
</Footer>
}