
import React, { useEffect, useRef, useState } from 'react'

import { connect } from 'react-redux'

import Select from '../total/Select_';
// import Modal from '../total/Modal';
import Textarea from '../total/Input/Textarea';
import Images from '../total/DraggableImgs_';
import styled from 'styled-components';
// import { appname } from '../../data/options';
import { post, get } from '../../netApi/fetch';
import { message } from 'antd';
import { confirm } from '../Method/Confirm';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import BTN from '../../StyleComponents/Button';
import setSKU from './method/setSKU';
import parseParams from '../../methods/paramParse';

const Panel = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5); 
    margin-bottom: 30px;
    padding: 30px;
    font-size: 14px;
    max-width: 824px;
    margin-left: auto;
    margin-right: auto;
    input{
        border: 1px solid #ebebeb;
        padding: 10px 16px;    
    }
    strong{
        display: inline-block;
        min-width: 5em;
    }
    &:last-child{
        margin-bottom: 0;
    }
`

const Page = styled.div`
    margin: 0 auto;
    position: relative;
    padding-top: 120px;
    background-color: #fff;
    
`
const Top = styled.div`
    position: absolute;
    top: 0; left: 0;
    padding: 0 20px;
    width: 100%; height: 90px;
    border-bottom: 1px solid #f1f1f1;
    display: flex; justify-content: space-between; align-items: center;
`

const FareType = styled.div`
    padding: 4px 11px;
    border: 1px solid transparent;
    position: relative;
    background: #eee;
    margin-bottom: 12px;
    margin-right: 12px;
    &.active{
        background: #fff;
        border-color: var(--theme-main);
        color: var(--theme-main);
        i{
            color: var(--theme-main);
        }
    }
    i{
        color: transparent;
        position: absolute;
        bottom: -1px;
        right: -2px;
        line-height: 1;
    }
`

interface sku {
    no: string,
    bar: string,
    weight: string,
    spec: {
        spec: {
            id: number,
            name: string,
            rank: number,
        },
        spec_item: {
            id: number,
            name: string,
            rank: string,
        }
    }[]
    ids: string,
    attrid?: string | number
}

interface props extends RouteComponentProps {
    user: user
}

function Good(props: props) {

    const [edit,
        // setEdit.
    ] = useState<boolean>(!!parseParams(props.location.search).id)

    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [multiAttr, setMultiAttr] = useState(false)
    const [images, setImages] = useState<any[]>()
    const [spec, setSpec] = useState<{
        name: string,
        rank: number | string
        id: number
        origin_id?: number
        spec_item_list: {
            name: string
            rank: number
            id: number,
            origin_id?: number
        }[]
    }[]>([])
    const [goods_attrs, setAttr] = useState<{
        unit: string
        weight: string
        style_no: string
        bar_code: string
        id?: string
    }[]>([])
    const [desc, setDesc] = useState('')

    const ID = useRef(0)

    const skulist = useRef<sku[]>()
    const attrlist = useRef<any[]>()

    const [catlist, setCatlist] = useState<any[]>()
    const [selectedCat, setSelectedCat] = useState<[number, number][]>([[0, 0]])
    const [prevselectcat, setprevselectcat] = useState<number[]>()

    useEffect(() => {
        const param = parseParams(props.location.search)
        if (param.id) {
            get('/warehouse/goodsinfo', { goods_id: param.id }).then(res => {
                setName(res.name)
                setCode(res.goods_no)
                setDesc(res.content)
                setImages(res.images)
                setMultiAttr(!!Number(res.is_multi_spec))
                if (res.is_multi_spec) {
                    const spec = Object.keys(res.spec_info_list).map(key => {
                        const item = res.spec_info_list[key]
                        if (item.id > ID.current) ID.current = Number(item.id) + 1
                        return {
                            name: item.name,
                            id: Number(item.id),
                            rank: Number(item.rank),
                            origin_id: item.id,
                            spec_item_list: item.items.map(i => {
                                if (i.id > ID.current) ID.current = Number(i.id) + 1
                                return {
                                    name: i.name,
                                    rank: Number(i.rank),
                                    id: Number(i.id),
                                    origin_id: i.id,
                                }
                            })
                        }
                    })
                    attrlist.current = res.attribute
                    setSpec(spec)
                } else {
                    setAttr(res.attribute.map(i => {
                        return {
                            unit: i.unit,
                            weight: i.weight,
                            bar_code: i.bar_code,
                            style_no: i.style_no,
                            id: i.id
                        }
                    }))
                }
                setprevselectcat(res.category_ids.map(i => Number(i)))
            }).catch(e => {
                if (e.message) message.error(e.message)
            })
        }
    }, [props.location.search])

    useEffect(() => {
        if (spec.length === 0 && !edit) {
            setSpec([{
                name: '',
                rank: 1,
                id: ++ID.current,
                spec_item_list: [{
                    name: '', rank: 0, id: ++ID.current,
                }],
            }])
        }
    }, [spec, edit])

    useEffect(() => {
        if (goods_attrs.length === 0) {
            setAttr([{
                unit: '',
                weight: '0',
                style_no: '',
                bar_code: '',
            }])
        }
    }, [goods_attrs])

    if (edit && spec.length > 0 && !skulist.current) {
        skulist.current = getMultiAttr()
        skulist.current = skulist.current.map(i => {
            const findout = attrlist.current.find(j => j.spec_item_path === i.ids)
            if (!findout) return i
            return {
                no: findout.style_no,
                bar: findout.bar_code,
                weight: findout.weight,
                ids: i.ids,
                spec: i.spec,
                attrid: findout.id,
            }
        })
    }

    useEffect(() => {
        if (!catlist && props.user.self_sid) {
            get('/goodscategory/getall', { sid: props.user.self_sid }).then(res => {
                setCatlist(res)
            })
        }
    }, [catlist, props.user.self_sid])

    useEffect(() => {
        if (prevselectcat && catlist) {
            const list = prevselectcat.map(i => {
                const data: [number, number] = [0, 0]
                for (let j = 0; j < catlist.length; j++) {
                    const cat = catlist[j];
                    if (Number(cat.id) === i) {
                        data[0] = i
                        break
                    }
                    if (cat.children.find(k => Number(k.id) === i)) {
                        data[0] = Number(cat.id)
                        data[1] = i
                        break
                    }
                }
                return data
            })
            setSelectedCat(list.length > 0 ? list : [[0, 0]])
        }
    }, [catlist, prevselectcat])

    return <Page>
        <Top>
            <NavLink to={`/index/mall/goods/1`}><i style={{ color: '#999' }} className='iconfont icon-right' /></NavLink>
            <div>{edit ? '修改' : '添加'}商品基础信息</div>
            <div className='flex-box'>
                {edit ? <>
                    <BTN color='red' solid onClick={submit.bind(true)}>保存到商品信息库</BTN>
                </> : <BTN color='red' solid onClick={submit}>保存到商品信息库</BTN>
                }
            </div>
        </Top>
        <Panel>
            <div className='flex-between'>
                <strong>商品名称</strong>
                <input placeholder='请输入商品名称' style={{ flex: 1 }} value={name} onChange={e => setName(e.target.value)} />
            </div>
            <img alt='' style={{ marginLeft: '5em', marginBottom: 30, maxWidth: '90%' }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/U1eU7x87xs.png' />
            <div className='flex-box' style={{ flexWrap: 'wrap' }}>
                <strong>商品编码</strong>
                <input placeholder='请输入商品编码' value={code} onChange={e => setCode(e.target.value)} style={{ marginRight: 10 }} />
                <div>每个商品对应唯一的商品编码</div>
            </div>
        </Panel>
        <Panel>
            <div className='flex-between' style={{ alignItems: 'flex-start' }}>
                <strong style={{ lineHeight: '44px' }}>商品分类</strong>
                <div style={{ flex: 1, }}>
                    {!!catlist && selectedCat.map((i, idx) => {
                        return <div key={idx} className='flex-box' style={{ marginBottom: 30, flexWrap: 'wrap' }}>
                            <div style={{ width: 300, marginRight: 30 }}>
                                <Select uni={`catf${idx}`} list={catlist} showKey='name' placeholder='请选择一级分类' value={catlist.findIndex(j => Number(i[0]) === Number(j.id))}
                                    onChange={e => {
                                        i[0] = Number(e.id)
                                        setSelectedCat([...selectedCat])
                                    }} />
                            </div>
                            <div style={{ width: 300, marginRight: 30 }}>
                                <Select uni={`cats${idx}`}
                                    list={catlist.find(j => Number(j.id) === i[0])?.children || []}
                                    showKey='name' placeholder='请选择二级分类'
                                    value={(catlist.find(j => Number(j.id) === i[0])?.children || []).findIndex(j => Number(i[1]) === Number(j.id))}
                                    onChange={e => {
                                        i[1] = Number(e.id)
                                        setSelectedCat([...selectedCat])
                                    }} />
                            </div>
                            {<i className='iconfont icon-guanbi-tianchong cur-pointer' style={{ color: '#999', fontSize: 16, lineHeight: 1 }} onClick={() => {
                                setSelectedCat(selectedCat.filter((j, jidx) => jidx !== idx))
                            }} />}
                        </div>
                    })}
                </div>
            </div>
            {selectedCat.length === 0 && <div className='flex-center'><BTN color='red' onClick={() => {
                setSelectedCat([...selectedCat, [0, 0]])
            }}>+ 添加分类</BTN></div>}
        </Panel>
        <Panel>
            <div style={{ marginBottom: 30, }}><strong>商品规格</strong></div>
            <div className='flex-box'>
                <FareType onClick={() => { if (!edit) setMultiAttr(false) }} className={'cur-pointer ' + (!multiAttr ? 'active' : '')}>
                    单规格
                        <i className='iconfont icon-xuanzhong1' />
                </FareType>
                <FareType onClick={() => { if (!edit) setMultiAttr(true) }} className={'cur-pointer ' + (multiAttr ? 'active' : '')}>
                    双规格
                        <i className='iconfont icon-xuanzhong1' />
                </FareType>
            </div>
            {!multiAttr && <div>
                {goods_attrs.map((i, idx) => {
                    return <div className='' key={idx} style={{ marginBottom: 30 }}>
                        <div className='flex-box' style={{ marginBottom: 16 }}>
                            <div style={{ marginRight: 20, }}>规格{idx + 1}</div>
                            <input value={i.unit} style={{ marginRight: 30, width: 150 }} placeholder='如件/瓶/箱/个/kg等' onChange={e => {
                                i.unit = e.target.value
                                setAttr([...goods_attrs])
                            }} />
                            <div style={{ marginRight: 20, }}>重量</div>
                            <input style={{ marginRight: 14, width: 150 }} placeholder='请输入商品重量' type='number' value={i.weight} onChange={e => {
                                i.weight = e.target.value
                                setAttr([...goods_attrs])
                            }} />
                            <div>g</div>
                        </div>
                        <div className="flex-between">
                            <div className='flex-box'>
                                <div style={{ marginRight: 20, }}>商品条码</div>
                                <input style={{ marginRight: 30 }} placeholder='请输入商品条码' value={i.bar_code} onChange={e => {
                                    i.bar_code = e.target.value
                                    setAttr([...goods_attrs])
                                }} />
                                <div>每个规格对应唯一的商品条码</div>
                            </div>
                            {goods_attrs.length > 1 && <div className="cur-pointer" onClick={() => {
                                setAttr(goods_attrs.filter(good => good !== i))
                            }} style={{ color: 'var(--theme-main)' }}>删除</div>}
                        </div>
                    </div>
                })}
                <div className='flex-center' style={{}}>
                    <BTN color='red' onClick={() => { setAttr([...goods_attrs, { unit: '', style_no: '', weight: '0', bar_code: '', }]) }}>+ 添加规格</BTN>
                </div>
            </div>}
            {multiAttr && <div>
                {spec.map((i, idx) => {
                    return <div key={i.id} >
                        <div className='flex-between' style={{ marginBottom: 30 }}>
                            <div style={{ minWidth: '6em' }}>规格名称{idx + 1}</div>
                            <input style={{ flex: 1 }} value={i.name} onChange={e => {
                                i.name = e.target.value
                                setSpec([...spec])
                            }} />
                            <i className="iconfont icon-guanbi-tianchong" onClick={() => {
                                setSpec(spec.filter(s => s !== i))
                            }} style={{ color: '#999', marginLeft: 6 }} />
                        </div>
                        {i.spec_item_list.map(s => {
                            return <div key={s.id} className='flex-between' style={{ marginBottom: 30 }}>
                                <div style={{ minWidth: '6em' }}>规格</div>
                                <input style={{ flex: 1 }} value={s.name} onChange={e => {
                                    s.name = e.target.value
                                    setSpec([...spec])
                                }} />
                                <i className="iconfont icon-guanbi-tianchong" onClick={() => {
                                    i.spec_item_list = i.spec_item_list.filter(spec => spec!== s)
                                    setSpec([...spec])
                                }} style={{ color: '#999', marginLeft: 6 }} />
                            </div>
                        })}
                        <div style={{ color: 'var(--theme-main)', marginBottom: 30 }} className='flex-center cur-pointer' onClick={() => {
                            i.spec_item_list.push({
                                name: '', id: ++ID.current, rank: i.spec_item_list.length + 1,
                            })
                            setSpec([...spec])
                        }}>+ 添加规格</div>
                    </div>
                })}
            </div>}
        </Panel>
        {multiAttr && <div className='flex-center' style={{ marginBottom: 30, }}>
            <BTN color='red' solid style={{ marginRight: 20 }} onClick={() => {
                setSpec([...spec, {
                    name: '',
                    rank: spec.length + 1,
                    id: ++ID.current,
                    spec_item_list: [{
                        name: '', rank: 0, id: ++ID.current,
                    }],
                }])
            }}>添加规格</BTN>
            <BTN solid color='red' onClick={() => {
                skulist.current = getMultiAttr()
                setSKU(skulist.current).then(list => {
                    skulist.current = list as sku[]
                })
            }}>
                设置规格
            </BTN>
        </div>}
        <Panel>
            <div style={{ marginBottom: 30, }}>
                <strong>商品图片</strong><span>{(images || []).length}/9</span>
            </div>
            <div style={{ background: '#f5f5f5', padding: '15px 15px 0' }}><Images imgtype='warehouse_goods' onChange={e => {
                setImages(e)
            }} images={images || []} width={72} height={72} maxLength={9} /></div>
        </Panel>
        <Panel>
            <div style={{ marginBottom: 30, }}><strong>商品描述</strong></div>
            <Textarea value={desc} onChange={setDesc} placeholder='选填，对商品进行补充描述' />
        </Panel>


    </Page>

    function getMultiAttr() {
        let attr = spec.reduce((prev, item,) => {
            if (prev.length === 0) return item.spec_item_list.map(i => {
                return {
                    spec: [{
                        spec: item,
                        spec_item: i,
                    }],
                    no: '',
                    weight: '0',
                    bar: '',
                }
            })
            let list = []
            for (let i = 0; i < prev.length; i++) {
                const p = prev[i];
                const l = item.spec_item_list.map(j => {
                    return {
                        no: p.no,
                        weight: p.weight,
                        bar: p.bar,
                        spec: [...p.spec, {
                            spec: item,
                            spec_item: j,
                        }]
                    }
                })
                list = [...list, ...l]
            }
            return list
        }, [])
        attr = attr.map(i => {
            const ids = i.spec.map(k => k.spec_item.id).sort((a, b) => a - b).join(',')
            return {
                ...i,
                ids
            }
        })
        if (skulist.current && skulist.current.length > 0) {
            attr = attr.map(item => {
                const findout = skulist.current.find(i => i.ids === item.ids)
                if (findout) {
                    return {
                        ...item,
                        weight: findout.weight,
                        bar: findout.bar,
                        no: findout.no,
                        attrid: findout.attrid || undefined,
                    }
                }
                return item
            })
        }
        return attr
    }

    function submit() {
        if (!name) {
            message.warn('请填写商品名称')
            return
        }
        if (!multiAttr) {
            if (goods_attrs.find(i => !i.unit)) {
                message.warn('请填写规格名称')
                return
            }
        } else {
            if (spec.find(i => {
                if (!i.name) return true
                if (i.spec_item_list.find(j => !j.name)) return true
                return false
            })) {
                message.warn('请填写规格名称')
                return
            }
        }
        // if (!images || images.length === 0) {
        //     message.warn('请上传商品图片')
        //     return
        // }
        const data: any = {
            name,
            content: desc,
            goods_no: code,
            is_multi_spec: multiAttr,
            category_ids: selectedCat.map(i => {
                if (i[1]) return i[1]
                return i[0]
            }).filter(i => i !== 0),
            image_ids: (images || []).map(i => {
                return { id: i.id, rank: i.rank }
            }),
        }

        if (data.category_ids.length > 1) {
            message.warn('暂不支持选择多个商品分类')
            return
        }

        if (data.image_ids.length === 0) {
            delete data.image_ids
        }

        if (edit) {
            data.id = parseParams(props.location.search).id
        }
        if (multiAttr) {
            data.goods_info = {
                spec_info_list: spec.map(i => {
                    return {
                        spec_front_id: i.id,
                        spec_name: i.name,
                        rank: i.rank,
                        spec_item_list: i.spec_item_list.map(s => {
                            return {
                                name: s.name,
                                rank: s.rank,
                                spec_item_front_id: s.id,
                            }
                        }),
                    }
                }),
                sku_list: getMultiAttr().map(i => {
                    return {
                        weight: i.weight || '0',
                        style_no: i.no,
                        bar_code: i.bar,
                        spec_list: i.spec.map(j => {
                            return {
                                spec_front_id: j.spec.id,
                                spec_item_front_id: j.spec_item.id,
                            }
                        })
                    }
                }),
            }
        } else {
            data.goods_attrs = goods_attrs.map(i => {
                return {
                    ...i,
                    weight: i.weight || '0'
                }
            })
        }
        data.is_sync = true
        if (edit && !this) {
            post('/warehouse/goodsset', data).then(res => {
                return confirm({
                    title: '提示',
                    text: '保存成功',
                    confirmText: '返回商品列表',
                    cancelText: '继续编辑',
                })
            }).then(res => {
                if (res) props.history.goBack()
            }).catch(e => {
                if (e.message) message.error(e.message)
            })
        } else {
            post('/warehouse/goodsset', data).then(res => {
                return confirm({
                    title: '提示',
                    text: '保存成功',
                    confirmText: '返回商品列表',
                    cancelText: '留在本页',
                })
            }).then(res => {
                if (res) props.history.goBack()
            }).catch(e => {
                if (e.message) message.error(e.message)
            })
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data
    }
}

export default connect(mapStateToProps)((Good))