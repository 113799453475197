import React, { Component } from 'react'
import { connect } from 'react-redux'
// import BanUnLogin from '../../high-component/BanUnLogin'
import Main from '../../high-component/Content'
import api from '../../netApi'
import Modal from '../total/Modal'

class WithDraw extends Component {
    constructor(p) {
        super()
        document.title = '佣金钱包'
        this.state = { price: '', data: {} }
        if (p.didLogin) {
            if (Number(p.user.source_uid) !== Number(p.user.self_uid)) {
                p.history.push('/')
            }
            if (Number(p.userType) !== -1) {
                if (Number(p.userType) === 5 || Number(p.userType) === 0) {
                    p.history.push('/payment/member/4')
                }
            }
            api('proxy', 'myWithdraw')({ type: 1 }).then((res) => {
                if (Number(res.data.code) !== 0) {
                    return
                }
                this.setState({
                    data: res.data.data
                })
            })
        }
    }
    componentWillUnmount() {
        document.title = '接力GO'
        this.setState = () => { }
    }
    componentDidUpdate(prev) {
        const p = this.props
        if (p.didLogin) {
            if (Number(p.user.source_uid) !== Number(p.user.self_uid)) {
                p.history.push('/')
            }
            if (Number(p.userType) !== -1) {
                if (Number(p.userType) === 5 || Number(p.userType) === 0) {
                    p.history.push('/payment/member/4')
                }
            }
            if (!prev.didLogin && p.didLogin) {
                api('proxy', 'myWithdraw')({ type: 1 }).then((res) => {
                    // 
                    if (Number(res.data.code) !== 0) {
                        return
                    }
                    this.setState({
                        data: res.data.data
                    })
                })
            }
        }
    }
    showwithdraw() {
        this.setState({
            showModal: true
        })
    }
    closeModal() {
        this.setState({
            showModal: false
        })
    }
    setPrice(e) {
        // console.log(e.target.value)
        this.setState({
            price: e.target.value
        })
    }
    withdraw(e) {
        // console.log(this.state.price)
        const p = this.state.price * 100
        const _this = this
        // console.log(this.state.data.surplus)
        if (p > this.state.data.surplus) {
            alert('提现金额不能大于可用余额')
            return
        }
        api('proxyorder', 'cashOut')({ type: 1 }, 'post', {
            amount: p,
        }).then((res) => {
            // 
            if (Number(res.data.code) === 0) {
                alert('提现成功')
                // console.log(_this.props)
                _this.props.history.push('/refresh')
                api('proxy', 'myWithdraw')({ type: 1 }).then((res) => {
                    // 
                    if (Number(res.data.code) !== 0) {
                        return
                    }
                    this.setState({
                        data: res.data.data
                    })
                })
            }
        })
    }
    render() {
        return (
            <div>
                <div style={{
                    backgroundImage: 'linear-gradient(0deg, #ff3558 0%, #ff7846 100%)', paddingTop: 50
                }}>
                    <div className='fcc' style={{ flexDirection: 'column' }}>
                        <div style={{ fontSize: 22, color: '#fff' }}>累计奖励收益(元)</div>
                        <div style={{ fontSize: 48, color: '#fff' }}>{(this.state.data.profit / 100 || 0).toFixed(2)}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className='fcc' style={{ flexDirection: 'column', margin: '20px 0' }}>
                            <div style={{ fontSize: 40, color: '#fff' }}>{(this.state.data.surplus / 100 || 0).toFixed(2)}</div>
                            <div style={{ fontSize: 22, color: '#fff' }}>可用余额（元）</div>
                        </div>
                        <div className='fcc' style={{ flexDirection: 'column', margin: '20px 0' }}>
                            <div style={{ fontSize: 40, color: '#fff' }}>{(this.state.data.cash_out / 100 || 0).toFixed(2)}</div>
                            <div style={{ fontSize: 22, color: '#fff' }}>提现累计（元）</div>
                        </div>
                    </div>
                </div>
                <div className='fcc' style={{ paddingTop: 50 }}>
                    <button style={{ background: '#fd5356', color: '#fff', fontSize: 30, padding: '.3rem 2rem ' }} onClick={this.showwithdraw.bind(this)} >
                        申请提现
                    </button>
                </div>
                {this.state.showModal ? (
                    <Modal title='请输入提现金额' cancel={true} close={this.closeModal.bind(this)} confirmText='确认提现' confirm={this.withdraw.bind(this)} >
                        <div style={{ padding: 30 }}>
                            <input placeholder='请输入提现金额' value={this.state.price} style={{ padding: '4px 11px' }} onChange={this.setPrice.bind(this)} ></input>
                        </div>
                    </Modal>
                ) : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        userType: state.userType,
        didLogin: state.is_Login,
    }
}

export default (Main(connect(mapStateToProps)(WithDraw)))
// export default BanUnLogin(Main(connect(mapStateToProps)(WithDraw)))