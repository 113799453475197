import React, { useState, useRef } from 'react';

import Modal from '../total/Modal';

import { get, post } from '../../netApi/fetch';
import styled from 'styled-components';
import { message } from 'antd';
import BTN from '../../StyleComponents/Button';
// import { message } from 'antd';


interface Props {
    onClose: () => void,
    refresh: () => void
}

interface ApplyItem {
    status: number,
    id: number,
    uid: number,
    createtime: string,
    referrer_sid: number,
    referrer_name: string,
    name: string,
    logo: string,
    address: string,
    addressdetail: string,
    phone: string,
}

const Item = styled.div`
    & > div:nth-child(2){
        & > div{
            margin-bottom: 16px;
            /* color:  */
            &:first-child{
                & >div:first-child{
                    margin-right: 10px;
                    color: #333;
                    font-size: 16px 
                }
            }
            &:last-child{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                button{
                    margin-left: 20px;
                }
            }
        }
        
    }
    margin-bottom: 20px;
    padding-right: 16px;
    border-bottom: 1px solid #eee;
`

export default function (props: Props) {
    const [list, setList] = useState<Array<ApplyItem>>()
    const [loading, showLoading] = useState(false)
    const count = useRef<number>()
    const Loading = useRef<boolean>()
    Loading.current = loading
    if (!list) {
        getList()
    }

    return <Modal title='申请列表' loading={loading} onClose={props.onClose} onConfirm={props.onClose} >
        <div style={{ overflowY: 'auto', height: '60vh', width: 500 }} onScroll={(e: React.UIEvent<HTMLDivElement>) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 50) {
                getList()
            }
        }}>
            {list && list.map((i: ApplyItem) => {
                return <Item key={i.id} className='flex-between flex-top'>
                    <img alt='' src={i.logo} className='img-center' style={{ borderRadius: '50%', width: 36, height: 36, marginRight: 16 }} />
                    <div className='flex1' style={{ fontSize: 12 }}>
                        <div className='flex-between'>
                            <div>{i.name}</div>
                            <div className='flex1'>申请成为你的帮卖好友</div>
                            <div>{i.createtime}</div>
                        </div>
                        <div>
                            <div>联系方式：{i.phone}</div>
                        </div>
                        <div>
                            地址：{i.address + i.addressdetail}
                        </div>
                        {i.referrer_name !== '' ? <div className='flex-box'>
                            <div style={{ color: 'var(--theme-main)' }}>{i.referrer_name}</div>
                            <div>帮你推荐了</div>
                            <div style={{ color: 'var(--theme-main)' }}>{i.name}</div>
                        </div> : null}
                        <div>
                            {i.status === -1 ? <BTN color='gray' onClick={dealItem.bind(null, 2, i)}>忽略</BTN> : null}
                            {i.status === -2 ? <BTN color='gray' >已忽略</BTN> : null}
                            {i.status === 1 ? <BTN color='gray' >已同意</BTN> : null}
                            {i.status === 0 ? <BTN color='gray' >已取消</BTN> : null}
                            {i.status === -1 ? <BTN color='red' onClick={dealItem.bind(null, 1, i)}>通过申请</BTN> : null}
                        </div>
                    </div>
                </Item>
            })}
        </div>
    </Modal>

    function getList() {
        if (Loading.current) return
        Loading.current = true
        // showLoading(true)
        const offset = list ? list.length : 0
        get('/seller/getapplyuserlist', { offset, limit: 20, types: 1 }).then((res: any) => {
            count.current = Number(res.count)
            let reslist: ApplyItem[] = []
            if (list) reslist = [...list]
            setList([...reslist, ...res.list.map((i: any): ApplyItem => {
                return {
                    status: Number(i.status),
                    id: Number(i.id),
                    uid: Number(i.uid),
                    createtime: i.createtime,
                    referrer_sid: Number(i.referrer_sid),
                    referrer_name: i.referrer_name,
                    name: i.name,
                    logo: i.logo,
                    address: i.address,
                    addressdetail: i.addressdetail,
                    phone: i.phone,
                }
            })])
            Loading.current = false
            // showLoading(false)
        })
    }

    function dealItem(op: 1 | 2, i: ApplyItem) {
        showLoading(true)
        post('/seller/confirmapply', {
            types: 1,
            id: i.id,
            op
        }).then(() => {
            showLoading(false)
            if (op === 1) i.status = 1
            if (op === 2) i.status = -2
            setList([...(list || [])])
            props.refresh && props.refresh()
        }).catch((e) => {
            if (e.message) message.error(e.message)
            showLoading(false)
        })
    }
}

