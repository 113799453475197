import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { get, getToken, post } from '../../netApi/fetch';
import changeAdmin from '../index/method/changeAdmin';
import { logout, set_login_user_data, waitingLogin, setusertype, saveMallInfo, saveMemberInfo, toggleLoginPanel, setIsHema, setLoginBindInfo } from '../../reducers/index'
import { confirm } from '../Method/Confirm';
import hideTopBarPathList from '../../data/hideTopBarPathList';
import { TOPBAR_HEIGHT } from '../../data/style';
import VIPBar from './VIPBar';
import VIPPower, { M2G } from '../index/method/VIPPower';

const Background = styled.div`
    background: linear-gradient(153deg,#fa7a3a 8%, #fa4f3a 92%);
    height: 50px;
    z-index: 99;
    position: fixed;
    left: 0;
    width: 100vw;
    top: 0;
    box-sizing: border-box;
    padding: 0 0 0 20px;
    &>:only-child{
        max-width: 1200px;
        margin: 0 auto;
    }
`

const Ava = styled.img`
    width: 30px;
    height: 30px;
    border: 2px solid #fb8475;
    border-radius: 50%;
    margin-right: 8px;
`

const LogoutPanel = styled.div`
    color: #fff; 
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    position: relative;
    transition: background-color .3s;
    &:hover{
        background-color: #FB8475;
    }
    img{
        position: absolute;
        bottom: -36px;
        right: 18px;
    }
`
const TopButton = styled.button`
    font-size: 16px;
    border: 1px white solid;
    padding: 9px 29px;
    border-radius: 20px;
    color: white;
    line-height: 1;
    background: transparent;
    margin-left: 1rem;
    &:hover{
        opacity: .5;
    }
`
const LoginButton = styled(TopButton)`
    color: #fff;
    border: #fff 1px solid;
`

interface props extends RouteComponentProps {
    user: user
    logout: () => void
    waitingLogin: () => void
    setUserType: (data: any) => void
    login: (data: user) => void
    saveMallInfo: (data?: mall.mallinfo | false) => void
    mallinfo?: mall.mallinfo | false,
    memberInfo: member.info
    saveMemberInfo: (data: member.info) => void
    showLogin: (value: boolean) => void
    setIsHema: (value: any) => void
    setLoginBindInfo: (value: loginBindInfo) => void
}

function Top(props: props) {
    const [logoutnav, setLogoutnav] = useState<number>(Number(localStorage.getItem('logoutnav') || 1))

    const showVIP = useState(false)

    const getting = useRef(false)
    useEffect(() => {
        if (getting.current) return
        getting.current = true
        if (props.mallinfo === undefined || (props.mallinfo !== false && !props.mallinfo.id)) {
            // console.log('get');
            get('/index/getloginbindinfo', {}).then(res => {
                getting.current = false
                props.setLoginBindInfo(res)
                props.saveMallInfo(res.bind_mall)
                props.saveMemberInfo(res.member_info)
                props.setIsHema(res.is_hema)
            }).catch(e => {
                getting.current = false
            })
        } else {
            getting.current = false
        }
    }, [props])

    if (hideTopBarPathList.find(i => new RegExp(i).test(props.location.pathname))) {
        return <></>
    }

    if (!props.user || !props.user.uid) {
        return <Background>
            <div className='flex-between' style={{ padding: '0 20px' }}>
                <div>
                    <img alt='' style={{ width: 100 }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/cKCblQJqK2.png' />
                </div>
                <div className='flex-between'>
                    {/* <TopButton className='cur-pointer' onClick={() => { this.setState({ signin: true }) }}>注册</TopButton> */}
                    <LoginButton onClick={() => { props.showLogin(true) }} className='cur-pointer'>登录</LoginButton>
                </div>
            </div>
        </Background>
    }

    return <Background className='flex-between'>
        <div>
            <img alt='接力GO图标' style={{ height: 30 }} src={'https://jieligo-1259536745.picgz.myqcloud.com/uploads/credit_loan/j67FDw33J3.png'} />
        </div>
        <div className='flex-box' style={{ position: 'relative' }}>
            {/* <Admin>
                <i className='iconfont icon-wode-weixuanzhong' />
                <span>管理员</span>
            </Admin> */}
            <Ava alt='logo' src={props.user.avatar} onClick={() => {
                showVIP[1](!showVIP[0])
                get('/god/checkisgod', {}).then(res => {
                    if (res) window.open('https://god.jieligo.net?token=' + getToken())
                })
            }} />
            {(window.innerWidth >= 1200) && <div style={{ color: '#fff', marginRight: 8 }} >{props.user.name}</div>}
            <div className='cur-pointer' onClick={() => {
                changeAdmin().then(res => {
                    if (res) {
                        props.logout()
                        props.waitingLogin()
                        props.saveMallInfo(undefined)
                        post('/admin/checkIsLogin', {}).then(res => {
                            post('/proxy/checkProxy', { type: 1 }).then(r => {
                                props.setUserType(r.type)
                            })
                            props.login(res)
                            props.history.push('/index?refresh=1')
                        })
                    }
                })
            }} style={{ background: '#fff', color: '#FA4F3A', padding: '3px 8px', borderRadius: 3, }}>切换身份</div>
            {(window.innerWidth >= 1200) && <div style={{ color: '#fff', marginRight: 8 }} >
                <div className='cur-pointer' onClick={() => {
                    VIPPower({ user: props.user })
                }} style={{ background: '#fff', color: '#FA4F3A', padding: '3px 8px', borderRadius: 3, marginLeft: 8 }}>升级VIP</div></div>}


            <div style={{ height: 37, borderRight: '.5px solid #fff8', margin: '0 0 0 20px', width: 0 }}></div>
            <LogoutPanel onClick={() => {
                confirm({
                    text: '请确认退出',
                    title: '确认退出登录'
                }).then(res => {
                    if (res) {
                        get('/admin/logout', {
                            openid: props.user.openid
                        }).then(() => {
                            window.location.replace('/')
                        })
                        props.logout()
                    }
                })
            }} className='cur-pointer'>
                退出登录
                {logoutnav ? <img onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setLogoutnav(0)
                    localStorage.setItem('logoutnav', '0')
                }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/content/i0Tyt30HQH.png' alt='' /> : null}
            </LogoutPanel>
            {showVIP[0] && <PropsVip />}
        </div>

    </Background>
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        memberInfo: state.member_info,
        mallinfo: state.mallInfo,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(logout())
        },
        waitingLogin: () => {
            dispatch(waitingLogin())
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
        saveMemberInfo: (data) => {
            dispatch(saveMemberInfo(data))
        },
        showLogin: (b) => {
            dispatch(toggleLoginPanel(b))
        },
        setIsHema: (v) => {
            dispatch(setIsHema(v))
        },
        setLoginBindInfo: (v) => {
            dispatch(setLoginBindInfo(v))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((Top)))

const VIPPanel = styled.div`
    width: 280px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px #cccccc; 
    padding: 16px;
    position: absolute;
    top: ${TOPBAR_HEIGHT}px;
    left: 0px;
    font-size: 12px;
    &>div{
        margin-bottom: 16px;
    }
    &::before{
        content: '';
        display: block;
        width: 0; height: 0;
        border: 9px solid transparent;
        border-top: 0;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -10px;
        left: 6px;
    }
    .bold{
        font-weight: bold;
    }
`
function VIP(props: {
    memberInfo: member.info
    user: user
}) {

    useEffect(() => {

    }, [props])
    let mediaData: member.rule
    if (props.memberInfo && props.memberInfo.rule_list) {
        mediaData = props.memberInfo.rule_list.find(i => /视频/.test(i.rule_title))
    }
    const medianum = Number(mediaData.max_value) - Number(mediaData.use_value)

    return <VIPPanel>
        <div className='flex-between'>
            <div className='flex-center'>
                <div className='bold' style={{ fontSize: 14, }} >{props.memberInfo?.member_name}</div>
                <div style={{ marginLeft: 6 }} >{props.memberInfo?.residue_day ? (props.memberInfo.residue_day + '天') : ''}</div>
            </div>
            <div className='cur-pointer' style={{
                background: 'linear-gradient(315deg,#dc9b48 3%, #f1d8af 99%)',
                borderRadius: 3, padding: '4px 6px'
            }} onClick={() => {
                VIPPower({ user: props.user })
            }}>
                升级VIP
            </div>
        </div>
        <div className='bold' >VIP等级权益</div>
        {!!mediaData && <><div className='flex-between' style={{ marginBottom: 6 }} >
            <div>剩余{M2G(medianum > 0 ? medianum : 0)}/{M2G(Number(mediaData.max_value))}</div>
            <div>已使用{(Number(mediaData.use_value) / Number(mediaData.max_value) * 100).toFixed(2)}%</div>
        </div>
            <div style={{ marginBottom: 6 }}>
                <VIPBar value={Number(mediaData.use_value) / Number(mediaData.max_value) * 100} />
            </div>
            <div className="flex-between">
                <div>视频/图片存储空间</div>
                <div className='cur-pointer' onClick={() => {
                    VIPPower({ user: props.user })
                }} style={{ color: `var(--theme-main)` }} >扩容</div>
            </div></>}
        {props.memberInfo?.rule_list?.map(i => {
            const num = Number(i.max_value) - Number(i.use_value)
            if (/视频/.test(i.rule_title)) return
            return <div key={i.rule_title} className="flex-between">
                <div>{i.rule_title}</div>
                {Number(i.max_value) === -1
                    ? <div className='bold'>无限</div>
                    : <div className='bold'>剩余{num > 0 ? num : 0}/{i.max_value}{i.unit}</div>}
            </div>
        })}
    </VIPPanel>
}

const PropsVip = withRouter(connect(mapStateToProps, mapDispatchToProps)((VIP)))