import React, { useEffect, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { get, post } from '../../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
import BTN from '../../../StyleComponents/Button'
import { confirm as Confirm } from '../../Method/Confirm';
import Prompt from '../../Method/Prompt'
import { category } from '../datalist'


interface props {
    onClose: () => void
    data: {

    }
}

let wrapper: Element

const Ctrl = styled.div`
    text-align: right;
    span{
        padding: 0 8px;
        color: var(--theme-main);
        cursor: pointer;
    }
    span:not(:last-child){
        border-right: 1px #ccc solid;
    }
`
const Table = styled.table`
    border-radius: 8px;
    thead{
        background: #f5f5f5;
    }
    td{
        padding: 10px;
    }
    tr{
        border: 1px solid #f1f1f1;
    }
`
const Tag = styled.span`
    border: 1px solid ${props => props.color};
    border-radius: 2px;
    color: ${props => props.color};
    font-size: 12px;
    padding: 2px 4px;
    margin-left: 10px;
`

export default async function (sid: number | string, onCatChange?: (list: category[]) => void) {
    return new Promise<void>(async (reslove, reject) => {
        function Category(props: props) {

            const [newName, setNewName] = useState('')

            const [shouldClose, setShouldClose] = useState(false)

            const [list, setList] = useState<category[]>()

            useEffect(() => {
                if (!list) {
                    onCatChange && onCatChange(list)
                    get('/goodscategory/getall', { sid: encodeURIComponent(btoa('' + sid)) }).then(res => {
                        setList(res)
                        onCatChange && onCatChange(res)
                    })
                }
            }, [list])

            const Item = function (props) {
                const data = props.item
                const [hide, setHide] = useState(true)
                if (data.deleted) return null
                const child = data.children.filter(i => !i.deleted)
                return <>
                    <tr key={data.id}>
                        <td>{data.name}<Tag color='var(--theme-main)'>一级分类</Tag></td>
                        <td>
                            <Ctrl>
                                <span onClick={() => {
                                    post('/goodscategory/rank', { id: data.id, step: 'max' }).then(() => { message.success('添加成功'); setList(undefined) }).catch(e => { if (e.message) message.error(e.message) })
                                }}>置顶</span>
                                <span onClick={del.bind(data)}>删除</span>
                                <span onClick={edit.bind(data)}>编辑</span>
                                <span onClick={() => {
                                    Prompt({
                                        title: '填写二级分类名称',
                                        type: 'string',
                                    }).then(name => {
                                        post('/goodscategory/create', {
                                            name, pid: data.id,
                                        }).then(() => { message.success('添加成功'); setList(undefined) }).catch(e => { if (e.message) message.error(e.message) })
                                    })
                                }}>添加二级分级</span>
                            </Ctrl>
                        </td>
                    </tr>
                    {child.map((j, idx) => {
                        if (idx > 0 && hide) return null
                        return <tr key={j.id}>
                            <td style={{ paddingLeft: 30 }}>{j.name}<Tag color='#999'>二级分类</Tag></td>
                            <td><Ctrl>
                                <span onClick={del.bind(j)}>删除</span>
                                <span onClick={edit.bind(j)}>编辑</span>
                            </Ctrl></td>
                        </tr>
                    })}
                    {child.length > 1 && <tr>
                        <td colSpan={2}>
                            <div className='flex-center cur-pointer' onClick={() => { setHide(!hide) }}>
                                {hide ? '查看全部二级' : '收起'}
                                <i className='iconfont icon-down' style={{ transition: 'all .3s', transform: `rotateZ(${hide ? 0 : 180}deg)` }} />
                            </div>
                        </td>
                    </tr>}

                </>
            }

            return <Modal title='编辑分类' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <div className='flex-box' style={{ border: '1px solid #d9d9d9', borderRadius: 4, padding: 10, marginBottom: 16 }}>
                        <div style={{ marginRight: 10, }}>添加一级分类</div>
                        <input style={{ border: 0, flex: 1 }} maxLength={5} value={newName} placeholder='请输入一级分类名称' onChange={e => setNewName(e.target.value)} />
                        <BTN color='gray' onClick={() => {
                            post('/goodscategory/create', {
                                name: newName,
                            }).then(() => {
                                message.success('添加成功')
                                setList(undefined)
                                setNewName('')
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }}>添加</BTN>
                    </div>
                    <Table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td style={{ width: 370 }}>分类名称</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(list || []).map(i => {
                                return <Item key={i.id} item={i} />
                            })}
                        </tbody>
                    </Table>
                </div>
            </Modal>

            function del() {
                Confirm({
                    text: `确定删除该商品分类？`
                }).then(res => {
                    if (res) {
                        return post('/goodscategory/delete', {
                            id: this.id
                        }).then(() => {
                            this.deleted = true
                            setList([...list])
                            message.success('删除成功')
                        })
                    }
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            function edit() {
                const { id, name, pid } = this
                Prompt({
                    type: "string",
                    value: name,
                    title: '编辑分类名称',
                }).then((res) => {
                    return post('/goodscategory/update', {
                        pid, id, name: res,
                    }).then(() => {
                        this.name = res
                        setList([...list])
                        message.success('修改成功')
                    })
                }).catch(e => { if (e.message) message.error(e.message) })
            }

            function confirm() {
                setShouldClose(true)
                reslove()
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{ sid }} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}