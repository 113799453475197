import React, { Component } from 'react';

import Modal from '../../total/Modal';

import styled from 'styled-components';
import { message } from 'antd';

import { get } from '../../../netApi/fetch';
import './fare.scss';

enum faretype {
    type1 = 1,  // 有运费
    type2 = 2, // 包邮
}
interface GotFateItem {
    area_list: areaListItem[]
    fare_template_id: string,
    template_name: string,
}

interface FareItem extends GotFateItem {
    template_type: string,
    free_shipping_condition: string,
    free_shipping_type: string,
    not_free_shipping_area_fare: string,
    unselected_area_fare: string,
    unselected_area_type: string,
    when_condition_no_satisfied_fare: string,
}

interface areaListItem {
    area_type: string, // 区域类型， 1-有运费，2-不配送，3-不包邮
    area_fare: string, // 区域邮费
    area: areaItem[]
}

interface areaItem {
    region: string, // 区域
    province: {
        province_id: string,
        province_name: string,
        city: string[]
    }[]
}

interface formatFareItem extends GotFateItem {
    unselected_area_fare: number, // 当模板类型为有运费模板，设置未选择区域的运费金额
    not_free_shipping_area_fare: number, //	当模板类型为包邮模板，设置不包邮地区要补的运费金额
    when_condition_no_satisfied_fare: number, // 当模板类型为包邮模板，购买不满足条件时，要补的运费金额
    free_shipping_condition: number, // 包邮条件，即满x件/元包邮
    free_shipping_type: number, // 当模板类型为包邮模板，选择的包邮类型。0-未知，1-买即包邮，2-满x元包邮，3-满x件包邮
    unselected_area_type: number, // 当模板类型为有运费模板时，未选择区域的类型，0-未知，1-包邮，2-有运费，3-不配送
    template_type: number,
}

interface Props {
    user: number | string,
    onClose: () => void,
    onConfirm: (i?: formatFareItem) => void,
    selectedId?: string
}

interface State {
    offset: number,
    limit: number,
    loading: boolean,
    fareList?: formatFareItem[],
    selectedFareId?: string,
    fareType: faretype,
    shouldClose?: boolean,
    inputValue?: string
}

interface ParamsInter {
    types?: number,
    limit?: number,
    key_word?: string  
}

const FareItem = styled.div`
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #cecece;
`
const FareItemTitle = styled.div`
    min-width: 7em;
`
const Icon = styled.div`
    /* position: absolute;
    right: 10px;
    top: 10px; */
    margin-right: 1em;
    i{
        line-height: 1;
        font-size: 16px;
        &.icon-gouxuan{
            color: var(--theme-main);
        }
        &.icon-weixuanzhong{
            color: #999;
        }
    }
`
const FareType = styled.div`
    padding: 12px 48px;
    border: 1px solid transparent;
    position: relative;
    background: #eee;
    margin-bottom: 20px;
    margin-right: 12px;
    &.active{
        background: #fff;
        border-color: var(--theme-main);
        color: var(--theme-main);
        i{
            color: var(--theme-main);
            color: var(--theme-main);
        }
    }
    i{
        color: transparent;
        position: absolute;
        bottom: -1px;
        right: -2px;
        line-height: 1;
    }
`
class Fare extends Component<Props, State> {
    readonly state: State = {
        offset: 0,
        limit: 9999,
        loading: false,
        fareType: faretype.type2,
        inputValue: ''
    }

    componentDidMount() {
        this.getFareTemplete()
        this.setState({
            selectedFareId: this.props.selectedId
        })
    }

    componentDidUpdate() {
        
    }

    handleInput(evt) {
        const value = evt.target.value;
        this.setState({inputValue: value})
    }

    handleSearch() {
        const { inputValue} = this.state;
        this.getFareTemplete(inputValue);
    }

    render() { 
    const { inputValue} = this.state;
        return <Modal title='选择运费模板' loading={this.state.loading} shouldClose={this.state.shouldClose}
            cancel={true} onClose={this.onClose.bind(this)} onConfirm={this.onConfirm.bind(this)} >
            <div className="search-nav flex-row align-center">
                <input className="nav-input" placeholder="搜索模板名称" value={inputValue} onChange={this.handleInput.bind(this)}/>
                <div className="nav-iocn" onClick={this.handleSearch.bind(this)}>
                    <img className="icon-img" src={require('./images/search_btn.png')}/>
                </div>
            </div>
            <div style={{ maxWidth: 586, width: 586 }}>
                {/* 选择有无运费 */}
                <div className='flex-box'>
                    <FareType onClick={() => { this.setState({ fareType: faretype.type2, selectedFareId: undefined }) }} className={'cur-pointer ' + (this.state.fareType === faretype.type2 ? 'active' : '')}>
                        包邮
                        <i className='iconfont icon-xuanzhong1' />
                    </FareType>
                    <FareType onClick={() => { this.setState({ fareType: faretype.type1, selectedFareId: undefined }) }} className={'cur-pointer ' + (this.state.fareType === faretype.type1 ? 'active' : '')}>
                        有运费
                        <i className='iconfont icon-xuanzhong1' />
                    </FareType>
                </div>
                <div style={{ color: '#999', fontSize: 14, marginBottom: 20 }}>请勾选要使用的运费设置（不勾则默认全国包邮）</div>
                <div style={{ maxHeight: '40vh', height: '40vh', overflowY: 'auto', paddingRight: 12 , paddingTop: 12, paddingBottom: 12}}>
                    {/* 没有运费 */}
                    {this.state.fareList && this.state.fareType === faretype.type2 ? this.state.fareList.map((i: formatFareItem) => {
                        if (i.template_type !== faretype.type2) {
                            return null
                        }
                        
                        return <FareItem className='cur-pointer' key={i.fare_template_id} onClick={this.selectFare.bind(this, i)} >
                            <div className='flex-between flex-top' style={{ padding: '16px 20px', borderBottom: '#ccc 1px solid' }}>
                                <div className='flex-box' style={{}} >
                                    <Icon>
                                        <i className={`iconfont ${this.state.selectedFareId === i.fare_template_id ? 'icon-gouxuan' : 'icon-weixuanzhong'}`} />
                                    </Icon>
                                    <span>名称：</span>
                                </div>
                                <div className='flex1'>{i.template_name}</div>
                            </div>
                            <div style={{ paddingLeft: 36 }}>
                                <div className='flex-between flex-top' style={{ padding: 10 }} >
                                    <FareItemTitle>包邮条件</FareItemTitle>
                                    <div className='flex1'>
                                        {i.free_shipping_type === 1 ? <div>
                                            购买即包邮
                                        </div> : null}
                                        {i.free_shipping_type === 2 ? <div>
                                            <div>满{(i.free_shipping_condition / 100).toFixed(2)}元包邮</div>
                                            <div>不满足条件，补运费{(i.when_condition_no_satisfied_fare / 100).toFixed(2)}元</div>
                                        </div> : null}
                                        {i.free_shipping_type === 3 ? <div>
                                            <div>满{i.free_shipping_condition}件包邮</div>
                                            <div>不满足条件，补运费{(i.when_condition_no_satisfied_fare / 100).toFixed(2)}元</div>
                                        </div> : null}
                                    </div>
                                </div>
                                {i.area_list.map((area, index) => {
                                    if (area.area.length === 0) return null
                                    return <div key={index} className='flex-between flex-top' style={{ padding: 10 }} >
                                        <FareItemTitle>{area.area_type === '3' ? '不包邮地区' : ''}{area.area_type === '2' ? '不配送地区' : ''}</FareItemTitle>
                                        <div className='flex1'>
                                            <div>
                                                {area.area.map(a => {
                                                    return a.province.map(p => p.province_name).join('、')
                                                }).join('、')}
                                            </div>
                                            {area.area_type === '3' ? <div>补运费{(i.not_free_shipping_area_fare / 100).toFixed(2)}元</div> : null}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </FareItem>
                    }) : null}

                    {/* 运费 */}
                    {this.state.fareList && this.state.fareType === faretype.type1 ? this.state.fareList.map((i: formatFareItem) => {
                        if (i.template_type !== faretype.type1) {
                            return null
                        }
                        return <FareItem className='cur-pointer' key={i.fare_template_id} onClick={this.selectFare.bind(this, i)} >
                            <div className='flex-between flex-top' style={{ padding: '16px 20px', borderBottom: '#ccc 1px solid' }}>
                                <div className='flex-box' style={{}} >
                                    <Icon>
                                        <i className={`iconfont ${this.state.selectedFareId === i.fare_template_id ? 'icon-gouxuan' : 'icon-weixuanzhong'}`} />
                                    </Icon>
                                    <span>名称：</span>
                                </div>
                                <div className='flex1'>{i.template_name}</div>
                            </div>
                            <div style={{ paddingLeft: 36 }}>
                                {i.area_list.map((area, index) => {
                                    return <div key={index} className='flex-between flex-top' style={{ padding: 10 }} >
                                        <FareItemTitle>
                                            {area.area_type === '1' ? `补运费${(Number(area.area_fare) / 100).toFixed(2)}元` : ''}
                                            {area.area_type === '2' ? `不配送地区` : ''}
                                        </FareItemTitle>
                                        <div className='flex1'>
                                            {area.area.map(a => {
                                                return a.province.map(p => p.province_name).join('、')
                                            }).join('、')}
                                        </div>
                                    </div>
                                })}
                                <div className='flex-between flex-top' style={{ padding: 10 }} >
                                    <FareItemTitle>未选择的地区：</FareItemTitle>
                                    <div className='flex1'>
                                        {(() => {
                                            switch (i.unselected_area_type) {
                                                case 1:
                                                    return '包邮'
                                                case 2:
                                                    return `运费￥${(i.unselected_area_fare / 100).toFixed(2)}`
                                                case 3:
                                                    return '不配送'
                                                default:
                                                    break;
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>

                        </FareItem>
                    }) : null}

                </div>
            </div>

        </Modal>
    }

    getFareTemplete(keyWord?: any) {
        this.setState({ loading: true })
        interface paramsInter {
            types?: number,
            limit?: number,
            key_word?: string
        }
        let params: paramsInter = {
            types: 1,
            limit: this.state.limit
        }
        if(keyWord) {
            params.key_word = keyWord || ''
        }
        get('/logistics/getfaretemplatelistbysid', {...params}).then((res: any) => {
            this.setState({
                loading: false,
                fareList: res.list.map((i: FareItem): formatFareItem => {
                    return {
                        ...i,
                        when_condition_no_satisfied_fare: Number(i.when_condition_no_satisfied_fare),
                        not_free_shipping_area_fare: Number(i.not_free_shipping_area_fare),
                        unselected_area_fare: Number(i.unselected_area_fare),
                        free_shipping_condition: Number(i.free_shipping_condition),
                        unselected_area_type: Number(i.unselected_area_type),
                        free_shipping_type: Number(i.free_shipping_type),
                        template_type: Number(i.template_type),
                    }
                })
            })
            if(keyWord && res.list.length) {
                const template_type = Number(res.list[0].template_type);
                this.setState({fareType: template_type})
            }
            // const selectedFare = res.list.find((i: FareItem) => i.fare_template_id === this.props.selectedId)
            // if (selectedFare) {
            //     console.log(selectedFare);
            //     this.setState({
            //         fareType: Number(selectedFare.template_type)
            //     })
            // }
        })
    }

    selectFare(item: formatFareItem) {
        if (item.fare_template_id === this.state.selectedFareId) {
            this.setState({
                selectedFareId: undefined
            })
            return
        }
        this.setState({
            selectedFareId: item.fare_template_id
        })
    }

    onClose() {
        this.props.onClose()
    }

    onConfirm() {
        if (!this.state.fareList) return
        this.props.onConfirm(this.state.fareList.find((i: formatFareItem) => i.fare_template_id === this.state.selectedFareId))
        this.setState({
            shouldClose: true,
        })
    }
}

export default Fare