import React, { Component } from 'react'

import NinePic from '../total/NinePic'
import api from '../../netApi/index';

class MyOrderDetail extends Component {

    constructor(p) {
        super()
        this.state = p.location.state || {}
        if (!this.state.data) {
            p.history.replace('/Myorder')
        }
        api('order', 'detail')({
            orderid: window.btoa(this.state.data.orderid),
            types: 1
        }).then(res => {
            this.setState({
                data: {
                    ...this.state.data,
                    ...res.data.data
                }
            })
        })
    }

    // componentDidMount(){
    //     console.log(this.state.data)
    // }

    // componentDidUpdate(){
    //     console.log(this.state.data)        
    // }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <div className='fixedBG' style={{ background: '#F7F8F9' }}></div>
                <div className='tuan__panel'>
                    <div style={{ color: this.state.data.statusColor }}>{this.state.data.statusText}</div>

                    {Number(this.state.data.addressid) === 0 ? (<div style={{ marginTop: 24 }}>
                        <div style={{ fontWeight: 'bold', fontSize: 14, color: '#353535', marginBottom: 8 }}>购买人信息</div>
                        <div style={{ background: '#fafafa', borderRadius: 4, padding: 16 }}>
                            <div className='fcc' style={{ marginBottom: 8 }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>姓名</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.username}</div>
                            </div>
                            <div className='fcc' style={{ marginBottom: 8 }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>手机号</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.phone}</div>
                            </div>
                            <div className='fcc' style={{ marginBottom: 8 }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>地址</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.address}</div>
                            </div>
                        </div>
                    </div>) : ''}

                    {Number(this.state.data.addressid) > 0 ? (
                        <div>
                            <div className='fcc' style={{ flexDirection: 'column' }}>
                                <img style={{ maxWidth: '40vw', marginBottom: 30 }} alt='核销码' src={this.state.data.wxcode}></img>
                            </div>
                            <div style={{ width: '100%', }}>
                                <span style={{ fontSize: 12, color: '#8b8b8b', marginRight: 50 }}>签到码</span>
                                <span style={{ fontSize: 24, color: '#d2d2d2' }}>{this.state.data.exchangecode}</span>
                            </div>
                        </div>
                    ) : ''}

                    {Number(this.state.data.addressid) > 0 ? (<div style={{ marginTop: 24 }}>
                        <div style={{ fontWeight: 'bold', fontSize: 14, color: '#353535', marginBottom: 8 }}>提货点信息</div>
                        <div style={{ background: '#fafafa', borderRadius: 4, padding: 16 }}>
                            <div className='fcc' style={{ marginBottom: 8, alignItems: 'flex-start' }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>名称</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.pick_up_location ? this.state.data.pick_up_location.name : ''}</div>
                            </div>
                            <div className='fcc' style={{ marginBottom: 8, alignItems: 'flex-start' }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>地址</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.pick_up_location ? this.state.data.pick_up_location.address : ''}</div>
                            </div>
                            <div className='fcc' style={{ marginBottom: 8, alignItems: 'flex-start' }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>详细地址</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.pick_up_location ? this.state.data.pick_up_location.addressdetail : ''}</div>
                            </div>
                        </div>
                    </div>) : ''}

                    <div style={{ marginTop: 24 }}>
                        <div style={{ fontWeight: 'bold', fontSize: 14, color: '#353535', marginBottom: 8 }}>商品信息</div>
                        <div style={{ background: '#fafafa', borderRadius: 4, padding: 16 }}>
                            <div className='fcc' style={{ borderBottom: '.5px solid #ccc', padding: '6px 0' }}>
                                <div style={{ fontSize: 14, color: '#353535', width: '12em' }}>商品</div>
                                <div style={{ fontSize: 14, color: '#353535', minWidth: '4em', flex: 1 }}>价格</div>
                                <div style={{ fontSize: 14, color: '#353535', minWidth: '4em', flex: 1, textAlign: 'right' }}>数量</div>
                            </div>
                            {this.state.data.items.map((i, index) => {
                                return (
                                    <div className='fcc' key={i.goodsid} style={{ borderBottom: index !== (this.state.data.items.length - 1) ? '.5px solid #ccc' : '0', padding: '10px 0 ' }}>
                                        <div style={{ fontSize: 12, color: '#353535', width: '14em' }}>{i.name}({i.unit})</div>
                                        <div style={{ fontSize: 12, color: '#fb1919', minWidth: '4em', flex: 1 }}>￥{(i.price / 100).toFixed(2)}</div>
                                        <div style={{ fontSize: 12, color: '#353535', minWidth: '4em', flex: 1, textAlign: 'right' }}>x{i.num}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {(this.state.data.remark && this.state.data.remark !== '') ? (
                        <div style={{ background: '#fafafa', borderRadius: 4, padding: 16 }}>
                            <span style={{ fontSize: 14, color: 'var(--theme-main)' }}>用户备注：</span><span style={{ fontSize: 14, color: '' }}>{this.state.remark}</span>
                        </div>
                    ) : ''}

                    {this.state.data.sign.length !== 0 ? (<div style={{ marginTop: 24 }}>
                        <div style={{ fontWeight: 'bold', fontSize: 14, color: '#353535', marginBottom: 8 }}>用户填写信息</div>
                        <div style={{ background: '#fafafa', borderRadius: 4, padding: 16 }}>
                            {this.state.data.sign.map(s => {
                                return (
                                    <div key={s.id} style={{
                                        alignItems: 'flex-start',
                                        marginBottom: 6
                                    }} className='fcc' >
                                        <div style={{ minWidth: '5em', marginRight: '1em', fontSize: 12, width: '7em' }}>{s.name}</div>
                                        <div style={{ flex: 1, fontSize: 12 }}>
                                            {Number(s.type) === 1 ? (<div>
                                                <div style={{ fontSize: 12 }}>{s.value[0].content}</div>
                                            </div>) : ''}
                                            {Number(s.type) === 2 ? (<div>
                                                {s.value.map((c, index) => {
                                                    return (
                                                        <div key={index} style={{ fontSize: 12 }}>{c.content}</div>
                                                    )
                                                })}
                                            </div>) : ''}
                                            {Number(s.type) === 3 ? (
                                                <audio src={s.value[0].src} controls style={{ height: '1.4em', maxWidth: '12em' }} />
                                            ) : ''}
                                            {Number(s.type) === 4 ? (
                                                <NinePic list={s.value} />
                                            ) : ''}
                                            {Number(s.type) === 5 ? (
                                                <div>
                                                    <div style={{ fontSize: 12 }}>{s.value.name}</div>
                                                </div>
                                            ) : ''}
                                            {Number(s.type) === 6 ? (
                                                <div>
                                                    <div style={{ fontSize: 12 }}>{s.value[0].content}</div>
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>) : ''}

                    <div style={{ marginTop: 24 }}>
                        <div style={{ fontWeight: 'bold', fontSize: 14, color: '#353535', marginBottom: 8 }}>订单信息</div>
                        <div style={{ background: '#fafafa', borderRadius: 4, padding: 16 }}>
                            <div className='fcc' style={{ marginBottom: 8 }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>下单时间</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.createtime}</div>
                            </div>
                            <div className='fcc' style={{ marginBottom: 8 }}>
                                <div style={{ fontSize: 12, minWidth: '5em', color: '#8d8d8d' }}>订单号</div>
                                <div style={{ fontSize: 12, flex: 1, color: '#353535' }}>{this.state.data.payorderid}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default MyOrderDetail