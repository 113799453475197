import React, { Component } from 'react'
import { connect } from 'react-redux';
import Main from '../../high-component/Content'
// import BanUnLogin from '../../high-component/BanUnLogin'

import styled from 'styled-components';

import { OFFSET_LIMIT as LIMIT } from '../../data/options';
import { get } from '../../netApi/fetch';

const Thead = styled.td`
    color: #969696;
    font-size: 18px;
`

class Spread extends Component {

    getList(e) {
        if (this.offset >= this.state.total && this.state.total !== -1) {
            return
        }
        if (this.state.loading) return
        let bottom
        if (e) {
            const offsetY = window.pageYOffset
            const scrollHeight = e.srcElement.body.scrollHeight
            bottom = scrollHeight - offsetY
        }
        if (!bottom || bottom < 2000) {
            this.setState({ loading: true }, () => {
                get('/serviceprovider/rebateorderrecord', {
                    offset: this.offset,
                    limit: LIMIT
                }).then(res => {
                    this.offset = this.offset + res.list.length
                    this.setState({
                        list: [...this.state.list, ...res.list],
                        loading: false,
                        total: res.count
                    })
                })
                // api('proxyorder', 'spread')({
                //     type: 1,
                //     offset: this.offset,
                //     limit: LIMIT
                // }).then((res) => {
                //     if (Number(res.data.code) !== 0) {
                //         return
                //     }
                //     this.offset = this.offset + res.data.data.list.length
                //     this.setState({
                //         list: [...this.state.list, ...res.data.data.list],
                //         loading: false,
                //         total: res.data.data.total
                //     })
                // })
            })
        }
    }

    constructor(p) {
        super()
        this.state = {
            list: [],
            loading: false,
            total: -1
        }
        this.offset = 0
        if (p.didLogin) {
            if (Number(p.userType) !== -1) {
                if (Number(p.userType) === 5 || Number(p.userType) === 0) {
                    p.history.push('/payment/member/4')
                }
            }
        }
    }
    handleScroll = this.getList.bind(this)
    componentDidMount() {
        document.title = '订单详情'
        
        this.getList()
        document.addEventListener('scroll', this.handleScroll)
    }
    componentWillUnmount() {
        document.title = '接力GO'
        this.setState = () => { }
        document.removeEventListener('scroll', this.handleScroll)
    }
    componentDidUpdate(prev) {
        const p = this.props
        if (p.didLogin) {
            if (Number(p.userType) !== -1) {
                if (Number(p.userType) === 5 || Number(p.userType) === 0) {
                    p.history.push('/payment/member/4')
                }
            } else {
                if (!prev.didLogin && p.didLogin) {
                    this.getList()
                }
            }
        }
    }

    render() {
        return (
            <div>
                <div style={{ backgroundImage: 'linear-gradient(0deg, #ff3558 0%, #ff7846 100%)', flexDirection: 'column', height: 200 }} className='fcc'>
                    <span style={{ color: 'white', fontSize: 22 }}>累计订单(个)</span>
                    <span style={{ color: 'white', fontSize: 48 }}>{this.state.total >= 0 ? this.state.total : 0}</span>
                </div>
                <div style={{ padding: 20 }}>
                    <table style={{ tableLayout: 'fixed', width: '100%' }}>
                        <thead>
                            <tr>
                                <Thead>信息</Thead>
                                <Thead style={{textAlign: 'center' }}>金额(元)</Thead>
                                <Thead style={{textAlign: 'center' }}>来源</Thead>
                                <Thead style={{textAlign: 'right' }}>日期</Thead>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.list.map(item => {
                                return (
                                    <tr key={item.id} style={{borderBottom: '1px solid #eee'}}>
                                        <td style={{ fontSize: 14, color: '#1f1f1f' }}>{item.info}</td>
                                        <td style={{ fontSize: 20, color: '#fd5356', textAlign: 'center' }}>{(item.number / 100).toFixed(2)}</td>
                                        <td>
                                            <div class='fcc'>
                                                <img style={{borderRadius: '50%', width: 30, height: 30, minWidth: 30}} alt='' src={item.source? item.source.logo : ''} />
                                                <div>{item.source? item.source.name : ''}</div>
                                            </div>
                                        </td>
                                        <td style={{ fontSize: 14, color: '#969696', textAlign: 'right' }}>{item.createtime}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        isLogin: state.isLogin,
        userType: state.userType,
    }
}

// export default BanUnLogin(Main(connect(mapStateToProps)(Spread)))
export default (Main(connect(mapStateToProps)(Spread)))