import React, { useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { getToken, } from '../../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
import { confirm as Confirm } from '../../Method/Confirm';
import { API } from '../../../data/options'
import Loading from '../../Method/Loading'


interface props {
    onClose: () => void
    data: {

    }
}

let wrapper: Element

export const Border = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    & > div{
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        & > div{
            margin-right: 30px;
        }
        input{
            width: 333px;
            border: 1px solid #979797;
            border-radius: 4px;
            padding: 3px;
        }
    }
`

const BorderC = styled(Border)`
    div{
        margin-bottom: 0px;
        display: block;
    }
`

export default async function () {
    return new Promise(async (reslove, reject) => {
        function Category(props: props) {
            const data = useRef<FormData>()
            const [shouldClose, setShouldClose] = useState(false)
            return <Modal title='批量上传图片' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <Border>
                        <div>
                            <div>上传表格</div>
                            <label style={{ cursor: 'pointer' }} onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <input type='file' className='cur-pointer' accept='.zip' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                                    onChange={e => {
                                        if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                        const file = e.target.files[0]
                                        if ((file.size / 1024 / 1024) > 30) {
                                            message.warning('上传文件不能超过30M')
                                            return
                                        }
                                        const formData = new FormData()
                                        formData.append('file', file)
                                        formData.append('types', '1')
                                        formData.append('token', getToken())
                                        data.current = formData
                                    }} />
                                <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                            </label>
                        </div>
                        <div>
                            <p style={{ whiteSpace: 'pre-line', fontSize: 12, color: '#8b8b8b' }} >{`上传文件说明： 
                                1、仅限zip格式，不大于30M。 
                                2、压缩包内，各个文件夹（包含多张商品图片）以商品编码命名，或单张图片以商品编码命名
                                3、选中多个图片或文件夹，直接压缩，不要打包到一个文件夹再压缩`}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div>例如：</div>
                            <img alt='' src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/a4006rd0D7.png' />
                        </div>
                    </Border>
                </div>
            </Modal>

            function confirm() {
                if (!data.current) {
                    message.warn('请选择文件')
                    return
                }
                Loading.show()
                fetch(API + '/mall/batchcreategoodsimage', {
                    method: 'post', body: data.current, mode: 'cors',
                    credentials: 'include',
                }).then((res) => {
                    Loading.hide()
                    res.json().then((res) => {
                        if (res.code !== 0) {
                            Confirm({
                                text: res.message
                            })
                            return
                        }
                        setShouldClose(true)
                        Confirm({
                            text: <>
                                <div style={{ minHeight: 400 }}>
                                    <BorderC style={{ width: 600 }}>
                                        <div style={{}}>已成功上传{res.data.success_count || 0}个商品图片，{(res.data.fail_count || 0) + (res.data.not_exist_count || 0)}个上传失败</div>
                                        {(res.data.not_exist_count || 0) > 0 && <div style={{ display: 'block' }}>
                                            <div>
                                                上传失败图片编码为：<span style={{ color: 'var(--theme-main)' }}>{(res.data.not_exist_no || []).join('、')}</span>
                                            </div>
                                            <div>
                                                （商品信息库中不存在以上编码的商品，请用正确的商品编码命名文件夹或图片）
                                            </div>
                                        </div>}
                                    </BorderC>
                                </div>
                            </>,
                            title: '温馨提示'
                        })
                        reslove(true)
                    })
                }).catch(() => {
                    message.error('网络错误')
                })
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}