
import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter, RouteComponentProps, match } from 'react-router-dom';
import Modal from '../../total/Modal';
import Switch from '../../total/Switch';
import { message } from 'antd';

interface props extends RouteComponentProps {
    goodslist: editgood[]
    salemodel: number | string,
    isshow_allseller_rank: boolean,
    isshow_allseller_order: boolean,
    can_usemyid_start: boolean,
    only_use_my_pickup_station: boolean,

    pid?: number | string,

    canchangesalemodel: any,

    disabled: boolean

    close?: () => void,
    confirm: (any: any) => void

    match: editmatch

    isHYD: boolean,
    _params: any
}

interface editmatch extends match {
    params: {
        mode: string
    }
}

interface state {
    goodslist: editgood[]
    salemodel: number | string,

    options: {
        isshow_allseller_rank: boolean,
        isshow_allseller_order: boolean,
        can_usemyid_start: boolean,
        only_use_my_pickup_station: boolean,
    }

    distri_show_batch: boolean,
    distri_show_batch_type: number,

    batchPrice1: number | string,
    batchPrice2: number | string,
}

interface editgood extends good {
    attribute: editAttr[]
}
interface editAttr extends good.attr {
    value?: string | number,
    minlimit_price: number | string,
    notcanupdatebutcandothis: boolean,
    distribution_price?: {
        nowprice?: number | string,
        baseprice?: number | string,
        minlimit_price?: number | string,
        sellerprice?: number | string
    }

}

const BatchSettingPanel = styled.div`
    transition: all .3s;
    overflow: hidden;
    max-height: ${(props: { distri_show_batch: boolean }) => { return props.distri_show_batch ? 60 : 0 }}px;
    box-sizing: border-box;
    padding: ${(props: { distri_show_batch: boolean }) => { return props.distri_show_batch ? '.4em' : '0 .4em' }};
    margin-top: .6em;
    background: #eee;
    position: relative;
`

class DistributionSetting extends Component<props, state> {

    constructor(p: props) {
        super(p)
        this.state = {
            distri_show_batch: false, // 开关批量设置
            distri_show_batch_type: 1, // 批量设置的方式
            salemodel: p.salemodel,
            goodslist: [],
            batchPrice1: '', // 批量固定金额
            batchPrice2: '', // 批量百分比
            options: {
                isshow_allseller_rank: p.isshow_allseller_rank || false, // 查看好友排行
                isshow_allseller_order: p.isshow_allseller_order || false, // 查看好友订单
                can_usemyid_start: p.can_usemyid_start || false, // 用自己id开团
                only_use_my_pickup_station: p.only_use_my_pickup_station || false,
            },
        }
    }

    componentDidMount() {
        if (this.props.goodslist && !this.state.goodslist[0] && this.props.goodslist.length > 0) {
            this.setState({
                goodslist: this.props.goodslist
            })
        }
    }

    componentDidUpdate() {
        console.log('this.props.goodslist',this.props.goodslist);
        console.log('this.props._params', this.props._params)
        if (this.props.goodslist && !this.state.goodslist[0] && this.props.goodslist.length > 0) {
            this.setState({
                goodslist: this.props.goodslist
            })
        }
    }


    render() {
        return (
            <Modal style={{ content: { maxHeight: '70vh', overflowY: 'auto' } }} banMaskClose={true}
                confirmText='保存设置' close={() => { this.props.close && this.props.close() }} cancel={true} title='帮卖设置'
                confirm={this.confirm.bind(this)}
            >
                {!this.props.isHYD && <>
                    <div className='fc'>
                        <div onClick={e => { if (!this.props.canchangesalemodel) return; this.setState({ salemodel: 1 }) }} className={`edittuan__distri-setting__salemodel ${this.state.salemodel === 1 ? 'edittuan__distri-setting__salemodel_active' : ''}`}>对外价格统一</div>
                        <div onClick={
                            e => { 
                                if(this.props._params && this.props._params.multilprice==1&&this.props._params.is_hssq == 1) {
                                    return;
                                }
                                if (!this.props.canchangesalemodel) return; this.setState({ salemodel: 2 }) 
                            }
                        } className={`edittuan__distri-setting__salemodel ${this.state.salemodel === 2 ? 'edittuan__distri-setting__salemodel_active' : ''}`}>可自由定价</div>
                    </div>
                    <div className='edittuan__distri-setting__intro' onClick={() => { }}>
                        {this.state.salemodel === 1 ? (
                            '选择此模式后，好友转发的活动商品将按照统一的价格对外销售，你可以在下方设置佣金，奖励帮你卖货的好友。'
                        ) : ''}
                        {this.state.salemodel === 2 ? (
                            '选择此模式后，帮你卖货的好友将可自由设定商品对外销售的价格，供货价即为他们从你手中拿货的价格。'
                        ) : ''}
                    </div>
                </>}

                <div className='fbc' style={{ marginTop: 8 }}>
                    <div className='edittuan__switchitem__text fbc'>好友开团时使用我的身份</div>
                    <Switch disabled={this.props.disabled} checked={this.state.options.can_usemyid_start} onClick={() => { this.setState({ options: { ...this.state.options, can_usemyid_start: !this.state.options.can_usemyid_start } }) }} />
                </div>
                <div className='fbc' style={{ marginTop: 8 }}>
                    <div className='edittuan__switchitem__text fbc'>允许所有好友共享订单</div>
                    <Switch disabled={this.props.disabled} checked={this.state.options.isshow_allseller_order} onClick={() => { this.setState({ options: { ...this.state.options, isshow_allseller_order: !this.state.options.isshow_allseller_order } }) }} />
                </div>
                <div className='fbc' style={{ marginTop: 8 }}>
                    <div className='edittuan__switchitem__text fbc'>允许好友查看我的好友排行榜</div>
                    <Switch disabled={this.props.disabled} checked={this.state.options.isshow_allseller_rank} onClick={() => { this.setState({ options: { ...this.state.options, isshow_allseller_rank: !this.state.options.isshow_allseller_rank } }) }} />
                </div>
                {this.props.match.params.mode !== 'edit' ? <div className='fbc' style={{ marginTop: 8 }}>
                    <div className='edittuan__switchitem__text fbc'>好友开团时只能使用我的自提点</div>
                    <Switch disabled={this.props.disabled} checked={this.state.options.only_use_my_pickup_station} onClick={() => {
                        console.log(this.state, this.props)
                        this.setState({ options: { ...this.state.options, only_use_my_pickup_station: !this.state.options.only_use_my_pickup_station } })
                    }}
                    />
                </div> : null}
                {!this.props.isHYD && <div className='edittuan__distri-setting__tobatch' onClick={() => { this.setState({ distri_show_batch: true }) }}>批量设置供货价</div>}
                {!this.props.isHYD && <BatchSettingPanel className='edittuan__distri-setting__batch' distri_show_batch={this.state.distri_show_batch} >
                    <div className='fc' style={{ marginBottom: 6, cursor: 'pointer' }} onClick={() => { this.setState({ distri_show_batch_type: 1 }) }}>
                        <div className={`edittuan__distri-setting__batch__selected-icon ${this.state.distri_show_batch_type === 1 ? 'edittuan__distri-setting__batch__selected-icon_active' : ''}`}></div>
                        <div style={{ fontSize: 14, color: this.state.distri_show_batch_type === 1 ? '#333' : '#999', minWidth: '10em', }}>批量设置金额</div>
                        <div>
                            <input type='digit' disabled={this.state.distri_show_batch_type === 2} value={this.state.batchPrice1}
                                style={{ fontSize: 12, border: 0, padding: '1px .7em', background: this.state.distri_show_batch_type === 2 ? 'transparent' : '' }}
                                placeholder='请输入金额' onChange={(e) => {
                                    if (isNaN(Number(e.target.value))) return
                                    this.setState({ batchPrice1: e.target.value })
                                }}
                            />
                        </div>
                        <div style={{ marginLeft: '.4em', color: this.state.distri_show_batch_type === 1 ? '#333' : '#999' }}>元</div>
                    </div>
                    <div className='fc' style={{ marginBottom: 6, cursor: 'pointer' }} onClick={() => { this.setState({ distri_show_batch_type: 2 }) }}>
                        <div className={`edittuan__distri-setting__batch__selected-icon ${this.state.distri_show_batch_type === 2 ? 'edittuan__distri-setting__batch__selected-icon_active' : ''}`}></div>
                        <div style={{ fontSize: 14, color: this.state.distri_show_batch_type === 2 ? '#333' : '#999', minWidth: '10em', }}>批量按百分比设置</div>
                        <div>
                            <input type='digit' disabled={this.state.distri_show_batch_type === 1} value={this.state.batchPrice2}
                                style={{ fontSize: 12, border: 0, padding: '1px .7em', background: this.state.distri_show_batch_type === 1 ? 'transparent' : '' }}
                                placeholder='请输入百分比' onChange={(e) => {
                                    if (isNaN(Number(e.target.value))) return
                                    this.setState({ batchPrice2: e.target.value })
                                }}
                            />
                        </div>
                        <div style={{ marginLeft: '.4em', color: this.state.distri_show_batch_type === 2 ? '#333' : '#999' }}>%</div>
                    </div>
                    <div className='edittuan__distri-setting__batch__buttons'>
                        <button className='edittuan__distri-setting__batch__button cur-pointer' onClick={() => { this.setState({ distri_show_batch: false }) }}>取消</button>
                        <button className='edittuan__distri-setting__batch__button cur-pointer' style={{ color: 'var(--theme-main)' }} onClick={this.confirmBatch.bind(this)}>确定</button>
                    </div>
                </BatchSettingPanel>}

                {/* 规格列表 */}
                {!this.props.isHYD && this.state.goodslist.map((good, i) => {
                    return good.attribute.map((attr, j) => {
                        let value
                        if (attr.value || attr.value === '') {
                            value = attr.value
                        } else {
                            if (attr.distribution_price) {
                                if (this.state.salemodel === 1) {
                                    value = (Number(attr.distribution_price.nowprice) - Number(attr.distribution_price.sellerprice)) / 100
                                } else if (this.state.salemodel === 2) {
                                    value = Number(attr.distribution_price.sellerprice) / 100
                                    attr.minlimit_price = (Number(attr.distribution_price.minlimit_price) / 100) || ''
                                }
                            } else {
                                value = ''
                            }
                        }
                        attr.value = value || ''
                        if (attr.notcanupdatebutcandothis === undefined) {
                            attr.notcanupdatebutcandothis = false
                            if (attr.value === '') { attr.notcanupdatebutcandothis = true }
                        }
                        return <div key={attr.id} className='edittuan__distri-setting__attr' >
                            <img alt={good.name} src={good.logo} style={{ width: 70, height: 70, minWidth: 70, marginRight: 12 }} className='img-center' />
                            <div style={{
                                flex: 1,
                                // width: '80%' 
                            }}>
                                <div className='edittuan__distri-setting__attr__name'>{good.name}</div>
                                <div className='edittuan__distri-setting__attr__unit'>规格：{attr.unit}</div>
                                {this.state.salemodel === 1 ? (
                                    <div className='fbc' style={{ fontSize: 12, flexWrap: 'wrap' }}>
                                        <div style={{ color: '#FD5755' }}>
                                            统一零售价￥{(Number((attr.distribution_price && attr.distribution_price.nowprice) ? attr.distribution_price.nowprice : attr.price) / 100).toFixed(2)}
                                            {/* 测试统一零售价￥{Number(attr.distribution_price)/100} */}
                                        </div>
                                        <div>
                                            <span>佣金</span>
                                            <input disabled={this.props.disabled && !attr.notcanupdatebutcandothis} type='digit' placeholder='卖货佣金需小于统一零售价' style={{ border: 0, padding: 4, width: '13em' }}
                                                value={attr.value}
                                                onChange={(e) => {
                                                    if (isNaN(Number(e.target.value))) return
                                                    const data = this.state.goodslist
                                                    if (!data[i].attribute[j].distribution_price) {
                                                        data[i].attribute[j].distribution_price = {
                                                            nowprice: data[i].attribute[j].price,
                                                            baseprice: 0,
                                                            sellerprice: undefined,
                                                        }
                                                    }
                                                    data[i].attribute[j].value = e.target.value
                                                    this.setState({
                                                        goodslist: data
                                                    })
                                                }}
                                            />
                                            <span>元</span>
                                        </div>
                                    </div>
                                ) : ''}
                                {(this.state.salemodel === 2) ? (<>
                                    <div className='fbc' style={{ fontSize: 12, flexWrap: 'wrap' }}>
                                        <div style={{ color: '#FD5755' }}>
                                            参考价￥{(Number(attr.price) / 100).toFixed(2)}
                                        </div>
                                        <div>
                                            <span>供货价</span>
                                            <input disabled={this.props.disabled && !attr.notcanupdatebutcandothis}
                                                type='digit' placeholder='请输入供货价' style={{ border: 0, padding: 4, width: '9em' }}
                                                value={(attr.value && !isNaN(Number(attr.value))) ? attr.value : (attr.distribution_price && attr.distribution_price.sellerprice) ? ((Number(attr.distribution_price.sellerprice) / 100)) : ''}
                                                onChange={(e) => {
                                                    if (isNaN(Number(e.target.value))) return
                                                    const data = this.state.goodslist
                                                    if (!data[i].attribute[j].distribution_price) {
                                                        data[i].attribute[j].distribution_price = {}
                                                    }
                                                    data[i].attribute[j].value = e.target.value
                                                    this.setState({
                                                        goodslist: data
                                                    }, () => {
                                                    })
                                                }}
                                            />
                                            <span>元</span>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 12, flexWrap: 'wrap' }}>
                                        <span>零售价不低于</span>
                                        <input disabled={this.props.disabled && !attr.notcanupdatebutcandothis}
                                            type='digit' placeholder='不填则不限制好友售价' style={{ border: 0, padding: 4, width: '12em' }}
                                            value={attr.minlimit_price || ''}
                                            onChange={(e) => {
                                                if (isNaN(Number(e.target.value))) return
                                                const data = this.state.goodslist
                                                if (!data[i].attribute[j].distribution_price) {
                                                    data[i].attribute[j].distribution_price = {}
                                                }
                                                data[i].attribute[j].minlimit_price = e.target.value
                                                this.setState({
                                                    goodslist: data
                                                }, () => {
                                                })
                                            }}
                                        />
                                        <span>元</span>
                                    </div>
                                </>) : ''}
                            </div>
                        </div>
                    })
                })}
            </Modal>)
    }

    confirmBatch() {
        if (this.state.distri_show_batch_type === 1) {
            for (let i = 0; i < this.state.goodslist.length; i++) {
                const good = this.state.goodslist[i];
                for (let j = 0; j < good.attribute.length; j++) {
                    const attr = good.attribute[j];
                    if (this.props.disabled && !attr.notcanupdatebutcandothis) break;
                    if (!attr.distribution_price) {
                        attr.distribution_price = {}
                    }
                    if (this.state.salemodel === 1) {
                        attr.value = Number(this.state.batchPrice1).toFixed(2)
                    } else if (this.state.salemodel === 2) {
                        attr.value = Number(this.state.batchPrice1).toFixed(2)
                    }
                }
            }
        } else if (this.state.distri_show_batch_type === 2) {
            for (let i = 0; i < this.state.goodslist.length; i++) {
                const good = this.state.goodslist[i];
                for (let j = 0; j < good.attribute.length; j++) {
                    const attr = good.attribute[j];
                    if (this.props.disabled && !attr.notcanupdatebutcandothis) break;
                    if (!attr.distribution_price) {
                        attr.distribution_price = {}
                    }
                    if (this.state.salemodel === 1) {
                        attr.value = ((Number(this.state.batchPrice2) / 100) * (Number(attr.price) / 100)).toFixed(2)
                    } else if (this.state.salemodel === 2) {
                        attr.value = ((Number(this.state.batchPrice2) / 100) * (Number(attr.price) / 100)).toFixed(2)
                    }
                }
            }
        }
        this.setState({ goodslist: this.state.goodslist })
    }

    confirm() {
        if (this.props.isHYD) {
            this.props.confirm({ salemodel: this.state.salemodel, options: this.state.options })
            this.props.close && this.props.close()
            return true
        }
        const distri_setting = []
        for (let i = 0; i < this.state.goodslist.length; i++) {
            const good = this.state.goodslist[i];
            for (let j = 0; j < good.attribute.length; j++) {
                const attr = good.attribute[j];
                const data: any = {
                    goodsid: good.goodsid,
                    goodsattrid: attr.id,
                }
                if (!attr.value && attr.value !== 0) { message.warning(good.name + '请完成帮卖设置'); return false }

                if (this.state.salemodel === 1) {
                    if (!this.props.pid) {
                        data.baseprice = 0
                        data.nowprice = (attr.distribution_price && attr.distribution_price.nowprice) ? attr.distribution_price.nowprice : attr.price
                        data.sellerprice = (data.nowprice - Number(attr.value) * 100)
                    }
                } else if (this.state.salemodel === 2) {
                    if (!this.props.pid) {
                        data.baseprice = 0
                        data.nowprice = attr.price
                        data.sellerprice = Number(attr.value) * 100
                    }
                }
                if (data.sellerprice < 0) {
                    message.error(`${good.name}(${attr.unit})的${this.state.salemodel === 1 ? '佣金' : '供货价'}有误，请检查`)
                    return false
                }
                data.input_number = Number(attr.value) * 100
                data.minlimit_price = Number(attr.minlimit_price) * 100
                distri_setting.push(data)
            }
        }
        console.log(distri_setting)
        this.props.confirm({ distri_setting, salemodel: this.state.salemodel, options: this.state.options })
        this.props.close && this.props.close()
        return true
    }
}

export default withRouter(DistributionSetting)