import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink, withRouter } from "react-router-dom";
import { toggleSidebar, logout, set_login_user_data, waitingLogin, setusertype, setOrder, saveMallInfo, setLoginBindInfo } from '../../reducers/index'

// import can_change_admin_list from '../../data/can_change_admin';
import banSidebarList from '../../data/banSidebarList';
import { appname } from '../../data/options'

import styled from 'styled-components';

// import Modal from './Modal'

import { get } from '../../netApi/fetch.ts';

import TuanSelectPanel from '../order/TuanSelectPanel';

import { SIDEBAR_WIDTH, } from '../../data/style';

const LinkPanel = styled.div`
    width: 100%; 
    font-size: 16px ;
    padding: 12px 0px 12px 20px;
    position: relative;
    .active &, &.active {
        font-weight: 600;
        background: #f5f5f5;
    }
    &:hover{
        background: var(--theme-main) ;
        color: #fff !important;
        font-weight: 600;
    }
    .subMenu{
        position: absolute;
        left: ${SIDEBAR_WIDTH}px;
        top: 0;
        width: ${SIDEBAR_WIDTH}px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 1px 0px 5px 0px #cccccc; 
        display: none;
        flex-direction: column;
        div:not(:hover){
            color: #999;
            font-weight: 400;
        }
        div:hover{
            font-weight: 600;
        }
    }
    &:hover .subMenu{
        display: flex;
    }
`

const SidebarMain = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    z-index: 50;
    height: 100vh;
    overflow-y: visible;
    overflow-x: visible;
    position: fixed;
    top: 0;
    left: 0;
    transition: .2s linear;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(208, 208, 208, 0.50);

    &>div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .navList {
        display: block!important;
        color: #999;
        flex: 1;
        overflow-y: visible;
        overflow-x: visible;
        justify-content: flex-start;
        a{
            color: inherit;
        }

        .active{
            color: var(--theme-main) ;
        }
        &>* {
            width: 100%;
            font-size: 16px;

            &:hover {
                text-decoration: none;
            }
        }

        i{
            margin-right: 10px;
        }
    }

    @media print {
        display: none;
    }
`

// let touchStart = { x: 0, y: 0, t: 0 }, touchEnd = { x: 0, y: 0, t: 0 }

// let count;

class Sidebar extends Component {
    constructor(p) {
        super()
        this.state = {
            sidebarLeft: 0,
            sidebarWidth: SIDEBAR_WIDTH,
            isShow: true,
            adminList: [],
            banSidebar: false
        }
        const width = document.body.clientWidth
        if (width < 993) {
            p.toggleSidebar(false)
        }
    }

    getData() {
        get('/favoritefunction/getlist', {
            app: appname,
            types: 1,
            sid: this.props.login_user_data.self_sid
        }).then(({ notuseorders }) => {
            this.setState({
                notuseorders: notuseorders ? String(notuseorders) : ''
            })
        })
    }
    componentDidMount() {
        // document.addEventListener('touchstart', this.tsHandle)
        // document.addEventListener('touchend', this.teHandle)

        if (this.props.is_Login) {
            this.getData()
        }
        // count = setInterval(() => {
        //     if (!this.props.is_Login) return
        //     this.getData()
        // }, 120000);
    }
    componentWillUnmount() {
        // try {
        //     document.removeEventListener('touchstart', this.tsHandle)
        // } catch (error) {
        //     console.log(error)
        // }
        // // clearInterval(count)
        // document.removeEventListener('touchend', this.teHandle)
    }

    componentDidUpdate(props) {
        if (this.props.is_Login && !props.is_Login) {
            this.getData()
        }
        if (props.login_user_data.is_branch === undefined) {
            this.shouldLoadBranch = true
        }
        if (this.shouldLoadBranch && props.login_user_data.is_branch === true) {
            this.getBranch()
        }

        // 路由变更时的处理
        (() => {
            if (this.props.location.pathname === props.location.pathname) return
            const { pathname } = this.props.location
            if (/\/goods\//.test(pathname)) {
                if (!this.state.showGood) {
                    this.setState({ showGood: true })
                }
                if (/[123]/.test(pathname) && !this.state.showGoodData) {
                    this.setState({ showGoodData: true })
                }
            }
        })()
    }

    // toggleSidebar() {
    //     const isShow = !this.props.sidebarOpen
    //     this.props.toggleSidebar(isShow)
    // }
    click(e) {
        e.preventDefault()
        e.stopPropagation()
    }
    logout() {
        get('/admin/logout', {
            openid: this.props.login_user_data.openid
        }).then(() => {
            window.location.replace('/')
        })
        this.props.logout()
    }

    getBranch() {
        this.shouldLoadBranch = false
        get('/index/getloginbindinfo', {}).then(res => {
            this.props.setLoginBindInfo(res)
            this.props.saveMallInfo(res.bind_mall)
            this.setState({
                branch: res
            })
        })
    }
    render() {
        const inOrders = !!this.props.location.pathname.match('orders') && !this.props.location.pathname.match('mall')
        const isBranch = Boolean(this.props.login_user_data.is_branch)
        return (
            <>
                {/* <div>
                    <i className='iconfont icon-pc_gengduo' style={{}} id='toggleSidebarIcon' onClick={this.toggleSidebar.bind(this)} />
                </div> */}
                <SidebarMain style={{
                    width: this.state.sidebarWidth
                }}>
                    <div style={{ height: 50 }}>
                        <img alt='接力GO图标' style={{ height: '70%' }} src={require('../../images/logo.png')} />
                    </div>

                    <div className='navList'>
                        {!this.props.is_Login ? (<NavLink exact to='/'>
                            <LinkPanel className='flex-box'>首页</LinkPanel>
                        </NavLink>) : ''}

                        {this.props.is_Login ? (<NavLink to='/index'>
                            <LinkPanel className='flex-box'  >
                                <i className='iconfont icon-shouye2' />
                                首页
                            </LinkPanel>
                        </NavLink>) : ''}

                        {this.props.is_Login ? (<div>
                            <LinkPanel className={`flex-box ${inOrders ? 'active' : ''}`}>
                                <i className='iconfont icon-dingdan1' />
                                订单
                                <div className='subMenu' style={{ width: '8em' }} >
                                    {!isBranch && <LinkPanel className={`flex-box cur-pointer ${inOrders ? 'active' : ''}`} style={{ height: 'unset', paddingLeft: 20 }} onClick={() => {
                                        this.setState({ showSelectedTuanPanel: true })
                                    }}>
                                        <i className='iconfont icon-huodongdingdan2' />
                                        活动订单
                                    </LinkPanel>}
                                    {this.props.login_user_data.bind_mall_id && <NavLink to='/index/mall/orders'>
                                        <LinkPanel className='flex-box' style={{ paddingLeft: 20, height: 'unset' }}>
                                            <i className='iconfont icon-shangchengdingdan' />
                                            <span>商城订单</span>
                                        </LinkPanel>
                                    </NavLink>}
                                </div>
                            </LinkPanel>
                        </div>) : ''}


                        {(this.props.is_Login && !isBranch) ? (<div>
                            <LinkPanel className='flex-box'>
                                <i className='iconfont icon-guanyuTA' />
                                好友
                                <div className='subMenu' style={{ width: '8.5em' }} >
                                    <NavLink to='/follow/list'>
                                        <LinkPanel className='flex-box' style={{ paddingLeft: 10, height: 'unset' }}>
                                            <i className='iconfont icon-guanzhuwode1' />
                                            <span>关注我的</span>
                                        </LinkPanel>
                                    </NavLink>
                                    <NavLink to='/fs/list'>
                                        <LinkPanel className='flex-box' style={{ paddingLeft: 10, height: 'unset' }}>
                                            <i className='iconfont icon-zhengzaibangwomai1' />
                                            <span>正在帮我卖</span>
                                        </LinkPanel>
                                    </NavLink>
                                    <NavLink to='/ul/list'>
                                        <LinkPanel className='flex-box' style={{ paddingLeft: 10, height: 'unset' }}>
                                            <i className='iconfont icon-wozhengzaibangmai1' />
                                            <span>我正在帮卖</span>
                                        </LinkPanel>
                                    </NavLink>
                                </div>
                            </LinkPanel>
                        </div>) : ''}

                        {this.props.is_Login && !isBranch ? (<div>
                            <LinkPanel className='flex-box' >
                                <i className='iconfont icon-caiwu' />
                                财务
                                <div className='subMenu' style={{ width: '8.5em' }}>
                                    <NavLink to='/finance'>
                                        <LinkPanel className='flex-box' style={{ paddingLeft: 10 }}>
                                            <i className='iconfont icon-duizhangdan' />
                                            <span>导出对账单</span>
                                        </LinkPanel>
                                    </NavLink>
                                </div>
                            </LinkPanel>
                        </div>) : ''}

                        {this.props.is_Login && !isBranch ? (<NavLink to='/eventOrder'>
                            <LinkPanel className='flex-box'  >
                                <i className='iconfont icon-baoming1' />
                                报名
                            </LinkPanel>
                        </NavLink>) : ''}

                        {this.props.userType && !isBranch && [1, 2, 3, 4].includes(Number(this.props.userType)) ? (<NavLink to='/promotion'>
                            <LinkPanel className='flex-box'  >
                                <i className='iconfont icon-tuiguang' />
                                推广
                            </LinkPanel>
                        </NavLink>) : ''}
                    </div>
                </SidebarMain>

                {this.state.showSelectedTuanPanel ? <TuanSelectPanel onClose={() => { this.setState({ showSelectedTuanPanel: false }) }} /> : null}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: (sidebarOpen) => {
            dispatch(toggleSidebar(sidebarOpen))
        },
        logout: () => {
            dispatch(logout())
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        waitingLogin: () => {
            dispatch(waitingLogin(false))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
        setOrderList: (order) => {
            dispatch(setOrder(order))
        },
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
        setLoginBindInfo: (data) => {
            dispatch(setLoginBindInfo(data))
        },
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        sidebarType: state.sidebarType,
        sidebarOpen: state.sidebarOpen,
        is_Login: state.is_Login,
        login_user_data: state.login_user_data,
        userType: state.userType,
        order: state.order
    }
}

const SB = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar))

class SbWraper extends Component {

    state = {}

    componentDidMount() {
        let ban = false
        for (let i = 0; i < banSidebarList.length; i++) {
            const item = banSidebarList[i];
            const reg = new RegExp(item, 'g')
            if (reg.test(this.props.location.pathname)) {
                ban = true
                break
            }
        }
        if (ban !== this.state.banSidebar) {
            this.setState({
                banSidebar: ban
            })
            // this.props.toggleSidebar(false)
        }
    }

    componentDidUpdate() {
        let ban = false
        for (let i = 0; i < banSidebarList.length; i++) {
            const item = banSidebarList[i];
            const reg = new RegExp(item, 'g')
            if (reg.test(this.props.location.pathname)) {
                ban = true
                break
            }
        }
        if (ban !== this.state.banSidebar) {
            this.setState({
                banSidebar: ban
            })
            // this.props.toggleSidebar(false)
        }
    }

    render() {
        if (this.state.banSidebar) return <></>
        return <SB />
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SbWraper))