import React, { Component } from 'react'
import { connect } from 'react-redux'
import Wx_sign from '../../high-component/Wx_sign'
// import Content from '../../high-component/Content'
import api from '../../netApi/index';
import pay from '../../data/pay';
import Modal from '../total/Modal';
import Textarea from '../total/Input/Textarea.tsx';
// import { LIMIT } from '../../data/options';
import { Checkbox, Radio } from 'antd';
import { post } from '../../netApi/fetch';
import { selectAddress } from '../../reducers';

class Settle extends Component {

    addressOffset = 0
    addressCount = Infinity

    constructor(p) {
        super()
        window.scroll(0, 0)
        this.state = p.location.state
        this.state.remark = ''
        const list = []
        this.state.totalnum = 0
        this.state.totalprice = 0
        for (let i = 0; i < this.state.goodslist.length; i++) {
            const num = this.state.goodslist[i].num;
            for (let j = 0; j < num.length; j++) {
                const item = num[j];
                if (item.num > 0) {
                    this.state.totalnum += item.num
                    list.push({
                        goodsid: item.id,
                        sku_id: item.id,
                        num: item.num,
                    })
                }
            }
        }
        this.state.list = list
        this.state.sign = this.state.data.sign.user.map(i => {
            return { data: i, value: '' }
        })
        const data = {
            types: 1,
            tuanid: this.state.data.id,
            isdistribution: this.state.data.isdistribution,
            distributionid: this.state.data.distributionid,
            items: list
        }
        console.log(p);
        if (p.selectedAddress && p.selectedAddress.type) {
            const { value, type } = p.selectedAddress
            if (type === 'address') {
                data.address = value.province + value.city + value.area + value.addressdetail
                data.addressinfo = {
                    province: value.province,
                    city: value.city,
                    area: value.area,
                    town: "",
                    address: value.addressdetail,
                }
            }
            if (type === 'point') {
                const { address } = value
                data.address = address.province + address.city + address.area + address.addressname + address.addressdetail
                data.addressinfo = {
                    province: address.province,
                    city: address.city,
                    area: address.area,
                    town: "",
                    address: address.address,
                }
            }
            if (type === 'wechat') {
                data.addressinfo = value.addressinfo
                data.address = value.addressinfo.province + value.addressinfo.city + value.addressinfo.area + value.addressinfo.address
            }
        }
        post('/order/getprice', data).then(res => {
            this.time_slice_info = res.time_slice_info
            this.setState({
                totalprice: res.totalprice,
                payprice: res.payprice,
                discount_price: res.discount_price,
                discount: res.discount,
            }, () => {
                // this.getCoupon()
            })
        })
    }

    render() {
        return (<div style={{
            padding: 20, maxWidth: 756, margin: 'auto'
        }}>

            {/* 背景色 */}
            <div style={{
                position: "fixed",
                background: '#f7f8f9',
                left: 0, top: 0, width: '100vw', height: '100vh',
                zIndex: -2
            }}></div>

            {/* 自提点/收获地址 */}
            <div className='tuan__panel fcc' onClick={() => {
                if (this.state.data.takeway === 2) {
                    this.setState({ selectAddress: true })
                    return
                }
                this.props.history.push('/useraddress' + (this.state.data.express_to_pick_up_point ? `/${this.state.data.id}` : ''))

            }}>
                <i className='iconfont icon-dingwei2' style={{
                    color: 'rgb(253, 100, 103)', fontSize: 30, lineHeight: 1, marginRight: 12
                }}></i>
                {Number(this.state.data.takeway) === 1 ? (
                    <div style={{ flex: 1, }}>
                        {(() => {
                            if (!this.props.selectedAddress || !this.props.selectedAddress.type) return (<>
                                <div></div>
                                <div style={{ fontSize: 14, color: '#8b8b8b', lineHeight: 1.2 }}>
                                    添加配送地址
                                </div>
                            </>)
                            if (this.props.selectedAddress.type === 'point') {
                                const address = this.props.selectedAddress.value
                                return <>
                                    <div>{address.address.name}</div>
                                    <div style={{ fontSize: 14, color: '#8b8b8b', lineHeight: 1.2 }}>
                                        {address.address.address}
                                    </div>
                                </>
                            }
                            if (this.props.selectedAddress.type === 'address') {
                                const address = this.props.selectedAddress.value
                                return <>
                                    <div>{address.contacts + ' ' + address.telphone}</div>
                                    <div style={{ fontSize: 14, color: '#8b8b8b', lineHeight: 1.2 }}>
                                        {`${address.province}${address.city}${address.area}${address.addressdetail}`}
                                    </div>
                                </>
                            }
                            if (this.props.selectedAddress.type === 'wechat') {
                                const address = this.props.selectedAddress.value
                                return <>
                                    <div>{address.username + ' ' + address.phone}</div>
                                    <div style={{ fontSize: 14, color: '#8b8b8b', lineHeight: 1.2 }}>
                                        {`${address.addressinfo.province}${address.addressinfo.city}${address.addressinfo.area}${address.addressinfo.address}`}
                                    </div>
                                </>
                            }
                            return null
                        })()}
                    </div>
                ) : (
                    <div style={{ flex: 1, }}>
                        <div>{this.state.address ? this.state.address.name : ''}</div>
                        <div style={{ fontSize: 14, color: '#8b8b8b', lineHeight: 1.2 }}>
                            {this.state.address ? `${this.state.address.address}${this.state.address.addressdetail}` : '选择自提点'}
                        </div>
                    </div>
                )}
                <i className='iconfont icon-you' style={{ color: '#d8d8d8', fontSize: 14, lineHeight: 1, marginLeft: 12 }}></i>
            </div>

            {/* 问题 */}
            <div className=''>
                {this.state.sign ? this.state.sign.map(i => {
                    // if (!Number(i.data.systemid)) return ''
                    return (<div key={i.data.id} style={{ display: 'block' }}>
                        <div style={{ fontSize: 16, color: 'rgb(153,153,153)', marginBottom: 6 }}>
                            {i.data.name}
                            {Number(i.data.is_must) === 1 ? (<span style={{ background: 'var(--theme-main)', fontSize: 8, color: '#fff', padding: '2px 4px', borderRadius: 4, marginLeft: 6 }}>必填</span>) : ''}
                            {Number(i.data.is_pub) === 1 ? (<span style={{ background: 'var(--theme-main)', fontSize: 8, color: '#fff', padding: '2px 4px', borderRadius: 4, marginLeft: 6 }}>公开</span>) : ''}
                            {Number(i.data.is_pub) === 2 ? (<span style={{ background: 'var(--theme-main)', fontSize: 8, color: '#fff', padding: '2px 4px', borderRadius: 4, marginLeft: 6 }}>部分公开</span>) : ''}
                        </div>
                        {(() => {
                            switch (Number(i.data.type)) {
                                case 1:
                                    return (
                                        <label style={{ display: 'block' }} className='tuan__panel'>
                                            <input
                                                type={Number(i.data.systemid) === 2 ? 'tel' : 'text'}
                                                style={{ display: 'block', width: '100%', border: '0', fontSize: 14 }} value={i.value}
                                                placeholder={i.data.description}
                                                onChange={(e) => {
                                                    i.value = e.target.value
                                                    this.setState({
                                                        sign: this.state.sign
                                                    })
                                                }} />
                                        </label>)
                                case 2:
                                    return <div style={{ marginBottom: 12 }}><Checkbox.Group onChange={e => {
                                        i.value = e
                                    }} options={i.data.data_value.map(i => i.content)} ></Checkbox.Group></div>
                                case 6:
                                    return <div style={{ marginBottom: 12 }}>
                                        <Radio.Group value={i.value} onChange={e => {
                                            i.value = e.target.value
                                            this.setState({ sign: this.state.sign })
                                        }}>
                                            {i.data.data_value.map(i => {
                                                return <Radio style={{ display: 'block', marginBottom: 6 }} key={i.id} value={i.content}>{i.content}</Radio>
                                            })}
                                        </Radio.Group>
                                    </div>
                                default:
                                    break;
                            }
                        })()}
                    </div>)
                }) : ''}
            </div>

            {/* 备注 */}
            <div className='tuan__panel'>
                <Textarea value={this.state.remark} style={{ border: 0 }} placeholder='填写备注' onChange={(e) => {
                    this.setState({
                        remark: e
                    })
                }} />

            </div>

            {/* 价格合计 */}
            <div className='tuan__panel'>
                <div style={{ color: '#999', fontSize: 14, marginBottom: 6 }}>总计{this.state.totalnum}件</div>
                <div className='fbc'>
                    <span style={{ fontSize: 14, color: '#333' }}>合计</span>
                    <span style={{ fontSize: 14, color: 'var(--theme-main)' }}>￥{(this.state.totalprice / 100).toFixed(2)}</span>
                </div>
                <div className='fbc'>
                    <span style={{ fontSize: 14, color: '#333' }}>会员优惠：{(() => {
                        const discount = Number(this.state.discount)
                        if (discount === 0) {
                            return '无折扣'
                        }
                        if (discount > 0 && discount < 1) {
                            return this.stringDiscount(discount)
                        }
                    })()}</span>
                    <span style={{ fontSize: 14, color: 'var(--theme-main)' }}>-￥{Number(this.state.discount_price) === 0 ? 0 : ((this.state.totalprice - this.state.discount_price) / 100).toFixed(2)}</span>
                </div>
                {this.state.postfee ? <div className='fbc'>
                    <span style={{ fontSize: 14, color: '#333' }}>运费</span>
                    <span style={{ fontSize: 14, color: 'var(--theme-main)' }}>￥{(this.state.postfee / 100).toFixed(2)}</span>
                </div> : null}
                <div className='fbc'>
                    <span style={{ fontSize: 14, color: '#333' }}>实付金额</span>
                    <span style={{ fontSize: 14, color: 'var(--theme-main)' }}>￥{(this.state.payprice / 100).toFixed(2)}</span>
                </div>
            </div>

            {/* 商品列表 */}
            <div className='tuan__panel'>
                {this.state.goodslist.map(good => {
                    return good.data.attribute.map(attr => {
                        let num = 0
                        for (let i = 0; i < good.num.length; i++) {
                            const _num = good.num[i];
                            if (_num.id === attr.id) {
                                num = _num.num
                            }
                        }
                        if (num <= 0) return ''
                        return (<div key={attr.id} style={{
                            background: '#F7F8F9', padding: 7, marginBottom: 12, height: 80, boxSizing: 'content-box'
                        }} className='fbc'>
                            <div style={{ height: 80, width: '100%', minWidth: 80, maxWidth: 80 }}>
                                <img alt={good.data.name} src={good.data.logo} style={{ width: '100%', height: '100%' }} className='img-center' />
                            </div>
                            <div style={{ marginLeft: 12, flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                                <div className='single-text-hidden' style={{ width: '14em', fontSize: 14, color: '#343434' }}>{good.data.name}</div>
                                <div style={{ fontSize: 10, color: '#878787' }}>{attr.unit}</div>
                                <div className='fbc ' >
                                    <span style={{ color: '#ff3e3e', fontSize: 16 }}>￥{((attr.distribution_price ? attr.distribution_price.nowprice : attr.price) / 100).toFixed(2)}</span>
                                    <span style={{ color: '#666', fontSize: 16 }}>x{num}</span>
                                </div>
                            </div>
                        </div>)
                    })
                })}
            </div>

            {/* 支付按钮 */}
            <div onClick={() => {
                if (!this.state.address && (!this.props.selectedAddress || !this.props.selectedAddress.value)) {
                    alert('请选择地址')
                    return
                }
                if (this.state.disable) return

                let must = false
                let orderdata = {
                    types: 1,
                    type: 'tuan',
                    tuanid: this.state.data.id,
                    items: this.state.list,
                    remark: this.state.remark,
                    notpay: this.state.data.notpay,
                    distributionid: this.state.data.distributionid || 0,
                    sign_data: [],

                }
                for (let index = 0; index < this.state.sign.length; index++) {
                    const i = this.state.sign[index];
                    if (Number(i.data.is_must) === 1 && (i.value === '' || i.value.length === 0)) {
                        must = true
                    }
                    if (i.value === '') continue
                    const value = (() => {
                        switch (Number(i.data.type)) {
                            case 1:
                                return [{ value: i.value }]
                            case 2:
                                return i.value.map(sign => {
                                    return { value: sign }
                                })
                            case 6:
                                return [{ value: i.value }]
                            default:
                                break;
                        }
                    })()
                    const data = {
                        data_value: value,
                        fid: i.data.id,
                        type: i.data.type,
                        systemid: i.data.systemid
                    }
                    orderdata.sign_data.push(data)
                }
                if (must) {
                    alert('你有必填项未填写')
                    return
                }
                if (Number(this.state.data.takeway) === 1) {
                    orderdata = {
                        ...orderdata,
                        addressid: 0,
                    }
                    if (this.props.selectedAddress && this.props.selectedAddress.type) {
                        const { value, type } = this.props.selectedAddress
                        if (type === 'address') {
                            orderdata.address = value.province + value.city + value.area + value.addressdetail
                            orderdata.addressinfo = {
                                province: value.province,
                                city: value.city,
                                area: value.area,
                                town: "",
                                address: value.addressdetail,
                            }
                            orderdata.username = value.contacts
                            orderdata.phone = value.contact_num
                        }
                        if (type === 'point') {
                            const { address } = value
                            orderdata.address = address.province + address.city + address.area + address.addressname + address.addressdetail
                            orderdata.addressinfo = {
                                province: address.province,
                                city: address.city,
                                area: address.area,
                                town: "",
                                address: address.addressdetail,
                            }
                            orderdata.username = value.username
                            orderdata.phone = value.phone
                        }
                        if (type === 'wechat') {
                            orderdata.addressinfo = value.addressinfo
                            orderdata.address = value.addressinfo.province + value.addressinfo.city + value.addressinfo.area + value.addressinfo.address
                            orderdata.username = value.username
                            orderdata.phone = value.phone
                        }
                    }
                } else if (Number(this.state.data.takeway) === 2) {
                    orderdata = {
                        ...orderdata,
                        addressid: this.state.address.id,
                        address: '',
                    }
                }
                if (this.time_slice_info?.time_slice_slot && this.time_slice_info?.time_slice_date) {
                    orderdata.time_slice_date = this.time_slice_info.time_slice_date
                    orderdata.time_slice_slot = this.time_slice_info.time_slice_slot
                }
                this.setState({ disable: true })
                api('order', 'create')({
                    app: this.props.userinfo.app_name
                }, 'post', orderdata).then(res => {
                    const data = res.data.data
                    if (!data) {
                        this.setState({ disable: false })
                    }
                    api('wechatpay', 'prepay')({
                        app: this.props.userinfo.app_name
                    }, 'post', {
                        openid: this.props.userinfo.openid,
                        from: 'order_pay',
                        orderid: data.orderid,
                        appid: this.props.userinfo.appid,
                        fee: this.state.payprice,
                        types: 1
                    }).then(result => {
                        const paydata = result.data.data
                        pay(paydata.jsparams, () => {
                            alert('支付成功')
                            this.props.history.replace('Success', { data: this.state.data, user: this.props.userinfo })
                        })
                    })
                })
            }} style={{
                color: '#fff', fontSize: 16, background: 'var(--theme-main)', padding: 10, textAlign: 'center', borderRadius: 10, position: 'relative'
            }}>
                <i className='iconfont icon-weixinzhifu' style={{ color: 'inherit', marginRight: 8 }}></i>
                微信支付 {(this.state.payprice / 100).toFixed(2)}元
                {this.state.disable ? (<div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    background: 'rgba(240,240,240,.7)'
                }} className='fcc'>
                    <div>
                        <i className='iconfont icon-xingzhuang' style={{ fontSize: 32, color: '#000' }}></i>
                    </div>
                </div>) : ''}
            </div>

            {/* 自提点选择框 */}
            {(this.state.selectAddress && Number(this.state.data.takeway) === 2) ? (
                <Modal title='选择自提点' cancel={true} close={() => { this.setState({ selectAddress: false }) }} style={{
                    outer: {
                        background: '#F7F8F9', maxHeight: '90vh'
                    }, content: { paddingBottom: 0 }
                }} >
                    <div className='hide-scrollbar' style={{ maxHeight: '65vh', overflow: 'auto', margin: '0 -10px' }}>
                        {this.state.data.addresslist.map(i => {
                            return (<div key={i.id} onClick={() => {
                                this.setState({
                                    address: i,
                                    selectAddress: false
                                })

                            }} className='fcc' style={{ background: 'white', margin: '0 0 10px', padding: 5, borderRadius: 5 }}>
                                <div style={{ paddingLeft: 15, marginRight: 15 }}>
                                    <i className='iconfont icon-dingwei2' style={{ color: '#9b9b9b', fontSize: 30 }}></i>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ color: '#353535', fontSize: 20, fontWeight: 'bold' }}>{i.name}</div>
                                    <div style={{ fontSize: 12, color: '#8b8b8b' }}>{i.address + i.addressdetail}</div>
                                    <div><span style={{ fontSize: 12, color: '#8b8b8b' }}>{i.contact}</span><span style={{ fontSize: 12, color: '#8b8b8b' }}>{i.mobile}</span></div>
                                </div>
                            </div>)
                        })}
                    </div>
                </Modal>
            ) : ''}

            {/* {this.state.showMap?(
                <iframe id="mapPage" allow="fullscreen" allowFullScreen style={{position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', border: 0}}
                src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=H2RBZ-V43CW-EL5RU-ORPCI-GDHXE-5KFNY&referer=jlgo"></iframe>
                    "https://apis.map.qq.com/tools/locpicker?search=1&type=0&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&referer=myapp"
            ):''} */}
        </div>)
    }

    stringDiscount(num) {
        if (isNaN(Number(num))) {
            throw new Error('it must be a Number')
        }
        if (num > 1 || num < 0) {
            throw new Error('it must little than 1 and bigger than 0')
        }

        const arr = String(num).split('.')[1].split('')
        const chr = ['〇点', '一', '二', '三', '四', '五', '六', '七', '八', '九']
        let str = ''
        for (let i = 0; i < arr.length; i++) {
            const item = Number(arr[i])
            str += chr[item]
        }
        return str + '折'
    }

    getCoupon() {
        const data = {
            types: 1,
            price: this.state.totalprice,
            offset: 0,
            limit: 10000,
        }
        api('coupon', 'getviewuse')(data).then(res => {

        })
    }

    getPrice() {

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userinfo: state.login_user_data,
        selectedAddress: state.selectedAddress,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setAddress: (data) => {
            dispatch(selectAddress(data))
        }
    }
}

export default Wx_sign(connect(mapStateToProps, mapDispatchToProps)((Settle)))