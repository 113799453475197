import React, { Component } from 'react'
import { connect } from 'react-redux'
// import {withRouter} from 'react-router-dom';

export default (Warp) => {
    class BanUnLogin extends Component {
        constructor(p) {
            super()
            // console.log(p)
            this.state = {
                didLogin: false,
            }
            if (!p.waiting) {
                if (!p.is_Login) {
                    p.history.push('/')
                } else {
                    this.state.didLogin = true
                }
            }
        }
        componentDidUpdate() {
            const p = this.props
            if (this.state.didLogin) return
            if (!p.waiting) {
                if (!p.is_Login) {
                    p.history.push('/')
                } else {
                    this.setState({
                        didLogin: true
                    })
                }
            }
        }
        render() {
            return (
                <Warp {...this.props} didLogin={this.state.didLogin} />
            )
        }
    }
    // return withRouter(connect(mapStateToProps)(BanUnLogin))
    return connect(mapStateToProps)(BanUnLogin)
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        waiting: state.waitingLogin,
        is_Login: state.is_Login
    }
}