import styled from 'styled-components';

export const Head = styled.div`
    padding: 40px 0;
    border-bottom: 1px solid #f1f1f1;
`
export const Filter = styled.div`
    padding: 11px 20px;
    background: #f5f5f5;
`
export const Page = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    box-shadow:0px -1px 2px 0px rgba(215,215,215,0.5);
    &>div{
        max-width: 956px;
        margin: auto;
    }
`
export const MainPage = styled.div`
    max-width: 1200px;
     margin: 0 auto;
      width: 100%; padding: 0 20px;
       /* position: relative; */
      padding-bottom: 100px;
`