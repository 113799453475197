import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setusertype } from '../reducers/index'

import { post } from '../netApi/fetch.ts';

import { withRouter } from 'react-router-dom';
import Footer from '../component/total/footer';
import { SIDEBAR_WIDTH, TOPBAR_HEIGHT } from '../data/style';

const mapStateToProps = (state) => {
    return { sidebarOpen: state.sidebarOpen, is_Login: state.is_Login }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setusertype: (v) => {
            dispatch(setusertype(v))
        }
    }
}


export default (Wrap, showFooter = true) => {
    class Content extends Component {
        constructor(p) {
            super()
            this.state = {
                showFooter: showFooter,
            }
            if (p.is_Login) {
                post('/proxy/checkProxy', { type: 1 }).then(res => {
                    p.setusertype(res.type)
                })
            }
        }

        sS = this.setState

        setState = function (state) {
            this.sS(state)
        }

        componentDidMount() {
            console.log(this.props);
            if (/banSidebar/.test(this.props.location.pathname)) {
                this.setState({ banSidebar: true })
            }
            // const mediaQuery = window.matchMedia('(min-width: 1600px)')
            // mediaQuery.addListener(this.marginLeftHandle.bind(this))
            // this.marginLeftHandle(mediaQuery)
        }

        marginLeftHandle = ((e) => {
            // const w = e.matches
            // const open = this.props.sidebarOpen
            // let l
            // if (!open) l = 'auto'
            // if (w && open) l = 0
            // if (!w && open) l = null
            // this.setState({
            //     style: {
            //         ...this.state.style,
            //         marginLeft: l
            //     }
            // })
        })

        componentDidUpdate(prev) {
            // if (prev.sidebarOpen !== this.props.sidebarOpen) {
            //     const mediaQuery = window.matchMedia('(min-width: 1600px)')
            //     this.marginLeftHandle(mediaQuery)
            // }
        }
        render() {
            return (
                <div style={{
                    marginTop: this.state.banSidebar ? 0 : TOPBAR_HEIGHT,
                    marginLeft: this.state.banSidebar ? 0 : SIDEBAR_WIDTH,
                }} className='PageContent'>
                    <div style={this.state.style} id='contentWrap'>
                        <Wrap {...this.props} />
                        {this.state.showFooter ? <Footer /> : null}
                    </div>
                </div>
            )
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(withRouter(Content))
}
