import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'


import Main from '../../high-component/Content'
import { get } from '../../netApi/fetch';
import parse from '../../methods/paramParse';
import Search from '../total/Input/Search';
import { message, Pagination } from 'antd';
import { Head, Filter, MainPage, Page } from '../../StyleComponents/SimpleFilterList';
import Loading from '../Method/Loading';
import styled from 'styled-components';

interface Props extends RouteComponentProps {
    login_user_data: LoginUserData,
}

interface State {
    searchKey: string,
    page: number,
    getData: {
        search: string,
        page: number,
    },
    total?: number,
    list: Item[]
}

interface Item {
    seller_count: number,
    sid: number | string,
    name: string,
    logo: string,
    intro: string,
    is_allow_recommend_seller: boolean,
    unified_price_commission_percent?: number,
    self_define_price_commission_percent?: number,
}

class UpLine extends Component<Props> {

    readonly state: State = {
        page: 1,
        searchKey: '',
        getData: {
            search: '',
            page: 1,
        },
        list: []
    }

    componentDidMount() {
        if (this.props.login_user_data.self_sid) {
            this.parseParam().then(() => {
                this.getList()
            })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if ((prevProps.location.search !== this.props.location.search) || (!prevProps.login_user_data.self_sid && this.props.login_user_data.self_sid)) {
            this.parseParam().then(() => {
                this.getList()
            })
        }
    }

    render() {
        const baseHref = '/ul/list'
        return <div style={{ position: 'relative' }}><MainPage>
            <Head className='flex-between'>
                <div style={{ fontSize: 20, fontWeight: 600 }}>我正在帮卖</div>
                <Search outerStyle={{ width: '20em' }} search={this.state.searchKey} onChange={(e: string) => {
                    this.setState({ searchKey: e })
                }} onSearch={() => {
                    this.props.history.push(`${baseHref}?search=${this.state.searchKey}&page=1`)
                }} placeholder='搜索用户微信昵称或小程序昵称' />
            </Head>
            <Filter>
                <div>我正在帮{this.state.total}人卖</div>
            </Filter>
            <div>
                {this.state.list.map((i: Item) => {
                    return <Item data={i} key={i.sid} />
                })}
            </div>
            <Page>
                <div className='flex-between'>
                    <div></div>
                    <Pagination pageSizeOptions={[]} total={this.state.total} current={this.state.getData.page} pageSize={20} onChange={e => {
                        this.props.history.push(`${baseHref}?page=${e}&search=${this.state.getData.search}`)
                    }} />
                </div>
            </Page>
        </MainPage></div>
    }

    parseParam() {
        return new Promise<void>((res, rej) => {
            const params = parse(this.props.location.search)
            const data = {
                search: '',
                page: 1
            }
            if (params.search) data.search = String(params.search)
            if (params.page) data.page = Number(params.page)
            this.setState({
                getData: data,
                searchKey: data.search
            }, () => {
                res()
            })
        })
    }

    getList() {
        const sid = this.props.login_user_data.self_sid
        if (!sid) return
        const data = {
            ...this.state.getData,
            types: 1,
            limit: 20,
            offset: 0,
        }
        data.offset = (data.page - 1) * data.limit
        delete data.page
        Loading.show()
        get('/distribution/getmydistributionlist', data).then((res: any) => {
            this.setState({
                total: Number(res.count),
                list: res.list.map((i: any): Item => {
                    const data: Item = {
                        seller_count: Number(i.seller_count),
                        sid: i.sid,
                        name: i.name,
                        logo: i.logo,
                        intro: i.intro,
                        is_allow_recommend_seller: Boolean(Number(i.is_allow_recommend_seller)),
                    }
                    if (i.unified_price_commission_percent) {
                        data.unified_price_commission_percent = Number(i.unified_price_commission_percent)
                    }
                    if (i.self_define_price_commission_percent) {
                        data.self_define_price_commission_percent = Number(i.self_define_price_commission_percent)
                    }
                    return data
                })
            })
            Loading.hide()
        }).catch((e) => {
            Loading.hide()
            if (e.message) message.error(e.message)
        })
    }
}


const mapStateToProps = (state: any,) => {
    return {
        is_Login: state.is_Login,
        waitingLogin: state.waitingLogin,
        login_user_data: state.login_user_data,
    }
}
export default connect(mapStateToProps)((Main(UpLine, false)))

function Item(props: {
    data: Item
}) {
    const data = props.data
    const Item = styled.div`
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
        color: #999;
        &:hover{
            background: #f8f8f8;
        }
        &>div>div:not(:last-child){
            margin-bottom: 12px;
        }
        &>div>div:last-child{
            &>div{
                margin-right: 16px;
            }
        }
    `
    return <Item className='flex-between flex-top' >
        <div style={{ marginRight: 20 }}>
            <img alt='logo' src={data.logo} style={{ width: 36, height: 36, borderRadius: '50%' }} />
        </div>
        <div className='flex1'>
            <div>
                <span style={{ fontSize: 16, color: '#333', marginRight: 12 }}>{data.name}</span>
                {data.is_allow_recommend_seller ? <span style={{ marginRight: 12, fontSize: 12, color: 'var(--theme-main)', border: '1px solid var(--theme-main)', borderRadius: 4, padding: '4px 11px' }}>可以为TA推荐帮卖好友</span> : null}
                <span>
                    <span style={{ color: '#FF9A27' }}>{data.seller_count}</span><span>人正在帮TA卖</span>
                </span>
            </div>
            <div>{data.intro}</div>
            <div className='flex-box'>
                {/* <div>帮卖佣金：￥{(data.p1 / 100).toFixed(2)}</div>
                <div>推荐佣金：{data.p1.toFixed(2)}%</div>
                <div>待收佣金：￥{(data.p1 / 100).toFixed(2)}</div>
                <div>已收佣金：￥{(data.p1 / 100).toFixed(2)}</div> */}
            </div>
        </div>
    </Item>
}