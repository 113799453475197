import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../total/Modal';
// import styled from 'styled-components';
// import BTN from '../../../StyleComponents/Button';
import { message } from 'antd';
import { getToken } from '../../../netApi/fetch';
import { API } from '../../../data/options';
import Loading from '../../Method/Loading';
import BTN from '../../../StyleComponents/Button';
import Toast from '../../Method/Toast';
let confirmWrap: Element
interface props {

}

export default function (props?: props) {
    return new Promise<boolean | string>((res, rej) => {
        function Create(p) {
            const uploadxls = useRef<FormData>()
            return <Modal cancel={true} title={'如何批量发货'} confirm={upload} style={{
                content: {
                    width: 600, border: '1px solid #d9d9d9', borderRadius: 4, margin: 20,
                }
            }}
                close={() => {
                    cancel()
                }}>
                <div style={{ marginBottom: 20 }}>
                    导出数据，下载表格并填写快递单号<BTN color='red' solid style={{ marginLeft: 12 }} onClick={() => { res('export'); cancel() }} >导出数据</BTN>
                </div>
                <img style={{ marginBottom: 20 }} alt='发货填写示例图' src='https://tuangou-1259536745.picgz.myqcloud.com/uploads/20191012/tE6T0cuxkZ.jpg'></img>
                <div style={{ marginBottom: 20, color: '#999' }}>
                    注意：请勿删除Excel表格中，订单号、快递公司、快递单号三列
                </div>
                <label style={{ cursor: 'pointer', }} onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <span style={{marginRight: 10}}>上传表格</span>
                    <input type='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                        onChange={e => {
                            if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                            const file = e.target.files[0]
                            const formData = new FormData()
                            formData.append('file', file)
                            formData.append('types', '1')
                            formData.append('token', getToken())
                            uploadxls.current = formData
                        }} />
                </label>
                <div style={{ color: '#3d83d8', cursor: 'pointer', marginTop: 16 }} onClick={e => {
                    const win = window.open('/help/importexcel', '_blank');
                    win && win.focus();
                }}>查看详细教程</div>
            </Modal>

            function upload() {
                if (!uploadxls.current) {
                    message.warning('请选择文件')
                    return
                }
                Loading.show()
                fetch(API + '/pcorder/sendgoodsbyimportexcel', {
                    method: 'post', body: uploadxls.current, mode: 'cors',
                    credentials: 'include',
                }).then((result) => {
                    result.json().then((result) => {
                        if (result.code !== 0) {
                            message.error(result.message)
                            Loading.hide()
                            return
                        }
                        Toast.show('批量发货成功')
                        res(true)
                        cancel()
                        Loading.hide()
                    })
                }).catch(() => {
                    message.error('网络错误')
                    Loading.hide()
                })
            }
        }

        function cancel() {
            ReactDOM.render(null, confirmWrap)
            res(false)
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Create {...props} />, confirmWrap)
    })
}