const list = [
    // reg match
    '/payment',
    'banSidebar',
    '/print',
    'importexcel',
    '/tuan/',
    'SelectGood',
    'settle',
    'Myorder',
    'Success',
    'orderdetail',
    'home',
    '/download/',
    '/downloads/app',
    'useraddress',
    'mallprint',
    '/auth',
    'picklist',
    'pay'
]
export default list