import React, { Component } from 'react';

class Loading extends Component {
    // componentDidMount(){
    //     console.log(this.props)
    // }
    render() {
        return (
            <div className='mask'>
                <div>
                    <i className='iconfont icon-xingzhuang' style={{ fontSize: 32, color: 'white' }}></i>
                </div>
            </div>
        )
    }
}
export default Loading