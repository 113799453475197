import React, { useState, useEffect, useRef, } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, NavLink, withRouter } from 'react-router-dom';
import { get, post } from '../../netApi/fetch';
import DistributionMall, { distributionMall } from './component/DistributionMall';
import ContentItem, { contentItem } from './component/ContentItem';
import Search from '../total/Input/Search';
import { SIDEBAR_WIDTH, INDEX_CONTENT_LIST_WIDTH, } from '../../data/style';
import { showdata } from './OverView';
import changeBranch from './method/changeBranch';
import { message } from 'antd';
import { connect } from 'react-redux';
import createMall from './method/createMall';
import parseParams from '../../methods/paramParse';
import { saveMallInfo, setTuanSearchKey } from '../../reducers';
import VIPBar from '../total/VIPBar';
import VIPPower, { M2G } from './method/VIPPower';
const List = styled.div`
    position: fixed;
    top: 50px;
    left: ${SIDEBAR_WIDTH}px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    width: ${INDEX_CONTENT_LIST_WIDTH}px;
    box-shadow: 0px 4px 6px 0px #cccccc; 
    background: #fff;
    z-index: 5;
`

const Hover = styled.div`
    background: #fff;
    transition: background .3s;
    padding: 16px 20px;
    display: flex;
    cursor: pointer;
    user-select: none;
    position: relative;
    div{
        color: #999;
    }
    a{
        display: flex;
    }
    &:hover{
        background-color: var(--theme-main) !important;
        color: #fff;
        *{
            color: #fff !important;
            border-color: #fff !important;
            &.redSpan{
                color: var(--theme-main)!important;
            }
        }
        .arrow{
            background: unset !important;
        }
    }
    .title{
        color: #333;
        font-size: 14px;
        font-weight: 600;
    }
`

const Icon = styled.div`
    border-radius: 50px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    i{
        color: #fff;
        font-size: 14px;
        line-height: 1;
    }
`

const Span = styled.span`
    margin-left: 8px;
    color: var(--theme-main) ;
    background-color: #FFF0F0;
    padding: 0 4px;
    border-radius: 4px;
    cursor: pointer;
`

const HSSQtype = styled.div<{ active: boolean }>`
    width: 108px;
    height: 32px;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: flex; align-items: center; justify-content: center;
    border: ${props => props.active ? '1px solid rgba(250,80,60,0.50)' : 0};
    color: ${props => props.active ? 'var(--theme-main)' : '#999'};
    font-weight: ${props => props.active ? 600 : 400};
    background: ${props => props.active ? '#fff' : '#f7f7f7'};
`

interface props extends RouteComponentProps {
    bindmallinfo?: showdata
    loginBindInfo?: loginBindInfo
    mall: mall.mallinfo
    refresh: () => void
    saveMallInfo: (data: false | mall.mallinfo) => void
    user: user
    memberInfo: member.info
    tuanSearchKey: string
    setTuanSearchKey: (str: string) => void
}



function ContentList(props: props) {
    const [mallList, setMallList] = useState<distributionMall[]>()

    const [contentList, setContentList] = useState<contentItem[]>()
    const limit = 30
    const contentCount = useRef(Infinity)
    const contentOffset = useRef(0)

    const [contentSearch, setContentSearch] = useState('')
    const searchKey = useRef('')

    const _hssq = !!Number(localStorage.getItem('contentlistishssq') || 0)

    const [isHSSQ, setIsHSSQ] = useState<boolean>(_hssq || false)
    const _isHSSQ = useRef<boolean>(_hssq || false)

    useEffect(() => {
        if (props.tuanSearchKey) {
            searchKey.current = props.tuanSearchKey
            setContentSearch(props.tuanSearchKey)
        }
        return () => {
            props.setTuanSearchKey(searchKey.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [tuanGoodsCount, setTuanGoodsCount] = useState<number>()

    useEffect(() => {
        if (tuanGoodsCount === undefined && props.user && props.user.uid && !props.user.is_branch) {
            get('/goods/getlistbysid', { offset: 0, limit: 0, op: 'display' }).then(res => {
                setTuanGoodsCount(Number(res.count))
            })
        }
    }, [props.user, tuanGoodsCount])

    useEffect(() => {
        if (!mallList) {
            get('/mall/getmydistributionmalllist', { offset: 0, limit: 100000, }).then(res => {
                setMallList(res.list)
            })
        }
    }, [mallList])

    const getting = useRef(false)
    useEffect(() => {
        if (getting.current) return
        getting.current = true
        if (!contentList && contentCount.current > contentOffset.current) {
            get('/content/getcontentlist', {
                app: 'jieligo',
                offset: 0,
                search: searchKey.current || '',
                limit,
                isstop: 2,
                is_hssq: _isHSSQ.current ? 1 : 0,
            }).then(res => {
                getting.current = false
                contentCount.current = Number(res.count)
                contentOffset.current = contentOffset.current + res.list.length
                setContentList(res.list || [])
            })
        } else {
            getting.current = false
        }
    }, [contentList])

    const [showMallList, setShowMallList] = useState(false)
    const [showTuanList, setShowTuanList] = useState(true)

    const [mymallnav, setmymallnav] = useState<number>(Number(localStorage.getItem('mymallnav') || 1))
    const [mymallnavTop,] = useState(60)
    const [mytuannav, setmytuannav] = useState<number>(Number(localStorage.getItem('mytuannav') || 1))
    const [mytuannavTop,] = useState<number>(260)

    useEffect(() => {
        setMallList(undefined)
        setTuanGoodsCount(undefined)
    }, [props.user])

    useEffect(() => {
        const data = parseParams(props.location.search)
        if (data.refresh && contentList) {
            getting.current = true
            get('/content/getcontentlist', {
                app: 'jieligo',
                offset: 0,
                search: searchKey.current || '',
                limit: (contentList).length || 10,
                isstop: 2,
                is_hssq: _isHSSQ.current ? 1 : 0,
            }).then(res => {
                getting.current = false
                contentCount.current = Number(res.count)
                contentOffset.current = res.list.length
                setContentList(res.list || [])
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location])

    useEffect(() => {
        localStorage.setItem('contentlistishssq', isHSSQ ? '1' : '0')
        _isHSSQ.current = isHSSQ
        contentOffset.current = 0
        contentCount.current = Infinity
        setContentList(undefined)
    }, [isHSSQ])

    useEffect(() => {
        const ishema = props.loginBindInfo?.is_hema
        if (ishema === undefined) return
        if (!ishema) {
            setIsHSSQ(false)
        }
    }, [props.loginBindInfo])


    if (props.user.is_branch) {
        return <List>
            <Hover>
                <NavLink to='/index/mall/goods/2'>
                    <Icon style={{ background: 'linear-gradient(133deg,#f87532 17%, #f93247 82%)' }} >
                        <i className='iconfont icon-wodesiyushangcheng' />
                    </Icon>
                    <div>
                        <div className='title' >
                            <span>{props.mall?.name}</span>
                            <Span className='redSpan'>{props.mall?.branch_name}</Span>
                        </div>
                        <div style={{ fontSize: 12 }}>同城、快递、自提，灵活设置</div>
                    </div>
                </NavLink>
            </Hover>
            <div style={{ padding: 10 }}>
                <div style={{ background: '#f7f7f7', padding: 10 }}>
                    <i className='icon-shangchengshangpinku iconfont ' style={{ marginRight: 10, }} />
                    {/* <span>你已创建{props.bindmallinfo?.mall_info.branch_mall_count}个门店</span> */}
                    <Span style={{ background: 'var(--theme-main)', color: '#fff' }} onClick={() => {
                        changeBranch({ mall: props.mall, masterid: props.mall.pid }).then((res) => {
                            if (res) {
                                props.saveMallInfo(undefined)
                                props.refresh()
                            }
                        }).catch(e => { if (e.message) message.error(e.message) })
                    }}>切换门店</Span>
                    <Span className='cur-pointer' style={{ color: 'var(--theme-main)', background: '#FFF0F0', padding: '2px 4px', marginLeft: 14, borderRadius: 4 }} onClick={() => {
                        post('/mall/switchbindmall', { mall_id: props.mall.pid }).then(() => {
                            props.saveMallInfo(undefined)
                            props.refresh()
                        }).catch(e => { if (e.message) message.error(e.message) })
                    }}>返回总部</Span>
                </div>
            </div>
        </List>
    }
    let mediaData: member.rule
    if (props.memberInfo && props.memberInfo.rule_list) {
        mediaData = props.memberInfo.rule_list.find(i => Number(i.rule_type) === 5)
    }
    return <List className='hiddenScrollBar' onScroll={onReachBottom}>
        {!!mediaData && <div style={{ padding: '16px 20px', fontSize: 12, color: '#999', }}>
            <div className="flex-between" style={{ marginBottom: 4, }}>
                <div>
                    <span style={{ fontWeight: 'bold', color: '#666' }}>剩余{M2G(Number(mediaData.max_value) - Number(mediaData.use_value) > 0 ? Number(mediaData.max_value) - Number(mediaData.use_value) : 0)}</span>
                    <span>/{M2G(Number(mediaData.max_value))}</span>
                </div>
                <div>已使用{(Number(mediaData.use_value) / Number(mediaData.max_value) * 100).toFixed(2)}%</div>
            </div>
            <div style={{ marginBottom: 4, }}>
                <VIPBar value={Number(mediaData.use_value) / Number(mediaData.max_value) * 100} />
            </div>
            <div className="flex-between">
                <div>视频/图片存储空间</div>
                <div className="cur-pointer" style={{ color: `var(--theme-main)` }} onClick={() => {
                    VIPPower({ user: props.user })
                }} >扩容</div>
            </div>
        </div>}
        <Hover onClick={() => { props.history.push('/index/release') }}>
            <Icon style={{ background: 'linear-gradient(133deg,#f87532 17%, #f93247 82%)' }} >
                <i className='icon-jia iconfont' />
            </Icon>
            <div style={{ flex: 1 }}>
                <div className='title'>发布</div>
                <div style={{ fontSize: 12 }} >发布团购活动、短视频带货、商城</div>
            </div>
        </Hover>
        <Hover id='mall' style={{ background: /\/index\/mall/.test(props.location.pathname) ? '#efefef' : '' }}>
            <NavLink to='/index/mall/goods/2'>
                <Icon style={{ background: 'linear-gradient(133deg,#f87532 17%, #f93247 82%)' }} >
                    <i className='iconfont icon-wodesiyushangcheng' />
                </Icon>
                <div>
                    <div className='title' >{props.user.name}的私域商城</div>
                    <div style={{ fontSize: 12 }}>同城、快递、自提，灵活设置</div>
                </div>
            </NavLink>
            {mymallnav ? <img id='mymallnav' style={{
                position: 'fixed',
                top: mymallnavTop,
                left: `calc(${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px - 20px)`,
                transition: 'transform .3s',
            }} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setmymallnav(0)
                localStorage.setItem('mymallnav', '0')
            }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/content/ysSBPsQJsL.png' alt='' /> : null}
        </Hover>
        {props.bindmallinfo?.mall_info ? <div style={{ padding: '10px 10px' }}>
            {props.bindmallinfo?.mall_info.branch_mall_count ? <div style={{ background: '#f7f7f7', padding: 5 }}>
                <i className='icon-shangchengshangpinku iconfont ' style={{ marginRight: 10, }} />
                <span>你已创建{props.bindmallinfo?.mall_info.branch_mall_count}个门店</span>
                <Span onClick={() => {

                    changeBranch({ mall: props.mall }).then((res) => {
                        if (res) props.refresh()
                    }).catch(e => { if (e.message) message.error(e.message) })
                }}>进入门店</Span>
            </div> : <div style={{ background: '#f7f7f7', padding: 5 }}>
                <i className='icon-shangchengshangpinku iconfont ' style={{ marginRight: 10, }} />
                <span>你还没有创建门店</span>
                <Span onClick={() => {
                    createMall({ mallinfo: props.mall, user: props.user, mall_id: props.mall.id, }).then(() => {
                        props.refresh()
                    }).catch(e => { if (e.message) message.error(e.message) })
                }}>创建门店</Span>
            </div>}
        </div> : null}
        <Hover onClick={() => { setShowMallList(!showMallList) }}>
            <Icon style={{ background: 'linear-gradient(133deg,#FFD138 17%, #FF9A27 82%)' }} >
                <i className='icon-wobangmaideshangcheng iconfont' />
            </Icon>
            <div style={{ flex: 1 }}>
                <div className='title'>我帮卖的商城</div>
                {mallList
                    ? <div style={{ fontSize: 12 }} >我帮卖了{(mallList).length}个商城</div>
                    : <div style={{ fontSize: 12 }} >加载中</div>}
            </div>
            <div style={{
                width: 20,
                height: 20,
                background: '#f7f7f7',
                borderRadius: '50%',
                transform: `rotateZ(${showMallList ? 90 : 0}deg)`,
                transformOrigin: 'center',
                transition: 'transform .3s',
            }} className='flex-center arrow' >
                <i className='iconfont icon-jiantou-changfangxing' style={{ lineHeight: 1, fontSize: 12 }} />
            </div>
        </Hover>
        {showMallList && (mallList || []).map(i => {
            return <DistributionMall data={i} key={i.mall_id} />
        })}
        <Hover onClick={() => { setShowTuanList(!showTuanList) }}>
            <Icon id='tuan' style={{ background: 'linear-gradient(133deg,#F87532 17%, #F93247 82%)' }} >
                <i className='icon-wodehuodongjibangmai iconfont' />
            </Icon>
            <div style={{ flex: 1 }}>
                <div className='title'>我的团购活动及帮卖</div>
                {contentList ? <div style={{ fontSize: 12 }} >共{contentCount.current}个团购活动及帮卖</div> : <div style={{ fontSize: 12 }} >加载中</div>}
            </div>
            <div style={{
                width: 20,
                height: 20,
                background: '#f7f7f7',
                borderRadius: '50%',
                transform: `rotateZ(${showTuanList ? 90 : 0}deg)`,
                transformOrigin: 'center',
                transition: 'transform .3s',
            }} className='flex-center arrow' >
                <i className='iconfont icon-jiantou-changfangxing' style={{ lineHeight: 1, fontSize: 12 }} />
            </div>
            {mytuannav ? <img id='mytuannav' style={{
                position: 'fixed',
                top: mytuannavTop,
                left: `calc(${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px - 20px)`,
                transition: 'transform .3s', zIndex: 20
            }} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setmytuannav(0)
                localStorage.setItem('mytuannav', '0')
            }} src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/content/2uUuXZQ2Hr.png' alt='' /> : null}
        </Hover>
        {showTuanList && <>
            <div style={{ padding: '10px 16px' }}>
                <Search placeholder='搜索活动' search={contentSearch} onChange={setContentSearch} onSearch={() => {
                    searchKey.current = contentSearch
                    contentOffset.current = 0
                    contentCount.current = Infinity
                    setContentList(undefined)
                }} />
            </div>
            <Hover style={{ background: props.location.pathname === `/index/goods` ? '#efefef' : '', padding: 0, }}>
                <NavLink to={`/index/goods`} style={{ flex: 1, padding: '16px 20px' }} >
                    <Icon style={{ background: 'linear-gradient(141deg,rgba(150,175,255,1) 0%,rgba(72,166,237,1) 100%)' }}>
                        <i className='iconfont icon-shangpinguanli1' />
                    </Icon>
                    <div>
                        <div className='title'>团购商品库</div>
                        {tuanGoodsCount
                            ? <div style={{ fontSize: 12 }} >共{tuanGoodsCount}个商品</div>
                            : <div style={{ fontSize: 12 }} >加载中...</div>
                        }

                    </div>
                </NavLink>
            </Hover>

            {props.loginBindInfo?.is_hema && <div className="flex-between" style={{ padding: '16px 14px 16px 20px' }}>
                <HSSQtype active={!!isHSSQ} onClick={() => {
                    setIsHSSQ(true)
                }}>盒算社群</HSSQtype>
                <HSSQtype active={!isHSSQ} onClick={() => {
                    setIsHSSQ(false)
                }}>盒马邻里</HSSQtype>
            </div>}

            {(contentList || []).map(i => {
                return <ContentItem data={i} key={i.id} />
            })}
        </>}
    </List >

    function onReachBottom(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (mymallnav) {
            const t = document.getElementById('mall')
            if (t) {
                // setmymallnavTop(t.getBoundingClientRect().top + 10)
                document.getElementById('mymallnav').style.top = t.getBoundingClientRect().top + 10 + 'px'
            }
        }
        if (mytuannav) {
            const t = document.getElementById('tuan')
            if (t) {
                // setmytuannavTop(t.getBoundingClientRect().top + 10)
                document.getElementById('mytuannav').style.top = t.getBoundingClientRect().top + 5 + 'px'
            }
        }
        const target = e.target as HTMLDivElement
        const bottom: number = target.scrollHeight - target.scrollTop - target.clientHeight
        if (bottom < 300) {
            if (getting.current) return
            getting.current = true
            get('/content/getcontentlist', {
                app: 'jieligo',
                offset: contentOffset.current,
                search: searchKey.current || '',
                limit,
                isstop: 2,
                is_hssq: _isHSSQ.current ? 1 : 0,
            }).then(res => {
                getting.current = false
                contentCount.current = Number(res.count)
                contentOffset.current = contentOffset.current + res.list.length
                setContentList([...(contentList || []), ...(res.list || [])])
            })
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
        setTuanSearchKey: (data) => {
            dispatch(setTuanSearchKey(data))
        },
    }
}


const mapStateToProps = (state) => {
    return {
        is_Login: state.is_Login,
        user: state.login_user_data,
        mall: state.mallInfo,
        memberInfo: state.member_info,
        tuanSearchKey: state.tuanSearchKey,
        loginBindInfo: state.loginBindInfo,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentList))