import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import banSidebarList from '../../data/banSidebarList';
import { SIDEBAR_WIDTH } from '../../data/style';
import { get, post } from '../../netApi/fetch';
import Modal from './Modal';

interface props extends RouteComponentProps {

}

const Panel = styled.div`
    width: 70px;
    height: 150px;
    background: #ffffff;
    border-radius: 40px;
    box-shadow: 0px 2px 5px 2px rgba(219,219,219,0.50); 
    position: fixed;
    bottom: 60px;
    left: ${(SIDEBAR_WIDTH - 70) / 2}px;
    padding: 20px 0;
    z-index: 51;
    font-size: 12px;
    flex-direction: column;
    & > div{
        flex-direction: column;
        width: 100%;
        &:hover{
            background-color: #f7f7f7;
        }
    }
    i{
        color: var(--theme-main);
        font-size: 27px;
        line-height: 1;
        margin-bottom: 4px;
    }
    /* @media print{
        display: none;
    } */
`
const Link = styled.a`
    padding: 6px 0;
    &:hover{
        background-color: #f7f7f7;
    }
`

export default withRouter((props: props) => {
    const [sid, setSid] = useState<number>()
    const getting = useRef(false)
    useEffect(() => {
        if (getting.current) return
        if (sid) return
        getting.current = true
        get('/chat/getservicesid', {}).then(res => {
            setSid(Number(res.service_sid))
            getting.current = false
        }).catch(() => {
            getting.current = false
        })
    })

    const code = useState('')
    useEffect(() => {
        if (!sid) return
        post('/user/getwxacode?app=jieligo', {
            width: 200,
            page: 'message/pages/message/home',
            scene: `id=${sid}`,
        }).then(res => {
            code[1](res.image)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sid])

    const showCode = useState(false)
    const showArticle = useState(false)

    if (banSidebarList.find(i => new RegExp(i).test(props.location.pathname))) {
        return <></>
    }
    return <>
        <Panel className='flex-between'>
            <div className='flex-center cur-pointer' onClick={showArticle[1].bind(false, !showArticle[0])}>
                <i className="icon-shiyongshouce iconfont" />
                <span>使用手册</span>
            </div>
            <div className='flex-center cur-pointer' onClick={showCode[1].bind(false, !showCode[0])}>
                <i className="icon-lianxikefu1 iconfont" />
                <span>联系客服</span>
            </div>
        </Panel>
        {showCode[0] && <Modal title='联系客服' showConfirm={false} onClose={showCode[1].bind(false, false)} >
            <div className="flex-center" style={{ marginBottom: 10, fontWeight: 600 }}>手机微信扫描下方小程序码</div>
            <div className="flex-center" style={{ marginBottom: 20, fontWeight: 600 }}>立即联系客服</div>
            <div className="flex-center" style={{ width: 300, marginBottom: 40 }}>
                <img src={code[0]} alt="" style={{ width: 238, height: 238 }} className="img-center" />
            </div>
        </Modal>}

        {showArticle[0] && <Modal title='使用手册' onClose={showArticle[1].bind(false, false)} >
            <Link className="flex-box" href='https://mp.weixin.qq.com/s/BNFFucnoDzsLT9y7kPZlvA' onClick={e => {
                e.preventDefault()
                window.open('https://mp.weixin.qq.com/s/BNFFucnoDzsLT9y7kPZlvA')
            }}>
                <img style={{ width: 160, marginRight: 20 }} src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/b7FKF7Feei.jpg" alt="" className="img-center" />
                <div style={{ color: '#333', width: 400 }}>好友帮卖教程</div>
            </Link>
            <Link className="flex-box" href='https://mp.weixin.qq.com/s/jrFAy380wUGrmWhyY7RDFQ' onClick={e => {
                e.preventDefault()
                window.open('https://mp.weixin.qq.com/s/jrFAy380wUGrmWhyY7RDFQ')
            }}>
                <img style={{ width: 160, marginRight: 20 }} src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/6YM6kf89Cu.jpg" alt="" className="img-center" />
                <div style={{ color: '#333', width: 400 }}>退款核销教程</div>
            </Link>
            <Link className="flex-box" href='https://mp.weixin.qq.com/s/C2wtU68Kzhwk2GidNQ5Vog' onClick={e => {
                e.preventDefault()
                window.open('https://mp.weixin.qq.com/s/C2wtU68Kzhwk2GidNQ5Vog')
            }}>
                <img style={{ width: 160, marginRight: 20 }} src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/SY4A43HJCI.jpg" alt="" className="img-center" />
                <div style={{ color: '#333', width: 400 }}>佣金提现教程</div>
            </Link>
            <Link className="flex-box" href='https://mp.weixin.qq.com/s/TjiODvs13NZIhQn1shs1nA' onClick={e => {
                e.preventDefault()
                window.open('https://mp.weixin.qq.com/s/TjiODvs13NZIhQn1shs1nA')
            }}>
                <img style={{ width: 160, marginRight: 20 }} src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/gI6Dra38Eo.jpg" alt="" className="img-center" />
                <div style={{ color: '#333', width: 400 }}>发布团购教程</div>
            </Link>
            <Link className="flex-box" href='https://mp.weixin.qq.com/s/jNF-34-4y8hyI5ctdgRSgw' onClick={e => {
                e.preventDefault()
                window.open('https://mp.weixin.qq.com/s/jNF-34-4y8hyI5ctdgRSgw')
            }}>
                <img style={{ width: 160, marginRight: 20 }} src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/Pn6NB1pjWY.jpg" alt="" className="img-center" />
                <div style={{ color: '#333', width: 400 }}>接力GO运营手册</div>
            </Link>
        </Modal>}
    </>
})