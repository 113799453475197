import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Modal from '../total/Modal';
import { get, post } from '../../netApi/fetch';
import { message } from 'antd';
import BTN from '../../StyleComponents/Button';
interface Props {
    onClose: () => void,
    reload?:() => void,
}
export interface GroupItem {
    id: string | number,
    name: string,
    seller_count: number,
    unified_price_percent: number,
    uid_list: User[]
}

interface User {
    uid: string,
    sid?: string,
    name: string,
    logo: string,
}

const Warp = styled.div`
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    &>div:not(:last-child){
        margin-bottom: 16px;
    }
    input{
        border: 0;
        border-bottom: 1px solid #ccc;
        text-align: center;
        margin-right: 16px;
        width: 8em;
    }
`
const C45 = styled.div`
    font-size: 14px;
    color: #454545;
`

const SelectPanel = styled.div`
    border-radius: 4px;
    border: 1px solid #eee;
    width: 47%;
    padding-bottom: 20px;
    overflow-y: auto;
    height: 100%;
    &>.cur-pointer{
        &:hover{
            background: #eee;
        }
        padding: 4px 11px;
    }
    img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 16px;
    }
`
export default function (props: Props) {

    let [page, setPage] = useState(1)
    let [list, setList] = useState<Array<GroupItem>>()
    let [loading, setLoading] = useState(false)
    let [editName, setName] = useState('')
    let [editNumber, setNumber] = useState('')

    // 未选择的用户
    let [unGroupUserList, setUnGroupUserList] = useState<Array<User>>()
    const gettingUnGroup = useRef(false)
    const unGroupPage = useRef(0)
    const EL = useRef<HTMLDivElement>(null)

    // 已选择的用户
    let [thisGroupUserList, setThisGroupUserList] = useState<Array<User>>([])
    let [delUserList, setDelUserList] = useState<Array<User>>([])

    // 已选择的分组
    let [selectedGroup, setSelectedGroup] = useState<GroupItem>()

    // 搜索用户
    let [searchKey, setSearchKey] = useState('')
    const searchK = useRef('')

    let [canCancel, setCanCancel] = useState(true)
    const [confirmText, setConfirmText] = useState('确定')

    const getUnGroupUser = useCallback(() => {
        if (gettingUnGroup.current) return
        if (page === 1) return
        gettingUnGroup.current = true
        get('/seller/getsellerlistbyteam', {
            team_id: 0,
            search: searchKey,
            offset: unGroupPage.current,
            limit: 20,
            types: 1,
        }).then((res: any) => {
            if (searchKey !== searchK.current) return
            unGroupPage.current = unGroupPage.current + res.list.length
            let list: User[] = []
            if (unGroupUserList) {
                list = [...unGroupUserList]
            }
            gettingUnGroup.current = false
            setUnGroupUserList([...list, ...res.list.map((i: any) => {
                return {
                    uid: i.uid,
                    sid: i.sid,
                    name: i.name,
                    logo: i.logo
                }
            })])
        }).catch((e: any) => {
            gettingUnGroup.current = false
        })

    }, [searchKey, page, unGroupUserList])

    useEffect(() => {
        if (!loading) {
            if (!list) {
                getGroup()
            }
        }
    }, [list, loading])

    useEffect(() => {
        if (!unGroupUserList) {
            gettingUnGroup.current = false
            searchK.current = searchKey
            getUnGroupUser()
        }
    }, [searchKey, page, unGroupUserList, getUnGroupUser])

    useEffect(() => {
        setConfirmText(page === 1 ? '确定' : '保存')
    }, [page])

    function confirm() {
        if (page === 2) {
            if (!editName || editName === '') {
                message.error('请填写等级名称')
                return
            }
            // if (!editNumber || editNumber === '') {
            //     message.error('统一定价的订单佣金')
            //     return
            // }
            // if (!thisGroupUserList || thisGroupUserList.length === 0) {
            //     message.error('请选择好友')
            //     return
            // }
            const data = {
                op: 'create',
                id: '',
                name: editName,
                unified_price_percent: editNumber,
                uid_list: thisGroupUserList.map(i => i.uid),
                types: 1,
            }
            if (selectedGroup) {
                data.id = String(selectedGroup.id)
                data.op = 'update'
            }
            
            post('/seller/setsellerteam', {
                ...data,
                id: data.id === '' ? undefined : data.id
            }).then(() => {
                init()
                props.reload && props.reload()
            })
        }
        if (page === 1) {
            props.onClose()
        }
    }

    const title: React.ReactElement = (() => {
        if (page === 1) {
            return <div>设置帮卖好友等级</div>
        }
        if (page === 2) {
            return <div className='flex-box cur-pointer' onClick={() => {
                init({
                    group: true
                })
            }}>
                <div><i className='iconfont icon-arrow-fine-left' style={{ fontWeight: 600, marginRight: 16 }} /></div>
                <div>返回</div>
            </div>
        }
        return <></>
    })()


    return <Modal loading={loading} title={title} cancel={canCancel} confirmText={confirmText} onClose={props.onClose} onReject={close.bind(null)} onConfirm={confirm} >
        <div className='hide-scrollbar' id='level-setting' style={{
            width: 460, position: "relative", height: '50vh', overflowY: 'auto',
        }}>
            {page === 1 ? <>
                <BTN color='red' style={{ marginBottom: 16 }} onClick={() => {
                    if(list && list.length > 4){
                        message.warn('最多只能设置5个等级')
                        return
                    }
                    getUnGroupUser()
                    setPage(2)
                }}>添加等级</BTN>
                {list && list.map((i: GroupItem) => {
                    return <Warp key={i.id}>
                        <div className='flex-between'>
                            <C45 style={{ marginRight: 16 }}>等级名称</C45>
                            <C45 className='flex1'>{i.name}</C45>
                            <div className='flex-box'>
                                <div className='cur-pointer' style={{ marginLeft: 16, }} onClick={deleteGroup.bind(null, i.id)} >删除</div>
                                <div className='cur-pointer' style={{ marginLeft: 16, color: 'var(--theme-main)' }} onClick={() => {
                                    setSelectedGroup(i)
                                    setName(i.name)
                                    setNumber((i.unified_price_percent).toFixed(2))
                                    setThisGroupUserList(i.uid_list)
                                    getUnGroupUser()
                                    setPage(2)
                                }}>编辑</div>
                            </div>
                        </div>
                        {/* <C45 className='flex-box'>
                            <div style={{ marginRight: 16 }}>统一定价的订单佣金</div>
                            <div>{i.unified_price_percent.toFixed(2)}%</div>
                        </C45> */}
                        <C45 className='flex-box'>
                            <C45 style={{ marginRight: 16 }}>添加好友</C45>
                            <C45>{i.seller_count > 0 ? `已添加${i.seller_count}个好友` : '未添加'}</C45>
                        </C45>
                    </Warp>
                })}
            </> : null}
            {page === 2 ? <>
                <Warp>
                    <div className='flex-between'>
                        <C45 style={{ marginRight: 16 }}>等级名称</C45>
                        <div className='flex1'>
                            <input value={editName} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>
                    {/* <C45 className='flex-box'>
                        <div style={{ marginRight: 16 }}>统一定价的订单佣金</div>
                        <div><input type='number' value={editNumber} onChange={e => setNumber(e.target.value)} onBlur={setNumber.bind(null, Number(editNumber).toFixed(2))} /></div>
                        <div>%</div>
                    </C45> */}
                    {/* <C45 className='flex-box'>
                        <C45 style={{ marginRight: 16 }}>添加好友</C45>
                        <div style={{ color: 'var(--theme-main)' }}>{'去添加>>'}</div>
                    </C45> */}
                </Warp>
                <div style={{marginBottom: 12}}>添加好友</div>
                <div style={{ height: `calc(50vh - 162px)` }}>
                    <div className='flex-between' style={{ height: '100%' }}>
                        <SelectPanel className='' ref={EL} onScroll={(e: React.UIEvent<HTMLDivElement>) => {
                            if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 50) {
                                getUnGroupUser()
                            }
                        }} >
                            <input type='text' style={{ margin: '12px auto 0', padding: 4, display: 'block', border: 'solid 1px #eee', borderRadius: 4 }} value={searchKey} onChange={e => {
                                setSearchKey(e.target.value)
                                unGroupPage.current = 0
                                setUnGroupUserList(undefined)
                            }} placeholder='搜索好友昵称' />
                            {selectedGroup ? <>
                                <div style={{ fontSize: 12, fontWeight: 600, margin: '12px 4px' }}>要移除的好友</div>
                                {delUserList && delUserList.map((i: User) => {
                                    if (thisGroupUserList.find((j: User) => j.uid === i.uid)) return null
                                    return <UserItem key={i.uid} user={i} type='del' />
                                })}
                            </> : null}
                            <div style={{ fontSize: 12, fontWeight: 600, margin: '12px 4px' }}>未选择的好友</div>
                            {unGroupUserList && unGroupUserList.map((i: User) => {
                                if (thisGroupUserList.find((j: User) => j.uid === i.uid)) return null
                                return <UserItem key={i.uid} user={i} type='unGroup' />
                            })}
                        </SelectPanel>
                        <SelectPanel className=''>
                            <div style={{ fontSize: 12, fontWeight: 600, margin: '12px 4px' }}>已选择的好友</div>
                            {[...thisGroupUserList].map((i: User) => {
                                return <UserItem key={i.uid} user={i} type='this' />
                            })}
                        </SelectPanel>
                    </div>
                </div>
            </> : null}
        </div>
    </Modal>

    function UserItem(prop: { user: User, type: 'this' | 'unGroup' | 'del' }) {
        return <div className='flex-box cur-pointer' style={{ userSelect: 'none' }} onClick={() => {
            let thisGroup = [...thisGroupUserList]
            if (prop.type === 'this') {
                thisGroup = thisGroup.filter((i: User) => i.uid !== prop.user.uid)
                if(unGroupUserList && unGroupUserList.find((i:User)=> i.uid === prop.user.uid)){

                }else{
                    delUserList.push(prop.user)
                }
            }
            if (prop.type === 'unGroup') {
                thisGroup.push(prop.user)
                if (EL.current) {
                    const ele: HTMLDivElement = EL.current
                    if (ele.childElementCount < 10) {
                        getUnGroupUser()
                    }
                }
            }
            if(prop.type === 'del'){
                delUserList = delUserList.filter((i: User) => i.uid !== prop.user.uid)
                thisGroup.push(prop.user)
            }
            if (unGroupUserList) setUnGroupUserList([...unGroupUserList])
            if (delUserList) setDelUserList([...delUserList])
            setThisGroupUserList([...thisGroup])
        }} >
            <img alt='' src={prop.user.logo + '?imageView2/2/w/200'} className='img-center' />
            <span>{prop.user.name}</span>
        </div>
    }
    /**
     * 
     * @param unSet 不重置的值， true为不重置
     */
    function init(unSet: {
        group: boolean
    } = { group: false }) {

        setCanCancel(true)
        setPage(1)
        if (!unSet.group) {
            setList(undefined)
        }
        setLoading(false)
        setName('')
        setUnGroupUserList(undefined)
        unGroupPage.current = 0
        setNumber('')
        setThisGroupUserList([])
        setSearchKey('')
        setDelUserList([])
    }

    // 获取分组列表
    async function getGroup(config = { loading: true }) {
        setLoading(config.loading)
        get('/seller/getteamlist', {
            types: 1,
            offset: 0, limit: 99,
        }).then((res: any) => {
            setList(res.list.map((i: any) => {
                return {
                    id: i.id,
                    name: i.name,
                    seller_count: Number(i.seller_count),
                    uid_list: i.uid_list.map((i: any) => {
                        return {
                            uid: i.uid,
                            logo: i.logo,
                            name: i.name
                        }
                    }),
                    unified_price_percent: Number(i.unified_price_percent),
                }
            }))
            setLoading(false)
        })
    }

    function close() {
        if (page === 1) {
            props.onClose()
        }
        if (page === 2) {
            setPage(1)
        }
    }

    function deleteGroup(id: number | string) {
        post('/seller/setsellerteam', {
            op: 'del',
            id,
            types: 1,
        }).then(() => {
            init()
        })
    }
}