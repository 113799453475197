import React, { Component } from 'react'
import { RouteComponentProps, match, NavLink } from 'react-router-dom'
import Main from '../../high-component/Content'
import BanUnLogin from '../../high-component/BanUnLogin'
import { connect } from 'react-redux'
import { showLoading } from '../../reducers/index'
import styled from 'styled-components';

// components
import Item, { FakeItem } from '../order/item'
import Select from '../total/Select_';
import Modal from '../total/Modal'
import Search from '../total/Input/Search'
import { message, Pagination } from 'antd'

//func
import { get, } from '../../netApi/fetch';
import parse from '../../methods/paramParse';

import { OFFSET_LIMIT } from '../../data/options';
import BTN from '../../StyleComponents/Button'
import Loading from '../Method/Loading'
import { ExportPickData } from './Method/ExportPickData'
const Warp = styled.div`
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 100px;
`
const Relative = styled.div`
    position: relative;
`
// const EventSelectLabel = styled.label`
//     input, i{
//         display: none;
//     }
// `
// const SelectedTitle = styled.div`
//     color: #454545;
//     font-size: 20px;
//     width: 9em;
//     cursor: pointer;
//     font-weight: 600;
//     user-select: none;
// `
const Page = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    box-shadow:0px -1px 2px 0px rgba(215,215,215,0.5);
    &>div{
        max-width: 956px;
        margin: auto;
    }
`
interface State {
    searchText?: string,
    batch: boolean,
    query: query
    orderlist: order[],
    count: number,
    order_total?: {
        allorder_count: number,
        my_saleorder_total: number,
    },
    tuanlist: {
        id: string,
        title: string,
        pid: string,
        distributionid: string,
        takeway: string,
    }[],
    expressList: {}[],
    printOrders?: order[],
    loading: boolean,
    isdistribution?: boolean,
    sellerData?: any,

    batch_loading?: boolean,

    ShowPrintOrder?: boolean,

    quickSend?: boolean,
    quickCheckin?: boolean,
    quick_loading?: boolean,
    quickIndex?: number,
    showSelectSendPanel?: boolean,

    selectedSendMode: number,
    showExpressMark: boolean,
    markGoodlist?: {
        name: string;
        id: string | number;
        num: number | string;
        mark_num: number | string;
    }[],
    markid?: number | string;
    showMarkHistory: boolean;
    approvalList: {
        review_op: string;
        name: string;
    }[],
    approvalIndex: number,

    branchlist?: any[],

    mall_id?: number,
}
interface Props extends RouteComponentProps {
    match: Match,
    login_user_data: any,
    user: user,
    showLoading: () => void,
    hideLoading: () => void,
}

interface Match extends match {
    params: {
        query: string
    }
}

interface query {
    search?: string,
    page?: number,
    shipping_mode?: 'all' | 'express' | 'rider' | 'pick_up_location'
    review_op?: 'for_me' | 'for_upper' | 'for_lower'
    order_op?: 'all' | 'my' | 'seller'
    status_type?: order.orderstatus
}

interface postdata {
    from: 'all' | 'mall'
    mall_id?: number | string
    shipping_mode: 'all' | 'express' | 'rider' | 'pick_up_location'
    review_op?: 'for_me' | 'for_upper' | 'for_lower'
    order_op: 'all' | 'my' | 'seller'
    status_type: order.orderstatus
    search?: string
    addressid?: string | number
    offset: number
    limit: number
}

class OrderList extends Component<Props, State> {

    readonly state: State = {
        searchText: '',
        batch: false,
        query: {
            search: '',
            page: 1,
            status_type: 'all',
            shipping_mode: 'all',
            order_op: 'all',
        },
        orderlist: [],
        tuanlist: [],
        expressList: [],
        count: 0,
        loading: false,

        selectedSendMode: 1,

        showExpressMark: false,
        showMarkHistory: false,
        approvalList: [{ review_op: 'for_me', name: '待我审批' }, { review_op: 'for_upper', name: '待上级审批' }, { review_op: 'for_lower', name: '待下级审批' }],
        approvalIndex: 0,
    }

    bc?: BroadcastChannel
    mallinfo?: any

    componentDidMount() {
        document.title = '订单列表'
        get('/express/getExpressCompany', {}).then((res: any) => {
            this.setState({ expressList: res })
        })
        this.getQuery().then(() => this.getData()).catch(e => { if (e.message) message.error(e.message) })

        get('/index/getloginbindinfo', {}).then(res => {
            this.mallinfo = res
        })

        const ua = navigator.userAgent.toLowerCase();
        if (!(/MicroMessenger/i).test(ua)) {
            if (!window.BroadcastChannel) return
            if (BroadcastChannel) {
                this.bc = new BroadcastChannel('orderList')
                this.bc.onmessage = e => {
                    switch (e.data) {
                        case 'reload':
                            this.getData({ loading: false })
                            break;
                        default:
                            break;
                    }
                }
            }
        }

    }

    componentWillUnmount() {
        document.title = '接力go'
        this.bc?.close()
    }

    gettingBranch: boolean = false

    componentDidUpdate(props: Props) {
        if (this.props.user.is_branch === false && !this.state.branchlist && !this.gettingBranch) {
            this.gettingBranch = true
            get('/mall/getbranchmalllist', {
                offset: 0, limit: 99999, mall_id: this.props.user.bind_mall_id,
            }).then(res => {
                this.setState({ branchlist: res.list.map(i => { return { ...i, showName: i.name + ' - ' + i.branch_name } }) })
            }).catch(e => { if (e.message) message.error(e.message) })
        }
        if (props.match.params.query === this.props.match.params.query) return
        this.setState({ orderlist: [], sellerData: null })
        this.getQuery().then(() => this.getData()).catch(e => {
            if (e.message) message.error(e.message)
            Loading.hide()
        })
    }

    render() {
        const orderType = [{ title: '全部订单', id: "all" }, { title: '我的订单', id: 'my' }, { title: '好友订单', id: 'seller' }]
        return <Relative><Warp>
            <div className='fbc' style={{ flexWrap: 'wrap', padding: '30px 0', borderBottom: '1px solid #f1f1f1' }}>
                {this.props.user.is_branch === false && this.state.branchlist ? <>
                    <div style={{ width: 300 }}>
                        <div style={{ display: 'inline-block', fontWeight: 600, fontSize: 20 }}>
                            <Select readonly list={this.state.branchlist} maxHeight={578} selectWidth='12em' uni='branch' showKey='showName'
                                onChange={e => {
                                    if (Number(e.id) !== this.state.mall_id) {
                                        this.setState({ mall_id: Number(e.id), loading: true, orderlist: [], }, () => {
                                            this.getData()
                                        })
                                    }
                                }}
                                value={this.state.branchlist.findIndex(i => Number(i.id) === Number(this.state.mall_id || this.props.user.bind_mall_id))} placeholder='' />
                        </div>
                    </div>
                    <div style={{ flex: 1000 }} />
                </> : null}
                {/* 状态筛选 */}
                <div className='fbc' style={{ width: '21em', position: "relative", padding: '8px 0', }}>
                    {[
                        { key: 'all', name: '全部' },
                        { key: 'notuse', name: '未核销/发货' },
                        { key: 'use', name: '已完成' },
                        { key: 'applyrefund', name: '退款中' },
                        { key: 'refundsuccess', name: '已退款' },
                    ].map((i: { key: order.orderstatus, name: string }) => {
                        const active = { color: 'var(--theme-main)' }
                        return (
                            <div key={i.key} className='fcc cur-pointer' onClick={() => {
                                this.changeQuery({ status_type: i.key, page: 1 });
                            }}>
                                <span style={this.state.query.status_type === i.key ? active : {}}>{i.name}</span>
                            </div>
                        )
                    })}
                    <div style={{ position: 'absolute', width: '100%', bottom: 0, height: 4 }}>
                        <div style={{
                            position: 'absolute', height: '100%', bottom: 0, top: 0, background: 'var(--theme-main)', borderRadius: 2,
                            width: (() => {
                                if (!this.state.query.status_type) {
                                    return 0
                                }
                                // (this.state.query.option && this.state.query.option.length === 3) ? '2em' : '3em'
                                switch (this.state.query.status_type) {
                                    case 'all':
                                        return '2em'
                                    case 'notuse':
                                        return 'calc(4px + 5.5em)'
                                    default:
                                        return '3em'
                                }
                            })(),
                            transition: 'all .3s',
                            left: (() => {
                                switch (this.state.query.status_type) {
                                    case 'all':
                                        return 0
                                    case 'notuse':
                                        return 'calc(3em )'
                                    case 'use':
                                        return 'calc(10em - 4px)'
                                    case 'applyrefund':
                                        return 'calc(100% - 7em - 4px)'
                                    case 'refundsuccess':
                                        return 'calc(100% - 3em)'
                                    default:
                                        return 0
                                }
                            })()
                        }}></div>
                    </div>
                </div>
                <div style={{ flex: 1000 }}></div>

                <div className='flex-between'>
                    {/* 搜索 */}
                    <div style={{ minWidth: 300, textAlign: 'right' }}>
                        <div style={{ width: 300, display: 'inline-block' }}>
                            <Search search={this.state.searchText || ''} onChange={e => {
                                this.setState({ searchText: e })
                            }} onSearch={() => { this.changeQuery({ search: this.state.searchText }) }} placeholder='搜索用户名、手机号、订单号' outerStyle={{ padding: '4px 11px' }} />
                        </div>
                    </div>
                </div>

            </div>
            <div className='flex-box' style={{ padding: 8, borderBottom: '1px solid #f1f1f1', minHeight: 50 }}>
                {/* 切换我的订单好友订单 */}
                <div className='fcc' style={{}}>
                    <div style={{ marginRight: '1.2em' }}>
                        <Select inputStyle={{
                            borderColor: 'var(--theme-main)', width: '8em', color: '#fff', background: 'var(--theme-main)'
                        }} maxHeight={578} selectWidth='12em' list={orderType} showKey='title' uni='ordertype'
                            value={orderType.findIndex((i) => i.id === this.state.query.order_op)}
                            onChange={(e): void => {
                                this.changeQuery({ order_op: e.id })
                            }} />
                    </div>
                </div>
                <div className='flex-box'>
                    <BTN style={{ marginRight: 12 }} onClick={() => {
                        this.changeQuery({ shipping_mode: this.state.query.shipping_mode === 'express' ? 'all' : 'express', page: 1 })
                    }} color={this.state.query.shipping_mode === 'express' ? 'red' : 'gray'}>快递配送</BTN>
                    <BTN style={{ marginRight: 12 }} onClick={() => {
                        this.changeQuery({ shipping_mode: this.state.query.shipping_mode === 'rider' ? 'all' : 'rider', page: 1 })
                    }} color={this.state.query.shipping_mode === 'rider' ? 'red' : 'gray'}>骑手配送</BTN>
                    <BTN style={{ marginRight: 12 }} onClick={() => {
                        this.changeQuery({ shipping_mode: this.state.query.shipping_mode === 'pick_up_location' ? 'all' : 'pick_up_location', page: 1 })
                    }} color={this.state.query.shipping_mode === 'pick_up_location' ? 'red' : 'gray'}>自提</BTN>
                </div>
                <div style={{ flex: 1 }}></div>
                <div className='flex-box'>
                    <BTN color='red' style={{ marginLeft: 16 }} onClick={() => {
                        ExportPickData({
                            type: '1',
                            isBranch: !this.state.branchlist,
                            branchlist: this.state.branchlist,
                            branch_id: this.props.user.is_branch ? Number(this.props.user.bind_mall_id) : undefined
                        })
                    }}>导出数据</BTN>
                    <BTN color='red' style={{ marginLeft: 16 }} onClick={() => {
                        ExportPickData({
                            type: '2',
                            isBranch: !this.state.branchlist,
                            branchlist: this.state.branchlist,
                            branch_id: this.props.user.is_branch ? Number(this.props.user.bind_mall_id) : undefined
                        })
                    }}>打印单据</BTN>
                </div>
            </div>
            <div>
                {<>
                    <div className='fbc' style={{}}>
                        {(this.state.order_total) ? <div style={{ marginTop: 12, }}>
                            <h4 style={{ fontSize: '1.2rem', fontWeight: 600, color: '#333' }}>
                                订单总览
                                {!!this.mallinfo && <NavLink to={`/mall/Analysis/${this.mallinfo.bind_mall_id},${this.mallinfo.bind_mall.distribution_relation_id}`}><span style={{ fontSize: 12, color: '#b8b8b8' }} className='cur-pointer'>点击查看订单分析</span></NavLink>}

                            </h4>
                            <div className='fcc' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {
                                    this.state.query.status_type === 'applyrefund' ?
                                        <div style={{ marginRight: 12 }}><Select list={this.state.approvalList} inputStyle={{ width: '9em', fontSize: 12 }}
                                            value={(() => {
                                                const idx = this.state.approvalList.findIndex(i => i.review_op === this.state.query.review_op)
                                                return idx >= 0 ? idx : 0
                                            })()}
                                            uni='approval' showKey='name' onChange={(e, idx) => {
                                                this.changeQuery({ review_op: e.review_op, page: 1, })
                                            }} /></div> : null
                                }
                                <div style={{ color: '#999', marginRight: 12 }}>总订单：<span style={{ color: '#333' }}>{this.state.order_total.allorder_count}</span></div>
                                <div style={{ color: '#999', marginRight: 12 }}>销售额：<span style={{ color: '#333' }}>{(this.state.order_total.my_saleorder_total / 100).toFixed(2)}</span>元</div>
                            </div>
                        </div> : <div></div>}
                        <div style={{ padding: '16px 0' }}>
                            {false ? <>
                                {true ? <>
                                    <BTN style={{ marginLeft: 12 }} color='red' onClick={() => { this.setState({ showSelectSendPanel: true }) }}>批量发货</BTN>
                                </> : null}
                                {true ? <>
                                    <BTN style={{ marginLeft: 12 }} color='red' onClick={() => { this.setState({ quickCheckin: true }) }}>一键核销</BTN>
                                </> : null}
                            </> : null}
                            {false && <BTN style={{ marginLeft: 12 }} color='red' onClick={() => { this.setState({ ShowPrintOrder: true }) }}>打印订单</BTN>}
                        </div>
                    </div>
                    {!this.state.loading ? <div>
                        {this.state.orderlist.map((i: order) => {
                            return <Item data={i} print={this.print.bind(this, i)} key={i.orderid} send={this.getData.bind(this, { loading: false })} expressList={this.state.expressList} />
                        })}
                    </div> : null}
                </>}

                {/* {this.state.query.orderType === 2  && this.state.query.distributionType === 2 && !this.state.loading ? <>
                    {this.state.orderlist.map((i: order) => {
                        return <Item data={i} print={this.print.bind(this, i)} key={i.orderid} send={this.getData.bind(this, { loading: false })} expressList={this.state.expressList} />
                    })}
                </> : null} */}

                {this.state.loading ? [1, 2, 3, 4, 5].map(i => {
                    return <FakeItem key={i} />
                }) : null}
            </div>

            <Page>
                <div className='flex-between'>
                    <div></div>
                    <Pagination total={this.state.count} pageSizeOptions={[]} current={this.state.query.page} pageSize={OFFSET_LIMIT} onChange={e => { this.changeQuery({ page: e }); }} />
                </div>
            </Page>


            {this.state.showSelectSendPanel ? <Modal title='请选择发货方式' onConfirm={() => {
                this.setState({ showSelectSendPanel: false })
                if (this.state.selectedSendMode === 1) {
                    this.setState({ batch: true })
                }
                if (this.state.selectedSendMode === 2) {
                    this.setState({ quickSend: true })
                }
            }} onClose={this.setState.bind(this, { ...this.state, showSelectSendPanel: false })}>
                <div className='fbc'>
                    <div className='cur-pointer' onClick={() => { this.setState({ selectedSendMode: 1 }) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: this.state.selectedSendMode === 1 ? '#fff' : '#eee', color: this.state.selectedSendMode === 1 ? 'var(--theme-main)' : '#666', border: this.state.selectedSendMode === 1 ? '1px solid var(--theme-main)' : '1px solid #eee' }}>
                        <div style={{ fontSize: 20, fontWeight: 600, }}>快递配送</div>
                        <div style={{ fontSize: 12, marginTop: 15 }}>需要导入快递单号</div>
                        <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: this.state.selectedSendMode === 1 ? 'block' : 'none' }} />
                    </div>
                    <div className='cur-pointer' onClick={() => { this.setState({ selectedSendMode: 2 }) }} style={{ position: 'relative', padding: '40px 20px', margin: '0 15px', width: 290, background: this.state.selectedSendMode === 2 ? '#fff' : '#eee', color: this.state.selectedSendMode === 2 ? 'var(--theme-main)' : '#666', border: this.state.selectedSendMode === 2 ? '1px solid var(--theme-main)' : '1px solid #eee' }}>
                        <div style={{ fontSize: 20, fontWeight: 600, }}>一键发货（无需导入单号）</div>
                        <div style={{ fontSize: 12, marginTop: 15 }}>适用于由商家自己配送货物的活动</div>
                        <i className='iconfont icon-xuanzhong1' style={{ position: 'absolute', lineHeight: 1, right: -1, bottom: -1, display: this.state.selectedSendMode === 2 ? 'block' : 'none' }} />
                    </div>
                </div>
            </Modal> : null}

        </Warp></Relative>
    }

    uploadxls: any = null

    getQuery(): Promise<void> {
        return new Promise((reslove, reject) => {
            const data = this.props.match.params.query ? parse(this.props.match.params.query) : {}
            const query: any = {
                search: '',
                page: 1,
                status_type: 'all',
                shipping_mode: 'all',
                order_op: 'all',
            }
            if (data.search) query.search = data.search // 搜索内容
            if (data.page) query.page = Number(data.page) // 当前页数
            if (data.status_type) query.status_type = data.status_type // 订单状态
            if (data.shipping_mode) query.shipping_mode = data.shipping_mode // 配送方式
            if (data.order_op) query.order_op = data.order_op // 我的订单/好友订单/全部订单
            if (data.review_op) query.review_op = data.review_op // 我的订单/好友订单/全部订单
            this.setState({ query, searchText: query.search }, () => {
                reslove()
            })
        })

    }

    changeQuery(data: query): void {
        let path: string = '/mall/orders/'
        const query: query = { ...this.state.query, ...data }

        if (query.status_type !== 'applyrefund') {
            delete query.review_op
        } else {
            if (!query.review_op) query.review_op = 'for_me'
        }
        if (query.search) path += `search=${query.search}`
        if (query.page) path += `&page=${query.page}`
        if (query.status_type) path += `&status_type=${query.status_type}`
        if (query.shipping_mode) path += `&shipping_mode=${query.shipping_mode}`
        if (query.order_op) path += `&order_op=${query.order_op}`
        if (query.review_op) path += `&review_op=${query.review_op}`
        this.props.history.push(path)
    }

    async getData(config: { loading?: boolean } = { loading: true }) {
        if (config.loading) {
            this.setState({ loading: true })
            Loading.show()
            this.setState({ orderlist: [] })
        }
        const p: postdata = {
            from: 'all',
            offset: (this.state.query.page - 1) * OFFSET_LIMIT,
            shipping_mode: this.state.query.shipping_mode || 'all',
            limit: OFFSET_LIMIT,
            order_op: this.state.query.order_op,
            status_type: this.state.query.status_type,
            search: this.state.query.search,
            review_op: 'for_me',
            mall_id: this.state.mall_id || '',
        }
        if (this.state.query.status_type === 'applyrefund') {
            p.review_op = this.state.query.review_op || 'for_me'
        }

        get('/mall/getorderlist', { ...p }).then(res => {
            this.setState({ loading: false })
            Loading.hide()
            this.setState({
                orderlist: res.list,
                count: Number(res.count),
                order_total: res.order_total
            })
        })
        return
    }

    printList(list: order[]) {
        if (!Array.isArray(list)) {
            return
        }
        this.setState({
            printOrders: list
        }, () => {
            // window.print()
            this.props.history.push({
                pathname: '/orders/print',
                state: {
                    list
                }
            })
        })
    }
    print(item: order) {
        this.setState({
            printOrders: [item]
        }, () => {
            this.props.history.push({
                pathname: '/orders/print',
                state: {
                    list: [item]
                }
            })
        })
    }
}

const mapStateToProps = (state: any,) => {
    return {
        is_Login: state.is_Login,
        waitingLogin: state.waitingLogin,
        login_user_data: state.login_user_data,
    }
}
const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
    return {
        showLoading: () => {
            dispatch(showLoading(true, '加载中...'))
        },
        hideLoading: () => {
            dispatch(showLoading(false))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BanUnLogin(Main(OrderList, false)))