/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';

import { RouteComponentProps, Route, Switch } from 'react-router-dom';

import Content from '../../high-component/Content';
import { logout, set_login_user_data, waitingLogin, setusertype, saveMallInfo, saveMemberInfo, } from '../../reducers/index'


import styled from 'styled-components';
import { connect } from 'react-redux';

// import { confirm } from '../Method/Confirm';
import { get, post, } from '../../netApi/fetch';
// import { message } from 'antd';

import BanUnLogin from '../../high-component/BanUnLogin';
// import Loading from '../Method/Loading';

import ContentList from './ContentList';
import TuanControl from './TuanControl';
import { INDEX_CONTENT_LIST_WIDTH } from '../../data/style';
import DistributionMallControl from './DistributionMallControl';
import MallControl from './MallControl';
import TuanGoods from './component/TuanGoods';
import StartMall from './component/StartMall';
import dataCreate from '../good/dataCreate';
import release from './release';

interface props extends RouteComponentProps {
    user: user
    is_Login: boolean
    logout: () => void
    login: (p: any) => void
    waitingLogin: () => void
    setUserType: (p: any) => void
    saveMallInfo: (data: any) => void
    saveMemberInfo: (data: any) => void
    mallinfo: mall.mallinfo | false
    memberInfo: member.info
}

export type showdata = {
    tuan_total_statistics: {
        staring_count: string | number,
        wait_start_count: string | number,
        end_count: string | number,
        all_count: string | number,
    },
    tuan_order_statistics: {
        unfinished_orders_count: string | number,
        applyrefund_orders_count: string | number,
    },
    mall_order_statistics: {
        unfinished_orders_count: string | number,
        applyrefund_orders_count: string | number,
    },
    goods_total_statistics: {
        tuan_on_sale_goods_count: string | number,
        mall_on_sale_goods_count: string | number,
        all_goods_count: string | number,
    },
    today_transaction_statistics: {
        today_income_amount: string | number,
        today_orders_count: string | number,
        today_payprice_count: string | number,
    },
    mall_info: {
        branch_mall_name_list: string,
        branch_mall_count: number | string,
    }
}

const Wrapper = styled.div`
    position: relative;
`


const Main = styled.div`
    margin-left: ${INDEX_CONTENT_LIST_WIDTH}px;
    background-color: #f7f7f7;
`

function Index(props: props) {

    useEffect(() => {
        if (props.mallinfo && props.mallinfo.id) {
            if (props.location.pathname === '/index') {
                props.history.push('/index/release')
                // props.history.push('/index/mall/goods/2')
            } else if (props.location.pathname === '/index/mall') {
                props.history.push('/index/mall/goods/2')
            } else if (props.location.pathname === '/index/mall/start') {
                props.history.push('/index/mall/goods/2')
            }
        } else {
            if (props.mallinfo === false) {
                console.log(props.mallinfo);
                if (/mall/.test(props.location.pathname)) {
                    if (props.location.pathname !== '/index/mall/start') {
                        props.history.push('/index/mall/start')
                    }
                }
                if (props.location.pathname === '/index') {
                    props.history.push('/index/release')
                }
            }
        }
    }, [props.history, props.location, props.mallinfo])


    const [showData, setShowData] = useState<showdata>()
    useEffect(() => {
        if (!showData && props.is_Login) {
            get('/index/geth5index', {}).then((res) => {
                setShowData(res)
            })
        }
    }, [showData, props.is_Login])

    useEffect(() => {
        if (!props.is_Login) {
            setShowData(undefined)
        }
    }, [props.is_Login])

    return <Wrapper>
        <ContentList bindmallinfo={showData} refresh={refresh} />
        <Main>
            <Switch>
                <Route path='/index/s/:id' component={DistributionMallControl} />
                <Route path='/index/t/:tuanid' component={TuanControl} />
                <Route path='/index/mall/start' component={StartMall} />
                <Route path='/index/release' component={release} />
                <Route path={`/index/mall/goods/create`} exact component={dataCreate} />
                <Route path='/index/mall' >
                    {props.mallinfo ? <MallControl mall={props.mallinfo} /> : <></>}
                </Route>
                <Route path='/index/goods' component={TuanGoods} />
            </Switch>
        </Main>
    </Wrapper>

    function refresh() {
        props.logout()
        props.waitingLogin()
        post('/admin/checkIsLogin', {}).then(res => {
            post('/proxy/checkProxy', { type: 1 }).then(r => {
                props.setUserType(r.type)
            })
            props.login(res)
            props.history.push('/refresh')
        })
    }

}
const mapStateToProps = (state) => {
    return {
        is_Login: state.is_Login,
        user: state.login_user_data,
        mallinfo: state.mallInfo,
        memberInfo: state.member_info,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        waitingLogin: () => {
            dispatch(waitingLogin(false))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
        saveMemberInfo: (data) => {
            dispatch(saveMemberInfo(data))
        }
    }
}

export default BanUnLogin(connect(
    mapStateToProps,
    mapDispatchToProps,
)((Content(Index, false))))