import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BTN from '../../StyleComponents/Button'

// import api from '../../netApi';

class Modal extends Component {
    static defaultProps = {
        confirmText: '确认',
        cancelText: '取消',
        style: {
            outer: {}
        },
        showConfirm: true,
        banMaskClose: false,
        banAnime: false,
    }

    static propTypes = {
        close: PropTypes.func,
        onClose: PropTypes.func,
        confirm: PropTypes.func,
        onConfirm: PropTypes.func,
        loading: PropTypes.bool,
        cancel: PropTypes.bool,
        onCancel: PropTypes.bool,
        // title: PropTypes.string,
        banMaskClose: PropTypes.bool, // 禁止点击阴影关闭窗口
        // subTitle: PropTypes.string,
        onReject: PropTypes.func,
        shouldClose: PropTypes.bool, // 从false变成true时调用this.close
        noMask: PropTypes.bool,
        banAnime: PropTypes.bool,
    }

    constructor(p) {
        super()
        this.state = {
            open: true,
            zoomin: true
        }
        setTimeout(() => {
            this.setState({ zoomin: false })
        }, 500)
    }
    componentDidUpdate(p) {
        if (!p.shouldClose && this.props.shouldClose) {
            this.close({})
        }
    }
    close(e) {
        e.preventDefault && e.preventDefault()
        e.stopPropagation && e.stopPropagation()
        this.setState({
            zoomOut: true
        })
        setTimeout(() => {
            // this.setState({
            //     open: false
            // })
            if (this.props.onClose) {
                this.props.onClose()
                return
            }
            if (this.props.close) {
                this.props.close()
            }
        }, 400);
    }
    confirm(e) {
        e.preventDefault()
        e.stopPropagation()
        if (!this.props.confirm && !this.props.onConfirm) {
            this.setState({
                zoomOut: true
            })
        }
        // setTimeout(() => {
        if (this.props.confirm || this.props.onConfirm) {
            this.props.confirm && this.props.confirm()
            this.props.onConfirm && this.props.onConfirm()
        } else {
            if (this.props.close) {
                this.props.close()
            }
        }
        // }, 400);
    }
    render() {
        return (
            <div className={`mask animated faster fadeIn ${this.state.zoomOut ? 'fadeOut' : null}`} style={{
                display: this.state.open ? 'flex' : 'none',
                background: this.props.noMask ? 'transparent' : undefined,
            }} onClick={(e) => {
                if (this.props.banMaskClose) {
                    if (!this.props.banAnime) {
                        this.setState({ shake: true }, () => {
                            setTimeout(() => {
                                this.setState({ shake: false })
                            }, 500)
                        })
                    }
                    return
                }
                this.close(e)
            }} >
                <div className={`animated ${this.state.zoomin ? 'zoomIn' : ''} faster maskinner ${this.state.zoomOut ? 'zoomOut' : null}  ${this.state.shake ? 'shake' : null}`}
                    style={{ background: '#fff', borderRadius: 4, transition: 'height .3s', animationDuration: '.5s', ...this.props.style.outer }}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }} >
                    {/* title */}
                    <div className='fbc' style={{ padding: '16px 24px', fontSize: 16, fontWeight: 700, color: '#353535', borderBottom: '1px solid #e8e8e8', ...this.props.style.title }}>
                        <span>{this.props.title}</span>
                        <span style={{ fontWeight: 'normal', fontSize: 12 }}>{this.props.subTitle}</span>
                        {(!this.props.subTitle) ? <i className='iconfont icon-cha cur-pointer' onClick={e => {
                            this.close(e)
                        }} style={{ color: '#999' }} /> : null}
                    </div>

                    {/* content */}
                    <div className='hiddenScrollBar' style={{ padding: 24, transition: 'all 0s', boxSizing: 'border-box', maxHeight: '80vh', ...this.props.style.content }}>{this.props.children}</div>

                    {/* bottons */}
                    {(this.props.showConfirm || this.props.cancel) ? <div style={{ padding: '16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {this.props.tips ? <div style={{ flex: 1 }}>{this.props.tips}</div> : null}
                        <div>
                            {this.props.cancel ? (
                                <BTN solid color='gray' onClick={e => {
                                    if (this.props.onReject) {
                                        this.props.onReject()
                                    } else {
                                        this.close(e)
                                    }
                                }}>{this.props.cancelText}</BTN>
                            ) : ''}
                            {this.props.showConfirm ? (
                                <BTN solid color='red' style={{ marginLeft: 10 }} onClick={this.confirm.bind(this)} >{this.props.confirmText}</BTN>
                            ) : null}
                        </div>
                    </div>
                        : null}

                    {/* loading */}
                    {this.props.loading ? (<Loading />) : ''}
                </div>
            </div>
        )
    }
}

export default Modal

class Loading extends Component {
    render() {
        return (
            <div style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, 
                // zIndex: 999,
                background: 'rgba(240,240,240,.7)'
            }} className='fcc'>
                <div>
                    <i className='iconfont icon-xingzhuang' style={{ fontSize: 32, color: '#000' }}></i>
                </div>
            </div>
        )
    }
}