import React, { Component } from 'react'
import { connect } from 'react-redux';

import Main from '../../high-component/Content'
import BanUnLogin from '../../high-component/BanUnLogin'

import api from '../../netApi/index';

import p_1 from '../../images/p_1.png';
import p_2 from '../../images/p_2.png';
import p_3 from '../../images/p_3.png';
import p_4 from '../../images/p_4.png';
import p_5 from '../../images/p_5.png';
import p_6 from '../../images/p_6.png';

class Member extends Component {
    static defaultProps = {
        user: {}
    }
    constructor(p) {
        super()
        this.state = {}
        api('proxy', 'checkProxy')({ type: 1 }, 'post').then((proxy) => {
            if (Number(proxy.data.code) !== 0) {
                return
            }
            const proxyData = proxy.data.data
            this.setState({
                end_time: proxyData.end_time,
                type: proxyData.type
            })
            if (Number(proxyData.type) === 5 || Number(proxyData.type) === 0) {
                p.histroy.push('/')
                return
            }
            api('proxy', 'accountConfigList')().then((res) => {
                if (Number(res.data.code) !== 0) {
                    return
                }
                for (let i = 0; i < res.data.data.length; i++) {
                    const item = res.data.data[i];
                    if (Number(item.type) === Number(proxyData.type)) {
                        this.setState({
                            memberData: item
                        })
                    }
                }
            })
        })
    }

    render() {
        return (
            <div id='member_after_pay'>
                <div className='top'>
                    <div className='main'>
                        <div className='content'>
                            <div className='img-wrap'>
                                <img alt={this.props.user.name} src={this.props.user.avatar} />
                            </div>
                            <div className='name_and_time'>
                                <div>{this.props.user.name}</div>
                                <div>{this.state.memberData ? this.state.memberData.name : ''}{' '}{this.state.end_time}到期</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='title'>我的权益</div>
                {this.state.memberData ? (
                    <div className='power'>
                        <div>
                            <div>
                                <div className='fcc icon'>
                                    <img src={p_1} alt=''></img>
                                </div>
                                <div>工具插件</div>
                            </div>
                            <div className='value'>{this.state.memberData.func_name.join('、')}</div>
                        </div>
                        <div>
                            <div>
                                <div className='fcc icon'>
                                    <img src={p_2} alt=''></img>
                                </div>
                                <div>交易费率</div>
                            </div>
                            <div className='value'>{this.state.memberData.rate}%</div>
                        </div>
                        <div>
                            <div>
                                <div className='fcc icon'>
                                    <img src={p_3} alt=''></img>
                                </div>
                                <div>管理员数量</div>
                            </div>
                            <div className='value'>{this.state.memberData.manage_num}个</div>
                        </div>
                        <div>
                            <div>
                                <div className='fcc icon'>
                                    <img src={p_4} alt=''></img>
                                </div>
                                <div>帮买好友数量</div>
                            </div>
                            <div className='value'>{this.state.memberData.distri_num}个</div>
                        </div>
                        <div>
                            <div>
                                <div className='fcc icon'>
                                    <img src={p_5} alt=''></img>
                                </div>
                                <div>小程序高级功能</div>
                            </div>
                            <div className='value'>{this.state.memberData.pro_senior_func_name.join('、')}</div>
                        </div>
                        <div>
                            <div>
                                <div className='fcc icon'>
                                    <img src={p_6} alt=''></img>
                                </div>
                                <div>PC端高级功能</div>
                            </div>
                            <div className='value'>{this.state.memberData.pc_senior_func_name.join('、')}</div>
                        </div>
                    </div>
                ) : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        waitingLogin: state.waitingLogin
    }
}

export default BanUnLogin(Main(connect(mapStateToProps)(Member)))