import React, { Component } from 'react'

import Modal from '../../total/Modal';
import Select from '../../total/Select_';

import { post } from '../../../netApi/fetch';

import { message, notification } from 'antd';

interface Props {
    takeway: number,
    quickSendList: TuanItem[],
    quickCheckinList: TuanItem[],
    initTuanId?: number,
    onClose: () => void,
    onConfirm: () => void,
}
interface State {
    quickSend?: boolean,
    quickIndex?: number,

    showSuccess?: boolean,
    showConfirm?: boolean,

    loading?: boolean,
    shouldClose?: boolean,
}

interface TuanItem {
    id: string,
    title: string,
    pid: string,
    distributionid: string,
    takeway: string,
}




class Quick extends Component<Props, State> {

    readonly state: State = {

    }

    async componentDidMount() {
        console.log(this.props.initTuanId);
        if (this.props.takeway === 1) {
            this.setState({
                quickIndex: this.props.quickSendList.findIndex(i => Number(i.id) === this.props.initTuanId)
            })
        }
        if (this.props.takeway === 2) {
            this.setState({
                quickIndex: this.props.quickCheckinList.findIndex(i => Number(i.id) === this.props.initTuanId)
            })
        }
    }


    render() {
        if (this.props.takeway === 1) {
            return <Modal cancel={true} shouldClose={this.state.shouldClose} title={'一键发货（无需导入单号）'} onConfirm={() => {
                if (this.state.quickIndex === undefined || this.state.quickIndex === null) {
                    message.warning('请选择活动')
                    return
                }
                this.setState({ showConfirm: true, })
            }} onClose={() => { this.props.onClose() }} >
                <div style={{ width: 450 }}>
                    <div className='c33'>请选择要发货的活动（不包括帮好友卖的活动）</div>
                    <div className='fbc c33' style={{ marginTop: 12 }}>
                        <div style={{ marginRight: 12 }}>选择活动</div>
                        <div style={{ flex: 1 }}>
                            <Select value={this.state.quickIndex} onChange={e => {
                                this.setState({
                                    quickIndex: this.props.quickSendList.findIndex(i => i.id === e.id)
                                })
                            }} uni='quick_send_select' showKey='title' maxHeight={300} list={this.props.quickSendList} />
                        </div>
                    </div>
                    <div className='c99' style={{ marginTop: 12 }}>1、适用于由商家自己配送货物的活动，无需导入快递单号</div>
                    <div className='c99' style={{ marginTop: 12 }}>2、适用于未申请过退款的订单，已申请过部分退款的订单需手动发货。</div>

                    {this.state.showConfirm ? <Modal loading={this.state.loading} title='' onConfirm={() => {
                        if (this.state.quickIndex === undefined || this.state.quickIndex === null) {
                            message.warning('请选择活动')
                            return
                        }
                        this.setState({ loading: true })
                        post('/order/akeycheckinorder', { types: 1, tuanid: this.props.quickSendList[this.state.quickIndex].id }).then((res: any) => {
                            notification.success({
                                message: `共${res.can_checkin_order_count}个订单已发货，${res.exclude_order_count}个申请过退款的订单未发货`,
                                description: '（若订单较多时，核销时间会比较长，可等待1～3分钟后刷新即可）'
                            })
                            this.setState({ loading: false })
                            this.props.onConfirm()
                        }).catch((e: any) => {
                            if (e.message) message.error(e.message)
                            this.setState({ loading: false })
                        })
                    }} >
                        一键发货后，商品由你自己配送，且无法输入快递单号，确定要一键发货？
                </Modal> : null}
                </div>
            </Modal>
        }
        if (this.props.takeway === 2) {
            return <Modal cancel={true} shouldClose={this.state.shouldClose} title={'一键核销'} onConfirm={() => {
                if (this.state.quickIndex === undefined || this.state.quickIndex === null) {
                    message.warning('请选择活动')
                    return
                }
                this.setState({ showConfirm: true, })
            }} onClose={() => { this.props.onClose() }} >
                <div style={{ width: 450 }}>
                    <div className='c33'>请选择要发货的活动（不包括帮好友卖的活动）</div>
                    <div className='fbc c33' style={{ marginTop: 12 }}>
                        <div style={{ marginRight: 12 }}>选择活动</div>
                        <div style={{ flex: 1 }}>
                            <Select value={this.state.quickIndex} onChange={e => {
                                this.setState({
                                    quickIndex: this.props.quickCheckinList.findIndex(i => i.id === e.id)
                                })
                            }} uni='quick_send_select' showKey='title' maxHeight={300} list={this.props.quickCheckinList} />
                        </div>
                    </div>
                    <div className='c99' style={{ marginTop: 12 }}>1、适用于配送方式为到门店自提的活动，可以一键核销该活动的所有订单（帮卖模式下暂不支持一键核销）</div>
                    <div className='c99' style={{ marginTop: 12 }}>2、适用于未申请过退款的订单，已申请过部分退款的订单需手动核销</div>

                    {this.state.showConfirm ? <Modal loading={this.state.loading} title='' onConfirm={() => {
                        if (this.state.quickIndex === undefined || this.state.quickIndex === null) {
                            message.warning('请选择活动')
                            return
                        }
                        this.setState({ loading: true })
                        post('/order/akeycheckinorder', { types: 1, tuanid: this.props.quickCheckinList[this.state.quickIndex].id }).then((res: any) => {
                            notification.success({
                                message: `共${res.can_checkin_order_count}个订单已核销，${res.exclude_order_count}个申请过退款的订单未核销`,
                                description: '（若订单较多时，核销时间会比较长，可等待1～3分钟后刷新即可）'
                            })
                            this.setState({ loading: false })
                            this.props.onConfirm()
                        }).catch((e: any) => {
                            if (e.message) message.error(e.message)
                            this.setState({ loading: false })
                        })
                    }} >
                        一键核销后，该活动下所有订单都将核销，确定要一键核销？
                </Modal> : null}
                </div>
            </Modal>
        }
        return null
    }

}

export default Quick