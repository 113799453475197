const ERROR = {
    MEMBER: {
        VIDEO: '存储空间已用完，需升级版本扩大存储空间才可添加图片、视频，是否现在去升级？',
        IMAGE: '存储空间已用完，需升级版本扩大存储空间才可添加图片、视频，是否现在去升级？',
        EXPRESS: '免费的消费者快递物流通知及查询次数已用完，需升级版本才可增加查询次数。升级前仅向顾客推送快递单号，不推送快递物流信息。是否现在去升级？',
        ADMIN: '',
    }
}

export default {
    ERROR,
}