import React from "react";
import Switch from "../../total/Switch";

export default function SwitchItem(props: {
  onChange: () => void;
  checked: boolean;
  text: React.ReactElement | string;
}) {
  return (
    <div
      className="edittuan__switchitem fbc cur-pointer"
      onClick={() => {
        props.onChange();
      }}
    >
      <div className="edittuan__switchitem__text fbc">{props.text}</div>
      <Switch checked={props.checked} onClick={props.onChange} />
    </div>
  );
}
