import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../total/Modal';
import styled from 'styled-components';
import BTN from '../../../StyleComponents/Button';
import imageUpload, { selectImage } from '../../../methods/imageUpload';
import Select from '../../total/Select_';
import Textarea from '../../total/Input/Textarea';
import { QQ_MAP_KEY } from '../../../data/options';
import { message } from 'antd';
import { get, post } from '../../../netApi/fetch';
import Loading from '../../Method/Loading';
let confirmWrap: Element
interface props {
    id?: number | string,
    mall_id?: number | string,
    edit_mall_id?: number | string,
    user: user,
    mallinfo?: mall.mallinfo,
}

const Border = styled.div`
    width: 622px;
    max-height: inherit;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    & > div{
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 18px 20px;
        strong{
            display: inline-block;
            min-width: 4em;
            margin-right: 1em;
        }
    }
`
const Input = styled.input`
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 2px;
    padding: 4px 11px;
    min-width: 18em;
`

export default function (props: props) {
    return new Promise<void>((res, rej) => {
        function Create(p) {
            const [logo, setlogo] = useState('')
            const [name, setName] = useState('')
            const [desc, setDesc] = useState('')
            const [contact, setContact] = useState('')
            const [addressDetail, setAddressDetail] = useState('')
            const [address, setAddress] = useState('')
            const poidata = useRef<any>()
            const [phone, setPhone] = useState('')
            const [mallGroupList, setMallGroupList] = useState<{
                id: string,
                name: string,
                check?: boolean,
            }[]>()
            const warpper = useRef<HTMLDivElement>(null)
            useEffect(() => {
                if (!mallGroupList) {
                    get('/mall/getmallbranchgrouplist', {}).then(res => {
                        setMallGroupList(res.filter(i => i.id !== 'all' && String(i.id) !== '0'))
                    })
                }
            }, [mallGroupList])

            useEffect(() => {
                if (p.edit_mall_id && p.data) {
                    const data = p.data
                    setlogo(data.logo)
                    setName(data.branch_name)
                    setContact(data.contact_name)
                    setDesc(data.desc)
                    setPhone(data.phone)
                    setAddressDetail(data.addressdetail)
                    setAddress(data.address)
                    poidata.current = {
                        latlng: {
                            lat: data.lat,
                            lng: data.lng
                        }
                    }
                }
            }, [p.edit_mall_id, p.data])

            function selectPoint() {
                console.log(warpper.current);
                const Iframe = document.createElement('iframe')
                Iframe.id = 'map'
                Iframe.width = '400px'
                Iframe.height = '800px'
                Iframe.style.cssText = 'z-index: 100; position: fixed; top: 20vh; left: calc(50vw - 200px);'
                Iframe.src = `https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=${QQ_MAP_KEY}&referer=jieligo`
                if (poidata.current && poidata.current.latlng && poidata.current.latlng.lng && poidata.current.latlng.lat) {
                    Iframe.src += `&coordtype=1&coord=${poidata.current.latlng.lat},${poidata.current.latlng.lng}`
                }
                const cb = function (event) {
                    // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
                    var loc = event.data;
                    if (warpper.current) {
                        warpper.current.removeChild(Iframe)
                    }
                    window.removeEventListener('message', cb)
                    if (loc && loc.module === 'locationPicker') {
                        console.log('location', loc);
                        poidata.current = loc
                        setAddress(loc.poiaddress)
                    }
                }
                window.addEventListener('message', cb, false);
                if (warpper.current) {
                    warpper.current.appendChild(Iframe)
                }
            }
            function confirm() {
                if (!props.mall_id && !props.edit_mall_id) {
                    message.warn('请先开启商城')
                    return
                }
                if (!name) {
                    message.warn('请填写门店名称')
                    return
                }
                if (!contact) {
                    message.warn('请填写联系人')
                    return
                }
                if (!phone) {
                    message.warn('请填写门店联系电话')
                    return
                }
                if (!(poidata.current && poidata.current.latlng && poidata.current.latlng.lng && poidata.current.latlng.lat)) {
                    message.warn('请选择门店地址')
                    return
                }

                console.log(mallGroupList);

                const data = {
                    op: props.edit_mall_id ? 3 : 2,
                    mall_id: props.edit_mall_id || props.mall_id,
                    branch_name: name,
                    logo: logo || props.user.avatar,
                    address,
                    addressdetail: addressDetail,
                    desc,
                    contact_name: contact,
                    phone,
                    lng: poidata.current.latlng.lng,
                    lat: poidata.current.latlng.lat,
                    branch_group_id: mallGroupList.find(i => i.check)?.id
                }
                
                post('/mall/setmall', data).then(() => {
                    message.success(props.edit_mall_id ? '修改成功' : '创建成功')
                    cancel()
                }).catch(e => { if (e.message) message.error(e.message) })

            }
            return <Modal title={props.edit_mall_id ? '修改门店' : '创建门店'} confirmText='保存设置' cancel onConfirm={confirm} onClose={cancel} >
                <Border>
                    <div ref={warpper} className='flex-box'>
                        <img alt='商城头像' className='img-center' src={logo || props.user.avatar} style={{ width: 40, height: 40, borderRadius: 20, marginRight: 16 }} />
                        <BTN noborder color='red' onClick={() => {
                            selectImage().then(res => {
                                if (res[0]) { return imageUpload(res[0], 'shop') }
                            }).then(res => {
                                setlogo(res.src)
                            })
                        }}>修改</BTN>
                    </div>
                    <div className='flex-box'>
                        <strong>{props.mallinfo?.name}</strong>
                        <Input placeholder='请输入门店名称' value={name} onChange={e => { setName(e.target.value) }} />
                    </div>
                    <div className='flex-box'>
                        <strong>门店分组</strong>
                        <Select placeholder='请选择门店分组' uni='group' list={mallGroupList || []} showKey='name' value={(mallGroupList || []).findIndex(i => i.check)} onChange={e => {
                            setMallGroupList(mallGroupList.map(i => {
                                if (i.id === e.id) return { ...i, check: true }
                                return { ...i, check: false }
                            }))
                        }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <strong>门店简介</strong>
                        <div style={{ flex: 1 }}>
                            <Textarea value={desc} onChange={e => { setDesc(e) }} />
                        </div>
                    </div>
                    <div>
                        <div style={{ marginBottom: 16 }} className='flex-box'>
                            <strong>门店地址</strong>
                            <Input placeholder='请输入门店地址' onChange={() => { }} value={address} onClick={() => { selectPoint() }} />
                        </div>
                        <div style={{ marginBottom: 16 }} className='flex-box'>
                            <strong>详细地址</strong>
                            <Input placeholder='例：3号楼6层602（必填）' value={addressDetail} onChange={e => { setAddressDetail(e.target.value) }} />
                        </div>
                        <div style={{ marginBottom: 16 }} className='flex-box'>
                            <strong>联系人</strong>
                            <Input placeholder='请填写联系人（必填）' value={contact} onChange={e => { setContact(e.target.value) }} />
                        </div>
                        <div style={{ marginBottom: 16 }} className='flex-box'>
                            <strong>联系电话</strong>
                            <Input placeholder='请填写联系方式（必填）' value={phone} onChange={e => { setPhone(e.target.value) }} />
                        </div>
                        <p style={{
                            fontSize: 14,
                            color: '#999',
                        }}>温馨提示：商城地址可作为商城支持自提的商品的自提点地址</p>
                    </div>
                </Border>
            </Modal>

        }

        function cancel() {
            if (confirmWrap) {
                ReactDOM.render(null, confirmWrap)
                document.body.removeChild(confirmWrap)
            }
            confirmWrap = undefined
            res()
        }

        function getData() {
            Loading.show()
            return get('/mall/getmallinfo', { mall_id: props.edit_mall_id }).then(res => {
                Loading.hide()
                ReactDOM.render(<Create {...props} data={res} />, confirmWrap)
            })
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        if (!props.edit_mall_id) {
            ReactDOM.render(<Create {...props} />, confirmWrap)
        } else {
            getData()
        }
    })
}