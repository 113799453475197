import React, { Component } from 'react'
import { connect } from 'react-redux'

import api from '../netApi'
// import {withRouter} from 'react-router-dom';

export default (Warp) => {
    class BanUnLogin extends Component {
        constructor(p) {
            super()
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            let url = encodeURIComponent(window.location.href.split('#')[0])
            if (isiOS && p.init_url) {
                // alert(p.init_url)
                url = encodeURIComponent(p.init_url)
            }
            this.url = url

            this.state = {
                canReady: false
            }

            // if(p.is_Login){
            this.getParam()
            // }
        }
        componentWillUnmount() {
            this.setState = (state, callback) => {
                return;
            };
        }

        componentDidUpdate(p) {
            if (!p.is_Login && this.props.is_Login) {
                this.getParam()
            }
        }

        render() {
            return (
                <Warp {...this.props} canReady={this.state.canReady} />
            )
        }
        getParam(url = this.url) {
            api('huser', 'param')({}, 'post', {
                url
            }).then((res) => {
                // alert(url)
                wxconfig(res.data.data)
                this.setState({
                    canReady: true
                })
            })
        }
    }
    // return withRouter(connect(mapStateToProps)(BanUnLogin))
    return connect(mapStateToProps)(BanUnLogin)
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        waiting: state.waitingLogin,
        is_Login: state.is_Login,
        init_url: state.init_url
    }
}

function wxconfig(sign) {
    // alert(2)
    // eslint-disable-next-line no-undef
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: sign.appId, // 必填，公众号的唯一标识
        timestamp: sign.timestamp, // 必填，生成签名的时间戳
        nonceStr: sign.nonce_str, // 必填，生成签名的随机串
        signature: sign.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'openLocation'] // 必填，需要使用的JS接口列表
    })
}