import React, { Component } from 'react'
// import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router-dom'

import { toggleLoginPanel, set_login_user_data, waitingLogin, setusertype, initurl, toggleSidebar, logout } from '../../reducers/index'
// import Select from '../total/Select_';

import WX from '../../high-component/Wx_sign';
import parse from '../../methods/paramParse';
// import { get } from '../../netApi/fetch';
// import Prompt from '../Method/Prompt';


import { TOPBAR_HEIGHT } from '../../data/style';
// import styled from 'styled-components';

interface Props extends RouteComponentProps {
    match: Match,
    islogin: boolean,
    user: user,
    openLoginPanel: () => void
}

interface Match extends match {
    params: {
        data: any
    }
}

interface state {
    set: Set<number>;
    bg: string;
    url: string;
}


class test extends Component<Props> {

    readonly state: state = {
        set: new Set(),
        bg: '',
        url: '',
    }
    componentDidMount() {
        document.body.title = '登录中...'
    }

    componentDidUpdate(p: Props, s: state) {
        const data = parse(this.props.location.search)
        if (!data.redirect_uri) return
        data.redirect_uri = decodeURIComponent(data.redirect_uri)
        if (!(data.redirect_uri.match(/localhost/gi) || data.redirect_uri.match(/https/gi))) return
        if (this.props.islogin) {
            if (data.redirect_uri.match(/\?/g)) {
                data.redirect_uri += `&token=${this.props.user.token}`
            } else {
                data.redirect_uri += `?token=${this.props.user.token}`
            }
            console.log('redirect', data);
            window.location.href = data.redirect_uri
        } else {
            this.props.openLoginPanel()
        }
    }

    render() {
        if (this.props.location.pathname === '/auth') {
            return <></>
        }
        return (<div className='flex-center' style={{ marginTop: TOPBAR_HEIGHT }}>
            <div>登录中</div>
        </div>)
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.login_user_data,
        islogin: state.is_Login
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeLoginPanel: () => {
            dispatch(toggleLoginPanel(false))
        },
        openLoginPanel: () => {
            dispatch(toggleLoginPanel(true))
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        waitingLogin: () => {
            dispatch(waitingLogin(false))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
        initurl: (v) => {
            dispatch(initurl(v))
        },
        toggleSidebar: b => {
            dispatch(toggleSidebar(b))
        },
        logout: () => {
            dispatch(logout())
        },
    }
}

export default WX(connect(mapStateToProps, mapDispatchToProps)((test)))