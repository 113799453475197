import React, { Component } from 'react'
import styled from 'styled-components';
import { InputNumber, message } from 'antd';
import Modal from '../../total/Modal';
import Select from '../../total/Select_';
import { initiativeRefund, applyrefund } from '../methods/initiativeRefund';
import { get } from '../../../netApi/fetch';
import { ReduxImg } from '../../total/DraggableImgs';

const ModeSelect = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`
const Inner = styled.div`
    width: 60vw;
    max-width: 100%;
`
const GoodName = styled.div`
    flex: 1;
    margin-right: 1rem;
    font-size: 16px;
    word-break: break-all;
    /* white-space: ; */
    span:nth-child(1){
        color: #333;
    }
    span:nth-child(2){
        color: #666;
    }
`
const Goodlist = styled.div`
    overflow-y: auto;
    max-height: 65vh;
    padding: 5px;
    &::-webkit-scrollbar-thumb {
        background: rgba(102,102,102,.6);
        border-radius: 3px;
    }
`
const Logo = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 20px;
`


const RefundItem = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div:nth-child(1){
        width: 6em;
    }
    & > div:nth-child(2){
        flex: 1;
        & > input {
            display: inline-block;
            width: 100%;
            border-radius: 4px;
            padding: 4px 11px;
            border: #d9d9d9 solid 1px;
            transition: all .3s;
        }
    }
`
interface Props {
    order: order,
    onClose: (refresh?: boolean) => void,
    modelist?: number[],
    isTop: number,
}

interface selectedGood extends order.good {
    selectedNum: number
}

interface State {
    mode: number,
    shouldClose: boolean,
    confirmText: boolean,
    refunding: boolean,
    loading: boolean,
    goodlist: selectedGood[],
    showReject: boolean,
    reasonList: { title: string, id: number }[],
    rejectReason?: number,
    rejectReasonText?: string,
}

interface uploadData {
    refund_desc?: string,
    refund_reason?: number,
    images_item?: {
        rank: number | string,
        id: number | string,
    }[]
}
class Refund extends Component<Props, State>{

    readonly state: State = {
        mode: 1,
        shouldClose: false,
        confirmText: false,
        refunding: false,
        loading: false,
        goodlist: [],
        showReject: false,
        reasonList: [{ title: '订单已发货', id: 5 }, { title: '货物不支持退款', id: 6 }, { title: '已超出退货/退款时间', id: 7 }, { title: '其他', id: 8 }],
        rejectReasonText: '',
    }

    async componentDidMount() {
        const data = await get('/order/getcanrefundmode', { types: 1, orderid: btoa('' + this.props.order.orderid) })
        this.modelist = this.modelist.filter(i => {
            if (!this.props.modelist) return true
            for (let j = 0; j < this.props.modelist.length; j++) {
                const type = this.props.modelist[j];
                if (i.type === type) return true
            }
            return false
        })

        this.setState({
            goodlist: data.can_refund_list,
            mode: this.modelist[0].type
        })
    }

    modelist = [{ type: 1, name: '全部退款' }, { type: 3, name: '部分退款' }]

    UploadRefundData: uploadData = {}

    render() {
        const select_num: number = ((): number => {
            if (this.state.mode === 1) {
                return this.props.order.items.reduce((num: number, good) => {
                    return num + Number(good.num)
                }, 0)
            }
            if (this.state.mode === 3) {
                return this.state.goodlist.reduce((num: number, good: selectedGood) => {
                    return num + Number(good.selectedNum || 0)
                }, 0)
            }
            return 0
        })()
        const tips = (() => {
            if (this.state.mode === 1) {
                return <><span>合计{select_num}件，退款金额：</span><span style={{ color: 'var(--theme-main)' }}>￥{(Number(this.props.order.payprice) / 100).toFixed(2)}</span></>
            }
            if (this.state.mode === 3) {
                const price = this.state.goodlist.reduce((price: number, good: selectedGood): number => {
                    return price + Number(good.selectedNum || 0) * Number(good.price)
                }, 0)
                return <>
                    <span>合计{select_num}件，退款金额：</span><span style={{ color: 'var(--theme-main)' }}>￥{(price / 100).toFixed(2)}</span>
                </>
            }
        })()
        return <>
            <Modal tips={tips} title='退款' shouldClose={this.state.shouldClose} onClose={() => { this.props.onClose() }} onConfirm={() => {
                if (select_num <= 0) {
                    message.warning('请选择商品')
                    return
                }
                this.setState({ confirmText: true })
            }} cancel={true} >
                <Inner>
                    <ModeSelect onClick={() => {
                        if (this.props.modelist ? this.props.modelist.length === 1 : false) {
                            message.warning('本订单无法选择退款方式')
                        }
                    }}>
                        <div>选择退款模式</div>
                        <div>
                            <Select list={this.modelist} uni='mode' showKey='name' value={this.modelist.findIndex(i => i.type === this.state.mode)} onChange={e => {
                                this.setState({ mode: e.type })
                            }} disabled={this.props.modelist ? this.props.modelist.length === 1 : false} />
                        </div>
                    </ModeSelect>
                    <p style={{ fontSize: 12, color: '#999' }}>温馨提示：每个订单最多可退款3次，取消退款申请也算一次。</p>
                    <Goodlist>
                        {this.state.mode === 1 ? <div>
                            {this.state.goodlist.map((i: selectedGood) => {
                                return <div key={i.id} className='fbc' style={{ alignItems: 'flex-start', marginBottom: 30, }}>
                                    <Logo>
                                        <img src={i.logo} alt='' className='img-center' />
                                    </Logo>
                                    <GoodName><span>{i.name}</span><span>({i.unit})</span></GoodName>
                                    <div className='fbc'>
                                        <div><span style={{ marginRight: '1rem', color: 'var(--theme-main)' }}>￥{((i.price / 100)).toFixed(2)}</span><span>数量： {i.num}</span></div>
                                    </div>
                                </div>
                            })}
                        </div> : null}
                        {this.state.mode === 3 ? <div>
                            {this.state.goodlist.map((i: selectedGood) => {
                                return <div key={i.id} style={{ marginBottom: 30, }}>
                                    <div className='fbc' style={{ alignItems: 'flex-start' }}>
                                        <Logo>
                                            <img src={i.logo} alt='' className='img-center' />
                                        </Logo>
                                        <GoodName><span>{i.name}</span><span>({i.unit})</span></GoodName>
                                    </div>
                                    <div className='fbc'>
                                        <div><span style={{ marginRight: '1rem' }}>购买： {i.num}</span><span style={{ marginRight: '1rem' }}>已退： {i.refund_num}</span><span>已核销： {i.checkin_num}</span></div>
                                        <InputNumber min={0} max={i.num - i.refund_num - i.checkin_num} value={i.selectedNum} formatter={(num) => String(Math.round(Number(num)))} onChange={(e) => {
                                            this.setState({
                                                goodlist: this.state.goodlist.map((j: selectedGood) => {
                                                    if (j.id !== i.id) return j
                                                    const data: selectedGood = {
                                                        ...j,
                                                        selectedNum: Number(e) || 0,
                                                    }
                                                    return data
                                                })
                                            })
                                        }} />
                                    </div>
                                </div>
                            })}
                        </div> : null}
                    </Goodlist>
                    {
                        Number(this.props.isTop) === 0 ?
                            <div>
                                <RefundItem>
                                    <div>退款理由：</div>
                                    <div>
                                        <Select
                                            list={this.state.reasonList}
                                            showKey={'title'} placeholder='选择拒绝理由'
                                            uni='reason' onChange={this.selectRaeson.bind(this)} value={this.state.rejectReason} />
                                    </div>
                                </RefundItem>
                                <RefundItem>
                                    <div>其他说明：</div>
                                    <div>
                                        <input value={this.state.rejectReasonText} placeholder='选填' onChange={this.setReasonText.bind(this)} />
                                    </div>
                                </RefundItem>
                                <div>
                                    <div style={{ marginBottom: 12 }}>图片：</div>
                                    <div style={{ width: 250 }}>
                                        <ReduxImg maxLength={6} imgtype='refund' images={[]} width={70} height={70} onChange={(e: image[]) => {
                                            const imglist = e.map((i: image) => {
                                                const rank: number = Number(i.rank) || 0
                                                return {
                                                    id: i.id,
                                                    rank: rank,
                                                }
                                            })
                                            this.UploadRefundData.images_item = imglist
                                        }} />
                                    </div>
                                    <div style={{ overflowY: 'auto', maxHeight: '40vh' }}>
                                    </div>
                                </div>
                            </div> : null
                    }
                </Inner>
            </Modal>
            {this.state.confirmText ? <Modal title='确认退款' loading={this.state.refunding} style={{ content: { paddingBottom: 8 } }} onConfirm={this.refund.bind(this)} cancel onClose={() => { this.setState({ confirmText: false }) }} >
                {(() => { console.log(this.state, this.props) })()}
                {this.state.mode === 1 ? <div style={{ width: 270 }}>
                    {tips}
                </div> : null}
                {this.state.mode === 3 ? <div style={{ width: 270 }}>
                    {tips}
                </div> : null}
            </Modal> : null}
        </>
    }

    async refund() {

        if (Number(this.props.isTop) === 0 && !this.UploadRefundData.refund_reason) {
            message.warn('请选择退款理由')
            return
        }

        let res
        this.setState({ refunding: true })
        const data = {
            refund_mode: this.state.mode,
            items: this.state.goodlist.map(i => {
                return { id: i.id, num: i.selectedNum }
            }).filter(i => i.num),
            orderid: this.props.order.orderid,
            ...this.UploadRefundData,
        }

        if (Number(this.props.isTop) === 0) {
            try {
                res = await applyrefund(data)
            } catch (e) { if (e.message) message.error(e.message) }

        } else {
            try {
                res = await initiativeRefund(data)
            } catch (e) {
                if (e.message) message.error(e.message) 
            }
        }
        this.setState({ confirmText: false, refunding: false })
        if (!res) return
        message.success('退款成功')
        this.setState({ shouldClose: true })
    }

    selectRaeson(e: { [key: string]: any; }) {
        this.setState({ rejectReason: this.state.reasonList.findIndex(i => i.id === e.id) })
        this.UploadRefundData.refund_reason = e.id
    }

    setReasonText(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ rejectReasonText: e.currentTarget.value })
        this.UploadRefundData.refund_desc = e.currentTarget.value
    }
}

export default Refund