import React, { useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { post } from '../../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
import Select from '../../total/Select_'
// import Switch from '../../total/Switch'
// import BTN from '../../../StyleComponents/Button'
// import { confirm as Confirm } from '../../Method/Confirm';
// import Prompt from '../../Method/Prompt'
import { good as TypeGood } from '../datalist'


interface props {
    onClose: () => void
    data: {

    }
}

let wrapper: Element

const Border = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    & > div{
        display: flex;
        align-items: center;
        & > div{
            margin-right: 30px;
        }
        input{
            border: 1px solid #979797;
            border-radius: 4px;
        }
    }
`

export default async function (good: TypeGood, eventList: any[]) {
    return new Promise(async (reslove, reject) => {
        function Category(props: props) {
            const [shouldClose, setShouldClose] = useState(false)
            const [value, setvalue] = useState<number>()
            const [price, setPrice] = useState(good.attribute.map(i => {
                return {
                    price: '',
                    name: i.unit,
                    id: i.goodsattrid,
                    originPrice: (Number(i.price) / 100).toFixed(2)
                }
            }))
            return <Modal title='参与活动' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <Border>
                        <div>
                            <div>选择促销活动</div>
                            <div style={{ width: 100 }}>
                                <Select uni='event' showKey='title' placeholder='请选择活动' list={eventList} value={value} onChange={(e, idx) => {
                                    setvalue(idx)
                                }} />
                            </div>
                        </div>
                    </Border>
                    <Border>
                        {price.map((i, idx) => {
                            return <div key={idx} style={{ display: 'block', marginBottom: 20 }}>
                                <div style={{ marginBottom: 20 }}>规格{idx + 1}：{i.name}</div>
                                <div className='flex-box'>
                                    <div style={{ marginRight: 10, }}>活动价</div>
                                    <input style={{ marginRight: 10, padding: 6, fontSize: 12, width: 100 }} value={i.price} onBlur={() => {
                                        if (!Number(i.price)) {
                                            i.price = ''
                                        } else {
                                            i.price = Number(i.price).toFixed(2)
                                        }
                                        setPrice([...price])
                                    }} onChange={e => {
                                        i.price = e.target.value
                                        setPrice([...price])
                                    }} />
                                    <div>元</div>
                                    <div style={{ fontSize: 12, color: '#999', marginLeft: 16 }}>团购价：￥{i.originPrice}</div>
                                </div>
                            </div>
                        })}
                        <div style={{ fontSize: 12, color: '#8b8b8b', }}>开启帮卖后，活动价的佣金与原售价佣金比例相同</div>
                    </Border>
                </div>
            </Modal>

            function confirm() {
                if (value === undefined) {
                    message.warning('请选择活动')
                    return
                }
                let flag = false
                const goods_attr_list = price.map(i => {
                    if (!i.price) flag = true
                    return {
                        goods_attr_id: i.id,
                        price: (Number(i.price) * 100).toFixed(0)
                    }
                })
                if (flag) {
                    message.warning('请填写价格')
                    return
                }
                post('/mall/putontheactivity', {
                    activity_id: eventList[value].id,
                    goods_id: good.goodsid,
                    goods_attr_list,
                }).then(res => {
                    setShouldClose(true)
                    reslove(true)
                }).catch(e => { if (e.message) message.error(e.message) })
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}