import React, { useEffect, } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import pay from '../../data/pay';
import paramParse from '../../methods/paramParse';
import { post } from '../../netApi/fetch';
interface props extends RouteComponentProps {
    user: user
}

function Pay(props: props) {
    useEffect(() => {
        if (!props.user || !props.user.sid) return
        const data = paramParse(props.location.search)
        if (!data.redirect_uri) {
            window.history.back()
            return
        }
        if (!data.from || !data.fee || !data.orderid) {
            window.history.back()
            return
        }
        console.log(data, props.user);
        post(`/wechatpay/prepay?app=${data.app || props.user.app_name}`, {
            openid: props.user.openid,
            from: data.from,
            fee: data.fee,
            appid: data.appid || props.user.appid,
            orderid: data.orderid,
            couponlogid: data.couponlogid || '',
        }).then(result => {
            const paydata = result.data.data
            pay(paydata.jsparams, () => {
                alert('支付成功')
                window.location.href = decodeURIComponent(data.redirect_uri)
            })
        })
    }, [props.location, props.user])

    useEffect(() => {
        document.title = '支付中'
        return () => {
            document.title = ''
        }
    }, [])

    return <></>
}

const mapStateToProps = (state,) => {
    return {
        user: state.login_user_data,
    }
}
export default connect(mapStateToProps,)(Pay)