import React, { Component } from 'react'
import { connect } from 'react-redux';
import { get } from '../../../netApi/fetch';
// import { get, getToken, getUrl } from '../../netApi/fetch';
// import { message } from 'antd';
import { match, NavLink, RouteComponentProps } from 'react-router-dom';
import Modal from '../../total/Modal';
// import styled from 'styled-components';
import Select from '../../total/Select_';
import Loading from '../../Method/Loading';
import BTN from '../../../StyleComponents/Button';
import { DatePicker, message, Pagination } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import TimePickerLocale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import { ExportPickData } from '../../mall/Method/ExportPickData';

const { RangePicker } = DatePicker;

// const Tip = styled('div') <{ left: number }>`
//     position: absolute;
//     background: #333;
//     transform-origin: bottom center;
//     transform: scale(0);
//     transition: transform .2s;
//     color: #fff;
//     border-radius: 4px;
//     padding: 14px 7px;
//     font-size: 14px;
//     right: -2em;
//     bottom: 3em;
//     span:nth-child(2){
//         color: #EC9E2D;
//     }
//     button:hover &{
//         transform: scale(1);
//     }
//     ::after{
//         content: '';
//         position: absolute;
//         bottom: -6px;
//         left: ${props => props.left - 6}px;
//         height: 0;
//         width: 0;
//         border-left: transparent solid 6px;
//         border-right: transparent solid 6px;
//         border-top: #333 solid 6px;
//     }
// `
interface props extends RouteComponentProps {
    match: m
    sidebarOpen: boolean
}
interface m extends match {
    params: {
        id: string
    }
}
class Analysis extends Component<props> {

    readonly state: any = {
        order: {},
        goodslist: [],
        order_statistics: {},
        goodtype: 1,
        showSelectPrint: false,
        printOP: 0,
        selectedSellerId: '',
        markFilter: 0,

        showExpressMark: false,
        markGoodlist: [],

        // 页面数据时间
        total_start: moment().startOf('day'),
        total_end: moment().endOf('day'),

        sellerPage: 1,
    }



    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(props: props, state) {
        if (this.props.match.params.id !== props.match.params.id) {
            this.reload()
            return
        }
        // const timeChange = [
        //     !state.total_start && this.state.tota_start, // 没有开始时间->有开始时间
        //     !state.total_end && this.state.tota_end, // 没有结束时间->有结束时间
        //     state.total_start && !this.state.total_start, // 有开始时间->没有开始时间
        //     state.total_end && !this.state.tota_end, // 有结束时间->没有结束时间
        //     this.state.total_start && state.total_start && state.total_start.isSame(this.state.total_start), // 开始时间变动
        //     this.state.total_end && state.total_end && state.total_end.isSame(this.state.total_end), // 开始时间变动
        // ]
        // console.log(state.total_start , this.state.tota_start);
        // if(timeChange.find(i => !!i)){
        //     console.log('object');
        //     // this.reload()
        // }
    }

    reload() {
        this.setState({
            order: {},
            goodslist: [],
            order_statistics: {},
            goodtype: 1,
            showSelectPrint: false,
            printOP: 0,
            selectedSellerId: '',
            markFilter: 0,
            sellerPage: 1,
        })
        this.getData()
    }

    render() {
        return (
            <div className='' style={{ margin: '0 auto', width: '100%', padding: '20px 50px', background: '#fff' }}>
                <h1 className='order__analysis__title' style={{ marginBottom: 20 }}>商城订单汇总</h1>

                <div style={{ marginBottom: 16, }} className='flex-between' >
                    <div>
                        <RangePicker style={{ marginRight: 12 }} defaultValue={[this.state.total_start, this.state.total_end,]} locale={TimePickerLocale} onChange={e => {
                            if (e && e[0] && e[1]) {
                                const [total_start, total_end] = e
                                total_start.startOf('day')
                                total_end.endOf('day')
                                this.setState({
                                    total_start, total_end,
                                })
                            } else {
                                this.setState({
                                    total_start: undefined, total_end: undefined,
                                })
                            }
                        }} />
                        <BTN solid color='red' onClick={this.reload.bind(this)}>查询</BTN>
                    </div>
                    <div>
                        <BTN color='red' onClick={() => {
                            ExportPickData({
                                type: '1',
                                branchlist: [],
                                isBranch: true,
                                branch_id: this.props.match.params.id.split(',')[0],
                            })
                        }}>导出数据</BTN>
                        <BTN color='red' style={{ marginLeft: 14 }} onClick={() => {
                            ExportPickData({
                                type: '2',
                                branchlist: [],
                                isBranch: true,
                                branch_id: this.props.match.params.id.split(',')[0],
                            })
                        }}>打印单据</BTN>
                    </div>
                    {/* <NavLink target='_blank' to={`/orders/print/${this.props.match.params.id}`} >
                        <BTN color='red' style={{ marginLeft: 12, position: 'relative', opacity: 1 }} className='cur-pointer'>打印消费者面单<Tip left={100} style={{ width: 200 }}>
                            <span>每个消费者的订单商品明细，适合</span><span>顾客核对明细</span>时使用
                        </Tip></BTN>
                    </NavLink>
                    <NavLink target='_blank' to={`/print/totalgood/${this.props.match.params.id}`} >
                        <BTN color='red' style={{ marginLeft: 12, position: 'relative', opacity: 1 }} className='cur-pointer'>打印商品总单<Tip left={100} style={{ width: 200 }}>
                            <span>包含每个商品售卖数量，适合</span><span>采购和统计</span>时使用
                        </Tip></BTN>
                    </NavLink> */}
                    {/* {<BTN color='red' style={{ marginLeft: 12, position: 'relative', opacity: 1 }} onClick={() => {
                        this.setState({ printType: 2, printSelector: true })
                    }} className='cur-pointer'>打印仓库拣货单<Tip left={100} style={{ width: 200 }}>
                            <span>包含每个自提门店的商品明细，适合</span><span>自提点拣货</span>时使用
                        </Tip></BTN>}
                    {<BTN color='red' style={{ marginLeft: 12, position: 'relative', opacity: 1 }} onClick={() => {
                        this.setState({ printType: 1, printSelector: true })
                    }} className='cur-pointer'>打印门店对货单&消费者订单<Tip left={125} style={{ width: 250 }}>
                            <span>包含每个门店的商品明细和消费者明细，供</span><span>自提门店对货和消费者核销</span>时使用
                        </Tip></BTN>} */}
                </div>

                <h4>我的订单总览</h4>
                <div className='order__analysis__total' style={{ alignItems: 'start' }} >
                    {this.state.order_count && <div className='order__analysis__total__item' style={{ flexWrap: 'wrap' }}>
                        <div className='order__analysis__total__item__name'>订单总数</div>
                        <div className='order__analysis__total__item__price' style={{ marginRight: 16 }}>
                            {this.state.order_count.order_count}
                        </div>
                        <NavLink to={`/mall/orders/order_op=my`}>
                            <BTN color='red' solid style={{ marginRight: 16 }}>我的订单详情</BTN>
                        </NavLink>
                    </div>}
                    {[
                        { key: 'no_shipped', name: '未核销/未发货', price: false },
                        { key: 'refunding_order_count', name: '退款中', price: false },
                        { key: 'refunded_order_count', name: '已退款', price: false },
                        { key: 'my_order_count', name: '我的订单', price: false },
                        { key: 'friend_order_count', name: '好友订单', price: false },
                    ].map((i, idx) => {
                        if (!this.state.order_count) return null
                        if (this.state.order_count[i.key] !== undefined) {
                            let price = <span>{Number(this.state.order_count[i.key])}</span>
                            if (i.price) price = <span>{(Number(this.state.order_count[i.key]) / 100).toFixed(2)}</span>
                            return <div key={idx} className='order__analysis__total__item'>
                                <div className='order__analysis__total__item__name'>{i.name}</div>
                                <div className='order__analysis__total__item__price'>
                                    {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                    {price}
                                </div>
                            </div>
                        } else return null
                    })}
                </div>

                {this.state.showPriceData
                    ? <>
                        <div className='flex-between'>
                            <h4>交易总览</h4>
                        </div>
                        <div className='order__analysis__total'>
                            {[1, 2,].includes(this.state.order_statistics.statistics_type) ? <div className='flex-center' style={{ width: '100%', marginBottom: 20 }} >
                                <div className='order__analysis__total__item__name'>本次总收入</div>
                                <div className='order__analysis__total__item__price' style={{ color: `var(--theme-main)` }}>
                                    <span style={{ fontSize: 12 }}>￥</span>
                                    {(this.state.order_statistics['total_income'] / 100).toFixed(2)}
                                </div>
                            </div> : null}
                            {[
                                { key: 'total_sales', name: '本次总销售', price: true },
                                { key: 'total_refund', name: '本次总退款', price: true },
                                { key: 'tuan_total_pay_price', name: '订单总额', price: true },
                                { key: 'my_sales', name: '我的销售', price: true },
                                { key: 'my_refund', name: '我的退款', price: true },
                                { key: 'my_income', name: '我的收入', price: true },
                                { key: 'supply_sales', name: '供货销售', price: true },
                                { key: 'supply_refund', name: '供货退款', price: true },
                                { key: 'supply_income', name: '供货收入', price: true },
                                { key: 'friend_sales', name: '好友销售', price: true },
                                { key: 'friend_refunds', name: '好友退款', price: true },
                            ].map((i, idx) => {
                                if (this.state.order_statistics[i.key] !== undefined) {
                                    let price = <span>{Number(this.state.order_statistics[i.key])}</span>
                                    if (i.price) price = <span>{(Number(this.state.order_statistics[i.key]) / 100).toFixed(2)}</span>
                                    return <div key={idx} className='order__analysis__total__item'>
                                        <div className='order__analysis__total__item__name'>{i.name}</div>
                                        <div className='order__analysis__total__item__price'>
                                            {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                            {price}
                                        </div>
                                    </div>
                                } else return null
                            })}
                        </div>

                        {[3, 4].includes(this.state.order_statistics.statistics_type) ? (<>
                            <h4>我的佣金总览</h4>
                            <div className='order__analysis__total'>
                                {[3, 4].includes(this.state.order_statistics.statistics_type) ? <div className='flex-center' style={{ width: '100%', marginBottom: 20 }} >
                                    <div className='order__analysis__total__item__name'>本次可提现收入</div>
                                    <div className='order__analysis__total__item__price' style={{ color: `var(--theme-main)` }}>
                                        <span style={{ fontSize: 12 }}>￥</span>
                                        {(this.state.order_statistics['total_income'] / 100).toFixed(2)}
                                    </div>
                                </div> : null}
                                {[
                                    { key: 'self_sales_rebates', name: '直接销售佣金', price: true },
                                    { key: 'friend_sales_rebates', name: '帮卖销售佣金', price: true },
                                    { key: 'total_rebates', name: '总佣金收入', price: true },
                                    { key: 'my_rebates', name: '我的佣金', price: true },
                                    { key: 'arrived_rebates', name: '已到账佣金', price: true },
                                    { key: 'unarrived_rebates', name: '待收佣金', price: true },
                                    { key: 'refund_rebates', name: '已撤回佣金', price: true },
                                    { key: 'need_refund_rebates', name: '需向上级退回佣金', price: true },
                                ].map((i, idx) => {
                                    if (this.state.order_statistics[i.key] !== undefined) {
                                        let price = <span>{Number(this.state.order_statistics[i.key])}</span>
                                        if (i.price) price = <span>{(Number(this.state.order_statistics[i.key]) / 100).toFixed(2)}</span>
                                        return <div key={idx} className='order__analysis__total__item'>
                                            <div className='order__analysis__total__item__name'>{i.name}</div>
                                            <div className='order__analysis__total__item__price'>
                                                {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                                {price}
                                            </div>
                                        </div>
                                    } else return null
                                })}
                            </div>
                        </>) : null}

                        {[2, 4].includes(this.state.order_statistics.statistics_type) ? (<>
                            <h4>好友佣金总览</h4>
                            <div className='order__analysis__total'>
                                {[
                                    { key: 'friend_total_rebates', name: '好友总佣金', price: true },
                                    { key: 'paid_rebates', name: '已发放佣金', price: true },
                                    { key: 'unpaid_rebates', name: '待发放佣金', price: true },
                                    { key: 'refund_rebates', name: '已撤回佣金', price: true },
                                    { key: 'need_take_back_rebates', name: '需向好友索回佣金', price: true },
                                ].map((i, idx) => {
                                    if (this.state.order_statistics[i.key] !== undefined) {
                                        let price = <span>{Number(this.state.order_statistics[i.key])}</span>
                                        if (i.price) price = <span>{(Number(this.state.order_statistics[i.key]) / 100).toFixed(2)}</span>
                                        return <div key={idx} className='order__analysis__total__item'>
                                            <div className='order__analysis__total__item__name'>{i.name}</div>
                                            <div className='order__analysis__total__item__price'>
                                                {i.price ? <span style={{ fontSize: 12 }}>￥</span> : null}
                                                {price}
                                            </div>
                                        </div>
                                    } else return null
                                })}
                            </div>
                        </>) : null}
                    </>
                    : null}

                {this.state.showPriceData
                    ? <div className='flex-center cur-pointer' style={{ color: `var(--theme-main)` }} onClick={() => { this.setState({ showPriceData: !this.state.showPriceData }) }} >
                        收起 <i className='icon-up iconfont' />
                    </div>
                    : <div className='flex-center cur-pointer' style={{ color: `var(--theme-main)` }} onClick={() => { this.setState({ showPriceData: !this.state.showPriceData }) }} >
                        查看更多交易数据 <i className='icon-down iconfont' />
                    </div>}
                <h4>商品售卖统计</h4>
                {/* 商品列表 */}
                <div className='flex-between'>
                    <div className='order__analysis__goodtypeChange'>
                        <div onClick={() => {
                            this.setState({ goodtype: 1 })
                        }} className={`order__analysis__goodtype ${this.state.goodtype === 1 ? 'order__analysis__goodtype_active' : ''}`}>商品维度</div>
                        {/* <div onClick={() => {
                            this.setState({ goodtype: 2 })
                        }} className={`order__analysis__goodtype ${this.state.goodtype === 2 ? 'order__analysis__goodtype_active' : ''}`}>门店维度</div> */}
                        <div onClick={() => {
                            this.setState({ goodtype: 3 })
                        }} className={`order__analysis__goodtype ${this.state.goodtype === 3 ? 'order__analysis__goodtype_active' : ''}`}>帮卖好友维度</div>
                    </div>
                </div>

                {this.state.goodtype === 1 ? (
                    <table className='order__analysis__list'>
                        <thead>
                            <tr>
                                <th>商品名称（规格）</th>
                                <th>{[2, 4, 6, 8].includes(this.state.order_statistics.statistics_type) ? '未核销' : '未发货'}</th>
                                <th>退款中</th>
                                <th>已售</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.goodslist.map(i => {
                                if (i.id === 'total') {
                                    return <tr key={i.goodsid}>
                                        <td>总计</td>
                                        <td>{i.notfinish}</td>
                                        <td>{i.refunding}</td>
                                        <td>{Number(i.notfinish) + Number(i.refunding)}</td>
                                    </tr>
                                }
                                return <tr key={i.goodsid}>
                                    <td>{i.name}</td>
                                    <td>{i.notfinish}</td>
                                    <td>{i.refunding}</td>
                                    <td>{Number(i.notfinish) + Number(i.refunding)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                ) : null}

                {this.state.goodtype === 3 ? <>
                    <Pagination style={{ marginBottom: 16 }} total={this.state.goodslist_seller.length} pageSize={10} current={this.state.sellerPage} showSizeChanger={false} onChange={e => {
                        this.setState({
                            sellerPage: e,
                        })
                    }} />
                    {this.state.goodslist_seller?.map((i, index) => {
                        const range = [(this.state.sellerPage - 1) * 10, this.state.sellerPage * 10 - 1]
                        if (index >= range[0] && index <= range[1]) {
                            return <table key={index} className='order__analysis__list'>
                                <thead>
                                    <tr>
                                        <th>{i.name}</th>
                                        <th style={{ width: '8em' }}>未核销/发货</th>
                                        <th style={{ width: '8em' }}>退款中</th>
                                        <th style={{ width: '8em' }}>已售</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {i.goods_sale_list.map(good => {
                                        return <tr key={good.goodsid}>
                                            <td>{good.name}</td>
                                            <td>{good.notfinish}</td>
                                            <td>{good.refunding}</td>
                                            <td>{Number(good.num)}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td>总计</td>
                                        <td>{i.total_not_finish}</td>
                                        <td>{i.total_refunding}</td>
                                        <td>{Number(i.total_num)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        return null
                    })}
                    <Pagination style={{ marginTop: 16 }} total={this.state.goodslist_seller.length} pageSize={10} current={this.state.sellerPage} showSizeChanger={false} onChange={e => {
                        this.setState({
                            sellerPage: e,
                        })
                    }} />
                </> : null}

                {/* 打印筛选 */}
                {this.state.printSelector ? <Modal title={`打印${this.state.printType === 2 ? '拣货单' : '对货单&消费者订单'}`} onConfirm={() => {
                    let url = `/picklist/${this.props.match.params.id}?op=${this.state.printOP}&type=${this.state.printType || ''}&ss=${this.state.selectedSellerId}&mall=1`
                    if (this.state.startTime) url += `&start=${this.state.startTime}`
                    if (this.state.endTime) url += `&end=${this.state.endTime}`
                    window.open(url, '_blank')
                }} onClose={() => { this.setState({ printSelector: false }) }}>
                    <div>
                        <div style={{ padding: 20, flex: 1 }} className='fcc'>
                            <div style={{ minWidth: '7em' }}>选择订单状态</div>
                            <div style={{ flex: 1, minWidth: '7em' }}>
                                <Select uni='dingdan' list={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' }, { value: 1, title: '已完成订单' },]} showKey='title'
                                    onChange={e => {
                                        this.setState({
                                            printOP: e.value
                                        })
                                    }} value={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' }, { value: 1, title: '已完成订单' },].findIndex(i => this.state.printOP === i.value)} />
                            </div>
                        </div>
                        <div className='fbc flexwrap'>
                            <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc '>
                                <div style={{ minWidth: '7em' }}>开始时间</div>
                                <div style={{ flex: 1, minWidth: '7em' }}>
                                    <DatePicker style={{ width: '100%', borderRadius: 4 }} locale={zhCN.DatePicker} showTime onChange={e => {
                                        const startTime = e?.unix()
                                        if (this.state.endTime && startTime && this.state.endTime < startTime) this.setState({ endTime: null })
                                        this.setState({ startTime: e?.unix() })
                                    }} />
                                </div>
                            </div>
                            <div style={{ padding: 20, paddingTop: 0, flex: 1 }} className='fbc'>
                                <div style={{ minWidth: '7em' }}>结束时间</div>
                                <div style={{ flex: 1, minWidth: '7em' }}>
                                    <DatePicker style={{ width: '100%', borderRadius: 4 }} disabledDate={(date) => {
                                        return date <= moment.unix(this.state.startTime || 0).endOf('day')
                                    }} value={this.state.endTime ? moment.unix(this.state.endTime) : null} locale={zhCN.DatePicker} showTime onChange={e => {
                                        this.setState({ endTime: e?.unix() })
                                    }} />
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ padding: 20, flex: 1 }} className='fcc'>
                            <div style={{ minWidth: '7em' }}>选择帮卖好友</div>
                            <div style={{ flex: 1, minWidth: '7em' }}>
                                <Select canSearch uni='sellerlist' placeholder={`请选择帮卖好友`} maxHeight={300} list={this.state.sellerlist || []} showKey='name'
                                    onChange={e => {
                                        this.setState({
                                            selectedSellerId: e.id
                                        })
                                    }} value={(this.state.sellerlist || []).findIndex(i => {
                                        if (i.id === this.state.selectedSellerId) return true
                                        return false
                                    })} />
                            </div>
                        </div> */}
                    </div>
                </Modal> : null}
            </div>
        )
    }

    getData() {
        this.getList()
        const data = {
            mall_id: this.props.match.params.id.split(',')[0],
            relation_id: this.props.match.params.id.split(',')[1],
            start_time: this.state.total_start ? this.state.total_start.format('YYYY-MM-DD HH:mm:ss') : '',
            end_time: this.state.total_end ? this.state.total_end.format('YYYY-MM-DD HH:mm:ss') : '',
        }
        get('/mall/transactionanalysis', data).then(res => {
            this.setState({
                order_statistics: res.order_statistics,
                order_count: res.order_count_statistics,
            })
        }).catch(e => { if (e.message) message.error(e.message) })

    }

    getList() {
        Loading.show()
        const result = [false, false]
        const data = {
            mall_id: this.props.match.params.id.split(',')[0],
            relation_id: this.props.match.params.id.split(',')[1],
            start_time: this.state.total_start ? this.state.total_start.unix() : '',
            end_time: this.state.total_end ? this.state.total_end.unix() : '',
        }
        get('/mall/getgoodsdimensionorder', data).then((res) => {
            result[0] = true
            if (!result.includes(false)) {
                Loading.hide()
            }
            this.setState({
                goodslist: res
            })
        }).catch(e => { if (e.message) message.error(e.message) })
        get('/mall/getsellerdimensionorder', {
            ...data,
            branch_dimension: 0,
        }).then(res => {
            this.setState({
                goodslist_seller: res.list,
            })
            result[1] = true
            if (!result.includes(false)) {
                Loading.hide()
            }
        }).catch(e => { if (e.message) message.error(e.message) })
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.login_user_data,
        sidebarOpen: state.sidebarOpen,
    }
}

export default connect(mapStateToProps)((Analysis))