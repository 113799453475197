import React, { Component } from 'react'
import { connect } from 'react-redux'

// import Loading from '../total/Loading' 
import Main from '../../high-component/Content'
import NinePic from '../total/NinePic'
import Loading from '../total/Loading'
// import Audio from '../total/Audio'
import api from '../../netApi/index';

import { OFFSET_LIMIT } from '../../data/options';

class Myorderlist extends Component {

    constructor() {
        super()
        this.state = {
            list: []
        }
        this.state.op = 'all'
        this.offset = 0
        window.document.title = '我的订单'
    }

    componentDidUpdate(p) {
        if (!p.is_Login && this.props.is_Login) {
            this.getList()
        }
    }

    scrollHandle = this.scroll.bind(this)
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandle)
    }
    componentDidMount() {
        if (this.props.is_Login) {
            this.getList()
        } else {
            // this.wx_login()
        }
        window.addEventListener('scroll', this.scrollHandle)
    }

    render() {
        return (
            <div>
                {/* 背景 */}
                <div style={{ background: '#f7f8f9', position: "fixed", left: 0, top: 0, width: '100%', height: '100%', zIndex: -2 }}></div>

                {/* 筛选 */}
                <div className='fcc' style={{ background: '#fff' }}>
                    <div className='fcc' onClick={() => {
                        this.setState({ op: 'all', showLoading: true }, () => {
                            this.refresh()
                        })
                    }} style={{ flex: 1, fontSize: 12, color: this.state.op === 'all' ? 'var(--theme-main)' : '#353535', padding: '.7em' }}>全部</div>
                    <div className='fcc' onClick={() => {
                        this.setState({ op: 'notuse', showLoading: true }, () => {
                            this.refresh()
                        })
                    }} style={{ flex: 1, fontSize: 12, color: this.state.op === 'notuse' ? 'var(--theme-main)' : '#353535', padding: '.7em' }}>未完成</div>
                    <div className='fcc' onClick={() => {
                        this.setState({ op: 'used', showLoading: true }, () => {
                            this.refresh()
                        })
                    }} style={{ flex: 1, fontSize: 12, color: this.state.op === 'used' ? 'var(--theme-main)' : '#353535', padding: '.7em' }}>已完成</div>
                </div>

                {/* 列表 */}
                <div style={{ padding: 12 }}>
                    {this.state.list.map(i => {
                        return (
                            <div key={i.orderid} style={{ marginBottom: 12, background: '#fff', padding: 12, borderRadius: 6, position: 'relative' }}>

                                {Number(i.notpay) ? (<div style={{ padding: '4px 11px', position: 'absolute', top: 12, right: 12, fontSize: 12, color: 'var(--theme-main)', background: '#FFF6F6', borderRadius: 12 }}>其他方式支付</div>) : ''}

                                <div className='fcc' style={{ justifyContent: 'flex-start', marginBottom: 20 }}>
                                    <img alt={i.shopname} src={i.shoplogo} style={{ borderRadius: '50%', width: 30, height: 30, marginRight: 15 }} className='img-center' />
                                    <span style={{ fontSize: 12, marginRight: 15 }}>{i.shopname}</span>
                                    <span style={{ fontSize: 12, minWidth: '10em', }}>{i.createtime}</span>
                                </div>

                                {/* 快递信息 */}
                                {Number(i.addressid) === 0 ? (
                                    <div style={{ background: '#fafafa', borderRadius: 6, padding: 12, marginBottom: 20 }}>
                                        <div className='fcc' style={{ marginBottom: 6 }}>
                                            <div style={{ minWidth: '5em', color: '#8d8d8d', fontSize: 12 }}>姓名</div>
                                            <div style={{ flex: 1, fontSize: 12, color: '#353535' }}>{i.username}</div>
                                        </div>
                                        <div className='fcc' style={{ marginBottom: 6 }}>
                                            <div style={{ minWidth: '5em', color: '#8d8d8d', fontSize: 12 }}>手机号</div>
                                            <div style={{ flex: 1, fontSize: 12, color: '#353535' }}>{i.phone}</div>
                                        </div>
                                        <div className='fcc' style={{ marginBottom: 6 }}>
                                            <div style={{ minWidth: '5em', color: '#8d8d8d', fontSize: 12 }}>地址</div>
                                            <div style={{ flex: 1, fontSize: 12, color: '#353535' }}>{i.address}</div>
                                        </div>
                                    </div>
                                ) : ''}

                                {i.sign.length > 0 ? (<div style={{ background: '#fafafa', borderRadius: 6, padding: 12, marginBottom: 20 }}>
                                    {i.sign.map(s => {
                                        return (
                                            <div key={s.id} style={{
                                                alignItems: 'flex-start',
                                                marginBottom: 6
                                            }} className='fcc' >
                                                <div style={{ minWidth: '5em', marginRight: '1em', fontSize: 12, width: '7em' }}>{s.name}</div>
                                                <div style={{ flex: 1, fontSize: 12 }}>
                                                    {Number(s.type) === 1 ? (<div>
                                                        <div style={{ fontSize: 12 }}>{s.value[0].content}</div>
                                                    </div>) : ''}
                                                    {Number(s.type) === 2 ? (<div>
                                                        {s.value.map((c, index) => {
                                                            return (
                                                                <div key={index} style={{ fontSize: 12 }}>{c.content}</div>
                                                            )
                                                        })}
                                                    </div>) : ''}
                                                    {Number(s.type) === 3 ? (
                                                        <audio src={s.value[0].src} controls style={{ height: '1.4em', maxWidth: '12em' }} />
                                                    ) : ''}
                                                    {Number(s.type) === 4 ? (
                                                        <NinePic list={s.value} />
                                                    ) : ''}
                                                    {Number(s.type) === 5 ? (
                                                        <div>
                                                            <div style={{ fontSize: 12 }}>{s.value.name}</div>
                                                        </div>
                                                    ) : ''}
                                                    {Number(s.type) === 6 ? (
                                                        <div>
                                                            <div style={{ fontSize: 12 }}>{s.value[0].content}</div>
                                                        </div>
                                                    ) : ''}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>) : ''}

                                {/* 商品信息 */}
                                <div style={{ background: '#fafafa', borderRadius: 6, padding: 12, marginBottom: 20 }}>
                                    {i.items.map(good => {
                                        return (
                                            <div key={good.goodsid} className='fcc' style={{ borderBottom: '1px solid #e8e8e8', padding: '6px 12px' }}>
                                                <img alt={good.name} src={good.logo} className='img-center' style={{ width: 28, height: 28, marginRight: 6 }} />
                                                <div style={{ flex: 1, fontSize: 12, color: '#353535' }} className='single-text-hidden'>{good.name}({good.unit})</div>
                                                <div style={{ color: '', fontSize: 12, minWidth: '5em' }}>￥{(good.price / 100).toFixed(2)}</div>
                                                <div style={{ color: '', fontSize: 12, minWidth: '2em', textAlign: 'right', marginLeft: '.5em' }}>x{good.num}</div>
                                            </div>
                                        )
                                    })}
                                    <div style={{ fontSize: 12, color: '#353535', textAlign: 'right', paddingTop: 8 }}>共计{(() => {
                                        let num = 0
                                        for (let index = 0; index < i.items.length; index++) {
                                            const item = i.items[index];
                                            num += Number(item.num)
                                        }
                                        return num
                                    })()}件，支付金额：<span style={{ fontSize: 12, color: 'red' }}>￥{(i.payprice / 100).toFixed(2)}</span></div>
                                </div>

                                {/* 自提点 */}
                                {Number(i.addressid) !== 0 ? (
                                    <div style={{ background: '#fafafa', borderRadius: 6, padding: 12, marginBottom: 20 }}>
                                        <div style={{ color: '#333', fontSize: 12 }}>已选自提点：{i.pick_up_location.address}</div>
                                    </div>
                                ) : ''}

                                <div className='fbc'>
                                    <span style={{ color: i.statusColor, fontSize: 16, }}>{i.statusText}</span>
                                    <button style={{ borderRadius: 4, border: '1px solid #979797', fontSize: 12, background: 'white' }} onClick={() => {
                                        this.props.history.push({
                                            pathname: '/orderdetail',
                                            state: {
                                                data: i
                                            }
                                        })
                                    }}>订单详情</button>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {this.state.showLoading ? <Loading /> : ''}
            </div>
        )
    }

    getList() {
        // console.log(this)
        this.setState({ isLoading: true })
        if (!this.props.is_Login) return
        api('user', 'getorderlist')({
            op: this.state.op,
            offset: this.offset,
            limit: OFFSET_LIMIT,
            types: 1
        }).then(res => {
            this.setState({ isLoading: false })
            if (res.data.code !== 0) return
            this.offset += res.data.data.list.length
            this.setState({
                list: this.state.list.concat(this.maplist(res.data.data.list)),
                count: res.data.data.count,
                showLoading: false
            })
        })
    }

    refresh() {
        this.setState({ isLoading: true })
        api('user', 'getorderlist')({
            op: this.state.op,
            offset: 0,
            limit: this.offset,
            types: 1
        }).then(res => {
            this.setState({ isLoading: false })
            this.setState({
                list: this.maplist(res.data.data.list),
                count: res.data.data.count,
                showLoading: false
            })
        })
    }

    scroll(e) {
        if (this.props.waitingLogin) return
        if (this.state.count <= this.offset)
            return
        const offsetY = window.pageYOffset
        const scrollHeight = e.srcElement.body.scrollHeight
        const bottom = scrollHeight - offsetY
        if (bottom < 2000 && !this.state.isLoading) {
            this.getList()
        }
    }

    wx_login() {
        api('huser', 'scope')({
            param: this.props.location.pathname
        }).then((res) => {
            this.url = res.data.data.scope_url
            window.location.href = this.url
        })
    }

    maplist(list) {
        return list.map(item => {
            const orderStatus = item.status
            let status = ''
            let color = '#8b8b8b'
            switch (Number(orderStatus)) {
                case 0:
                    status = '未支付'
                    break;
                case 1:
                    status = '未发货'
                    color = 'var(--theme-main)'
                    if (Number(item.addressid) !== 0) {
                        status = '未核销'
                    }
                    if (item.refundContent.length > 0) {
                        status = item.refundContent
                    }
                    break;
                case 2:
                    status = '核销成功'
                    if (Number(item.addressid) === 0) {
                        status = '发货成功'
                    }
                    break;
                case 4:
                    status = '退款完成'
                    break
                case 5:
                    status = '未发货'
                    color = 'var(--theme-main)'
                    if (Number(item.addressid) !== 0) {
                        status = '未核销'
                    }
                    if ((Number(item.refund_status) === -1 || Number(item.refund_status) === 0) && item.refund_status !== null) {
                        status = '申请退款中'
                    }
                    break;
                case 6:
                    status = '业务开单'
                    break
                default:
                    break;
            }
            item.statusText = status
            item.statusColor = color
            return {
                ...item,
            }
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        is_Login: state.is_Login,
        waitingLogin: state.waitingLogin,
        login_user_data: state.login_user_data,
    }
}

export default Main(connect(mapStateToProps)(Myorderlist))