import { Input } from "antd"
import React, { useEffect, useState } from "react"
import SwitchItem from "./SwitchItem"
import { Imgs } from "../../total/DraggableImgs"

interface image {
  id: number
  src: string
}

export default (props: {
  onChange: (data: { hour: number; images: image[] }) => void
  initHour?: string
  initImages?: image[]
}) => {
  const [hour, setHour] = useState<string>(props.initHour || "")

  const [images, setImages] = useState<image[]>(props.initImages || [])

  useEffect(() => {
    props.onChange({
      hour: Number(hour),
      images,
    })
  }, [hour, images, props])

  return (
    <>
      <div className="edittuan__switchitem fbc cur-pointer">
        <div className="edittuan__switchitem__text fbc">拼团人数：2人成团</div>
      </div>

      <SwitchItem text={"必须新客参团"} onChange={() => {}} checked />

      <div className="edittuan__switchitem fbc cur-pointer">
        <div className="edittuan__switchitem__text flex-between">
          拼团有效时间
        </div>
        <div>
          <Input
            addonAfter="小时"
            type="number"
            value={hour}
            onChange={e => {
              setHour(Number(e.target.value).toFixed(0))
            }}
          />
        </div>
      </div>

      <div className="edittuan__switchitem">
        <div
          className="edittuan__switchitem__text"
          style={{ marginBottom: 12 }}
        >
          拼团规则图
        </div>
        <div>
          <Imgs
            imgtype="tuan_rule"
            onChange={setImages}
            maxLength={9}
            images={images}
            width={70}
            height={70}
          />
        </div>
      </div>
    </>
  )
}
