import React, { Component } from 'react'

import { connect } from 'react-redux'
import { select_good } from '../../../reducers/index';

import styled from 'styled-components';
import { appname } from '../../../data/options';

import api from '../../../netApi/index';

import Loading from '../../total/Loading'
import NinePic from '../../total/NinePic'
import Good from './Good';

import parse from '../../../methods/paramParse';
import { get, post } from '../../../netApi/fetch';

import { SIDEBAR_WIDTH, INDEX_CONTENT_LIST_WIDTH } from '../../../data/style';
import { withRouter } from 'react-router';
import { message } from 'antd';
import { confirm } from '../../Method/Confirm';
import { HR2SR } from '../../../StyleComponents/Button';
const CodeList = styled.div`
    align-self: flex-start;
`
const CodeItem = styled.div`
    width: 140px;
    border-radius: 8px;
    background: #fff;
    padding: 14px 20px;
`

const TOTAL_MARGIN_RIGHT = SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH

const ControlPanel = styled.div`
    position: fixed;
    /* left: calc((100vw - ${TOTAL_MARGIN_RIGHT}px)/2 + ${TOTAL_MARGIN_RIGHT}px + 320px ); */
    /* left: calc((${TOTAL_MARGIN_RIGHT}px)  + 660px + 20px + 16px ); */
    right: 0;
    top: 0;
    width: 204px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    padding-top: 140px;
    /* @media (max-width: 1500px) {
        left: unset;
        right: 0;
    } */
    @media (min-width: ${660 + 500 + TOTAL_MARGIN_RIGHT}px){
        left: calc((100vw - ${TOTAL_MARGIN_RIGHT}px)/2 + ${TOTAL_MARGIN_RIGHT}px + 330px + 20px );
        /* left: calc((${TOTAL_MARGIN_RIGHT}px)  + 660px + 20px + 16px + 40px ); */
    }
`

const CtrlBtn = styled.button`
    width: 94px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(219,219,219,0.50); 
    padding: 0;
    border: 0;
    margin-bottom: 16px;
    margin-right: 8px;
    i{
        margin-right: 6px;
    }
    &:hover{
        background: var(--theme-main);
        color: #ffffff;
    }
`

const TuanPanel = styled.div`
    /* margin: auto; */
    margin-left: 40px;
    padding: 40px 0 20px;
    width: 660px;

    @media (max-width: 1500px) {
        margin-right: 250px;
    }
    @media (max-width: ${660 + 500 + TOTAL_MARGIN_RIGHT}px){
        width: calc(100vw - 250px - 40px - ${TOTAL_MARGIN_RIGHT}px - 20px);
        /* 100vw - 右侧操作栏宽度 - 左边距 - 左侧栏宽度 - 滚动条预留 */
        /* margin-right: auto; */
    }

    @media (min-width: ${660 + 500 + TOTAL_MARGIN_RIGHT}px){
        margin: auto;
    }

`

class Tuan extends Component {
    constructor(p) {
        super()
        this.state = {
            activeCategory: -1
        }
        this.getData(p)
    }

    componentDidMount() {
        document.body.style.background = '#f7f7f7'
    }

    componentWillUnmount() {
        document.body.style.background = ''
        document.title = '接力GO'

    }

    componentDidUpdate(p) {
        // 
        // if (this.props.selectedGood !== null) {
        //     for (let i = 0; i < this.state.goodslist.length; i++) {
        //         const good = this.state.goodslist[i];
        //         if (good.data.goodsid === this.props.selectedGood.goodsid) {
        //             // eslint-disable-next-line react/no-direct-mutation-state
        //             this.state.goodslist[i].num = this.props.selectedGood.num
        //             break
        //         }
        //     }
        //     this.props.clearSelectGood()
        //     this.setState({
        //         goodslist: this.state.goodslist
        //     })
        // }
        if (p.match.params.tuanid !== this.props.match.params.tuanid) {
            this.getData()
            this.setState({ code_src: false, data: null })
        }
    }

    render() {
        return (this.state.data && this.state.categorylist) ? (
            <div style={{ margin: 'auto' }}>
                {this.props.match.path !== '/index/t/:tuanid/detail' && <>
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', zIndex: -1, backgroundImage: 'linear-gradient(-180deg, rgba(102,102,102,0.30) 0%, #f7f8f9 98%)', height: '72vw', }}>
                        <img className='img-center' alt='' src={this.state.data.shopinfo.banner || 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/shop/qqaGBQ2G8I.jpg'} style={{ width: '100%', height: '70vw', mixBlendMode: 'hue', filter: 'blur(3px)', }} />
                    </div>
                    <div style={{ background: '#F7F8F9', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: -2, }}></div>
                </>}
                <TuanPanel style={{ minHeight: '100vh', }}>
                    <div className='tuan__top' style={{ color: '#333', textShadow: 'none' }}>
                        {this.state.data.title}
                    </div>

                    {/* 团购信息 */}
                    <div className='tuan__panel tuan__content' style={{ paddingTop: 0 }}>
                        <div className='tuan__content__shopinfo'>
                            <div className='tuan__content__shopinfo__ava'>
                                <img className='img-center tuan__content__shopinfo__ava__img' alt='' src={this.state.data.shopinfo.logo} />
                            </div>
                            <div className='tuan__content__shopinfo__name'>
                                <span>{this.state.data.shopinfo.name}</span>
                            </div>
                            <div className='tuan__content__buy-ava-group'>

                            </div>
                        </div>
                        <div className='tuan__content__meta'>
                            <div className='tuan__content__meta__item fcc single-text-hidden'>
                                <span className='tuan__content__meta__item__text'>每人参与次数:{Number(this.state.data.sign_num) === 0 ? '不限' : this.state.data.sign_num}</span>
                            </div>
                            <div className='tuan__content__meta__item fcc single-text-hidden'>
                                <span className='tuan__content__meta__item__text'>发货方式:{Number(this.state.data.takeway) === 1 ? '快递' : ''}{Number(this.state.data.takeway) === 2 ? '自提' : ''}</span>
                            </div>
                        </div>
                        <div className='tuan__content__content'>
                            {this.state.data.tuan_detail.map((item, index) => {
                                switch (Number(item.type)) {
                                    case 1:
                                        return (<div key={index}>
                                            <div style={{ whiteSpace: 'pre-line', fontSize: 16, marginBottom: 16 }}>{item.value}</div>
                                        </div>)
                                    case 2:
                                        return (
                                            <div key={index}>
                                                <img alt='' src={item.value.src} style={{ width: '100%' }} />
                                            </div>
                                        )
                                    case 3:
                                        return <NinePic key={index} list={item.value} />
                                    default:
                                        return ''
                                }
                            })}
                        </div>
                        <div className='tuan__content__goods'>
                            <div className='tuan__content__goods__category'>
                                {/* <div className='tuan__content__goods__category__warp' style={{ width: (7 * (this.state.categorylist.length + 1)) + 'em', transition: 'all 0s' }}> */}
                                {[{ id: -1, name: '全部好物' }, ...this.state.categorylist].map(i => {
                                    return (<div style={{ transition: 'all 0s' }} className={`fcc tuan__content__goods__category__item ${this.state.activeCategory === i.id ? 'tuan__content__goods__category__item_active' : ''}`}
                                        key={i.id} onClick={() => {
                                            this.setState({
                                                activeCategory: i.id
                                            })
                                        }}>
                                        {i.name}
                                    </div>)
                                })}
                                {/* </div> */}
                            </div>
                            <div >
                                {this.state.goodslist.map((i, index) => {
                                    const cid = Number(this.state.activeCategory)
                                    const gcid = i.data.categorylist.map(j => Number(j.cate_id))
                                    if (!gcid.find(j => j === cid) && Number(this.state.activeCategory) !== -1) return null
                                    return <Good key={index} num={i.num} good={i.data} isHema={this.state.data.is_hema} is_Login={this.props.is_Login} change={(num) => {
                                        if (i.num.length === 1) {
                                            i.num[0] = num
                                        }
                                        this.setState({
                                            goodslist: this.state.goodslist
                                        })
                                    }} save={() => {
                                        this.props.history.replace(this.props.location.pathname, this.state)
                                    }} />
                                })}
                            </div>
                        </div>

                        <div className='fcc' style={{ width: '100%', }}>
                            <div className='fcc' style={{
                                width: '100%',
                                height: 60,
                                background: '#fff',
                                borderRadius: 30,
                                // boxShadow: '0px 2px 4px 2px rgba(221,221,221,0.5)',
                                // padding: '5px 5px 5px 16px',
                            }}>
                                <div className='fcc' style={{ flexDirection: 'column' }} onClick={() => {
                                    // this.props.history.push('/Myorder')
                                }}>
                                    <i className='iconfont icon-wodedingdanicon' style={{ color: '#FD6467', fontSize: 24, lineHeight: 1, marginBottom: 4 }}></i>
                                    <span style={{ color: '#666', fontSize: 12 }}>我的订单</span>
                                </div>
                                <div className='fcc' style={{
                                    flex: 1,
                                    marginLeft: 15,
                                    background: 'linear-gradient(90deg,rgba(248,94,50,1) 0%,rgba(249,47,71,1) 100%)',
                                    color: '#fff',
                                    fontSize: 16,
                                    height: 50,
                                    borderRadius: 5,
                                    position: 'relative'
                                }}>立即下单
                                    {(() => {
                                        let count = 0
                                        for (let i = 0; i < this.state.goodslist.length; i++) {
                                            const good = this.state.goodslist[i];
                                            const num = good.num
                                            for (let j = 0; j < num.length; j++) {
                                                const item = num[j];
                                                count += item.num
                                            }
                                        }
                                        if (count <= 0) return
                                        return (<span style={{ fontSize: 12, lineHeight: 1, borderRadius: '0.6em', background: '#fff', height: '1.2em', minWidth: '1.2em', padding: 4, color: '#FF9A27', marginLeft: 8 }} className='fcc'>
                                            {count}
                                        </span>)
                                    })()}
                                </div>
                            </div>
                        </div>
                        {/* 订单展示 */}
                        <div className='tuan__panel tuan__order'>
                            <div className='tuan__order__title'>
                                {this.state.data.visitors}人浏览，{this.state.orders?.count}次参与
                            </div>
                            <div>
                                {this.state.orders?.list.map(i => {
                                    return <Order sign={this.state.data.sign.user} data={i} key={i.orderid} />
                                })}
                            </div>
                        </div>
                    </div>
                </TuanPanel>
                <ControlPanel>
                    <div style={{ flex: 1 }}>
                        {!(Number(this.state.data.is_hema_staff) === 1 && Number(this.state.data.pid) > 0) && <CtrlBtn onClick={() => {
                            this.props.history.push('/event/edit/' + btoa(this.props.match.params.tuanid))
                        }}>
                            <i className='iconfont icon-bianji' />
                            <span>编辑</span>
                        </CtrlBtn>}
                        {Number(this.state.data.pid) <= 0 && <CtrlBtn onClick={() => {
                            this.props.history.push('/event/copy/' + btoa(this.props.match.params.tuanid))
                        }} >
                            <i className='iconfont icon-fuzhi1' />
                            <span>复制</span>
                        </CtrlBtn>}
                        <CtrlBtn onClick={() => {
                            post('/tuan/tuanbutton', {
                                types: 1,
                                id: this.state.data.id,
                                status: Number(this.state.data.status) === 1 ? 1 : 2
                            }).then(res => {
                                message.success(`活动《${this.state.data.title}》已${Number(this.state.data.status) === 1 ? '暂停' : '开始'}`)
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        status: Number(this.state.data.status) === 1 ? 0 : 1
                                    }
                                })
                                this.props.history.push(this.props.location.pathname + '?refresh=' + this.state.data.id)
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }} >
                            <i className={`iconfont ${Number(this.state.data.status) === 1 ? 'icon-zanting' : 'icon-kaiqihuodong'}`} />
                            <span>{Number(this.state.data.status) === 1 ? '暂停' : '开始'}活动</span>
                        </CtrlBtn>
                        <CtrlBtn onClick={e => {
                            e.preventDefault()
                            post('/tuan/updatetuanbystatus', {
                                tuanid: this.state.data.id,
                                type: "hidden",
                                types: 1,
                            }).then(res => {
                                message.success(`${Number(this.state.data.isprivate) === 1 ? '取消隐藏' : '隐藏'}成功`)
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        isprivate: Number(this.state.data.isprivate) === 1 ? 0 : 1
                                    }
                                })
                                this.props.history.push(this.props.location.pathname + '?refresh=' + this.state.data.id)
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }}>
                            <i style={{ fontSize: 14 }} className={`iconfont ${Number(this.state.data.isprivate) === 1 ? 'icon-quxiaoyincang' : 'icon-yincang1'} `} />
                            <span>{`${Number(this.state.data.isprivate) === 1 ? '取消隐藏' : '隐藏活动'}`}</span>
                        </CtrlBtn>
                        <CtrlBtn onClick={e => {
                            post('/tuan/updatetuanbystatus', {
                                tuanid: this.state.data.id,
                                type: 'istop',
                                types: 1,
                            }).then(res => {
                                message.success(`${Number(this.state.data.istop) === 1 ? '取消置顶' : '置顶'}成功`)
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        istop: Number(this.state.data.istop) === 1 ? 0 : 1
                                    }
                                })
                                this.props.history.push(this.props.location.pathname + '?refresh=' + this.state.data.id)
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }}>
                            <i className='iconfont icon-zhidingxianbeifen' />
                            <span>{Number(this.state.data.istop) === 1 ? '取消置顶' : '置顶活动'}</span>
                        </CtrlBtn>
                        <CtrlBtn onClick={() => {
                            confirm({
                                text: `确定删除团购《${this.state.data.title}》吗？删除后不可恢复`
                            }).then(res => {
                                if (res) {
                                    post('/tuan/delete', {
                                        types: 1,
                                        id: this.state.data.id
                                    }).then(res => {
                                        message.warning(`活动《${this.state.data.title}》已删除`)
                                        this.props.history.push(`/refresh?to=${encodeURIComponent('/index')}`)
                                    }).catch(e => { if (e.message) message.error(e.message) })
                                }
                            })
                        }}>
                            <i className='iconfont icon-shanchu' />
                            <span>删除活动</span>
                        </CtrlBtn>
                        <CtrlBtn onClick={() => {
                            confirm({
                                width: 'unset',
                                title: '分享链接或路径',
                                text: <div>
                                    <div className='flex-between' style={{ marginBottom: 20 }} >
                                        <div style={{ width: '7em' }}>H5分享链接</div>
                                        <div style={{
                                            background: '#f2f2f2',
                                            borderRadius: 7,
                                            width: 417,
                                            padding: '6px 11px',
                                        }} className='flex-between' >
                                            <input style={{ border: 0, color: '#333', flex: 1 }} disabled value={`https://www.jieligo.net/tuan/${btoa(this.props.match.params.tuanid)}`} />
                                            <HR2SR onClick={() => {
                                                copy_link(`https://www.jieligo.net/tuan/${btoa(this.props.match.params.tuanid)}`)
                                            }}>复制</HR2SR>
                                        </div>
                                    </div>
                                    <div className='flex-between' style={{ marginBottom: 200 }} >
                                        <div style={{ width: '7em' }}>小程序路径</div>
                                        <div style={{
                                            background: '#f2f2f2',
                                            borderRadius: 7,
                                            width: 417,
                                            padding: '6px 11px',
                                        }} className='flex-between' >
                                            <input style={{ border: 0, color: '#333', flex: 1 }} disabled value={`/pages/good/good?id=${(this.props.match.params.tuanid)}`} />
                                            <HR2SR onClick={() => {
                                                copy_link(`/pages/good/good?id=${(this.props.match.params.tuanid)}`)
                                            }}>复制</HR2SR>
                                        </div>
                                    </div>
                                </div>
                            }).catch()
                        }} style={{ width: 140 }}>
                            <i className='iconfont icon-fenxiang' />
                            <span>分享链接或路径</span>
                        </CtrlBtn>
                        <CtrlBtn style={{ width: 140 }} onClick={() => {
                            window.open(`https://www.jieligo.net/tuan/${btoa(this.props.match.params.tuanid)}`, '_blank')
                        }}>
                            <i className='iconfont icon-xinyemiandakai' />
                            <span>新页面打开</span>
                        </CtrlBtn>
                    </div>
                    <CodeList >
                        {/* 扫码进入小程序 */}
                        {(() => {
                            if (!this.state.code_src) {
                                this.getCode()
                            } else {
                                return <CodeItem>
                                    <div style={{ width: 100, height: 100, marginBottom: 8, }}>
                                        <img alt='小程序码' src={this.state.code_src} className='img-center' />
                                    </div>
                                    <div style={{ textAlign: 'center', color: '#999', fontSize: 16 }}>扫一扫</div>
                                    <div style={{ textAlign: 'center', color: '#999', fontSize: 16 }}>用小程序查看</div>
                                </CodeItem>
                            }
                        })()}
                    </CodeList>
                </ControlPanel>
            </div>) : (<Loading />)
    }

    getData(props) {
        const p = this.props || props
        const viewdata = {
            types: 1,
            id: btoa(p.match.params.tuanid),
            from: 'view',
        }
        if (p.match.params.sid) {
            viewdata.share_from_sid = p.match.params.sid
        }

        const param = parse(p.location.search)
        if (param.from) viewdata.from = param.from

        get('/tuan/view', viewdata).then(res => {
            window.document.title = res.title
            if (this.props.onGetData) this.props.onGetData(res)
            this.setState({
                data: res, goodslist: res.goodslist.map(i => {
                    return {
                        data: i, num: i.attribute.map(a => {
                            return {
                                id: a.id, num: 0
                            }
                        })
                    }
                })
            })
            const showCateList = []
            for (let i = 0; i < res.categorylist.length; i++) {
                const c = res.categorylist[i];
                for (let j = 0; j < res.goodslist.length; j++) {
                    const good = res.goodslist[j];
                    const cid = Number(c.id)
                    const gcid = good.categorylist.map(c => Number(c.cate_id))
                    if (gcid.find(id => id === cid)) {
                        showCateList.push(c)
                        break
                    }
                }
            }
            this.setState({ categorylist: showCateList })
        }).catch(e => {
            if (e.message) message.error(e.message)
            this.props.history.push('/index')
        })

        const ordersData = {
            types: 1,
            tuanid: btoa(p.match.params.tuanid),
            offset: 0,
            limit: 20,
        }
        api('tuan', 'gettuanlist')(ordersData).then(res => {
            const orders = res.data.data
            this.setState({ orders, })
        })
    }

    getCode() {
        if (!this.state.code_src) {
            api('user', 'getwxacode')({ app: appname }, 'post', {
                page: 'pages/good/good', scene: `id=${this.props.match.params.tuanid}&code=2`,
                width: 280, types: 1
            }).then(res => {
                if (res.status !== 200) return
                if (Number(res.data.code) === 0) {
                    this.setState({ code_src: res.data.data.image })
                }
            })
        }
    }
}

function copy_link(link) {
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', link);
    document.body.appendChild(input);
    input.select()
    // input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
        document.execCommand('copy')
    }
    document.body.removeChild(input);
}

const mapStateToProps = (state, ownProps) => {
    return { user: state.login_user_data, }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearSelectGood: () => {
            dispatch(select_good(null))
        }
    }
}


export default withRouter((connect(mapStateToProps, mapDispatchToProps)(Tuan)))

class Order extends Component {
    render() {
        return (<div className='tuan__order__item'>
            <div className='tuan__order__item__head fcc'>
                <span style={{ color: '#be3f41', marginRight: 12 }}>{this.props.data.lou}.</span>
                <div className='tuan__order__item__head__ava'>
                    <img className='img-center' alt='头像' src={this.props.data.shopinfo.logo} style={{ height: '100%', width: '100%', borderRadius: '50%' }} />
                </div>
                <div className='tuan__order__item__head__name'>{this.props.data.shopinfo.name}</div>
                <div style={{ flex: 1 }}>
                    <div className='fcc' style={{ background: '#fff6f6', color: 'var(--theme-main)', borderRadius: 5, padding: '2px 6px', fontSize: 10, width: '9em' }}>
                        <i style={{ color: 'inherit', marginRight: 6 }} className='iconfont icon-gou'></i> 已参团 +1
                    </div>
                </div>
                <div className='tuan__order__item__head__showtime'>{this.props.data.showtime}</div>
            </div>
            <div className='tuan__order__item__body'>
                {this.props.data.items.map((i, idx) => {
                    return (
                        <div key={idx} className='fbc' style={{ margin: '6px 3px' }}>
                            <span style={{ fontSize: 12, color: '#313131' }}>{i.name}({i.unit})</span>
                            <span style={{ fontSize: 12, color: '#313131' }}>+{i.num}</span>
                        </div>
                    )
                })}
                {this.props.data.sign && this.props.data.sign.map((i) => {
                    let ban = 1 // 1公开 2部分公开 0 隐藏
                    for (let s = 0; s < this.props.sign.length; s++) {
                        const sign = this.props.sign[s];
                        if (i.fid === sign.id) {
                            ban = (Number(sign.is_pub))
                            break
                        }
                    }
                    switch (Number(i.type)) {
                        case 1: // 文字
                            return (
                                <div key={i.id} className='fbc' style={{ margin: '6px 3px' }}>
                                    <span style={{ fontSize: 12, color: '#313131' }}>{i.name}</span>
                                    <span style={{ fontSize: 12, color: '#313131', maxWidth: '50vw' }}>{(() => {
                                        if (ban === 0) {
                                            return '****'
                                        }
                                        if (ban === 1) {
                                            return i.value[0].content
                                        }
                                        if (ban === 2) {
                                            const l = i.value[0].content.length
                                            return i.value[0].content.slice(0, Math.round(l / 2)) + '****'
                                        }
                                        return i.value[0].content
                                    })()}</span>
                                </div>
                            )
                        case 2: // 多选
                            return (
                                <div key={i.id} className='fbc' style={{ margin: '6px 3px', alignItems: 'flex-start' }}>
                                    <span style={{ fontSize: 12, color: '#313131' }}>{i.name}(多选)</span>
                                    <div style={{ fontSize: 12, color: '#313131', maxWidth: '50vw' }}>

                                        {Array.isArray(i.value) ? i?.value?.map((answer, index) => {
                                            return (<div key={index} style={{ textAlign: 'right', fontSize: 12 }}>
                                                {ban === 1 ? answer.content : '****'}
                                            </div>)
                                        }) : i.value}
                                    </div>
                                </div>
                            )
                        case 3: // 语音
                            return (
                                <div key={i.id} style={{ margin: '6px 3px' }}>
                                    <div style={{ fontSize: 12, color: '#313131' }}>{i.name}</div>
                                    {/* <span style={{fontSize: 12, color: '#313131',maxWidth: '50vw'}}>{i.value[0].content}</span> */}
                                    <audio src={ban === 1 ? i.value[0].src : ''} style={{ height: 30, width: '70vw' }} controls></audio>
                                </div>
                            )
                        case 4: // 图片
                            if (ban === 0) return ''
                            return (
                                <div key={i.id} className='' style={{ margin: '6px 3px' }}>
                                    <span style={{ fontSize: 12, color: '#313131' }}>{i.name}</span>
                                    <NinePic list={i.value} />
                                </div>
                            )
                        case 5: // 地点
                            return (
                                <div key={i.id} className='fbc' style={{ margin: '6px 3px' }}>
                                    <span style={{ fontSize: 12, color: '#313131' }}>{i.name}</span>
                                    <span style={{ fontSize: 12, color: '#313131', maxWidth: '50vw' }}>{ban === 1 ? i.value.name : '****'}</span>
                                </div>
                            )
                        case 6: // 单选
                            return (
                                <div key={i.id} className='fbc' style={{ margin: '6px 3px' }}>
                                    <span style={{ fontSize: 12, color: '#313131' }}>{i.name}</span>
                                    <span style={{ fontSize: 12, color: '#313131', maxWidth: '50vw' }}>{ban === 1 ? i.value[0].content : '****'}</span>
                                </div>
                            )
                        default:
                            return ''
                    }
                })}
            </div>
        </div>)
    }
}