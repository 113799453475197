import React from "react";
import styled from "styled-components";

const Privacy = styled.div`
    max-width: 1200px;
    margin: 84px auto;
    padding-top: 25px;
    h2{
        padding: .1em 0;
        margin: .1em 0;
        color: #000;
        font-weight: 700;
    }
`

export default function () {
    return <Privacy>
        <h1>隐私政策</h1>
        <p>北京灵数世纪科技有限公司（下文简称“接力GO”或“我们”）尊重您的隐私权以及个人信息保护权。本隐私政策旨在向您介绍接力GO以及我们的业务、我们如何收集、分享以及使用您的个人信息、您如何可以行使您的权利。本隐私政策将帮助您了解以下内容：</p>
        <p>一、接力GO采集以及使用的个人信息的种类以及目的</p>
        <p>二、接力GO如何将您的个人信息向第三方进行分享、转让或披露</p>
        <p>三、Cookies以及类似的追踪科技</p>
        <p>四、接力GO如何保护您的个人信息</p>
        <p>五、您的权利</p>
        <p>六、我们如何处理未成年人的信息</p>
        <p>七、本协议通知的更新流程</p>
        <p>八、如何联系我们</p>
        <p>我们在我们的【网站/APP】上收集的您的个人信息；您自愿向我们直接提供或者通过【第三方APP、微信公众号或小程序、或第三方在线平台】向我们提供的个人信息。在使用我们的这些服务前，请您务必仔细阅读并透彻理解本政策，并做出您认为适当的选择。</p>
        <p>本隐私政策依据中国法律法规制定。本隐私政策中所指的“个人信息”指中国法律法规中规定的个人信息以及个人敏感信息。</p>
        <p>如您对我们的如何使用您的个人信息有任何的问题或者疑虑，请随时通过本隐私通知底部所示的联系方式与我们联系。</p>
        <h2>一、接力GO采集以及使用的个人信息的种类以及目的</h2>
        <p>我们采集的关于您的个人信息主要有如下的种类：</p>
        <p>（一）您自愿向我们提供的您的个人信息我们会收集您自愿选择向我们提供的某些个人信息，这些信息对于对应的服务而言非常重要，例如：</p>
        <p>如您有意向使用我们的网站、接力GO的网络商城、APP或微信公众号或小程序，我们会要求您向我们提供您的个人联系方式以完成账户注册及配送业务，这类的信息包括姓名、手机、邮箱、收货地址、微信头像、生日、性别、昵称、位置信息等。我们也会使用这些个人信息对您在登入我们的网站、APP或微信公众号或小程序时对您进行身份核实。</p>
        <p>如您在我们的商城进行预订或者寻求帮助，我们会要求您向我们提供您的个人联系方式以便我们联系您；我们会要求您向我们提供您的个人联系方式和收货地址以便我们完成配送；我们会要求访问您的手机相册获取与商品相关的图片、视频，以便为您提供售后服务。</p>
        <p>如您有兴趣参与我们线下的团长等定制化活动或服务，我们会要求您向我们提供您的个人联系方式以便我们联系您，这类的信息包括姓名、性别、手机、邮箱、参加活动理由、职业/专业等，这些信息将由您或我们的工作人员录入我们的内部系统之中。</p>
        <p>如您有兴趣了解接力GO的动态或者我们的产品或者服务，您可以获得我们的这些营销类的信息或者将您的问题告诉我们。为了向您发送这些营销类的个人信息或者回复您的问题，我们会采集以及使用您的名字、邮箱和/或微信头像、微信昵称。</p>
        <p>（二）我们的网站或APP经您授权采集的个人信息</p>
        <p>当您访问我们的网站或APP的时候，我们会在您授权后从您的设备处收集一些您的个人信息，以完成一些可能对您有所帮助的功能：</p>
        <p>（1）基于位置信息的个性化推荐功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域可以购买的产品与/或服务，例如向您推荐离您最近的自提点。</p>
        <p>（2）基于摄像头（相机）的扩展功能：您可以使用这项扩展功能完成视频拍摄、拍照的功能。</p>
        <p>（3）基于图片上传的扩展功能：您可以使用此功能在申请售后时通过上传图片作为售后依据。</p>
        <p>（4）基于消息的扩展功能：您可以使用此功能在下单后实时接收订单相关通知等。</p>
        <p>（5）基于拨打电话的扩展功能：您可以使用此功能，给允许被打电话的团长/团员电拨打电话。</p>
        <p>上述扩展功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）、麦克风以及通讯录的访问权限，以实现这些功能所涉及的信息的收集和使用。您可以决定将这些权限随时的开启或关闭。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>
        <p>（三）我们通过第三方获得的您的个人信息</p>
        <p>我们会不时地从第三方处获得关于您的个人信息（例如京东、淘宝、天猫等电商平台），但这些个人信息仅仅是我们为了向您发货和完成您在电商平台上的订单，我们也将遵循相应电商平台不时发布的规则。</p>
        <p>一般来说，我们收集到的您的个人信息仅会用于我们在本隐私通知中所载的目的。但是，在法律允许的情形下，我们也会将您的个人信息用于一些新的目的，但这些目的是与我们先前向您告知的目的相关联和不冲突的，例如因国家需要或者公共利益的需要进行归档保存、科研以及历史研究；或者是进行内部审计、统计分析，以改善我们的产品或服务）。</p>
        <h2>二、接力GO如何将您的个人信息向第三方进行分享、转让或披露</h2>
        <p>我们可能会将您的个人信息向如下种类的第三方进行共享或披露：</p>
        <p>（一）向我们的集团公司或关联公司以及向我们公司提供数据处理服务的第三方服务商以及合作伙伴（例如，为了支持我们的APP或网站的正常运营以及安全的服务商、送达商品包裹的物流公司等）进行披露，或者其他为了本隐私通知所列的目的进行数据处理的第三方。</p>
        <p>（二）向相关的执法机构、监管机构、政府、法院或者其他第三方进行披露，并且该等披露是我们认为必要的并且是为了</p>
        <p>（1）符合某一项法律规定或要求；</p>
        <p>（2）行使、建立或者抗辩我们的合法权利；</p>
        <p>（3）保护您非常重要的权益或者他人非常重要的权益；或</p>
        <p>（4）为维护社会公众利益的情形。</p>
        <p>除非经过您的明确同意，我们不会将您的个人信息转让给任何公司、组织和个人，在涉及合并、收购或破产清算，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续接受此隐私政策的约束，否则我们将要求该公司、组织重新征求您的授权同意。</p>
        <h2>三、Cookies以及类似的追踪科技</h2>
        <p>为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为 Cookie 的小数据文件。Cookie会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息(例如一键登录等)的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全等。我们不会将 Cookie 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机或移动设备上保存的所有 Cookie，您有权接受或拒绝 Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie；另外，您也可以清除软件内保存的所有 Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能</p>
        <h2>四、接力GO如何保护您的个人信息</h2>
        <p>我们会采取合适的技术手段以及管理手段来保护您的个人信息。这些手段将根据您的个人信息的风险的高低来适用。具体的保护方式例如我们会对您的个人信息进行加密，无论是在个人信息传输中或者在静态的环境下。</p>
        <p>（一）跨境传输</p>
        <p>您的个人信息可能会被传输到中国境外或者在中国境外进行数据处理。我们已经采取了相应的保护手段以实现您的个人信息将会依照本隐私告知的要求进行保护。</p>
        <p>（二）数据存储</p>
        <p>我们会储存我们收集到的您的个人信息以满足我们的正当的业务需求，例如向您提供服务或者来满足相应的法律、税务以及财务的要求。</p>
        <p>当我们没有这些需要使用到您的个人信息的正当业务需求时，我们会删除您的个人数据或者对其进行匿名化处理，或者是如果匿名化或者删除皆不可能实现（例如您的个人信息已经在备份中存储），我们会安全地储存您的这些个人信息并且将这些个人信息与其他的数据处理区隔开来。</p>
        <h2>五、您的权利</h2>
        <p>对于我们采集和使用您的个人信息，您有如下的权利以保护您的个人信息：</p>
        <p>（一）如您希望访问、更正、更新或者要求我们删除您的个人信息，您可以通过“如何联系我们”部分所列的方式与我们取得联系。</p>
        <p>（二）您有权退订我们不时向您发送的营销信息。您可以通过每则营销信息中所列的“退订”方式进行退订。如您希望退订其他的营销信息，您可以通过“如何联系我们”部分所列的方式与我们取得联系。</p>
        <p>我们将会按照中国法律的要求回应您的数据主体权利的行权请求。</p>
        <h2>六、我们如何处理未成年人的信息</h2>
        <p>在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
        <p>对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
        <h2>七、本隐私通知的更新流程</h2>
        <p>我们将不时地对本隐私通知进行更新以满足法律、技术以及商业上的变化。当我们对本隐私通知进行更新时，我们会依据这些变化的重要程度采取合适的方式通知您。</p>
        <h2>八、如何联系我们</h2>
        <p>我们设立了个人信息保护专职部门，您可以通过jieligo006@163.com与其联系</p>
        <p>如果您对我们的回复不满意，特别是您认为我门的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案</p>
    </Privacy>

}