import { useState, useEffect } from "react"
import React from 'react';
import Modal from "../../total/Modal";
import ReactDOM from "react-dom";
// import Switch from "../../total/Switch";
import styled from "styled-components";
import BTN from "../../../StyleComponents/Button";
import { get, post } from "../../../netApi/fetch";
// import { responsiveMap } from "antd/lib/_util/responsiveObserve";
import Select from "../../total/Select_";
import { message } from "antd";
import { confirm as Confirm } from '../../Method/Confirm';
// import createMall from "./createMall";
import MallSetting from "./MallSetting";
import createMall from "./createMall";
import Toast from "../../Method/Toast";
import text from "../../../data/text";
import { admin } from "../../../data/competence";
import VIPPower from '../../index/method/VIPPower'

let confirmWrap: Element

const CatItem = styled('div') <{ active: boolean }>`
    width: 112px;
    height: 38px;
    background: ${props => props.active ? '#FFF3E6' : '#fff'};
    color: ${props => props.active ? '#FF9A27' : '#666'};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Table = styled('table')`
    width: 100%;
    /* height: 100%; */
    border: 1px solid #D9D9D9;
    border-radius:2px;
    font-weight: 600;
    td{
        border: 1px solid #f0f0f0;
        text-align: center;
        &:not(:first-child){
            min-width: 6em;
        }
        &:last-child{
            min-width: 11.5em;
        }
    }
    thead{
        background: #F7F8FA;
        tr{
            height: 56px;
        }
        td{
            padding: 0 1em;
            :last-child, :nth-child(2){
                text-align: left;
            }    
        }
    }

    tbody {
        td:last-child{
            color: var(--theme-main);
            padding: 1em;
            span, a {
                transition: all .2s;
                &:not(:last-child){
                    margin-right: 1.2em
                }
                &:hover{
                    opacity: .5;
                }
                &:active{
                    opacity: 1;
                    color: #d63c44;
                }
            }
        }
        td:first-child{
            padding: 1em;
        }
        td:nth-child(2){
            padding: 20px 1em;
            span{
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        tr:not(:last-child){
            border-bottom: 1px solid #ccc;
        }
    }

    img{
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 12px;
        margin-right: 1em;
    }
`

export default function (props) {
    post('/charge/checkmemberauth', { sid: false, auth_type: 1 }).then(res => {
        text.ERROR.MEMBER.ADMIN = res.message
        admin.set(res.auth)
    })
    return new Promise((res, rej) => {
        function Manage(p) {
            const [shouldClose, close] = useState(false)
            const [page, setPage] = useState(1)
            const [addGroupTitle, setTitle] = useState('')
            const [catlist, setCatlist] = useState<any[]>()
            const [activeCat, setActiveCat] = useState<number | string>('all')
            useEffect(() => {
                if (!catlist || catlist.length === 0) {
                    get('/mall/getmallbranchgrouplist', {}).then(data => {
                        setCatlist(data.map(i => {
                            return {
                                ...i,
                                editting: false,
                                edittingName: i.name,
                            }
                        }))
                    })
                }
            }, [catlist])
            const selectCatList = (catlist || []).filter(i => i.id !== 'all' && String(i.id) !== '0')
            return <Modal title={page === 1 ? '管理门店' : <div className='cur-pointer flex-between' onClick={() => { setPage(1) }}>
                <div><i className='iconfont icon-arrow-fine-left' style={{ fontWeight: 600, marginRight: 16 }} /></div>
                <span>返回</span>
            </div>} shouldClose={shouldClose} cancel onConfirm={confirm} onClose={reject} subTitle={<BTN solid color='red' onClick={() => {
                createMall({ user: props.user, mall_id: props.mallinfo.id, mallinfo: props.mallinfo || undefined }).then(() => {
                    setCatlist([])
                }).catch(() => { })
            }}>创建门店</BTN>} >
                {page === 1 ? <div style={{ width: 652, height: 600, maxHeight: 'inherit', overflowY: 'auto' }}>
                    <div className='flex-box' style={{ flexWrap: "wrap", marginBottom: 24 }}>
                        {catlist && catlist.map((i, idx) => {
                            return <CatItem active={activeCat === i.id} onClick={setActiveCat.bind(null, i.id)} key={idx}>{i.name}</CatItem>
                        })}
                        <div className='flex-center' style={{ width: 112, height: 38 }}>
                            <BTN color='red' onClick={() => { setPage(2) }} >管理分组</BTN>
                        </div>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <td style={{ width: '12em' }}>门店头像与名称</td>
                                <td style={{ width: '12em' }}>门店分组</td>
                                <td style={{ width: '12em' }}>门店管理员</td>
                                <td style={{ width: '', minWidth: 'unset' }}></td>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                if (!catlist) return null
                                const activeCate = catlist.find(i => activeCat === i.id)
                                if (!activeCate) return null
                                return activeCate.branch_list.map(i => {
                                    return <tr key={i.id}>
                                        <td>
                                            <div className='flex-box'>
                                                <img alt='' src={i.logo} className='img-center' />
                                                <div className='flex-box' style={{ flexWrap: 'wrap' }}>
                                                    <span style={{ fontWeight: 600, }}>{i.name}</span>
                                                    <span style={{ color: '#fff', background: 'var(--theme-main)', borderRadius: 4, padding: '0 6px' }}>{i.branch_name}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Select uni={`bcid${i.id}`} list={selectCatList} showKey='name' placeholder='请选择分组'
                                                value={selectCatList.findIndex(j => String(j.id) === String(i.branch_group_id))}
                                                onChange={(e, idx) => {
                                                    post('/mall/setmallgroup', {
                                                        mall_id: i.id, group_id: e.id
                                                    }).then(() => {
                                                        activeCate.branch_group_id = e.id
                                                        setCatlist([])
                                                    }).catch(e => { if (e.message) message.error(e.message) })
                                                }} />
                                        </td>
                                        <td>
                                            <div className='flex-box' style={{ flexWrap: 'wrap', color: '#666', padding: 20 }}>
                                                {i.admins.map(admin => {
                                                    return <div key={admin.uid} style={{ marginRight: 20, marginBottom: 10 }} className='flex-box'>
                                                        <span style={{ marginRight: 5 }}>
                                                            {admin.shopInfo.name}
                                                        </span>
                                                        <i className='iconfont icon-guanbi-tianchong cur-pointer' onClick={() => {
                                                            post('/mall/setadministrator', { op: 3, uid: admin.uid, mall_id: admin.mall_id, }).then(() => {
                                                                i.admins = i.admins.filter(j => j.uid !== admin.uid)
                                                                setCatlist([...catlist])
                                                            })
                                                        }} />
                                                    </div>
                                                })}
                                                <div style={{ position: 'relative' }} onClick={() => {
                                                    if (!admin.get()) {
                                                        Confirm({
                                                            title: '温馨提示',
                                                            text: text.ERROR.MEMBER.ADMIN,
                                                            confirmText: '去升级',
                                                            cancelText: '暂不升级',
                                                        }).then(res => {
                                                            if (res) VIPPower({})
                                                        })
                                                        return
                                                    }
                                                    i.showInviteCode = !i.showInviteCode
                                                    setCatlist([...catlist])
                                                }}>
                                                    <BTN color='red' >
                                                        添加管理员
                                                    </BTN>
                                                    {i.showInviteCode && <CodePanel scene={`mode=mall&m=${i.id}&r=${i.distribution_relation_id}`} />}
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ width: '', minWidth: 'unset' }}>
                                            <BTN noborder color='gray' onClick={() => {
                                                Confirm({
                                                    title: '操作确认',
                                                    text: `确定要删除门店《${i.branch_name}》吗？`,
                                                }).then(del => {
                                                    if (del) {
                                                        post('/mall/setmall', {
                                                            op: 4, delete_mall_id: i.id,
                                                        }).then(() => {
                                                            Toast.show('删除成功')
                                                            setCatlist([])
                                                        }).catch(e => { if (e.message) message.error(e.message) })
                                                    }
                                                }).catch()
                                            }} >
                                                删除
                                            </BTN>
                                            <BTN noborder color='red' onClick={() => {
                                                MallSetting({
                                                    mall_id: i.id,
                                                    user: props.user
                                                }).then(res => {
                                                    if (res) setCatlist([])
                                                }).catch(e => { })
                                            }} >
                                                修改
                                            </BTN>
                                        </td>
                                    </tr>
                                })
                            })()}
                        </tbody>
                    </Table>
                </div> : null}
                {page === 2 ? <div style={{ width: 652, height: 600, maxHeight: 'inherit', overflowY: 'auto' }}>
                    <div className='flex-between' style={{ borderRadius: 2, border: '1px solid #d9d9d9', marginBottom: 20, }}>
                        <div style={{ padding: '1em' }}>
                            <strong>添加分组</strong>
                        </div>
                        <div style={{ padding: '1em', flex: 1 }}>
                            <input value={addGroupTitle} onChange={e => { setTitle(e.target.value) }} style={{ border: 0, width: '100%' }} placeholder='请输入分组名称' />
                        </div>
                        <div style={{ padding: '1em' }}>
                            <BTN noborder color='red' onClick={() => {
                                if (addGroupTitle.length === 0) {
                                    message.warn('请输入分组名称')
                                    return
                                }
                                post('/mall/groupset', {
                                    name: addGroupTitle,
                                }).then(() => {
                                    setCatlist([])
                                    setTitle('')
                                }).catch(e => { if (e.message) message.error(e.message) })
                            }}>添加</BTN>
                        </div>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <td style={{ width: '37em' }}>分组名称</td>
                                <td style={{ width: '' }}>操作</td>
                            </tr>
                        </thead>
                        <tbody>
                            {selectCatList.map(i => {
                                return <tr key={i.id}>
                                    <td>
                                        {i.editting ? <input autoFocus value={i.edittingName} style={{ padding: '4px 11px', border: '1px solid #999', width: '100%', borderRadius: 2 }}
                                            placeholder='请输入分组名称' onChange={e => {
                                                setCatlist(catlist.map(j => {
                                                    if (i.id === j.id) {
                                                        return {
                                                            ...j, edittingName: e.target.value
                                                        }
                                                    }
                                                    return j
                                                }))
                                            }} /> : <strong>{i.name}</strong>}
                                    </td>
                                    <td>
                                        <div className='flex-between'>
                                            <BTN noborder onClick={() => {
                                                if (!i.editting) {
                                                    setCatlist(catlist.map(j => {
                                                        if (i.id === j.id) {
                                                            return {
                                                                ...j, editting: true
                                                            }
                                                        }
                                                        return j
                                                    }))
                                                } else {
                                                    post('/mall/groupset', {
                                                        id: i.id,
                                                        name: i.edittingName
                                                    }).then(() => {
                                                        message.success('修改成功')
                                                        setCatlist([])
                                                    })
                                                }
                                            }}>{i.editting ? '保存' : '修改'}</BTN>
                                            <BTN noborder onClick={() => {
                                                Confirm({
                                                    text: `确定要删除分组\`${i.name}\`吗`,
                                                    title: '操作确认'
                                                }).then(res => {
                                                    if (res) {
                                                        post('/mall/groupset', {
                                                            id: i.id,
                                                            status: 0
                                                        }).then(() => {
                                                            message.success('删除成功')
                                                            setCatlist([])
                                                        }).catch(e => { if (e.message) message.error(e.message) })
                                                    }
                                                })
                                            }}>删除</BTN>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div> : null}
            </Modal>

            function confirm() {
                res(true)
                close(true)
            }
            function reject() {
                res(false)
                ReactDOM.render(<></>, confirmWrap)
            }
        }




        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Manage {...props} />, confirmWrap)
    })
}

const Panel = styled.div`
    position: absolute;
    top: 40px;
    right: 0;
    box-shadow: #999 0 0 15px;
    flex-direction: column;
    padding: 12px;
    display: flex;
    align-items: center;
    width: 280px;
    background-color: white;
    z-index: 2;
    user-select: none;
    img{
        margin-bottom: 12px !important;
        width: 200px !important;
        height: 200px !important;
        border-radius: 50%;
        margin-right: 0 !important;
    }
`

export function CodePanel(props: {
    scene: string
    page?: string
}) {

    const [img, setImg] = useState<string>()
    useEffect(() => {
        if (img) return
        post('/user/getwxacode?app=jieligo', {
            width: 200,
            page: props.page || 'package/packageAdmin/myadministrator/myadministrator',
            scene: props.scene,
        }).then(res => {
            setImg(res.image)
        })
    }, [img, props])
    return <Panel>
        <img src={img} alt='' />
        <div style={{ fontWeight: 600, fontSize: 12 }}>扫描小程序码去邀请管理员</div>
    </Panel>
}