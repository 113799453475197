import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import Footer from "../index/component/Footer"
import BTN from "../../StyleComponents/Button"
import QRCode from 'qrcode.react';
import { get } from "../../netApi/fetch"

interface props {
    user: user
}

const Page = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    user-select: none;
    .logo{
        width: 86px;
        margin-bottom: 16px;
        border-radius: 20px;
    }
    .poster{
        width: 800px;
        max-width: 67vw;
    }
    h1{
        margin-bottom: 50px;
        font-size: 36px;
    }
    p{
        margin-bottom: 20px;
        color: #999;
    }
    button{
        width: 280px;
        height: 70px;
        border-radius: 35px;
        margin-bottom: 20px;
        font-size: 22px;
        i{
            font-size: 28px;
            margin-right: 12px;
        }
    }
    .qrcode{
        margin-bottom: 20px;
    }
`

const SPage = styled(Page)`
    button{
        width: 190px;
        height: 45px;
        border-radius: 25px;
        margin-bottom: 20px;
        font-size: 16px;
        i{
            font-size: 20px;
            margin-right: 12px;
        }
    }
    .poster{
        width: 800px;
        max-width: 100%;
    }
`

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps,)((props: props) => {

    console.log(window.innerWidth);

    const [appUrl, setUrl] = useState<string>()

    useEffect(() => {
        if (appUrl) return
        get('/appversion/newest', {}).then(res => {
            console.log(res);
            setUrl(res.download_url)
        })
    }, [appUrl])

    const DownloadButton = <BTN solid color='red' onClick={() => {
        if (!appUrl) return
        window.open(appUrl, '_blank')
    }} ><i className="iconfont icon-anzhuo"></i>下载 Android 版</BTN>

    if (window.innerWidth < 1200) {
        return <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <SPage>
                <div style={{ display: 'flex', flexDirection: 'column', background: '#F5F5F5', paddingTop: 180, }}>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 auto', alignItems: 'center' }}>
                        <img alt='' className='logo' src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/NN2Z8cCnl2.png?imageView2/5/200' />
                        <h1 style={{ fontSize: 16, }}>接力GO</h1>
                        <p style={{ fontSize: 12, }}>简单好用的价值协作工具</p>
                        {DownloadButton}
                        <div>
                            <img className='poster' src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/7ZEmJ6g5YG.png" alt="" />
                        </div>
                    </div>
                </div>
                <div style={{ margin: '16px auto 60px', padding: '0 50px' }}>
                    <p style={{ fontSize: 22, }}>接力GO是专业的社区团购&新零售系统服务商，为社区团长、社区消费者对接高品质的社区团购头部品牌，提供更好的社区团购服务！</p>
                </div>
                <div style={{ flex: 1 }}></div>
                <p className="flex-center">应用名称：接力GO</p>
                <p className="flex-center">开发商：北京灵数世纪科技有限公司</p>
            </SPage>
            <Footer />
        </div>
    }

    return <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Page>
            <div style={{ background: '#F5F5F5', paddingTop: 180, }}>
                <div className="flex-between" style={{ margin: '0 auto', maxWidth: 1200, alignItems: 'flex-end' }}>
                    <div style={{ paddingBottom: 50 }}>
                        <img alt='' className='logo' src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/NN2Z8cCnl2.png?imageView2/5/200' />
                        <h1>接力GO</h1>
                        <p style={{ fontSize: 22, }}>简单好用的价值协作工具</p>
                        {DownloadButton}
                        <div className="qrcode">
                            <QRCode size={120} value={`https://www.jieligo.net/downloads/app`} />
                        </div>
                        <p style={{ fontSize: 14, }}>扫描二维码下载应用或者点击按钮下载</p>
                    </div>
                    <div>
                        <img className='poster' src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/7ZEmJ6g5YG.png" alt="" />
                    </div>
                </div>
            </div>
            <div style={{ margin: '16px auto 60px', maxWidth: 1200, }}>
                <p style={{ fontSize: 22, }}>接力GO是专业的社区团购&新零售系统服务商，为社区团长、社区消费者对接高品质的社区团购头部品牌，提供更好的社区团购服务！</p>
            </div>
            <div style={{ flex: 1 }}></div>
            <p className="flex-center">应用名称：接力GO</p>
            <p className="flex-center">开发商：北京灵数世纪科技有限公司</p>
        </Page>
        <Footer />
    </div>
})