import React, { Component } from 'react'

import styled from 'styled-components';

const Item = styled.a`
    display: flex;
    margin-bottom: 40px;
`

const Img = styled.img`
    width: 120px!important;
    height: 120px!important;
    border-radius: 4px;
`
const Text = styled.div`
    flex: 1;
    padding: 10px 20px;
`
const Title = styled.div`
    font-weight:600;
    color:rgba(51,51,51,1);
`
const Content = styled.div`
    font-weight:400;
    font-size: 12px;
    color:rgba(153,153,153,1);
`

interface Props {
    content: string,
    title: string,
    img: string,
    src: string,
    signin: () => void,
}

class News extends Component<Props> {
    render() {
        return (
            <Item href={this.props.src} target='_blank'  rel="noopener noreferrer" className='cur-pointer' onClick={this.props.signin}>
                <Img className='img-center' src={this.props.img} />
                <Text>
                    <Title>{this.props.title}</Title>
                    <Content>{this.props.content}</Content>
                </Text>
            </Item>
        )
    }
}

export default News