import moment from 'moment';
import React, { Component } from 'react'

import ReactDOM from 'react-dom';

import styled from 'styled-components';

type icon = 'success'

const WIDTH = 110
const HEIGHT = 110

const FCC = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const View = styled(FCC)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index:999;
`


const ToastPanel = styled(FCC)`
    width: ${WIDTH}px;
    height: ${HEIGHT}px;
    border-radius: 15px;
    color: #fff;
    font-size: 14;
    background: rgba(76,76,76,.95);
    flex-direction: column;
    z-index: 999;
    position: fixed;
    left: calc(50vw - ${WIDTH / 2}px);
    top: calc(50vh - ${HEIGHT / 2}px);
`

const Icon = styled.i`
    line-height: 1;
    font-size: 18px;
    margin-bottom: 19px;
`

interface Props {
    message?: string
    mask: boolean
}

let wrapper: Element

let closeTime: moment.Moment

class Toast extends Component<Props> {
    render() {
        if (this.props.mask) {
            return <View>
                <ToastPanel>
                    <Icon className='iconfont icon-caozuochenggong'></Icon>
                    {this.props.message ? <div>{this.props.message}</div> : null}
                </ToastPanel>
            </View>
        }
        return <ToastPanel>
            <Icon className='iconfont icon-caozuochenggong'></Icon>
            {this.props.message ? <div>{this.props.message}</div> : null}
        </ToastPanel>
    }

}

function showToast(message: string, icon: icon = 'success', duration: number = 1500, mask: boolean = false): void {
    if (!wrapper) {
        wrapper = document.createElement('div') as Element
        document.body.appendChild(wrapper)
    }
    ReactDOM.render(<Toast message={message} mask={mask} />, wrapper)
    const ct = moment().add(duration, 'ms')
    if (ct.isAfter(closeTime)) closeTime = ct
    setTimeout(() => {
        hideToast()
    }, duration)
}

function hideToast(): void {
    if (moment().isBefore(closeTime)) return
    if (!wrapper) {
        wrapper = document.createElement('div') as Element
        document.body.appendChild(wrapper)
    }
    ReactDOM.render(<></>, wrapper)
}


export default {
    show: showToast,
}