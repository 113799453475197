import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Main from '../../high-component/Content';
import Modal from '../total/Modal';
import Select from '../total/Select';
import NinePic from '../total/NinePic';
import Audio from '../total/Audio';
import api from '../../netApi';

import styled from 'styled-components';

import { get } from '../../netApi/fetch.ts';

import Refund from './OrderCtrl/refund.tsx';
import ActRefund from './OrderCtrl/ActRefund.tsx';
import Checkin from './OrderCtrl/Checkin.tsx';
import { confirm } from '../Method/Confirm';

import { post } from '../../netApi/fetch';

import { message, Timeline } from 'antd';
import BTN, { SolidRed } from '../../StyleComponents/Button';
import Prompt from '../Method/Prompt';
import { QQ_MAP_KEY } from '../../data/options';
import VIPPower from '../index/method/VIPPower';
import { express } from '../../data/competence';
import text from '../../data/text';
import ChangeOrderNoModal from './components/ChangeOrderNoModal';
import './detail.scss';

const Top = styled.div`
  border-bottom: 2px solid #f1f1f1;
  position: relative;
  height: 100px;
  width: 100%;
  & > div {
    height: 100%;
  }
`;
const OrderStatus = styled.span`
  color: var(--theme-main);
`;

const Panel = styled.div`
  border-radius: 2px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
`;

const PanelTitle = styled.div`
  border-bottom: 1px #ccc solid;
  padding: 12px 20px;
  color: #333;
  font-size: 14px;
`;

const RefundItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div:nth-child(1) {
    width: 6em;
    min-width: 6em;
  }
  & > div:nth-child(2) {
    flex: 1;
  }
`;
const OrderMain = styled.div`
  flex: 1;
  max-width: 900px;
`;

class detail extends Component {
  constructor(p) {
    super();
    document.title = '订单详情';
    this.state = {
      shouldLoad: true,
      // order: {},
      ship: false,
      showExchange: false,
      expressList: [],
      expressNum: '',
      refundList: [],
      refundState: {
        process: null,
        top: null,
      },
    };
  }
  bc;
  ship() {
    this.setState({ ship: true });
  }
  close() {
    this.setState({ ship: false });
  }
  setExperss(e) {
    // console.log(e)
    this.setState({
      express: e,
    });
  }
  setExpressnum(e) {
    this.setState({
      expressNum: e.target.value,
    });
  }
  sendExpress() {
    api('order', 'sendgoods')({}, 'post', {
      orderid: this.state.order.orderid,
      express_id: this.state.express.id,
      expressname: this.state.express.name,
      expressnum: this.state.expressNum,
      types: 1,
    }).then((res) => {
      if (Number(res.data.code) === 0) {
        alert('发货成功');
        if (!express.get()) {
          confirm({
            title: '温馨提示',
            text: text.ERROR.MEMBER.EXPRESS,
            confirmText: '去升级',
            cancelText: '暂不升级',
          }).then((res) => {
            if (res) VIPPower({});
          });
        }
        this.props.history.push('/refresh');
      }
    });
  }
  exchange() {
    api('order', 'exchange')({}, 'post', {
      orderid: this.state.order.orderid,
      types: 1,
      code: 0,
    }).then((res) => {
      if (Number(res.data.code) === 0) {
        message.success('核销成功');
        this.props.history.push('/refresh');
      }
    });
  }

  componentDidMount() {
    if (!window.BroadcastChannel) return;
    if (BroadcastChannel) {
      this.bc = new BroadcastChannel('orderList');
    }
    if (this.props.is_Login) {
      this.getDetail();
    }
    post('/charge/checkmemberauth', { sid: false, auth_type: 3 }).then((res) => {
      express.set(res.auth);
    });
  }

  componentDidUpdate(p) {
    if (this.props.is_Login && (this.props.match.params.id !== p.match.params.id || !p.is_Login)) {
      this.getDetail();
    }
  }

  componentWillUnmount() {
    if (this.riderInterval) {
      clearInterval(this.riderInterval);
    }
  }

  //展示弹窗
  showChangeModal() {
    this.refs['changeOrderNoRef'].showModal();
  }

  // 修改快递单号
  handleChangeOrderNo(expressnum) {
    let { order } = this.state;
    api('order', 'updatedeliverysnbyorderid')({}, 'post', {
      order_id: this.state.order.orderid,
      express_num: expressnum,
    }).then((resp) => {
      const { data } = resp;
      order.expressnum = expressnum;
      if (data.data.express_name) {
        // 更新订单数据
        order.expressname = data.data.express_name;
      }
      message.success('快递单号修改成功');
      this.setState({ order });
      this.refs['changeOrderNoRef'].hideModal();
    });
  }

  render() {
    return (
      <div className='fbc' style={{ flexDirection: 'column' }}>
        <Top>
          <div className='flex-center' style={{ margin: '0 auto', position: 'relative', maxWidth: 1200 }}>
            <div className='fcc' style={{ color: '#999' }}>
              订单状态：
              <OrderStatus>
                {this.state.order && Number(this.state.order.shipping_mode) !== 3 && this.state.order.status_content}
                {this.state.order && Number(this.state.order.shipping_mode) === 3 && this.state.dispatch && this.state.dispatch.showText}
              </OrderStatus>
            </div>
            {!this.state.order?.is_hema && (
              <div className='fcc' style={{ position: 'absolute', right: 20, top: 0, bottom: 0 }}>
                {this.state.order && this.state.order.refund_status !== -1 && Number(this.state.order.status) === 1 ? (
                  <BTN
                    solid
                    color={'gray'}
                    onClick={() => {
                      if (this.state.mode_list) {
                        this.setState({ initiativeRefund: true });
                        return;
                      }
                      get('/order/getcanrefundmode', {
                        types: 1,
                        orderid: btoa(this.state.order.orderid),
                      }).then(({ mode_list, fail_content }) => {
                        if (fail_content !== '') {
                          alert(fail_content);
                        }
                        this.setState({ initiativeRefund: true, mode_list });
                      });
                    }}
                  >
                    发起退款
                  </BTN>
                ) : null}

                {/* 处理退款 */}
                {/* && Number(this.state.order.cansend) !== 0 */}
                {this.state.order && this.state.order.refund_status === -1 ? (
                  <div>
                    {this.state.refundState.process == null && this.state.refundState.top == null ? (
                      <button className='button_solid_red' style={{ margin: 12 }}>
                        已同意并向上级申请退款
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({ showRefund: true });
                        }}
                        className='button_solid_red'
                        tyle={{ marginLeft: 12 }}
                      >
                        处理退款
                      </button>
                    )}
                  </div>
                ) : null}

                {this.state.order &&
                this.state.order.is_can_send_goods_or_exchange &&
                (Number(this.state.order.status) === 1 || Number(this.state.order.status) === 5) ? (
                  <>
                    {/* 发货按钮 */}
                    {Number(this.state.order.shipping_mode) === 1 ? (
                      <div className='fcc'>
                        <BTN color='gray' solid className='' style={{ marginLeft: 12 }} onClick={this.ship.bind(this)}>
                          发 货
                        </BTN>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* 核销按钮 */}
                    {Number(this.state.order.shipping_mode) === 2 ? (
                      <div className='fcc'>
                        <BTN
                          color='red'
                          solid
                          className=''
                          style={{ marginLeft: 12 }}
                          onClick={() => {
                            this.setState({ showExchange: true });
                          }}
                        >
                          核 销
                        </BTN>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.order.shipping_mode) === 3 ? (
                      <div className='fcc'>
                        <BTN
                          color='red'
                          style={{ marginLeft: 12 }}
                          solid
                          onClick={() => {
                            confirm({ text: '本订单将由达达骑手上门取货并配送，确定现在要配送？', title: '操作确认' }).then((res) => {
                              if (!res) return;
                              post('/order/dispatchdelivery', { order_id: this.state.order.orderid })
                                .then((res) => {
                                  message.success('发货成功');
                                  this.getDetail();
                                })
                                .catch((e) => {
                                  if (e.message) message.error(e.message);
                                });
                            });
                          }}
                        >
                          立即配送
                        </BTN>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {(() => {
                  if (!this.state.order) return null;
                  if (!this.state.dispatch) return null;
                  const { status } = this.state.dispatch;
                  if ([1, 2, 100].includes(Number(status))) {
                    return (
                      <BTN
                        solid
                        color='gray'
                        onClick={() => {
                          Prompt({
                            type: 'select',
                            title: '请选择取消理由',
                            selectConfig: {
                              list: this.state.cancelreasons,
                              showKey: 'reason',
                            },
                          })
                            .then((res) => {
                              post('/dispatch/cancelorder', {
                                order_id: this.state.order.orderid,
                                reason_id: res.id,
                              }).then((res) => {
                                message.success('操作成功');
                                this.getDetail();
                              });
                            })
                            .catch((e) => {});
                        }}
                      >
                        取消配送
                      </BTN>
                    );
                  }
                  if ([5, 7, 10].includes(Number(status))) {
                    return (
                      <BTN
                        solid
                        color='gray'
                        onClick={() => {
                          confirm({ text: '本订单将由达达骑手上门取货并配送，确定现在要配送？', title: '操作确认' }).then((res) => {
                            if (!res) return;
                            post('/dispatch/readdorder', { order_id: this.state.order.orderid })
                              .then((res) => {
                                message.success('操作成功');
                                this.getDetail();
                              })
                              .catch((e) => {
                                if (e.message) message.error(e.message);
                              });
                          });
                        }}
                      >
                        重新配送订单
                      </BTN>
                    );
                  }
                  if ([9].includes(Number(status))) {
                    return (
                      <BTN
                        solid
                        color='gray'
                        onClick={() => {
                          confirm({ text: '本订单将由达达骑手上门取货并配送，确定现在要配送？', title: '操作确认' }).then((res) => {
                            if (!res) return;
                            post('/dispatch/returnconfirm', { order_id: this.state.order.orderid })
                              .then((res) => {
                                message.success('操作成功');
                                this.getDetail();
                              })
                              .catch((e) => {
                                if (e.message) message.error(e.message);
                              });
                          });
                        }}
                      >
                        配送异常返回确认
                      </BTN>
                    );
                  }
                })()}
              </div>
            )}
          </div>
        </Top>
        <OrderMain style={{ flex: 1 }}>
          {this.state.order && this.state.order.buy_no ? (
            <div style={{ textAlign: 'center', paddingTop: 20 }}>
              <span style={{ color: '#999' }}>接龙号：</span>
              <span style={{ color: '#333' }}>NO.{/[a-zA-Z]+/.exec(this.state.order.buy_no)}-</span>
              <span style={{ color: '#333', fontSize: '2em' }}>{/[0-9]+/.exec(this.state.order.buy_no)}</span>
            </div>
          ) : null}
          {this.state.order ? (
            <div style={{ padding: '20px', width: '100%' }}>
              {this.state.order.refund_info.length ? (
                <Panel>
                  <PanelTitle>退款进度</PanelTitle>
                  <div style={{ padding: '10px 20px', color: '#333' }}>
                    {this.state.order.refund_info.map((i, idx) => {
                      const item = i;
                      const apply = item.refund_reason_by_apply;
                      const result = item.refund_reason_by_cancel_or_success;
                      const review_log_list = item.review_log_list;
                      return (
                        <RefundItem key={idx} style={{ marginBottom: '1em' }}>
                          <div style={{ minWidth: '2em', width: '2em' }}>{review_log_list.length !== 0 && review_log_list ? idx + 1 : ''}</div>
                          <div>
                            {review_log_list.length !== 0 && review_log_list ? (
                              <div style={{ borderBottom: '1px solid #ccc', marginBottom: '10px' }}>
                                <div>退款审批进度：</div>
                                <Timeline style={{ marginLeft: '20px', marginTop: '15px' }}>
                                  {review_log_list.map((item, index) => {
                                    const rs = Number(item.review_status);
                                    return (
                                      <Timeline.Item
                                        key={index}
                                        color={rs === 1 || rs === 0 || index === review_log_list.length - 1 ? '#FF9A27' : '#CCCCCC'}
                                      >
                                        <div style={{ marginLeft: 10 }}>
                                          {item.name ? <div style={{ fontSize: 12, marginBottom: 10 }}>{item.name}</div> : null}
                                          {!item.time_desc ? <div style={{ fontSize: 12, marginBottom: 10 }}>{item.reviewtime}</div> : null}
                                          {item.time_desc ? <div style={{ fontSize: 12, marginBottom: 10 }}>{item.time_desc}</div> : null}
                                          {(rs === 1 || rs === 0) && item.review_reason.refund_reason ? (
                                            <div style={{ fontSize: 12, marginBottom: 10 }}>
                                              {rs === 1 ? '同意' : '拒绝'}理由:&nbsp;&nbsp;{item.review_reason.refund_reason}
                                            </div>
                                          ) : null}
                                          {(rs === 1 || rs === 0) && item.review_reason.refund_desc ? (
                                            <div style={{ fontSize: 12, marginBottom: 10 }}>
                                              其他说明:&nbsp;&nbsp;{item.review_reason.refund_desc}
                                            </div>
                                          ) : null}
                                          {item.review_reason && item.review_reason.length !== 0 && item.review_reason.images.length !== 0 ? (
                                            <div>
                                              <div style={{ fontSize: 12, marginBottom: 10 }}>图片：</div>
                                              {item.review_reason.images.map((imgItem, imgIndex) => {
                                                return (
                                                  <img src={imgItem.src} key={imgIndex} alt='' style={{ width: 100, height: 100, marginRight: 10 }} />
                                                );
                                              })}
                                            </div>
                                          ) : null}
                                        </div>
                                      </Timeline.Item>
                                    );
                                  })}
                                </Timeline>
                              </div>
                            ) : null}
                            <RefundItem>
                              <div>退款时间：</div>
                              <div>
                                {apply.refund_time.split(' ').map((i) => (
                                  <span key={i} style={{ marginRight: 6 }}>
                                    {i}
                                  </span>
                                ))}
                              </div>
                            </RefundItem>
                            <RefundItem>
                              <div>退款类型：</div>
                              <div>{item.refund_mode}</div>
                            </RefundItem>
                            {item.refund_sku_list ? (
                              <RefundItem>
                                <div>退款时间：</div>
                                <div>
                                  {item.refund_sku_list.map((j, idx) => {
                                    return (
                                      <div key={idx}>
                                        <span style={{ marginRight: '1.2em' }}>
                                          {j.name}({j.unit})
                                        </span>
                                        <span>数量：{j.num}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </RefundItem>
                            ) : null}
                            <RefundItem>
                              <div>退款金额：</div>
                              <div>{item.refund_fee}</div>
                            </RefundItem>
                            <RefundItem>
                              <div>退款理由：</div>
                              <div>{apply.refund_reason}</div>
                            </RefundItem>
                            <RefundItem>
                              <div>退款说明：</div>
                              <div>{apply.refund_desc}</div>
                            </RefundItem>
                            {apply.images?.length ? (
                              <RefundItem>
                                <div>图片：</div>
                                <div style={{ paddingTop: 6, marginBottom: -6 }}>
                                  {' '}
                                  <NinePic imgHeight={140} list={apply.images} />
                                </div>
                              </RefundItem>
                            ) : null}
                            {result ? (
                              <>
                                <div style={{ height: 1, background: '#d8d8d8', margin: '6px 0' }}></div>
                                <RefundItem>
                                  <div>退款进度：</div>
                                  <div>
                                    {result.refund_time.split(' ').map((i) => (
                                      <span key={i} style={{ marginRight: 6, color: i === '退款成功' ? 'var(--theme-main)' : '' }}>
                                        {i}
                                      </span>
                                    ))}
                                  </div>
                                </RefundItem>
                                {result.refund_reason ? (
                                  <>
                                    <RefundItem>
                                      <div>拒绝理由：</div>
                                      <div>{result.refund_reason}</div>
                                    </RefundItem>
                                    <RefundItem>
                                      <div>拒绝说明：</div>
                                      <div>{result.refund_desc}</div>
                                    </RefundItem>
                                    {result.images.length ? (
                                      <RefundItem>
                                        <div>图片：</div>
                                        <div style={{ paddingTop: 6 }}>
                                          {' '}
                                          <NinePic imgHeight={140} list={result.images} />
                                        </div>
                                      </RefundItem>
                                    ) : null}
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </RefundItem>
                      );
                    })}
                  </div>
                </Panel>
              ) : null}
              {this.state.order.checkin_record?.length ? (
                <Panel>
                  <PanelTitle>{Number(this.state.order.checkin_record[0].takeway) === 2 ? '核销' : '发货'}记录</PanelTitle>
                  <div style={{ padding: '10px 20px', color: '#333' }}>
                    {this.state.order.checkin_record.map((item, idx, arr) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            marginBottom: '1em',
                            borderBottom: idx === arr.length - 1 ? 0 : '1px solid #ccc',
                            paddingBottom: idx === arr.length - 1 ? 0 : 6,
                          }}
                        >
                          <div>
                            <RefundItem>
                              <div>{Number(item.takeway) === 2 ? '核销' : '发货'}时间：</div>
                              <div>{item.checkin_time}</div>
                            </RefundItem>
                            <RefundItem>
                              <div>{Number(item.takeway) === 2 ? '核销' : '发货'}类型：</div>
                              <div>{Number(item.checkin_mode) === 1 ? '全部核销' : '部分核销'}</div>
                            </RefundItem>
                            {item.goods ? (
                              <RefundItem>
                                <div>{Number(item.takeway) === 2 ? '核销' : '发货'}时间：</div>
                                <div>
                                  {Object.keys(item.goods).map((j) => {
                                    const good = item.goods[j];
                                    return (
                                      <div key={j}>
                                        <span style={{ marginRight: '1.2em' }}>{good.name}</span>
                                        <span>数量：{good.num}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </RefundItem>
                            ) : null}
                            <RefundItem>
                              <div>{Number(item.takeway) === 2 ? '核销' : '发货'}人：</div>
                              <div>{item.operator_name}</div>
                            </RefundItem>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Panel>
              ) : null}
              {Number(this.state.order.shipping_mode) === 3 && this.state.dispatch ? (
                <Panel>
                  <PanelTitle>配送信息</PanelTitle>
                  <div style={{ padding: '10px 20px', color: '#333' }}>
                    <div style={{ marginBottom: 10 }}>配送平台：{this.state.dispatch.platform?.name}</div>
                    <div style={{ marginBottom: 10 }}>骑手姓名：{this.state.dispatch.rider_name}</div>
                    <div style={{ marginBottom: 10 }}>骑手电话：{this.state.dispatch.rider_phone}</div>
                  </div>
                </Panel>
              ) : null}
              {this.state.dispatch && Number(this.state.dispatch.status) !== 4 && (
                <div id='map-container' style={{ marginBottom: 30, borderRadius: 10, height: 200 }} />
              )}
              {Number(this.state.order.shipping_mode) !== 2 ? (
                <Panel>
                  <PanelTitle>购买人信息</PanelTitle>
                  <div style={{ padding: '10px 20px', color: '#333' }}>
                    <div className='fcc' style={{ justifyContent: 'flex-start', marginBottom: 10 }}>
                      <div style={{ width: 30, height: 30, marginRight: 12 }}>
                        <img className='img-center' style={{ borderRadius: '50%' }} alt='' src={this.state.order.shopinfo.logo} />
                      </div>
                      <div>{this.state.order.shopinfo.name}</div>
                    </div>
                    <div style={{ marginBottom: 10 }}>姓名：{this.state.order.username}</div>
                    <div style={{ marginBottom: 10 }}>电话：{this.state.order.phone}</div>
                    <div style={{ marginBottom: 10 }}>地址：{this.state.order.address}</div>
                  </div>
                </Panel>
              ) : null}

              {/* 修改快递单号弹窗 */}
              <ChangeOrderNoModal
                ref='changeOrderNoRef'
                expressnum={this.state.order.expressnum}
                handleChangeOrderNo={this.handleChangeOrderNo.bind(this)}
              />
              <Panel>
                <PanelTitle>订单信息</PanelTitle>
                <div style={{ padding: '10px 20px', color: '#333' }}>
                  <div style={{ marginBottom: 10 }}>订单状态：{this.state.order.status_content}</div>
                  <div style={{ marginBottom: 10 }}>订单时间：{this.state.order.createtime}</div>
                  {Number(this.state.order.shipping_mode) === 1 ? (
                    <div style={{ marginBottom: 10 }}>物流公司：{this.state.order.expressname}</div>
                  ) : (
                    ''
                  )}
                  {Number(this.state.order.shipping_mode) === 1 ? (
                    <div style={{ marginBottom: 10 }}>
                      快递单号：{this.state.order.expressnum}{' '}
                      {([2, 4].includes(this.state.order.status) && (
                        <span className='change-btn' onClick={this.showChangeModal.bind(this)}>
                          修改快递单号
                        </span>
                      )) ||
                        null}
                    </div>
                  ) : (
                    ''
                  )}
                  {Number(this.state.order.shipping_mode) === 3 && this.state.dispatch && (
                    <div style={{ marginBottom: 10 }}>配送状态：{this.state.dispatch.showText}</div>
                  )}
                  {this.state.order.lasttuan_shopinfo && this.state.order.lasttuan_shopinfo.logo ? (
                    <div style={{ marginBottom: 10 }}>
                      <span>订单来源：</span>
                      <img
                        alt=''
                        src={this.state.order.lasttuan_shopinfo.logo}
                        style={{ width: 36, height: 36, borderRadius: '50%', marginRight: 12 }}
                        className='img-center'
                      />
                      <span>{this.state.order.lasttuan_shopinfo.name}</span>
                    </div>
                  ) : null}
                  {this.state.order.sale_from_info ? (
                    <div style={{ marginBottom: 10 }}>
                      <span>订单来源：</span>
                      <img
                        alt=''
                        src={this.state.order.sale_from_info.shop_logo}
                        style={{ width: 36, height: 36, borderRadius: '50%', marginRight: 12 }}
                        className='img-center'
                      />
                      <span>{this.state.order.sale_from_info.shop_name}</span>
                      {this.state.order.sale_from_info.branch_name.length && (
                        <span
                          style={{
                            display: 'inline-block',
                            border: '1px #FF9423 solid',
                            borderRadius: 2,
                            color: '#FF9423',
                            fontWeight: 'normal',
                            padding: '0 4px',
                            fontSize: 12,
                            marginLeft: 12,
                          }}
                        >
                          {this.state.order.sale_from_info.branch_name}
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
              </Panel>
              {this.state.expressData && this.state.expressData.data ? (
                <Panel>
                  <PanelTitle>物流信息</PanelTitle>
                  <div style={{ padding: '10px 20px', color: '#333', maxHeight: 200, overflowY: 'auto' }}>
                    {this.state.expressData.data &&
                      this.state.expressData.data.map((item, idx, arr) => {
                        return (
                          <div key={idx} className='fcc' style={{ alignItems: 'flex-start' }}>
                            <div style={{ color: '#999', marginRight: 12 }}>{item.time}</div>
                            <div
                              style={{
                                flex: 1,
                                color: idx === 0 ? 'var(--theme-main)' : '#999',
                                borderLeft: '1px solid #999',
                                paddingLeft: 12,
                                paddingBottom: idx === arr.length - 1 ? 0 : 10,
                                position: 'relative',
                              }}
                            >
                              {item.context}
                              {idx > 0 ? (
                                <div
                                  style={{
                                    position: 'absolute',
                                    width: 8,
                                    height: 8,
                                    top: 8,
                                    left: -4,
                                    borderRadius: '50%',
                                    background: '#999',
                                  }}
                                ></div>
                              ) : (
                                ''
                              )}
                              {idx === 0 ? (
                                <div
                                  style={{
                                    position: 'absolute',
                                    width: 18,
                                    height: 18,
                                    top: 0,
                                    left: -9,
                                    borderRadius: '50%',
                                    background: 'var(--theme-main)',
                                    color: 'white',
                                    lineHeight: 1,
                                  }}
                                  className='fcc'
                                ></div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Panel>
              ) : null}
              {this.state.no_right_to_query_express_records && (
                <Panel>
                  <PanelTitle>物流信息</PanelTitle>
                  <div className='flex-center' style={{ padding: '10px 20px', color: '#333', height: 200 }}>
                    <SolidRed
                      title='免费的消费者快递物流通知及查询次数已用完，须升级版本才可增加查询次数。'
                      onClick={() => {
                        VIPPower({});
                      }}
                    >
                      去升级
                    </SolidRed>
                  </div>
                </Panel>
              )}
              {this.state.order.sign && this.state.order.sign.length ? (
                <Panel>
                  <PanelTitle>用户填写信息</PanelTitle>
                  <div>
                    <div style={{ padding: '10px 20px', color: '#333' }}>
                      {this.state.order.sign ? (
                        <table
                          style={{
                            borderSpacing: '0 30px',
                            width: '100%',
                          }}
                        >
                          <thead>
                            <tr>
                              <td style={{ width: '25%' }}>信息名</td>
                              <td style={{ width: '75%' }}>信息</td>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.order.sign.map((item) => {
                              // console.log(item.type,item)
                              return (
                                <tr key={item.id} valign='top'>
                                  <td>{item.name}</td>
                                  <td>
                                    {Number(item.type) === 1 ? (
                                      <div>
                                        <div>{item.value[0].content}</div>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {Number(item.type) === 2 ? (
                                      <div>
                                        {item.value.map((c, index) => {
                                          return <div key={index}>{c.content}</div>;
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {Number(item.type) === 3 ? <Audio src={item.value[0].src} /> : ''}
                                    {Number(item.type) === 4 ? <NinePic list={item.value} /> : ''}
                                    {Number(item.type) === 5 ? (
                                      <div>
                                        <div>{item.value.name}</div>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {Number(item.type) === 6 ? (
                                      <div>
                                        <div>{item.value[0].content}</div>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Panel>
              ) : null}

              <Panel>
                <PanelTitle>商品信息</PanelTitle>
                <div style={{ padding: '10px 0', color: '#333' }}>
                  <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <tbody>
                      {this.state.order.items.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td className='orderItem__goodName'>
                              <div className='fcc'>
                                <div>
                                  <img alt='' className='img-center' src={item.logo + '?imageView2/5/w/200'} />
                                </div>
                                <div style={{ wordBreak: 'break-all' }}>
                                  <span>{item.name}</span>
                                  <span>({item.unit})</span>
                                </div>
                              </div>
                            </td>
                            <td className='orderItem__price'>
                              <div className='fcc'>￥{(item.price / 100).toFixed(2)}</div>
                            </td>
                            <td className='orderItem__num'>
                              <div className='fcc'>x{item.num}</div>
                            </td>

                            {index === 0 && (
                              <td className='orderItem__totalPrice' rowSpan={this.state.order.items.length}>
                                <div>共{this.state.order.items.length}件</div>
                                <div>应付金额：￥{(this.state.order.totalprice / 100).toFixed(2)}</div>
                                {(() => {
                                  if ([0, 100].includes(Number(this.state.order.discount))) return null;
                                  const discount = Number(this.state.order.discount) / 10 + '折';
                                  const dp = (Number(this.state.order.discount_price_after) / 100).toFixed(2);
                                  return (
                                    <div>
                                      会员优惠（{discount}）：<span style={{ display: 'inline-block' }}>-￥{dp}</span>
                                    </div>
                                  );
                                })()}
                                {this.state.order.couponlog_price ? (
                                  <div>
                                    活动惊喜：
                                    <span style={{ display: 'inline-block' }}>-￥{(Number(this.state.order.couponlog_price) / 100).toFixed(2)}</span>
                                  </div>
                                ) : null}
                              </td>
                            )}

                            {index === 0 && (
                              <td className='orderItem__totalPrice' rowSpan={this.state.order.items.length}>
                                <div>实付金额</div>
                                <div style={{ color: 'var(--theme-main)' }}>￥{(Number(this.state.order.payprice) / 100).toFixed(2)}</div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan='5' style={{ padding: '8px 20px' }}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <span style={{ padding: '4px 11px 4px 0', minWidth: '4em' }}>买家备注：</span>
                            <span style={{ display: 'inline-block', height: '100%', padding: ' 4px 11px 4px 0px' }}>
                              <span style={{ maxWidth: '70%', wordBreak: 'break-all' }}>{this.state.order.remark}</span>
                              {/* <i className='icon-xiugai iconfont' onClick={()=>{this.setState({setRemark: true})}} style={{marginLeft: 20}}></i> */}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan='5' style={{ padding: '8px 20px', borderBottom: 0 }}>
                          {this.state.order.mch_remark ? <div className='orderItem__mchRemark'>卖家备注：{this.state.order.mch_remark}</div> : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Panel>
            </div>
          ) : null}
        </OrderMain>
        {this.state.ship ? (
          <Modal title='请填写发货信息' close={this.close.bind(this)} cancel={true} confirm={this.sendExpress.bind(this)}>
            <div id='shipPanel'>
              <div style={{ maxWidth: 500 }}>
                <div className='title'>购买人信息</div>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top' }}>
                    <div style={{ fontSize: 14 }}>收货人</div>
                    <div style={{ fontSize: 14 }}>{this.state.order.username}</div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top' }}>
                    <div style={{ fontSize: 14, minWidth: '4em' }}>电 话</div>
                    <div style={{ fontSize: 14 }}>{this.state.order.phone}</div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top' }}>
                    <div style={{ fontSize: 14, minWidth: '4em' }}>地 址</div>
                    <div style={{ fontSize: 14 }}>{this.state.order.address}</div>
                  </div>
                </div>
              </div>
              <form style={{ maxWidth: 500 }}>
                <table style={{ tableLayout: 'fixed', width: '100%' }}>
                  <thead>
                    <tr>
                      <td style={{ width: '25%' }}>立即发货</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ marginTop: 14 }}>
                      <td style={{ height: 30 }}>
                        <div style={{ fontSize: 14 }}>快递公司</div>
                      </td>
                      <td style={{ fontSize: 14 }}>
                        <Select
                          uni='expressCompany'
                          list={this.state.expressList}
                          showkey='name'
                          showValue='name'
                          maxHeight={200}
                          placeholder='请选择快递公司'
                          hovorClass='select-hover'
                          change={this.setExperss.bind(this)}
                        ></Select>
                      </td>
                    </tr>
                    <tr style={{ marginTop: 14 }}>
                      <td style={{ height: 30 }}>
                        <div style={{ fontSize: 14 }}>快递单号</div>
                      </td>
                      <td style={{ fontSize: 14 }}>
                        <input
                          onChange={this.setExpressnum.bind(this)}
                          value={this.expressNum}
                          style={{
                            fontSize: 14,
                            padding: '4px 11px',
                            width: '100%',
                            outline: 'none',
                            borderRadius: 5,
                            border: '1px solid rgb(217,217,217)',
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </Modal>
        ) : null}

        {this.state.showExchange ? (
          <Checkin
            order={this.state.order}
            onClose={(e) => {
              this.setState({ showExchange: false });
              if (e.refresh) {
                this.props.history.push('/refresh');
                this.bc && this.bc.postMessage('reload');
              }
            }}
          />
        ) : null}

        {this.state.showRefund ? (
          <Refund
            order={this.state.order}
            refundList={this.state.refundList}
            refundState={this.state.refundState}
            onClose={(e) => {
              this.setState({ showRefund: false });
              if (e && e.shouldReflesh) {
                this.props.history.push('/refresh');
                this.bc && this.bc.postMessage('reload');
              }
            }}
          />
        ) : null}

        {this.state.initiativeRefund ? (
          <ActRefund
            order={this.state.order}
            isTop={this.state.order.is_top}
            onClose={() => {
              // this.setState({ initiativeRefund: false, mode_list: null })
              this.props.history.push('/refresh');
              this.bc && this.bc.postMessage('reload');
            }}
            modelist={this.state.mode_list}
          />
        ) : null}
      </div>
    );
  }

  riderInterval;

  getDetail() {
    // return
    const orderid = window.btoa(this.props.match.params.id);

    get('/order/detail', { orderid })
      .then((res) => {
        if (this.state.order) {
          this.bc && this.bc.postMessage('reload');
        }
        const item = { ...res };
        this.setState({
          order: item,
          shouldLoad: false,
        });
        if (Number(item.shipping_mode) === 3) {
          const riderStatusText = (t) => {
            switch (t) {
              case 0:
                return '预发布';
              case 1:
                return '待接单';
              case 2:
                return '待取货';
              case 100:
                return '已到店';
              case 3:
                return '配送中';
              case 4:
                return '已完成';
              case 5:
                return '已取消';
              case 7:
                return '已过期';
              case 9:
                return '投递异常返还中';
              case 10:
                return '投递异常返还完成';
              default:
                return '';
            }
          };
          let dispatch = item.dispatch_info;
          dispatch.showText = riderStatusText(Number(item.dispatch_info.status));
          this.setState({ dispatch });
        }
        const ref = { ...item.refund_info };

        Object.keys(ref).map((item) => {
          const data = { ...ref[item], refund_time: undefined };
          delete data.refund_time;
          return data;
        });
        if (item.type !== 'hd' && Number(item.shipping_mode) === 1) {
          if (Number(item.status) === 2) {
            if (item.member_auth !== false) {
              get('/express/query', { id: item.express_id, num: item.expressnum.trim(), types: 1 })
                .then((res) => {
                  this.setState({ expressData: res, no_right_to_query_express_records: false });
                })
                .catch((e) => {
                  this.setState({ no_right_to_query_express_records: true });
                });
            }
          } else {
            api('express', 'getExpressCompany')().then((res) => {
              this.setState({
                expressList: res.data.data,
              });
            });
          }
        } else if (Number(item.shipping_mode) === 2) {
          get('/address/getone', {
            types: 1,
            id: window.btoa(item.addressid),
          }).then((res) => {});
        }

        if (Number(item.shipping_mode) === 3) {
          // 获取取消配送理由
          get('/dispatch/cancelreasons', {}).then((res) => {
            this.setState({
              cancelreasons: res,
            });
          });

          // 加载地图API
          function loadScript() {
            if (!item.dispatch_info || !item.dispatch_info.rider_lng || !item.dispatch_info.rider_lat) return;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${QQ_MAP_KEY}`;
            script.onload = function () {
              function loadmap() {
                const TMap = window.TMap;
                if (!TMap) {
                  setTimeout(loadmap.bind(this), 2000);
                  return;
                }
                console.log('loadMap');
                const center = new TMap.LatLng(item.dispatch_info.rider_lat, item.dispatch_info.rider_lng);
                const map = new TMap.Map(document.getElementById('map-container'), {
                  center,
                  zoom: 14,
                });
                const rider = `https://jieligo-1259536745.picgz.myqcloud.com/uploads/goods/299f9XY2CJ.png?imageView2/5/w/300`;
                const target = 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/goods/0bw9dRwvsw.png?imageView2/5/w/300';
                const marker = new TMap.MultiMarker({
                  map,
                  styles: {
                    //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                    rider: new TMap.MarkerStyle({
                      width: 45, // 点标记样式宽度（像素）
                      height: 45, // 点标记样式高度（像素）
                      src: rider, //图片路径
                    }),
                    target: new TMap.MarkerStyle({
                      width: 30,
                      height: 40,
                      src: target,
                    }),
                  },
                  geometries: [
                    {
                      id: 1,
                      styleId: 'rider',
                      position: center,
                    },
                    {
                      id: 2,
                      styleId: 'target',
                      position: new TMap.LatLng(item.dispatch_info.receiver_lat, item.dispatch_info.receiver_lng),
                    },
                  ],
                });
                console.log(marker);
              }
              loadmap.call(this);
            }.bind(this);
            document.body.appendChild(script);
          }

          if (item.dispatch_info && Number(item.dispatch_info.status) !== 4) {
            loadScript.call(this);
          }
        }

        // 未开审核 --is_refund_review==flase---走旧流程
        const istop = Number(this.state.order.is_top);
        if (Number(this.state.order.distribution_order_refund_review) === 0 || this.state.order.is_refund_review === false) {
          //判断是否一级
          if (istop === 1) {
            this.setState({
              refundState: {
                process: 'old',
                top: istop,
              },
            });
            //不是一级
          } else {
            this.setState({
              refundState: {
                process: 'old',
                top: istop,
              },
            });
          }
          //开审核
        } else {
          //判断是否一级
          if (istop === 1) {
            // 未审批
            if (this.state.order.is_refund_review === 0) {
              // 旧流程 confirmrefund
              this.setState({
                refundState: {
                  process: 'old',
                  top: istop,
                },
              });
            }
            //不是一级
          } else {
            // 未审批
            if (this.state.order.is_refund_review === 0) {
              // 新流程 reviewrefundapply
              this.setState({
                refundState: {
                  process: 'new',
                  top: istop,
                },
              });
            }
          }
        }

        const nowRefundData = this.state.order.refund_info.find((i) => String(i.refund_id) === String(this.state.order.now_refund_id));
        if (nowRefundData) {
          if (nowRefundData.review_log_list) {
            const review_log_list = nowRefundData.review_log_list;
            this.setState({
              refundList: [review_log_list],
            });
          }
        }
      })
      .catch((e) => {
        this.props.history.push('/orders/q');
        return;
      });
  }
}

const mapStateToProps = (state) => {
  return {
    waitingLogin: state.waitingLogin,
    is_Login: state.is_Login,
  };
};

export default connect(mapStateToProps)(Main(withRouter(detail, false)));
