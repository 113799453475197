import React, { Component } from 'react';

import api from '../../../netApi';

import { Pagination, message } from 'antd';

import Ban from '../../../high-component/BanUnLogin';
import Content from '../../../high-component/Content';

// import { OFFSET_LIMIT } from '../../../data/options';

import Control from './Control';
import Good, { FakeGood } from '../../total/good/GoodItem';
// import Cate from '../../total/good/Category';
import Modal from '../../total/Modal';

import locale from 'antd/es/date-picker/locale/zh_CN';
import { get } from '../../../netApi/fetch';

const OFFSET_LIMIT = 20

class Goodslist extends Component {

    constructor() {
        super()
        document.title = 'alibaba'
        this.state = {
            keyWords: '',
            offerIds: '',
            goodlist: [],
            page: 1,
            themeList: [],
            selectedThemeId: null,
        }
    }

    componentDidMount() {
        if (this.props.didLogin) {
            this.init()
        }
    }

    componentDidUpdate(prev) {
        if (!prev.didLogin && this.props.didLogin) {
            this.init()
        }
    }

    componentWillUnmount() {
        document.title = '接力GO'
    }

    render() {
        return <div style={{ padding: '1rem' }}>
            <Control clear={this.clear.bind(this)} onSearch={this.search.bind(this)} onSearchId={this.search.bind(this)} onPriceChange={this.priceSetting.bind(this)} onOptionsChange={(e) => {
                this.options = e
                this.setState({
                    count: 0,
                    inited: false,
                    page: 1,
                }, () => {
                    this.getList()
                })
            }} />
            <div className='aliGoodList'>
                {!this.state.inited ? [0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5].map((i, index) => <FakeGood key={index} />) : null}
                {this.state.goodlist.map(i => {
                    return <Good
                        key={i.offerId} alienable={i.enable}
                        channelPrice={i.channelPrice}
                        name={i.title} logo={i.imgUrl} tags={i.offerTags} price={i.currentPrice} salenum={i.soldOut} superBuyerPrice={i.superBuyerPrice}
                        id={i.offerId} onClick={this.addGood.bind(this)} />
                })}

                {/* 占位 */}
                <div style={{ width: 215 }}></div>
                <div style={{ width: 215 }}></div>
                <div style={{ width: 215 }}></div>
                <div style={{ width: 215 }}></div>
                <div style={{ width: 215 }}></div>
                {/* 占位 */}
            </div>

            <Pagination current={this.state.page} pageSizeOptions={[]} showQuickJumper={true} locale={locale} total={this.state.count} pageSize={OFFSET_LIMIT} onChange={e => {
                this.setState({ page: e, inited: false }, () => {
                    this.getList()
                })
            }} />

            {/* 分类选择 */}
            {this.state.showCateEdit ? (
                <Modal loading={this.state.addLoading} title='选择添加到专题' close={() => { this.setState({ showCateEdit: false }) }} confirm={() => {
                    this.addCateGood(this.selectedId, this.state.selectedThemeId)
                }}  >
                    <div style={{ overflow: 'overlay', maxHeight: 350 }}>
                        {[...this.state.themeList].map(i => {
                            return (<div key={i.id} className='cur-pointer flex-box' style={{ padding: '4px 11px', width: 500 }} onClick={() => {
                                this.setState({
                                    selectedThemeId: i.id,
                                })
                            }} >
                                <i className={`iconfont ${i.id === this.state.selectedThemeId ? 'icon-gou' : 'icon-yuan'}`} style={{
                                    color: i.id === this.state.selectedThemeId ? '#fa503c' : '#ccc',
                                    marginRight: 6,
                                }} />
                                <img alt='' src={i.logo} style={{ width: 36, height: 36, marginRight: 6 }} />
                                <div>
                                    {i.theme_name}
                                </div>
                            </div>)
                        })}
                    </div>
                </Modal>
            ) : null}
        </div>
    }

    init() {
        this.getList()
        this.getCategory()
    }

    addGood(id) {
        this.selectedId = id
        this.setState({
            showCateEdit: true
        })
    }

    addCateGood(id, theme_id) {
        this.setState({
            addLoading: true
        })
        api('alibaba', 'createalibabagoods')({}, 'post', {
            offerId: id,
            types: 1,
            theme_id,
        }).then(res => {
            this.setState({
                showCateEdit: false,
                addLoading: false,
            })
            if (res.data.code !== 0) {
                return
            }
            message.success('添加成功')
        })
    }

    search(keyWords, offerIds) {
        // console.log(this)
        this.priceStart = undefined
        this.priceEnd = undefined
        console.log(keyWords, offerIds);
        this.setState({
            keyWords: keyWords,
            page: 1,
            inited: false,
            count: 0,
            offerIds,
        }, () => {
            this.getList()
        })
    }

    priceSetting(start, end) {
        this.priceStart = start
        this.priceEnd = end
        this.setState({ page: 1, inited: false, count: 0 }, () => {
            this.getList()
        })
    }

    getList() {
        this.setState({
            goodlist: [],
            loading: true,
        })
        api('alibaba', 'searchcyb')({}, 'post', {
            types: 1,
            page: this.state.page,
            pageSize: OFFSET_LIMIT,
            keyWords: this.state.keyWords,
            offerIds: this.state.offerIds,
            priceStart: this.priceStart,
            priceEnd: this.priceEnd,
            ...this.options,
        }).then(res => {
            this.setState({ inited: true })
            if (res.data.code !== 0) return
            window.scrollTo({
                top: 1000,
            });
            const data = res.data.data
            this.setState({
                goodlist: data.list,
                count: data.count,
            })
        })
    }

    clear() {
        this.options = {}
        this.priceStart = undefined
        this.priceEnd = undefined
        this.setState({ keyWords: '', inited: false, }, () => {
            this.getList()
        })
    }

    getCategory() {
        get('/tuan/themelist', { offset: 0, limit: 100000 }).then(res => {
            console.log(res);
            this.setState({
                themeList: res.list,
            })
        })
        // api('goodscategory', 'getall')({
        //     sid: window.btoa(this.props.user.self_sid), types: 1
        // }).then(res => {
        //     this.setState({ categorylist: res.data.data })
        // })
    }
}

export default Content(Ban(Goodslist))