import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Value = styled.div`
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    @media print {
        font-family: SimHei;
        font-weight: bold;
        color: #000;
    }
`

const Name = styled.div`
    font-size: 14px;
    display: inline-block;
    @media print {
        font-family: SimHei;
        font-weight: bold;
        color: #000;
    }
`

class PrintOrder extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired
    }
    // componentDidMount() {
    //     console.log(this.props.data)
    // }
    render() {
        return (
            <div className='order-print' style={{ pageBreakAfter: 'always' }}>
                <div>
                    <div
                        style={{
                            display: 'inline-block', width: '50%'
                        }}>
                        <Name>接龙号：</Name>
                        <Value className='value'>{this.props.data.buy_no}</Value>
                    </div>
                </div>
                <div>
                    <Name>配送方式：</Name>
                    <Name>
                        {(Number(this.props.data.addressid) === 0 && this.props.data.type !== 'hd')
                            ? '快递配送'
                            : '自提'}
                    </Name>
                </div>
                <div>
                    <Name>订单状态：</Name>
                    <Name>
                        {this.props.data.status_content}
                    </Name>
                </div>
                <div>
                    <Name>购买人昵称：</Name>
                    <Name>
                        {this.props.data.shopinfo.name}
                    </Name>
                </div>
                <div>
                    <Name>收货人：</Name>
                    <Name>
                        {(() => {
                            if (!(Number(this.props.data.addressid) === 0 && this.props.data.type !== 'hd')) {
                                if (Array.isArray(this.props.data.sign)) {
                                    const sign = this.props.data.sign.find(i => Number(i.systemid) === 1)
                                    if (!sign) return this.props.data.username
                                    return sign.value[0].content
                                } else {
                                    if (this.props.data.sign && this.props.data.sign['联系人']) {
                                        return this.props.data.sign['联系人'].value || this.props.data.username
                                    }
                                }
                            }
                            return this.props.data.username
                        })()}
                    </Name>
                </div>
                <div>
                    <Name>地址：</Name>
                    <Name>{this.props.data.address}</Name>
                </div>
                <div>
                    <Name>联系电话：</Name>
                    <Name>
                        {(() => {
                            if (!(Number(this.props.data.addressid) === 0 && this.props.data.type !== 'hd')) {
                                if (Array.isArray(this.props.data.sign)) {
                                    const sign = this.props.data.sign.find(i => Number(i.systemid) === 2)
                                    if (!sign) return this.props.data.phone
                                    return sign.value[0].content
                                } else {
                                    if (this.props.data.sign && this.props.data.sign['联系方式']) {
                                        return this.props.data.sign['联系方式'].value || this.props.data.phone
                                    }
                                }
                            }
                            return this.props.data.phone
                        })()}
                    </Name>
                </div>
                {(() => {
                    if (Array.isArray(this.props.data.sign)) {
                        return this.props.data.sign.map(i => {
                            if (Number(i.systemid) !== 0) return null
                            if (Number(i.type) !== 1) return null
                            return < div key={i.id}>
                                <Name>{i.name}：</Name>
                                <Name>{i.value[0].content}</Name>
                            </ div>
                        })
                    } else {
                        for (const key in this.props.data.sign) {
                            const arr = []
                            if (this.props.data.sign.hasOwnProperty(key)) {
                                const sign = this.props.data.sign[key];
                                // if (Number(sign.systemid) !== 0) return null
                                if (Number(sign.type) !== 1) return null
                                arr.push(sign)
                            }
                            return arr.map((i, idx) => {
                                return <div key={idx}>
                                    <Name>{i.name}：</Name>
                                    <Name>{i.value}</Name>
                                </ div>
                            })
                        }
                    }
                })()}
                <div>
                    <Name>买家备注：</Name>
                    <Name>{this.props.data.remark}</Name>
                </div>
                <hr />
                <div>
                    <Name>下单时间：</Name>
                    <Name>{this.props.data.createtime}</Name>
                </div>
                <div>
                    <Name>订单号：</Name>
                    <Name>{this.props.data.payorderid}</Name>
                </div>
                <hr />
                <table>
                    <thead>
                        <tr>
                            <td><Name>品名/规格</Name></td>
                            <td><Name>总数</Name></td>
                            <td><Name>小计</Name></td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.items.map((item) => {
                            return (
                                <tr style={{ borderBottom: '1px solid #ddd' }} key={item.id}>
                                    <td>
                                        <div style={{ padding: '0' }}>
                                            <Name style={{ display: 'inline' }}>{item.name}</Name>
                                            <Name style={{ display: 'inline' }}> 【{item.unit}】</Name>
                                        </div>
                                    </td>
                                    <td>
                                        <Name>{item.num}</Name>
                                        {(item.checkin_num && Number(item.checkin_num) > 0)
                                            ? <div style={{ fontSize: 12 }}>【已{Number(this.props.data.addressid) !== 0 ? '核销': '发货'}&nbsp;{item.checkin_num}】</div>
                                            : null}
                                        {(item.refund_num && Number(item.refund_num) > 0)
                                            ? <div style={{ fontSize: 12 }}>【已退款&nbsp;{item.refund_num}】</div>
                                            : null}
                                    </td>
                                    <td><Name>{((Number(item.price) * Number(item.num)) / 100).toFixed(2)}</Name></td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan='3'>
                                <Name>
                                    共{this.props.data.items.reduce((count, item) => count + Number(item.num), 0)}件，合计￥{(this.props.data.totalprice / 100).toFixed(2)}
                                </Name>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <Name>备注：</Name>
                    <Name>{this.props.data.mch_remark}</Name>
                </div>
                <div style={{ margin: '1em 0' }}>
                    =======分割线========
                </div>
            </div>
        )
    }
}

export default PrintOrder
