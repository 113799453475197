import React, { Component } from 'react';
import api from '../../../netApi';
import Ban from '../../../high-component/BanUnLogin';
import Content from '../../../high-component/Content';

import Item from './Item';
class List extends Component {

    constructor(){
        super()
        this.state = {
            orderList: []
        }
        document.title = '1688退款列表'
    }

    componentDidMount() {
        if (this.props.didLogin) {
            this.init()
        }
    }

    componentDidUpdate(prev) {
        if (!prev.didLogin && this.props.didLogin) {
            this.init()
        }
    }

    componentWillUnmount(){
        document.title = '接力GO'
    }

    render(){
        return <div className='aliRefund'>
            
            {/* 退款订单 */}
            <div className='aliRefundList'>
                {this.state.orderList.map(i => {
                    return <Item key={i.alipayPaymentId} data={i} />
                })}
            </div>
        </div>
    }

    init(){
        this.getList()
    }

    getList(){
        api('alibaba', 'getrefundlist')({}, 'post', {
            types: 1
        }).then(res => {
            if(res.data.code !== 0)return
            const data = res.data.data
            this.setState({
                totalCount: data.totalCount,
                orderList: data.opOrderRefundModels,
            })
            
        })
    }   
}

export default Content(Ban(List))
