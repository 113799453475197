import React, { Component } from 'react'
import { RouteComponentProps, match } from 'react-router-dom'
import { getToken } from '../../netApi/fetch';
import styled from 'styled-components';
import { message } from 'antd';
import { API } from '../../data/options';
interface props extends RouteComponentProps {
    match: Match
}

interface Match extends match {
    params: {
        query: string
    }
}
interface state {
    ready: boolean,
    loadingPoint: number
}

const Shadow = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #00000066;
`

const Content = styled.div`
    width:326px;
    height:128px;
    background:rgba(255,255,255,1);
    border-radius:6px;
    flex-direction: column;
`

class Export extends Component<props>{

    readonly state: state = {
        ready: false,
        loadingPoint: 3,
    }

    componentDidMount() {
        const query: { [any: string]: string } = {}
        this.props.match.params.query.split('&').forEach(i => {
            const data = i.split('=')
            query[data[0]] = data[1]
        })
        query['token'] = getToken()
        console.log(query);
        this.download()
    }

    download() {
        this.setState({ ready: false })
        if (this.interval) {
            console.log(this.interval);
            message.warning('正在下载，请稍候')
            return
        }
        const href = API + '/pcorder/orderList?' + this.props.match.params.query + '&token=' + getToken()
        console.log('fetch start');
        this.interval = setInterval(() => {
            console.log(this.state.loadingPoint);
            this.setState({ loadingPoint: this.state.loadingPoint % 3 + 1 })
        }, 500)
        fetch(href, {
            mode: 'cors',
            method: 'GET',
            credentials: 'include',
        }).then(res => {
            console.log('fetch get')
            const ContentType = res.headers.get('content-type')
            if (ContentType?.slice(0, 4) === 'text') {
                res.json().then(data => {
                    
                    message.error(data.message, 0)
                    this.setState({ ready: true })
                })
                return
            }
            let filename = res.headers.get('Content-Disposition')?.split('=')[(res.headers.get('Content-Disposition')?.split('=').length || 1) - 1] || ''
            filename = filename.split('').filter(i => {
                return /[0-9:：:xlsx..-_-]/.test(i)
            }).join('')
            console.log(filename);
            res.blob().then(blob => {
                if (!!window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, filename);
                    return
                }
                const a = document.createElement('a');
                document.body.appendChild(a) //兼容火狐，将a标签添加到body当中
                const url = window.URL.createObjectURL(blob);   // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
                a.href = url;
                a.download = filename
                a.click();
                a.remove()  //移除a标签
                this.setState({ ready: true })
                window.URL.revokeObjectURL(url);
                clearInterval(this.interval)
                this.interval = 0
                this.setState({ loadingPoint: 3 })
            })
        })
    }

    interval: any = 0

    render() {
        return (
            <div>
                <Shadow className='fcc'>
                    <Content className='fcc'>
                        {this.state.ready
                            ? <>
                                <i className='icon-gouxuan iconfont' style={{ color: 'var(--theme-main)', lineHeight: 1, fontSize: 32, marginBottom: 14 }} onClick={this.state.ready ? this.download.bind(this) : () => { }} />
                                <div style={{ color: '#333', fontSize: 14 }}>
                                    表格导出成功，请在电脑找到并打开表格即可
                                </div>
                            </>
                            : <>
                                <img alt='' style={{ width: 32, height: 32, marginBottom: '1em' }} title='重新下载' src='https://jieligo-1259536745.image.myqcloud.com/uploads/tuan/irtbUwBOtR.gif' onClick={this.state.ready ? this.download.bind(this) : () => { }} />
                                <div style={{ color: '#333', fontSize: 14 }}>
                                    接力GO正在生成表格中，请耐心等待
                                {this.state.loadingPoint > 0 ? <span>.</span> : null}
                                    {this.state.loadingPoint > 1 ? <span>.</span> : null}
                                    {this.state.loadingPoint > 2 ? <span>.</span> : null}
                                </div>
                            </>
                        }
                    </Content>
                </Shadow>
            </div>
        )
    }
}

export default (Export)