import React, { useState, useEffect, useRef } from 'react';

import Modal from '../total/Modal';
import { distributionSetting } from './MySeller';

import { get, post } from '../../netApi/fetch';
import styled from 'styled-components';
import Switch from '../total/Switch';
import { message } from 'antd';

import { GroupItem } from './Level';

interface Props {
    setting?: distributionSetting,
    onClose: () => void,
    refresh: () => void,
    openLevel: () => void,
}

const Warp = styled.div`
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
`
const Gray = styled.div`
    padding: 16px;
    background:#f8f8f8;
    margin-bottom: 26px;
    &:last-child{
        margin-bottom: 0;
    }
`
const Item = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding:  16px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &:last-child{
        padding-bottom: 0;
        border: 0;
    }
    &:first-child{
        padding-top: 0;
    }
    &>div{
        &:nth-child(2){
            flex: 1;
            &>div{
                &:nth-child(1){
                    font-size: 14px;
                    color: #353535;
                }
                &:nth-child(2){
                    font-size: 12px;
                    color: #999;
                    max-width: 360px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        &:nth-child(1){
            margin-right: 16px;
        }
    }
`

export default function (props: Props) {
    const [canCancel, setCanCancel] = useState(true)
    const [page, setPage] = useState(1)
    const [is_need_apply, setApply] = useState(props.setting?.is_need_apply || false)
    const [is_allow_recommend_seller, setAllow] = useState(props.setting?.is_allow_recommend_seller || false)
    const [unified_price_commission_percent, setUnified] = useState(props.setting?.unified_price_commission_percent || '')
    const [self_define_price_commission_percent, setSelfDefind] = useState(props.setting?.self_define_price_commission_percent || '')
    const [selectedGroups, setGroups] = useState((): Set<number> => {
        let s: Set<number> = new Set([-1])
        if (props.setting && props.setting.can_recommend_team.length > 0) {
            s = new Set(props.setting.can_recommend_team.map(i => Number(i)))
        }
        return s
    })
    const [groups, setAllGroups] = useState<Array<GroupItem>>()
    const [loading, setLoading] = useState(false)

    const reloadFlag = useRef(false)

    useEffect(() => {
        if (!groups) {
            setLoading(true)
            get('/seller/getteamlist', {
                types: 1,
                offset: 0, limit: 99,
            }).then((res: any) => {
                setAllGroups(res.list)
                setLoading(false)
            }).catch((e) => {
                setLoading(false)
                if (e.message) message.error(e.message)
            })
        }
    }, [groups])

    useEffect(() => {
        const inter = setInterval(() => {
            const DIV = document.getElementById('level-setting')
            if (DIV) {
                reloadFlag.current = true
            }
            if (reloadFlag.current && !DIV) {
                reloadFlag.current = false
                get('/seller/getteamlist', {
                    types: 1,
                    offset: 0, limit: 99,
                }).then((res: any) => {
                    setAllGroups(res.list)
                    setLoading(false)
                }).catch((e) => {
                    setLoading(false)
                    if (e.message) message.error(e.message)
                })
            }
        }, 400)
        return () => {
            clearInterval(inter)
        }
    })

    function confirm() {
        if (page !== 1) {
            setCanCancel(false)
        }
        if (page === 2) {
            setPage(1)
            setCanCancel(true)
        }
        if (page === 1) {
            if (is_allow_recommend_seller) {
                if (!unified_price_commission_percent) {
                    message.warning('请输入返佣比例')
                    return
                }
                if (!self_define_price_commission_percent) {
                    message.warning('请输入返佣比例')
                    return
                }
            }
            post('/seller/setconfig', {
                types: 1,
                is_need_apply,
                is_allow_recommend_seller,
                can_recommend_team: Array.from(selectedGroups),
                unified_price_commission_percent,
                self_define_price_commission_percent,
            }).then(() => {
                message.success('设置成功')
                props.refresh()
                props.onClose()
            }).catch((e) => {
                if (e.message) message.error(e.message)
            })
        }
    }

    const title: React.ReactElement = (() => {
        if (page === 1) {
            return <div>设置帮卖好友等级</div>
        }
        if (page === 2) {
            return <div className='flex-box cur-pointer' onClick={() => {
                setPage(1)
                setCanCancel(true)
            }}>
                <div><i className='iconfont icon-arrow-fine-left' style={{ fontWeight: 600, marginRight: 16 }} /></div>
                <div>返回</div>
            </div>
        }
        return <></>
    })()
    return <Modal loading={loading} banMaskClose={false} banAnime title={title} cancel={canCancel} onConfirm={confirm} onClose={() => { props.onClose() }} >
        <div className='hide-scrollbar' style={{
            width: 430, position: "relative", height: '60vh', overflowY: 'auto',
        }}>
            <div className='hide-scrollbar' style={{
                position: 'relative', left: page === 1 ? 0 : -430, transition: 'all .3s', height: '100%', overflowY: 'auto', background: '#fff', zIndex: 10
            }}>
                <SwitchItem text='需要申请才能成为帮卖好友' onChange={() => { setApply(!is_need_apply) }} checked={is_need_apply} />
                <Warp style={{ paddingBottom: 0 }}>
                    <div className=' flex-between' style={{ margin: '0px 0 20px', }}>
                        <div className=' flex-between'>允许帮卖好友帮我推荐</div>
                        <Switch checked={is_allow_recommend_seller} onClick={() => { setAllow(!is_allow_recommend_seller) }} />
                    </div>
                    <div style={{ overflowY: "hidden", transition: 'max-height .3s', maxHeight: is_allow_recommend_seller ? '60vh' : 0 }}>
                        <Gray className="flex-between" >
                            <div>谁可以帮我推荐好友</div>
                            <div className='cur-pointer flex-between' style={{ color: 'var(--theme-main)' }} onClick={() => { setCanCancel(false); setPage(2) }} >
                                <div>
                                    {((): string => {
                                        if (groups) {
                                            if (selectedGroups.has(-1)) {
                                                return '所有帮卖好友'
                                            }
                                            if (groups.length >= selectedGroups.size) {
                                                return `已选择${selectedGroups.size}组好友`
                                            }
                                        }
                                        return '所有帮卖好友'
                                    })()}
                                </div>
                                <i className='iconfont icon-you' style={{ lineHeight: 1 }} />
                            </div>
                        </Gray>
                        <Gray>
                            <div style={{ borderBottom: '1px solid #ccc', padding: '16px 0' }}>
                                <div>设置好友推荐返佣</div>
                                <div>（按被推荐的好友销售额/拿货金额计算）</div>
                            </div>
                            <div className='flex-between' style={{ borderBottom: '1px solid #ccc', padding: '16px 0' }}>
                                <div>统一定价的返佣</div>
                                <div>
                                    <input style={{ border: 0, background: 'transparent' }} type='number' placeholder='占销售额的百分比'
                                        value={unified_price_commission_percent} onChange={e => setUnified(Number(e.target.value))} onBlur={() => {
                                            let num = unified_price_commission_percent
                                            if (num > 100) num = 100
                                            setUnified(Number(num).toFixed(2))
                                        }} />
                                    <span>%</span>
                                </div>
                            </div>
                            <div className='flex-between' style={{ borderBottom: '1px solid #ccc', padding: '16px 0' }}>
                                <div>自由定价的返佣</div>
                                <div>
                                    <input style={{ border: 0, background: 'transparent' }} type='number' placeholder='占拿货金额的百分比'
                                        value={self_define_price_commission_percent} onChange={e => setSelfDefind(Number(e.target.value))} onBlur={() => {
                                            let num = self_define_price_commission_percent
                                            if (num > 100) num = 100
                                            setSelfDefind(Number(num).toFixed(2))
                                        }} />
                                    <span>%</span>
                                </div>
                            </div>
                        </Gray>
                        <div style={{ width: 370, margin: '0 auto', fontSize: 14, color: '#999' }}>
                            <p>温馨提示：</p>
                            <p>1、好友推荐了其他好友帮卖，则可以获取该好友帮卖销售额/供货价的一部分作为TA的推荐佣金，建议该佣金设置为0～5%</p>
                            <p>2、统一定价的活动产生的订单，推荐佣金按好友的销售额的百分比设置</p>
                            <p>3、自由定价的活动产生的订单，推荐佣金按供货销售额的百分比设置</p>
                            <p>4、如无特殊设定，帮卖好友推荐的好友为永久的绑定关系</p>
                        </div>
                    </div>
                </Warp>
            </div>
            <div className='hide-scrollbar'
                style={{
                    boxSizing: 'border-box',
                    position: 'absolute',
                    width: '100%',
                    left: page === 2 && selectedGroups ? 0 : -430, top: 0, transition: 'all .3s', height: '100%', overflowY: 'auto', background: '#fff', zIndex: 9
                }} >
                <div className="cur-pointer" style={{ color: 'var(--theme-main)', marginBottom: 20 }} onClick={() => { props.openLevel && props.openLevel() }}>编辑好友等级</div>
                <Warp>
                    <Item onClick={setGroups.bind(null, new Set([-1]))}>
                        <div>
                            <i className={`iconfont ${selectedGroups.has(-1) ? 'icon-gouxuan' : 'icon-yuan'}`}
                                style={{ color: selectedGroups.has(-1) ? 'var(--theme-main)' : '#eee' }} />
                        </div>
                        <div>
                            <div>所有帮卖好友</div>
                            <div>包括已设置和未设置等级的帮卖好友</div>
                        </div>
                    </Item>
                </Warp>
                <Warp>
                    {(groups || []).map((i: GroupItem) => {
                        return <Item key={i.id} onClick={() => {
                            if (selectedGroups.has(Number(i.id))) {
                                selectedGroups.delete(Number(i.id))
                            } else {
                                selectedGroups.add(Number(i.id))
                                selectedGroups.delete(-1)
                            }
                            setGroups(new Set(selectedGroups))
                        }}>
                            <div>
                                <i className={`iconfont ${selectedGroups.has(Number(i.id)) ? 'icon-gouxuan' : 'icon-yuan'}`}
                                    style={{ color: selectedGroups.has(Number(i.id)) ? 'var(--theme-main)' : '#eee' }} />
                            </div>
                            <div>
                                <div>{i.name}</div>
                                <div>{i.uid_list.map(i => i.name).join('、')}</div>
                            </div>
                        </Item>
                    })}
                    <Item onClick={() => {
                        if (selectedGroups.has(0)) {
                            selectedGroups.delete(0)
                        } else {
                            selectedGroups.add(0)
                            selectedGroups.delete(-1)
                        }
                        setGroups(new Set(selectedGroups))
                    }}>
                        <div>
                            <i className={`iconfont ${selectedGroups.has(0) ? 'icon-gouxuan' : 'icon-yuan'}`}
                                style={{ color: selectedGroups.has(0) ? 'var(--theme-main)' : '#eee' }} />
                        </div>
                        <div>
                            <div>未分组的帮卖好友</div>
                            <div></div>
                        </div>
                    </Item>
                </Warp>
            </div>
        </div>
    </Modal>
}


function SwitchItem(props: { checked: boolean, onChange: () => void, text: string }) {
    return (
        <Warp className=' flex-between cur-pointer' onClick={() => { props.onChange() }} >
            <div className=' flex-between'>{props.text}</div>
            <Switch checked={props.checked} onClick={props.onChange} />
        </Warp>
    )
}