import React, { Component } from 'react'

// import Order from './orderItem';
import Order from '../order/item';
import Modal from '../total/Modal';

import BanUnLogin from '../../high-component/BanUnLogin'
import Main from '../../high-component/Content'

import api from '../../netApi';

import Export from '../order/exportPanel'

import { OFFSET_LIMIT, } from '../../data/options';

import BTN from '../../StyleComponents/Button.tsx';

const status = [
    ['all', '全部'],
    ['notuse', '未完成'],
    ['used', '已完成'],
]


class TuanOrderList extends Component {

    myself = true

    constructor() {
        super()
        this.state = {
            search: '',
            status: 0,
            list: [],
            tuanName: null
        }
        this.offset = 0

        this.tuanid = ''

    }

    scroll(e) {
        const offsetY = window.pageYOffset
        const scrollHeight = e.srcElement.body.scrollHeight
        const bottom = scrollHeight - offsetY
        if (bottom < 2000) {
            this.getlist()
        }
    }

    scrollHandle = this.scroll.bind(this)
    componentWillUnmount() {
        document.title = '接力GO'
        window.removeEventListener('scroll', this.scrollHandle)
    }
    componentDidMount() {
        document.title = '报名管理'
        if (this.props.is_Login) {
            this.getlist()
        }
        window.addEventListener('scroll', this.scrollHandle)
    }
    componentDidUpdate(prev) {
        if (!prev.is_Login && this.props.is_Login) {
            this.getlist()
        }
    }


    render() {
        // console.log(this.state.Loading)
        return (<div style={{
            padding: 20,
            maxWidth: 986,
            margin: 'auto',
            width: '100%',
            minHeight: '90vh'
        }}>
            <div className='flex-between'>
                <div className='cur-pointer' onClick={() => {
                    this.setState({ selectEventPanel: true })
                }}>
                    <span>{this.state.tuanName === null ? '全部活动' : this.state.tuanName}</span><span><i className='iconfont icon-down' /></span>
                </div>
                <div style={{ position: 'relative' }}>
                    <input style={{
                        backgroundColor: '#eaeaea', fontSize: 16, padding: '4px 30px', border: 'none', width: '100%',
                    }} type='search' value={this.state.search} onChange={(e) => {
                        // 输入
                        this.setState({ search: e.target.value })
                    }} onKeyUp={e => {
                        if (e.key !== 'Enter') return
                        // 回车
                        this.count = undefined
                        this.offset = 0
                        this.setState({ list: [] })
                        this.getlist()
                    }} />
                    <i className='iconfont icon-seekicon cur-pointer' title='搜索' onClick={() => {
                        // 搜索
                        this.count = undefined
                        this.offset = 0
                        this.setState({ list: [] })
                        this.getlist()
                    }} style={{ position: 'absolute', left: 10, top: 4 }}></i>
                    <i className='iconfont icon-quxiaozhuanhuan cur-pointer' title='清空' style={{ position: 'absolute', right: 10, top: 4 }} onClick={() => {
                        // 重置
                        this.count = undefined
                        this.offset = 0
                        this.setState({ list: [] })
                        this.setState({ search: '' }, () => { this.getlist() })
                    }}></i>
                </div>

            </div>


            <div className='fcc' style={{
                justifyContent: 'flex-start',
                marginTop: 12,
            }}>
                {status.map((item, index) => {
                    return <div className='cur-pointer' key={index} style={{
                        color: index === this.state.status ? '#fd5156' : '#999',
                        marginRight: 12
                    }} onClick={() => {
                        if (index === this.state.status) return
                        this.offset = 0
                        this.count = undefined
                        this.setState({ status: index, list: [] }, () => {
                            this.getlist()
                        })
                    }}>{item[1]}</div>
                })}
            </div>
            <div className='fbc' style={{ justifyContent: 'flex-end' }}>
                <BTN color='red' solid title='导出excel文件或直接打印' onClick={this.showExport.bind(this)}>导出数据</BTN>
            </div>

            {/* {(this.state.totalData && this.state.list.length > 0) ? <div style={{ marginTop: 12, }}>
                <h5>订单总览<span style={{ fontSize: 12, color: '#b8b8b8' }} className='cur-pointer' onClick={() => {
                    this.setState({ showGoodTotalList: true })
                }}>点击查看商品汇总</span></h5>
                <div className='fcc' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    <div style={{ color: '#999', marginRight: 12 }}>总订单：<span style={{ color: '#333' }}>{this.state.totalData.allorder_count}</span></div>
                    <div style={{ color: '#999', marginRight: 12 }}>已完成：<span style={{ color: '#333' }}>{this.state.totalData.isfinish_count}</span></div>
                    <div style={{ color: '#999', marginRight: 12 }}>销售额：<span style={{ color: '#333' }}>{(this.state.totalData.sale_count / 100).toFixed(2)}</span>元</div>
                    <div style={{ color: '#999', marginRight: 12 }}>退款：<span style={{ color: '#333' }}>{(this.state.totalData.refund_count / 100).toFixed(2)}</span>元</div>
                </div>
            </div> : null} */}

            <div className='TuanOrderList__list'>
                {this.state.list && this.state.list.length > 0 ? (
                    this.state.list.map(order => {
                        return <Order send={() => {
                            this.refresh()
                        }} key={order.orderid} expressList={[]} data={order} print={this.print.bind(this, order)} />
                    })
                ) : null}
                {this.state.list && this.state.list.length === 0 && !this.state.Loading ? (
                    <div className='fcc' style={{ height: 300 }}>暂无订单</div>
                ) : null}

            </div>

            {this.state.selectEventPanel ? (
                <Modal close={() => { this.setState({ selectEventPanel: false }) }} title='选择活动' style={{ content: { maxHeight: 400, overflow: 'auto' } }} >
                    <div>
                        {[{ title: '全部活动', id: '' }, ...this.state.tuanlist].map(tuan => {
                            return (
                                <div className={`TuanOrderList__selectTuan ${this.tuanid === tuan.id ? 'TuanOrderList__selectTuan_active' : ''} cur-pointer fbc`}
                                    onClick={() => {
                                        this.setState({ selectEventPanel: false, tuanName: tuan.title })
                                        this.tuanid = tuan.id
                                        this.offset = 0
                                        this.count = undefined
                                        this.getlist({ refresh: true })
                                    }}
                                    key={tuan.id}>{tuan.title}</div>
                            )
                        })}
                    </div>
                </Modal>
            ) : null}

            {/* 导出 */}
            {this.state.showExportPanel ? <Export
                printList={
                    this.printList.bind(this)
                }
                close={() => {
                    this.setState({ showExportPanel: false })
                }}
                tuanlist={this.state.tuanlist}
                height={320}
                type='hd'
                width={400}></Export> : null}
        </div>)
    }

    getlist(config = {}) {
        if (this.count !== undefined && (this.offset >= this.count)) return
        if (this.state.Loading) return
        this.setState({ Loading: true })
        const data = {
            offset: this.offset,
            limit: config.limit || OFFSET_LIMIT,
            search: this.state.search,
            op: status[this.state.status][0],
            tuanid: window.btoa(this.tuanid),
        }

        if (this.myself) {
            data.sid = window.btoa(this.props.user.self_sid)
            data.type = 'hd'
            data.addressid = 'all'
            api('shop', 'getorderlist')({
                ...data, types: 1
            }).then(res => {
                if (res.status !== 200) {
                    alert('网络出错，请刷新重试')
                }
                const data = res.data.data
                this.count = data.count
                this.offset += data.list.length
                this.setState({ Loading: false })
                const list = data.list.map(order => {
                    let status = ''
                    const orderStatus = order.status
                    switch (Number(orderStatus)) {
                        case 0:
                            status = '未支付'
                            break;
                        case 1:
                            status = '支付成功'
                            if (order.refundContent.length > 0) {
                                status = order.refundContent
                            }
                            break
                        case 2:
                            status = '核销成功'
                            break;
                        case 4:
                            status = '退款完成'
                            break
                        case 5:
                            status = '无需支付'
                            break
                        case 6:
                            status = '业务开单'
                            break
                        default:
                            break;
                    }
                    return {
                        ...order,
                        statusText: status
                    }
                })
                if (config.refresh) {
                    this.setState({ Loading: false, list })
                    return
                }
                this.setState({
                    Loading: false,
                    list: this.state.list.concat(list),
                    totalData: data.order_total,
                    tuanlist: data.tuanlist
                })
            })
        }
    }

    print(item) {
        this.setState({
            printOrders: [item]
        }, () => {
            this.props.history.push({
                pathname: '/orders/print',
                state: {
                    list: [item]
                }
            })
        })
    }

    refresh() {
        this.count = undefined
        const limit = this.offset
        this.offset = 0
        this.getlist({ limit, refresh: true })
    }

    showExport() {
        this.setState({ showExportPanel: true })
    }

    printList(list) {
        if (!Array.isArray(list)) {
            return
        }
        this.setState({
            printOrders: list
        }, () => {
            // window.print()
            this.props.history.push({
                pathname: '/orders/print',
                state: {
                    list
                }
            })
        })
    }
}

export default Main(BanUnLogin(TuanOrderList))
