import React, { Component } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

const FCC = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const View = styled(FCC)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index:999;
`

const LoadingPanel = styled(FCC)`
    background: rgba(0,0,0,.4);
    width: 90px;
    height: 90px;
    border-radius: 15px;
    color: #fff;
    flex-direction: column;
    z-index: 999;
`

const Icon = styled.i`
    line-height: 1;
    animation: rotate 1.5s ease-in-out 0s infinite;
    font-size: 32px;
    margin-bottom: 12px;
    &::before{
        animation: none !important;
    }
`

interface Props {
    isLoading: boolean,
    loadingText: string,
}

class Loading extends Component<Props> {
    render() {
        if (!this.props.isLoading) return <></>
        return <View>
            <LoadingPanel>
                <Icon className='iconfont icon-xingzhuang'></Icon>
                {this.props.loadingText ? <div>{this.props.loadingText}</div> : null}
            </LoadingPanel>
        </View>
    }
}

const mapStateToProps = (state: any, ) => {
    return {
        isLoading: state.isLoading,
        loadingText: state.loadingText,
    }
}

export default connect(mapStateToProps)(Loading)