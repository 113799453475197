import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className='fcc' id='footer' style={{ minHeight: '10vh', flexDirection: "column" }}>
                <a className='flex-center' target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602008538" style={{ textDecoration: 'none', fontSize: 12 }}>
                    <img src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/4UhsHGjua4.png" alt='' />
                    <p style={{
                        margin: '0px 0px 0px 5px', color: '#939393'
                    }}>粤公网安备 44010602008538号</p ></ a>
                <a style={{ fontSize: 12, color: '#999' }} target='_blank' rel="noopener noreferrer" href='http://beian.miit.gov.cn/'>{window.location.href.indexOf('jieligo') !== -1 ? '京ICP备19012758号-2':'京ICP备19012758号-1'}</a>
            </div>
        )
    }
}
export default Footer