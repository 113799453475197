import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { baseURL } from '../../netApi/index'
// import Select from '../total/Select';
import Select from '../total/Select_.tsx';
import { message, Radio } from 'antd';
import Modal from '../total/Modal';
import { getToken } from '../../netApi/fetch';


class Export extends Component {
    static propTypes = {
        // tuanList: PropTypes.array.isRequired
    }
    constructor() {
        super()
        this.state = {
            downloadLink: '',
            option: 0,
            type: 1,
            tuanid: '',
            exportType: 0, // 0全部 1快递 2自提

            actionType: 1, // 1 下载xls 2 打印

            sort: 'asc'
        }
    }
    componentDidMount(){
        // const selectedID = this.props.order.selectedTuan.id
        // if(selectedID){
        //     this.setState({tuanid: selectedID})
        // }
    }
    select(type, e) {
        this.setState({ [type]: e.target.value })
    }
    downloadData = (links, e) => {
        window.open(links + '&export=1', '_blank')
        return
    }
    print(links) {
        fetch(links +`&limit=9999999&token=${getToken()}`, {
            credentials: 'include',
            headers: {
                'Content-type': 'application/json;charset=UTF-8'
            },
        }).then((res) => {
            return res.json()
        }).then((res) => {
            const data = res.data
            const list = data.list
            this.props.printList(list)
        })
    }
    export() {
        if (!this.state.tuanid) {
            message.warning('请选择活动')
            return
        }
        console.log(this.props)
        if (this.state.actionType === 1) {
            const links = `${baseURL}/pcorder/orderList?option=${this.state.option}&type=${this.props.type === 'hd' ? 2 : 1}&tuanid=${this.state.tuanid}&exportType=${this.state.exportType}&sort=${this.state.sort}&token=${getToken()}`
            this.downloadData(links)
        } else if (this.state.actionType === 2) {
            let links = `${baseURL}/shop/getorderlist?op=${this.state.option === 0 ? 'all' : ''}${this.state.option === 2 ? 'notuse' : ''}&tuanid=${encodeURIComponent(window.btoa(this.state.tuanid))}`
            links += `&sid=${encodeURIComponent(window.btoa(this.props.user.self_sid))}&types=${1}&type=${this.props.type === 'hd' ? 'hd' : 'tuan'}`
            this.print(links)
        }
    }
    changeExportType(t) {
        this.setState({ actionType: t })
    }
    close(e) {
        e.preventDefault()
        e.stopPropagation()
        this.props.close()
    }
    render() {
        const Title = <div className='fcc'>
            <div style={{ fontWeight: this.state.actionType === 1 ? 600 : 400, marginRight: 24, fontSize: this.state.actionType === 1 ? 16 : 12 }} className='cur-pointer' onClick={this.changeExportType.bind(this, 1)}>导出订单</div>
            <div style={{ fontWeight: this.state.actionType === 2 ? 600 : 400, marginRight: 24, fontSize: this.state.actionType === 2 ? 16 : 12 }} className='cur-pointer' onClick={this.changeExportType.bind(this, 2)}>打印订单</div>
        </div>
        return (
            <Modal showConfirm={false} title={Title} loading={this.state.Loading} close={this.props.close} style={{
                outer: {
                    width: 400
                }
            }} >
                <div style={{ padding: 20, paddingTop: 0 }} className='fcc'>
                    <div style={{ minWidth: '7em' }}>选择活动</div>
                    <div style={{ flex: 1 }}><Select uni='dingdan' list={[
                        ...this.props.tuanlist,
                    ]} showKey='title'
                        placeholder='请选择活动'
                        onChange={e => {
                            this.setState({
                                tuanid: e.id
                            })
                        }} value={this.props.tuanlist.findIndex(i => i.id === this.state.tuanid)} maxHeight={200}
                    /></div>
                </div>

                <div style={{ padding: 20, paddingTop: 0 }} className='fcc'>
                    <div style={{ minWidth: '7em' }}>选择订单状态</div>
                    <div style={{ flex: 1 }}><Select uni='dingdan' list={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' },]} showKey='title'
                        onChange={e => {
                            this.setState({
                                option: e.value
                            })
                        }} value={this.state.option === 0 ? 0 : 1}
                    /></div>
                </div>

                {this.state.actionType === 1 ? <div style={{ display: 'flex', padding: '0 20px 20px' }}>
                    <div onClick={() => { this.setState({ sort: 'asc' }) }}>
                        <Radio checked={this.state.sort === 'asc'}>最早订单排最前</Radio>
                    </div>
                    <div onClick={() => { this.setState({ sort: 'desc' }) }}>
                        <Radio checked={this.state.sort === 'desc'}>最晚订单排最前</Radio>
                    </div>
                </div> : null}
                <div style={{ padding: 20, paddingBottom: 0, paddingTop: 0 }} className='fcc'>
                    {this.state.actionType === 1
                        ? <button className='button_solid_red' onClick={this.export.bind(this)}>导出订单</button>
                        : <button className='button_solid_red' onClick={this.export.bind(this)}>打印订单</button>}
                </div>
            </Modal>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        order: state.order,
    }
}
export default (connect(mapStateToProps)((Export)))