import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { get, getToken, } from '../../../netApi/fetch'
import {  message } from 'antd'
import Select from '../../total/Select_'
import BTN from '../../../StyleComponents/Button'
import { confirm as Confirm } from '../../Method/Confirm';
import { API } from '../../../data/options'
import { Border } from './importGoodData';

interface props {
    onClose: () => void
    data: {

    }
}

let wrapper: Element

export default async function () {
    return new Promise(async (reslove, reject) => {
        function Category(props: props) {
            const data = useRef<FormData>()
            const [file, setFile] = useState<File>()
            const [branchlist, setBranchlist] = useState<any[]>()
            useEffect(() => {
                if (!branchlist) {
                    get('/mall/getmallbranchgrouplist', {}).then(res => {
                        setBranchlist(res.reduce((prev, item) => {
                            if (item.id === 'all') return prev
                            if (item.branch_list && item.branch_list.length === 0) return prev
                            return [...prev, { ...item, showName: item.name, select: false }, ...item.branch_list.map(i => {
                                return {
                                    ...i,
                                    showName: i.branch_name,
                                    select_sub_item: 1,
                                    select: false
                                }
                            })]
                        }, [{ id: 'all', showName: '全部', select: false, }]))
                    })
                }
            }, [branchlist])

            const [showString, setShowString] = useState('')

            useEffect(() => {
                const s = ((): string => {
                    if (!branchlist) return ''
                    const l = branchlist.filter(i => i.branch_group_id && i.select).length
                    if (l > 0) return `已选择${l}个门店`
                    return ''
                })()
                setShowString(s)
            }, [branchlist])

            const [shouldClose, setShouldClose] = useState(false)
            const [loading, setloading] = useState(false)
            return <Modal title='批量导入商品' onClose={props.onClose} shouldClose={shouldClose} loading={loading} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <Border>
                        <div>
                            <div onClick={() => { data.current = undefined }}>下载表格模板并填写</div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <a download href="https://jieligo-1259536745.cos.ap-guangzhou.myqcloud.com/uploads/wxacode/%E5%95%86%E5%93%81%E6%89%B9%E9%87%8F%E4%B8%8A%E6%9E%B6%E8%A1%A8%E6%A0%BCy.xlsx">
                                    <BTN color='red'>
                                        下载模版
                                    </BTN>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>上传表格</div>
                            <label style={{ cursor: 'pointer' }} onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <input id='file' type='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                                    onChange={e => {
                                        if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                        const file = e.target.files[0]
                                        setFile(file)
                                    }} />
                                <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                            </label>
                        </div>
                    </Border>
                    <Border>
                        <div className='flex-box'>
                            <div style={{ marginRight: 16 }}>选择上架门店</div>
                            {(() => {
                                const list = branchlist || []
                                const value = list.map((i, idx) => i.select ? idx : -1).filter(i => i !== -1)
                                return <Select inputStyle={{ padding: '4px 11px' }} uni='branch' placeholder='请选择门店' showString={showString} multi list={list} maxHeight={200} value={value} showKey='showName' onChange={selectBranch} />
                            })()}
                        </div>
                    </Border>
                </div>
            </Modal>

            function selectBranch(e) {
                e.select = !e.select
                const list = branchlist.map(i => {
                    if (e.id === 'all') return { ...i, select: e.select }
                    if (e.branch_list && i.branch_group_id) {
                        if (Number(i.branch_group_id) === Number(e.id)) {
                            return {
                                ...i, select: e.select
                            }
                        }
                    }
                    return i
                })
                setBranchlist(list)
            }

            function confirm() {
                if (!file) {
                    message.error('请选择文件')
                    return
                }
                const formData = new FormData()
                formData.append('file', file)
                file.slice(0, 1).arrayBuffer().then(() => {
                    formData.append('types', '1')
                    formData.append('token', getToken())
                    for (let i = 0; i < branchlist.length; i++) {
                        const b = branchlist[i];
                        if (b.branch_group_id && b.select) {
                            formData.append('to_branch_mall_ids[]', b.id)
                        }
                    }
                    setloading(true)
                    fetch(API + '/mall/setmallgoodsbyimportexcel', {
                        method: 'post', body: formData, mode: 'cors',
                        credentials: 'include',
                    }).then((res) => {
                        setloading(false)
                        res.json().then((res) => {
                            if (res.code !== 0) {
                                Confirm({
                                    text: res.message
                                })
                                return
                            }
                            message.success('上架成功')
                            setShouldClose(true)
                            reslove(true)
                        })
                    }).catch(() => {
                        setloading(false)
                        setFile(undefined)
                        var file = document.getElementById('file') as HTMLInputElement;
                        file.value = ''
                        message.error('网络错误')
                    })
                }).catch((err) => {
                    setloading(false)
                    setFile(undefined)
                    var file = document.getElementById('file') as HTMLInputElement;
                    file.value = ''
                    message.error('文件发生变动，请重新选择文件')
                })
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}