import React, { Component } from 'react'
// import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { showLoading } from '../../reducers/index'


// import api from '../../netApi/index';
import { message } from 'antd';
import { API } from '../../data/options';
import { getToken, post } from '../../netApi/fetch';
import { confirm } from '../Method/Confirm';
import VIPPower from '../index/method/VIPPower';
import  { storage } from '../../data/competence';
import text from '../../data/text';
// import {getToken} from '../../netApi/fetch.ts';

interface props {
    images: any[],
    width?: number,
    height?: number,
    imgtype?: string,
    onChange?: (any) => void
    maxLength?: number,
    row?: number,
    loadingStyle?: React.CSSProperties
    disabled?: boolean
    // showLoading: () => void
    // hideLoading: () => void
}

export class Imgs extends Component<props> {

    readonly state = {
        images: [],
        style: {

        },
        nullPanel: 0,
        init: false, // 初始化是否已完成
        showImage: '',
        Loading: false,
    }

    wrap = React.createRef<HTMLDivElement>()

    dragData: any

    componentDidUpdate() {
        if (this.state.images.length === 0 && this.props.images.length > 0 && !this.state.init) {
            this.setState({
                images: this.props.images,
                init: true
            })
        }
        // console.log(this.props, this.state, this.wrap.current.style)
    }

    getSnapshotBeforeUpdate(props, state) {
        let width = this.wrap.current.clientWidth
        const data: any = {}
        if (state.images.length !== this.state.images.length) {
            if (props.width && props.width > 0) {
                const row = Math.floor((width) / props.width)
                const num = this.state.images.length + 1
                const line = Math.ceil(num / row)
                data.nullPanel = row * line - num
                this.setState({
                    nullPanel: data.nullPanel
                })
            } else if (props.row && props.row > 0) {
                const row = props.row
                const num = this.state.images.length + 1
                const line = Math.ceil(num / row)
                data.nullPanel = row * line - num
                this.setState({
                    nullPanel: data.nullPanel
                })
            }
        }
        return data
    }

    componentDidMount() {
        const style: any = {
            height: this.props.height || 60,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginBottom: 15,
            transition: 'all 0s',
            position: 'relative',
        }
        if (this.props.row) {
            style.flexBasis = (70 / this.props.row) + '%'
        } else if (this.props.width) {
            style.width = this.props.width
        } else {
            style.flexBasis = '33%'
        }
        this.setState({
            style
        })
        post('/charge/checkmemberauth', {sid: false, auth_type: 5}).then(res => {
            storage.set(!!res.auth)
        })
    }

    render() {
        return (
            <div className='fbc' ref={this.wrap} style={{ display: 'flex', transition: 'all 0s', flexWrap: 'wrap', width: '100%', position: 'relative' }}>
                {this.state.images.map((i, index) => {
                    if (i.rank === undefined) { i.rank = index }
                    return (
                        <div key={i.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                this.setState({ showImage: i.src })
                            }}
                            style={{ ...this.state.style, order: i.rank, backgroundImage: `url("${i.src + '?imageView2/5/w/300'}")`, }}
                            draggable={true && !this.props.disabled}
                            onDragStart={() => {
                                this.dragData = i.id
                            }}
                            onDragEnter={(e) => {
                                const imgs = this.state.images
                                const r = i.rank;
                                for (let j = 0; j < this.state.images.length; j++) {
                                    const item = imgs[j];
                                    if (item.id === this.dragData) {
                                        imgs[index].rank = item.rank
                                        item.rank = r
                                    }
                                }
                                if(this.props.disabled)return
                                this.setState({
                                    images: imgs
                                }, () => {
                                    this.props.onChange && this.props.onChange(this.state.images)
                                })
                            }}
                            onDragEnd={e => {
                                this.dragData = null
                            }}
                        >
                            {!this.props.disabled && <div className='fcc' style={{ height: '20%', minHeight: 14, width: '100%', background: 'rgba(33,33,33,.6)', position: 'absolute', bottom: 0, left: 0 }} onClick={(e) => {
                                e.stopPropagation()
                                this.setState({
                                    images: this.state.images.filter(img => img.id !== i.id)
                                }, () => { this.props.onChange && this.props.onChange(this.state.images) })
                            }}>
                                <i className='iconfont icon-shanchu' style={{ color: '#fff', fontSize: 12, lineHeight: 1 }}></i>
                            </div>}
                        </div>
                    )
                })}
                {(!this.props.maxLength || this.state.images.length < this.props.maxLength) ? (<div style={{ ...this.state.style, order: 9999 }}><label className='fcc cur-pointer' onClick={() => {
                    if(this.props.disabled)return
                    if (!storage.get()) {
                        confirm({
                            text: text.ERROR.MEMBER.IMAGE,
                            title: '温馨提示',
                            confirmText: '去升级',
                            cancelText: '暂不升级',
                        }).then(res => {
                            if (res) VIPPower({})
                            
                        })
                        return
                    }
                    const input = document.createElement('input')
                    input.type = 'file'
                    input.accept = 'image/*'
                    input.multiple = true
                    input.onchange = (e) => {
                        const target = e.target as HTMLInputElement
                        const count = target.files.length + this.state.images.length
                        for (let i = 0; i < target.files.length; i++) {
                            const image = target.files[i];
                            const data = new FormData()
                            data.append('file', image)
                            data.append('types', '1')
                            data.append('uploadtype', this.props.imgtype || 'goods')
                            data.append('token', getToken())
                            // if (this.props.showLoading) this.props.showLoading()
                            this.setState({ Loading: true })
                            setTimeout(() => {
                                fetch(API + '/file/upload', {
                                    method: 'post', body: data, mode: 'cors',
                                    credentials: 'include',
                                }).then((res) => {
                                    res.json().then((res) => {
                                        if (res.code !== 0) {
                                            message.error(res.message)
                                            return
                                        }
                                        const data = res.data.file
                                        data.rank = this.state.images.length
                                        const images = this.props.maxLength ? [...this.state.images, data].slice(0, this.props.maxLength) : [...this.state.images, data]
                                        this.setState({
                                            images
                                        }, () => {
                                            if (this.state.images.length === count || (this.props.maxLength && images.length >= this.props.maxLength)) {
                                                // if (this.props.hideLoading) this.props.hideLoading()
                                                this.setState({ Loading: false })
                                                this.props.onChange && this.props.onChange(this.state.images)
                                            }
                                        })
                                    })
                                }).catch(() => {
                                    message.error('网络错误')
                                })
                            }, 200 * i);
                        }
                    }
                    input.click()
                }} style={{ height: '100%', border: '1px solid rgb(201,201,201)', }}>
                    <i className='iconfont icon-jia' style={{ color: 'rgb(201,201,201', fontSize: 28, lineHeight: 1 }}></i>
                </label></div>) : null}
                {(() => {
                    const r = []
                    if (this.state.nullPanel > 0) {
                        for (let i = 0; i < this.state.nullPanel; i++) {
                            r.push((<div key={i} style={{ order: 10000, ...this.state.style, height: 0, marginBottom: 0 }}></div>))
                        }
                    }
                    return r.map(i => {
                        return i
                    })
                })()}
                {this.state.showImage ? <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', background: 'rgba(0,0,0,0.5)', zIndex: 999 }} className='fcc' onClick={() => { this.setState({ showImage: null }) }}>
                    <img alt='' src={this.state.showImage} style={{ maxWidth: '70vw', maxHeight: '70vh', userSelect: 'none' }} />
                </div> : null}

                {this.state.Loading ? <Loading style={this.props.loadingStyle || {}} /> : ''}
            </div>
        )
    }
}

export default (Imgs)

class Loading extends Component<{ style: React.CSSProperties }> {
    render() {
        return (<div className='fcc' style={{ position: 'absolute', width: '100%', top: 0, left: 0, height: '100%', background: 'rgba(255,255,255,.6)', ...this.props.style }}>
            <i className='iconfont icon-xingzhuang' style={{ fontSize: 32, color: 'black' }}></i>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showReduxLoading: () => {
            dispatch(showLoading(true))
        },
        hideReduxLoading: () => {
            dispatch(showLoading(false))
        }
    }
}
export const ReduxImg = connect(mapStateToProps, mapDispatchToProps)(Imgs)