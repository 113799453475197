import React, { Component } from 'react';

export default class Good extends Component {

    constructor() {
        super()
        this.state = {
            hover: false
        }
    }

    render() {
        return (
            <div className='goodItem' onClick={e => {
                const link = `https://detail.1688.com/offer/${this.props.id}.html?sk=consign`
                window.open(link, 'newwindow')
            }} onMouseEnter={() => { this.setState({ hover: true }) }}
                onMouseLeave={() => { this.setState({ hover: false }) }}
            >
                <img alt={this.props.name} src={this.props.logo} className='img-center' />
                <div className='goodItem__data'>
                    <div className='single-text-hidden goodItem__data__name'>{this.props.name}</div>
                    <div className='goodItem__data__number'>
                        月销：{this.props.salenum}件
                    </div>

                    {/* tag */}
                    <div style={{ height: 36 }}>
                        {this.props.tags ? this.props.tags.map((tag, index) => {
                            return <div key={index} className='goodItem__data__tag goodItem__data__tag_orange'>
                                {tag}
                            </div>
                        }) : null}
                    </div>

                    {/* 普通价格 */}
                    {!this.props.superBuyerPrice ? (
                        <div>
                            <div className='goodItem__data__tag goodItem__data__tag_gray'>
                                进货价
                            </div>
                            <div className='goodItem__data__price'>
                                <span>￥</span>
                                <span>{this.props.price}</span>
                            </div>
                        </div>
                    ) : null}

                    {/* 普通价格 */}
                    {this.props.channelPrice  ? (
                        <div>
                            <div className='goodItem__data__tag' style={{background: 'var(--theme-main)', color: '#fff'}}>
                                渠道专属价
                            </div>
                            <div className='goodItem__data__price'>
                                <span>￥</span>
                                <span>{this.props.channelPrice}</span>
                            </div>
                        </div>
                    ) : null}

                    {/* 超级买家 */}
                    {this.props.superBuyerPrice ? (
                        <div>
                            <div className='goodItem__data__tag goodItem__data__tag_super'>
                                超级买家价
                            </div>
                            <div className='goodItem__data__price'>
                                <span>￥</span>
                                <span>{this.props.superBuyerPrice}</span>
                            </div>
                        </div>
                    ) : null}
                </div>

                {this.state.hover && this.props.alienable ? <div className='goodItem__hover'>
                    <div className='fcc cur-pointer' >
                        <button onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.props.onClick(this.props.id)
                        }} className='goodItem__hover__button goodItem__hover__button_active cur-pointer'><i className='iconfont icon-jiaruxuanpin' />加入选品</button>
                    </div>
                </div> : null}

                {this.state.hover && !this.props.alienable ? <div className='goodItem__hover'>
                    <div className='fcc cur-pointer' >
                        <button onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }} className='goodItem__hover__button goodItem__hover__button_static cur-pointer'>商品无效</button>
                    </div>
                </div> : null}
            </div>
        );
    }
}

export class FakeGood extends Component {
    render() {
        return (
            <div className='goodItem' >
                <img alt='' className='img-center skeleton' />
                <div className='goodItem__data'>
                    <div className='goodItem__data__name skeleton' ></div>
                    <div className='goodItem__data__number skeleton'></div>

                    {/* tag */}
                    <div style={{ height: 36 }}>
                        <div className='skeleton'></div>
                    </div>
                </div>
            </div>
        )
    }
}