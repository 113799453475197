import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../total/Modal';
// import styled from 'styled-components';
// import BTN from '../../../StyleComponents/Button';
// import { message } from 'antd';
import { get, post } from '../../../netApi/fetch';
let confirmWrap: Element
interface props {

}

interface postdata {
    s_id?: number | string
    mall_branch_id?: number | string
}
export default function (props?: props) {
    return new Promise<boolean>((res, rej) => {
        function Create(p) {
            const [adminlist, setAdminlist] = useState<any[]>()
            const loading = useRef(false)
            if (!adminlist && !loading.current) {
                get('/shopadministrator/getmybindshop', {}).then(res => {
                    setAdminlist(res.list)
                    loading.current = true
                })
            }
            function changeAdmin(i) {
                const data: postdata = {}
                const item = adminlist[i]
                if (item.is_branch_mall) {
                    data.mall_branch_id = item.id
                } else {
                    data.s_id = item.id
                }
                post('/admin/switchAccount', data).then(() => {
                    res(true)
                    ReactDOM.render(null, confirmWrap)

                    // this.props.logout()
                    // post('/admin/checkIsLogin', {}).then(res => {
                    //     post('/proxy/checkProxy', { type: 1 }).then(r => {
                    //         this.props.setUserType(r.type)
                    //     })
                    //     this.props.waitingLogin()
                    //     this.props.login(res)
                    //     this.close()
                    //     this.props.history.push('/refresh')
                    // })
                })
            }
            return <Modal title='切换主页' cancel={true} onClose={cancel} style={{ content: { maxHeight: 600, overflowX: 'auto' } }} >
                {(adminlist || []).map((item, i) => {
                    return (
                        <div key={i} className='select-hover flex-between cur-pointer'
                            onClick={() => changeAdmin(i)}
                            style={{
                                overflowY: 'auto', wordBreak: 'break-all',
                                minWidth: 300, marginTop: 10, padding: '4px 11px', maxHeight: 400
                            }}>
                            <div style={{ marginRight: 20 }}><img style={{ width: 30, height: 30, borderRadius: '50%', }} alt='头像' src={item.logo}></img></div>
                            <div style={{ fontSize: 14, cursor: 'pointer' }}>{item.name}</div>
                            {item.istop ? (
                                <div style={{
                                    minWidth: 40, height: 20, fontSize: 12, lineHeight: '20px', textAlign: 'center', borderRadius: 10, marginLeft: 10, background: '#fd5356', color: '#fff'
                                }}>
                                    本人
                                </div>
                            ) : ''}
                            {Number(item.is_branch_mall)
                                ? <><div className='flex-center' style={{
                                    minWidth: 40, height: 20, fontSize: 12, lineHeight: '20px', textAlign: 'center',
                                    borderRadius: 10, marginLeft: 10, background: '#fd5356', color: '#fff',
                                    padding: '0 11px'
                                }}>
                                    {item.branch_name}
                                </div>
                                </>
                                : null}
                            <div style={{ flex: 1 }}></div>
                        </div>
                    )
                })}
            </Modal>
        }

        function cancel() {
            ReactDOM.render(null, confirmWrap)
            res(false)
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Create {...props} />, confirmWrap)
    })
}