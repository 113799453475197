import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Panel = styled.div`
    display: flex;
    @media (max-width: 576px) {
        flex-direction: column;
    }
    align-items:  center;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
    overflow-x: hidden;
`
const Title = styled.div`
        font-size: 26px;
        color: #333;
        font-weight: 600;
        margin-bottom: 16px;
`
const Content = styled.p`
        font-size: 14px;
        color: #999;
        white-space: pre-line;
        line-height: 28px;
`
const Img = styled.img`
    @media (min-width: 576px) {
        width: 60%;
        order: ${props => props.type === 1 ? 3 : 1};
    }
    width: 80%;
    height: auto;
    order: 3;
`

class ContentLayoutPanel extends Component {
    static defaultProps = {
        type: 1
    }
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        img: PropTypes.string.isRequired,
        type: PropTypes.number // 1文字左 2文字右
    }
    constructor() {
        super()
        this.state = {

        }
    }
    componentDidMount() {
        const width = document.body.clientWidth
        this.setState({
            width
        })
    }
    render() {
        return (
            <Panel>
                {!!this.props.textbg && <img src={this.props.textbg} alt='' style={{
                    height: '100%',
                    position: "absolute",
                    [this.props.type === 1 ? 'left' : 'right']: 0, 
                    top: 0,
                    zIndex: -1,
                }} />}
                <div className='fcc'
                    style={{
                        flexDirection: 'column', alignItems: 'flex-start', order: 2, padding: 20,
                        minWidth: this.props.textWidth,
                    }}>
                    <Title>
                        {this.props.title}
                    </Title>
                    <Content className='subContent'>
                        {this.props.content}
                    </Content>
                </div>
                <Img src={this.props.img} type={this.props.type} />
            </Panel>
        )
    }
}
export default ContentLayoutPanel