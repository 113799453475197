import React, { Component } from 'react'
// import {useCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toggleLoginPanel, set_login_user_data, waitingLogin, setusertype, initurl, toggleSidebar, logout, saveMallInfo } from '../../reducers/index'
import api from '../../netApi/index'
import { setToken, post } from '../../netApi/fetch.ts'

import styled from 'styled-components';
import Modal from './Modal';
// import { message } from 'antd';
import delay_login from '../../data/delay_login_list';
import parse from '../../methods/paramParse.ts';

// const Input = styled.label`
//     color: #333;
//     margin-bottom: 20px;
//     display: flex;
//     align-items: center;
//     width: 100%;
//     div{
//         /* padding-bottom: 10px; */
//         min-width: 4rem;
//     }
//     input{
//         border-radius:4px;
//         border:1px solid rgba(204,204,204,1);
//         background: white;
//         width: 100%;
//         padding: .4rem;
//     }
// `

const Title = styled.div`
    width: 100%;
    font-size: 16px;
    color: #666;
    height: 1em;
`
// const Line = styled.div`
//     height: 240px;
//     width: 1px;
//     background: #d8d8d8;
// `


let loginInterval
class Login extends Component {
    constructor(p) {
        super()
        this.state = {}
        // eslint-disable-next-line no-restricted-globals
        p.initurl(location.href.split('#')[0])
        this.delayLogin = false
        for (let i = 0; i < delay_login.length; i++) {
            const item = delay_login[i];
            const reg = new RegExp(item, 'g')
            if (reg.test(p.location.pathname)) {
                this.delayLogin = true
                break
            }
        }
    }
    close() {
        this.props.closeLoginPanel()
        clearInterval(loginInterval)
    }
    async checkIsLogin(cb = {}, e) {
        const _this = this
        if (this.props.is_Login && e !== false) {
            clearInterval(loginInterval)
            return
        }
        try {
            let data = await post('/admin/checkIsLogin', {})
            setToken(data.token)
            clearInterval(loginInterval)
            post('/proxy/checkProxy', { type: 1 }).then(res => {
                _this.props.setUserType(res.type)
            })
            this.props.login(data)
            this.props.saveMallInfo(undefined)
            this.close()
            if (document.body.clientWidth > 990) {
                this.props.toggleSidebar(true)
            }
            cb.success && cb.success()
        } catch (error) {
            cb.fail && cb.fail()
        }
        this.props.waitingLogin()
        cb.complete && cb.complete()
    }
    catch(e) {
        e.preventDefault()
        e.stopPropagation()
    }
    componentDidUpdate() {
        clearInterval(loginInterval)
        if (this.props.showLoginPanel) {
            loginInterval = setInterval(this.checkIsLogin.bind(this), 1500)
        } else {
            clearInterval(loginInterval)
        }
        if (this.props.showLoginPanel) {
            if (!this.state.code) {
                api('admin', 'show')().then(res => {
                    const data = res.data
                    if (!res.data.data) {
                        window.location.reload();
                    }
                    const code = data.data.base64
                    setToken(data.data.token)
                    localStorage.setItem('token', data.data.token)
                    const d = new Date()
                    d.setTime(d.getTime() + 604800)
                    this.setState({
                        code, token: data.data.token
                    })
                })
            }
        }
    }
    componentWillUnmount() {
        clearInterval(loginInterval)
        loginInterval = undefined
        this.setState = null
    }
    componentDidMount() {
        const _this = this
        const parseData = parse(this.props.location.search)
        if (parseData.token) {
            setToken(parseData.token)
            localStorage.setItem('token', parseData.token)
        }
        this.checkIsLogin({
            fail: function () {
                if (_this.props.location.search !== '') {
                    const s = _this.props.location.search.slice(1)
                    const q = s.split('&')
                    const data = q.map((item) => {
                        return item.split('=')
                    })
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        if (item[0] === 'code') {
                            let should_to_index = true
                            const postdata = {
                                code: item[1]
                            }
                            if (_this.props.location.pathname.split('/')[2] === 'tuan') {
                                postdata.share_from_sid = _this.props.location.pathname.split('/')[1]
                                should_to_index = false
                            }
                            if (_this.props.location.pathname.split('/')[1] === 'downloads') {
                                should_to_index = false
                            }
                            api('huser', 'callback')(postdata).then(userdata => {

                                // 应设置reducer -> isWX = true
                                if (userdata.data.code !== 0) {
                                    if (!should_to_index) {
                                        _this.props.history.push(_this.props.location.pathname)
                                        return
                                    }
                                    window.location.replace('/')
                                    return
                                }
                                _this.props.login(userdata.data.data)
                                setToken(userdata.data.data.token)
                                localStorage.setItem('token', userdata.data.data.token)
                                _this.close.bind(_this)
                                _this.props.waitingLogin()
                                // _this.props.history.push('/orders')
                            })
                            return
                        }
                    }
                }
                const ua = navigator.userAgent.toLowerCase();
                if ((/MicroMessenger/i).test(ua)) {
                    // if (false) {
                    if (_this.delayLogin) return
                    const s = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6cf3b69eb2efe6e7&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
                    window.location.href = s
                    return
                } else {
                    api('admin', 'show')().then(res => {
                        const data = res.data
                        if (!res.data.data) {
                            window.location.reload();
                        }
                        const code = data.data.base64
                        setToken(data.data.token)
                        localStorage.setItem('token', data.data.token)
                        const d = new Date()
                        d.setTime(d.getTime() + 604800)
                        _this.setState({
                            code, token: data.data.token
                        })
                    })
                }
            }
        })
        setTimeout(() => {
            window.addEventListener('focus', () => {
                const delay = new Date() - (this.blurTime || new Date())
                const minute = 30
                const TIMELIMIT = minute * 60 * 1000 // 30分钟检测
                if (delay > TIMELIMIT) {
                    this.checkIsLogin({
                        fail: (() => {
                            this.setState({ showPanel: true })
                            this.props.logout()
                            this.props.openLoginPanel()
                        })
                    }, false)
                }
            })
            window.addEventListener('blur', () => {
                this.blurTime = new Date()
            })
        }, 60 * 1000)
    }
    render() {
        if (this.props.location.pathname === '/test' || this.props.location.pathname === '/auth') {
            return <></>
        }
        if (this.props.is_Login && !this.state.showPanel) return null
        if (this.props.showLoginPanel && !this.props.is_Login) {
            return <Modal title='扫码登录' onClose={this.close.bind(this)} >
                <div style={{ maxWidth: 400 }}>
                    <Title onClick={this.redirectLogin.bind(this)} />
                    <div className='fcc'>
                        <img alt='请使用微信扫描小程序码登录' onError={e => {

                        }} style={{
                            marginBottom: 24,
                            maxWidth: 240
                        }} src={this.state.code} />
                    </div>
                    <div className='fcc' style={{ fontSize: '12px', color: '#979797' }}>
                        提示：扫描接力GO小程序二维码，即可同步登录接力GO PC端管理平台，你可以通过PC端提高管理效率
                    </div>
                </div>
            </Modal>
        }
        return <></>
    }

    redirectLogin() {
        window.location.href = `https://jieligo.net/test/redirect_uri=${encodeURIComponent(window.location.href)}`
    }
}



const mapStateToProps = (state) => {
    return {
        showLoginPanel: state.showLoginPanel,
        is_Login: state.is_Login,
        login_user_data: state.login_user_data,
        waitingLogin: state.waitingLogin
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeLoginPanel: () => {
            dispatch(toggleLoginPanel(false))
        },
        openLoginPanel: () => {
            dispatch(toggleLoginPanel(true))
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        waitingLogin: () => {
            dispatch(waitingLogin(false))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
        initurl: (v) => {
            dispatch(initurl(v))
        },
        toggleSidebar: b => {
            dispatch(toggleSidebar(b))
        },
        logout: () => {
            dispatch(logout())
        },
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))