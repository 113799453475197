/* eslint-disable react/jsx-no-target-blank */
import { Checkbox, message, Pagination } from 'antd';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import BTN from '../../../StyleComponents/Button';
import Search from '../../total/Input/Search';
import setCategory from '../../good/method/setCategory';
import { get, post, } from '../../../netApi/fetch';
import importGoodToTuan from '../../good/method/importGoodToTuan';
import parseParams from '../../../methods/paramParse';
import GoodItem from '../../good/datalistitem';
import { INDEX_CONTENT_LIST_WIDTH, SIDEBAR_WIDTH, } from '../../../data/style';
import Loading from '../../Method/Loading';
import Select from '../../total/Select_';
import Prompt from '../../Method/Prompt';

interface props extends RouteComponentProps {
    user: user
    match: Match
    sidebarOpen: boolean
    isHema?: boolean
    mallInfo: mall.mallinfo
}
interface Match extends match {
    params: {
        type: string
    }
}

const CtrlBTN = styled(BTN)`
    width: 100%;
    margin-bottom: 12px;
`

export interface good {
    can_buy?: string;
    attribute: {
        bar_code: string
        extra: string
        goods_id: string
        id: string
        out_goods_id: string
        out_sku_id: string
        rank: string
        spec_item_path: string
        src: string
        status: string
        style_no: string
        unit: string
        updatetime: string
        weight: string
        price?: string
        goodsattrid?: string
        stock_num?: string
    }[]
    category_ids: (string | number)[]
    content: string
    createtime: string
    erp_goods_id: string
    erp_id: string
    erp_supplier_id: string
    goods_no: string
    id: string
    images: { id: string, src: string }[]
    is_multi_spec: string
    logo: string
    name: string
    operator: string
    rank: string
    sid: string
    spec_info_list: []
    status: string
    unit: string
    updatetime: string
    version: string
    mall_goods_id?: string
    is_participate_activity?: number
    goodsid?: string
    activity_id?: string
    is_on_sale?: boolean
    total_stock_num?: number
    checked?: boolean // 商品列表中选中商品时，表示是否被选中
}

export type category = {
    children: any[]
    createtime: string
    id: string
    name: string
    pid: string
    rank: string
    sid: string
    status: string
    uid: string
    deleted?: boolean
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 0;
    background: #fff;
    & > div:first-child{
        display: flex;
    }
    ._typelist{
        display: flex;
        & > a{
            padding-top: 15px;
            cursor: pointer;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #a3a3a3;
            transition: none;
            a:not(.active){color: #a3a3a3}
            div:first-child{
                margin-bottom: 10px;
            }
            div:last-child{
                width: 34px;
                height: 4px;
                background: #fff;
                border-radius: 2px;
            }
            &.active{
                font-size: 18px;
                font-weight: 600;
                color: var(--theme-main) ;
                div:last-child{
                    background: var(--theme-main) ;
                }
            }
        }

    }

`

const Main = styled.div`
    background-color: #fff;
    background-clip: content-box;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    & > div:first-child{
        flex: 1;
        display: flex;
        & > div{
            padding: 10px 10px 0;
            &:nth-child(1){
                & > div{
                    cursor: pointer;
                    background-color: #f5f5f5;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    width: 96px; height: 40px;
                    display: flex; align-items: center; justify-content: center;
                    .active{
                        color: var(--theme-main) ;
                        font-weight: 600;
                        background: #fff;
                    }
                }
            }
            &:nth-child(2){
                ._subCatList{
                    background-color: #f5f5f5;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    & > div{
                        cursor: pointer;
                        display: flex; align-items: center; justify-content: center;
                        width: 96px;height: 40px;
                        .active{
                            color: var(--theme-main) ;
                            font-weight: 600;
                            background: #fff;
                        }
                    }
                }
                table{
                    width: 100%;
                    thead{
                        background-color: #f5f5f5;
                        color: #333; font-size: 14px;
                        td{
                            padding: 18px 12px;
                        }
                        td:nth-child(1){width: 250px;}
                        /* td:nth-child(2){width: 350px;} */
                        td:last-child{width: 270px;}
                    }
                    tbody{
                        
                    }
                }
            }
        }
    }
    & > div:last-child{
        height: 60px;
        display: flex; align-items: center; justify-content: center;
        box-shadow: 0px 0px 6px 0px rgba(215,215,215,0.5); 
    }
`

const Page = styled.div<{ open: boolean }>`
    @media(min-width: 1600px){
        margin-left: ${props => props.open ? '200px' : 0};
    }
`



function List(props: props) {
    const limit = 10

    const [type, setType] = useState(4)
    const [search, setSearch] = useState('')
    const [Goodlist, setGoodList] = useState<good[]>()

    const [page, setPage] = useState(1)
    const [catid, setCatid] = useState<(number | string)[]>([0])
    const [count, setCount] = useState<number>(0)

    const key = useRef<{
        page: number,
        search: string,
        catid: (number | string)[],
        type: number,
    }>()
    const [eventList,] = useState<any[]>()
    const href = useRef(props.location.pathname + props.location.search)

    const [branch, setBranch] = useState<number>()
    const [branchList, setBranchList] = useState<mall.mallinfo[]>()
    useEffect(() => {
        console.log(props.isHema);
        if (props.isHema) {
            get('/index/getloginbindinfo', {}).then(res => {
                setBranchList(res.branch_mall_list)
            }).catch(e => message.error(e.message || e))
        }
    }, [props.isHema])

    useEffect(() => {
        const type = 4
        const data = parseParams(props.location.search)
        if ((props.location.pathname + props.location.search) === href.current) return
        href.current = props.location.pathname + props.location.search
        if (type) {
            setSearch(decodeURI(data.search || '') || '')
            setType(Number(type))
            setPage(Number(data.page) || 1)
            setCatid((data.cat || '0').split(',').map(i => Number(i)))
            setGoodList(undefined)
        }
    }, [props.match, props.location])

    useEffect(() => {
        function checkKey(): boolean {
            if (Number(page) !== Number(key.current.page)) return false
            if ((search) !== (key.current.search)) return false
            if (Number(catid[0]) !== Number(key.current.catid[0])) return false
            if (catid[1] || key.current.catid[1]) {
                if (Number(catid[1]) !== Number(key.current.catid[1])) return false
            }
            if (key.current.type !== type) return false
            return true
        }
        if (!Goodlist) {
            if (key.current && checkKey() && !branch) return
            key.current = {
                page: Number(page),
                search,
                catid: catid,
                type,
            }
            Loading.show('加载中')
            const data = {
                offset: (page - 1) * limit, limit,
                catid: catid[catid.length - 1] || 0,
                search,
                op: 'display',
                distribution_relation_id: branch || undefined,
            }
            if (!data.distribution_relation_id) delete data.distribution_relation_id
            get('/goods/getlistbysid', data).then(res => {
                if (checkKey()) {
                    Loading.hide()
                    setGoodList(res.list)
                    setCount(Number(res.count))
                }
            })
        }
    }, [Goodlist, page, catid, search, props.match, type, props.history, branch])

    const reload = useCallback(() => {
        function checkKey(): boolean {
            if (Number(page) !== Number(key.current.page)) return false
            if ((search) !== (key.current.search)) return false
            if (Number(catid[0]) !== Number(key.current.catid[0])) return false
            if (catid[1] || key.current.catid[1]) {
                if (Number(catid[1]) !== Number(key.current.catid[1])) return false
            }
            if (key.current.type !== type) return false
            return true
        }
        key.current = {
            page: Number(page),
            search,
            catid: catid,
            type,
        }
        Loading.show('加载中')
        const data = {
            offset: (page - 1) * limit, limit,
            catid: catid[catid.length - 1] || 0,
            search,
            op: 'display',
            distribution_relation_id: branch || undefined
        }
        if (!data.distribution_relation_id) delete data.distribution_relation_id
        get('/goods/getlistbysid', data).then(res => {
            if (checkKey()) {
                Loading.hide()
                setGoodList(res.list)
                setCount(Number(res.count))
            }
        })
    }, [page, search, catid, type, branch])

    const [catlist, setCatlist] = useState<category[]>()
    useEffect(() => {
        if (!catlist) {
            get('/goodscategory/getall', { sid: props.user.self_sid }).then(res => {
                setCatlist(res)
            })
        }
    }, [catlist, props.user.self_sid])

    useEffect(() => {
        const ua = navigator.userAgent.toLowerCase();
        if ((/MicroMessenger/i).test(ua)) return undefined
        if (!window.BroadcastChannel) return
        const bc = new BroadcastChannel('goodslist')
        window.name = 'jieligo_list' + Math.random()
        const cb = ((e) => {
            switch (e.data) {
                case 'reload':
                    reload()
                    break;
                default:
                    break;
            }
        })
        bc.addEventListener('message', cb)
        return () => {
            bc.removeEventListener('message', cb)
        }
    }, [reload])

    const main = useRef<HTMLDivElement>()

    const [mainHeight, setMainHeight] = useState(`calc(100vh - 50px - 60px - ${main.current?.offsetTop || 90}px - 20px)`)

    useEffect(() => {
        setMainHeight(`calc(100vh - 60px - 50px - ${main.current?.offsetTop || 90}px - 20px)`)
    }, [])

    const [selectAll, setSelectAll] = useState<boolean>(false)
    useEffect(() => {
        const hasUnCheckGood = Goodlist?.find(i => !i.checked)
        setSelectAll(!hasUnCheckGood)
    }, [Goodlist])

    const batchCategory = useCallback(() => {
        const hasCheckGood = Goodlist?.find(i => i.checked)
        if (!hasCheckGood) {
            message.warn('请先勾选商品')
            return
        }
        Prompt({
            type: 'select', placeholder: '请选择分类', title: '批量修改分类',
            selectConfig: {
                list: catlist,
                showKey: 'name'
            },
        }).then((res: category) => {
            return post('/goods/batchupdategoodscate', {
                cate_id: res.id,
                goods_id_list: Goodlist.filter(i => i.checked).map(i => i.goodsid)
            })
        }).then(() => {
            message.success('修改成功')
            reload()
        }).catch(e => {
            if (e) message.error(e.message)
        })
    }, [Goodlist, catlist, reload])

    return <Page className='flex-column' open={props.sidebarOpen} style={{ background: '#fff', }}>
        <Header>
            <div>
                <div className='_typelist'>
                    <NavLink to='/index/goods' style={{ marginRight: 36 }} exact className=''>
                        <div style={{ fontSize: 14, }}>团购商品库</div>
                        <div></div>
                    </NavLink>
                    <div className="flex-center">
                        {props.isHema && !!branchList && <Select list={branchList} showKey={'branch_name'} uni='branch' onChange={e => {
                            setGoodList(undefined)
                            setBranch(Number(e.distribution_relation_id))
                            route(1, catid.join(','), search)
                        }} value={branchList?.findIndex(i => Number(i.distribution_relation_id) === Number(branch))} placeholder={'选择门店'} />}
                    </div>
                    <div className='flex-box cur-pointer' style={{ marginRight: 16 }} onClick={() => {
                        setGoodList(Goodlist.map(i => {
                            return {
                                ...i, checked: !selectAll,
                            }
                        }))
                    }} >
                        <div style={{ width: 20, }}>
                            <Checkbox checked={selectAll} style={{}} />
                        </div>
                        <div>
                            当页全选
                        </div>
                    </div>
                    <BTN style={{ height: 30, alignSelf: 'center' }} onClick={batchCategory}>批量修改分类</BTN>
                </div>
            </div>
            <div>
                <Search onSearch={() => {
                    route(1, catid.join(','), search)
                }} search={search} onChange={setSearch} placeholder='搜索商品名称、编码、条码' outerStyle={{ width: '14em', padding: '4px 12px' }} />
            </div>
        </Header>
        <Main ref={main}>
            <div>
                <div style={{ maxHeight: mainHeight, overflowY: 'auto' }}>
                    {type !== 3 && <div style={(Number(catid[0]) === 0) ? { background: '#fff', color: `var(--theme-main)` } : {}} onClick={() => {
                        route(1, '0', search)
                    }}>全部</div>}

                    {type !== 3 && (catlist || []).map(i => {
                        if (i.deleted) return null
                        return <div key={i.id} onClick={() => {
                            route(1, [i.id].join(','), search)
                        }} style={(Number(catid[0]) === Number(i.id)) ? { background: '#fff', color: `var(--theme-main)` } : {}}>
                            {i.name}
                        </div>
                    })}

                    {type !== 3 && <div >
                        <BTN color='red' onClick={() => {
                            setCategory(props.user.self_sid, (list) => {
                                setCatlist(list)
                            })
                        }} >+添加分类</BTN>
                    </div>}
                </div>
                <div style={{ flex: 1, maxHeight: mainHeight, overflowY: 'auto' }}>
                    <table>
                        <thead>
                            <tr>
                                {type === 4 && <td style={{ width: 36 }}> </td>}
                                <td style={{ padding: '6px 12px' }}>商品名</td>
                                <td style={{ padding: '6px 12px' }}>规格</td>
                                <td style={{ padding: '6px 12px' }}>{type !== 1 ? '价格' : ''}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(Goodlist || []).map(i => {
                                return <GoodItem checked={i.checked} onCheck={(checked) => {
                                    i.checked = checked
                                    setGoodList([...Goodlist])
                                }} type={type} isBranch={props.user.is_branch} key={i.id || i.goodsid} data={i} eventList={eventList} reload={() => {
                                    reload()
                                }} />
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='hiddenScrollBar  flex-box' style={{ height: mainHeight, overflowY: 'auto', flexDirection: 'column' }}>
                    {type === 4 && <div style={{ paddingTop: 12, marginRight: 16, }}>
                        <CtrlBTN color='red' style={{ fontSize: 12, }} onClick={() => {
                            importGoodToTuan().then(res => {
                                if (res) reload()
                            })
                        }} >批量导入商品</CtrlBTN>
                    </div>}
                    {type === 4 && <div style={{ paddingTop: 12, marginRight: 16, }}>
                        <a href={'/good/create/_'} target="_blank" >
                            <CtrlBTN solid color='red' style={{ fontSize: 12, }} >添加团购商品</CtrlBTN>
                        </a>
                    </div>}
                </div>
            </div>
            <div style={{
                position: 'fixed', bottom: 0,
                left: SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH,
                width: `calc(100vw - ${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px)`,
                background: '#fff',
            }}>
                <div style={{ flex: 1, }} className='flex-center'>
                    <Pagination current={page} pageSize={limit} total={count} showSizeChanger={false} onChange={e => {
                        route(e, catid.join(','), search)
                    }} />
                </div>
            </div>
        </Main>
    </Page>

    function route(page: number, cat: string, search: string,) {
        props.history.push(`${props.location.pathname}?page=${page || 1}&cat=${cat}&search=${search}`)
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.login_user_data,
        mallInfo: state.mallInfo,
        isHema: state.isHema,
    }
}
export default connect(mapStateToProps)(List)