import styled from "styled-components";

export const Table = styled.table`
    thead{
        display: table-header-group;
        font-weight: bold;
        tr:first-child{
            td{
                font-size: 24px;
            }
        }
        tr:last-child{
            td{
                text-align: center;
            }
        }
    }
    tbody{
        td{
            border: 1px solid #000;
            text-align: center;
            line-height: 2;
        }
    }
    *{
        color: #000;
        font-family: SimHei;
    }
    border-collapse: collapse;
    width: 90%;
    margin: 20px auto 60px;
    &:nth-child(2n){
        page-break-after: always;
    }
    @media print{
        margin: 30px auto 60px;
    }
`