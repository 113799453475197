import { get } from "../../netApi/fetch";

export function getexpresspickuppointlistbytuanid(tuanid) {
  get("/address/getexpresspickuppointlistbytuanid", {
    types: 1,
    tuanid,
    offset: 0,
    limit: 200300,
  }).then((res) => {
    this.setState({
      showMore: true,
      selectedExpressToAddress: res.list.map((i) => i.id),
    });
  });
}
