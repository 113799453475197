
import React, { useState, useEffect, useRef, } from 'react';
import styled from 'styled-components';
import parseParams from '../../../methods/paramParse';
import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import Search from '../../total/Input/Search';
import { SIDEBAR_WIDTH, INDEX_CONTENT_LIST_WIDTH, PAGINATION_HEIGHT, TOPBAR_HEIGHT, } from '../../../data/style';
import { message, Pagination } from 'antd';
import Select from '../../total/Select_';
import { get } from '../../../netApi/fetch';
import OrderItem from '../../order/item';
import { ExportPickData } from '../../mall/Method/ExportPickData';
import { connect } from 'react-redux';
import Loading from '../../Method/Loading';
import batch from '../../order/methods/batch';
import Toast from '../../Method/Toast';
const OptionsLine = styled.div<{ option: string }>`
    position: absolute;
    bottom: 0;
    top: 0;
    background: var(--theme-main) ;
    border-radius: 2px;
    height: 2px;
    transition: all .3s;
    width: ${props => {
        if (!props.option) {
            return 0
        }
        // (option && option.length === 3) ? '2em' : '3em'
        switch ((props.option || '').length) {
            case 3:
                return '2em'
            case 6:
                return 'calc(4px + 5.5em)'
            default:
                return '3em'
        }
    }};
    left: ${props => {
        switch (props.option) {
            case 'all':
                return 0
            case 'notuse':
                return 'calc(3em )'
            case 'used':
                return 'calc(10em - 4px)'
            case 'applyrefund':
                return 'calc(100% - 7em - 4px)'
            case 'refundsuccess':
                return 'calc(100% - 3em)'
            default:
                return 0
        }
    }};
`

const Page = styled.div`
    position: fixed;
    width: calc(100vw - ${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px - 128px);
    height: ${PAGINATION_HEIGHT}px;
    left: calc( ${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px 0px rgba(215,215,215,0.50); 
    background: #fff;
`

const Side = styled.div`
    width: 128px;
    right: 0;
    top: ${TOPBAR_HEIGHT + 70}px;
    height: calc(100vh - 70px - ${TOPBAR_HEIGHT}px);
    background-color: #f7f7f7;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    button{
        width: 108px;
        height: 32px;
        /* background: #ffffff; */
        border-radius: 4px;
        /* color: #666; */
        font-size: 14px;
        border: 0;
        margin-bottom: 16px;
        transition: all .3s;
        color: #fff;
        background-color: var(--theme-main);
        &:hover{
            filter: brightness(.9);
        }
    }
`

interface props extends RouteComponentProps {
    match: Match
    user: user
}
interface Match extends match {
    params: {
        id: string
    }
}

type orderType = 'all' | 'my' | 'seller'

function List(props: props) {
    useEffect(() => {
        document.body.style.background = '#fff'
        return () => {
            document.body.style.background = ''
        }
    })
    const [option, setOption] = useState<string>()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [count, setCount] = useState(0)

    const [type, setType] = useState<orderType>('all')
    const typelist: { title: string, value: orderType }[] = [
        { title: '全部订单', value: 'all' },
        { title: '我的订单', value: 'my' },
        { title: '好友订单', value: 'seller' },
    ]

    const [orderList, setOrderList] = useState<order[]>()
    const [expressList, setExpressList] = useState<any[]>()

    const getKey = useRef<any>()
    useEffect(() => {
        const params = parseParams(props.location.search)
        setOption(params.option || 'all')
        setPage(Number(params.page || 1))
        setType(params.type as orderType || 'all')
        setSearch(params.search || '')
        getKey.current = {
            type: params.type as orderType || 'all',
            option: params.option || 'all',
            search: params.search || '',
            page: Number(params.page || 1),
        }
        const data = {
            order_op: params.type as orderType || 'all',
            status_type: params.option || 'all',
            shipping_mode: 'all',
            search: params.search || '',
            offset: (Number(params.page || 1) - 1) * 10,
            limit: 10,
            from: 'mall',
            review_op: 'for_me',
            relation_id: '',
            mall_id: '',
        }

        if (props.match.params.id) {
            data.mall_id = (props.match.params.id.split(',')[0])
            data.relation_id = (props.match.params.id.split(',')[1])
        }else{
            data.from = 'all'
        }

        setOrderList([])
        Loading.show('加载中')
        get('/mall/getorderlist', data).then(res => {
            function paramsChange(e) {
                if (e.page === getKey.current.page && e.option === getKey.current.option && e.type === getKey.current.type && e.search === getKey.current.search) return false
                return true
            }
            if (paramsChange({
                type: params.type as orderType || 'all',
                option: params.option || 'all',
                search: params.search || '',
                page: Number(params.page || 1),
            })) {
                return
            }
            Loading.hide()
            setCount(res.count)
            setOrderList(res.list)
        }).catch(e => {
            Loading.hide()
            message.error(e.message)
        })
    }, [props.location.search, props.match.params.id])


    const [branchlist, setBranchList] = useState<any[]>()
    useEffect(() => {
        if (props.user.is_branch === false && !branchlist) {
            get('/mall/getbranchmalllist', {
                offset: 0, limit: 99999, mall_id: props.user.bind_mall_id,
            }).then(res => {
                setBranchList(res.list.map(i => { return { ...i, showName: i.name + ' - ' + i.branch_name } }))
            }).catch(e => { if (e.message) message.error(e.message) })
        }
    }, [props.user, branchlist])

    useEffect(() => {
        if (!expressList) {
            get('/express/getExpressCompany', {}).then(res => {
                setExpressList(res)
            })
        }
    }, [expressList])

    function route(data: any) {
        const s = {
            option,
            page,
            type,
            search,
        }
        if (data.option) s.option = data.option
        if (data.page) s.page = data.page
        if (data.type) s.type = data.type
        if (data.search) s.search = data.search
        let href = `${props.location.pathname}?option=${s.option}&page=${s.page}&type=${s.type}&search=${s.search}`
        if (data.v) {
            href += `&${data.v}`
        }
        props.history.push(href)
    }

    return <div style={{ background: '#fff', padding: '8px 16px ', marginRight: 128 }}>
        <div className='flex-between' style={{ marginBottom: 14 }}>
            <div className='flex-between' style={{ width: '21em', position: "relative", padding: '8px 0', }}>
                {[
                    { key: 'all', name: '全部' },
                    { key: 'notuse', name: '未核销/发货' },
                    { key: 'used', name: '已完成' },
                    { key: 'applyrefund', name: '退款中' },
                    { key: 'refundsuccess', name: '已退款' },
                ].map(i => {
                    const active = { color: `var(--theme-main)` }
                    return (
                        <div key={i.key} className='fcc cur-pointer' onClick={() => {
                            route({ page: 1, option: i.key })
                        }}>
                            <span style={option === i.key ? active : {}}>{i.name}</span>
                        </div>
                    )
                })}
                <div style={{ position: 'absolute', width: '100%', bottom: 0, height: 4 }}>
                    <OptionsLine option={option} ></OptionsLine>
                </div>
            </div>
            <div style={{ width: 196, }}>
                <Search placeholder='搜索用户名、手机号、订单号' search={search} onSearch={() => {
                    route({ page: 1, })
                }} onChange={setSearch} outerStyle={{ width: '14em', padding: '4px 12px', }} inputStyle={{ fontSize: 12 }} />
            </div>
        </div>
        <div>
            <div style={{ width: 90 }}>
                <Select inputStyle={{
                    background: 'var(--theme-main)', color: '#fff', border: 0,
                }} list={typelist} showKey='title' value={typelist.findIndex(i => i.value === type)} uni='ordertype' onChange={e => {
                    route({ page: 1, type: e.value, })
                }} />
            </div>
        </div>
        <div style={{ paddingBottom: 80, }}>
            {(orderList || []).map(i => {
                return <OrderItem key={i.orderid} data={i} expressList={expressList || []} print={() => {
                    props.history.push({
                        pathname: '/orders/print',
                        state: {
                            list: [i]
                        }
                    })
                }} send={() => { route({ v: (Math.random() * 10000).toFixed(0) }) }} />
            })}
        </div>
        <Page>
            <Pagination current={page} showSizeChanger={false} pageSize={10} onChange={e => {
                route({ page: e })
            }} total={count} />
        </Page>
        <Side>
            <button color='red' style={{}} onClick={() => {
                ExportPickData({
                    type: '1',
                    isBranch: !branchlist,
                    branchlist: branchlist,
                    branch_id: props.user.is_branch ? Number(props.user.bind_mall_id) : undefined,
                    relation_id: (props.match.params.id?.split(',')[1])
                })
            }}>导出数据</button>
            <button color='red' style={{}} onClick={() => {
                ExportPickData({
                    type: '2',
                    isBranch: !branchlist,
                    branchlist: branchlist,
                    branch_id: props.user.is_branch ? Number(props.user.bind_mall_id) : undefined,
                    relation_id: (props.match.params.id?.split(',')[1])
                })
            }}>打印单据</button>
            <button style={{ color: '#333', background: '#fff' }} onClick={() => {
                batch().then(res => {
                    if (res === 'export') {
                        
                        ExportPickData({
                            type: '1',
                            isBranch: !branchlist,
                            branchlist: branchlist,
                            branch_id: props.user.is_branch ? Number(props.user.bind_mall_id) : undefined
                        })
                        return
                    }
                    if (res === true) {
                        Toast.show('批量发货成功', 'success', 2000)
                        route({ v: (Math.random() * 10000).toFixed(0) })
                    }
                })
            }}>批量发货</button>
        </Side>
    </div>
}

const mapStateToProps = (state) => {
    return {
        user: state.login_user_data
    }
}
export default connect(mapStateToProps)((withRouter(List)))
