import React, { Component } from 'react'
import { connect } from 'react-redux';
// import BanUnLogin from '../../high-component/BanUnLogin'
import Main from '../../high-component/Content'
import Loading from '../total/Loading';

import { OFFSET_LIMIT as LIMIT } from '../../data/options';
import { get } from '../../netApi/fetch';

class Follow extends Component {

    getList(e) {
        if (this.offset >= this.state.total && this.state.total !== -1) {
            return
        }
        if (this.state.loading) return
        let bottom
        if (e) {
            const offsetY = window.pageYOffset
            const scrollHeight = e.srcElement.body.scrollHeight
            bottom = scrollHeight - offsetY
        }
        // console.log(this.state,this.offset)
        if (!bottom || bottom < 2000) {
            this.setState({ loading: true }, () => {
                get('/serviceprovider/myalllower', {
                    offset: this.offset,
                    limit: LIMIT,
                    search: this.state.search
                }).then(res => {
                    this.offset = this.offset + res.list.length
                    this.setState({
                        list: [...this.state.list, ...res.list],
                        loading: false,
                        total: res.count,
                        all: res.count,
                        thisMonth: res.this_month_lower_count,
                    })
                })
            })
        }
    }
    dosearch(e) {
        if (e.key !== 'Enter') return
        this.offset = 0
        this.setState({
            search: e.target.value,
            list: [],
            total: -1,
        })
        this.getList()

    }
    constructor(p) {
        super()
        this.state = {
            list: [],
            loading: false,
            total: -1,
            search: '',
            searchText: '',
        }
        this.offset = 0
        if (p.didLogin) {
            if (Number(p.userType) !== -1) {
                if (Number(p.userType) === 5 || Number(p.userType) === 0) {
                    p.history.push('/payment/member/4')
                }
            }
        }
    }
    handleScroll = this.getList.bind(this)
    componentDidMount() {
        document.title = '我的粉丝'
        this.getList()
        document.addEventListener('scroll', this.handleScroll)
    }
    componentWillUnmount() {
        document.title = '接力GO'
        document.removeEventListener('scroll', this.handleScroll)
    }
    componentDidUpdate(prev) {
        const p = this.props
        if (p.didLogin) {
            if (Number(p.userType) !== -1) {
                if (Number(p.userType) === 5 || Number(p.userType) === 0) {
                    p.history.push('/payment/member/4')
                }
            } else {
                if (!prev.didLogin && p.didLogin) {
                    this.getList()
                }
            }

        }
    }
    render() {
        return (
            <div style={{ paddingTop: 50 }}>
                <div style={{ padding: '0 20px', position: 'relative' }}>
                    <input style={{
                        backgroundColor: '#eaeaea', fontSize: 16, padding: '4px 30px', border: 'none', width: '100%',
                    }} type='search' onKeyUp={this.dosearch.bind(this)} value={this.state.searchText} onChange={(e) => {
                        this.setState({
                            searchText: e.target.value
                        })
                    }} />
                    <i className='iconfont icon-seekicon' style={{ position: 'absolute', left: 30, top: 4 }}></i>
                    <i className='iconfont icon-quxiaozhuanhuan' style={{ position: 'absolute', right: 30, top: 4 }} onClick={() => {
                        this.setState({
                            search: '',
                            searchText: '',
                            list: [],
                            total: -1,
                        }, () => {
                            this.offset = 0
                            this.getList()
                        })
                    }}></i>
                </div>
                <div style={{ boxShadow: '0 8px 19px 0 rgba(219,219,219,0.50)', margin: '20px auto', maxWidth: 900, }} className='fcc'>
                    <div className='fcc' style={{ flexDirection: 'column' }}>
                        <div>本月粉丝</div>
                        <div>
                            <span style={{ fontSize: 32 }}>{this.state.thisMonth}</span>
                            <span style={{ fontSize: 16 }}>人</span>
                        </div>
                    </div>
                    <div className='fcc'>
                        <div style={{ width: 4, height: 30, margin: '0 20px', backgroundColor: '#fd5356' }}>

                        </div>
                    </div>
                    <div className='fcc' style={{ flexDirection: 'column' }}>
                        <div>累计粉丝</div>
                        <div>
                            <span style={{ fontSize: 32 }}>{this.state.all > 0 ? this.state.all : 0}</span>
                            <span style={{ fontSize: 16 }}>人</span>
                        </div>
                    </div>
                </div>
                <div style={{ padding: 20 }}>
                    <table style={{ tableLayout: 'fixed', width: '100%', borderSpacing: '0px 10px' }}>
                        <thead>
                            <tr>
                                <td style={{ fontSize: 18, color: '#969696' }}>名称</td>
                                <td style={{ fontSize: 18, color: '#969696', textAlign: 'center' }}>信息</td>
                                <td style={{ fontSize: 18, color: '#969696', textAlign: 'right' }}>日期</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.list.map(item => {
                                return (
                                    <tr key={item.id} style={{ marginBottom: 20 }}>
                                        <td style={{ fontSize: 14, color: '#1f1f1f' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fcc sm-hide' style={{ width: 30, height: 30, minWidth: 30, borderRadius: '50%', overflow: 'hidden' }}>
                                                    <img src={item.logo} alt={item.name} style={{ width: 30, height: 30 }} />
                                                </div>
                                                <span>
                                                    {item.name}
                                                </span>
                                            </div>
                                        </td>
                                        <td style={{ fontSize: 20, color: '#fd5356', textAlign: 'center', padding: '10px 0' }}>{item.level_name}</td>
                                        <td style={{ fontSize: 14, color: '#969696', textAlign: 'right' }}>{item.createtime}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <Loading /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        userType: state.userType,

    }
}

export default (Main(connect(mapStateToProps)(Follow)))
// export default BanUnLogin(Main(connect(mapStateToProps)(Follow)))