import React, { useRef, useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { getToken, } from '../../../netApi/fetch'
import styled from 'styled-components'
import { message } from 'antd'
import BTN from '../../../StyleComponents/Button'
import { confirm as Confirm } from '../../Method/Confirm';
import { API } from '../../../data/options'


interface props {
    onClose: () => void
    data: {

    }
}

let wrapper: Element

export const Border = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    & > div{
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        & > div{
            margin-right: 30px;
        }
        input{
            width: 333px;
            border: 1px solid #979797;
            border-radius: 4px;
            padding: 3px;
        }
    }
`

export default async function () {
    return new Promise(async (reslove, reject) => {
        function Category(props: props) {
            const data = useRef<FormData>()
            const [shouldClose, setShouldClose] = useState(false)
            const [file, setFile] = useState<File>()
            const [loading, setloading] = useState(false)
            return <Modal title='批量导入商品' onClose={props.onClose} loading={loading} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ width: 640, height: 450, overflowY: 'auto' }}>
                    <Border>
                        <div>
                            <div>下载表格模板并填写</div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <a download href="https://jieligo-1259536745.cos.ap-guangzhou.myqcloud.com/uploads/wxacode/商品批量上传到商品信息库.xlsx">
                                    <BTN solid color='red'>
                                        下载模版
                                    </BTN>
                                </a>
                            </div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <a download href={`${API}/warehouse/categorylistexport?token=${getToken()}&types=1`}>
                                    <BTN color='red'>
                                        下载分类编号表
                                    </BTN>
                                </a>
                            </div>
                        </div>
                        <div style={{ fontSize: 12, color: '#8b8b8b' }}>分类编号表说明：用于填写表格模板分类栏时，查找分类编号</div>
                        <div>
                            <div>上传表格</div>
                            <label style={{ cursor: 'pointer' }} onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <input type='file' id='file' className='cur-pointer' accept='.xlsx' style={{ padding: 2, borderWidth: 1, borderColor: '#d9d9d9' }}
                                    onChange={e => {
                                        if (!e || !e.target || !e.target.files || !e.target.files[0]) return
                                        const file = e.target.files[0]
                                        setFile(file)
                                    }} />
                                <span style={{ color: '#3d83d8', marginLeft: 20 }}>重新选择</span>
                            </label>
                        </div>
                    </Border>
                </div>
            </Modal>

            function confirm() {
                setloading(true)
                const formData = new FormData()
                formData.append('file', file)
                formData.append('types', '1')
                formData.append('token', getToken())
                data.current = formData
                file.slice(0, 1).arrayBuffer().then(() => {
                    fetch(API + '/warehouse/batchcreategoods', {
                        method: 'post', body: data.current, mode: 'cors',
                        credentials: 'include',
                    }).then((res) => {
                        setloading(false)
                        res.json().then((res) => {
                            if (res.code !== 0) {
                                Confirm({
                                    text: res.message
                                })
                                return
                            }
                            message.success('上架成功')
                            setShouldClose(true)
                            reslove(true)
                        })
                    }).catch(() => {
                        setloading(false)
                        setFile(undefined)
                        var file = document.getElementById('file') as HTMLInputElement;
                        file.value = ''
                        message.error('网络错误')
                    })
                }).catch(() => {
                    setloading(false)
                    setFile(undefined)
                    var file = document.getElementById('file') as HTMLInputElement;
                    file.value = ''
                    message.error('文件发生变动，请重新选择文件')
                })
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Category data={{}} onClose={() => {
            ReactDOM.render(null, wrapper)
            if (wrapper) {
                document.body.removeChild(wrapper)
                wrapper = undefined
            }
        }} />, wrapper)
    })
}