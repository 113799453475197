
type competence = {
    VALUE: boolean,
    get: () => boolean,
    set: (boolean) => void
}

export const admin: competence = { // 管理员数量是否已达上限
    VALUE: false,
    get() {
        return this.VALUE
    },
    set(value) {
        this.VALUE = value
    }
}

export const seller: competence = { // 帮卖好友数量是否已达上限
    VALUE: false,
    get() {
        return this.VALUE
    },
    set(value) {
        this.VALUE = value
    }
}

export const express: competence = { // 查询快递次数是否已达上限
    VALUE: false,
    get() {
        return this.VALUE
    },
    set(value) {
        this.VALUE = value
    }
}

export const storage: competence = { // 图片视频空间是否已达上限
    VALUE: false,
    get() {
        return this.VALUE
    },
    set(value) {
        this.VALUE = value
    }
}

export function setStorage(value: boolean){
    storage.set(value)
}

export function getStorage(): boolean{
    return storage.get()
}