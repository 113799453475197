import React, { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import Modal from '../total/Modal';
let confirmWrap: Element

interface Props {
    text?: string | JSX.Element,
    title?: string,
    confirmText?: string,
    cancelText?: string,
    width?: number
}

export const confirm = (props: Props) => {
    return new Promise<boolean>((res, rej) => {
        function Confirm(props: Props) {
            const [shouldClose, close] = useState(false)
            const result = useRef<boolean>()
            useEffect(() => {
                const cb = (e: KeyboardEvent) => {
                    e.preventDefault()
                    if (e.key === 'Enter') {
                        res(true)
                        close(true)
                    }
                }
                window.addEventListener('keypress', cb)
                return () => {
                    window.removeEventListener('keypress', cb)
                }
            })
            return <Modal title={props.title || ''} banAnime banMaskClose shouldClose={shouldClose} cancel={true} confirmText={props.confirmText || '确认'} cancelText={props.cancelText || '取消'}
                onClose={() => {
                    ReactDom.render(<></>, confirmWrap)
                    if (confirmWrap) {
                        document.body.removeChild(confirmWrap)
                        confirmWrap = undefined
                    }
                    if (result.current === undefined) {
                        // rej()
                        return
                    }
                    res(result.current)
                }}
                onReject={() => {
                    result.current = false
                    close(true)
                }}
                onConfirm={() => {
                    result.current = true
                    close(true)
                }} >
                <div style={{ width: props.width || 330 }}>{props.text || ''}</div>
            </Modal>
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDom.render(<Confirm {...props} />, confirmWrap)
    })

}