import styled from "styled-components"

export const Nav = styled.div<{ active?: number }>`
    height: 70px;
    background: #fff;
    color: #999;
    display: flex;
    box-shadow: 0px 2px 3px 0px #f2f2f2; 
    position: relative;
    & > *{
        cursor: pointer;
        width: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        color: #999;
        padding-top: 6px;
        transition: background-color .3s, color .3s;
        i{
            font-size: 18px;
            line-height: 1;
            margin-bottom: 4px;
        }
        font-size: 12px;
        &:nth-child(${props => props.active}){
            color: var(--theme-main) ;
            background:#f7f7f7;
            font-weight: 600;
        }
        &:hover{
            background: var(--theme-main) ;
            color: #fff;
            font-weight: 600;
        }
        div{
            font-size: 14px
        }
    }
`

export const Num = styled.div`
    position: absolute;
    left: 48px;
    top: 6px;
    background: var(--theme-main) ;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    border-radius: 9px;
    padding: 0 6px;
    color: #fff;
    z-index: 4;
    *:hover>& {
        color: var(--theme-main) ;
        background: #fff;
    }
`