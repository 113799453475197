import React, { Component } from 'react';

const goodsStatus = ['', '买家未收到货', '买家已收到货', '买家已退货']

class Item extends Component {

    constructor(props) {
        super()
        this.state = {
            data: {}
        }
        const source = Object.keys(props.data).map(key => {
            let value = props.data[key]
            if (key.slice(0, 3) === 'gmt') {
                value = String(GMTStringToDate(value))
            }
            return [key, value]
        })
        for (const key of source) {
            this.state.data[key[0]] = key[1]
        }
        this.state.data.status = statusFormat(this.state.data.status)
    }

    componentDidMount() {

    }

    render() {
        return <div className='aliRefundItem'>
            <table style={{}}>
                <thead>
                    <tr>
                        <td>产品名</td><td>申请退款时间：{this.state.data.gmtApply}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{this.state.data.productName}</td><td>退款金额：{(this.state.data.refundPayment / 100).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>退款原因：{this.state.data.applyReason}</td><td>商品状态：{goodsStatus[this.state.data.goodsStatus]}</td>
                    </tr>
                    <tr>
                        <td colSpan='2'>退款状态：{this.state.data.status}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

export default Item

function GMTStringToDate(str) {
    const ret = new Date(0)
    ret.setFullYear(Number(str.slice(0, 4)))
    ret.setMonth(Number(str.slice(4, 6)) - 1)
    ret.setDate(Number(str.slice(6, 8)))
    ret.setHours(Number(str.slice(8, 10)))
    ret.setMinutes(Number(str.slice(10, 12)))
    ret.setSeconds(Number(str.slice(12, 14)))
    ret.setUTCMilliseconds(Number(str.slice(14, 17)))
    const timeZone = Number(str.slice(-5, -2))
    if (timeZone !== 8) {
        ret.setHours(ret.getHours() + 8 - timeZone)
    }
    return `${ret.getFullYear()}年${ret.getMonth()}月${ret.getDate()}日 
    ${ret.getHours() < 10 ? '0' + ret.getHours() : ret.getHours()}:${ret.getMinutes() < 10 ? '0' + ret.getMinutes() : ret.getMinutes()}:${ret.getSeconds() < 10 ? '0' + ret.getSeconds() : ret.getSeconds()}`
}

function statusFormat(str) {
    switch (str) {
        case 'waitselleragree':
            return '等待卖家同意'
        case 'refundsuccess':
            return '退款成功'
        case 'refundclose':
            return '退款关闭'
        case 'waitbuyermodify':
            return '待买家修改'
        case 'waitbuyersend':
            return '等待买家退货'
        case 'waitsellerreceive':
            return '等待卖家确认收货'
        default:
            return str
    }
}