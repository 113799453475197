
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

const Panel = styled.div`
    padding: 16px 20px 0;
    display: flex;
    color: #999;
    font-size: 12px;
    transition: background-color .3s;
    cursor: pointer;
    &:hover{
        background-color: var(--theme-main)!important ;
        color: #fff;
        *{
            color: #fff !important;
            border-color: #fff !important;
        }
    }
    & > img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    & > div{
        padding-bottom: 16px;
        border-bottom: .5px solid #eee;
        flex: 1;
        & > div:not(:last-child){
            margin-bottom: 10px;
        }
    }
    .strong{
        color: #333;
        font-weight: 600;
        font-size: 14px;
    }
    .branchName{
        color: #fff;
        background-color: var(--theme-main) ;
        padding: 2px 4px;
        margin-left: 6px; 
        border-radius: 3px;
    }
`

interface props extends RouteComponentProps {
    data: distributionMall
}

function Item(props: props) {
    const { data } = props
    const matchid = props.location.pathname.split('/')
    const active = matchid[3] === (data.mall_id + ',' + data.relation_id)
    return <Panel style={{
        background: active ? '#e9e9e9' : ''
    }} onClick={() => { props.history.push(`/index/s/${data.mall_id},${data.relation_id}/orders`) }}>
        <img src={data.logo} className='img-center' alt='' />
        <div>
            <div>
                <span className='strong'>{data.name}</span>
                {data.branch_name.length > 0 ? <span className='branchName'>{data.branch_name}</span> : null}
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 20, }}>
                    <span className='strong'>{data.visitor_count}</span>人访问
                </div>
                <div style={{ marginRight: 20, }}>
                    <span className='strong'>{data.orders_count_by_people}</span>人参与
                </div>
                <div>
                    <span className='strong'>{data.orders_count}</span>个订单
                </div>
            </div>
            <div>
                {data.createtime}
            </div>
        </div>
    </Panel>
}

export default withRouter(Item)

export interface distributionMall {
    name: string
    branch_name: string
    logo: string
    createtime: string
    mall_id: string
    relation_id: string
    parent_relation_id: string
    sid: string
    visitor_count: string
    orders_count: string
    orders_count_by_people: string
    has_new_distribution_goods: boolean
}

