import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { showLoading } from '../../reducers/index'
import Main from '../../high-component/Content'
import { Head, Filter, MainPage, Page } from '../../StyleComponents/SimpleFilterList';
import { get } from '../../netApi/fetch';
import parse from '../../methods/paramParse';

import Search from '../total/Input/Search';
import Select from '../total/Select_';
import { message, Pagination } from 'antd';

import Setting from './Setting';
import Level, { GroupItem } from './Level';
import Item from './Item';
import ApplyList from './ApplyList';

interface Props extends RouteComponentProps {
    showLoading: () => void,
    hideLoading: () => void,
    login_user_data: LoginUserData,
}

interface State {
    getData: {
        sorttype: number,
        sort: string,
        search: string,
        page: number,
    },
    searchKey: string,
    distributionSetting?: distributionSetting,
    showSetting?: boolean,
    showLevel?: boolean,
    showApply?: boolean,

    list?: RankItem[]
    total?: number,
    teamList?: GroupItem[]
}

export interface RankItem {
    shopinfo: shopinfo,
    uid: string | number,
    tuan_total: number,
    sale_total: string,
    pay_rebates: string,
    createtime: string,
    team_id: string,
    team_name: string,
}

export interface distributionSetting {
    is_need_apply: boolean,  // 需要申请才能帮卖
    is_allow_recommend_seller: boolean,  // 允许推荐好友
    can_recommend_team: number[],  // 允许推荐好友的好友的分组列表 -1为全部， 0为未分组的好友
    unified_price_commission_percent: number | string, // 统一定价返佣比例
    self_define_price_commission_percent: number | string, // 自由定价返佣比例
}



class List extends Component<Props>{

    readonly state: State = {
        getData: {
            sorttype: 1,
            sort: 'desc',
            search: '',
            page: 1
        },
        searchKey: '',
    }

    type = [
        { type: 1, name: '按销售额' },
        { type: 2, name: '按成为团长的时间' },
    ]

    gettingGroup: boolean = false
    gettingSetting: boolean = false

    componentDidMount() {
        this.parseParam().then(() => {
            this.getList()
        })
    }

    componentDidUpdate(prevProps: Props) {
        if ((prevProps.location.search !== this.props.location.search) || (!prevProps.login_user_data.self_sid && this.props.login_user_data.self_sid)) {
            this.parseParam().then(() => {
                this.getList()
            })
        }
        if ((this.props.login_user_data.self_sid)) {
            if (!this.state.teamList) this.getGroup()
            if (!this.state.distributionSetting) this.getSetting()
        }
    }

    render() {
        const baseHref = '/fs/list'
        return <div style={{ position: 'relative' }}><MainPage>
            <Head className='flex-between'>
                <div style={{ fontSize: 20, fontWeight: 600 }}>{this.state.total ? this.state.total + '人' : ''}正在帮我卖</div>
                <Search outerStyle={{ width: '20em' }} placeholder='搜索用户微信昵称或小程序昵称' search={this.state.searchKey} onChange={(e: string) => this.setState({ searchKey: e })} onSearch={() => {
                    this.props.history.push(`${baseHref}?page=1&search=${this.state.searchKey}&sort=${this.state.getData.sort}&type=${this.state.getData.sorttype}`)
                }} />
            </Head>
            <div className='flex-box' style={{ padding: '20px 0' }}>
                <div className='button_hollow_red' style={{ padding: '4px 11px', marginRight: 11, }} onClick={() => { this.setState({ showSetting: true }) }}>
                    帮卖设置
                </div>
                <div className='button_hollow_red' style={{ padding: '4px 11px', marginRight: 11, }} onClick={() => { this.setState({ showLevel: true }) }} >
                    帮卖好友分组设置
                </div>
                <div className='button_hollow_red' style={{ padding: '4px 11px', marginRight: 11, }} onClick={() => { this.setState({ showApply: true }) }} >
                    申请列表
                </div>
            </div>
            <Filter className='flex-box'>
                <div className='flex-box'>
                    <div style={{ fontWeight: 600 }}>排序方式</div>
                    <Select list={this.type} uni='followType' inputStyle={{
                        color: 'var(--theme-main)', background: 'transparent', border: 0, width: `${this.state.getData.sorttype === 1 ? 7 : 11}em`, fontWeight: 600
                    }} showKey='name' onChange={e => {
                        this.props.history.push(`${baseHref}?page=1&search=${this.state.getData.search}&sort=${this.state.getData.sort}&type=${e.type}`)
                    }} value={this.type.findIndex(i => i.type === this.state.getData.sorttype)} />
                </div>
                <div className='cur-pointer' style={{ color: 'var(--theme-main)', fontWeight: 600, marginLeft: 30 }} onClick={() => {
                    this.props.history.push(`${baseHref}?page=1&search=${this.state.getData.search}&sort=${this.state.getData.sort === 'asc' ? 'desc' : 'asc'}&type=${this.state.getData.sorttype}`)
                }}>
                    <span>
                        {this.state.getData.sort === 'asc' ? '从少到多' : ''}
                        {this.state.getData.sort === 'desc' ? '从多到少' : ''}
                    </span>
                    <i className='iconfont icon-paixu1' style={{ fontSize: 14, }} />
                </div>
            </Filter>
            <div className=''>
                {this.state.list?.map((item: RankItem) => {
                    return <Item teamList={this.state.teamList || []} key={item.uid} data={item} reload={this.getList.bind(this)} />
                })}
            </div>
            <Page>
                <div className='flex-between'>
                    <div></div>
                    <Pagination total={this.state.total} pageSizeOptions={[]}  current={this.state.getData.page} pageSize={20} onChange={e => {
                        this.props.history.push(`${baseHref}?page=${e}&search=${this.state.getData.search}&sort=${this.state.getData.sort}&type=${this.state.getData.sorttype}`)
                    }} />
                </div>
            </Page>
            {this.state.showSetting ? <Setting openLevel={()=>{this.setState({showLevel: true})}} refresh={() => {
                this.getSetting()
            }} setting={this.state.distributionSetting} onClose={() => { this.setState({ showSetting: false }) }} /> : null}
            {this.state.showLevel ? <Level reload={this.getList.bind(this, false)} onClose={() => { this.getList(false);this.setState({ showLevel: false }) }} /> : null}
            {this.state.showApply ? <ApplyList refresh={this.getList.bind(this)} onClose={() => { this.setState({ showApply: false }) }} /> : null}
        </MainPage></div>
    }

    getList(loading: boolean = true) {
        const sid = this.props.login_user_data.self_sid
        if (!sid) return
        const data = {
            ...this.state.getData,
            types: 1,
            limit: 20,
            from: 'index',
            offset: 0,
            tuanid: 0,
            sid,
        }
        data.offset = (data.page - 1) * data.limit
        delete data.page
        if(loading){
            this.props.showLoading()
        }
        get('/seller/getrank', data).then((res: any) => {
            this.props.hideLoading()
            this.setState({
                total: Number(res.count),
                list: res.list.map((i: any) => {
                    return {
                        ...i,
                        pay_rebates: (Number(i.pay_rebates) / 100).toFixed(2),
                        sale_total: (Number(i.sale_total) / 100).toFixed(2),
                        tuan_total: Number(i.tuan_total)
                    }
                })
            })

        }).catch((e) => {
            this.props.hideLoading()
            if (e.message) message.error(e.message)
        })
    }

    parseParam() {
        return new Promise<void>((res, rej) => {
            const params = parse(this.props.location.search)
            const data = {
                sorttype: 1,
                sort: 'desc',
                search: '',
                page: 1
            }
            if (params.type) data.sorttype = Number(params.type)
            if (params.sort) data.sort = String(params.sort)
            if (params.search) data.search = String(params.search)
            if (params.page) data.page = Number(params.page)
            this.setState({
                getData: data,
                searchKey: data.search
            }, () => {
                res()
            })
        })
    }

    getSetting() {
        if (this.gettingSetting) return
        this.gettingSetting = true
        get('/seller/getconfig', { types: 1 }).then((res: any) => {
            if (Array.isArray(res)) return
            const data: distributionSetting = {
                is_need_apply: Boolean(Number(res.is_need_apply)),
                is_allow_recommend_seller: Boolean(Number(res.is_allow_recommend_seller)),
                unified_price_commission_percent: (Number(res.unified_price_commission_percent)).toFixed(2),
                self_define_price_commission_percent: (Number(res.self_define_price_commission_percent)).toFixed(2),
                can_recommend_team: res.can_recommend_team.map((i: any) => Number(i))
            }
            this.gettingSetting = false
            this.setState({
                distributionSetting: data
            })
        }).catch((e) => {
            this.gettingSetting = false
            if (e.message) message.error(e.message)
        })
    }

    getGroup() {
        if (this.gettingGroup) return
        this.gettingGroup = true
        get('/seller/getteamlist', {
            types: 1,
            offset: 0, limit: 99,
        }).then((res: any) => {
            this.gettingGroup = false
            this.setState({
                teamList: res.list
            })
        }).catch((e) => {
            this.gettingGroup = false
            if (e.message) message.error(e.message)
        })
    }
}

const mapStateToProps = (state: any, ) => {
    return {
        is_Login: state.is_Login,
        waitingLogin: state.waitingLogin,
        login_user_data: state.login_user_data,
    }
}
const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
    return {
        showLoading: () => {
            dispatch(showLoading(true, '加载中...'))
        },
        hideLoading: () => {
            dispatch(showLoading(false))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)((Main(List, false)))
