import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import styled from "styled-components"
// import { get } from '../../netApi/fetch';
import Modal from "../total/Modal"
const Link = styled.a`
  padding: 6px 0;
  &:hover {
    background-color: #f7f7f7;
  }
`
const ReleaseItem = styled.div`
  width: 211px;
  height: 143px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(214, 214, 214, 0.68);
    z-index: 3;
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-bottom: 6px;
    color: #ffffff;
    i {
      font-size: 22px;
      line-height: 1;
    }
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
  }
  .content {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    text-align: left;
    color: #999999;
  }
`

function Release(props: { user: user; loginBindInfo: loginBindInfo }) {
  const router = useHistory()
  if (props.user.is_branch) {
    router.replace("/index/mall/goods/2")
  }
  const nav = useHistory()
  const [help, showHelp] = useState(false)
  const [canReleaseHemaTuan, setCanReleaseHemaTuan] = useState(false)
  const [hasMall, setHasMall] = useState<boolean>(false)
  useEffect(() => {
    if (props.loginBindInfo.is_hema) {
      setCanReleaseHemaTuan(true)
    }
    setHasMall(!!props.loginBindInfo.bind_mall_id)
  }, [props.loginBindInfo])
  return (
    <div style={{ background: "#fff" }}>
      <div style={{ maxWidth: 884, margin: "86px auto" }}>
        <div
          className="flex-center"
          style={{ marginBottom: 30, flexWrap: "wrap" }}
        >
          <ReleaseItem
            onClick={() => {
              nav.push("/event/create")
            }}
          >
            <div
              className="icon flex-center"
              style={{
                background: "linear-gradient(90deg,#f86a32 2%, #f92f47)",
              }}
            >
              <i className="iconfont icon-fabutuangou1"></i>
            </div>
            <div className="title">发布团购活动</div>
            <div className="content">团购商品、快速转发、轻松管理</div>
          </ReleaseItem>
          {canReleaseHemaTuan && (
            <ReleaseItem
              onClick={() => {
                nav.push("/event/create?hemaOnly=1")
              }}
            >
              <div
                className="icon flex-center"
                style={{
                  background: "linear-gradient(319deg,#5c0f8b 7%, #8334b4 93%)",
                }}
              >
                <i className="iconfont icon-hemazhuanshuhuodong"></i>
              </div>
              <div className="title">盒马专属活动【一】</div>
              <div className="content">一个活动展示多个NB门店</div>
            </ReleaseItem>
          )}
          {canReleaseHemaTuan && (
            <ReleaseItem
              onClick={() => {
                nav.push("/event/create?multilprice=1")
              }}
            >
              <div
                className="icon flex-center"
                style={{
                  background: "linear-gradient(319deg,#5c0f8b 7%, #8334b4 93%)",
                }}
              >
                <i className="iconfont icon-hemazhuanshuhuodong"></i>
              </div>
              <div className="title">盒马专属活动【二】</div>
              <div className="content">相同商品在不同活动里售卖不同价格</div>
            </ReleaseItem>
          )}
          {canReleaseHemaTuan && (
            <ReleaseItem
              onClick={() => {
                nav.push("/event/create?hemaOnly=1&candistribution=1")
              }}
            >
              <div
                className="icon flex-center"
                style={{
                  background: "linear-gradient(319deg,#5c0f8b 7%, #8334b4 93%)",
                }}
              >
                <i className="iconfont icon-hemazhuanshuhuodong"></i>
              </div>
              <div className="title">盒马专属活动【三】</div>
              <div className="content">展示多个NB门店且支持帮卖模式</div>
            </ReleaseItem>
          )}
          {canReleaseHemaTuan && (
            <ReleaseItem
              onClick={() => {
                nav.push("/event/create?multilprice=1&is_hssq=1")
              }}
            >
              <img
                alt=""
                src={
                  "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/vRBmAhRER8.png/md"
                }
                className="icon"
              />
              <div className="title">盒算社群专属活动</div>
              <div className="content">相同商品在不同活动里售卖不同价格</div>
            </ReleaseItem>
          )}
          {canReleaseHemaTuan && (
            <ReleaseItem
              onClick={() => {
                nav.push("/event/create?bargain=1&multilprice=1&is_hssq=1")
              }}
            >
              <img
                alt=""
                src={
                  "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/vRBmAhRER8.png/md"
                }
                className="icon"
              />
              <div className="title">发布拼团活动</div>
              <div className="content">2人团，需新用户参团</div>
            </ReleaseItem>
          )}
          <ReleaseItem
            onClick={() => {
              nav.push("/event/create?video=1")
            }}
          >
            <div
              className="icon flex-center"
              style={{
                background: "linear-gradient(140deg,#ffd138 14%, #ff9a27 100%)",
              }}
            >
              <i className="iconfont icon-duanshipindaihuo"></i>
            </div>
            <div className="title">短视频带货</div>
            <div className="content">短视频展示、带货更直观</div>
          </ReleaseItem>
          <ReleaseItem
            onClick={() => {
              nav.push("/index/mall/start")
            }}
          >
            <div
              className="icon flex-center"
              style={{
                background: "linear-gradient(140deg,#96afff 14%, #48a6ed 87%)",
              }}
            >
              <i className="iconfont icon-shangcheng-xuanzhong"></i>
            </div>
            <div className="title">{hasMall ? "进入商城" : "发布商城"}</div>
            <div className="content">同城、快递、自提、灵活设置</div>
          </ReleaseItem>
        </div>
        <div style={{ height: 1, background: "#eee", marginBottom: 30 }}></div>
        <div
          onClick={() => {
            showHelp(true)
          }}
          className="flex-center cur-pointer"
          style={{ color: "#999", fontSize: 14 }}
        >
          不会使用？可先点击查看使用教程
          <i className="iconfont icon-you"></i>
        </div>
      </div>
      {help && (
        <Modal
          title="使用手册"
          onClose={() => {
            showHelp(false)
          }}
        >
          <Link
            className="flex-box"
            href="https://mp.weixin.qq.com/s/BNFFucnoDzsLT9y7kPZlvA"
            onClick={e => {
              e.preventDefault()
              window.open("https://mp.weixin.qq.com/s/BNFFucnoDzsLT9y7kPZlvA")
            }}
          >
            <img
              style={{ width: 160, marginRight: 20 }}
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/b7FKF7Feei.jpg"
              alt=""
              className="img-center"
            />
            <div style={{ color: "#333", width: 400 }}>好友帮卖教程</div>
          </Link>
          <Link
            className="flex-box"
            href="https://mp.weixin.qq.com/s/jrFAy380wUGrmWhyY7RDFQ"
            onClick={e => {
              e.preventDefault()
              window.open("https://mp.weixin.qq.com/s/jrFAy380wUGrmWhyY7RDFQ")
            }}
          >
            <img
              style={{ width: 160, marginRight: 20 }}
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/6YM6kf89Cu.jpg"
              alt=""
              className="img-center"
            />
            <div style={{ color: "#333", width: 400 }}>退款核销教程</div>
          </Link>
          <Link
            className="flex-box"
            href="https://mp.weixin.qq.com/s/C2wtU68Kzhwk2GidNQ5Vog"
            onClick={e => {
              e.preventDefault()
              window.open("https://mp.weixin.qq.com/s/C2wtU68Kzhwk2GidNQ5Vog")
            }}
          >
            <img
              style={{ width: 160, marginRight: 20 }}
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/SY4A43HJCI.jpg"
              alt=""
              className="img-center"
            />
            <div style={{ color: "#333", width: 400 }}>佣金提现教程</div>
          </Link>
          <Link
            className="flex-box"
            href="https://mp.weixin.qq.com/s/TjiODvs13NZIhQn1shs1nA"
            onClick={e => {
              e.preventDefault()
              window.open("https://mp.weixin.qq.com/s/TjiODvs13NZIhQn1shs1nA")
            }}
          >
            <img
              style={{ width: 160, marginRight: 20 }}
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/gI6Dra38Eo.jpg"
              alt=""
              className="img-center"
            />
            <div style={{ color: "#333", width: 400 }}>发布团购教程</div>
          </Link>
          <Link
            className="flex-box"
            href="https://mp.weixin.qq.com/s/jNF-34-4y8hyI5ctdgRSgw"
            onClick={e => {
              e.preventDefault()
              window.open("https://mp.weixin.qq.com/s/jNF-34-4y8hyI5ctdgRSgw")
            }}
          >
            <img
              style={{ width: 160, marginRight: 20 }}
              src="https://jieligo-1259536745.picgz.myqcloud.com/uploads/warehouse_goods/Pn6NB1pjWY.jpg"
              alt=""
              className="img-center"
            />
            <div style={{ color: "#333", width: 400 }}>接力GO运营手册</div>
          </Link>
        </Modal>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.login_user_data,
    loginBindInfo: state.loginBindInfo,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Release)
