import React, { Component } from "react"
import api from "../../netApi/index"
import { get } from "../../netApi/fetch"

import { connect } from "react-redux"

import { Prompt } from "react-router-dom"
import { setLoginBindInfo } from "../../reducers"

import BanUnLogin from "../../high-component/BanUnLogin"
import Main from "../../high-component/Content"

import Content from "./TuanContent"

import Switch from "../total/Switch"
import Select from "../total/Select"
import SelectTSX from "../total/Select_.tsx"
import Modal from "../total/Modal"
import Loading from "../total/Loading"
import Tag from "./editComponent/tag"
import InputSign from "./editComponent/InputSign"
import DistributionSetting from "./editComponent/distributionSetting"
import Address from "./editComponent/Address.tsx"
import Fare from "./editComponent/Fare.tsx"

import { appname } from "../../data/options"

import { DatePicker, message } from "antd"
import locale from "antd/es/date-picker/locale/zh_CN"

import paramParse from "../../methods/paramParse.ts"
import { checkVideo } from "../../methods/permission.ts"

import moment from "moment"
import "moment/locale/zh-cn"
import { confirm } from "../Method/Confirm"
import BTN from "../../StyleComponents/Button"

import { storage } from "../../data/competence.ts"
import VIPPower from "../index/method/VIPPower.tsx"
import { post } from "../../netApi/fetch"
import text from "../../data/text.ts"

import HemaGoodsNumSetting from "./editComponent/HemaGoodsNumSetting"
import parseParams from "../../methods/paramParse"
import SwitchItem from "./editComponent/SwitchItem.tsx"
import BargainSetting from "./editComponent/BargainSetting.tsx"
import WrapGoodSelect from "./editComponent/SelectGoodModal"
import OnlyHemaTuanBranchItem from "./editComponent/OnlyHemaTuanBranchItem"

import { getexpresspickuppointlistbytuanid } from "./service.ts"

moment.locale("zh-cn")

// 文件内公用参数
let canchangesalemodel
let sourceSign = []
let ori_goodsid = [] // 原有商品
let GOOD_ITEM_HEIGHT = 250

const RangePicker = DatePicker.RangePicker

class Edit extends Component {
  interval = null
  bargainSettingValue = null

  constructor(p) {
    super()
    this.state = {
      data: {
        // 上传数据
        title: "",
        goodslist: [],
        takeway: 1,
        salemodel: 1,
        can_share: true,
        tuan_detail: [],
        sign: {
          user: [],
        },
        tagsee: {
          see_type: 1,
          tag: [],
        },
      },
      isHema: false, // 是否盒马
      isHYD: false, // 是否盒马HYD
      type: 1, // 1创建 2修改 3复制
      timelimit: 0, // 是否设置时间限制
      distributionSetting: [], // 运行时保存的分销设置
      salemodel: 1, // 1设置佣金 2设置供货价

      showMore: false, // 打开更多设置

      distri_show_batch: true, // 显示批量设置佣金
      distri_show_batch_type: 1, // 批量设置佣金类型
      showDistributionSettingPanel: false, // 显示帮卖设置
      showAddgood: false, // 显示添加商品

      nowSelectAddress: [], // 暂选的地址
      showAddressSelect: false, // 选择自提点
      selectedAddress: [], // 已选自提点

      canupdate: true, // 不允许变更帮卖信息， 不允许删除商品， 但可以添加商品（需设置佣金）
      meta_isdistribution: true,

      addressList: [],

      openSign: false, // 打开输入信息设置

      showTag: false, // tag管理面板

      banLeave: true, // 阻止用户离开

      selectedFare: {}, // 运费模板

      showExpressToAddressSelect: false, // 选择配送到自提点的自提点
      selectedExpressToAddress: [], // 已选择的配送到自提点的自提点

      selectedHemaOnlyBranch: null, // 河马多个商城活动当前选择的商城

      // 参数
      _params: null,
    }
    this.content = React.createRef()
    const param = paramParse(p.location.search)
    if (param.video) this.state.canUseVideo = true
    canchangesalemodel = 1
    document.title = "发布团购"

    // 获取帮卖好友分组列表
    this.getGroup()

    get("/follow/getcanpushcount", {}).then(res => {
      this.setState({ canPushcount: res.canusecount })
    })

    get("/index/getloginbindinfo", {}).then(res => {
      this.props.setLoginBindInfo(res)
      if (res.is_hema === true) {
        GOOD_ITEM_HEIGHT = 340
        this.setState({
          isHema: true,
          branchList: res.branch_mall_list,
          takeway: 2,
        })
        const params = parseParams(p.location.search)
        if (params.hemaOnly) {
          this.setState({
            hemaOnly: true,
            hemaOnlyBranchList: res.branch_mall_list.map(i => {
              return {
                ...i,
                select: true,
              }
            }),
          })
        }
        if (params.multilprice) {
          // 需要填写nowprice作为对外售价
          this.setState({
            hemaMutilPrice: true,
          })
        }
      }
      if (res.is_hyd === true) {
        GOOD_ITEM_HEIGHT = 340
        this.setState({ isHYD: true })
      }
    })

    checkVideo().then(res => {
      this.setState({ canUseVideo: res && param.video })
    })
    if (p.match.params && p.match.params.id) {
      document.title = "编辑团购"
      this.state.type = 2
      if (p.match.params.mode === "copy") {
        this.state.type = 3
      }
      const viewdata = {
        types: 1,
        id: p.match.params.id,
        from: "update",
      }
      this.state.Loading = true
      api(
        "tuan",
        "view"
      )(viewdata).then(res => {
        const data = res.data.data
        if (!data) return
        let _params = null
        if (data.is_hssq === 1) {
          _params = {
            multilprice: 1,
            is_hssq: 1,
          }
        }
        this.is_hssq = data.is_hssq
        this.setState({ is_hssq: this.is_hssq, _params })

        if (data.is_hema_exclusive) {
          this.setState({ hemaOnly: true })
          get("/index/getloginbindinfo", {}).then(res => {
            if (res.is_hema === true) {
              this.setState({
                hemaOnly: true,
                hemaOnlyBranchList: res.branch_mall_list.map(i => {
                  if (
                    !!data.goodslist[Number(i.id)] &&
                    !this.state.selectedHemaOnlyBranch
                  ) {
                    this.setState({
                      selectedHemaOnlyBranch: i.distribution_relation_id,
                    })
                  }
                  return {
                    ...i,
                    select: !!data.goodslist[Number(i.id)],
                    goodslist:
                      data.goodslist[Number(i.id)]?.map((good, i) => {
                        return {
                          ...good,
                          rank: i,
                          attribute: good.attribute.map(attr => {
                            return {
                              ...attr,
                              editPrice: (Number(attr.price) / 100).toFixed(2),
                              editOriginPrice: (
                                Number(attr.original_price) / 100
                              ).toFixed(2),
                              editInputNumber: (
                                Number(attr.distribution_price?.input_number) /
                                100
                              ).toFixed(2),
                              editNowPrice: (
                                Number(attr.distribution_price?.nowprice) / 100
                              ).toFixed(2),
                              editQuantity: Number(attr.quantity),
                              editMultilPriceNowPrice: (
                                Number(
                                  attr.distribution_price?.nowprice ||
                                    attr.price
                                ) / 100
                              ).toFixed(2),
                            }
                          }),
                        }
                      }) || [],
                    addressList: data.addresslist[Number(i.id)]?.map(i => i.id),
                  }
                }),
              })
            }
          })
        }

        if (data.is_multi_price) {
          this.setState({
            hemaMutilPrice: true,
          })
        }

        // 寻找盒马门店
        this.setState({
          selectedHemaNB: { id: data.mall_id },
        })

        if (!data.tagsee) {
          data.tagsee = { see_type: 1, tag: [] }
        }

        if (Number(data.startpaymodel) > 0) {
          const purchase = {
            type: (() => {
              const type = Number(data.startpaymodel)
              if (type === 1 || type === 2) return 1
              if (type === 3 || type === 4) return 2
            })(),
            subtype: (() => {
              const type = Number(data.startpaymodel)
              if (type === 1 || type === 3) return 1
              if (type === 2 || type === 4) return 2
            })(),
          }
          if (purchase.type === 1)
            purchase.value = (Number(data.limit_price) / 100).toFixed(2)
          if (purchase.type === 2) purchase.value = Number(data.limit_price)
          this.setState({
            showPurchase: true,
            purchase,
            showMore: true,
          })
        }

        // 运费模板
        this.setState({
          selectedFare: {
            ...data.fare_template_info,
            fare_template_id: data.fare_template_info.id,
          },
        })

        if (data.is_hema_exclusive) {
        } else {
          data.goodslist = data.goodslist.map((i, index) => {
            ori_goodsid.push(i)
            if (Number(i.rank) !== index) {
              i.rank = index
            }
            i.attribute = i.attribute.map(attr => {
              return {
                ...attr,
                editPrice: (Number(attr.price) / 100).toFixed(2),
                editOriginPrice: (Number(attr.original_price) / 100).toFixed(2),
                editInputNumber: (
                  Number(attr.distribution_price?.input_number) / 100
                ).toFixed(2),
                editNowPrice: (
                  Number(attr.distribution_price?.nowprice) / 100
                ).toFixed(2),
                editQuantity: Number(attr.quantity),
                editMultilPriceNowPrice: (
                  Number(attr.distribution_price?.nowprice || attr.price) / 100
                ).toFixed(2),
              }
            })
            return i
          })
        }

        let timelimit = 0
        if (data.time_type.start === undefined) {
          delete data.time_type
        } else {
          timelimit = 1
          data.time_type = {
            start: moment(data.time_type.start_).unix(),
            end: moment(data.time_type.end_).unix(),
          }
        }
        if (data.is_hema_exclusive) {
        } else {
          this.setState({ selectedAddress: data.addresslist.map(i => i.id) })
        }

        data.distribution_price = []
        const setData = {}
        if (data.salemodel > 0) setData.salemodel = data.salemodel

        if (Number(data.pid) > 0) {
          canchangesalemodel = false
        }

        if (data.isdistribution && Number(data.isdistribution) === 1) {
          canchangesalemodel = false
        }

        if (this.state.type === 3) {
          delete data.canupdate
          canchangesalemodel = true
        }
        if (
          data.tuan_detail.find(
            i => Number(i.type) === 4 && Number(i.istop) === 1
          )
        ) {
          const video = data.tuan_detail.find(
            i => Number(i.type) === 4 && Number(i.istop) === 1
          )
          this.setState({
            topVideo: {
              src: video.value.origin_src,
            },
            canUseVideo: true,
          })
        }
        this.setState(
          {
            data,
            timelimit,
            canupdate: data.canupdate,
            meta_isdistribution: !data.isdistribution,
          },
          () => {
            if (data.type === "pt") {
              let _params = this.state._params || {}
              this.setState({
                _params: {
                  ..._params,
                  bargain: 1,
                },
              })
            }
          }
        )
        if (Number(data.express_to_pick_up_point) === 2) {
          getexpresspickuppointlistbytuanid.call(this, window.btoa(data.id))
        }
        if (data.sign.user.length > 0) {
          this.setState({
            openSign: true,
            showMore: true,
          })
          sourceSign = JSON.parse(JSON.stringify(data.sign.user))
        }

        api(
          "goods",
          "getdistributiongoodsattrlistbytid"
        )({
          tuanid: window.btoa(data.lasttuanid || data.id),
          types: 1,
        }).then(res => {
          this.setState({ Loading: false })
          let distribution_price = []

          if (Number(data.pid) === 0) {
            // 一级修改，初始化distribution_price
            for (let i = 0; i < res.data.data.list.length; i++) {
              const ori_good = res.data.data.list[i]
              for (let j = 0; j < ori_good.attribute.length; j++) {
                const ori_attr = ori_good.attribute[j]
                distribution_price.push({
                  ...ori_attr.distribution_price,
                  goodsattrid: ori_attr.id,
                  goodsid: ori_good.goodsid,
                })
              }
            }
          }

          this.setState({
            superiorGoodslist: res.data.data.list,
            data: {
              ...data,
              distribution_price,
            },
            categorylist: [],
          })

          if (Number(data.pid) > 0) {
            for (let i = 0; i < res.data.data.list.length; i++) {
              const good = res.data.data.list[i]
              for (let j = 0; j < good.attribute.length; j++) {
                const attr = good.attribute[j]
                if (Number(data.salemodel) === 1) {
                  attr.distribution_price = {
                    nowprice: attr.distribution_price.nowprice,
                    baseprice: attr.distribution_price.sellerprice,
                    sellerprice: null,
                  }
                } else if (Number(data.salemodel) === 2) {
                  attr.distribution_price = {
                    nowprice: attr.distribution_price.nowprice,
                    baseprice: attr.distribution_price.sellerprice,
                    sellerprice: null,
                  }
                }
              }
            }
            this.setState({
              goodslist: res.data.data.list,
            })
          }
        })
      })
    }
  }

  componentDidMount() {
    const param = paramParse(this.props.location.search)

    this.is_hssq = param.is_hssq
    this.setState({
      is_hssq: this.is_hssq,
      _params: param,
    })
    setTimeout(() => {
      const params = parseParams(this.props.location.search)
      if (params.hemaOnly) return
      if (params.multilprice) return
      if (this.state.isHema) return
      if (this.state.isHYD) return
      if (
        this.props.match.params &&
        this.props.match.params.mode === "create"
      ) {
        const data = JSON.parse(localStorage.getItem("edittingTuan"))
        if (
          data &&
          data.data &&
          Number(data.sid) === Number(this.props.user.self_sid) &&
          JSON.stringify(data.data.data) !== JSON.stringify(this.state.data)
        ) {
          if (window.confirm("是否继续上次的编辑")) {
            this.setState({ ...data.data })
            this.setState({ Loading: false })
          }
        }
        this.interval = setInterval(() => {
          localStorage.setItem(
            "edittingTuan",
            JSON.stringify({
              data: { ...this.state, topVideo: undefined },
              sid: this.props.user.self_sid,
            })
          )
        }, 1500)
      }
    }, 300)

    if (BroadcastChannel) {
      if (!window.BroadcastChannel) return
      this.bc = new BroadcastChannel("goodslist")
      window.name = "jieligo_list"
      this.bc.onmessage = e => {
        switch (e.data) {
          case "reload":
            this.setState({ goodslist: [] }, () => {
              this.getOther().then(() => {
                // this.setState({showAddgood: false}, () => {
                //     this.setState({showAddgood: true})
                // })
              })
            })
            break
          default:
            break
        }
      }
    }
  }

  componentDidUpdate(_, s) {
    if (this.state.isHema && !s.isHema) {
      GOOD_ITEM_HEIGHT = 340
      this.setState({ isHema: true, data: { ...this.state.data, takeway: 2 } })
    }
    if (this.state.isHYD && !s.isHYD) {
      GOOD_ITEM_HEIGHT = 340
      this.setState({ isHYD: true, data: { ...this.state.data, takeway: 1 } })
    }
    if (this.state.isHema) {
      if (this.state.branchList.length > 0) {
        if (
          !this.state.selectedHemaNB ||
          !this.state.selectedHemaNB.distribution_relation_id
        ) {
          if (this.state.selectedHemaNB?.id) {
            this.setState({
              selectedHemaNB: this.state.branchList.find(
                i => Number(i.id) === Number(this.state.selectedHemaNB?.id)
              ),
            })
          }
        }
      }
    }
  }

  componentWillUnmount() {
    canchangesalemodel = undefined
    sourceSign = []
    ori_goodsid = []
    GOOD_ITEM_HEIGHT = 250
    clearInterval(this.interval)
  }

  render() {
    const { _params } = this.state
    return (
      <div className="edittuan">
        {!this.state.isHema && !this.state.isHYD && (
          <Prompt
            message="你还没有保存，确定要退出吗？"
            when={this.state.banLeave}
          />
        )}

        {/* top */}
        <div className="edittuan__top fcc">
          <div className="cur-pointer" onClick={this.props.history.goBack}>
            <i className="icon-right iconfont"></i>
          </div>
          <div className="fcc edittuan__top__title">发布团购</div>
          <div>
            <button
              className="button_solid_red"
              onClick={this.submit.bind(this)}
            >
              确 定
            </button>
          </div>
        </div>

        <div className="edittuan__wrap">
          {/* 视频 */}
          {this.state.canUseVideo ? (
            <div className="edittuan__panel" style={{ textAlign: "center" }}>
              <div
                className="edittuan__panel__title"
                style={{ marginBottom: 12, textAlign: "left" }}
              >
                添加短视频
              </div>
              {!this.state.topVideo || !this.state.topVideo.src ? (
                <label
                  style={{ padding: 80, display: "block", fontSize: 24 }}
                  onClick={e => {
                    if (!storage.get()) {
                      e.stopPropagation()
                      e.preventDefault()
                      this.setState({
                        showAddMenu: false,
                      })
                      confirm({
                        text: text.ERROR.MEMBER.IMAGE,
                        title: "温馨提示",
                        confirmText: "去升级",
                        cancelText: "暂不升级",
                      }).then(res => {
                        if (res) VIPPower({})
                      })
                    }
                  }}
                >
                  <div
                    className="flex-center cur-pointer"
                    style={{
                      color: "#999",
                      border: "1px solid #999",
                      borderRadius: 8,
                      padding: 50,
                      flexDirection: "column",
                    }}
                  >
                    <i
                      className="icon-tianjiatupian1 iconfont"
                      style={{
                        fontSize: 50,
                        lineHeight: 1,
                        marginBottom: 30,
                        display: "block",
                      }}
                    />
                    <div>添加视频</div>
                    <div>（最多一个视频，且置顶播放）</div>
                  </div>
                  <input
                    type="file"
                    style={{ height: 0, width: 0, border: 0, display: "none" }}
                    accept=".mp4"
                    onChange={e => {
                      if (!e.target.files[0]) return
                      if (e.target.files[0].type !== "video/mp4") {
                        message.warning("请选择mp4格式的视频")
                        return
                      }
                      if (e.target.files[0].size > 20 * 1024 * 1024) {
                        message.warning("视频文件太大，请重新选择")
                        return
                      }
                      const file = e.target.files[0]
                      const fr = new FileReader()
                      fr.readAsDataURL(e.target.files[0])
                      fr.onload = function (e) {
                        this.setState({
                          topVideo: {
                            src: e.target.result,
                            file,
                          },
                        })
                      }.bind(this)
                    }}
                  />
                </label>
              ) : (
                <div style={{ position: "relative" }}>
                  <i
                    className="iconfont icon-guanbi-tianchong cur-pointer"
                    onClick={() => {
                      this.setState({ topVideo: undefined })
                    }}
                    style={{
                      zIndex: 9,
                      lineHeight: 1,
                      fontSize: 24,
                      position: "absolute",
                      right: -5,
                      top: -5,
                    }}
                  />
                  <video
                    src={this.state.topVideo ? this.state.topVideo.src : ""}
                    onLoadedMetadata={e => {}}
                    controls
                    style={{ maxHeight: 400, maxWidth: "100%" }}
                  />
                </div>
              )}
            </div>
          ) : null}
          {/* 视频 */}

          {/* 文字图片标题 */}
          <div className="edittuan__panel">
            <div className="edittuan__content__top fcc">
              <img
                alt=""
                src={
                  this.state.is_hssq
                    ? this.props.loginBindInfo?.hema_logo_list?.hssq_logo
                    : this.props.user.avatar
                }
                className="img-center edittuan__content__top__ava"
              />
              <input
                maxLength={200}
                type="text"
                value={this.state.data.title}
                onChange={e => {
                  this.setState({
                    data: { ...this.state.data, title: e.target.value },
                  })
                }}
                placeholder="团购活动主题（最多60个字）"
                className="edittuan__content__top__input"
              />
            </div>
            <Content ref={this.content} list={this.state.data.tuan_detail} />
          </div>
          {/* 文字图片标题 */}

          {this.state.isHema && !this.state.hemaOnly && (
            <div className="edittuan__panel flex-between">
              <div className="edittuan__panel__title">
                <div style={{ marginBottom: 9 }}>选择盒马NB门店</div>
                <div style={{ fontSize: 12, color: "#999", maxWidth: 320 }}>
                  盒马的团购活动一旦发布，NB店不再支持修改更换，但NB店下的自提点、商品，仍可进行修改
                </div>
              </div>
              <SelectTSX
                maxHeight={300}
                inputStyle={{ width: 300 }}
                value={this.state.branchList?.findIndex(
                  i => Number(i.id) === Number(this.state.selectedHemaNB?.id)
                )}
                list={this.state.branchList || []}
                uni="nbBranch"
                showKey="branch_name"
                disabled={this.state.type === 2}
                placeholder="请选择NB门店"
                onChange={e => {
                  if (
                    Number(e.distribution_relation_id) ===
                    Number(this.state.selectedHemaNB?.distribution_relation_id)
                  )
                    return
                  this.setState({
                    selectedHemaNB: e,
                    selectedAddress: [],
                    data: {
                      ...this.state.data,
                      goodslist: [],
                    },
                  })
                }}
              />
            </div>
          )}

          {(this.state.isHYD || this.state.hemaOnly) && (
            <div className="edittuan__switchitem">
              <div className="flex-box">
                <div
                  className="flex-center cur-pointer"
                  style={{
                    width: 126,
                    height: 43,
                    marginRight: 14,
                    background: !this.state.data.isdistribution
                      ? "#fff"
                      : "#efefef",
                    color: !this.state.data.isdistribution
                      ? "var(--theme-main)"
                      : "#333",
                    border: !this.state.data.isdistribution
                      ? "1px solid var(--theme-main)"
                      : "",
                  }}
                  onClick={() => {
                    const data = this.state.data
                    if (
                      data.isdistribution &&
                      Number(this.state.type) === 2 &&
                      !this.state.meta_isdistribution
                    ) {
                      return
                    }
                    data.isdistribution = false
                    data.notpay = false
                    GOOD_ITEM_HEIGHT = 340
                    this.setState({
                      data,
                    })
                  }}
                >
                  不开帮卖
                </div>
                <div
                  className="flex-center cur-pointer"
                  style={{
                    width: 126,
                    height: 43,
                    background:
                      Number(this.state.data.salemodel) === 1 &&
                      this.state.data.isdistribution
                        ? "#fff"
                        : "#efefef",
                    color:
                      Number(this.state.data.salemodel) === 1 &&
                      this.state.data.isdistribution
                        ? "var(--theme-main)"
                        : "#333",
                    border:
                      Number(this.state.data.salemodel) === 1 &&
                      this.state.data.isdistribution
                        ? "1px solid var(--theme-main)"
                        : "",
                  }}
                  onClick={() => {
                    const data = this.state.data
                    data.isdistribution = true
                    data.notpay = false
                    data.salemodel = 1
                    GOOD_ITEM_HEIGHT = 375
                    this.setState({
                      data,
                    })
                  }}
                >
                  对外价格统一
                </div>
              </div>
              {this.state.data.isdistribution ? (
                <div
                  onClick={() => {
                    if (this.state.data.goodslist.length === 0) {
                      message.warning("请选择商品")
                      return
                    }
                    this.setState({ showDistributionSettingPanel: true })
                  }}
                  style={{
                    background: "#FFF6F6",
                    fontSize: 14,
                    color: "var(--theme-main)",
                    padding: 12,
                    marginTop: 16,
                  }}
                  className="fcc"
                >
                  帮卖设置 &gt;
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          {this.state.isHema && this.state.hemaOnly && (
            <div className="edittuan__panel">
              <div className="edittuan__panel__title">
                <div style={{ marginBottom: 9 }}>选择盒马NB门店</div>
              </div>
              <div
                style={{ flexWrap: "wrap", marginRight: -20 }}
                className="flex-box"
              >
                {this.state.hemaOnlyBranchList
                  ?.filter(i => i.select)
                  .map(branch => {
                    return (
                      <OnlyHemaTuanBranchItem
                        onClick={() => {
                          this.setState({
                            selectedHemaOnlyBranch:
                              branch.distribution_relation_id,
                          })
                        }}
                        className={
                          this.state.selectedHemaOnlyBranch ===
                          branch.distribution_relation_id
                            ? "active"
                            : ""
                        }
                        key={branch.distribution_relation_id}
                      >
                        {branch.branch_name}
                        <div
                          className="delete"
                          onClick={e => {
                            e.stopPropagation()
                            branch.select = false
                            this.setState({
                              hemaOnlyBranchList: [
                                ...this.state.hemaOnlyBranchList,
                              ],
                              selectedHemaOnlyBranch: null,
                            })
                          }}
                        >
                          <i className="icon-guanbi-tianchong iconfont " />
                        </div>
                      </OnlyHemaTuanBranchItem>
                    )
                  })}
                {this.state.hemaOnlyBranchList?.filter(i => !i.select).length >
                  0 && (
                  <SelectTSX
                    readonly
                    hideIcon
                    maxHeight={300}
                    inputStyle={{
                      width: 152,
                      height: 43,
                      border: "1px solid #8e99ae",
                      marginBottom: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: 0,
                    }}
                    value={
                      <div
                        style={{ color: "#8e99ae" }}
                        className="flex-center nbBranch"
                      >
                        <i
                          className="iconfont icon-jiahao-tianchong"
                          style={{ marginRight: 6 }}
                        />
                        添加删除的门店
                      </div>
                    }
                    list={
                      this.state.hemaOnlyBranchList?.filter(i => !i.select) ||
                      []
                    }
                    uni="nbBranch"
                    showKey="branch_name"
                    onChange={e => {
                      if (!e) return
                      this.setState({
                        hemaOnlyBranchList: this.state.hemaOnlyBranchList.map(
                          i => {
                            if (
                              Number(e?.distribution_relation_id) ===
                              Number(i.distribution_relation_id)
                            ) {
                              return {
                                ...i,
                                select: true,
                              }
                            }
                            return i
                          }
                        ),
                      })
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {this.state.hemaOnly &&
            this.state.hemaOnlyBranchList?.map(branch => {
              if (
                branch.distribution_relation_id !==
                this.state.selectedHemaOnlyBranch
              )
                return null
              return (
                <div
                  className="edittuan__panel"
                  key={branch.distribution_relation_id}
                >
                  <div className="edittuan__panel__title">
                    添加商品
                    {this.state.isHema && (
                      <ol style={{ paddingInlineStart: 12 }}>
                        <li
                          style={{
                            fontSize: 12,
                            color: "#999",
                            marginTop: "1em",
                          }}
                        >
                          所添加的商品，为上述选择NB店所对应的盒马方商品，商城价格、商城库存均为盒马传输的实时价格及库存，请根据实际运营情况来设置活动价格及活动库存；
                        </li>
                        <li
                          style={{
                            fontSize: 12,
                            color: "#999",
                            marginTop: "1em",
                          }}
                        >
                          支持对活动商品设置单独的活动价
                          {!this.state.hemaMutilPrice && <>及库存</>}
                          ，不会与盒马商城传来的价格进行比对。但活动对外展示时，会以当时较小的库存为准来进行展示，以免超售；
                        </li>
                        {!this.state.hemaMutilPrice && (
                          <li
                            style={{
                              fontSize: 12,
                              color: "#999",
                              marginTop: "1em",
                            }}
                          >
                            目前，同一NB店关联的同一商品，被应用到多个团购活动时，以最新活动设置的该商品的活动库存及活动价格为准（最新设置的商品价格及库存，也会同步到其他活动中）
                          </li>
                        )}
                        <li
                          style={{
                            fontSize: 12,
                            color: "#999",
                            marginTop: "1em",
                          }}
                        >
                          添加商品后，点击商品右上角“操作-更多设置”，支持设置该商品的限购数量。
                        </li>
                      </ol>
                    )}
                  </div>
                  <div
                    className="edittuan__goods"
                    style={{
                      height:
                        Math.ceil(((branch.goodslist || []).length + 1) / 3) *
                          (GOOD_ITEM_HEIGHT + 16) -
                        16,
                    }}
                  >
                    <div
                      className="edittuan__goods__item cur-pointer fcc"
                      style={{ height: GOOD_ITEM_HEIGHT }}
                      onClick={() => {
                        this.setState({ showAddgood: true })
                      }}
                    >
                      <i
                        className="iconfont icon-jia"
                        style={{ color: "#999", fontSize: 64 }}
                      />
                      <div style={{ color: "#999" }}>点击添加商品</div>
                    </div>
                    {branch.goodslist?.map(i => {
                      return (
                        <Good
                          key={i.goodsid}
                          priceEditDisable={
                            Number(this.state.type) === 2 &&
                            this.state.data.isdistribution
                          }
                          isHema={this.state.isHema}
                          isHYD={this.state.isHYD}
                          distribution={this.state.data.isdistribution}
                          hydPriceEaditable={
                            this.state.data.canupdate ||
                            this.state.data.canupdate === undefined ||
                            this.state.type === 1 ||
                            this.state.type === 3
                          }
                          hemaOnly={this.state.hemaOnly}
                          start_count={i.start_count}
                          limit_count={i.limit_count}
                          candelete={true}
                          changeRank={e => {
                            branch.goodslist = branch.goodslist
                              .map(j => {
                                let ret = { ...j }
                                if (j.goodsid === dragGoodData.goodsid) {
                                  ret.rank = i.rank - 0.5
                                }
                                return ret
                              })
                              .sort((a, b) => a.rank - b.rank)
                              .map((i, idx) => {
                                return { ...i, rank: idx }
                              })
                            this.setState({
                              hemaOnlyBranchList: this.state.hemaOnlyBranchList,
                            })
                          }}
                          good={i}
                          delete={() => {
                            branch.goodslist = branch.goodslist
                              .filter(
                                item =>
                                  Number(item.goodsid) !== Number(i.goodsid)
                              )
                              .sort((a, b) => a.rank - b.rank)
                              .map((i, idx) => {
                                return { ...i, rank: idx }
                              })
                            this.setState({
                              hemaOnlyBranchList: this.state.hemaOnlyBranchList,
                            })
                          }}
                          setData={data => {
                            branch.goodslist = branch.goodslist.map(item => {
                              if (Number(item.goodsid) !== Number(i.goodsid))
                                return item
                              return {
                                ...item,
                                ...data,
                              }
                            })
                            this.setState({
                              hemaOnlyBranchList: this.state.hemaOnlyBranchList,
                            })
                          }}
                          toTop={() => {
                            i.rank = -1
                            branch.goodslist = branch.goodslist
                              .sort((a, b) => a.rank - b.rank)
                              .map((i, idx) => {
                                return { ...i, rank: idx }
                              })
                            this.setState({
                              hemaOnlyBranchList: this.state.hemaOnlyBranchList,
                            })
                          }}
                          isMust={() => {
                            branch.goodslist = branch.goodslist.map(
                              (good, idx) => {
                                if (
                                  Number(good.goodsid) === Number(i.goodsid)
                                ) {
                                  return {
                                    ...good,
                                    is_required: !good.is_required,
                                  }
                                }
                                return { ...good }
                              }
                            )
                            this.setState({
                              hemaOnlyBranchList: this.state.hemaOnlyBranchList,
                            })
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })}

          {/* 商品 */}
          {!this.state.hemaOnly && (
            <div className="edittuan__panel">
              <div className="edittuan__panel__title">
                添加商品
                {this.state.isHema && (
                  <ol style={{ paddingInlineStart: 12 }}>
                    <li
                      style={{ fontSize: 12, color: "#999", marginTop: "1em" }}
                    >
                      所添加的商品，为上述选择NB店所对应的盒马方商品，商城价格、商城库存均为盒马传输的实时价格及库存，请根据实际运营情况来设置活动价格及活动库存；
                    </li>
                    <li
                      style={{ fontSize: 12, color: "#999", marginTop: "1em" }}
                    >
                      支持对活动商品设置单独的活动价
                      {!this.state.hemaMutilPrice && <>及库存</>}
                      ，不会与盒马商城传来的价格进行比对。但活动对外展示时，会以当时较小的库存为准来进行展示，以免超售；
                    </li>
                    {!this.state.hemaMutilPrice && (
                      <li
                        style={{
                          fontSize: 12,
                          color: "#999",
                          marginTop: "1em",
                        }}
                      >
                        目前，同一NB店关联的同一商品，被应用到多个团购活动时，以最新活动设置的该商品的活动库存及活动价格为准（最新设置的商品价格及库存，也会同步到其他活动中）
                      </li>
                    )}
                    <li
                      style={{ fontSize: 12, color: "#999", marginTop: "1em" }}
                    >
                      添加商品后，点击商品右上角“操作-更多设置”，支持设置该商品的限购数量。
                    </li>
                  </ol>
                )}
              </div>
              <div
                className="edittuan__goods"
                style={{
                  height:
                    Math.ceil((this.state.data?.goodslist?.length + 1) / 3) *
                      (GOOD_ITEM_HEIGHT + 16) -
                    16,
                }}
              >
                <div
                  className="edittuan__goods__item cur-pointer fcc"
                  style={{ height: GOOD_ITEM_HEIGHT }}
                  onClick={() => {
                    if (this.state.isHema) {
                      if (
                        !this.state.selectedHemaNB ||
                        !this.state.selectedHemaNB.distribution_relation_id
                      ) {
                        message.warning("请选择NB门店")
                        return
                      }
                    }
                    this.setState({ showAddgood: true })
                  }}
                >
                  <i
                    className="iconfont icon-jia"
                    style={{ color: "#999", fontSize: 64 }}
                  />
                  <div style={{ color: "#999" }}>点击添加商品</div>
                </div>
                {this.state.data.goodslist.map(i => {
                  return (
                    <Good
                      key={i.goodsid}
                      hemaMutilPrice={this.state.hemaMutilPrice} // 需填写nowprice作为对外售价
                      priceEditDisable={
                        Number(this.state.type) === 2 &&
                        this.state.data.isdistribution
                      }
                      isHema={this.state.isHema}
                      isHYD={this.state.isHYD}
                      distribution={this.state.data.isdistribution}
                      hydPriceEaditable={
                        this.state.data.canupdate ||
                        this.state.type === 1 ||
                        this.state.type === 3
                      }
                      start_count={i.start_count}
                      limit_count={i.limit_count}
                      candelete={true}
                      changeRank={e => {
                        const data = this.state.data
                        data.goodslist = data.goodslist
                          .map(j => {
                            let ret = { ...j }
                            if (j.goodsid === dragGoodData.goodsid) {
                              ret.rank = i.rank - 0.5
                            }
                            return ret
                          })
                          .sort((a, b) => a.rank - b.rank)
                          .map((i, idx) => {
                            return { ...i, rank: idx }
                          })
                        this.setState({
                          data,
                        })
                      }}
                      good={i}
                      delete={() => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            goodslist: this.state.data.goodslist
                              .filter(
                                item =>
                                  Number(item.goodsid) !== Number(i.goodsid)
                              )
                              .sort((a, b) => a.rank - b.rank)
                              .map((i, idx) => {
                                return { ...i, rank: idx }
                              }),
                          },
                        })
                      }}
                      setData={data => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            goodslist: this.state.data.goodslist.map(item => {
                              if (Number(item.goodsid) !== Number(i.goodsid))
                                return item
                              return {
                                ...item,
                                ...data,
                              }
                            }),
                          },
                        })
                      }}
                      toTop={() => {
                        i.rank = -1
                        this.setState({
                          data: {
                            ...this.state.data,
                            goodslist: this.state.data.goodslist
                              .sort((a, b) => a.rank - b.rank)
                              .map((i, idx) => {
                                return { ...i, rank: idx }
                              }),
                          },
                        })
                      }}
                      isMust={() => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            goodslist: this.state.data.goodslist.map(
                              (good, idx) => {
                                if (
                                  Number(good.goodsid) === Number(i.goodsid)
                                ) {
                                  return {
                                    ...good,
                                    is_required: !good.is_required,
                                  }
                                }
                                return { ...good }
                              }
                            ),
                          },
                        })
                      }}
                    />
                  )
                })}
              </div>
            </div>
          )}
          {/* 商品 */}

          {/* 配送方式 */}
          {this.state.hemaOnly &&
            this.state.hemaOnlyBranchList?.map(branch => {
              if (
                branch.distribution_relation_id !==
                this.state.selectedHemaOnlyBranch
              )
                return null
              return (
                <div
                  className="flex-between"
                  key={branch.distribution_relation_id}
                >
                  <div className="edittuan__panel " style={{ flex: 2 }}>
                    <div className="fbc">
                      <div style={{ fontSize: 14, color: "#454545" }}>
                        请选择配送方式
                      </div>
                      <Select
                        disabled={
                          this.state.type === 2 ||
                          this.state.isHema ||
                          this.state.isHYD
                        }
                        list={[
                          { takeway: 1, name: "快递配送上门" },
                          { takeway: 2, name: "自提点/门店取货" },
                        ]}
                        showkey="name"
                        showValue="name"
                        hovorClass="select-hover"
                        value={Number(this.state.data.takeway) - 1}
                        change={(e, ee) => {
                          this.setState({
                            data: { ...this.state.data, takeway: e.takeway },
                          })
                          if (e.takeway === 2) {
                            if (ee === undefined) return
                            this.openAddressSelect()
                          }
                        }}
                        uni={"takeway"}
                      />
                    </div>
                    <div
                      className="fbc cur-pointer"
                      style={{ fontSize: 14, color: "#454545", marginTop: 16 }}
                      onClick={() => {
                        this.openAddressSelect()
                      }}
                    >
                      <div style={{ fontSize: 14, color: "#454545" }}>
                        选择自提点
                      </div>
                      <div>
                        已选{branch.addressList?.length || 0}个自提点 {">"}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          {/* 配送方式 */}
          {!this.state.hemaOnly && (
            <div className="flex-between">
              <div className="edittuan__panel " style={{ flex: 2 }}>
                <div className="fbc">
                  <div style={{ fontSize: 14, color: "#454545" }}>
                    请选择配送方式
                  </div>
                  <Select
                    disabled={
                      this.state.type === 2 ||
                      this.state.isHema ||
                      this.state.isHYD
                    }
                    list={[
                      { takeway: 1, name: "快递配送上门" },
                      { takeway: 2, name: "自提点/门店取货" },
                    ]}
                    showkey="name"
                    showValue="name"
                    hovorClass="select-hover"
                    value={Number(this.state.data.takeway) - 1}
                    change={(e, ee) => {
                      this.setState({
                        data: { ...this.state.data, takeway: e.takeway },
                      })
                      if (e.takeway === 2) {
                        if (ee === undefined) return
                        this.openAddressSelect()
                      }
                    }}
                    uni={"takeway"}
                  />
                </div>
                {this.state.data.takeway === 2 ? (
                  <div
                    className="fbc cur-pointer"
                    style={{ fontSize: 14, color: "#454545", marginTop: 16 }}
                    onClick={() => {
                      this.openAddressSelect()
                    }}
                  >
                    <div style={{ fontSize: 14, color: "#454545" }}>
                      选择自提点
                    </div>
                    <div>
                      已选{this.state.selectedAddress.length}个自提点 {">"}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* 运费模板 */}
              {this.state.data.takeway === 1 ? (
                <div
                  className="edittuan__panel flex1 cur-pointer"
                  onClick={() => {
                    this.setState({ showSelectedFare: true })
                  }}
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <div className="flex-between" style={{ padding: "4px 0" }}>
                    <div style={{ fontSize: 14, color: "#454545" }}>
                      设置运费
                    </div>
                    <div>
                      {this.state.selectedFare?.template_name || "默认全国包邮"}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}

          {/* 时间 */}
          {Number(this.state.data.pid) <= 0 ||
          this.state.data.pid === undefined ? (
            <div className="edittuan__panel">
              <div className="fbc" style={{ marginBottom: 12 }}>
                <div style={{ fontSize: 14, color: "#454545" }}>
                  团购有效时间
                </div>
                <Select
                  list={[
                    { timelimit: 0, name: "长期有效" },
                    { timelimit: 1, name: "特定时间有效" },
                  ]}
                  showkey="name"
                  showValue="name"
                  hovorClass="select-hover"
                  value={Number(this.state.timelimit)}
                  change={e => {
                    this.setState({ timelimit: e.timelimit })
                  }}
                  uni={"takeway"}
                />
              </div>
              {this.state.timelimit === 1 ? (
                <div className="fbc" style={{ marginBottom: 12 }}>
                  <div style={{ fontSize: 14, color: "#454545" }}>选择时间</div>
                  <RangePicker
                    defaultValue={(() => {
                      if (this.state.data.time_type) {
                        return [
                          moment.unix(this.state.data.time_type.start),
                          moment.unix(this.state.data.time_type.end),
                        ]
                      } else {
                        return undefined
                      }
                    })()}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    locale={locale}
                    onChange={e => {
                      let time_type
                      if (e && e.length === 2) {
                        time_type = {
                          start: e[0].second(0).unix(),
                          end: e[1].second(0).unix(),
                        }
                      } else {
                        time_type = []
                      }
                      this.setState({
                        data: {
                          ...this.state.data,
                          time_type,
                        },
                      })
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* {!this.state.isHYD && !this.state.hemaOnly && !this.state.hemaMutilPrice && Number(this.state.data.distribution_mode) !== 2 && ( */}
          {!this.state._params?.bargain &&
            ((!this.state.isHYD &&
              !this.state.hemaOnly &&
              !this.state.hemaMutilPrice) ||
              (_params &&
                Number(_params.multilprice) === 1 &&
                Number(_params.is_hssq) === 1)) &&
            Number(this.state.data.distribution_mode) !== 2 && (
              <div className="edittuan__switchitem">
                <div className="fbc">
                  <div className="edittuan__switchitem__text fbc">
                    让好友帮卖
                  </div>
                  <Switch
                    disabled={
                      !this.state.meta_isdistribution && this.state.type !== 3
                    }
                    checked={this.state.data.isdistribution}
                    onClick={() => {
                      const data = this.state.data
                      data.isdistribution = !data.isdistribution
                      data.notpay = false
                      this.setState({
                        data,
                      })
                      if (!this.state.data.salemodel) {
                        this.setState({
                          data: {
                            ...this.state.data,
                            salemodel: 1,
                          },
                        })
                      }
                    }}
                  />
                </div>
                {this.state.data.isdistribution ? (
                  <div
                    onClick={() => {
                      if (this.state.data.goodslist.length === 0) {
                        message.warning("请选择商品")
                        return
                      }
                      this.setState({ showDistributionSettingPanel: true })
                    }}
                    style={{
                      background: "#FFF6F6",
                      fontSize: 14,
                      color: "var(--theme-main)",
                      padding: 12,
                      marginTop: 16,
                    }}
                    className="fcc"
                  >
                    帮卖设置 &gt;
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

          {this.state._params?.bargain && (
            <BargainSetting
              onChange={e => {
                this.bargainSettingValue = e
              }}
              initHour={this.state.data.grouping_activity_info?.activity_time}
              initImages={this.state.data.grouping_activity_info?.rule_img}
            />
          )}

          {/* 更多设置 */}
          <div
            style={{
              color: "var(--theme-main)",
              fontSize: 13,
              textAlign: "center",
              marginBottom: "2em",
            }}
            className="cur-pointer"
            onClick={() => {
              // scroll.scrollTop += 300
              if (!this.state.showMore) {
                this.setState(
                  {
                    showMore: true,
                  },
                  () => {}
                )
              } else {
                this.setState({
                  showMore: false,
                })
              }
            }}
          >
            {this.state.showMore ? "收起" : "更多设置"}
          </div>
          {this.state.showMore ? (
            <div>
              {Number(this.state.data.pid) <= 0 ||
              this.state.data.pid === undefined ? (
                <div className="edittuan__panel" style={{ padding: 14 }}>
                  <div className="fbc" style={{}}>
                    <div style={{ fontSize: 14, color: "#454545" }}>
                      参团起购设置
                    </div>
                    <SelectTSX
                      uni="parchase"
                      list={[
                        { type: 0, name: "未设置" },
                        { type: 1, name: "按起购价设置" },
                        { type: 2, name: "按起购量设置" },
                      ]}
                      showKey="name"
                      value={this.state.purchase?.type || 0}
                      onChange={e => {
                        this.setState({
                          showPurchase: e.type !== 0,
                          purchase: e,
                        })
                      }}
                    />
                  </div>
                  {this.state.showPurchase ? (
                    <>
                      <div className="flex-between" style={{ marginTop: 12 }}>
                        <div style={{ fontSize: 14, color: "#454545" }}>
                          选择起购方式
                        </div>
                        <SelectTSX
                          uni="sub_parchase"
                          inputStyle={{ width: "16em" }}
                          list={[
                            {
                              type: 1,
                              name: `每次参团须满足的起购${
                                this.state.purchase.type === 1 ? "金额" : "数量"
                              }`,
                            },
                            {
                              type: 2,
                              name: `只需满足一次的起购${
                                this.state.purchase.type === 1 ? "金额" : "数量"
                              }`,
                            },
                          ]}
                          showKey="name"
                          value={(this.state.purchase?.subtype || 1) - 1}
                          onChange={e => {
                            if (!e) return
                            this.setState({
                              purchase: {
                                ...this.state.purchase,
                                subtype: e.type,
                              },
                            })
                          }}
                        />
                      </div>
                      <div className="flex-between" style={{ marginTop: 12 }}>
                        <div style={{ fontSize: 14, color: "#454545" }}>
                          参团起购{this.state.purchase.type === 1 ? "价" : "量"}
                        </div>
                        <input
                          type="number"
                          value={this.state.purchase?.value || ""}
                          style={{
                            padding: "4px 11px",
                            borderRadius: 4,
                            border: "1px solid #d9d9d9",
                          }}
                          onChange={e => {
                            this.setState({
                              purchase: {
                                ...this.state.purchase,
                                value: Number(e.target.value),
                              },
                            })
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/* {(Number(this.state.data.pid) <= 0 || this.state.data.pid === undefined) && <div className='edittuan__panel' style={{ padding: 14 }} onClick={() => {
                            CouponList()
                        }} ></div>} */}
              <SwitchItem
                text={"隐藏用户头像与昵称"}
                checked={this.state.data.ishiddenuserinfo}
                onChange={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      ishiddenuserinfo: !this.state.data.ishiddenuserinfo,
                    },
                  })
                }}
              />
              {(this.state.type === 1 || this.state.type === 3) &&
              !this.state.isHema &&
              !this.state.isHYD ? (
                <SwitchItem
                  text={"只做订单统计，无需在线支付"}
                  checked={this.state.data.notpay}
                  onChange={() => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        notpay: !this.state.data.notpay,
                      },
                    })
                  }}
                />
              ) : (
                ""
              )}
              {!this.state.isHema && !this.state.isHYD && (
                <SwitchItem
                  text={"仅支持在本群参与"}
                  checked={!this.state.data.can_share}
                  onChange={() => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        can_share: !this.state.data.can_share,
                      },
                    })
                  }}
                />
              )}
              {this.props.match.params.mode === "create" && (
                <SwitchItem
                  text={
                    <div className="flex-box">
                      <span>推送公众号通知</span>
                      <span
                        style={{
                          color: "#506694",
                          marginLeft: 12,
                          fontSize: 12,
                        }}
                      >
                        今日剩{this.state.canPushcount}次
                      </span>
                    </div>
                  }
                  checked={this.state.data.advertising}
                  onChange={() => {
                    if (this.state.canPushcount <= 0) {
                      message.warning(
                        "每天只能推送10次公众号通知，今日次数已用完"
                      )
                      this.setState({
                        data: { ...this.state.data, advertising: false },
                      })
                      return
                    }
                    this.setState({
                      data: {
                        ...this.state.data,
                        advertising: !this.state.data.advertising,
                      },
                    })
                  }}
                />
              )}
              {
                <SwitchItem
                  text={"隐藏该活动"}
                  checked={this.state.data.isprivate}
                  onChange={() => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        isprivate: !this.state.data.isprivate,
                      },
                    })
                  }}
                />
              }

              {this.state.data.takeway === 1 ? (
                <SwitchItem
                  text={
                    <div>
                      <span>自提点可选为收货地址</span>
                      {Number(this.state.data.express_to_pick_up_point) ===
                      2 ? (
                        <span
                          title="点击修改自提点"
                          onClickCapture={e => {
                            e.stopPropagation()
                            this.setState({ showExpressToAddressSelect: true })
                          }}
                          style={{
                            color: "var(--theme-main)",
                            marginLeft: "1em",
                            zIndex: 66,
                          }}
                        >
                          已选{this.state.selectedExpressToAddress.length}个
                        </span>
                      ) : null}
                    </div>
                  }
                  checked={
                    Number(this.state.data.express_to_pick_up_point) === 2
                  }
                  onChange={() => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        express_to_pick_up_point:
                          Number(this.state.data.express_to_pick_up_point) === 2
                            ? 0
                            : 2,
                      },
                      showExpressToAddressSelect:
                        Number(this.state.data.express_to_pick_up_point) !== 2,
                    })
                  }}
                />
              ) : null}

              {!this.state.isHema && !this.state.isHYD && (
                <div className="edittuan__switchitem fbc">
                  <div className="edittuan__switchitem__text fbc">
                    {(() => {
                      switch (Number(this.state.data.tagsee.see_type)) {
                        case 1:
                          return "谁可以看"
                        case 2:
                          return "谁可以看"
                        case 3:
                          return "谁可以看"
                        case 4:
                          return "谁不可看"
                        default:
                          break
                      }
                    })()}
                  </div>
                  <div
                    className="fbc"
                    onClick={() => {
                      this.setState({ showTag: true })
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "14em",
                        color:
                          Number(this.state.data.tagsee.see_type) === 4
                            ? "#fb5156"
                            : "#1AAD19",
                      }}
                      className="edittuan__switchitem__text single-text-hidden"
                    >
                      {(() => {
                        const text = this.state.data.tagsee.tag
                          .map(i => i.name)
                          .join(",")
                        switch (Number(this.state.data.tagsee.see_type)) {
                          case 1:
                            return "公开"
                          case 2:
                            return "私密"
                          case 3:
                            return text
                          case 4:
                            return text
                          default:
                            break
                        }
                      })()}
                    </div>
                    <i className="icon-you iconfont" />
                  </div>
                </div>
              )}

              {
                <div className="edittuan__switchitem fbc">
                  <div className="edittuan__switchitem__text fbc">
                    每人可下单次数
                  </div>
                  <div className="fbc">
                    <input
                      value={this.state.data.sign_num || ""}
                      maxLength={3}
                      type="number"
                      style={{
                        padding: "4px 11px",
                        borderRadius: 2,
                        border: "1px solid #eee",
                      }}
                      placeholder="不填则不限"
                      onChange={e => {
                        const data = this.state.data
                        data.sign_num = Math.floor(Number(e.target.value))
                        this.setState({ data })
                      }}
                    />
                  </div>
                </div>
              }

              {!this.state.isHema && !this.state.isHYD && (
                <SwitchItem
                  text={"参团时输入信息"}
                  checked={this.state.openSign}
                  onChange={() => {
                    this.setState({ openSign: !this.state.openSign })
                  }}
                />
              )}
            </div>
          ) : null}

          {/* 输入信息 */}
          {!this.state.isHema &&
          !this.state.isHYD &&
          this.state.openSign &&
          this.state.showMore ? (
            <InputSign
              signlist={this.state.data.sign.user}
              onChange={e => {
                const data = this.state.data
                data.sign.user = e.list
                this.setState({ data })
              }}
            />
          ) : null}
        </div>

        {/* 帮卖设置 */}
        {this.state.showDistributionSettingPanel &&
        this.state.data.salemodel &&
        (Number(this.state.data.pid) <= 0 || this.state.data.pid === undefined)
          ? (() => {
              return (
                <DistributionSetting
                  _params={_params}
                  canchangesalemodel={canchangesalemodel}
                  isHYD={this.state.isHYD || this.state.hemaOnly}
                  disabled={
                    !(
                      Number(this.state.canupdate) ||
                      this.state.canupdate === undefined
                    )
                  }
                  isshow_allseller_rank={this.state.data.isshow_allseller_rank}
                  isshow_allseller_order={
                    this.state.data.isshow_allseller_order
                  }
                  can_usemyid_start={this.state.data.can_usemyid_start}
                  only_use_my_pickup_station={
                    this.state.data.only_use_my_pickup_station
                  }
                  salemodel={this.state.data.salemodel}
                  goodslist={this.state.data.goodslist}
                  confirm={setting => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        salemodel: setting.salemodel,
                        distribution_price: setting.distri_setting,
                        ...setting.options,
                      },
                    })
                  }}
                  close={() => {
                    this.setState({ showDistributionSettingPanel: false })
                  }}
                />
              )
            })()
          : ""}

        {/* 二级帮卖设置 */}
        {this.state.showDistributionSettingPanel &&
        this.state.data.salemodel &&
        Number(this.state.data.pid) > 0 ? (
          <SubDistributionSetting
            disabled={
              !(
                Number(this.state.canupdate) ||
                this.state.canupdate === undefined
              )
            }
            isshow_allseller_rank={this.state.data.isshow_allseller_rank}
            isshow_allseller_order={this.state.data.isshow_allseller_order}
            can_usemyid_start={this.state.data.can_usemyid_start}
            salemodel={this.state.data.salemodel}
            goodslist={this.state.data.goodslist}
            oriGood={ori_goodsid}
            superiorGoodslist={this.state.superiorGoodslist}
            confirm={setting => {
              this.setState({
                data: {
                  ...this.state.data,
                  salemodel: setting.salemodel,
                  distribution_price: setting.distri_setting,
                  ...setting.options,
                },
              })
            }}
            close={() => {
              this.setState({ showDistributionSettingPanel: false })
            }}
          />
        ) : (
          ""
        )}

        {/* 添加商品 */}
        {this.state.showAddgood && !this.state.hemaOnly ? (
          <WrapGoodSelect
            cannotcancel={Array.from(ori_goodsid)}
            isHema={this.state.isHema}
            selectedHemaNB={this.state.selectedHemaNB}
            selectedGoodsid={this.state.data.goodslist.map(i => i.goodsid)}
            goodslist={this.state.goodslist}
            categorylist={this.state.categorylist}
            close={() => {
              this.setState({ showAddgood: false })
            }}
            confirm={(e, l, branch) => {
              this.setState({
                data: {
                  ...this.state.data,
                  goodslist: [
                    ...this.state.data.goodslist,
                    ...e.map((i, index) => {
                      if (i.rank && Number(i.rank) !== 0) return i
                      if (this.state.hemaMutilPrice) {
                        return {
                          ...i,
                          attribute: i.attribute.map(attr => {
                            return {
                              ...attr,
                              editMultilPriceNowPrice: (
                                Number(attr.price) / 100
                              ).toFixed(2),
                            }
                          }),
                        }
                      }
                      return {
                        ...i,
                      }
                    }),
                  ]
                    .filter(i => l.includes(i.goodsid))
                    .map((i, idx) => {
                      return { ...i, rank: idx }
                    }),
                },
              })
            }}
          />
        ) : (
          ""
        )}

        {/* 添加商品 */}
        {this.state.showAddgood && this.state.hemaOnly ? (
          <WrapGoodSelect
            cannotcancel={Array.from(ori_goodsid)}
            isHema={this.state.isHema}
            selectedHemaNB={this.state.hemaOnlyBranchList.find(
              i =>
                this.state.selectedHemaOnlyBranch === i.distribution_relation_id
            )}
            selectedGoodsid={
              this.state.hemaOnlyBranchList
                ?.find(
                  i =>
                    this.state.selectedHemaOnlyBranch ===
                    i.distribution_relation_id
                )
                ?.goodslist?.map(i => i.goodsid) || []
            }
            close={() => {
              this.setState({ showAddgood: false })
            }}
            confirm={(e, l, branch) => {
              const b = this.state.hemaOnlyBranchList.find(
                i =>
                  this.state.selectedHemaOnlyBranch ===
                  i.distribution_relation_id
              )
              b.goodslist = [
                ...(b.goodslist || []),
                ...e.map((i, index) => {
                  if (i.rank && Number(i.rank) !== 0) return i
                  return {
                    ...i,
                    rank: index + (b.goodslist || []).length,
                    attribute: i.attribute.map(attr => {
                      return {
                        ...attr,
                        editMultilPriceNowPrice: (
                          Number(attr.price) / 100
                        ).toFixed(2),
                      }
                    }),
                  }
                }),
              ]
                .filter(i => l.includes(i.goodsid))
                .sort((a, b) => a.rank - b.rank)
                .map((i, idx) => {
                  return {
                    ...i,
                    rank: idx,
                  }
                })
              this.setState({
                hemaOnlyBranchList: this.state.hemaOnlyBranchList,
              })
            }}
          />
        ) : (
          ""
        )}

        {/* 选择自提点 */}
        {this.state.showAddressSelect &&
        this.state.data.takeway === 2 &&
        !this.state.hemaOnly ? (
          <Address
            selectedAddress={this.state.selectedAddress}
            onClose={() => {
              this.setState({ showAddressSelect: false })
            }}
            isHema={this.state.isHema}
            is_hssq={this.state.is_hssq}
            branch={this.state.selectedHemaNB}
            onConfirm={list => {
              this.setState({
                selectedAddress: list,
                showAddressSelect: false,
              })
            }}
          />
        ) : null}

        {/* 选择自提点 */}
        {this.state.showAddressSelect &&
        this.state.data.takeway === 2 &&
        this.state.hemaOnly ? (
          <Address
            selectedAddress={
              this.state.hemaOnlyBranchList.find(
                i =>
                  this.state.selectedHemaOnlyBranch ===
                  i.distribution_relation_id
              )?.addressList || []
            }
            onClose={() => {
              this.setState({ showAddressSelect: false })
            }}
            isHema={this.state.isHema}
            branch={this.state.hemaOnlyBranchList.find(
              i =>
                this.state.selectedHemaOnlyBranch === i.distribution_relation_id
            )}
            onConfirm={list => {
              this.state.hemaOnlyBranchList.find(
                i =>
                  this.state.selectedHemaOnlyBranch ===
                  i.distribution_relation_id
              ).addressList = list
              this.setState({
                hemaOnlyBranchList: this.state.hemaOnlyBranchList,
              })
            }}
          />
        ) : null}

        {/* 选择自提点 */}
        {this.state.showExpressToAddressSelect &&
        this.state.data.takeway === 1 ? (
          <Address
            selectedAddress={this.state.selectedExpressToAddress || []}
            onClose={() => {
              this.setState({ showExpressToAddressSelect: false })
            }}
            onConfirm={list => {
              this.setState({
                selectedExpressToAddress: list,
                showExpressToAddressSelect: false,
              })
            }}
          />
        ) : null}

        {/* 转圈圈 */}
        {this.state.Loading ? <Loading /> : null}

        {/* 选择tag */}
        {this.state.showTag ? (
          <Tag
            initData={this.state.data.tagsee}
            onClose={() => {
              this.setState({ showTag: false })
            }}
            onConfirm={e => {
              this.setState({
                data: {
                  ...this.state.data,
                  tagsee: {
                    ...e,
                  },
                },
                showTag: false,
              })
            }}
          />
        ) : null}

        {/* 选择运费 */}
        {this.state.showSelectedFare ? (
          <Fare
            selectedId={this.state.selectedFare.fare_template_id}
            onConfirm={e => {
              if (!e) {
                this.setState({
                  selectedFare: {},
                })
                return
              }
              this.setState({
                selectedFare: e,
              })
            }}
            onClose={() => {
              this.setState({ showSelectedFare: false })
            }}
          />
        ) : null}
      </div>
    )
  }

  videoUpload(file) {
    const data = new FormData()
    data.append("types", 1)
    data.append("uploadtype", "video")
    data.append("file", file)
    // this.props.showLoading()
    return api("file", "upload")({}, "post", data)
  }

  openAddressSelect() {
    if (this.state.isHema && !this.state.hemaOnly) {
      if (
        !this.state.selectedHemaNB ||
        !this.state.selectedHemaNB.distribution_relation_id
      ) {
        message.warning("请选择NB门店")
        return
      }
    }
    this.setState({ showAddressSelect: true })
  }

  getGroup() {
    get("/seller/getteamlist", {
      types: 1,
      offset: 0,
      limit: 99,
    }).then(res => {
      this.setState({
        teamList: res.list,
      })
    })
  }

  getOther() {
    const _this = this
    return new Promise(function (resolve, reject) {
      let goodlist = false
      let catelist = false
      if (_this.state.data && Number(_this.state.data.pid) > 0) {
        resolve()
        return
      }
      if (_this.state.goodslist && _this.state.goodslist.length > 0) {
        resolve()
        return
      }
      // 获取我的商品
      api(
        "goods",
        "getlistbysid"
      )({
        offset: 0,
        limit: 100000,
        sid: window.btoa(_this.props.user.self_sid),
        types: 1,
        op: "display",
      }).then(res => {
        if (!res.data.data) {
          console.log("error")
          return
        }
        if (res.data.data.list.length === 0) {
          reject()
        }
        _this.setState({ goodslist: res.data.data.list })
        goodlist = true
        if (catelist && goodlist) {
          resolve()
        }
      })
      // 获取商品分类列表
      api(
        "goodscategory",
        "getall"
      )({
        sid: window.btoa(_this.props.user.self_sid),
        types: 1,
      }).then(res => {
        if (!res.data.data) {
          return
        }
        _this.setState({ categorylist: res.data.data })
        catelist = true
        if (catelist && goodlist) {
          resolve()
        }
      })
    })
  }

  submit() {
    const data = {}
    const { _params } = this.state

    // is_hssq

    if (this.is_hssq) data.is_hssq = this.is_hssq ? 1 : 0

    // 保留信息
    data.content = ""
    data.imageids = []
    data.type = "tuan"
    data.sign = []
    data.sign_num = this.state.data.sign_num || 0

    // 发送公众号信息
    data.advertising = this.state.data.advertising

    // 设置团购总起购限制
    if (this.state.purchase) {
      data.limit_price = this.state.purchase?.value || 0
      const type = Number(this.state.purchase.type)
      if (type === 1) data.limit_price *= 100
      const subtype = Number(this.state.purchase.subtype || 1)
      switch (type) {
        case 1:
          if (subtype === 1) data.startpaymodel = 1
          if (subtype === 2) data.startpaymodel = 2
          break
        case 2:
          if (subtype === 1) data.startpaymodel = 3
          if (subtype === 2) data.startpaymodel = 4
          break
        default:
          break
      }
    }

    // 设置标题
    data.title = this.state.data.title
    if (!data.title) {
      message.error("请填写标题")
      return
    }

    // 设置团购详情
    data.tuan_detail = this.content.current.state.list.map((i, index) => {
      const d = {}
      d.type = i.type
      d.rank = index
      if (Number(i.type) === 1) {
        d.value = i.value
      } else if (Number(i.type) === 2) {
        d.value = i.value.id
      } else if (Number(i.type) === 3) {
        d.value = i.value.map(j => {
          return {
            id: j.id,
            rank: j.rank,
          }
        })
      } else if (Number(i.type) === 4) {
        d.istop = i.istop
        d.value = i.value.id
      }
      return d
    })

    // 商品id
    if (this.state.hemaOnly) {
      try {
        data.goodsids = this.state.hemaOnlyBranchList
          .reduce((prev, branch) => {
            if (!branch.select) return prev
            if (!branch.goodslist || branch.goodslist.length === 0) {
              message.error(`请选择${branch.branch_name}的商品`)
              throw Error("请选择商品")
            }
            return [...prev, ...branch.goodslist]
          }, [])
          .map(g => {
            return {
              id: g.goodsid,
              rank: g.rank,
              limit_count:
                Number(g.limit_count) > 0 ? Number(g.limit_count) : 0,
              start_count:
                Number(g.start_count) > 0 ? Number(g.start_count) : 0,
              is_required: g.is_required,
            }
          })
      } catch (error) {
        return
      }
    } else {
      data.goodsids = this.state.data.goodslist.map(g => {
        return {
          id: g.goodsid,
          rank: g.rank,
          limit_count: Number(g.limit_count) > 0 ? Number(g.limit_count) : 0,
          start_count: Number(g.start_count) > 0 ? Number(g.start_count) : 0,
          is_required: g.is_required,
        }
      })
    }
    if (data.goodsids.length === 0) {
      message.error("请选择商品")
      return
    }

    // 配送方式
    data.takeway = this.state.data.takeway

    // 自提点
    if (data.takeway === 2) {
      if (this.state.hemaOnly) {
        try {
          data.addressids = this.state.hemaOnlyBranchList.reduce(
            (prev, branch) => {
              if (!branch.select) return prev
              if (!branch.addressList || branch.addressList.length === 0) {
                message.error(`请选择${branch.branch_name}的自提点`)
                throw Error("请选择自提点")
              }
              return [...prev, ...branch.addressList]
            },
            []
          )
        } catch (error) {
          return
        }
      } else {
        data.addressids = Array.from(this.state.selectedAddress)
        if (data.addressids.length === 0) {
          message.error("请选择自提点")
          return
        }
      }
    } else {
      data.addressids = []
    }

    // sid
    data.sid = this.props.user.self_sid

    // 是否允许用户转发
    data.can_share = this.state.data.can_share

    // 隐藏
    data.isprivate = this.state.data.isprivate

    // 隐藏用户信息
    data.ishiddenuserinfo = this.state.data.ishiddenuserinfo

    // 时间限制
    if (this.state.timelimit === 0) {
      data.time_type = []
    } else if (this.state.timelimit === 1) {
      data.time_type = this.state.data.time_type
    }

    // 帮卖佣金
    data.isdistribution = this.state.data.isdistribution || 0
    data.distributionid = this.state.data.distributionid || 0
    data.pid = this.state.data.pid || 0
    if (data.isdistribution && !this.state.isHYD && !this.state.hemaOnly) {
      if (data.pid === -1) {
        data.pid = 0
      }

      data.distribution_price = this.state.data.distribution_price
      data.salemodel = this.state.data.salemodel

      if (!data.distribution_price) {
        message.error("请填写帮卖设置")
        return
      }

      // 使用原有商品分销设置
      if (!data.distribution_price) {
        for (let i = 0; i < this.state.data.goodslist.length; i++) {
          const good = this.state.data.goodslist[i]
          for (let j = 0; j < good.attribute.length; j++) {
            const attr = good.attribute[j]
            const dp = attr.distribution_price
            if (dp.sellerprice === undefined || dp.sellerprice === null) break
            data.distribution_price.push({
              nowprice: dp.nowprice,
              baseprice: dp.baseprice,
              sellerprice: dp.sellerprice,
              goodsattrid: attr.id,
              goodsid: good.goodsid,
              input_number: (Number(attr.value) * 100).toFixed(0),
            })
          }
        }
      }

      // 补全input_number
      for (let i = 0; i < data.distribution_price.length; i++) {
        const item = data.distribution_price[i]
        if (!item.input_number) {
          delete item.salesnum
          if (data.salemodel === 1) {
            item.input_number = item.nowprice - item.sellerprice
          } else if (data.salemodel === 2) {
            item.input_number = item.sellerprice
          }
        }
      }

      // 好友开团时使用我的身份
      data.can_usemyid_start = this.state.data.can_usemyid_start || 0

      // 允许所有好友共享订单
      data.isshow_allseller_order = this.state.data.isshow_allseller_order || 0

      // 允许好友查看我的好友排行榜
      data.isshow_allseller_rank = this.state.data.isshow_allseller_rank || 0

      // 好友只能使用1级的自提点
      data.only_use_my_pickup_station =
        this.state.data.only_use_my_pickup_station || 0

      // 检测商品
      for (let i = 0; i < this.state.data.goodslist.length; i++) {
        const good = this.state.data.goodslist[i]
        for (let j = 0; j < good.attribute.length; j++) {
          const attr = good.attribute[j]
          const id = Number(attr.id)
          let has = false
          for (let k = 0; k < data.distribution_price.length; k++) {
            const dp = data.distribution_price[k]
            if (Number(dp.goodsattrid) === id) {
              has = true
              break
            }
          }
          if (!has) {
            message.error(`请完成${good.name}的帮卖设置`)
            return
          }
        }
      }
    } else if (data.isdistribution && this.state.isHYD) {
      // 使用原有商品分销设置
      data.distribution_price = []
      for (let i = 0; i < this.state.data.goodslist.length; i++) {
        const good = this.state.data.goodslist[i]
        for (let j = 0; j < good.attribute.length; j++) {
          const attr = good.attribute[j]
          data.distribution_price.push({
            goodsattrid: attr.id,
            goodsid: good.goodsid,
            input_number: (Number(attr.editInputNumber || 0) * 100).toFixed(0),
            nowprice: (Number(attr.editNowPrice || 0) * 100).toFixed(0),
          })
          if (Number(attr.editInputNumber || 0) === 0) {
            message.warning("请填写供应价")
            return
          }
          if (Number(attr.editNowPrice || 0) === 0) {
            message.warning("请填写对外售价")
            return
          }
        }
      }

      data.salemodel = this.state.data.salemodel
      // 好友开团时使用我的身份
      data.can_usemyid_start = this.state.data.can_usemyid_start || 0

      // 允许所有好友共享订单
      data.isshow_allseller_order = this.state.data.isshow_allseller_order || 0

      // 允许好友查看我的好友排行榜
      data.isshow_allseller_rank = this.state.data.isshow_allseller_rank || 0

      // 好友只能使用1级的自提点
      data.only_use_my_pickup_station =
        this.state.data.only_use_my_pickup_station || 0
    } else if (data.isdistribution && this.state.hemaOnly) {
      data.distribution_price = []
      const branchlist = this.state.hemaOnlyBranchList.filter(i => i.select)
      for (let i = 0; i < branchlist.length; i++) {
        const branch = branchlist[i]
        for (let j = 0; j < branch.goodslist.length; j++) {
          const good = branch.goodslist[j]
          for (let k = 0; k < good.attribute.length; k++) {
            const attr = good.attribute[k]
            data.distribution_price.push({
              goodsattrid: attr.id,
              goodsid: good.goodsid,
              input_number: (Number(attr.editInputNumber || 0) * 100).toFixed(
                0
              ),
              nowprice: (Number(attr.editNowPrice || 0) * 100).toFixed(0),
            })
            if (Number(attr.editInputNumber || 0) === 0) {
              message.warning("请填写供应价")
              return
            }
            if (Number(attr.editNowPrice || 0) === 0) {
              message.warning("请填写对外售价")
              return
            }
          }
        }
      }

      data.salemodel = this.state.data.salemodel
      // 好友开团时使用我的身份
      data.can_usemyid_start = this.state.data.can_usemyid_start || 0

      // 允许所有好友共享订单
      data.isshow_allseller_order = this.state.data.isshow_allseller_order || 0

      // 允许好友查看我的好友排行榜
      data.isshow_allseller_rank = this.state.data.isshow_allseller_rank || 0

      // 好友只能使用1级的自提点
      data.only_use_my_pickup_station =
        this.state.data.only_use_my_pickup_station || 0
    } else {
      data.pid = -1
      if (this.state.hemaMutilPrice) {
        data.is_multil_price_tuan = 1
        data.tuan_multi_price = []
        for (let i = 0; i < this.state.data.goodslist.length; i++) {
          const good = this.state.data.goodslist[i]
          for (let j = 0; j < good.attribute.length; j++) {
            const attr = good.attribute[j]
            data.tuan_multi_price.push({
              nowprice: (Number(attr.editMultilPriceNowPrice) * 100).toFixed(0),
              goodsattrid: attr.id,
              goodsid: good.goodsid,
            })
          }
        }
      }
    }

    if (
      _params &&
      Number(_params.multilprice) === 1 &&
      Number(_params.is_hssq) === 1
    ) {
      data.is_multil_price_tuan = 1
      data.tuan_multi_price = []
      for (let i = 0; i < this.state.data.goodslist.length; i++) {
        const good = this.state.data.goodslist[i]
        for (let j = 0; j < good.attribute.length; j++) {
          const attr = good.attribute[j]
          data.tuan_multi_price.push({
            nowprice: (Number(attr.editMultilPriceNowPrice) * 100).toFixed(0),
            goodsattrid: attr.id,
            goodsid: good.goodsid,
          })
        }
      }
    }

    if (this.state.hemaMutilPrice) data.is_multil_price_tuan = 1

    // notpay
    data.notpay = this.state.data.notpay
    if (data.isdistribution) {
      data.notpay = 0
    }

    // 运费模板
    data.fare_template_id = this.state.selectedFare.fare_template_id

    // 谁可以看
    data.see_type = this.state.data.tagsee.see_type || 1
    data.text = (this.state.data.tagsee.tag || []).map(i => i.id)

    // 一级快递到自提点
    data.express_to_pick_up_point = this.state.data.express_to_pick_up_point
    data.express_addressids = this.state.selectedExpressToAddress

    // 填写盒马nb
    if (this.state.isHema && !this.state.hemaOnly) {
      data.mall_id = this.state.selectedHemaNB.id
    }

    if (this.state.hemaOnly) {
      data.is_hema_exclusive = 1
    }

    // hssq拼团信息
    if (this.state._params.bargain) {
      data.type = "pt"
      data.address = ""
      data.rule_img = this.bargainSettingValue?.images?.map((item, rank) => ({
        id: item.id,
        rank,
      }))
      data.grouping_activity_config = {
        must_new_user_join: true,
        capacity: 2,
        activity_time: this.bargainSettingValue?.hour,
      }
    }

    const send = async function () {
      this.setState({ Loading: true })
      // 置顶视频
      if (
        this.state.topVideo &&
        this.state.topVideo.file &&
        this.state.canUseVideo
      ) {
        try {
          const video = await this.videoUpload(this.state.topVideo.file)
          data.tuan_detail.push({
            type: 4,
            value: video.data.data.file.id,
            istop: 1,
            rank: data.tuan_detail.length + 1,
          })
        } catch (error) {
          console.log(error)
          message.error("视频上传出错")
        }
      }
      if (this.state.type === 1) {
        // 创建团购
        api("tuan", "create")({ app: appname }, "post", {
          ...data,
          types: 1,
        })
          .then(res => {
            if (Number(res.data.code) === 0) {
              localStorage.removeItem("edittingTuan")
              this.props.history.push(`/index/t/${res.data.data.id}/detail`)
            } else {
              this.setState({
                Loading: false,
              })
            }
          })
          .catch(e => {
            this.setState({
              Loading: false,
            })
          })
      }
      if (this.state.type === 2) {
        // 修改团购
        // 团购id
        data.id = this.state.data.id
        data.sign = data.sign.map(i => {
          if (Number(i.type) !== 2 && Number(i.type) !== 6) {
            const s = { ...i }
            delete s.editting
            return s
          }
          const s = {
            ...i,
            data_value: i.data_value.map(j => {
              return {
                value: j.content,
                ...j,
              }
            }),
          }
          delete s.editting
          return s
        })

        api("tuan", "update")({ app: appname }, "post", {
          ...data,
          types: 1,
        })
          .then(res => {
            if (Number(res.data.code) === 0) {
              localStorage.removeItem("edittingTuan")
              this.props.history.push(`/index/t/${res.data.data.id}/detail`)
            } else {
              this.setState({
                Loading: false,
              })
            }
          })
          .catch(e => {
            this.setState({
              Loading: false,
            })
          })
      }
      if (this.state.type === 3) {
        // 复制团购
        data.sign = data.sign
          .filter(i => Number(i.systemid) === 0)
          .map(i => {
            const sign = { ...i }
            delete sign.id
            return sign
          })
        delete data.distributionid
        api("tuan", "create")({ app: appname }, "post", {
          ...data,
          types: 1,
        })
          .then(res => {
            if (Number(res.data.code) === 0) {
              localStorage.removeItem("edittingTuan")
              this.props.history.push(`/index/t/${res.data.data.id}/detail`)
            } else {
              this.setState({
                Loading: false,
              })
            }
          })
          .catch(e => {
            this.setState({
              Loading: false,
            })
          })
      }
    }

    // sign
    data.sign = []
    if (this.state.openSign) {
      data.sign = this.state.data.sign.user
    }

    const addSignOption = []
    const delSignOption = []
    const updateSignOption = []

    for (let i = 0; i < data.sign.length; i++) {
      const sign = data.sign[i]
      if (Number(sign.type) !== 2 && Number(sign.type) !== 6) continue
      for (let j = 0; j < sourceSign.length; j++) {
        const s_sign = sourceSign[j]

        if (Number(s_sign.id) === Number(sign.id)) {
          const delid = []
          const addContent = []
          // 已选id
          const Sign_id = new Set(sign.data_value.map(i => Number(i.id)))

          // 原有id
          const SSign_id = new Set(s_sign.data_value.map(i => Number(i.id)))

          // 计算被删除问题
          for (const id of SSign_id) {
            if (!Sign_id.has(id)) {
              delid.push(id)
            }
          }
          if (delid.length > 0) {
            delSignOption.push({
              id: sign.id,
              del_id: delid,
            })
          }

          // 添加问题
          for (let k = 0; k < sign.data_value.length; k++) {
            const item = sign.data_value[k]
            if (!item.id) {
              addContent.push({ value: item.content })
            } else {
              if (
                s_sign.data_value.filter(i => {
                  return i.id === item.id && i.content === item.content
                })[0]
              ) {
              } else {
                updateSignOption.push({ id: item.id, value: item.content })
              }
            }
          }
          if (addContent.length > 0) {
            addSignOption.push({
              id: sign.id,
              data_value: addContent,
            })
          }
        }
      }
    }

    this.setState({
      banLeave: false,
    })

    let addOptionReady = false
    let delOptionReady = false
    let updateOptionReady = false

    if (addSignOption.length > 0) {
      api("fieldoption", "create")({ app: appname }, "post", {
        ...addSignOption,
        types: 1,
      }).then(res => {
        if (Number(res.data.code) === 0) {
          addOptionReady = true
          if (addOptionReady && delOptionReady && updateOptionReady) {
            send.call(this)
          }
        }
      })
    } else {
      addOptionReady = true
    }

    if (delSignOption.length > 0) {
      api("fieldoption", "delete")({ app: appname }, "post", {
        ...delSignOption,
        types: 1,
      }).then(res => {
        if (Number(res.data.code) === 0) {
          delOptionReady = true
          if (addOptionReady && delOptionReady && updateOptionReady) {
            send.call(this)
          }
        }
      })
    } else {
      delOptionReady = true
    }

    if (updateSignOption.length > 0) {
      api("fieldoption", "update")({ app: appname }, "post", {
        data_value: updateSignOption,
        types: 1,
      }).then(res => {
        if (Number(res.data.code) === 0) {
          updateOptionReady = true
          if (addOptionReady && delOptionReady && updateOptionReady) {
            send.call(this)
          }
        }
      })
    } else {
      updateOptionReady = true
    }

    if (addOptionReady && delOptionReady && updateOptionReady) {
      send.call(this)
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoginBindInfo: data => {
      dispatch(setLoginBindInfo(data))
    },
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.login_user_data,
    loginBindInfo: state.loginBindInfo,
  }
}

export default BanUnLogin(
  Main(connect(mapStateToProps, mapDispatchToProps)(Edit, false))
)

let dragGoodData
class Good extends Component {
  constructor(p) {
    super()
    this.state = {
      showMenu: false,
      start_count: p.good.start_count || "",
      limit_count: p.good.limit_count || "",
    }
  }
  componentDidMount() {
    if (this.props.isHema) {
      GOOD_ITEM_HEIGHT = 340
      this.setState({
        isHema: this.props.isHema,
      })
    }

    if (this.props.isHYD && this.props.distribution) GOOD_ITEM_HEIGHT = 375
    if (this.props.hemaOnly && this.props.distribution) GOOD_ITEM_HEIGHT = 375

    const good = this.props.good
    if (
      good.attribute.find(
        i => i.editPrice === undefined || i.editQuantity === undefined
      )
    ) {
      this.props.setData({
        attribute: good.attribute.map(i => {
          const data = { ...i }
          data.editPrice = (Number(i.price) / 100).toFixed(2)
          data.editOriginPrice = (Number(i.original_price) / 100).toFixed(2)
          data.editQuantity = Number(i.quantity)
          return data
        }),
      })
    }
    if (this.state.isHema || this.props.isHYD) {
      if (
        good.attribute[0].start_with &&
        Number(good.attribute[0].start_with) !== Number(this.props.start_count)
      ) {
        this.props.setData({
          start_count: Number(good.attribute[0].start_with),
          limit_count: this.state.limit_count,
        })
        this.setState({
          start_count: Number(good.attribute[0].start_with),
          limit_count: this.state.limit_count,
        })
      }
    }
  }

  componentDidUpdate() {
    const good = this.props.good
    if (
      good.attribute.find(
        i => i.editPrice === undefined || i.editQuantity === undefined
      )
    ) {
      this.props.setData({
        attribute: good.attribute.map(i => {
          const data = { ...i }
          data.editPrice = (Number(i.price) / 100).toFixed(2)
          data.editOriginPrice = (Number(i.original_price) / 100).toFixed(2)
          data.editQuantity = Number(i.quantity)
          return data
        }),
      })
    }
    if (this.state.isHema || this.props.isHYD) {
      if (
        good.attribute[0].start_with &&
        Number(good.attribute[0].start_with) !== Number(this.props.start_count)
      ) {
        if (Number(this.props.start_count))
          good.attribute[0].start_with = Number(this.props.start_count)
        this.props.setData({
          start_count:
            Number(this.props.start_count) ||
            Number(good.attribute[0].start_with),
          limit_count: this.state.limit_count,
          attribute: good.attribute,
        })
        this.setState({
          start_count:
            Number(this.props.start_count) ||
            Number(good.attribute[0].start_with),
          limit_count: this.state.limit_count,
        })
      }
    }
  }

  render() {
    const good = this.props.good
    return (
      <div
        draggable={!this.state.stopDrag}
        className="edittuan__goods__item fcc"
        style={{
          justifyContent: "flex-start",
          left:
            (() => {
              const x = (this.props.good.rank + 1) % 3
              if (x === 0) return 0
              if (x === 1) return 35
              if (x === 2) return 70
            })() + "%",
          top:
            Math.floor((Number(this.props.good.rank) + 1) / 3) *
            (GOOD_ITEM_HEIGHT + 16),
          height: GOOD_ITEM_HEIGHT,
        }}
        onDragEnter={e => {
          if (!e.target.draggable) return
          this.props.changeRank(this.props.good)
        }}
        onDragStart={() => {
          dragGoodData = this.props.good
        }}
        onMouseLeave={() => {
          this.setState({ showMenu: false })
        }}
      >
        <img
          className="img-center edittuan__goods__item__logo"
          draggable={false}
          alt={this.props.good.name}
          src={this.props.good.logo}
        />
        <div className="single-text-hidden edittuan__goods__item__title">
          {this.props.good.name}
        </div>
        {!(this.props.isHema || this.props.isHYD) && (
          <>
            <div
              style={{
                width: "100%",
                flexWrap: "wrap",
                color: "var(--theme-main)",
                fontSize: 12,
              }}
            >
              {Number(good.is_required) === 1 ? (
                <span style={{ marginRight: 12 }}>必选</span>
              ) : null}
              {Number(this.props.start_count) > 1 ? (
                <span style={{ marginRight: 12 }}>
                  起购{this.props.start_count}件
                </span>
              ) : null}
              {Number(this.props.limit_count) > 0 ? (
                <span>限购{this.props.limit_count}件</span>
              ) : null}
            </div>
            <div style={{ width: "100%", flexWrap: "wrap" }} className="fbc">
              {(() => {
                let max = Number(
                  this.props.good.attribute[0].distribution_price &&
                    this.props.good.attribute[0].distribution_price.nowprice
                    ? this.props.good.attribute[0].distribution_price.nowprice
                    : this.props.good.attribute[0].price
                )
                let min = Number(
                  this.props.good.attribute[0].distribution_price &&
                    this.props.good.attribute[0].distribution_price.nowprice
                    ? this.props.good.attribute[0].distribution_price.nowprice
                    : this.props.good.attribute[0].price
                )
                for (
                  let index = 0;
                  index < this.props.good.attribute.length;
                  index++
                ) {
                  const p = Number(
                    this.props.good.attribute[index].distribution_price &&
                      this.props.good.attribute[index].distribution_price
                        .nowprice
                      ? this.props.good.attribute[index].distribution_price
                          .nowprice
                      : this.props.good.attribute[index].price
                  )
                  if (p > max) {
                    max = p
                  }
                  if (p < min) {
                    min = p
                  }
                }
                if (max === min) {
                  return (
                    <div className="edittuan__goods__item__price">
                      ￥{(min / 100).toFixed(2)}
                    </div>
                  )
                } else {
                  return (
                    <div className="edittuan__goods__item__price">
                      ￥{(min / 100).toFixed(2)}~￥{(max / 100).toFixed(2)}
                    </div>
                  )
                }
              })()}
            </div>
          </>
        )}

        {(this.props.isHema || this.props.isHYD) && (
          <>
            {(Number(good.is_required) === 1 ||
              Number(this.props.start_count) > 1 ||
              Number(this.props.limit_count) > 0) && (
              <div
                style={{
                  background: "#FFA500",
                  fontSize: 12,
                  color: "#fff",
                  position: "absolute",
                  top: 113,
                  left: 12,
                  padding: "2px 5px",
                  borderRadius: 4,
                }}
              >
                {Number(good.is_required) === 1 ? (
                  <span style={{ marginRight: 12 }}>必选</span>
                ) : null}
                {Number(this.props.start_count) > 1 ? (
                  <span style={{ marginRight: 12 }}>
                    起购{this.props.start_count}件
                  </span>
                ) : null}
                {Number(this.props.limit_count) > 0 ? (
                  <span>限购{this.props.limit_count}件</span>
                ) : null}
              </div>
            )}
            {good.attribute.length === 1 && (
              <>
                <div
                  className="single-text-hidden"
                  style={{ width: "100%", flexWrap: "wrap", marginBottom: 10 }}
                >
                  {good.attribute[0].unit}
                </div>
                <div
                  className="flex-between"
                  style={{
                    width: "100%",
                    flexWrap: "wrap",
                    fontSize: 12,
                    color: "#999",
                    marginBottom: 10,
                  }}
                >
                  <span>
                    商城库存：
                    {Number(good.attribute[0].isunlimited) === 0
                      ? good.attribute[0].mall_quantity
                      : "无限"}
                  </span>
                  <span>
                    商城价格：￥
                    <span style={{ fontSize: 14, color: "#333" }}>
                      {(Number(good.attribute[0].mall_price) / 100).toFixed(2)}
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    flexWrap: "wrap",
                    marginBottom: 10,
                    fontSize: 12,
                  }}
                >
                  <span>活动库存</span>
                  <input
                    onChange={e => {
                      const value = e.target.value
                      good.attribute[0].editQuantity = value
                      this.props.setData({
                        attribute: good.attribute,
                      })
                    }}
                    onBlur={() => {
                      if (
                        Number(good.attribute[0].editQuantity) ===
                        Number(good.attribute[0].quantity)
                      )
                        return
                      if (
                        !window.confirm(
                          "你修改的信息，将会同步至所有调取该商品的团购活动，是否坚持继续修改？"
                        )
                      ) {
                        good.attribute[0].editQuantity = Number(
                          good.attribute[0].quantity
                        )
                        this.props.setData({
                          attribute: good.attribute,
                        })
                        return
                      }
                      post("/goods/updatetuangoodsinfo", {
                        type: 1,
                        goods_attr_id: good.attribute[0].id,
                        value: good.attribute[0].editQuantity || 0,
                      })
                        .then(() => {
                          good.attribute[0].editQuantity = Number(
                            good.attribute[0].editQuantity
                          )
                          good.attribute[0].quantity = Number(
                            good.attribute[0].editQuantity
                          )
                          this.props.setData({
                            attribute: good.attribute,
                          })
                        })
                        .catch(e => {
                          good.attribute[0].editQuantity = Number(
                            good.attribute[0].quantity
                          )
                          this.props.setData({
                            attribute: good.attribute,
                          })
                          message.error(e)
                        })
                    }}
                    type="number"
                    value={good.attribute[0].editQuantity || ""}
                    style={{
                      width: 90,
                      border: 0,
                      borderBottom: "1px solid #999",
                      textAlign: "center",
                    }}
                  />
                  <span>件</span>
                </div>
                {!(
                  (this.props.isHYD || this.props.hemaOnly) &&
                  this.props.distribution
                ) &&
                  !this.props.hemaMutilPrice && (
                    <div
                      style={{
                        width: "100%",
                        flexWrap: "wrap",
                        fontSize: 12,
                        marginBottom: 10,
                      }}
                    >
                      <span>活动价格</span>
                      <input
                        onChange={e => {
                          const value = e.target.value
                          good.attribute[0].editPrice = value
                          this.props.setData({
                            attribute: good.attribute,
                          })
                        }}
                        onBlur={() => {
                          function change() {
                            if (
                              good.attribute[0].editPrice ===
                              (good.attribute[0].price / 100).toFixed(2)
                            )
                              return
                            if (
                              !window.confirm(
                                "你修改的信息，将会同步至所有调取该商品的团购活动，是否坚持继续修改？"
                              )
                            ) {
                              good.attribute[0].editPrice = (
                                good.attribute[0].price / 100
                              ).toFixed(2)
                              this.props.setData({
                                attribute: good.attribute,
                              })
                              return
                            }
                            if (Number(good.attribute[0].editPrice) === 0)
                              good.attribute[0].editPrice = (
                                good.attribute[0].price / 100
                              ).toFixed(2)
                            post("/goods/updatetuangoodsinfo", {
                              type: 2,
                              goods_attr_id: good.attribute[0].id,
                              value: (
                                good.attribute[0].editPrice * 100
                              ).toFixed(0),
                            })
                              .then(() => {
                                good.attribute[0].editPrice = Number(
                                  good.attribute[0].editPrice
                                ).toFixed(2)
                                good.attribute[0].price =
                                  Number(good.attribute[0].editPrice) * 100
                                if (
                                  good.attribute[0].distribution_price &&
                                  good.attribute[0].distribution_price.nowprice
                                ) {
                                  good.attribute[0].distribution_price.nowprice =
                                    Number(good.attribute[0].editPrice) * 100
                                }
                                this.props.setData({
                                  attribute: good.attribute,
                                })
                              })
                              .catch(e => {
                                good.attribute[0].editPrice = (
                                  good.attribute[0].price / 100
                                ).toFixed(2)
                                this.props.setData({
                                  attribute: good.attribute,
                                })
                                message.error(e)
                                change()
                              })
                          }
                          change.call(this)
                        }}
                        disabled={this.props.priceEditDisable}
                        type="number"
                        value={good.attribute[0].editPrice || ""}
                        style={{
                          width: 90,
                          border: 0,
                          borderBottom: "1px solid #999",
                          textAlign: "center",
                          color: "var(--theme-main)",
                          fontSize: 16,
                        }}
                      />
                      <span>元</span>
                    </div>
                  )}
                {!this.props.isHYD && this.props.hemaMutilPrice && (
                  <div
                    style={{
                      width: "100%",
                      flexWrap: "wrap",
                      fontSize: 12,
                      marginBottom: 10,
                    }}
                  >
                    <span>活动价格</span>
                    <input
                      onChange={e => {
                        const value = e.target.value
                        good.attribute[0].editMultilPriceNowPrice = value
                        if (good.attribute[0].distribution_price) {
                          good.attribute[0].distribution_price.nowprice = (
                            value * 100
                          ).toFixed(0)
                        } else {
                          good.attribute[0].distribution_price = {
                            nowprice: (value * 100).toFixed(0),
                          }
                        }
                        this.props.setData({
                          attribute: good.attribute,
                        })
                      }}
                      type="number"
                      value={good.attribute[0].editMultilPriceNowPrice || ""}
                      style={{
                        width: 90,
                        border: 0,
                        borderBottom: "1px solid #999",
                        textAlign: "center",
                        color: "var(--theme-main)",
                        fontSize: 16,
                      }}
                    />
                    <span>元</span>
                  </div>
                )}
                {(this.props.isHYD || this.props.hemaOnly) &&
                  !!Number(this.props.distribution) && (
                    <div
                      style={{
                        width: "100%",
                        flexWrap: "wrap",
                        fontSize: 12,
                        marginBottom: 10,
                      }}
                    >
                      <span>供应价</span>
                      <input
                        onChange={e => {
                          const value = e.target.value
                          good.attribute[0].editInputNumber = value
                          this.props.setData({
                            attribute: good.attribute,
                          })
                        }}
                        disabled={!this.props.hydPriceEaditable}
                        type="number"
                        value={good.attribute[0].editInputNumber || ""}
                        style={{
                          width: 90,
                          border: 0,
                          borderBottom: "1px solid #999",
                          textAlign: "center",
                          color: "var(--theme-main)",
                          fontSize: 16,
                        }}
                      />
                      <span>元</span>
                    </div>
                  )}
                {(this.props.isHYD || this.props.hemaOnly) &&
                  !!Number(this.props.distribution) && (
                    <div
                      style={{
                        width: "100%",
                        flexWrap: "wrap",
                        fontSize: 12,
                        marginBottom: 10,
                      }}
                    >
                      <span>对外售价</span>
                      <input
                        onChange={e => {
                          const value = e.target.value
                          good.attribute[0].editNowPrice = value
                          this.props.setData({
                            attribute: good.attribute,
                          })
                        }}
                        disabled={!this.props.hydPriceEaditable}
                        type="number"
                        value={good.attribute[0].editNowPrice || ""}
                        style={{
                          width: 90,
                          border: 0,
                          borderBottom: "1px solid #999",
                          textAlign: "center",
                          color: "var(--theme-main)",
                          fontSize: 16,
                        }}
                      />
                      <span>元</span>
                    </div>
                  )}
                <div style={{ width: "100%", flexWrap: "wrap", fontSize: 12 }}>
                  <span>划线价</span>
                  <input
                    onChange={e => {
                      const value = e.target.value
                      good.attribute[0].editOriginPrice = value
                      this.props.setData({
                        attribute: good.attribute,
                      })
                    }}
                    onBlur={() => {
                      if (
                        Number(good.attribute[0].editOriginPrice) ===
                        Number(good.attribute[0].original_price)
                      )
                        return
                      if (
                        !window.confirm(
                          "你修改的信息，将会同步至所有调取该商品的团购活动，是否坚持继续修改？"
                        )
                      ) {
                        good.attribute[0].editOriginPrice = (
                          Number(good.attribute[0].original_price) / 100
                        ).toFixed(2)
                        this.props.setData({
                          attribute: good.attribute,
                        })
                        return
                      }
                      post("/goods/updatetuangoodsinfo", {
                        type: 3,
                        goods_attr_id: good.attribute[0].id,
                        value:
                          (
                            Number(good.attribute[0].editOriginPrice) * 100
                          ).toFixed(0) || 0,
                      })
                        .then(() => {
                          good.attribute[0].editOriginPrice = Number(
                            good.attribute[0].editOriginPrice
                          ).toFixed(2)
                          good.attribute[0].original_price = (
                            Number(good.attribute[0].editOriginPrice) * 100
                          ).toFixed(0)
                          this.props.setData({
                            attribute: good.attribute,
                          })
                        })
                        .catch(e => {
                          good.attribute[0].editOriginPrice = (
                            Number(good.attribute[0].original_price) / 100
                          ).toFixed(2)
                          this.props.setData({
                            attribute: good.attribute,
                          })
                          message.error(e.message)
                        })
                    }}
                    type="number"
                    value={good.attribute[0].editOriginPrice || ""}
                    style={{
                      width: 90,
                      border: 0,
                      borderBottom: "1px solid #999",
                      textAlign: "center",
                    }}
                  />
                  <span>元</span>
                </div>
              </>
            )}

            {good.attribute.length > 1 && (
              <>
                <div
                  className="single-text-hidden"
                  style={{
                    width: "100%",
                    flexWrap: "wrap",
                    fontSize: 12,
                    marginBottom: 12,
                  }}
                >
                  共{good.attribute.length}个规格
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: 12,
                    marginBottom: 12,
                    color: "#999",
                  }}
                >
                  商城库存：{good.attribute[0].mall_quantity}
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: 12,
                    marginBottom: 12,
                    color: "#999",
                  }}
                >
                  商城价格：￥
                  <span style={{ fontSize: 14, color: "#333" }}>
                    {(Number(good.attribute[0].mall_price) / 100).toFixed(2)}
                  </span>
                </div>
                <div style={{ width: "100%", fontSize: 12 }}>
                  <BTN
                    onClick={() => {
                      HemaGoodsNumSetting({
                        good,
                        distribution: !!this.props.distribution,
                      }).then(res => {
                        if (!res) return
                        good.attribute = good.attribute.map(attr => {
                          const findoutAttr = res.find(i => i.id === attr.id)
                          return {
                            ...attr,
                            editNowPrice: findoutAttr.editNowPrice,
                            editInputNumber: findoutAttr.editInputNumber,
                          }
                        })
                      })
                    }}
                    solid
                    style={{
                      color: "var(--theme-main)",
                      background: "#FFF1F1",
                      border: 0,
                    }}
                  >
                    设置活动库存和价格
                  </BTN>
                </div>
              </>
            )}
          </>
        )}

        <div style={{}}>
          {this.state.showMenu ? (
            <div
              className="edittuan__goods__item__menu shadow-menu"
              style={{
                right: 14,
                ...(this.props.isHema || this.props.isHYD
                  ? {
                      top: 28 + 14 + 1,
                    }
                  : {
                      top: GOOD_ITEM_HEIGHT - (14 + 1),
                    }),
              }}
              onMouseLeave={() => {
                this.setState({ showMenu: false })
              }}
            >
              <div
                className="edittuan__goods__item__menu__item"
                onClick={() => {
                  this.setState({
                    showSetting: true,
                    stopDrag: true,
                    showMenu: false,
                  })
                }}
              >
                更多设置
              </div>
              <div
                className="edittuan__goods__item__menu__item"
                onClick={this.props.toTop}
              >
                商品置顶
              </div>
              <div
                className="edittuan__goods__item__menu__item"
                onClick={this.props.isMust}
              >
                {Number(good.is_required) === 1 ? "取消必选" : "设为必选"}
              </div>
              {this.props.candelete ? (
                <div
                  className="edittuan__goods__item__menu__item"
                  onClick={this.props.delete}
                >
                  删除
                </div>
              ) : null}
            </div>
          ) : (
            ""
          )}

          {this.state.showSetting ? (
            <Modal
              title={this.props.good.name}
              cancel
              close={() => {
                this.setState({
                  showSetting: false,
                  stopDrag: false,
                  start_count: this.props.good.start_count || "",
                  purchasing: this.props.good.purchasing || "",
                })
              }}
              confirm={() => {
                if (
                  Number(this.state.limit_count) > 0 &&
                  this.state.start_count > this.state.limit_count
                ) {
                  message.error("起购量不得大于限购量")
                  return
                }
                good.attribute[0].start_with = this.state.start_count
                this.props.setData({
                  start_count: this.state.start_count,
                  limit_count: this.state.limit_count,
                  attribute: good.attribute,
                })
                this.setState({ showSetting: false, stopDrag: false })
              }}
            >
              <div style={{ width: 325 }}>
                {!this.props.isHYD && (
                  <label
                    className="cur-pointer"
                    style={{ display: "block", marginBottom: 18 }}
                  >
                    <div style={{ marginBottom: 6 }}>设置起购量</div>
                    <input
                      style={{ paddingLeft: 11, width: "100%" }}
                      placeholder="不填则为无限制"
                      value={this.state.start_count}
                      onChange={e => {
                        const start_count = Number(e.target.value)
                        if (isNaN(start_count)) return
                        this.setState({ start_count: start_count || "" })
                      }}
                    />
                  </label>
                )}
                <label
                  className="cur-pointer"
                  style={{ display: "block", marginBottom: 18 }}
                >
                  <div style={{ marginBottom: 6 }}>设置限购量</div>
                  <input
                    style={{ paddingLeft: 11, width: "100%" }}
                    placeholder="不填则为无限制"
                    value={this.state.limit_count}
                    onChange={e => {
                      const limit_count = Number(e.target.value)
                      if (isNaN(limit_count)) return
                      this.setState({ limit_count: limit_count || "" })
                    }}
                  />
                </label>
              </div>
            </Modal>
          ) : null}
        </div>

        {this.props.isHema || this.props.isHYD ? (
          <BTN
            onClick={() => {
              this.setState({ showMenu: !this.state.showMenu })
            }}
            solid
            color="#000000"
            style={{
              fontSize: 12,
              outline: 0,
              position: "absolute",
              top: 14,
              right: 14,
              backgroundColor: "#ccc",
              border: 0,
              opactity: 1,
            }}
          >
            操 作
            <i
              className="iconfont icon-down"
              style={{ fontSize: 12, lineHeight: 1, marginLeft: 4 }}
            />
          </BTN>
        ) : (
          <BTN
            onClick={() => {
              this.setState({ showMenu: !this.state.showMenu })
            }}
            solid
            color="#000000"
            style={{
              fontSize: 12,
              outline: 0,
              position: "absolute",
              bottom: 14,
              right: 14,
              backgroundColor: "#ccc",
              border: 0,
            }}
          >
            操 作
            <i
              className="iconfont icon-down"
              style={{ fontSize: 12, lineHeight: 1, marginLeft: 4 }}
            />
          </BTN>
        )}
      </div>
    )
  }
}

class SubDistributionSetting extends Component {
  constructor(p) {
    super()
    this.state = {
      distri_show_batch: false, // 开关批量设置
      distri_show_batch_type: 1, // 批量设置的方式
      salemodel: p.salemodel,
      goodslist: [],
      batchPrice1: "", // 批量固定金额
      batchPrice2: "", // 批量百分比
      options: {
        isshow_allseller_rank: p.isshow_allseller_rank || false, // 查看好友排行
        isshow_allseller_order: p.isshow_allseller_order || false, // 查看好友订单
        can_usemyid_start: p.can_usemyid_start || false, // 用自己id开团
      },
    }
  }

  componentDidMount() {
    if (this.props.goodslist && !this.state.goodslist[0]) {
      this.setState({
        goodslist: this.props.goodslist,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.goodslist && !this.state.goodslist[0]) {
      this.setState({
        goodslist: this.props.goodslist,
      })
    }
  }

  render() {
    return (
      <Modal
        style={{ content: { maxHeight: "70vh", overflowY: "auto" } }}
        banMaskClose={true}
        confirmText="保存设置"
        close={() => {
          this.props.close && this.props.close()
        }}
        cancel={true}
        title="帮卖设置"
        confirm={() => {
          const distri_setting = []
          for (let i = 0; i < this.state.goodslist.length; i++) {
            const good = this.state.goodslist[i]
            for (let j = 0; j < good.attribute.length; j++) {
              const attr = good.attribute[j]
              const data = {
                goodsid: good.goodsid,
                goodsattrid: attr.id,
              }
              if (!attr.value) {
                message.error(good.name + "请完成帮卖设置")
                return
              }

              if (this.state.salemodel === 1) {
                data.baseprice = Number(attr.distribution_price.baseprice)
                data.nowprice = Number(attr.distribution_price.nowprice)
                data.sellerprice = data.nowprice - attr.value * 100
                // data.sellerprice = (data.baseprice + attr.value * 100)
              } else if (this.state.salemodel === 2) {
                data.nowprice = Number(attr.distribution_price.nowprice)
                data.baseprice = Number(attr.distribution_price.baseprice)
                // data.nowprice = attr.price
                // data.nowprice = attr.nowprice || attr.price
                data.sellerprice = attr.value * 100
              }
              if (data.sellerprice < data.baseprice) {
                message.error(
                  `${good.name}(${attr.unit})的${
                    this.state.salemodel === 1 ? "佣金" : "供货价"
                  }有误，请检查`
                )
                return
              }
              data.input_number = Number(attr.value) * 100
              distri_setting.push(data)
            }
          }
          this.props.confirm({
            distri_setting,
            salemodel: this.state.salemodel,
            options: this.state.options,
          })
          this.props.close && this.props.close()
        }}
      >
        <div className="fc">
          <div
            onClick={e => {
              if (!canchangesalemodel) return
              this.setState({ salemodel: 1 })
            }}
            className={`edittuan__distri-setting__salemodel ${
              this.state.salemodel === 1
                ? "edittuan__distri-setting__salemodel_active"
                : ""
            }`}
          >
            对外价格统一
          </div>
          <div
            onClick={e => {
              if (!canchangesalemodel) return
              this.setState({ salemodel: 2 })
            }}
            className={`edittuan__distri-setting__salemodel ${
              this.state.salemodel === 2 &&
              "edittuan__distri-setting__salemodel_active"
            }`}
          >
            可自由定价
          </div>
        </div>
        <div className="edittuan__distri-setting__intro">
          {this.state.salemodel === 1 &&
            "选择此模式后，好友转发的活动商品将按照统一的价格对外销售，你可以在下方设置佣金，奖励帮你卖货的好友。"}
          {this.state.salemodel === 2 &&
            "选择此模式后，帮你卖货的好友将可自由设定商品对外销售的价格，供货价即为他们从你手中拿货的价格。"}
        </div>

        <div className="fbc" style={{ marginTop: 8 }}>
          <div className="edittuan__switchitem__text fbc">
            允许所有好友共享订单
          </div>
          <Switch
            disabled={this.props.disabled}
            checked={this.state.options.isshow_allseller_order}
            onClick={() => {
              this.setState({
                options: {
                  ...this.state.options,
                  isshow_allseller_order:
                    !this.state.options.isshow_allseller_order,
                },
              })
            }}
          />
        </div>
        <div className="fbc" style={{ marginTop: 8 }}>
          <div className="edittuan__switchitem__text fbc">
            允许好友查看我的好友排行榜
          </div>
          <Switch
            disabled={this.props.disabled}
            checked={this.state.options.isshow_allseller_rank}
            onClick={() => {
              this.setState({
                options: {
                  ...this.state.options,
                  isshow_allseller_rank:
                    !this.state.options.isshow_allseller_rank,
                },
              })
            }}
          />
        </div>
        <div
          className="edittuan__distri-setting__tobatch"
          onClick={() => {
            this.setState({ distri_show_batch: true })
          }}
        >
          批量设置供货价
        </div>
        {this.state.distri_show_batch ? (
          <div className="edittuan__distri-setting__batch">
            <div
              className="fc"
              style={{ marginBottom: 6, cursor: "pointer" }}
              onClick={() => {
                this.setState({ distri_show_batch_type: 1 })
              }}
            >
              <div
                className={`edittuan__distri-setting__batch__selected-icon ${
                  this.state.distri_show_batch_type === 1
                    ? "edittuan__distri-setting__batch__selected-icon_active"
                    : ""
                }`}
              ></div>
              <div
                style={{
                  fontSize: 14,
                  color:
                    this.state.distri_show_batch_type === 1 ? "#333" : "#999",
                  minWidth: "10em",
                }}
              >
                批量设置金额
              </div>
              <div>
                <input
                  type="digit"
                  disabled={this.state.distri_show_batch_type === 2}
                  value={this.state.batchPrice1}
                  style={{
                    fontSize: 12,
                    border: 0,
                    padding: "1px .7em",
                    background:
                      this.state.distri_show_batch_type === 2
                        ? "transparent"
                        : "",
                  }}
                  placeholder="请输入金额"
                  onChange={e => {
                    if (isNaN(Number(e.target.value))) return
                    this.setState({ batchPrice1: e.target.value })
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: ".4em",
                  color:
                    this.state.distri_show_batch_type === 1 ? "#333" : "#999",
                }}
              >
                元
              </div>
            </div>
            <div
              className="fc"
              style={{ marginBottom: 6, cursor: "pointer" }}
              onClick={() => {
                this.setState({ distri_show_batch_type: 2 })
              }}
            >
              <div
                className={`edittuan__distri-setting__batch__selected-icon ${
                  this.state.distri_show_batch_type === 2
                    ? "edittuan__distri-setting__batch__selected-icon_active"
                    : ""
                }`}
              ></div>
              <div
                style={{
                  fontSize: 14,
                  color:
                    this.state.distri_show_batch_type === 2 ? "#333" : "#999",
                  minWidth: "10em",
                }}
              >
                批量按百分比设置
              </div>
              <div>
                <input
                  type="digit"
                  disabled={this.state.distri_show_batch_type === 1}
                  value={this.state.batchPrice2}
                  style={{
                    fontSize: 12,
                    border: 0,
                    padding: "1px .7em",
                    background:
                      this.state.distri_show_batch_type === 1
                        ? "transparent"
                        : "",
                  }}
                  placeholder="请输入百分比"
                  onChange={e => {
                    if (isNaN(Number(e.target.value))) return
                    this.setState({ batchPrice2: e.target.value })
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: ".4em",
                  color:
                    this.state.distri_show_batch_type === 2 ? "#333" : "#999",
                }}
              >
                %
              </div>
            </div>
            <div className="edittuan__distri-setting__batch__buttons">
              <button
                className="edittuan__distri-setting__batch__button"
                onClick={() => {
                  this.setState({ distri_show_batch: false })
                }}
              >
                取消
              </button>
              <button
                className="edittuan__distri-setting__batch__button"
                style={{ color: "var(--theme-main)" }}
                onClick={() => {
                  if (this.state.distri_show_batch_type === 1) {
                    for (let i = 0; i < this.state.goodslist.length; i++) {
                      const good = this.state.goodslist[i]
                      for (let j = 0; j < good.attribute.length; j++) {
                        const attr = good.attribute[j]
                        if (
                          this.props.disabled &&
                          !attr.notcanupdatebutcandothis
                        )
                          break
                        if (this.state.salemodel === 1) {
                          attr.value = Number(this.state.batchPrice1).toFixed(2)
                        } else if (this.state.salemodel === 2) {
                          attr.value = Number(this.state.batchPrice1).toFixed(2)
                        }
                      }
                    }
                  } else if (this.state.distri_show_batch_type === 2) {
                    for (let i = 0; i < this.state.goodslist.length; i++) {
                      const good = this.state.goodslist[i]
                      for (let j = 0; j < good.attribute.length; j++) {
                        const attr = good.attribute[j]
                        if (
                          this.props.disabled &&
                          !attr.notcanupdatebutcandothis
                        )
                          break
                        if (this.state.salemodel === 1) {
                          attr.value = (
                            (this.state.batchPrice2 / 100) *
                            (attr.price / 100)
                          ).toFixed(2)
                        } else if (this.state.salemodel === 2) {
                          attr.value = (
                            (this.state.batchPrice2 / 100) *
                            (attr.price / 100)
                          ).toFixed(2)
                        }
                      }
                    }
                  }
                  this.setState({ goodslist: this.state.goodslist })
                }}
              >
                确定
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* 规格列表 */}
        {this.state.goodslist.map((good, i) => {
          return good.attribute.map((attr, j) => {
            if (this.state.salemodel === 1) {
              const value =
                attr.value || attr.value === ""
                  ? attr.value
                  : attr.distribution_price &&
                    attr.distribution_price.sellerprice !== null
                  ? (attr.price - attr.distribution_price.sellerprice) / 100
                  : ""
              attr.value = value
            }

            if (this.state.salemodel === 2) {
              const value =
                attr.value || attr.value === ""
                  ? attr.value
                  : attr.distribution_price &&
                    attr.distribution_price.sellerprice !== null
                  ? attr.distribution_price.sellerprice / 100
                  : ""
              attr.value = value
              attr.nowprice = attr.nowprice
                ? attr.nowprice
                : attr.distribution_price
                ? attr.distribution_price.nowprice / 100
                : ""
            }
            if (attr.notcanupdatebutcandothis === undefined) {
              attr.notcanupdatebutcandothis = false
              if (attr.value === "") {
                attr.notcanupdatebutcandothis = true
              }
            }
            return (
              <div key={attr.id} className="edittuan__distri-setting__attr">
                <img
                  alt={good.name}
                  src={good.logo}
                  style={{
                    width: 70,
                    height: 70,
                    minWidth: 70,
                    marginRight: 12,
                  }}
                  className="img-center"
                />
                <div style={{ width: "80%" }}>
                  <div className="edittuan__distri-setting__attr__name">
                    {good.name}
                  </div>
                  <div className="edittuan__distri-setting__attr__unit">
                    规格：{attr.unit}
                  </div>
                  {this.state.salemodel === 1 ? (
                    <div
                      className="fbc"
                      style={{
                        fontSize: 12,
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                      }}
                    >
                      <div>
                        <div style={{ color: "#FD5755" }}>
                          统一零售价￥{(attr.price / 100).toFixed(2)}
                        </div>
                        <div>
                          你的佣金：
                          {(
                            (attr.distribution_price.nowprice -
                              attr.distribution_price.baseprice) /
                            100
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div>
                        <span>佣金</span>
                        <input
                          disabled={
                            this.props.disabled &&
                            !attr.notcanupdatebutcandothis
                          }
                          type="digit"
                          placeholder="卖货佣金需小于统一零售价"
                          style={{ border: 0, padding: 4, width: "13em" }}
                          value={attr.value}
                          onChange={e => {
                            if (isNaN(Number(e.target.value))) return
                            const data = this.state.goodslist
                            data[i].attribute[j].value = e.target.value
                            this.setState({
                              goodslist: data,
                            })
                          }}
                        />
                        <span>元</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.salemodel === 2 ? (
                    <div className="fbc" style={{ fontSize: 12 }}>
                      <div>
                        <div style={{ color: "#FD5755" }}>
                          参考价￥
                          {(attr.distribution_price.nowprice / 100).toFixed(2)}
                        </div>
                        <div>
                          你的进货价：
                          {(attr.distribution_price.baseprice / 100).toFixed(2)}
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>供货价</span>
                          <input
                            disabled={
                              this.props.disabled &&
                              !attr.notcanupdatebutcandothis
                            }
                            type="digit"
                            placeholder="请输入供货价"
                            style={{ border: 0, padding: 4, width: "12em" }}
                            value={
                              attr.value !== null || attr.value !== undefined
                                ? attr.value
                                : attr.distribution_price
                                ? attr.distribution_price.sellerprice / 100
                                : ""
                            }
                            onChange={e => {
                              if (isNaN(Number(e.target.value))) return
                              const data = this.state.goodslist
                              data[i].attribute[j].value = e.target.value
                              this.setState({
                                goodslist: data,
                              })
                            }}
                          />
                          <span>元</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )
          })
        })}
      </Modal>
    )
  }
}
