import React, { useState, useRef } from 'react';
import Modal from '../../total/Modal';
import { get } from '../../../netApi/fetch';

interface Props {
    type: 1 | 2;
    id: string | number;
    tuanid: string | number;
    onClose: () => void;
}

interface item {
    createtime: string;
    mark_type: string;
    operator_name: string;
    operator_uid: string;
    tuanid: string;
    id: string;
    goods_list: {
        name: string;
        num: string;
    }[];
}

export default function (props: Props) {
    const [hasMore, setHasMore] = useState(true)
    // const [offset, setOffset] = useState(0)
    const [list, setList] = useState([] as item[])
    const offset = useRef(0)
    const loading = useRef(false)
    const limit = 10

    if(hasMore && list.length === 0){
        getList()
    }

    if (!hasMore && list.length === 0) return <Modal title='标记记录' onClose={() => { props.onClose() }}><div style={{ minWidth: 190 }}>
        暂无记录
    </div></Modal>
    return <Modal title='标记记录' onClose={() => { props.onClose() }}>
        <div style={{ maxHeight: 600, overflow: 'auto', fontSize: 14, color: '#454545' }} onScroll={e => {
            if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 50) {
                getList()
            }
        }} >
            {list.map((i) => {
                
                return <div key={i.id} style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 6 }}>{i.createtime} {i.operator_name} 标记发货</div>
                    <div style={{ maxWidth: 400, }}>
                        {i.goods_list.map((good, idx) => {
                            return <div key={idx} style={{ marginBottom: 6 }}>
                                {good.name}&nbsp;&nbsp;&nbsp;&nbsp;{good.num}
                            </div>
                        })}
                    </div>
                </div>
            })}
        </div>
    </Modal>

    function getList() {
        if (loading.current) return
        if (!hasMore) return
        loading.current = true
        const data = {
            offset: offset.current, limit,
            mark_type: props.type,
            seller_sid: props.id,
            addressid: props.id,
            tuanid: props.tuanid,
        }
        if (props.type === 1) delete data.seller_sid
        if (props.type === 2) delete data.addressid
        get('/order/getmarksendgoodslogbypickupmode', data).then((res: any) => {
            offset.current = offset.current + Number(res.list.length)
            setHasMore(offset.current < Number(res.count))
            setList([...(list || []), ...res.list])
            loading.current = false

        })
    }
}