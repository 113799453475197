import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { baseURL } from '../../../netApi/index'
// import Select from '../total/Select';
import Select from '../../total/Select_';
import { message } from 'antd';
import Modal from '../../total/Modal';
// import { get } from '../../../netApi/fetch';


interface Props {
    printList: (list: any[]) => void,
    close: () => void,
    tuanlist: any[],
    user: any,
    height: number,
    width: number,
    type?: string,
    preselect?: string
}

class Export extends Component<Props> {
    static propTypes = {
        // tuanList: PropTypes.array.isRequired
    }
    readonly state = {
        downloadLink: '',
        option: 0,
        type: 1,
        tuanid: '',
        exportType: 0, // 0全部 1快递 2自提

        actionType: 1, // 1 下载xls 2 打印

        sort: 'asc',

        Loading: false,

    }

    componentDidMount() {
        const selectedID = this.props.preselect
        console.log(selectedID)
        if (selectedID) {
            this.setState({ tuanid: atob(decodeURIComponent(selectedID)) })
        }
    }
    select(type: any, e: any) {
        this.setState({ [type]: e.target.value })
    }
    export() {
        if(!this.state.tuanid){
            message.warning('请选择活动')
            return
        }
        const data:any = {}
        data.tuanid = encodeURIComponent(window.btoa(this.state.tuanid))
        data.type = this.props.type === 'hd' ? 'hd' : 'tuan'
        window.open(`/orders/print/${data.tuanid}?op=${this.state.option}&type=${data.type}`)
    }
    close(e: any) {
        e.preventDefault()
        e.stopPropagation()
        this.props.close()
    }
    render() {
        return (
            <Modal showConfirm={false} title={'打印订单'} loading={this.state.Loading} close={this.props.close} style={{
                outer: {
                    width: 400
                }
            }} >
                <div style={{ padding: 20, paddingTop: 0 }} className='fcc'>
                    <div style={{ minWidth: '7em' }}>选择活动</div>
                    <div style={{ flex: 1 }}><Select uni='event' list={[
                        ...this.props.tuanlist,
                    ]} showKey='title'
                        placeholder='请选择活动'
                        onChange={e => {
                            this.setState({
                                tuanid: e.id
                            })
                        }} value={this.props.tuanlist.findIndex(i => i.id === this.state.tuanid)} maxHeight={200}
                    /></div>
                </div>

                <div style={{ padding: 20, paddingTop: 0 }} className='fcc'>
                    <div style={{ minWidth: '7em' }}>选择订单状态</div>
                    <div style={{ flex: 1 }}><Select uni='dingdan' list={[{ value: 0, title: '全部' }, { value: 2, title: '未完成订单' },]} showKey='title'
                        onChange={e => {
                            this.setState({
                                option: e.value
                            })
                        }} value={this.state.option === 0 ? 0 : 1}
                    /></div>
                </div>
                <div style={{ padding: 20, paddingBottom: 0, paddingTop: 0 }} className='fcc'>
                    <button className='button_solid_red' onClick={this.export.bind(this)}>打印订单</button>
                </div>
            </Modal>

        )
    }
}

const mapStateToProps = (state: any, ) => {
    return {
        user: state.login_user_data,
        order: state.order,
    }
}
export default (connect(mapStateToProps)((Export)))