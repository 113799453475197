import React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import styled from "styled-components"

const TUAN_DEFAULT_IMAGE =
  "https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/naC7D4C7DP.png"
const Panel = styled.div`
  padding: 16px 20px 0;
  display: flex;
  color: #999;
  font-size: 12px;
  transition: background-color 0.3s;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: var(--theme-main) !important;
    color: #fff;
    * {
      color: #fff !important;
      border-color: #fff !important;
    }
  }
  & > img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    position: relative;
  }
  & > div {
    padding-bottom: 16px;
    border-bottom: 0.5px solid #eee;
    flex: 1;
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .strong {
    color: #333;
    font-weight: 600;
    font-size: 14px;
    word-break: break-all;
  }
  .branchName {
    color: #fff;
    background-color: var(--theme-main);
    padding: 2px 4px;
    margin-left: 6px;
    border-radius: 3px;
  }
`

const Tag = styled.div`
  font-size: 12px;
  color: ${props => props.color};
  border: 1px solid;
  border-color: ${props => props.color};
  border-radius: 4px;
  padding: 0 4px;
  margin-right: 10px;
`

const Top = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  background: #ff9a27;
  width: 3em;
  height: 1.6em;
  text-align: center;
  z-index: 2;
`

const Num = styled.span`
  *:hover > & {
    background: #fff !important;
    color: var(--theme-main) !important;
  }
  background: var(--theme-main);
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  left: 40px;
  top: 8px;
  position: absolute;
  padding: 0 5px;
  min-width: 22px;
  text-align: center;
`

interface props extends RouteComponentProps {
  data: contentItem
}

function Item(props: props) {
  const matchid = props.location.pathname.split("/")
  const active = Number(matchid[3]) === Number(props.data.id)
  if (props.data.type === "tuan" || props.data.type === "pt") {
    const data = props.data as tuanItem
    const now = Number(new Date())
    return (
      <Panel
        style={{
          background: active ? "#e9e9e9" : "",
        }}
        onClick={() => {
          props.history.push("/index/t/" + data.id + "/detail")
        }}
      >
        {Number(data.istop) === 1 && <Top>置顶</Top>}
        <img
          src={(data.image.src || TUAN_DEFAULT_IMAGE) + "/sm"}
          className="img-center"
          alt=""
        />
        {Number(data.notsend_orders_total) > 0 ? (
          <Num>{data.notsend_orders_total}</Num>
        ) : null}
        <div>
          <div>
            <span className="strong">{data.title}</span>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {data.start_end_time && data.start_end_time.end_time ? (
              <>
                {now < data.start_end_time.start_time * 1000 ? (
                  <Tag color="#f7b441">未开始</Tag>
                ) : null}
                {now > data.start_end_time.end_time * 1000 ? (
                  <Tag color="#999">已结束</Tag>
                ) : null}
                {now < data.start_end_time.end_time * 1000 &&
                now > data.start_end_time.start_time * 1000 ? (
                  <Tag color="#fd5356">进行中</Tag>
                ) : null}
              </>
            ) : (
              <>
                {Number(data.status) === 1 ? (
                  <Tag color="#fd5356">正在开团</Tag>
                ) : null}
                {Number(data.status) === 2 ? (
                  <Tag color="#999">已结束</Tag>
                ) : null}
              </>
            )}
            {Number(data.distributionid) !== 0 ? (
              <>
                {Number(data.pid) === 0 ? (
                  <Tag color="#fd5356">已开启帮卖</Tag>
                ) : null}
                {Number(data.pid) !== 0 ? (
                  <Tag color="#f7b441">帮好友卖</Tag>
                ) : null}
              </>
            ) : null}
          </div>
          <div className="flex-box">
            <div>
              {data.visitors}人访问&nbsp;&nbsp;
              {Number(data.isprivate) ? (
                <i className="iconfont icon-yincang" />
              ) : null}
            </div>
            <div>&nbsp;&nbsp;{data.showtime}</div>
          </div>
        </div>
      </Panel>
    )
  }
  return null
}

export default withRouter(Item)

export type contentItem = tuanItem | messageItem

interface tuanItem {
  id: string
  title: string
  pid: string
  istop: string
  type: "tuan" | "pt"
  takeway: string
  distributionid: string
  orders: string
  time_type: string
  createtime: string
  isprivate: string
  visitors: string
  seller_orders: string
  seller_tuan_total: string
  status: string
  showtime: string
  parent_update_count: string
  lasttuanid: string
  image: {
    src: string
  }
  divs: string
  start_end_time?: {
    end_time: number
    start_time: number
  }
  notsend_orders_total: string
}

interface messageItem {
  type: "hd"
  id: string
}
