import React, { Component } from 'react';
import { get } from '../../netApi/fetch';
import { RouteComponentProps, match } from 'react-router-dom'
import styled from 'styled-components';
import Loading from '../Method/Loading';
import parse from '../../methods/paramParse';
import { Table } from '../../StyleComponents/PrintTable';
import moment from 'moment';
import { message } from 'antd';

interface props extends RouteComponentProps {
    match: m,
}

interface m extends match {
    params: {
        tuanid: string
    }
}

interface state {
    pickOrders: {
        addressData: {
            id: number,
            data: any,
            statistics: any[],
            sellername: string,
        },
        orderList: any[],
        order_count: string,
    }[],
    hideCustomList?: boolean,
    mall?: boolean,
    mallOrders?: {
        consumer_order: {
            address_contact: string
            address_detail: string
            address_mobile: string
            address_name: string
            count_num: string
            count_price: string
            order_count: number
            list: {
                buy_no: string
                buyer_name: string
                contact: string
                goods_attr_no: string
                goods_name: string
                mobile: string
                num: string
                price: string
                refund_apply_num: string
            }[]
        }
        delivery_order: {
            address_contact: string
            address_detail: string
            address_mobile: string
            address_name: string
            count_num: string
            count_price: string
            list: {
                buy_no: string
                goods_attr_no: string
                goods_name: string
                num: string
                refund_apply_num: string
                total_price: string
            }[]
            order_count: string
        }
    }[]
}

const Print = styled.div`
    position: sticky;
    right: 0;
    top: 170px;
    text-align: right;
    @media print {
        display: none;
    }
`
const Wrap = styled.div`
    @media screen {
        position: relative;
        margin: 0 auto;
        max-width: 1200px;
    }
    @media print{
        max-width: unset;
        margin: 0;
        width: 100%;
    }
`
class PickList extends Component<props>{

    readonly state: state = {
        pickOrders: []
    }

    componentDidMount() {
        // type 1 门店拣货单&&消费者订单 type2 门店拣货单
        // op 0全部订单 1已完成 2未完成
        const search = parse(this.props.location.search)
        if (Number(search.type) === 2) {
            this.setState({
                hideCustomList: true
            })
        }
        console.log(search);
        Loading.show()
        if (Number(search.mall) === 1) {
            this.malldata(search)
        } else {
            this.tuandata(search)
        }
    }

    tuandata(search) {
        get('/pcorder/orderList', {
            sort: 'asc',
            option: search.op || 0,
            tuanid: atob(decodeURIComponent(this.props.match.params.tuanid)),
            output_sheet_list: '2,5,4',
            types: 1,
            output_type: 'array',
            seller_sid: search.ss
        }).then((res: any) => {
            const pickOrders = Object.keys(res.pickup_order).map((i: string) => {
                const as = res.pickup_order[i].address_statistics
                if (Array.isArray(as)) {
                    return false
                }
                return {
                    addressData: {
                        id: i,
                        data: res.address_info[i],
                        statistics: Object.keys(as[Object.keys(as)[0]]).map(g => {
                            return {
                                id: g,
                                ...as[Object.keys(as)[0]][g],
                            }
                        }),
                        sellername: res.pickup_order[i].list[0].sellername
                    },
                    orderList: res.pickup_order[i].list,
                    order_count: res.pickup_order[i].order_count
                }
            }).filter(i => i)
            this.setState({
                pickOrders
            })
            Loading.hide()
        }).catch(e => {
            if (e.message) message.error(e.message)
            Loading.hide()
        })
    }

    malldata(search) {
        console.log(search);
        this.setState({ mall: true })
        const data: any = {
            mall_id: this.props.match.params.tuanid.split(',')[0],
            order_status: search.op,
        }
        if (search.start) data.start = search.start
        if (search.end) data.end = search.end
        get('/mall/getmallpickuppointdeliveryorder', data).then(res => {
            
            this.setState({
                mallOrders: res
            })
            Loading.hide()
        }).catch(e => {
            if (e.message) message.error(e.message)
            Loading.hide()
        })
    }

    render() {
        const date = moment().format('YYYY-MM-DD HH:mm:ss')
        if (this.state.mall) {
            if (!this.state.mallOrders) return null
            return <Wrap>
                <Print><button className='button_solid_red' onClick={() => { window.print() }}>打印</button></Print>
                <div>
                    {this.state.mallOrders.map((i, index) => {
                        return <React.Fragment key={index}>

                            {/* 门店拣货单 */}
                            <Table style={{
                                pageBreakAfter: this.state.hideCustomList ? "always" : undefined
                            }}>
                                <thead>
                                    <tr>
                                        <td colSpan={6}>
                                            <div style={{ fontSize: 12 }}>打印时间：{date}</div>
                                            <div>【{i.delivery_order.address_name}】{this.state.hideCustomList ? '拣' : '对'}货单</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6}>
                                            <div>共{i.delivery_order.order_count}个订单</div>
                                            <div className='flex-box'>
                                                <div style={{ marginRight: 30 }}>电话：{i.delivery_order.address_mobile}</div>
                                                <div style={{ marginRight: 30 }}>地址：{i.delivery_order.address_detail}</div>
                                                <div style={{ marginRight: 30 }}>团长：{i.delivery_order.address_contact}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>序号</td>
                                        <td style={{ border: '1px solid #000', width: '7em' }}>商品编码</td>
                                        <td style={{ border: '1px solid #000', }}>商品名称</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>退款中</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>数量</td>
                                        {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                                        <td style={{ border: '1px solid #000', width: '4em' }}>总价</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {i.delivery_order.list.map((a, idx) => {
                                        return <tr key={idx}>
                                            <td style={{ border: '1px solid #000', width: '4em' }}>{idx + +1}</td>
                                            <td style={{ border: '1px solid #000', width: '7em' }}>{a.goods_attr_no}</td>
                                            <td style={{ border: '1px solid #000', }}>{a.goods_name}</td>
                                            <td style={{ border: '1px solid #000', width: '4em' }}>{a.refund_apply_num}</td>
                                            <td style={{ border: '1px solid #000', width: '4em' }}>{a.num}</td>
                                            {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                                            <td style={{ border: '1px solid #000', width: '4em' }}>{(Number(a.total_price) / 100).toFixed(2)}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td colSpan={3}>合计：</td>
                                        {/* <td></td> */}
                                        <td>{i.delivery_order.list.reduce((prev, item) => prev + Number(item.refund_apply_num), 0)}</td>
                                        <td>{i.delivery_order.count_num}</td>
                                        <td>{(Number(i.delivery_order.count_price) / 100).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            {/* 消费者订单 */}
                            {!this.state.hideCustomList ? <Table>
                                <thead>
                                    <tr>
                                        <td colSpan={6}>
                                            <div style={{ fontSize: 12 }}>打印时间：{date}</div>
                                            <div>【{i.consumer_order.address_name}】消费者订单</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6}>
                                            <div>共{i.consumer_order.order_count}个订单</div>
                                            <div className='flex-box'>
                                                <div style={{ marginRight: 30 }}>电话：{i.consumer_order.address_mobile}</div>
                                                <div style={{ marginRight: 30 }}>地址：{i.consumer_order.address_detail}</div>
                                                <div style={{ marginRight: 30 }}>团长：{i.consumer_order.address_contact}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>接龙号</td>
                                        <td style={{ border: '1px solid #000', width: '7em' }}>昵称</td>
                                        <td style={{ border: '1px solid #000', width: '7em' }}>联系人</td>
                                        <td style={{ border: '1px solid #000', width: '7em' }}>手机号</td>
                                        <td style={{ border: '1px solid #000', }}>商品名称</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>退款中</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>数量</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>价格</td>
                                        <td style={{ border: '1px solid #000', width: '3em' }}>提货确认</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {i.consumer_order.list.map((o, idx) => {
                                        return <tr key={idx}>
                                            <td>{o.buy_no}</td>
                                            <td>{o.buyer_name}</td>
                                            <td>{o.contact}</td>
                                            <td>{o.mobile}</td>
                                            <td>{o.goods_name}</td>
                                            <td>{o.refund_apply_num}</td>
                                            <td>{o.num}</td>
                                            <td>{(Number(o.price) / 100).toFixed(2)}</td>
                                            <td></td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td colSpan={5}>合计：</td>
                                        <td>{i.consumer_order.list.reduce((prev, item) => prev + Number(item.refund_apply_num), 0)}</td>
                                        <td>{i.consumer_order.count_num}</td>
                                        <td>{(Number(i.consumer_order.count_price) / 100).toFixed(2)}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table> : null}
                        </React.Fragment>
                    })}
                </div>
            </Wrap>
        }
        return <Wrap>
            <Print><button className='button_solid_red' onClick={() => { window.print() }}>打印</button></Print>
            <div>
                {this.state.pickOrders.map((i, index) => {
                    return <React.Fragment key={index}>

                        {/* 门店拣货单 */}
                        <Table style={{
                            pageBreakAfter: this.state.hideCustomList ? "always" : undefined
                        }}>
                            <thead>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ fontSize: 12 }}>打印时间：{date}</div>
                                        <div>【{i.addressData.data.name}】{this.state.hideCustomList ? '拣' : '对'}货单</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div>共{i.order_count}个订单</div>
                                        <div className='flex-box'>
                                            <div style={{ marginRight: 30 }}>电话：{i.addressData.data.mobile}</div>
                                            <div style={{ marginRight: 30 }}>地址：{i.addressData.data.address + i.addressData.data.addressdetail}</div>
                                            <div style={{ marginRight: 30 }}>团长：{i.addressData.sellername}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>序号</td>
                                    <td style={{ border: '1px solid #000', width: '7em' }}>商品编码</td>
                                    <td style={{ border: '1px solid #000', }}>商品名称</td>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>退款中</td>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>数量</td>
                                    {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                                    <td style={{ border: '1px solid #000', width: '4em' }}>总价</td>
                                </tr>
                            </thead>
                            <tbody>
                                {i.addressData.statistics.map((a, idx) => {
                                    return <tr key={idx}>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>{idx + +1}</td>
                                        <td style={{ border: '1px solid #000', width: '7em' }}>{a.style_no}</td>
                                        <td style={{ border: '1px solid #000', }}>{a.name}</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>{a.refunding_num}</td>
                                        <td style={{ border: '1px solid #000', width: '4em' }}>{a.num}</td>
                                        {/* <td style={{ border: '1px solid #000', width: '4em' }}>单价</td> */}
                                        <td style={{ border: '1px solid #000', width: '4em' }}>{(a.price / 100).toFixed(2)}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan={3}>合计：</td>
                                    {/* <td></td> */}
                                    <td>{i.addressData.statistics.reduce((prev, curr) => prev + curr.refunding_num, 0)}</td>
                                    <td>{i.addressData.statistics.reduce((prev, curr) => prev + curr.num, 0)}</td>
                                    <td>{(i.addressData.statistics.reduce((prev, curr) => prev + (curr.price), 0) / 100).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>

                        {/* 消费者订单 */}
                        {!this.state.hideCustomList ? <Table>
                            <thead>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ fontSize: 12 }}>打印时间：{date}</div>
                                        <div>【{i.addressData.data.name}】消费者订单</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div>共{i.order_count}个订单</div>
                                        <div className='flex-box'>
                                            <div style={{ marginRight: 30 }}>联系人：{i.addressData.data.contact}</div>
                                            <div style={{ marginRight: 30 }}>电话：{i.addressData.data.mobile}</div>
                                            <div>地址：{i.addressData.data.address + i.addressData.data.addressdetail}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>接龙号</td>
                                    <td style={{ border: '1px solid #000', width: '7em' }}>昵称</td>
                                    <td style={{ border: '1px solid #000', width: '7em' }}>联系人</td>
                                    <td style={{ border: '1px solid #000', width: '7em' }}>手机号</td>
                                    <td style={{ border: '1px solid #000', }}>商品名称</td>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>退款中</td>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>数量</td>
                                    <td style={{ border: '1px solid #000', width: '4em' }}>价格</td>
                                    <td style={{ border: '1px solid #000', width: '3em' }}>提货确认</td>
                                </tr>
                            </thead>
                            <tbody>
                                {i.orderList.map((o, idx) => {
                                    return <tr key={idx}>
                                        <td>{o.buy_no}</td>
                                        <td>{o.wechatname}</td>
                                        <td>{o.联系人}</td>
                                        <td>{o.联系方式}</td>
                                        <td>{o.goods_name + ' ' + o.unit}</td>
                                        <td>{o.refunding_num}</td>
                                        <td>{o.num}</td>
                                        <td>{o.price}</td>
                                        <td></td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan={5}>合计：</td>
                                    <td>{i.orderList.reduce((prev, curr) => Number(curr.refunding_num) + prev, 0)}</td>
                                    <td>{i.orderList.reduce((prev, curr) => curr.num + prev, 0)}</td>
                                    <td>{Number(i.orderList.reduce((prev, curr) => Number(curr.price) * curr.num + prev, 0)).toFixed(2)}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table> : null}
                    </React.Fragment>
                })}
            </div>
        </Wrap>
    }
}

export default PickList