import { post } from '../../../netApi/fetch';
import { appname } from '../../../data/options';

interface data {
    refund_fee?: number,
    refund_mode: number,
    items?: {
        id: number,
        num: number,
    }[],
    orderid: number,
}

export function applyrefund(data: data) {
    return post('/order/applyrefund', { ...data, types: 1, }, { app: appname })
}

export function initiativeRefund(data: data) {
    return post('/order/confirmrefund', { ...data, types: 1, }, { app: appname })
}