import React, { useState } from 'react'
import Modal from '../../total/Modal'
import ReactDOM from 'react-dom'
import { get, post } from '../../../netApi/fetch'
import styled from 'styled-components'
import { Checkbox, message } from 'antd'
import Select from '../../total/Select_'
import Switch from '../../total/Switch'


interface props {
    onClose: () => void
    data: {
        mall_distribution_commission_list: {  // 好友分级
            group_name: string
            total_commission_percent: string
            mall_distribution_group_id: string
            commission_level_list: {
                commission_level: string
                commission_percent: string
            }[]
        }[]
        branch_group_list: { // 门店列表
            branch_group_id: string
            group_name: string
            branch_list: {
                mall_id: string
                name: string
                distribution_relation_id: string
                branch_group_id: string
                branch_name: string
            }[]
        }[]

        pickup_point_list: { // 自提点列表
            group_id: string
            group_name: string
            pickup_point_list: {
                id: string
                name: string
                cat_id: string
            }[]
        }[]

        sku_list: { // 规格列表
            unit: string
            price: string
            original_price: string
            stock_num: string
            goodsattrid: string
            ware_house_sku_id: string
        }[]

        isselect_shipping_mode?: (string | number)[]

        isselect_addressids?: (string | number)[]

        on_sale_branch_mall_ids?: (number | string)[]

        is_distribution?: number | string
        total_commission_percent: string

        global_mall_distribution_commission_list?: {  // 好友分级
            group_name: string
            total_commission_percent: string
            mall_distribution_group_id: string
            commission_level_list: {
                commission_level: string
                commission_percent: string
            }[]
        }[]

        has_rider_shipping_mode: boolean
        can_distribution: boolean
        not_weight: boolean
    }
    isedit: boolean
    goodsid: any
}

const Border = styled.div`
    border: 1px solid #d1d1d1;
    padding: 0 30px;
    &:not(:last-child){
        margin-bottom: 30px;
    }
    min-width: 500px;
    & > div{
        padding: 26px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:not(:last-child){
            border-bottom: #f1f1f1 solid 1px;
        }
    }
    input{
        border: 1px solid #ebebeb;
        padding: 8px 11px;
        flex: 1;
    }
`

let wrapper: Element


export default async function (goodsid: number | string | { ware_house_goods_id: string }, isedit?: boolean, goods_id?: number | string, t?: string, goodsid_?: string) {
    return new Promise(async (reslove, reject) => {
        function Mall(props: props) {
            const [express, setExpress] = useState((() => {
                const data = {
                    express: false,
                    pickup: false,
                    rider: false,
                }
                if (isedit) {
                    if (props.data.isselect_shipping_mode) {
                        data.express = props.data.isselect_shipping_mode.includes('1')
                        data.pickup = props.data.isselect_shipping_mode.includes('2')
                        data.rider = props.data.isselect_shipping_mode.includes('3')
                    }
                }
                return data
            })())
            const [distribution, setDistribution] = useState(props.data.is_distribution ? Boolean(Number(props.data.is_distribution)) : false)

            const [total_commission_percent, setTotalCommission] = useState(props.data.total_commission_percent || '')

            const props_commission = (props.data.mall_distribution_commission_list.length === 0 && isedit)
                ? props.data.global_mall_distribution_commission_list
                : props.data.mall_distribution_commission_list
            const [commsion,
                // setCommsion,
            ] = useState(props_commission.map(i => {
                return {
                    name: i.group_name,
                    value: i.total_commission_percent,
                    default: i.total_commission_percent,
                    id: i.mall_distribution_group_id,
                }
            }))
            const [branchList, setBranchList] = useState((props.data.branch_group_list || []).reduce((p, group) => {
                let list = []
                list.push({
                    value: false,
                    name: group.group_name,
                    group,
                })
                for (let i = 0; i < group.branch_list.length; i++) {
                    const branch = group.branch_list[i];
                    const item = {
                        value: false,
                        name: branch.branch_name,
                        branch,
                        select_sub_item: 1
                    }
                    if ((props.data.on_sale_branch_mall_ids || []).find(i => Number(i) === Number(branch.mall_id))) {
                        item.value = true
                    }
                    list.push(item)
                }
                return [...p, ...list]
            }, []))

            const [pickUpList,
                // setPickUpList,
            ] = useState(props.data.pickup_point_list.reduce((p, group) => {
                let list = []
                list.push({
                    value: false,
                    name: group.group_name,
                    group,
                })
                for (let i = 0; i < group.pickup_point_list.length; i++) {
                    const point = group.pickup_point_list[i];
                    const item = {
                        value: false,
                        name: point.name,
                        point,
                        select_sub_item: 1
                    }
                    if (isedit) {
                        item.value = !!props.data.isselect_addressids.find(j => String(j) === String(point.id))
                    }
                    list.push(item)
                }
                return [...p, ...list]
            }, []))
            const [attrlist, setAttrlist] = useState(props.data.sku_list.map(i => {
                return {
                    price: i.price !== '' ? (Number(i.price) / 100).toFixed(2) : '',
                    original_price: i.original_price !== '' ? (Number(i.original_price) / 100).toFixed(2) : '',
                    stock_num: i.stock_num,
                    unit: i.unit,
                    goodsattrid: i.goodsattrid,
                    ware_house_sku_id: i.ware_house_sku_id,
                }
            }))

            const [shouldClose, setShouldClose] = useState(false)

            return <Modal title='上架到商城' onClose={props.onClose} shouldClose={shouldClose} onConfirm={confirm}>
                <div style={{ maxHeight: '60vh', overflowY: 'auto', width: 590 }}>
                    <Border>
                        <div>
                            <div style={{ marginRight: '2em', minWidth: '6em' }}>选择配送方式</div>
                            <div>
                                <div className='flex-box'>
                                    <div className='cur-pointer' style={{ width: '10em' }} onClick={e => { setExpress({ ...express, express: !express.express }) }}>
                                        <Checkbox checked={express.express} style={{ marginRight: '1em' }} />
                                        <span>快递配送上门</span>
                                    </div>
                                    <div>
                                        {/* <div>运费设置</div> */}
                                    </div>
                                </div>
                                <div className=' flex-box'>
                                    <div className='cur-pointer' style={{ width: '10em' }} onClick={e => { setExpress({ ...express, pickup: !express.pickup }) }}>
                                        <Checkbox checked={express.pickup} style={{ marginRight: '1em' }} />
                                        <span>门店自提</span>
                                    </div>
                                    <div>
                                        <div className='flex-box'>
                                            {/* <div style={{ marginRight: '2em' }}>选择自提点</div> */}
                                            {/* <div style={{ flex: 1 }}>
                                                <Select maxHeight={400} placeholder='请选择自提点' showString={(() => {
                                                    const count = pickUpList.filter(i => i.point && i.value).length
                                                    return count ? `已选择${count}个自提点` : undefined
                                                })()} list={pickUpList} showKey='name' uni='pickup' value={(() => {
                                                    const list = []
                                                    for (let i = 0; i < pickUpList.length; i++) {
                                                        const b = pickUpList[i];
                                                        if (b.value) list.push(i)
                                                    }
                                                    return list
                                                })()} multi onChange={pickupHandle} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ cursor: (props.data.has_rider_shipping_mode && !props.data.not_weight) ? "pointer" : 'not-allowed' }} onClick={e => {
                                    if (!props.data.has_rider_shipping_mode || props.data.not_weight) return
                                    setExpress({ ...express, rider: !express.rider })
                                }}>
                                    <Checkbox disabled={!props.data.has_rider_shipping_mode || props.data.not_weight} checked={express.rider} style={{ marginRight: '1em' }} />
                                    <span>骑手配送<span style={{ color: 'var(--theme-main)' }}>{props.data.not_weight ? '（请填写商品重量）' : ''}</span></span>
                                </div>
                            </div>
                            <div style={{ flex: 1 }}></div>
                        </div>
                        <div>
                            <div style={{ marginRight: '2em' }}>让好友帮我卖</div>
                            <Switch checked={distribution} disabled={!props.data.can_distribution} onClick={() => { setDistribution(!distribution) }} />
                        </div>
                        {distribution ? <div style={{
                            borderRadius: 2, border: '1px solid #D9D9D9',
                            display: 'block', padding: 0,
                        }}>
                            <div className='flex-between' style={{
                                padding: '10px 20px',
                            }}>
                                <input value={total_commission_percent} placeholder='请输入佣金占销售价的百分比' style={{ border: 0, minWidth: '15em' }} type='number' max={100}
                                    onBlur={() => {
                                        const v = Number(total_commission_percent)
                                        if (v > 100) setTotalCommission('100')
                                        else {
                                            setTotalCommission(v.toFixed(0))
                                        }
                                    }}
                                    onChange={e => {
                                        setTotalCommission(e.target.value)
                                    }} />
                                <span>%</span>

                            </div>
                        </div> : null}
                        {/* {distribution ? <div style={{
                            borderRadius: 2, border: '1px solid #D9D9D9',
                            display: 'block', padding: 0,
                        }}>
                            {commsion.map(i => {
                                return <div className='flex-between' key={i.id} style={{
                                    padding: '10px 20px',
                                }}>
                                    <strong style={{ width: '6em' }}>{i.name}</strong>
                                    <div style={{ flex: 1 }} />
                                    <input value={i.value} placeholder='请输入佣金占销售价的百分比' style={{ border: 0, minWidth: '15em' }}
                                        onBlur={() => { setCommsion(commsion.map(i => { return { ...i, value: i.value === '' ? '' : Number(i.value).toFixed(2) } })) }}
                                        onChange={e => {
                                            setCommsion(commsion.map(j => {
                                                if (i.id === j.id) return { ...j, value: e.target.value }
                                                return j
                                            }))
                                        }} />
                                    <span>%</span>
                                </div>
                            })}
                        </div> : null} */}
                        {branchList && branchList.length > 0 ? <div>
                            <div className='flex-box'>
                                <div style={{ marginRight: '2em' }}>{isedit ? '同步到门店' : '选择上架门店'}</div>
                                <div style={{ flex: 1 }}>
                                    <Select placeholder='请选择门店' maxHeight={400} showString={(() => {
                                        const count = branchList.filter(i => i.branch && i.value).length
                                        return count ? `已选择${count}个门店` : undefined
                                    })()} list={branchList} showKey='name' uni='branch' value={(() => {
                                        const list = []
                                        for (let i = 0; i < branchList.length; i++) {
                                            const b = branchList[i];
                                            if (b.value) list.push(i)
                                        }
                                        return list
                                    })()} multi onChange={branchHandle} />
                                </div>
                            </div>
                        </div> : <div style={{ padding: 26 }}>
                            </div>}
                    </Border>
                    {attrlist.map((i, idx) => {
                        return <Border key={idx}>
                            <div style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: '7em' }} >规格</div>
                                <div>{i.unit}</div>
                            </div>
                            <div style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: '7em' }} >售价</div>
                                <input value={i.price} type='number' onBlur={formatPrice} onChange={e => setAttrlist(attrlist.map((j, jidx) => {
                                    const d = { ...j }
                                    if (jidx === idx) d.price = e.target.value
                                    return d
                                }))} />
                                <span style={{ marginLeft: 12 }}>元</span>
                            </div>
                            <div style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: '7em' }} >库存</div>
                                <input value={i.stock_num} type='number' placeholder='不填则不限数量' onChange={e => setAttrlist(attrlist.map((j, jidx) => {
                                    const d = { ...j }
                                    if (jidx === idx) d.stock_num = (e.target.value)
                                    return d
                                }))} />
                            </div>
                            <div style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: '7em' }} >原价</div>
                                <input value={i.original_price} type='number' placeholder='请输入商品原价（选填）' onBlur={formatPrice} onChange={e => setAttrlist(attrlist.map((j, jidx) => {
                                    const d = { ...j }
                                    if (jidx === idx) d.original_price = e.target.value
                                    return d
                                }))} />
                                <span style={{ marginLeft: 12 }}>元</span>
                            </div>
                        </Border>
                    })}
                </div>
            </Modal>

            function branchHandle(e) {
                let list = []
                if (e.group) {
                    if (e.group.branch_group_id === 'all') {
                        list = branchList.map(i => { return { ...i, value: !e.value } })
                        setBranchList(list)
                        return
                    }
                    list = branchList.map(i => {
                        const data = { ...i }
                        if (data.group && String(data.group.branch_group_id) === String(e.group.branch_group_id)) data.value = !e.value
                        if (data.branch && String(data.branch.branch_group_id) === String(e.group.branch_group_id)) data.value = !e.value
                        return data
                    })
                }
                if (e.branch) {
                    list = branchList.map(i => {
                        const data = { ...i }
                        if (data.branch && data.branch.mall_id === e.branch.mall_id) data.value = !e.value
                        return data
                    })
                }

                function checkAll() {
                    list.find(i => i.group && i.group.branch_group_id === 'all').value = !list.find(i => {
                        if (i.group) return i.group.branch_group_id !== 'all' && !i.value
                        if (i.branch) return !i.value
                        return false
                    })
                }
                function checkGroup() {
                    let gidx
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i];
                        if (item.group) {
                            if (item.group.branch_group_id === 'all') continue
                            gidx = i // 保存当前group下标
                            item.value = true
                        }
                        if (item.branch) {
                            if (!item.value) list[gidx].value = false
                        }
                    }
                }

                checkGroup()
                checkAll()

                setBranchList(list)
            }

            // function pickupHandle(e) {
            //     
            //     let list = []
            //     if (e.group) {
            //         if (e.group.group_id === 'all') {
            //             list = pickUpList.map(i => { return { ...i, value: !e.value } })
            //             setPickUpList(list)
            //             return
            //         }
            //         list = pickUpList.map(i => {
            //             const data = { ...i }
            //             if (data.group && String(data.group.group_id) === String(e.group.group_id)) data.value = !e.value
            //             if (data.point && String(data.point.cat_id) === String(e.group.group_id)) data.value = !e.value
            //             return data
            //         })
            //     }
            //     if (e.point) {
            //         list = pickUpList.map(i => {
            //             const data = { ...i }
            //             if (data.point && data.point.id === e.point.id) data.value = !e.value
            //             return data
            //         })
            //     }

            //     function checkAll() {
            //         console.log(list);
            //         list.find(i => i.group && i.group.group_id === 'all').value = !list.find(i => {
            //             if (i.group) return i.group.group_id !== 'all' && !i.value
            //             if (i.point) return !i.value
            //             return false
            //         })
            //     }
            //     function checkGroup() {
            //         let gidx
            //         for (let i = 0; i < list.length; i++) {
            //             const item = list[i];
            //             if (item.group) {
            //                 if (item.group.group_id === 'all') continue
            //                 gidx = i // 保存当前group下标
            //                 item.value = true
            //             }
            //             if (item.point) {
            //                 if (!item.value) list[gidx].value = false
            //             }
            //         }
            //     }

            //     checkGroup()
            //     checkAll()

            //     setPickUpList(list)
            //     const count = list.filter(i => i.point && i.value).length
            //     if (count > 0 && !express.pickup) setExpress({ ...express, pickup: true })
            // }

            function formatPrice() {
                setAttrlist(attrlist.map(i => {
                    return {
                        ...i,
                        original_price: i.original_price !== '' ? Number(i.original_price).toFixed(2) : '',
                        price: Number(i.price).toFixed(2),
                    }
                }))
            }

            function confirm() {
                let op = props.isedit ? 3 : 1
                const zeroPrice = attrlist.find(i => Number(i.price) <= 0)
                if (zeroPrice) {
                    message.warning(`${zeroPrice.unit}的价格不得为0`)
                    return
                }
                if (distribution && total_commission_percent === '') {
                    message.warning(`请输入佣金占销售价的百分比`)
                    return
                }
                const data: any = {
                    op,
                    is_distribution: distribution,
                    total_commission_percent,
                    distribution_group_commission_type: 2,
                    distribution_group_commission_list: commsion.map(i => {
                        return {
                            mall_distribution_group_id: i.id,
                            total_commission_percent: i.value,
                            commission_level_list: [
                                {
                                    commission_level: 1,
                                    commission_percent: i.value,
                                }
                            ]
                        }
                    }),
                    sku_list: attrlist.map(i => {
                        return {
                            goodsattrid: i.goodsattrid,
                            original_price: (Number(i.original_price) * 100).toFixed(0),
                            price: (Number(i.price) * 100).toFixed(0),
                            stock_num: i.stock_num,
                            ware_house_sku_id: i.ware_house_sku_id,
                        }
                    }),
                    goods_shipping_list: [
                        express.express ? {
                            shipping_mode: 1,
                            fare_template_id: [0]
                        } : false,
                        express.pickup ? {
                            shipping_mode: 2,
                            select_all: 0,
                            addressids: pickUpList.filter(i => i.select_sub_item === 1 && i.value).map(i => i.point.id)
                        } : false,
                        express.rider ? {
                            shipping_mode: 3,
                        } : false,
                    ].filter(i => i),

                    to_branch_mall_ids: branchList.filter(i => i.select_sub_item && i.value).map(i => {
                        return i.branch.mall_id
                    }),

                }
                if (isedit) {
                    data.goodsid = goods_id
                    data.mall_goods_id = goodsid
                    if (t === 'ware') {
                        data.mall_goods_id = props.goodsid
                        data.goodsid = goodsid_
                    }
                } else {
                    if (props.goodsid.ware_house_goods_id) {
                        data.ware_house_goods_id = props.goodsid.ware_house_goods_id
                    } else {
                        data.goodsid = goodsid
                    }
                }
                if (!props.isedit) delete data.mall_goods_id
                
                if (data.goods_shipping_list.length === 0) {
                    message.warning('请选择配送方式')
                    return
                }
                // const pickup = data.goods_shipping_list.find((i: any) => i && i.shipping_mode === 2) as { shipping_mode: 2, addressids: (number | string)[] } | undefined
                // if (pickup && pickup.addressids.length === 0) {
                //     message.warning('请选择自提点')
                //     return
                // }
                let href = '/mall/setmallgoods'
                if (props.goodsid.ware_house_goods_id) href = '/mall/addmallgoodsbywarehousegoods'
                post(href, data).then(res => {
                    message.success('上架成功')
                    setShouldClose(true)
                    reslove(true)
                }).catch(e => { if (e.message) message.error(e.message) })
            }
        }
        let data
        // @ts-ignore to opt out of type checking for a single line.
        if (!goodsid.ware_house_goods_id || isedit) {
            try {
                data = !isedit ? await get('/mall/beforeaddmallgoodsview', { goodsid: Number(goodsid) }) : await get('/mall/mallgoodsview', { mall_goodsid: Number(goodsid), from: 'update' })
                if (isedit) {

                }
            } catch (e) {
                if (e.message) message.error(e.message)
                reject(e)
                return
            }
        } else {
            try {
                // @ts-ignore to opt out of type checking for a single line.
                data = await get('/mall/beforeaddmallgoodsview', { ware_house_goods_id: goodsid.ware_house_goods_id || '', type: 'ware_house' })
            } catch (e) {
                if (e.message) message.error(e.message)
                reject(e)
                return
            }
        }

        if (!wrapper) {
            wrapper = document.createElement('div') as Element
            document.body.appendChild(wrapper)
        }

        ReactDOM.render(<Mall data={data} goodsid={goodsid} isedit={isedit} onClose={() => {
            ReactDOM.render(null, wrapper)
        }} />, wrapper)
    })
}