import styled from "styled-components";

const OnlyHemaTuanBranchItem = styled.div`
  width: 108px;
  height: 43px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  margin-right: 12px;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #fa503c;
    color: #fff;
  }
  &.active {
    background: #ffffff;
    border: 1px solid var(--theme-main);
    color: var(--theme-main);
  }
  &:hover,
  &.active {
    .delete {
      display: block;
    }
  }
  .delete {
    position: absolute;
    display: none;
    right: -10px;
    top: -10px;
    color: #ccc;
    i {
      line-height: 1;
    }
  }
`;
export default OnlyHemaTuanBranchItem;
