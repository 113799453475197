import React, { useState, useEffect } from 'react';
import { match, NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import MallOrder from './component/MallOrder';
import MallAnalysis from './component/MallAnalysis';
import { Nav, Num } from './style';
import styled from 'styled-components';
import { post } from '../../netApi/fetch';

interface Props extends RouteComponentProps {
    match: Match,
}

interface Match extends match {
    params: {
        id: string
    }
}

const Phone = styled.div`
    div:hover > &{
        display: flex;
    }
    width: 350px;
    height: 350px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px #cccccc;
    position: absolute;
    bottom: -350px;
    right: 12px;
    padding: 50px 20px 85px;
    display: none;
    flex-direction: column;
    align-items: center;
    z-index: 36;
    img{
        width: 190px;
        height: 190px;
        margin-bottom: 40px;
        margin-top: 30px;
    }
`

function C(props: Props) {
    const id = props.match.params.id
    const [tuanData,] = useState<any>()
    const [orders, setOrders] = useState(0)
    const [active, setActive] = useState(0)
    useEffect(() => {
        if (!tuanData) return
        const orders = Number(tuanData.isPay) + Number(tuanData.sellerorders_ispay)
        setOrders(orders)
    }, [tuanData])

    useEffect(() => {
        const page = props.location.pathname
        if (/applyrefund/.test(props.location.search)) {
            setActive(2)
        } else if (/orders/.test(page)) {
            setActive(1)
        } else if (/analysis/.test(page)) {
            setActive(3)
        }
    }, [props])

    const [code, setCode] = useState<string>()
    useEffect(() => {
        post('/user/getwxacode?app=jieligo', {
            width: 200,
            page: 'package/packageUserhome/userhome',
            scene: `mall_id=${props.match.params.id.split(',')[0]}&relation_id=${props.match.params.id.split(',')[1]}`,
        }).then(res => {
            setCode(res.image)
        })
    }, [props.match.params.id])

    return <div>
        <Nav active={active}>
            <NavLink to={`/index/s/${id}/orders`}>
                <i className='iconfont icon-dingdanguanli1' />
                <div>订单管理</div>
                {orders > 0 ? <Num>{orders}</Num> : null}
            </NavLink>
            <NavLink to={`/index/s/${id}/orders?option=applyrefund`}>
                <i className='iconfont icon-tuikuan3' />
                <div>退款</div>
            </NavLink>
            <NavLink to={`/index/s/${id}/analysis`}>
                <i className='iconfont icon-jiaoyifenxi1' />
                <div>交易分析</div>
            </NavLink>
            <div>
                <i className='iconfont icon-shoujichakan' />
                <div>手机端查看</div>
                <Phone>
                    <div style={{ color: '#666' }}>扫一扫用手机查看商城</div>
                    <img alt='' src={code} />
                </Phone>
            </div>
        </Nav>
        <Switch>
            <Route path={`/index/s/:id/orders`} >
                <MallOrder />
            </Route>
            <Route path={`/index/s/:id/analysis`} component={MallAnalysis} />
        </Switch>
    </div>
}

export default C