import React, { Component, ChangeEvent } from 'react'

import styled from 'styled-components';
const instancelist: string[] = []
const Input = styled.input`
    user-select: none; 
    width: 100%; 
    font-size: 14; 
    padding: 4px 11px;
    background: #fff; 
    border: 1px solid #d9d9d9;
    outline: none; 
    border-radius: 4px;
    transition: all .3s;
    &:hover{
        border-color: var(--theme-main);
    }
    &::placeholder{
        color: #bfbfbf;
    }
`
const SelectPanel = styled.div`
    position: absolute;
    z-index: 1000; 
    background: #fff; 
    box-shadow: 0 2px 8px rgba(0,0,0,0.15); 
    overflow: auto;
    border-radius: 6px;
    transition: all .36s; 
    padding: 4px; 
    transform-origin: top;
    transition-timing-function: ease;
`
const SelectItem = styled.div`
    padding: 4px 11px; 
    font-size: 14px; 
    user-select: none;
    word-break: break-all;
    &:hover{
        background: #f5f5f5;
    }
`
const Icon = styled.i`
    font-size: 12px;
    transition: transform .36s;
    transform-origin: center;
`

interface State {
    showList: boolean,
    value?: number | string,
    inputValue?: string,
    list?: { [key: string]: any }[],
}

interface Props {
    list: { [key: string]: any }[],
    uni: string,
    showKey: string,
    placeholder?: string,
    onChange?: (e: { [key: string]: any }, i?: number) => void,
    maxHeight?: number,
    hovorClass?: string,
    value?: number | number[],
    disabled?: boolean,
    inputStyle?: React.CSSProperties,
    selectWidth?: number | string,
    canSearch?: boolean,
    multi?: boolean,
    showString?: string
    readonly?: boolean,
    disableKey?: string
    hideIcon?: boolean
}

class Select extends Component<Props, State> {

    readonly state: State = {
        showList: false,
    }

    key: number = instancelist.length

    constructor(p: Props) {
        super(p)
        instancelist.push('')
    }

    select(i: number, e?: React.MouseEvent) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }
        if (this.props.disableKey) {
            const item = (this.state.list || this.props.list)[i]
            if (item[this.props.disableKey] === true) return
        }
        this.setState({
            inputValue: undefined,
            list: undefined,
        })
        this.props.onChange && this.props.onChange((this.state.list || this.props.list)[i], i)
    }
    showPanel(e: any) {
        if (this.props.disabled) return
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            showList: !this.state.showList
        })
    }
    closeSelect(e: any) {
        if ((String(e.target.className) || '').split(' ').includes(this.props.uni)) return
        this.setState({
            showList: false
        })
    }
    filterList(e: ChangeEvent<HTMLInputElement>) {
        const key = e.target.value
        this.setState({ inputValue: key })
        this.setState({
            list: this.props.list.filter(i => {
                if (i[this.props.showKey]) {
                    const reg = new RegExp(String(key))
                    if (reg.test(i[this.props.showKey])) return true
                }
                return false
            })
        })
        this.props.onChange && this.props.onChange({})
    }
    cs = this.closeSelect.bind(this)
    componentWillUnmount() {
        document.removeEventListener('click', this.cs)
    }
    componentDidMount() {
        document.addEventListener('click', this.cs)
    }
    componentDidUpdate(prev: Props) {
        if (!Array.isArray(this.props.value)) {
            if (this.props.value !== prev.value) {
                if (this.props.value === -1) {
                    this.setState({ value: '' })
                    return
                }
                this.props.value !== undefined && this.select(this.props.value)
            }
        }
    }
    render() {
        const value: string = (() => {
            if (this.props.showString) return this.props.showString
            if (this.state.inputValue !== undefined) return this.state.inputValue
            if (this.props.value !== undefined) {
                if (!Array.isArray(this.props.value)) {
                    if (this.props.list[this.props.value]) return this.props.list[this.props.value][this.props.showKey]
                    if(this.props.value === -1) return ''
                    return this.props.value
                }
                if (Array.isArray(this.props.value)) {
                    if (!this.props.multi) {
                        throw new Error("");
                    }
                    const value = this.props.value || []
                    return this.props.list.filter((item: any, idx: number) => {
                        for (let i = 0; i < value.length; i++) {
                            const key = value[i];
                            if (Number(key) === idx) return true
                        }
                        return false
                    }).map(i => i[this.props.showKey]).join(',')
                }
            }
            return ''
        })()
        return (
            <div style={{
                position: 'relative'
            }}  >
                {!this.props.readonly
                    ? <Input
                        placeholder={this.props.placeholder}
                        onChange={this.filterList.bind(this)}
                        value={value}
                        readOnly={this.props.canSearch ? false : true}
                        style={{ ...this.props.inputStyle }}
                        onClick={this.showPanel.bind(this)} className={`${this.props.uni} cur-pointer`} />
                    : <div style={{ paddingRight: 22, ...this.props.inputStyle }} onClick={this.showPanel.bind(this)} className={`${this.props.uni} cur-pointer`}>
                        {value || this.props.placeholder}
                    </div>
                }

                {!this.props.hideIcon && <div style={{ height: '100%', position: 'absolute', right: 5, top: 0 }} className='fcc' >
                    <Icon className='icon-down iconfont' style={{
                        transform: this.state.showList ? 'rotate(180deg)' : '',
                        color: this.props.inputStyle ? this.props.inputStyle.color : '',
                    }} />
                </div>}
                <SelectPanel style={{
                    maxHeight: this.props.maxHeight,
                    opacity: this.state.showList ? 1 : 0,
                    width: this.props.selectWidth || '100%',
                    transform: `${this.state.showList ? 'scaleY(1)' : 'scaleY(0)'}`,
                }} className={`${this.props.uni}`}>
                    {(this.state.list || this.props.list).map((item, i) => {
                        return (
                            <SelectItem key={i} onClickCapture={this.select.bind(this, i)}
                                className={`cur-pointer ${this.props.hovorClass || ''} ${this.props.multi ? this.props.uni : ''}`}
                                style={{
                                    paddingLeft: item.select_sub_item ? `calc(${item.select_sub_item}em + 11px)` : undefined,
                                    cursor: item[this.props.disableKey] === true ? 'not-allowed' : undefined,
                                }}
                            >
                                {this.props.multi
                                    ? Array.isArray(this.props.value) && this.props.value.includes(i)
                                        ? <i style={{ marginRight: 6, color: 'var(--theme-main)' }} className={`iconfont icon-duoxuan3`} />
                                        : <i style={{ marginRight: 6, }} className={`iconfont icon-duoxuan2`} />
                                    : null}
                                {item[this.props.showKey]}
                            </SelectItem>
                        )
                    })}
                </SelectPanel>
            </div>
        )
    }
}
export default Select