/*
 * @Descripttion:
 * @version:
 * @Author: 郭胜军
 * @Date: 2022-01-10 17:12:47
 * @LastEditors: 郭胜军
 * @LastEditTime: 2022-01-10 17:54:12
 */
import React from 'react';
import { message } from 'antd';
import './index.scss';

class ChangeOrderNoModal extends React.Component {
  constructor(props) {
    const { expressnum } = props;
    super(props);
    this.state = {
      visible: false,
      inputValue: expressnum || '',
    };
  }
  componentDidMount() {}

  handleInputChange(evt) {
    const value = evt.target.value;
    this.setState({ inputValue: value });
  }
  showModal() {
    this.setState({ visible: true });
  }
  hideModal() {
    this.setState({ visible: false });
  }
  handleSure() {
    const { inputValue } = this.state;
    if (inputValue === '') {
      message.error('请输入快递单号');
      return;
    }
    this.props.handleChangeOrderNo(inputValue);
  }
  render() {
    const { visible, inputValue } = this.state;
    if (!visible) {
      return null;
    }
    return (
      <div className='change-orderno-modal'>
        <div className='modal-screen' onClick={this.hideModal.bind(this)}></div>
        <div className='modal-content'>
          <div className='content-title semibold'>修改快递单号</div>
          <div className='content-input flex-row align-center'>
            <span className='input-name'>快递单号</span>
            <input className='input-node' placeholder='请输入快递单号' value={inputValue} onChange={this.handleInputChange.bind(this)} />
          </div>
          <div className='content-btns flex-row align-center'>
            <div className='btns-item btn-l' onClick={this.hideModal.bind(this)}>
              取消
            </div>
            <div className='btns-item btn-r' onClick={this.handleSure.bind(this)}>
              确认
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeOrderNoModal;
