import React, { Component } from 'react'
// import axios from 'axios';
import { connect } from 'react-redux';

// import Select from '../total/Select_.tsx';

// import api from '../../netApi';

// import WX from '../../high-component/Wx_sign';
import parse from '../../methods/paramParse.ts';
import { get } from '../../netApi/fetch';
import BanUnLogin from '../../high-component/BanUnLogin';

class test extends Component {

    constructor() {
        super()
        this.state = {
            v: -1,
            showData: {}
        }
    }

    componentDidMount() {
        get('/file/countmoney').then(res => {
            this.setState({
                showData: res
            })
        })
    }

    componentDidUpdate() {
        const data = parse(this.props.match.params.data)
        if (!data.redirect_uri) return
        data.redirect_uri = decodeURIComponent(data.redirect_uri)
        if (!(data.redirect_uri.match(/localhost/gi) || data.redirect_uri.match(/https/gi))) return
        if (this.props.islogin) {
            if (data.redirect_uri.match(/\?/g)) {
                data.redirect_uri += `&token=${this.props.user.token}`
            } else {
                data.redirect_uri += `?token=${this.props.user.token}`
            }
            window.location.href = data.redirect_uri
        }
    }

    render() {
        return (<div style={{ minHeight: 99, flexDirection: 'column', display: 'flex', alignItems: 'flex-start', maxWidth: 600, margin: 'auto', paddingTop: '5em' }}>
            {Object.keys(this.state.showData).map((key, idx) => {
                const item = this.state.showData[key]
                if (typeof item === 'string') {
                    return <div style={{width: '100%'}} key={idx}>{item}</div>
                } else {
                    if (typeof item !== 'object') return ''
                    return <div style={{width: '100%'}} key={idx}>
                        <table>
               
                            <tbody>
                                <tr>
                                    <td style={{width: '5em'}}>费率</td>
                                    <td>合计</td>
                                </tr>
                                {item.map(i => {
                                    return <tr  key={i.tip_rate}>
                                        <td>{(Number(i.tip_rate) * 100).toFixed(2)}%</td>
                                        <td>{i.amount_sum}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <br />
                    </div>
                }
            })}
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        islogin: state.is_Login
    }
}

export default BanUnLogin(connect(mapStateToProps)(test))