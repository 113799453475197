import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// import api from '../../netApi/index';
import {post} from '../../netApi/fetch.ts';

import { setIsInvite, setusertype } from '../../reducers/index'

import BanUnLogin from '../../high-component/BanUnLogin'
import Main from '../../high-component/Content'
import tuanzhang from '../../images/tuanzhang.png';
import service_invite from '../../images/service_invite.png';
// import tixian from '../../images/tixian.png';



class Promotion extends Component {
    check(p) {
        const _this = this
        post('/proxy/checkProxy', {type: 1}).then(res => {
            _this.props.setUserType(res.type)
            let typeText
            switch (Number(res.type)) {
                case 1:
                    typeText = '黄金服务商'
                    break;
                case 2:
                    typeText = '钻石服务商'
                    break;
                case 3:
                    typeText = '初级会员'
                    break;
                case 4:
                    typeText = '高级会员'
                    break;
                case 6:
                    typeText = '特邀会员'
                    break
                default:
                    break;
            }
            _this.setState({
                typeText
            })
            if([0, 5, 6].includes(Number(res.type))){
                p.history.push('/')
            }
        })
    }
    setServicePath(p) {
        this.setState({
            servicePath: {
                pathname: `/payment/service/${p.user.self_sid}`,
                state: {
                    isinvite: true
                },
            }
        })
    }
    setmemberPath(p) {
        this.setState({
            memberPath: {
                pathname: `/payment/member/${p.user.self_sid}`,
                state: {
                    isinvite: true
                },
            }
        })
    }
    constructor(p) {
        // console.log(p)
        super()
        this.state = {
            servicePath: {},
            memberPath: {},
        }
        if (p.waitingLogin) return
        this.check(p)
    }

    componentDidUpdate(prev) {
        if (!prev.is_Login && this.props.is_Login) {
            this.check(this.props)
            this.setmemberPath(this.props)
            this.setServicePath(this.props)
        }
    }
    componentDidMount() {
        this.setmemberPath(this.props)
        this.setServicePath(this.props)
    }
    render() {
        return (
            <div style={{ position: 'relative', paddingTop: '30%' }}>
                <img src='https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/6Z3xzUX0gU.png' style={{ display: 'block', position: 'absolute', top: 0, width: '100%', zIndex: -1 }} alt='' />
                <div className='fbc' style={{ padding: 20, background: 'white', boxShadow: '0 8px 19px 0 rgba(219,219,219,0.50)', maxWidth: 800, margin: '0 auto 20px' }}>
                    <img alt='头像' src={this.props.user ? (this.props.user.avatar) + '?imageView2/1/w/200/h/200' : ''} style={{ width: 50, height: 50, borderRadius: '50%' }} ></img>
                    <div style={{ flex: 1, marginLeft: 10 }}>
                        <div className='fcc' style={{ justifyContent: 'flex-start', marginBottom: 8 }} >
                            <span style={{ fontSize: 16 }}>{this.props.user.name}</span>
                            <span style={{
                                background: 'rgb(253, 83, 86)', color: '#fff', fontSize: 12, padding: '4px 11px', borderRadius: 5, marginLeft: 16
                            }}>{this.state.typeText}</span></div>
                        <div style={{ fontSize: 10, color: '#9d9d9d' }}>邀请人：{this.props.user.inviter}</div>
                    </div>
                </div>
                <div style={{ background: '#ffffff', boxShadow: '0 8px 19px 0 rgba(219,219,219,0.50)', padding: 20, maxWidth: 800, margin: '0 auto' }}>
                    <div>
                        <div className='fbc' style={{ justifyContent: 'space-around', padding: 0, minWidth: 300, marginTop: 20 }}>
                            <div style={{ width: 50, height: 50, minWidth: 50, minHeight: 50, background: '#ffb63c', marginRight: 10 }} className='fcc'>
                                <img alt='邀请团长' src={tuanzhang} style={{ width: 25, height: 25, minWidth: 25, minHeight: 25, }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ fontSize: 18 }}>邀请团长/门店升级会员</div>
                                <div className='fcc' style={{ display: 'inline-block', background: '#ffefef', borderRadius: 8, color: '#872c2d', fontSize: 10, padding: '4px 10px' }}>直接引荐全返</div>
                            </div>
                            <Link to={this.state.memberPath} >
                                <button className='button_solid_red' onClick={this.props.setIsInvite()} style={{ fontSize: 12, color: '#fff', background: '#fd5356' }}>立即邀请</button>
                            </Link>
                        </div>
                        <div className='fbc' style={{ justifyContent: 'space-around', padding: 0, minWidth: 300, marginTop: 20 }}>
                            <div style={{ width: 50, height: 50, minWidth: 50, minHeight: 50, background: '#3c99ff', marginRight: 10 }} className='fcc'>
                                <img alt='邀请服务商' src={service_invite} style={{ width: 25, height: 25, minWidth: 25, minHeight: 25, }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ fontSize: 18 }}>邀请代理付费</div>
                                <div className='fcc' style={{ display: 'inline-block', background: '#ffefef', borderRadius: 8, color: '#872c2d', fontSize: 10, padding: '4px 10px' }}>直接引荐返40%，间接返10%</div>
                            </div>
                            <Link to={this.state.servicePath} >
                                <button className='button_solid_red' onClick={this.props.setIsInvite()} style={{ fontSize: 12, color: '#fff', background: '#fd5356' }}>立即邀请</button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <img src={tixian} alt='' style={{maxWidth: '90vw',display: 'block',margin: '0 auto'}} />
                </div> */}
                <div style={{ background: 'white', boxShadow: '0 8px 19px 0 rgba(219,219,219,0.50)', padding: 20, maxWidth: 800, margin: '0 auto 20px' }}>
                    <Link to='/promotion/follow'>
                        <div className='fbc' style={{ justifyContent: 'space-around', }}>
                            <div style={{ flex: 1, color: '#353535'}}>
                                <div style={{ fontSize: 18 }}>我的粉丝</div>
                                <div style={{ fontSize: 10 }}>粉丝付费升级，你都将获得奖励</div>
                            </div>
                            <div>
                                &gt;
                            </div>
                        </div>
                    </Link>
                    {(Number(this.props.user.source_uid) === Number(this.props.user.self_uid)) ? (<hr></hr>) : ''}
                    {(Number(this.props.user.source_uid) === Number(this.props.user.self_uid)) ? (<Link to='/promotion/withdraw'>
                        <div className='fbc' style={{ justifyContent: 'space-around', }}>
                            <div style={{ flex: 1, color: '#353535'}}>
                                <div style={{ fontSize: 18 }}>我的钱包</div>
                                <div style={{ fontSize: 10 }}>你的每一次分享成果，都会得到相应的奖励，并能随时提现</div>
                            </div>
                            <div>
                                &gt;
                            </div>
                        </div>
                    </Link>) : ''}
                    <hr></hr>
                    <Link to='/promotion/spread'>
                        <div className='fbc' style={{ justifyContent: 'space-around', }}>
                            <div style={{ flex: 1, color: '#353535'}}>
                                <div style={{ fontSize: 18 }}>我的推广订单</div>
                                <div style={{ fontSize: 10 }}>通过推广，你的代理商或用户支付而产生的订单</div>
                            </div>
                            <div>
                                &gt;
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        isWX: state.isWX,
        waitingLogin: state.waitingLogin,
        is_Login: state.is_Login,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsInvite: () => {
            dispatch(setIsInvite(true))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        }
    }
}

export default BanUnLogin(Main(connect(mapStateToProps, mapDispatchToProps)(withRouter(Promotion))))