import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { toggleSelectPanel, toggleSidebar } from "../reducers/index";

// 首页
import Home from "../component/index/Home";
import OverView from "../component/index/OverView.tsx";

// 左侧边栏
import Sidebar from "../component/total/Sidebar";

// 登录
import Login from "../component/total/login";

// 空页面 刷新用
import Empty from "../component/total/Empty";

// loading
import Loading from "../component/total/Loading_mid.tsx";

// 订单管理
import orderDetail from "../component/order/detail";
import OrderList from "../component/order/OrderList.tsx"; // 订单列表 重置版
import Batch from "../component/order/Batch";
import PrintOrderPage from "../component/order/PrintOrderPage";
import ExportExcel from "../component/order/DownloadExcel.tsx";

// 代理
import Payment from "../component/agent/Payment";
import Promotion from "../component/agent/Promotion";
import Withdraw from "../component/agent/withdraw";
import spread from "../component/agent/spread";
import Follow from "../component/agent/follow";
import Member from "../component/agent/Member";
import Paysuccess from "../component/agent/Paysuccess";

// 团购管理
import SingleGood from "../component/tuan/SingleGood";
import Edit from "../component/tuan/Edit";

// 购买
import Tuan from "../component/tuan/Tuan";
import Settle from "../component/tuan/Settle";
import TuanSuccess from "../component/tuan/Success";
import SelectAddress from "../component/tuan/SelectAddress.tsx";

// 我的订单
import Myorder from "../component/order/Myorder";
import Myorderdetail from "../component/order/Myorderdetail";

// 商品管理
// import Goodlist from '../component/good/Goodlist.tsx';
import GoodEdit from "../component/good/Edit";
import DataCreate from "../component/good/dataCreate.tsx";

// 活动订单
import EventOrderList from "../component/event/orderlist";

// 测试功能
import Test from "../component/test/test.tsx";

// 1688相关
import AliGoodList from "../component/ali/Goodlist/Goodlist.js";
import AliRefundList from "../component/ali/refund/RefundList.js";

// 关注我的
import FollowList from "../component/follow/List.tsx";

// 我的帮卖好友
import MySeller from "../component/friends/MySeller.tsx";
// 我在帮卖
import UpLine from "../component/friends/UpLine";

import Money from "../component/god/Money";
import PickList from "../component/PickList/PickList";
import TotalGoods from "../component/PickList/TotalGoods";
import Exportdatabyfriend from "../component/print/Exportdatabyfriend";

import FinanceHome from "../component/finance/Home.tsx";

// 账户信息
// import data from '../component/user/data';

import MallOrder from "../component/mall/orders";
import mallAnalysis from "../component/mall/Analysis.tsx";
import MallPrint from "../component/mall/Print.tsx";
import Top from "../component/total/Top";
import Service from "../component/total/Service";
import pay from "../component/pay/pay";
import apple from "../component/apple/apple";
import apps from "../component/downloads/apps";

class R extends Component {
  componentDidMount() {
    document.title =
      window.location.href.indexOf("jieligo") === -1
        ? "GroupAI公司官网"
        : "接力GO";
  }

  hideSelectPanel() {
    const closeList = [
      ["showSelectPanel", "hideSelectPanel"],
      ["sidebarOpen", "hideSidebar"],
    ];
    for (let i = 0; i < closeList.length; i++) {
      const item = closeList[i];
      if (!this.props[item[0]]) continue;

      if (item[0] === "sidebarOpen") {
        if (document.body.clientWidth > 993) continue;
      }
      this.props[item[1]]();
    }
  }
  render() {
    return (
      <div onClick={this.hideSelectPanel.bind(this)}>
        <Router>
          <Top />
          <Sidebar />
          <Switch>
            {/* 未登录 */}
            <Route path="/home" component={Home} />

            {/* 主页 */}
            {/* <Route path='/index' component={Index} /> */}
            <Route path="/index" component={OverView} />

            {/* 账号信息 */}
            {/* <Route path='/user/data' component={data} /> */}

            {/* 其他做不了微信登陆的网页跳过来拿token */}
            <Route path="/auth" exact component={Test} />

            {/* 提现统计 */}
            <Route path="/countmoney" component={Money} />

            <Route
              path="/download/order/:query"
              exact
              component={ExportExcel}
            />
            {/** 下载excel */}
            <Route path="/orders/detail/:id" exact component={orderDetail} />
            {/** 单个订单 */}
            <Route path="/orders" exact component={OrderList} />
            {/** 总订单列表 */}
            <Route path="/orders/print" exact component={PrintOrderPage} />
            <Route
              path="/orders/print/:tuanid"
              exact
              component={PrintOrderPage}
            />
            <Route path="/orders/:query" exact component={OrderList} />
            {/** 总订单列表 */}

            {/** 新订单列表 */}
            {/* <Route path='/orders/q' exact component={OrderList} /> */}
            {/** 新订单列表 */}
            {/* <Route path='/orders/q/:query' exact component={OrderList} /> */}

            {/** 单个订单 */}
            {/* <Route path='/orders/:id' exact component={orderDetail} /> */}
            {/** 订单分析 */}
            {/* <Route path='/orders/Analysis/:tid' exact component={Analysis} /> */}

            {/* 活动报名 */}
            <Route
              path="/eventOrder/detail/:id"
              exact
              component={orderDetail}
            />
            <Route path="/eventOrder" exact component={EventOrderList} />

            {/* 服务商（旧） */}
            <Route path="/promotion" exact component={Promotion} />
            <Route path="/promotion/banSidebar" exact component={Promotion} />
            <Route path="/promotion/withdraw" exact component={Withdraw} />
            <Route path="/promotion/spread" exact component={spread} />
            <Route path="/promotion/follow" exact component={Follow} />
            <Route
              path="/promotion/withdraw/banSidebar"
              exact
              component={Withdraw}
            />
            <Route
              path="/promotion/spread/banSidebar"
              exact
              component={spread}
            />
            <Route
              path="/promotion/follow/banSidebar"
              exact
              component={Follow}
            />
            <Route path="/payment/:type/:sid" component={Payment} />
            <Route path="/:type/success" exact component={Paysuccess} />
            <Route path="/member" exact component={Member} />

            {/* 跳到一个空白页面然后返回以实现刷新 */}
            <Route path="/refresh" exact component={Empty} />

            {/* 团购编辑 */}
            <Route path="/event/:mode/:id" exact component={Edit} />
            <Route path="/event/:mode" exact component={Edit} />
            <Redirect from="/eventlist" to="/index" />

            {/* 商品编辑 */}
            <Route path="/good/:type/:id" exact component={GoodEdit} />

            {/* h5下单 */}
            <Route path="/tuan/:id" exact component={Tuan} />
            <Route path="/:sid/tuan/:id" exact component={Tuan} />
            <Route path="/SelectGood" exact component={SingleGood} />
            <Route path="/Settle" exact component={Settle} />
            <Route
              path="/useraddress/:tuanid"
              exact
              component={SelectAddress}
            />
            <Route path="/useraddress" exact component={SelectAddress} />
            <Route path="/Success" exact component={TuanSuccess} />
            <Route path="/help/importexcel" exact component={Batch} />

            {/* 我的订单 */}
            <Route path="/Myorder" exact component={Myorder} />
            <Route path="/orderdetail" exact component={Myorderdetail} />

            {/* 1688 */}
            <Route path="/1688/goodlist" exact component={AliGoodList} />
            <Route path="/1688/refund" exact component={AliRefundList} />

            {/* 关注我的 */}
            <Route path="/follow/list" exact component={FollowList} />

            {/* 我的帮卖好友 */}
            <Route path="/fs/list" exact component={MySeller} />

            {/* 我在帮谁卖 */}
            <Route path="/ul/list" exact component={UpLine} />

            {/* 打印拣货单与客单 */}
            <Route path="/picklist/:tuanid" exact component={PickList} />
            {/* 打印商品总单 */}
            <Route
              path="/print/totalgood/:tuanid"
              exact
              component={TotalGoods}
            />
            {/* 打印下级金额数据 */}
            <Route
              path="/print/dbf/:tuanid"
              exact
              component={Exportdatabyfriend}
            />

            {/* 打印商城相关 */}
            <Route path="/mallprint/:type" exact component={MallPrint} />

            {/* 导出对账单 */}
            <Route path="/finance" exact component={FinanceHome} />

            {/* 商城相关 */}
            <Route path="/mall/orders" exact component={MallOrder} />
            <Route path="/mall/orders/:query" exact component={MallOrder} />
            {/** 总订单列表 */}
            <Route path="/mall/Analysis/:id" exact component={mallAnalysis} />
            {/** 订单分析 */}

            {/* 商品信息库 */}
            <Route path="/goods/data/create" component={DataCreate} />
            {/* <Route path='/goods/data/:type' component={GoodDatabase} /> */}
            <Redirect from="/goods/data" to="/index/mall/goods/2" />

            {/* 支付 */}
            <Route path="/pay" component={pay} />

            {/* 苹果相关 */}
            <Route path="/apple" component={apple} />

            {/* 下载app */}
            <Route path="/downloads/app" component={apps} />

            <Redirect to="/home" />
          </Switch>
          <Login />
          <Loading />
          <Service />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSelectPanel: state.showSelectPanel,
    sidebarOpen: state.sidebarOpen,
    is_Login: state.is_Login,
    showLoginPanel: state.showLoginPanel,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    hideSelectPanel: () => {
      dispatch(toggleSelectPanel(false));
    },
    hideSidebar: () => {
      dispatch(toggleSidebar(false));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(R);
