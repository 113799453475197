// 消费者面单
import React from 'react';
import styled from 'styled-components';

const S = styled.span`
    font-size: 24px;
    font-family: SimHei;
    font-weight: bold;
    color: #000;
`
const Item = styled.div`
    font-family: SimHei;
    color: #000;
    width: 58mm;
    margin-left: 90px;
    page-break-after: always;
    @media print{
        margin-left: 0;
    }
    & > div{
        margin-bottom: 6px;
    }
`

const L = styled.div`
    border-bottom: 1px #000 solid;
`

export default function (props) {
    const order = props.item
    const delivery = Number(order.shipping_mode)
    return <Item>
        <div className='flex-center'>
            <S>接力GO订单</S>
        </div>
        <div>
            接龙号：<S>{order.buy_no}</S>
        </div>
        {delivery !== 3 && <div>
            配送方式：<S>
                {delivery === 1 ? '快递' : ''}
                {delivery === 2 ? '自提' : ''}
            </S>
        </div>}
        {delivery === 3 && <div>
            商城：
        </div>}
        <div>
            订单状态：{order.status_content}
        </div>
        <L></L>
        <div>订单号：{order.payorderid}</div>
        <div>下单时间：{order.createtime}</div>
        <L></L>
        <div>
            <S>买家备注：{order.remark}</S>
        </div>
        <L></L>
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <td>品名/规格</td>
                    <td>总数</td>
                    <td>小计</td>
                </tr>
            </thead>
            <tbody>
                {order.items.map((item) => {
                    return (
                        <tr style={{}} key={item.id}>
                            <td>
                                <div style={{ padding: '0' }}>
                                    <div>{item.name}</div>
                                    <div> 【{item.unit}】</div>
                                </div>
                            </td>
                            <td>
                                {item.num}
                                {(item.checkin_num && Number(item.checkin_num) > 0)
                                    ? <div style={{ fontSize: 12 }}>【已{Number(order.addressid) !== 0 ? '核销' : '发货'}&nbsp;{item.checkin_num}】</div>
                                    : null}
                                {(item.refund_num && Number(item.refund_num) > 0)
                                    ? <div style={{ fontSize: 12 }}>【已退款&nbsp;{item.refund_num}】</div>
                                    : null}
                            </td>
                            <td>{((Number(item.price) * Number(item.num)) / 100).toFixed(2)}</td>
                        </tr>
                    )
                })}
                <tr>
                    <td colSpan={3}>
                        <div>
                            共{order.items.reduce((count, item) => count + Number(item.num), 0)}件，合计￥<S>{(order.totalprice / 100).toFixed(2)}</S>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <L></L>
        {delivery === 1 && <>
            <div>
                <S> 地址：{order.address}</S>
            </div>
            <div><S>{order.phone}</S></div>
            <div><S>{order.username}</S></div>
        </>}
        {delivery === 2 && <>
            <div>
                <S>自提点：{order.address_info?.address + order.address_info?.addressdetail}</S>
            </div>
            <div>
                <S>{order.fielddata.find(i => Number(i.systemid) === 2)?.fieldvalue[0].content}</S>
            </div>
            <div>
                <S>{order.fielddata.find(i => Number(i.systemid) === 1)?.fieldvalue[0].content}</S>
            </div>
        </>}
        {delivery === 3 && <>
            <div>
                <S> 地址：{order.address}</S>
            </div>
            <div><S>{order.phone}</S></div>
            <div><S>{order.username}</S></div>
        </>}
        <div style={{ margin: '1em 0' }}>
            =======分割线========
                </div>
    </Item>
}