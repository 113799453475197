/* eslint-disable react/jsx-no-target-blank */
import { message, Pagination } from 'antd';
import React, { useEffect, useRef, useState, } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import BTN, { Light, SolidRed } from '../../../StyleComponents/Button';
import Search from '../../total/Input/Search';
import setCategory from '../../good/method/setCategory';
import eventlist from '../../good/method/eventlist';
import createEvent from '../../good/method/createEvent';
import { get, getToken, getUrl, post, } from '../../../netApi/fetch';
import moment from 'moment';
import importGoodData from '../../good/method/importGoodData';
import importGoodToMall from '../../good/method/importGoodToMall';
import uploadimage from '../../good/method/uploadimage';
import parseParams from '../../../methods/paramParse';
import exportSalesData from '../../good/method/exportSalesData';
import GoodItem from '../../good/datalistitem';
import { INDEX_CONTENT_LIST_WIDTH, PAGINATION_HEIGHT, SIDEBAR_WIDTH, } from '../../../data/style';
import Loading from '../../Method/Loading';
import simpleCreateGood from '../../good/method/simpleCreateGood';
import { showdata } from '../OverView';
import changeBranch from '../method/changeBranch';
import { logout, saveMallInfo, setusertype, set_login_user_data, waitingLogin } from '../../../reducers';

interface props extends RouteComponentProps {
    user: user
    match: Match
    sidebarOpen: boolean
    bindmallinfo?: showdata
    mall: mall.mallinfo
    refresh: () => void
    saveMallInfo: (data: false | mall.mallinfo) => void
    logout: () => void
    login: (p: any) => void
    waitingLogin: () => void
    setUserType: (p: any) => void
    saveMemberInfo: (data: any) => void
    memberInfo: member.info
    isHema?: boolean
}
interface Match extends match {
    params: {
        type: string
    }
}



export interface good {
    can_buy?: string;
    attribute: {
        bar_code: string
        extra: string
        goods_id: string
        id: string
        out_goods_id: string
        out_sku_id: string
        rank: string
        spec_item_path: string
        src: string
        status: string
        style_no: string
        unit: string
        updatetime: string
        weight: string
        price?: string
        goodsattrid?: string
        stock_num?: string
    }[]
    category_ids: (string | number)[]
    content: string
    createtime: string
    erp_goods_id: string
    erp_id: string
    erp_supplier_id: string
    goods_no: string
    id: string
    images: { id: string, src: string }[]
    is_multi_spec: string
    logo: string
    name: string
    operator: string
    rank: string
    sid: string
    spec_info_list: []
    status: string
    unit: string
    updatetime: string
    version: string
    mall_goods_id?: string
    is_participate_activity?: number
    goodsid?: string
    activity_id?: string
    is_on_sale?: boolean
    total_stock_num?: number
}

export type category = {
    children: any[]
    createtime: string
    id: string
    name: string
    pid: string
    rank: string
    sid: string
    status: string
    uid: string
    deleted?: boolean
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 0;
    background: #fff;
    & > div:first-child{
        display: flex;
    }
    ._typelist{
        display: flex;
        & > a{
            padding-top: 15px;
            cursor: pointer;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            line-height: 18px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #a3a3a3;
            transition: none;
            a:not(.active){color: #a3a3a3}
            div:first-child{
                margin-bottom: 10px;
            }
            div:last-child{
                width: 34px;
                height: 4px;
                background: #fff;
                border-radius: 2px;
            }
            &.active{
                font-size: 14px;
                font-weight: 600;
                color: var(--theme-main) ;
                div:last-child{
                    background: var(--theme-main) ;
                }
            }
        }

    }

`

const Main = styled.div`
    background-color: #fff;
    background-clip: content-box;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    & > div:first-child{
        flex: 1;
        display: flex;
        & > div{
            padding: 10px 10px 0;
            &:nth-child(1){
                & > div{
                    cursor: pointer;
                    background-color: #f5f5f5;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    width: 96px; height: 40px;
                    display: flex; align-items: center; justify-content: center;
                    .active{
                        color: var(--theme-main) ;
                        font-weight: 600;
                        background: #fff;
                    }
                }
            }
            &:nth-child(2){
                ._subCatList{
                    background-color: #f5f5f5;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    & > div{
                        cursor: pointer;
                        display: flex; align-items: center; justify-content: center;
                        width: 96px;height: 40px;
                        .active{
                            color: var(--theme-main) ;
                            font-weight: 600;
                            background: #fff;
                        }
                    }
                }
                table{
                    width: 100%;
                    thead{
                        background-color: #f5f5f5;
                        color: #333; font-size: 14px;
                        td{
                            padding: 18px 12px;
                        }
                        td:nth-child(1){width: 250px;}
                        /* td:nth-child(2){width: 350px;} */
                        /* td:last-child{width: 270px;} */
                    }
                    tbody{
                        
                    }
                }
            }
            &:nth-child(3){
                width: 125px;
                background-color: #f7f7f7;
            }
        }
    }
`

const Tips = styled.div`
    width: unset;
    color: #ff9a27;
    padding: 6px 11px;
    background: #fff3e6;
    display: inline-block;
    border-radius: 4px;
    margin: 12px 12px 0;
    align-self: flex-start;
    font-size: 14px;
    i{
        cursor: pointer;
        font-size: 14px;
        line-height: 1;
        margin-right: 7px;
    }
`

const Page = styled.div<{ open: boolean }>`
    @media(min-width: 1600px){
        margin-left: ${props => props.open ? '200px' : 0};
    }
`

const PageWrap = styled.div`
    position: fixed;
    width: calc(100vw - ${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px);
    height: ${PAGINATION_HEIGHT}px;
    left: calc( ${SIDEBAR_WIDTH + INDEX_CONTENT_LIST_WIDTH}px);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px 0px rgba(215,215,215,0.50); 
    background: #fff;
`
const CtrlBTN = styled(Light)`
    width: 100%;
    margin-bottom: 12px;
`
function List(props: props) {
    const limit = 10

    const data = parseParams(props.location.search)

    const [type, setType] = useState(Number(props.match.params.type) || 1)
    const [search, setSearch] = useState(data.search || '')
    const [Goodlist, setGoodList] = useState<good[]>()

    const [page, setPage] = useState(Number(data.page || 1) || 1)
    const [catid, setCatid] = useState<(number | string)[]>((data.cat || '0').split(','))
    const [count, setCount] = useState<number>(0)

    // 页面提示文字
    const [tips1, setTips1] = useState(true)
    const [tips2, setTips2] = useState(true)
    const [tips3, setTips3] = useState(true)

    const key = useRef<{
        page: number,
        search: string,
        catid: (number | string)[],
        type: number,
    }>()
    const [eventList, setEventlist] = useState<any[]>()

    const href = useRef(props.location.pathname + props.location.search)
    useEffect(() => {
        const type = Number(props.match.params.type)
        const data = parseParams(props.location.search)
        if ((props.location.pathname + props.location.search) === href.current) return
        href.current = props.location.pathname + props.location.search
        if (type) {
            // key.current = undefined
            setSearch(decodeURI(data.search || '') || '')
            setType(Number(type))
            setPage(Number(data.page) || 1)
            setCatid((data.cat || '0').split(',').map(i => Number(i)))
            setGoodList(undefined)
        }
    }, [props])

    useEffect(() => {
        function checkKey(): boolean {
            if (Number(page) !== Number(key.current.page)) return false
            if ((search) !== (key.current.search)) return false
            if (Number(catid[0]) !== Number(key.current.catid[0])) return false
            if (catid[1] || key.current.catid[1]) {
                if (Number(catid[1]) !== Number(key.current.catid[1])) return false
            }
            if (key.current.type !== type) return false
            return true
        }
        function formatMallGood(i) {
            return {
                ...i,
                attribute: i.sku_list,
            }
        }
        if (!Goodlist) {
            if (key.current && checkKey()) return
            key.current = {
                page: Number(page),
                search,
                catid: catid,
                type,
            }
            if (type === 1) {
                Loading.show('加载中')
                get('/warehouse/goodslist', {
                    offset: (page - 1) * limit, limit,
                    category_id: catid[catid.length - 1] || 0,
                    keyword: search,
                }).then(res => {
                    if (checkKey()) {
                        Loading.hide()
                        setGoodList(res.list)
                        setCount(Number(res.count))
                    }
                }).catch(e => { message.error(e.message); Loading.hide() })
            } else if (type === 2) {
                Loading.show('加载中')
                get('/mall/getmallgoodslist', {
                    offset: (page - 1) * limit, limit,
                    cat_id: catid[catid.length - 1] || 0,
                    search,
                    mall_id: false, relation_id: false
                }).then(res => {
                    if (checkKey()) {
                        Loading.hide()
                        setGoodList(res.goods_list.map(formatMallGood))
                        setCount(Number(res.count))
                    }
                }).catch(e => { message.error(e.message); Loading.hide() })
            } else if (type === 3) {
                if (!eventList || eventList.length === 0) return
                const event = (eventList).find(i => Number(i.id) === Number(catid[catid.length - 1]))
                if (!event) {
                    setCatid([Number(eventList[0].id)])
                    return
                }
                Loading.show('加载中')
                get('/mall/getmallgoodslist', {
                    offset: (page - 1) * limit, limit,
                    activity_id: catid[catid.length - 1] || 0,
                    search,
                    mall_id: false, relation_id: false
                }).then(res => {
                    if (checkKey()) {
                        Loading.hide()
                        setGoodList(res.goods_list.map(formatMallGood))
                        setCount(Number(res.count))
                    }
                }).catch(e => { message.error(e.message); Loading.hide() })
            }
        }
    }, [Goodlist, page, catid, search, props.match, type, eventList,])

    function reload() {
        function checkKey(): boolean {
            if (Number(page) !== Number(key.current.page)) return false
            if ((search) !== (key.current.search)) return false
            if (Number(catid[0]) !== Number(key.current.catid[0])) return false
            if (catid[1] || key.current.catid[1]) {
                if (Number(catid[1]) !== Number(key.current.catid[1])) return false
            }
            if (key.current.type !== type) return false
            return true
        }
        function formatMallGood(i) {
            return {
                ...i,
                attribute: i.sku_list,
            }
        }
        key.current = {
            page: Number(page),
            search,
            catid: catid,
            type,
        }
        console.log('get');
        if (type === 1) {
            Loading.show('加载中')
            get('/warehouse/goodslist', {
                offset: (page - 1) * limit, limit,
                category_id: catid[catid.length - 1] || 0,
                keyword: search,
            }).then(res => {
                if (checkKey()) {
                    Loading.hide()
                    setGoodList(res.list)
                    setCount(Number(res.count))
                }
            })
        } else if (type === 2) {
            Loading.show('加载中')
            get('/mall/getmallgoodslist', {
                offset: (page - 1) * limit, limit,
                cat_id: catid[catid.length - 1] || 0,
                search,
                mall_id: false, relation_id: false
            }).then(res => {
                if (checkKey()) {
                    Loading.hide()
                    setGoodList(res.goods_list.map(formatMallGood))
                    setCount(Number(res.count))
                }
            })
        } else if (type === 3) {
            console.log(eventList);
            if (!eventList || eventList.length === 0) return
            const event = (eventList).find(i => Number(i.id) === Number(catid[catid.length - 1]))
            if (!event) {
                setCatid([Number(eventList[0].id)])
                return
            }
            Loading.show('加载中')
            get('/mall/getmallgoodslist', {
                offset: (page - 1) * limit, limit,
                activity_id: catid[catid.length - 1] || 0,
                search,
                mall_id: false, relation_id: false
            }).then(res => {
                if (checkKey()) {
                    Loading.hide()
                    setGoodList(res.goods_list.map(formatMallGood))
                    setCount(Number(res.count))
                }
            })
        }
    }

    const [catlist, setCatlist] = useState<category[]>()
    const gettingCatgoryList = useRef(false)
    useEffect(() => {
        if (gettingCatgoryList.current) return
        gettingCatgoryList.current = true
        if (!catlist && props.user.self_sid) {
            get('/goodscategory/getall', { sid: props.user.self_sid }).then(res => {
                gettingCatgoryList.current = false
                setCatlist(res)
            })
        }
    }, [catlist, props.user.self_sid])

    useEffect(() => {
        get('/mall/getshopmallactivitylist', {
            offset: 0,
            limit: 200000,
        }).then(res => {
            setEventlist(res.map(i => {
                return {
                    ...i,
                    start: moment(i.start_time),
                    end: moment(i.end_time),

                }
            }))
        })
        if (eventList && type === 3 && !Goodlist) reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    useRef((() => {
        const ua = navigator.userAgent.toLowerCase();
        if ((/MicroMessenger/i).test(ua)) return undefined
        if (!window.BroadcastChannel) return
        const bc = new BroadcastChannel('goodslist')
        window.name = 'jieligo_list' + Math.random()
        bc.onmessage = ((e) => {
            switch (e.data) {
                case 'reload':
                    reload()
                    break;
                default:
                    break;
            }
        })
        return bc
    })())

    const main = useRef<HTMLDivElement>()

    const [mainHeight, setMainHeight] = useState(`calc(100vh - 50px - 60px - ${main.current?.offsetTop || 90}px - 20px)`)

    useEffect(() => {
        setMainHeight(`calc(100vh - 60px - 50px - ${main.current?.offsetTop || 90}px - 20px)`)
    }, [tips1, tips2, tips3, type])

    return <Page className='flex-column' open={props.sidebarOpen} style={{ background: '#fff', }}>
        <Header>
            <div>
                <div className='_typelist'>
                    <NavLink to={`/index/mall/goods/2`} className={type === 2 ? 'active' : ''}>
                        <div>上架中</div>
                        <div></div>
                    </NavLink>
                    {props.user.bind_mall_id && <><NavLink to={`/index/mall/goods/1`} className={type === 1 ? 'active' : ''}>
                        <div>商品信息库</div>
                        <div></div>
                    </NavLink>
                        <NavLink to={`/index/mall/goods/3`} className={type === 3 ? 'active' : ''}>
                            <div>商城活动中</div>
                            <div></div>
                        </NavLink>
                    </>}
                    {!!props.isHema && <div className='flex-center'>
                        <BTN solid color={'red'} onClick={() => {
                            changeBranch({ mall: props.mall, masterid: props.mall.pid }).then((res) => {
                                if (res) {
                                    props.saveMallInfo(undefined)
                                    props.logout()
                                    props.waitingLogin()
                                    post('/admin/checkIsLogin', {}).then(res => {
                                        post('/proxy/checkProxy', { type: 1 }).then(r => {
                                            props.setUserType(r.type)
                                        })
                                        props.login(res)
                                        props.history.push('/refresh')
                                    })
                                }
                            }).catch(e => { if (e.message) message.error(e.message) })
                        }} >切换门店</BTN>
                    </div>}
                </div>
            </div>
            <div>
                <Search inputStyle={{ fontSize: 12, }} onSearch={() => {
                    route(1, catid.join(','), search)
                }} search={search} onChange={setSearch} placeholder='搜索商品名称、编码、条码' outerStyle={{ width: '14em', padding: '4px 12px' }} />
            </div>
        </Header>
        {type === 1 && tips1 === true && <Tips>
            <i onClick={() => { setTips1(false) }} className='iconfont icon-guanbi-tianchong' />
            <span>
                商品信息库储存商品的基础信息，如详情图、规格等。商品添加到商品信息库后，即可上架到商城。
            </span>
        </Tips>}
        {type === 2 && tips2 === true && <Tips>
            <i onClick={() => { setTips2(false) }} className='iconfont icon-guanbi-tianchong' />
            <span>
                商品上架后将在商城中展示。可将上架中商品参与商城活动。对于不想在商城展示、又想保留上架信息的商品，可点击“暂停出售”。
            </span>
        </Tips>}
        {type === 3 && tips3 === true && <Tips>
            <i onClick={() => { setTips3(false) }} className='iconfont icon-guanbi-tianchong' />
            <span>
                商城中可创建限时促销活动。对于不想在商城展示、又想保留活动信息的商品，可点击“暂停出售”。
            </span>
        </Tips>}
        <Main ref={main}>
            <div>
                <div className='hiddenScrollBar' style={{ height: mainHeight, overflowY: 'auto' }}>
                    {type !== 3 && <div style={(Number(catid[0]) === 0) ? { background: '#fff', color: `var(--theme-main)` } : {}} onClick={() => {
                        route(1, '0', search)
                    }}>全部</div>}

                    {type !== 3 && (catlist || []).map(i => {
                        if (i.deleted) return null
                        return <div key={i.id} onClick={() => {
                            route(1, [i.id].join(','), search)
                        }} style={(Number(catid[0]) === Number(i.id)) ? { background: '#fff', color: `var(--theme-main)` } : {}}>
                            {i.name}
                        </div>
                    })}

                    {type !== 3 && <div >
                        <BTN color='red' onClick={() => {
                            setCategory(props.user.self_sid, (list) => {
                                setCatlist(list)
                            })
                        }} >+添加分类</BTN>
                    </div>}
                    {type === 3 && (eventList || []).map(i => {
                        return <div key={i.id} onClick={() => {
                            route(1, [i.id].join(','), search)
                        }} style={{
                            background: (Number(catid[0]) === Number(i.id)) ? '#fff' : '',
                            color: (Number(catid[0]) === Number(i.id)) ? `var(--theme-main)` : '',
                            flexDirection: "column",
                            height: 54,
                        }}>
                            <div>{i.title}</div>
                            {(() => {
                                const s: React.CSSProperties = {
                                    color: '#fff', width: 44, height: 18, borderRadius: 2, fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: "center",
                                }
                                if (Number(i.status) === 2) {
                                    return <div style={{ ...s, background: '#ccc' }} >已暂停</div>
                                }
                                switch (Number(i.activity_status)) {
                                    case 1:
                                        return <div style={{ ...s, background: '#ccc' }}>已结束</div>
                                    case 2:
                                        return <div style={{ ...s, background: `var(--theme-main)` }}>进行中</div>
                                    case 3:
                                        return <div style={{ ...s, background: '#FF9A27' }}>未开始</div>
                                    default:
                                        return null
                                }
                            })()}
                        </div>
                    })}
                    {type === 3 && <div><BTN color='red' style={{ padding: '3px 6px' }} onClick={() => {
                        createEvent(Number(props.user.bind_mall_id)).then(() => {
                            getEventlist()
                        }).catch(e => { if (e.message) message.error(e.message) })
                    }} >+创建活动</BTN></div>}
                </div>
                <div className='hiddenScrollBar' style={{ flex: 1, height: mainHeight, overflowY: 'auto' }}>
                    {type !== 4 && <div className='_subCatList' style={{
                        marginBottom: (() => {
                            if (!catlist) return 0
                            const item = catlist.find(i => Number(i.id) === Number(catid[0]))
                            if (!item) return 0
                            if (item.children.length === 0) return 0
                        })()
                    }} >
                        {(() => {
                            if (!catlist) return null
                            const item = catlist.find(i => Number(i.id) === Number(catid[0]))
                            if (!item) return null
                            if (item.children.length === 0) return null
                            return item.children.map(i => {
                                return <div key={i.id} style={(Number(catid[1]) === Number(i.id)) ? { background: '#fff', color: `var(--theme-main)` } : {}} onClick={() => {
                                    route(1, [catid[0], i.id].join(','), search)
                                }} >
                                    {i.name}
                                </div>
                            })
                        })()}

                    </div>}
                    <table>
                        <thead>
                            <tr>
                                <td style={{ padding: '6px 12px' }}>商品名</td>
                                <td style={{ padding: '6px 12px' }}>规格</td>
                                {type !== 1 && <td style={{ padding: '6px 12px' }}>价格</td>}
                                {(type === 2 || type === 3) && <td style={{ padding: '6px 12px' }}>排序权重</td>}
                            </tr>
                        </thead>
                        <tbody>
                            {(Goodlist || []).map(i => {
                                return <GoodItem type={type} isBranch={props.user.is_branch} key={i.id || i.goodsid} data={i} eventList={eventList} reload={() => {
                                    reload()
                                }} />
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='hiddenScrollBar  flex-box' style={{ height: mainHeight, overflowY: 'auto', flexDirection: 'column' }}>
                    {type === 1 && <NavLink to={`/index/mall/goods/create`} style={{ width: '100%' }} >
                        <SolidRed color='red' style={{ fontSize: 12, width: '100%', marginBottom: 12 }} solid >添加商城商品</SolidRed>
                    </NavLink>}
                    {type === 1 && <CtrlBTN color='red' style={{ fontSize: 12 }} onClick={() => {
                        importGoodData().then(res => {
                            if (res) reload()
                        })
                    }}  >批量导入商品</CtrlBTN>}
                    {type === 1 && <CtrlBTN color='red' style={{ fontSize: 12 }} onClick={() => {
                        uploadimage().then(res => {
                            if (res) reload()
                        })
                    }}  >批量上传图片</CtrlBTN>}
                    {type === 1 && <CtrlBTN color='red' style={{ fontSize: 12 }} onClick={() => {
                        window.open(getUrl('/warehouse/exportgoods') + `?types=1&token=${getToken()}`, '_blank')
                    }}  >导出商品信息</CtrlBTN>}
                    {type === 2 && <SolidRed color='red' style={{ fontSize: 12, width: '100%', marginBottom: 12 }} onClick={() => {
                        simpleCreateGood().then(res => {
                            if (res) reload()
                        })
                    }} solid >上架商品</SolidRed>}
                    {type === 2 && <CtrlBTN color='red' style={{ fontSize: 12 }} onClick={() => {
                        importGoodToMall().then(res => {
                            if (res) reload()
                        })
                    }} >批量上架</CtrlBTN>}
                    {type === 2 && <CtrlBTN color='red' style={{ fontSize: 12 }} onClick={() => {
                        exportSalesData()
                    }} >导出售卖数据</CtrlBTN>}
                    {type === 3 && <CtrlBTN color='red' style={{ fontSize: 12 }} onClick={() => {
                        eventlist(props.user.self_sid, Number(props.user.bind_mall_id)).then(getEventlist).catch(getEventlist)
                    }} >
                        管理活动</CtrlBTN>}
                </div>
            </div>
            <PageWrap>
                <div style={{ flex: 1, marginLeft: -100 }} className='flex-center'>
                    <Pagination current={page} pageSize={limit} showSizeChanger={false} total={count} onChange={e => {
                        route(e, catid.join(','), search)
                    }} />
                </div>
            </PageWrap>
        </Main>
    </Page>

    function route(page: number, cat: string, search: string,) {
        props.history.push(`${props.location.pathname}?page=${page || 1}&cat=${cat}&search=${search}`)
    }

    function getEventlist() {
        get('/mall/getshopmallactivitylist', {
            offset: 0,
            limit: 200000,
        }).then(res => {
            const list = res.map(i => {
                return {
                    ...i,
                    start: moment(i.start_time),
                    end: moment(i.end_time),
                }
            })
            route(1, (list[0] || { id: '' }).id + '', search)
            setEventlist(list)
        })
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.login_user_data,
        is_Login: state.is_Login,
        mall: state.mallInfo,
        memberInfo: state.member_info,
        isHema: state.isHema,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveMallInfo: (data) => {
            dispatch(saveMallInfo(data))
        },
        logout: () => {
            dispatch(logout())
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        waitingLogin: () => {
            dispatch(waitingLogin(false))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)((List))