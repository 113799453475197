
import React, { Component } from 'react'

import { connect } from 'react-redux'

import BanUnLogin from '../../high-component/BanUnLogin'
import Main from '../../high-component/Content'
import Select from '../total/Select_.tsx';
import Modal from '../total/Modal';
import Textarea from '../total/Input/Textarea.tsx';
import Images from '../total/DraggableImgs';
import styled from 'styled-components';
import { appname } from '../../data/options';
import { post, get } from '../../netApi/fetch';
import { message } from 'antd';
import { showLoading } from '../../reducers/index';
import { confirm } from '../Method/Confirm';
import { Link } from 'react-router-dom';
import BTN from '../../StyleComponents/Button';

const FareType = styled.div`
    padding: 4px 11px;
    border: 1px solid transparent;
    position: relative;
    background: #eee;
    margin-bottom: 12px;
    margin-right: 12px;
    &.active{
        background: #fff;
        border-color: var(--theme-main);
        color: var(--theme-main);
        i{
            color: var(--theme-main);
        }
    }
    i{
        color: transparent;
        position: absolute;
        bottom: -1px;
        right: -2px;
        line-height: 1;
    }
`
const Batch = styled.div`
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 20px;
    background: #f8f8f8;
    overflow-y: hidden;
    transition: all .3s;
    .flex-box{
        margin-bottom: 12px;
    }
    .title{
        width: 7em;
    }
`
const SKU = styled(Batch)`
    background: #fff;
    border: 1px solid #cecece;
    position: relative;
    padding: 20px;
`
const Input = styled.input`
        border: 1px solid #ccc;
        font-size: 12px;
        padding: 4px 11px;
        width: 200px;
        border-radius: 4px;
        margin-right: 12px;
`
class Edit extends Component {

    constructor(p) {
        super()
        this.state = {
            type: p.match.params.type,
            id: p.match.params.id,
            good: {
                name: '',
                content: '',
                catid: 0,
                imageids: [],
                images: [],
                goodsattrs: [
                    {
                        unit: '',
                        quantity: '',
                        isunlimited: 1,
                        price: '',
                        style_no: '',
                        original_price: '',
                        inputPrice: '',
                    },
                ],
                goods_spec_info_list: []
            },
            catelist: [],

            // 多重规格
            multiAttr: false,
            batchPrice: '',
            batchQuantity: '',
            batchOriginalPrice: '',
        }
        this.images = React.createRef()
        // window.addEventListener('message', (e)=>{
        //     console.log(e)
        // })
    }

    baseSpecID = 1
    baseSpecItemID = 1

    componentDidUpdate(prev) {
        if (!prev.is_Login && this.props.is_Login) {
            this.initData()
        }
    }

    componentDidMount() {
        if (this.props.is_Login) {
            this.initData()
        }
        if (!window.BroadcastChannel) return
        if (BroadcastChannel) {
            this.bc = new BroadcastChannel('goodslist')
            this.bc.onmessage = ((e) => {
                console.log('========');
            })
        }
    }

    render() {
        return <div className='goodedit'>
            <div className='goodedit__top fbc'>
                <Link className='goodedit__top__back cur-pointer' to={'/goods'}>
                    <i className='iconfont icon-right'></i>
                </Link>
                <div className='goodedit__top__title'>
                    {this.state.type === 'edit' ? '修改商品' : '创建商品'}
                </div>
                <div className=''>
                    <button className='button_solid_red' onClick={this.submit.bind(this)}>确 认</button>
                </div>
            </div>

            <div className='goodedit__content'>
                <div className='goodedit__panel'>
                    <div className='goodedit__panel__input fbc'>
                        <div className='goodedit__panel__input__name'>
                            商品名称
                        </div>
                        <div className='goodedit__panel__input__value'>
                            <input type='text' placeholder='请输入商品名称' onChange={(e) => {
                                const good = this.state.good
                                good.name = e.target.value
                                this.setState({ good })
                            }} value={this.state.good.name} disabled={this.props.isHema} />
                        </div>
                    </div>
                    <div className='goodedit__panel__input fbc' style={{ alignItems: 'flex-start' }}>
                        <div className='goodedit__panel__input__name'>
                            商品图片
                        </div>
                        <div className='goodedit__panel__input__value'>
                            <Images ref={this.images} width={90} height={90} disable={this.props.isHema} images={this.state.good.images} onChange={(e) => {
                                this.setState({ good: { ...this.state.good, images: e } })
                            }} />
                        </div>
                    </div>
                </div>

                {this.state.initComplete ? (
                    <div className='goodedit__panel'>
                        <div className='goodedit__panel__input fcc'>
                            <div className='goodedit__panel__input__name'>
                                商品分类
                            </div>
                            <div className='goodedit__panel__input__value'>
                                <Select onChange={(e, i) => {
                                    const good = this.state.good
                                    good.catid = e.id
                                    this.setState({ good })
                                }} uni='category' list={this.state.catelist}
                                    hovorClass='select-hover' value={(() => {
                                        for (let i = 0; i < this.state.catelist.length; i++) {
                                            const id = this.state.catelist[i].id
                                            if (id === this.state.good.catid) return i
                                        }
                                    })()} showKey='name' maxHeight={300} placeholder='请选择分类' />
                            </div>
                        </div>
                    </div>
                ) : ''}

                {this.state.type !== 'edit' ? <div className='flex-box'>
                    <FareType onClick={() => { this.setState({ multiAttr: false }) }} className={'cur-pointer ' + (!this.state.multiAttr ? 'active' : '')}>
                        单规格
                        <i className='iconfont icon-xuanzhong1' />
                    </FareType>
                    <FareType onClick={() => { this.setState({ multiAttr: true }) }} className={'cur-pointer ' + (this.state.multiAttr ? 'active' : '')}>
                        双规格
                        <i className='iconfont icon-xuanzhong1' />
                    </FareType>
                </div> : null}
                {this.props.isHema && <div style={{marginBottom: 30, color: '#ff5060', fontSize: 12,}}>
                    *接力GO后台不允许修改商品信息，仅限盒马后台修改    
                </div>}
                {this.state.good && !this.state.multiAttr && this.state.good.goodsattrs && this.state.good.goodsattrs.map((attr, index) => {
                    return (
                        <div className='goodedit__panel' key={index} >
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    规格{index + 1}
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='text' placeholder='如件/瓶/箱/个/kg等' onChange={(e) => {
                                        attr.unit = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema} value={attr.unit} />
                                </div>
                                {(this.state.good.goodsattrs.length > 1 && !this.props.isHema) ? (<div style={{ paddingLeft: 20 }}><i onClick={() => {
                                    if (!this.state.good.isdistribution || Number(this.state.good.isdistribution) !== 1) {
                                        this.setState({
                                            good: {
                                                ...this.state.good,
                                                goodsattrs: [
                                                    ...this.state.good.goodsattrs.slice(0, index),
                                                    ...this.state.good.goodsattrs.slice(index + 1),
                                                ]
                                            }
                                        })
                                    } else {
                                        alert('开启了好友帮卖的时候不允许删除规格')
                                    }
                                }} style={{ lineHeight: 1, fontSize: 20, color: '#666' }} className='iconfont icon-guanbi-tianchong'></i></div>) : ''}
                            </div>
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    对外团购价
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='number' max='9999' placeholder='请输入金额' onBlur={e => {
                                        attr.inputPrice = Number(e.target.value)
                                        if (isNaN(attr.inputPrice)) {
                                            attr.inputPrice = 0
                                        }
                                        attr.price = attr.inputPrice * 100
                                        this.setState({ good: this.state.good })
                                    }} onChange={(e) => {
                                        attr.price = e.target.value * 100
                                        attr.inputPrice = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema}
                                        value={attr.inputPrice !== undefined ? attr.inputPrice : (attr.price / 100) > 0 ? (attr.price / 100).toFixed(2) : ''} />
                                </div>
                            </div>
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    库存
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='number' max='9999' placeholder='不填则不限数量' min='0' step={100} onChange={(e) => {
                                        attr.quantity = e.target.value
                                        if (e.target.value !== '') {
                                            attr.isunlimited = false
                                        } else {
                                            attr.isunlimited = true
                                        }
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema} value={(Number(attr.quantity) === 0 && Number(attr.isunlimited) === 1) ? '' : attr.quantity} />
                                </div>
                            </div>
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    原价
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='number' max='9999' placeholder='请输入商品原价(选填)' onChange={(e) => {
                                        attr.original_price = e.target.value * 100
                                        attr.inputOriPri = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema} value={attr.inputOriPri !== undefined ? attr.inputOriPri : attr.original_price > 0 ? ((attr.original_price / 100).toFixed(2)) : ''} />
                                </div>
                            </div>
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    成本价
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='number' max='9999' placeholder='请输入商品原价(选填)' onChange={(e) => {
                                        attr.cost_price = (e.target.value * 100).toFixed(0)
                                        attr.inputCost = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema} value={attr.inputCost !== undefined ? attr.inputCost : attr.cost_price > 0 ? ((attr.cost_price / 100).toFixed(2)) : ''} />
                                </div>
                            </div>
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    商品编码
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='text' placeholder='请输入商品编码(选填)' onChange={(e) => {
                                        attr.style_no = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema} value={attr.style_no} />
                                </div>
                            </div>
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    商品重量
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='text' placeholder='请输入商品重量(单位：克)' onChange={(e) => {
                                        attr.weight = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} disabled={this.props.isHema} value={attr.weight || ''} />
                                </div>
                                <div className='flex-center' style={{ width: '2em' }}>g</div>
                            </div>
                            {(index === this.state.good.goodsattrs.length - 1) ? (
                                <div className='fcc'>
                                    <BTN color='red' style={{}} onClick={() => {
                                        if (this.props.isHema) {
                                            message.warning('接力GO后台不允许修改商品信息，仅限盒马后台修改')
                                            return
                                        }
                                        this.setState({
                                            good: {
                                                ...this.state.good,
                                                goodsattrs: [
                                                    ...this.state.good.goodsattrs,
                                                    {
                                                        unit: '',
                                                        quantity: '',
                                                        isunlimited: 1,
                                                        price: '',
                                                        style_no: '',
                                                        original_price: '',
                                                        weight: '',
                                                    },
                                                ]
                                            }
                                        })
                                    }}>+ 添加规格</BTN>
                                </div>
                            ) : ''}
                        </div>
                    )
                })}

                {this.state.good && this.state.multiAttr ? <div>
                    {this.state.good.goods_spec_info_list.map((attr, index) => {
                        return <div key={index} className='goodedit__panel' >
                            <div className='goodedit__panel__input fcc'>
                                <div className='goodedit__panel__input__name'>
                                    规格名称{index + 1}
                                </div>
                                <div className='goodedit__panel__input__value'>
                                    <input type='text' placeholder='如颜色' onChange={(e) => {
                                        attr.name = e.target.value
                                        this.setState({ good: this.state.good })
                                    }} value={attr.name} />
                                </div>
                            </div>
                            {attr.spec_item_list.map(item => {
                                return <div key={item.spec_item_front_id} className='goodedit__panel__input fcc'>
                                    <div className='goodedit__panel__input__name'>
                                        规格
                                    </div>
                                    <div className='goodedit__panel__input__value'>
                                        <input type='text' placeholder='如白色' onChange={(e) => {
                                            item.name = e.target.value
                                            this.setState({ good: this.state.good })
                                        }} value={item.name} maxLength={10} />
                                        <i className='iconfont icon-guanbi-tianchong cur-pointer' style={{ display: attr.spec_item_list.length === 1 ? 'none' : '' }}
                                            onClick={() => {
                                                attr.spec_item_list = attr.spec_item_list.filter(i => i.spec_item_front_id !== item.spec_item_front_id)
                                                this.setState({ good: this.state.good })
                                            }} />
                                    </div>
                                </div>
                            })}
                            <div className='flex-center' style={{ color: 'var(--theme-main)', position: "relative" }}>
                                <span className=' cur-pointer' onClick={() => {
                                    attr.spec_item_list.push({
                                        name: '',
                                        spec_item_front_id: this.baseSpecItemID + 1
                                    })
                                    this.baseSpecItemID++
                                    this.setState({
                                        good: this.state.good
                                    })
                                }}>+添加规格</span>
                                {this.state.good.goods_spec_info_list.length > 1
                                    ? <span className='cur-pointer' style={{ color: 'var(--theme-main)', position: "absolute", right: 0 }} onClick={() => {
                                        this.setState({ good: { ...this.state.good, goods_spec_info_list: this.state.good.goods_spec_info_list.filter(i => i.spec_front_id !== attr.spec_front_id) } })
                                    }} >删除</span>
                                    : null}
                            </div>
                        </div>
                    })}
                </div> : null}

                {this.state.multiAttr ? <div className='flex-center' style={{ marginBottom: 30 }}>
                    {this.state.good.goods_spec_info_list.length < 2 ? <button className='button_solid_red' style={{ marginRight: 12 }} onClick={() => {
                        const { good } = this.state
                        good.goods_spec_info_list.push({
                            spec_front_id: this.baseSpecID + 1,
                            name: '',
                            spec_item_list: [
                                { spec_item_front_id: this.baseSpecItemID + 1, name: '' }
                            ]
                        })
                        this.baseSpecID++
                        this.baseSpecItemID++
                        this.setState({ good })
                    }}>添加规格</button> : null}
                    <button className='button_solid_red' onClick={this.showSkuSetting.bind(this)}>填写价格</button>
                </div> : null}

                <div className='goodedit__panel' >
                    <div className='goodedit__panel__input fbc'>
                        <div className='goodedit__panel__input__name'>
                            商品简述
                        </div>
                        <div className='goodedit__panel__input__value'>
                        </div>
                    </div>
                    <Textarea disable={!!this.props.isHema} value={this.state.good.content} onChange={e => {
                        this.setState({
                            good: {
                                ...this.state.good,
                                content: e,
                            }
                        })
                    }} />
                </div>
            </div>
            {this.state.showSkulist && this.state.showSkuSetting ? <Modal title='设置价格与库存' confirmText='保存设置' onConfirm={this.saveSkuSetting.bind(this)} onClose={() => { this.setState({ showSkuSetting: false }) }} >
                <div style={{ width: 600, maxWidth: '100%', padding: '0 12px 12px', height: '50vh', overflowY: 'auto' }}>
                    <div className='cur-pointer' style={{ color: 'var(--theme-main)', fontWeight: 600, fontSize: 14 }} onClick={() => { this.setState({ showBatchPrice: true }) }} >批量设置价格和库存</div>
                    <Batch style={{ maxHeight: this.state.showBatchPrice ? 200 : 0, padding: this.state.showBatchPrice ? 20 : '0 20px', }}>
                        <div className='flex-box'>
                            <div className='title'>对外团购价</div>
                            <div>
                                <Input type='number' max='9999' value={this.state.batchPrice} onBlur={() => {
                                    this.setState({ batchPrice: (Number(this.state.batchPrice) || 0).toFixed(2) })
                                }} onChange={e => this.setState({ batchPrice: e.target.value })} placeholder='请输入金额' />
                            </div>
                            <div>元</div>
                        </div>
                        <div className='flex-box'>
                            <div className='title'>剩余库存</div>
                            <div>
                                <Input type='number' max='9999' value={this.state.batchQuantity} onBlur={() => {
                                    this.setState({ batchQuantity: this.state.batchQuantity === '' ? '' : (Number(this.state.batchQuantity) || 0).toFixed(0) })
                                }} onChange={e => this.setState({ batchQuantity: e.target.value })} placeholder='不填则不限数量' />
                            </div>
                        </div>
                        <div className='flex-box'>
                            <div className='title'>原价</div>
                            <div>
                                <Input type='number' max='9999' value={this.state.batchOriginalPrice} onBlur={() => {
                                    this.setState({ batchOriginalPrice: (Number(this.state.batchOriginalPrice) || 0).toFixed(2) })
                                }} onChange={e => this.setState({ batchOriginalPrice: e.target.value })} placeholder='请输入金额，没有可不填' />
                            </div>
                            <div>元</div>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <span style={{ marginLeft: 20, color: '#999' }} className='cur-pointer' onClick={() => { this.setState({ showBatchPrice: false }) }} >取消</span>
                            <span style={{ marginLeft: 20, color: 'var(--theme-main)' }} className='cur-pointer' onClick={this.batchSku.bind(this)} >确定</span>
                        </div>
                    </Batch>
                    {this.state.showSkulist.map(i => {
                        return <SKU key={i.key}>
                            {i.spec_list.map(spec => {
                                return <div key={spec.spec.spec_front_id} className='flex-box'>
                                    <div className='title'>{spec.spec.name}</div>
                                    <div>{spec.specItem.name}</div>
                                </div>
                            })}
                            <div className='flex-box'>
                                <div className='title'>对外团购价</div>
                                <div>
                                    <Input type='number' max='9999' value={i.price} onChange={e => {
                                        i.price = e.target.value
                                        this.setState({ showSkulist: this.state.showSkulist })
                                    }} onBlur={() => {
                                        i.price = (Number(i.price) || 0).toFixed(2)
                                        this.setState({ showSkulist: this.state.showSkulist })
                                    }} placeholder='请输入金额' />
                                </div>
                                <div>元</div>
                            </div>
                            <div className='flex-box'>
                                <div className='title'>剩余库存</div>
                                <div>
                                    <Input type='number' max='9999' value={i.isunlimited ? '' : i.quantity} onChange={e => {
                                        i.quantity = e.target.value
                                        i.isunlimited = false
                                        this.setState({ showSkulist: this.state.showSkulist })
                                    }} onBlur={() => {
                                        if (i.quantity === '') i.isunlimited = true
                                        i.quantity = Math.round(Number(i.quantity) || 0)
                                        this.setState({ showSkulist: this.state.showSkulist })
                                    }} placeholder='不填则不限数量' />
                                </div>
                            </div>
                            {i.showMore ? <div>
                                <div className='flex-box'>
                                    <div className='title'>原价</div>
                                    <div>
                                        <Input type='number' max='9999' value={i.original_price} onChange={e => {
                                            i.original_price = e.target.value
                                            this.setState({ showSkulist: this.state.showSkulist })
                                        }} onBlur={() => {
                                            i.original_price = (Number(i.original_price) || 0).toFixed(2)
                                            this.setState({ showSkulist: this.state.showSkulist })
                                        }} placeholder='请输入金额' />
                                    </div>
                                    <div>元</div>
                                </div>
                                <div className='flex-box'>
                                    <div className='title'>成本价</div>
                                    <div>
                                        <Input type='number' max='9999' value={i.cost_price} onChange={e => {
                                            i.cost_price = e.target.value
                                            this.setState({ showSkulist: this.state.showSkulist })
                                        }} onBlur={() => {
                                            i.cost_price = (Number(i.cost_price) || 0).toFixed(2)
                                            this.setState({ showSkulist: this.state.showSkulist })
                                        }} placeholder='请输入金额' />
                                    </div>
                                    <div>元</div>
                                </div>
                                <div className='flex-box'>
                                    <div className='title'>商品编码</div>
                                    <div>
                                        <Input type='text' value={i.style_no} onChange={e => {
                                            i.style_no = e.target.value
                                            this.setState({ showSkulist: this.state.showSkulist })
                                        }} placeholder='不填则不限数量' />
                                    </div>
                                </div>
                                <div className='flex-box'>
                                    <div className='title'>商品重量</div>
                                    <div>
                                        <Input type='number' value={i.weight || ''} onChange={e => {
                                            i.weight = e.target.value
                                            this.setState({ showSkulist: this.state.showSkulist })
                                        }} placeholder='单位（克）' />
                                    </div>
                                    <div>克</div>
                                </div>
                            </div> : null}
                            {!i.showMore ? <div className='cur-pointer' style={{ color: 'var(--theme-main)', fontWeight: 600, fontSize: 14 }} onClick={() => {
                                i.showMore = true
                                this.setState({ showSkulist: this.state.showSkulist })
                            }} >更多设置</div> : null}
                        </SKU>
                    })}
                </div>
            </Modal> : null}
        </div>
    }

    initData() {
        if (!this.props.location.state) {
            get('/goodscategory/getall', { sid: window.btoa(this.props.user.self_sid), types: 1 }).then(res => {
                this.setState({ catelist: res })
            })
        } else {
            this.setState({
                catelist: this.props.location.state.cate
            })
        }
        if (this.state.type !== 'edit') {
            const good = {
                name: '',
                content: '',
                sid: this.props.user.self_sid,
                catid: 0,
                imageids: [],
                images: [],
                goodsattrs: [
                    {
                        unit: '',
                        quantity: '',
                        isunlimited: 1,
                        price: '',
                        style_no: '',
                        original_price: '',
                    },
                ],
                goods_spec_info_list: [{
                    spec_front_id: this.baseSpecID + 1,
                    name: '',
                    spec_item_list: [
                        { spec_item_front_id: this.baseSpecItemID + 1, name: '' }
                    ]
                }]
            }
            this.baseSpecID++
            this.baseSpecItemID++
            this.setState({ good, initComplete: true })
        } else {
            this.getGoodData()
        }
    }

    getGoodData() {
        get('/goods/view', {
            types: 1,
            goodsid: window.atob(this.state.id)
        }).then(data => {
            const good = data
            good.images = good.images.map((i, index) => {
                return {
                    ...i,
                    rank: index + 1
                }
            })
            const Good = {
                ...good,
                goodsattrs: good.attribute,
                catid: good.categorylist[0] || 0
            }
            if (Boolean(Number(good.is_multistagele_spec))) {
                Good.goods_spec_info_list = Good.goods_spec_info_list.map(attr => {
                    return {
                        ...attr,
                        spec_front_id: Number(attr.spec_id),
                        spec_id: Number(attr.spec_id),
                        spec_item_list: attr.spec_item_list.map(item => {
                            return {
                                ...item,
                                spec_item_front_id: Number(item.spec_item_id),
                                spec_item_id: Number(item.spec_item_id),
                            }
                        })
                    }
                })
            }
            this.setState({
                good: Good,
                initComplete: true,
                multiAttr: Boolean(Number(good.is_multistagele_spec))
            })
            if (Boolean(Number(good.is_multistagele_spec))) {
                this.baseSpecID = Math.max(...good.goods_spec_info_list.map(attr => {
                    return Number(attr.spec_id)
                }))
                this.baseSpecItemID = Math.max(...good.goods_spec_info_list.map(attr => {
                    return Math.max(...attr.spec_item_list.map(item => {
                        return Number(item.spec_item_id)
                    }))
                }))
                const SpecMap = new Map()
                for (let i = 0; i < Good.goods_spec_info_list.length; i++) {
                    const spec = Good.goods_spec_info_list[i];
                    SpecMap.set(spec.spec_id, spec)
                }
                const skulist = Good.attribute.map(attr => {
                    const item = {}
                    item.price = Number(attr.price)
                    item.quantity = Number(attr.quantity) || ''
                    item.isunlimited = Boolean(Number(attr.isunlimited))
                    item.style_no = attr.style_no
                    item.original_price = Number(attr.original_price) || ''
                    item.cost_price = Number(attr.cost_price) || ''
                    item.skuid = attr.id
                    item.weight = attr.weight
                    item.spec_list = attr.spec_info_list.map(spec => {
                        const specData = SpecMap.get(Number(spec.spec_id))
                        const data = {
                            spec: specData,
                            specItem: specData.spec_item_list.find(i => Number(i.spec_item_id) === Number(spec.spec_item_id))
                        }
                        return data
                    })
                    item.key = item.spec_list.map(i => i.specItem.spec_item_front_id).sort((a, b) => a - b).join(',')
                    return item
                })
                this.originalSKU = skulist
                this.createSku()
            }
        })
    }

    submit() {
        const good = this.state.good

        good.imageids = this.images.current.state.images.map(i => {
            return {
                id: i.id,
                rank: i.rank
            }
        })
        if (!good.name || good.name === '') {
            message.warning('请填写商品名称')
            return
        }
        if (good.imageids.length === 0) {
            message.warning('请选择商品图片')
            return
        }

        good.categorylist = [good.catid]

        if (!this.state.multiAttr) {
            for (let i = 0; i < good.goodsattrs.length; i++) {
                const attr = good.goodsattrs[i];
                if (!attr.unit || attr.unit === '') {
                    alert(`规格${i + 1}未填写规格`)
                    return
                }
                if (attr.price <= 0) {
                    alert(`规格${i + 1}未填写价格`)
                    return
                }
            }
            good.goodsattrs = good.goodsattrs.map(i => {
                return {
                    ...i,
                    original_price: Number(i.original_price).toFixed(0),
                    buy_price: 0,
                    id: i.id || '',
                    inputOriPri: undefined,
                    inputPrice: undefined,
                    quantity: Number(i.quantity),
                    price: Number(i.price).toFixed(0),
                    weight: Number(i.weight).toFixed(0),
                }
            })
        }

        if (this.state.multiAttr) {
            if (!this.checkSku()) return
            good.goods_info = {
                spec_info_list: good.goods_spec_info_list.map((spec, index) => {
                    const data = { ...spec }
                    data.spec_name = data.name
                    data.rank = index
                    data.spec_item_list = data.spec_item_list.map((i, index) => {
                        return {
                            ...i,
                            rank: index,
                        }
                    })
                    delete data.goodsid
                    delete data.name
                    return data
                }),
                sku_list: this.state.skulist.map((i, index) => {
                    const data = { ...i }
                    delete data.key
                    delete data.showMore
                    data.price = (Number(data.price) * 100).toFixed(0)
                    data.cost_price = (Number(data.cost_price) * 100).toFixed(0)
                    data.quantity = (Number(data.quantity)).toFixed(0)
                    data.original_price = (Number(data.original_price) * 100).toFixed(0)
                    data.weight = (Number(data.weight)).toFixed(0)
                    data.spec_list = data.spec_list.map(spec => {
                        return {
                            spec_id: spec.spec.spec_id,
                            spec_item_id: spec.specItem.spec_item_id,
                            spec_front_id: spec.spec.spec_front_id,
                            spec_item_front_id: spec.specItem.spec_item_front_id
                        }
                    })
                    return data
                })
            }
            good.is_multistagele_spec = true
            good.goodsattrs = []
            delete good.attribute
        }
        this.props.showLoading()
        post(`/goods/${this.state.type === 'edit' ? 'update' : 'create'}?app=${appname}`, {
            ...good,
            types: 1
        }).then(res => {
            this.bc && this.bc.postMessage('reload')
            this.props.hideLoading()
            confirm({
                title: '',
                text: <div className='flex-center' style={{ flexDirection: 'column' }}>
                    <i className='iconfont icon-gou' style={{ lineHeight: 1, color: 'var(--theme-main)', fontSize: 24 }} />
                    <div style={{ fontSize: 16, marginTop: 16, fontWeight: 600 }}>商品保存成功！</div>
                </div>,
                confirmText: '关闭窗口并返回商品列表',
                cancelText: '留在本页',
            }).then(res => {
                if (res) {
                    if (window.opener) window.open('', window.opener.name);
                    window.close()
                }
            })
        }).catch(e => {
            let errorMessage = e?.message ? e.message : e;
            alert(errorMessage)
            this.props.hideLoading()
        })
    }

    createSku() {
        if (!this.checkSpec()) return
        const speclist = this.state.good.goods_spec_info_list
        const length = speclist.reduce((prev, item) => prev * item.spec_item_list.length, 1)
        let list = new Array(length)
        for (let i = 0; i < speclist.length; i++) {
            const spec = speclist[i];
            const l = speclist.slice(i + 1).reduce((prev, item) => prev * item.spec_item_list.length, 1) // 步长
            for (let j = 0; j < list.length; j++) {
                if (!list[j]) list[j] = {}
                const item = list[j];
                if (!item.spec_list) item.spec_list = []
                let value = Math.floor(j / l)
                while (value >= spec.spec_item_list.length) {
                    value = value - spec.spec_item_list.length
                }
                item.spec_list.push({
                    specItem: spec.spec_item_list[value],
                    spec: spec,
                })
                item.price = ''
                item.quantity = ''
                item.isunlimited = true
                item.style_no = ''
                item.original_price = ''
                item.key = item.spec_list.map(i => i.specItem.spec_item_front_id).sort((a, b) => a - b).join(',')
                item.showMore = false
                item.weight = ''
            }
        }
        if (this.originalSKU && !this.state.skulist) {
            list = list.map(sku => {
                const ori = this.originalSKU.find(i => i.key === sku.key)
                if (!ori) return sku
                const data = { ...sku }
                data.price = (Number(ori.price) / 100).toFixed(2)
                data.original_price = (Number(ori.original_price) / 100).toFixed(2)
                data.cost_price = (Number(ori.cost_price) / 100).toFixed(2)
                data.quantity = (Number(ori.quantity))
                data.isunlimited = Boolean(Number(ori.isunlimited))
                data.style_no = ori.style_no
                data.skuid = ori.skuid
                data.weight = ori.weight
                return data
            })
        }
        if (this.state.skulist) {
            list = list.map(sku => {
                const ori = this.state.skulist.find(i => i.key === sku.key)
                if (!ori) return sku
                const data = { ...sku }
                data.price = ori.price
                data.original_price = ori.original_price
                data.quantity = ori.quantity
                data.cost_price = ori.cost_price
                data.isunlimited = ori.isunlimited
                data.style_no = ori.style_no
                data.skuid = ori.skuid
                data.showMore = ori.showMore
                data.weight = ori.weight
                return data
            })
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.skulist = list
        this.setState({
            skulist: list,
        })
        return list
    }

    showSkuSetting() {
        this.setState({
            showSkulist: this.createSku(),
            showSkuSetting: true,
        })
    }
    saveSkuSetting() {
        for (let i = 0; i < this.state.showSkulist.length; i++) {
            const sku = this.state.showSkulist[i];
            if (!Number(sku.price)) {
                message.warning('请完成价格设置')
                this.showSkuSetting()
                return false
            }
        }
        this.setState({
            skulist: this.state.showSkulist,
            showSkulist: undefined,
            showBatchPrice: false,
        }, () => {
            message.success('设置成功')
            this.createSku()
        })
    }
    batchSku() {
        this.setState({
            showSkulist: this.state.showSkulist.map(i => {
                const data = { ...i }
                if (this.state.batchPrice) {
                    data.price = this.state.batchPrice
                }
                if (this.state.batchQuantity) {
                    data.quantity = this.state.batchQuantity
                    data.isunlimited = false
                }
                if (this.state.batchOriginalPrice) {
                    data.original_price = this.state.batchOriginalPrice
                }
                return data
            })
        })
    }
    checkSpec() {
        for (let i = 0; i < this.state.good.goods_spec_info_list.length; i++) {
            const spec = this.state.good.goods_spec_info_list[i];
            if (!spec.name) {
                message.warning('请填写规格名称' + (i + 1))
                return false
            }
            for (let j = 0; j < spec.spec_item_list.length; j++) {
                const item = spec.spec_item_list[j];
                if (!item.name) {
                    message.warning('请填写规格')
                    return false
                }
            }
        }
        return true
    }
    checkSku() {
        if (!this.state.skulist) { this.createSku(); return false }
        if (!this.checkSpec()) return false
        const list = this.createSku()
        for (let i = 0; i < list.length; i++) {
            const sku = list[i];
            if (!Number(sku.price)) {
                message.warning('请完成价格设置')
                this.showSkuSetting()
                return false
            }
        }
        return true
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
        is_Login: state.is_Login,
        isHema: state.isHema,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showLoading: () => {
            dispatch(showLoading(true))
        },
        hideLoading: () => {
            dispatch(showLoading(false))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BanUnLogin(Main(Edit, false)))