import { useEffect, useRef, useState } from "react"
import React from 'react';
import Modal from "../../total/Modal";
import ReactDOM from "react-dom";
import Switch from "../../total/Switch";
import styled from "styled-components";
import { QQ_MAP_KEY } from "../../../data/options";
import { message, TimePicker } from "antd";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import Loading from "../../Method/Loading";
import imageUpload from "../../../methods/imageUpload";
import { get, post } from "../../../netApi/fetch";
import Search from '../../total/Select_';
import Textarea from "../../total/Input/Textarea";


let confirmWrap: Element

interface props {
    create?: boolean
    user: user
    mall_id?: number | string
}

interface postData {
    name?: string
    logo?: string
    desc?: string
    lng?: string | number
    lat?: string | number
    contact_name?: string
    phone?: string | number
    province?: string
    city?: string
    area?: string
    addressdetail?: string
    id?: string | number
}

interface address {
    name: string
    detail: string
}

const Border = styled.div`
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    padding: 16px;
    margin-bottom: 16px;
    `

const InputData = styled.div`
    margin-bottom: 16px;
    strong{
        display: inline-block;
        width: 7em;
    }
    & > input{
        width: 400px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 4px 11px;
    }
`

export default function (props: props) {
    const { RangePicker } = TimePicker
    return new Promise((res, rej) => {

        function Setting(p) {
            const [shouldClose, close] = useState(false) // modal关闭flag

            const [distributionCheck, setDistributionCheck] = useState(!!p.distirbution) // 分销开关
            const [commission, setCommission] = useState<{ id: number | string, value: string, name: string, num: string | number }[]>() // 分销佣金比例
            const [expressCheck, setExpressCheck] = useState(false) // dada开关
            const [contact, setContact] = useState<string>('')
            const [phone, setPhone] = useState<string>('')
            const [mallName, setMallName] = useState<string>('')
            const [branchName, setBranchName] = useState<string>('')
            const [desc, setDesc] = useState<string>('')
            const [logo, setLogo] = useState<string>(props.user.avatar)
            const [km, setKM] = useState<string>('')
            const [addressData, setAddressData] = useState<address>({ name: '', detail: '' })
            const [businessType, setBusinessType] = useState<number>()
            const [businessTypeList, setBusinessTypeList] = useState<{ name: string, id: string }[]>()
            const [time, setTime] = useState<{
                start: moment.Moment
                end: moment.Moment
            }>()

            const poidata = useRef<any>()
            const sourceData = useRef<any>()
            const businessID = useRef<string>()
            const wrapper = useRef<HTMLDivElement>(null)

            useEffect(() => {
                if (!businessTypeList) {
                    get('/dispatch/businesslist', {}).then(res => {
                        setBusinessTypeList(res.list)
                    })
                }
            }, [businessTypeList])

            useEffect(() => {
                if (props.create && !commission) {
                    get('/seller/getteamlist', {
                        offset: 0, limit: 99,
                    }).then(res => {
                        const list = res.list.map(i => {
                            return {
                                name: i.name,
                                id: i.id,
                                value: '',
                            }
                        })
                        setCommission([
                            {
                                name: '默认分组',
                                id: 0,
                                value: '',
                            },
                            ...list
                        ])
                    })
                }
            }, [commission])

            useEffect(() => {
                if (!sourceData.current && !props.create) {
                    const data: { mall_id?: string | number } = {}
                    if (props.mall_id) data.mall_id = props.mall_id
                    get('/mall/getmallinfo', data).then(res => {
                        sourceData.current = res
                        setDistributionCheck(Boolean(Number(res.is_distribution)))
                        // setCommission()
                        poidata.current = {
                            latlng: {
                                lat: res.lat,
                                lng: res.lng,
                            }
                        }
                        if (res.dispatchShops[0] && res.dispatchShops[0].delivery_time) {
                            setTime({
                                start: moment(res.dispatchShops[0].delivery_time[0].start_time, moment.HTML5_FMT.TIME_SECONDS),
                                end: moment(res.dispatchShops[0].delivery_time[0].end_time, moment.HTML5_FMT.TIME_SECONDS),
                            })
                            businessID.current = res.dispatchShops[0].business_id

                            setKM((res.dispatchShops[0].distance))

                        }
                        if (res.pid > 0) setBranchName(res.branch_name)
                        setContact(res.contact_name)
                        setPhone(res.phone)
                        setMallName(res.name)
                        setLogo(res.logo)
                        setAddressData({
                            detail: res.addressdetail,
                            name: res.address,
                        })
                        setDesc(res.desc)
                        setExpressCheck(res.is_open_dispatch)
                        if (res.distribution_commission_list.length > 0) {
                            const list = res.distribution_commission_list.map(i => {
                                return {
                                    name: i.group_name,
                                    id: i.mall_distribution_group_id,
                                    value: i.total_commission_percent,
                                }
                            })
                            for (let i = 0; i < res.seller_team.length; i++) {
                                const item = res.seller_team[i];
                                if (list.find(j => Number(item.id) === Number(j.id))) continue
                                list.push({ name: item.name, id: item.id, value: '' })
                            }
                            setCommission(list)
                        } else {
                            const list = res.seller_team.map(i => {
                                return {
                                    name: i.name,
                                    id: i.id,
                                    value: '',
                                }
                            })
                            setCommission([
                                ...list
                            ])
                        }
                    })
                }
            }, [sourceData])


            if (businessID.current && businessTypeList && (businessType === undefined)) {
                setBusinessType(businessTypeList.findIndex(i => String(i.id) === String(businessID.current)))
            }

            const isBranch = sourceData.current && Number(sourceData.current.pid) > 0

            let typetext = isBranch ? '门店' : '商城'

            if (props.create && !isBranch) typetext = '发布商城'
            return <Modal title={`${typetext}设置`} shouldClose={shouldClose} cancel onConfirm={confirm} onClose={reject} confirmText={(props.create && !isBranch) ? '发布' : '确认'} >
                <div ref={wrapper} style={{ width: 600, height: 450, maxHeight: 'inherit', overflowY: 'auto' }}>
                    <Border>
                        <div className='flex-box' style={{ marginBottom: 16, }}>
                            <img alt='' className='img-center' src={logo} style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 16, }} />
                            <span className='cur-pointer' style={{ color: 'var(--theme-main)', }} onClick={changeLogo} >修改</span>
                        </div>
                        {isBranch
                            ? <InputData>
                                <strong>{mallName}</strong>
                                <input placeholder={`请输入${typetext}名称`} type='string' value={branchName} onChange={e => { setBranchName(e.target.value) }} />
                            </InputData>
                            : <InputData>
                                <strong>{typetext}名称</strong>
                                <input placeholder={`请输入${typetext}名称`} type='string' value={mallName} onChange={e => { setMallName(e.target.value) }} />
                            </InputData>
                        }

                        <InputData style={{ display: 'flex' }}>
                            <strong>{typetext}简介</strong>
                            <div style={{ width: 400 }}>
                                <Textarea placeholder={`请输入${typetext}简介`} style={{ border: '1px solid rgba(0,0,0,0.15)' }} value={desc} onChange={setDesc} />
                            </div>
                        </InputData>
                        <InputData>
                            <div>温馨提示：</div>
                            <div>{typetext}地址可作为{typetext}支持自提的商品的自提点地址</div>
                        </InputData>
                        <InputData>
                            <strong>{typetext}地址</strong>
                            <input placeholder='请选择地址' type='string' onClick={selectPoint} value={addressData.name} onChange={() => false} />
                        </InputData>
                        <InputData>
                            <strong>详细地址</strong>
                            <input placeholder='例：3号楼6层602（必填）' type='string' value={addressData.detail} onChange={e => { setAddressData({ ...addressData, detail: e.target.value }) }} />
                        </InputData>
                        <InputData>
                            <strong>联系人</strong>
                            <input placeholder='请填写联系人（必填）' type='string' value={contact} onChange={e => { setContact(e.target.value) }} />
                        </InputData>
                        <InputData>
                            <strong>联系电话</strong>
                            <input placeholder='请填写联系方式（必填）' type='string' value={phone} onChange={e => { setPhone(e.target.value) }} />
                        </InputData>
                    </Border>
                    <Border>
                        <div className='flex-between' style={{ marginBottom: expressCheck ? 20 : 0 }}>
                            <div><b>同城骑手配送（达达配送）</b></div>
                            <Switch checked={expressCheck} onClick={setExpressCheck.bind(null, !expressCheck)} />
                        </div>
                        {expressCheck ? <>
                            <InputData className='flex-box'>
                                <strong>{typetext}业务类型</strong>
                                <Search placeholder={`请选择${typetext}业务类型`} uni='dadaCate' list={businessTypeList || []} maxHeight={300} showKey='name' onChange={(e, i) => {
                                    setBusinessType(i)
                                }} value={businessType} />
                            </InputData>
                            <InputData>
                                <strong>骑手配送范围</strong>
                                <input placeholder='请输入配送范围公里数' type='number' value={km} onChange={e => { setKM(e.target.value) }} />
                                <span style={{ marginLeft: 20, color: '#6463a6' }}>km</span>
                            </InputData>
                            <InputData>
                                <strong>骑手配送时间</strong>
                                <RangePicker locale={locale} value={[time?.start, time?.end]} onChange={(e) => {
                                    if (!e) return
                                    setTime({
                                        start: e[0],
                                        end: e[1],
                                    })
                                }} />
                            </InputData>
                        </> : null}
                    </Border>
                    {/* <Border>
                        <div className='flex-between' style={{ marginBottom: distributionCheck ? 20 : 0 }}>
                            <div><b>让好友帮我卖</b></div>
                            <Switch checked={distributionCheck} onClick={setDistributionCheck.bind(null, !distributionCheck)} />
                        </div>
                        {distributionCheck && commission ? <>
                            {commission.map(i => {
                                return <div className='flex-between' style={{ marginBottom: 12 }} key={i.id}>
                                    <div style={{ width: '8em' }}>{i.name}</div>
                                    <div className='flex-box' style={{ flex: 1, }}>
                                        <input value={i.value} type='number' style={{ border: '1px solid rgba(0, 0, 0, 0.15)', padding: '5px 12px' }} onChange={e => {
                                            setCommission(commission.map(j => {
                                                if (j.id !== i.id) return j
                                                return {
                                                    ...i,
                                                    value: e.target.value,
                                                }
                                            }))
                                        }} onBlur={() => { setCommission(commission.map(j => { return { ...j, value: j.value === '' ? '' : Number(j.value).toFixed(2) } })) }} />
                                        <span style={{ marginLeft: 16 }}>%</span>
                                    </div>
                                </div>
                            })}
                            <div style={{ color: '#999', fontSize: 14, }}>
                                温馨提示：这里的帮卖设置是对所有的商品设置，但同时对单个商品设置了帮卖佣金，则按单个商品的设置分配佣金比例
                            </div>
                        </> : null}
                    </Border> */}

                </div>
            </Modal>


            function confirm() {

                // if (distributionCheck && commission.find(i => i.value === '' || Number(i.value) > 100 || Number(i.value) < 0)) {
                //     message.warning('请输入正确的百分比')
                //     return
                // }
                if (!poidata.current || !addressData.detail) {
                    message.warning('请选择地址')
                    return
                }
                if (!contact) {
                    message.warning('请填写联系人')
                    return
                }
                if (!phone) {
                    message.warning('请填写联系电话')
                    return
                }

                if (expressCheck) {
                    if (!businessType) {
                        message.warning('请选择业务类型')
                        return
                    }
                    if (!km) {
                        message.warning('请填写配送距离')
                        return
                    }
                    if (!time || !time.start || !time.end) {
                        message.warn('请填写配送时间')
                        return
                    }
                }

                if (sourceData.current && sourceData.current.pid > 0) {
                    if (!branchName || branchName.length === 0) {
                        message.warn('请填写门店名称')
                        return
                    }
                }

                const data = {
                    op: props.create ? 1 : 3,
                    name: mallName,
                    logo,
                    desc,
                    lng: poidata.current.latlng.lng,
                    lat: poidata.current.latlng.lat,
                    contact_name: contact,
                    phone,
                    province: '',
                    city: '',
                    area: '',
                    addressdetail: addressData.detail,
                    address: addressData.name,
                    is_distribution: distributionCheck,
                    distribution_commission_list: commission.map(i => {
                        return {
                            mall_distribution_group_id: i.id,
                            total_commission_percent: i.value,
                            commission_level_list: [
                                {
                                    commission_level: 1,
                                    commission_percent: i.value,
                                }
                            ]
                        }
                    }),
                    dispatch: expressCheck,
                    distance: km || '',
                    platform_id: 1,
                    business_id: expressCheck ? businessTypeList[businessType].id : '',
                    delivery_time: expressCheck ? [{
                        start_time: time.start.format('HH:mm:ss'),
                        end_time: time.end.format('HH:mm:ss'),
                    }] : undefined,
                    branch_name: branchName,
                    mall_id: props.mall_id,
                }
                post('/mall/setmall', data).then(() => {
                    if (props.create) {
                        message.success('创建成功')
                    } else {
                        message.success('修改成功')
                    }
                    close(true)
                    res(true)
                }).catch(e => { if (e.message) message.error(e.message) })

            }
            function reject() {
                if (confirmWrap) {
                    ReactDOM.render(null, confirmWrap)
                    document.body.removeChild(confirmWrap)
                    confirmWrap = undefined
                }
                rej()
            }

            function selectPoint() {
                const Iframe = document.createElement('iframe')
                Iframe.id = 'map'
                Iframe.width = '800px'
                Iframe.height = '400px'
                Iframe.style.cssText = 'z-index: 100; position: fixed; top: 20vh; left: calc(50vw - 200px);'
                Iframe.src = `https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=${QQ_MAP_KEY}&referer=jieligo`
                if (poidata.current && poidata.current.latlng && poidata.current.latlng.lng && poidata.current.latlng.lat) {
                    Iframe.src += `&coordtype=1&coord=${poidata.current.latlng.lat},${poidata.current.latlng.lng}`
                }
                const cb = function (event) {
                    // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
                    var loc = event.data;
                    console.log(event);
                    if (wrapper && wrapper.current && wrapper.current !== undefined) wrapper.current.removeChild(Iframe)
                    window.removeEventListener('message', cb)
                    if (loc && loc.module === 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                        console.log('location', loc);
                        poidata.current = loc
                        setAddressData({ name: loc.poiaddress, detail: '' })
                    }
                }
                window.addEventListener('message', cb, false);
                if (wrapper && wrapper.current && wrapper.current !== undefined) wrapper.current.appendChild(Iframe)
            }

            function changeLogo() {
                const lb = document.createElement('input')
                lb.type = 'file'
                lb.accept = 'image/*'
                lb.onchange = () => {
                    const logo = lb.files[0]
                    if (!logo) return
                    Loading.show('正在上传图片')
                    imageUpload(logo, 'shop').then(res => {
                        Loading.hide()
                        setLogo(res.src)
                    })
                }
                lb.click()
            }
        }

        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDOM.render(<Setting {...props} />, confirmWrap)
    })
}