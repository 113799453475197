import { OFFSET_LIMIT } from '../data/options';

// action types
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
const TOGGLE_LOGINPANEL = 'TOGGLE_LOGINPANEL'
const TOGGLE_SELECTPANEL = 'TOGGLE_SELECTPANEL'
const SET_LOGIN_USER_DATA = 'SET_LOGIN_USER_DATA'
const SETORDER = 'SETORDER'
const SET_TUANLIST = 'SET_TUANLIST'
const LOGOUT = 'LOGOUT'
const WAITINGLOGIN = 'WAITINGLOGIN'
const WX_OPEN = 'WX_OPEN'
const SET_ISINVITE = 'SET_ISINVITE'
const USER_TYPE = 'USER_TYPE'
const INIT_URL = 'INIT_URL'
const SELECT_GOOD = 'SELECT_GOOD'
const SAVE_MY_RELEASE_TUAN = 'SAVE_MY_RELEASE_TUAN'
const SAVE_MALL_INFO = 'SAVE_MALL_INFO'
const SAVE_MEMBER_INFO = 'SAVE_MEMBER_INFO'
// loading
const LOADING = 'LOADING'

const SELECT_ADDRESS = 'SELECT_ADDRESS'

const IS_HEMA = 'IS_HEMA'

const SET_TUAN_SEARCH_KEY = 'SET_TUAN_SEARCH_KEY' // 设置'/index'的团购列表的搜索关键字

const SET_LOGIN_BIND_INFO = 'SET_LOGIN_BIND_INFO' // 设置登陆绑定信息--商城、身份、权限etc.

// reducer
const initialState = {

    // 账户信息相关
    login_user_data: {},
    is_Login: false,
    waitingLogin: true,
    isWX: false,

    // 侧边栏
    sidebarOpen: true,
    sidebarType: 1,

    // 登录面板
    showLoginPanel: false,

    // 浮动选择栏
    showSelectPanel: false,

    // loading
    isLoading: false,
    loadingText: '',

    // 订单相关
    order: {
        limit: OFFSET_LIMIT,
        offset: 0,
        list: [],
        selectedTuan: {},
        option: 'all',
        search: '',
        count: -1
    },

    // 分享时兼容微信ios，进入页面刷新
    isinvite: false,
    init_url: false,

    // 用户类型 1黄金代理2钻石代理3初级账号4高级账号5普通用户
    userType: -1,

    tuanlist: [],
    tuanSearchKey: '',

    selectedGood: null,
    selectedAddress: null,

    // 我发布的活动列表
    myReleaseTuanList: [],

    // 商城信息
    mallInfo: {},

    // 会员信息
    member_info: {},

    // 是否盒马
    isHema: false,

    // 登陆绑定信息--商城、身份、权限etc.
    loginBindInfo: {},
}
export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return { ...state, sidebarOpen: action.sidebarOpen }
        case TOGGLE_LOGINPANEL:
            return { ...state, showLoginPanel: action.showlogin }
        case TOGGLE_SELECTPANEL:
            return { ...state, showSelectPanel: action.show }
        case SET_LOGIN_USER_DATA:
            return { ...state, login_user_data: action.data, is_Login: action.islogin }

        /**
         * isloading: 显示loading
         * loadingText: loading文字
         */
        case LOADING:
            return { ...state, isLoading: action.isLoading, loadingText: action.loadingText }

        case WX_OPEN:
            return { ...state, isWX: true }
        case SETORDER:
            return { ...state, order: action.order }
        case LOGOUT:
            return { ...state, login_user_data: {}, is_Login: false, waitingLogin: true }
        case WAITINGLOGIN:
            return { ...state, waitingLogin: action.value }
        case SET_TUANLIST:
            return { ...state, tuanlist: action.list }
        case SET_TUAN_SEARCH_KEY:
            return { ...state, tuanlist: [], tuanSearchKey: action.value }
        case SET_ISINVITE:
            return { ...state, isinvite: action.value }
        case USER_TYPE:
            return { ...state, userType: action.value }
        case INIT_URL:
            return { ...state, init_url: action.value }
        case SELECT_GOOD:
            return { ...state, selectedGood: action.value }
        case SAVE_MY_RELEASE_TUAN:
            return { ...state, myReleaseTuanList: action.value }
        case SAVE_MALL_INFO:
            return { ...state, mallInfo: action.value }
        case SELECT_ADDRESS:
            return { ...state, selectedAddress: action.value }
        case SAVE_MEMBER_INFO:
            return { ...state, member_info: action.value }
        case IS_HEMA:
            return { ...state, isHema: action.value }
        case SET_LOGIN_BIND_INFO:
            return { ...state, loginBindInfo: action.value }
        default:
            return state;
    }
}

export function toggleSidebar(sidebarOpen) {
    return { type: TOGGLE_SIDEBAR, sidebarOpen: true }
}
export function toggleLoginPanel(showlogin) {
    return { type: TOGGLE_LOGINPANEL, showlogin }
}
export function toggleSelectPanel(show) {
    return { type: TOGGLE_SELECTPANEL, show }
}
export function set_login_user_data(islogin, data) {
    return { type: SET_LOGIN_USER_DATA, data, islogin }
}
export function setOrder(order) {
    return { type: SETORDER, order }
}
export function setTuanlist(list) {
    return { type: SET_TUANLIST, list }
}

export function setTuanSearchKey(value) {
    return { type: SET_TUAN_SEARCH_KEY, value }
}

export function logout() {
    return { type: LOGOUT }
}
export function waitingLogin(value) {
    return { type: WAITINGLOGIN, value }
}
export function setIsInvite(value) {
    return { type: SET_ISINVITE, value }
}
export function setusertype(value) {
    return { type: USER_TYPE, value }
}
export function initurl(value) {
    return { type: INIT_URL, value }
}
export function select_good(value) {
    return { type: SELECT_GOOD, value }
}
export function setMyReleaseTuanList(value) {
    return { type: SAVE_MY_RELEASE_TUAN, value }
}

export function showLoading(show, text) {
    const data = { isLoading: show, loadingText: text || '' }
    return { type: LOADING, ...data }
}

export function saveMallInfo(value) {
    return { type: SAVE_MALL_INFO, value }
}

export function saveMemberInfo(value) {
    return { type: SAVE_MEMBER_INFO, value }
}

export function selectAddress(value) {
    return { type: SELECT_ADDRESS, value }
}

export function setIsHema(value) {
    return { type: IS_HEMA, value }
}

export function setLoginBindInfo(value) {
    return { type: SET_LOGIN_BIND_INFO, value }
}