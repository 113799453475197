import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import Main from '../../high-component/Content';

import Ban from '../../high-component/BanUnLogin';

// import api from '../../netApi'

import gold_service from '../../images/gold_service.png';
import diomond_service from '../../images/diomond_service.png';
import high_member from '../../images/high_member.png';
import low_member from '../../images/low_member.png';

const bg = 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/66N369A3Rp.png'
class Paysuccess extends Component {

    constructor(p) {
        super()
        // console.log(p)
        const type = p.match.params.type
        if (type !== 'member' && type !== 'service') {
            p.history.replace('/')
        }
        let typeText, showlogo
        switch (p.location.state ? p.location.state.type : null) {
            case 1:
                showlogo = gold_service
                typeText = '黄金服务商'
                break;
            case 2:
                showlogo = diomond_service
                typeText = '钻石服务商'
                break;
            case 3:
                showlogo = low_member
                typeText = '初级会员'
                break;
            default:
                showlogo = high_member
                typeText = '高级会员'
                break;
        }

        this.state = {
            typeText, showlogo
        }

    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <img alt='bg' src={bg} style={{
                    position: 'absolute',
                    top: 0, left: 0,
                    width: '100%', height: 'auto', zIndex: -1
                }}></img>
                <div style={{ paddingTop: 64, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <i className='iconfont icon-gou' style={{
                        color: '#b7a083', fontSize: 60
                    }}></i>
                    <div style={{
                        color: '#b7a083'
                    }}>支付成功</div>
                </div>
                <div style={whitePanel}>
                    <div className='fbc' style={{ borderBottom: '1px solid #d8d8d8', paddingBottom: 15 }}>
                        <img alt='' src={this.state.showlogo} style={{
                            width: 60
                        }}></img>
                        <div style={{ fontSize: 20, color: '#585858', fontWeight: 'bold', flex: 1, paddingLeft: 8 }}>
                            恭喜！您已经成为{this.state.typeText}
                        </div>
                    </div>
                    <div style={{ fontSize: 10, margin: '16px 0' }}>
                        您{this.state.typeText === '服务商' ? '可以' : '已获得权益'}：
                    </div>
                    {this.state.typeText === '服务商' ? (
                        <div>
                            <div className='fbc' style={{ marginBottom: 12 }}>
                                <span style={number}>1</span>
                                <div style={{ fontSize: 12, flex: 1, marginLeft: 12 }}>邀请好友成为代理（直接引荐返30%）</div>
                            </div>
                            <div className='fbc' style={{ marginBottom: 12 }}>
                                <span style={number}>2</span>
                                <div style={{ fontSize: 12, flex: 1, marginLeft: 12 }}>邀请团长/门店升级会员（直接引荐返50%）</div>
                            </div>
                            <Link to='/promotion'>
                                <button style={{
                                    border: 'none', borderRadius: 8, background: '#d6b080', color: 'white', width: '100%', padding: 15, marginTop: 15
                                }}>
                                    立即邀请好友
                                </button>
                            </Link>
                        </div>
                    ) : (
                            <div>
                                <div style={{ paddingBottom: 30, borderBottom: '1px solid #d8d8d8' }}>
                                    <div className='fbc' style={{ marginBottom: 12, display: 'inline-flex', marginRight: 8 }}>
                                        <span style={number}>1</span>
                                        <div style={{ fontSize: 12, flex: 1, marginLeft: 8 }}>工具插件</div>
                                    </div>
                                    <div className='fbc' style={{ marginBottom: 12, display: 'inline-flex', marginRight: 8 }}>
                                        <span style={number}>2</span>
                                        <div style={{ fontSize: 12, flex: 1, marginLeft: 8 }}>交易费率</div>
                                    </div>
                                    <div className='fbc' style={{ marginBottom: 12, display: 'inline-flex', marginRight: 8 }}>
                                        <span style={number}>3</span>
                                        <div style={{ fontSize: 12, flex: 1, marginLeft: 8 }}>管理员数量</div>
                                    </div>
                                    <div className='fbc' style={{ marginBottom: 12, display: 'inline-flex', marginRight: 8 }}>
                                        <span style={number}>4</span>
                                        <div style={{ fontSize: 12, flex: 1, marginLeft: 8 }}>帮卖好友数量</div>
                                    </div>
                                    <div className='fbc' style={{ marginBottom: 12, display: 'inline-flex', marginRight: 8 }}>
                                        <span style={number}>5</span>
                                        <div style={{ fontSize: 12, flex: 1, marginLeft: 8 }}>小程序高级功能</div>
                                    </div>
                                    <div className='fbc' style={{ marginBottom: 12, display: 'inline-flex', marginRight: 8 }}>
                                        <span style={number}>6</span>
                                        <div style={{ fontSize: 12, flex: 1, marginLeft: 8 }}>PC端高级功能</div>
                                    </div>
                                </div>
                                <div style={{ paddingTop: 12 }}>
                                    <div style={{ fontSize: 12, color: '#666' }}>立即回到小程序体验新权益</div>
                                    <div style={{ ...grayPanel, padding: 20, background: '#fff', margin: 0, }}>
                                        <img src={require('../../images/code.png')} alt='小程序码' style={{ width: 100, borderRadius: '50%' }} />
                                        <div style={{ fontSize: 14, marginTop: 14 }}>长按图片扫描二维码进入小程序</div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
                {this.state.typeText === '服务商' ? (
                    <div style={grayPanel}>
                        <img src={require('../../images/code.png')} alt='小程序码' style={{ width: 100, borderRadius: '50%' }} />
                        <div style={{ fontSize: 14, marginTop: 14 }}>长按图片扫描二维码进入小程序</div>
                    </div>
                ) : ''}
                <a alt='关注' href='https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI5NzMyMjEzMg==#wechat_redirect'>
                    <div style={{ ...grayPanel, flexDirection: 'row', justifyContent: 'space-between', padding: 16 }}>
                        <img alt='' src='https://tuangou-1259536745.picgz.myqcloud.com/uploads/tuan/sOe8222ehX.png' style={{
                            width: 40, borderRadius: 5
                        }} />
                        <div style={{ padding: ' 0 8px' }}>
                            <div style={{ fontSize: 14 }}>接力go</div>
                            <div style={{ fontSize: 8, color: '#999' }}>
                                关注接力go服务号，不错过任何一个订单
                            </div>
                        </div>
                        {/* <div> */}
                        <button style={{ background: '#fd5356', color: '#fff', border: 'none', borderRadius: 4, minWidth: '4em' }}>关注</button>
                        {/* </div> */}
                    </div>
                </a>
            </div>
        )
    }
}

export default Ban(Main(Paysuccess))

const whitePanel = {
    boxShadow: '0 8px 19px 0 rgba(219,219,219,0.50)',
    borderRadius: 12,
    background: '#fff',
    padding: 15,
    margin: 30
}
const number = {
    background: '#666666',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20, height: 20, minWidth: 20,
    borderRadius: 10,
    color: 'white',
    fontSize: 10
}
const grayPanel = {
    margin: 30,
    background: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 24
}