import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NinePic extends Component {

    static propTypes = {
        list: PropTypes.array.isRequired,
        alt: PropTypes.string,
        imgHeight: PropTypes.number,

    }

    showImg(i) {
        this.setState({
            preview: true,
            preImg: i.data
        })
    }

    constructor(p) {
        super()
        this.state = {
            ImgList: []
        }
        for (let i = 0; i < p.list.length; i++) {
            const item = p.list[i];
            const data = new Image()
            data.src = item.src
            data.onload = () => {
                const list = this.state.ImgList
                list[i] = { data, source: item }
                this.setState({
                    ImgList: list
                })
            }
        }
    }

    render() {
        return (
            <div>
                <div className='fbc' style={{
                    flexWrap: 'wrap',
                }}>
                    {this.state.ImgList.map((i, index) => {
                        return (
                            <img className='img-center cur-pointer' alt={this.props.alt || ''} src={i.data.src + '?imageView2/2/w/300'} key={index}
                                style={{
                                    display: 'block', flex: 0,
                                    width: '30%', height: this.props.imgHeight || 80,
                                    marginBottom: 15, order: i.source.rank, userSelect: 'none'
                                }} onClick={this.showImg.bind(this, i)} draggable={false}
                            />
                        )
                    })}
                    {this.state.ImgList.length % 3 === 2 ? (<span style={{ width: '30%', order: 10000000 }}></span>) : ''}
                    {this.state.ImgList.length % 3 === 1 ? (<span style={{ width: '30%', order: 10000000 }}></span>) : ''}
                    {this.state.ImgList.length % 3 === 1 ? (<span style={{ width: '30%', order: 10000000 }}></span>) : ''}
                </div>
                {this.state.preview ? (<div className='mask' onClick={() => {
                    this.setState({
                        preview: false,
                        preImg: null
                    })
                }} >
                    <img alt={this.props.alt} src={this.state.preImg.src} style={{
                        maxWidth: '90vw', maxHeight: '90vh', userSelect: 'none'
                    }}></img>
                </div>) : ''}
            </div>
        )
    }
}


export default NinePic